import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { Chart } from 'angular-highcharts';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-broker-endo-tat-report',
  templateUrl: './broker-endo-tat-report.component.html',
  styleUrls: ['./broker-endo-tat-report.component.css']
})
export class BrokerEndoTatReportComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray:any = [];
  InfoPolicy:any = [];
  ClientId:any = [];
  InfoPolicyMonth:any;
  tat_graph:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  employers:any;
  total_employees:any;
  claim_submitted:any;
  active_policies:any;

  claim_registered: any;
  claim_pedning: any;
  claim_settled: any;
  claim_rejected: any;
  registered_amount: any;
  pedning_amount: any;
  settled_amount: any;
  rejected_amount: any;
  status:any = [];
  count:any = [];
  amount:any = [];
  InfoSummary:any = [];

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  dtOptions2: DataTables.Settings = {
    destroy: true,
    scrollY: 190,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: true, targets: '_all' }
    ]
  };
  dtTrigger2: Subject<any> = new Subject<any>();

  readaccess   : any = 1;
  writeaccess  : any = 1;
  deleteaccess :  any = 1;
  modulename = 'claim_executive_dashboard';

  bar: Chart;
  clientname: any;
  label_text:any;

  clientwisepolicy:any = [];

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
  }

  initGroup(){

    this.barchart();
    this.getInfodropdown();

  }

  // full width table get API
  getInfo(){

    this.InfoPolicy = [];
    this.InfoPolicyMonth = [];

    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "employer_id":$('#client_name').val(),
      "dimension": $('#dimensions').val()
    }
    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {

          this.InfoPolicy = result.data.endo_list;
          this.InfoPolicyMonth = result.data.endo_month_list;
          this.tat_graph = result.data.tat_graph;
          this.barchart();

          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });

          console.log(this.InfoPolicy);
          console.log(this.InfoPolicyMonth);
          // setTimeout(() => {
          //   this.dtTrigger.next();
          //   // this.barchart();
          // }, 2000);

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

          // this.InfoPolicy = [];
          // this.InfoPolicyMonth = [];

      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  getSortedMonths(): string[] {
    const monthOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return Object.keys(this.InfoPolicyMonth || {}).sort((a, b) => {
      return monthOrder.indexOf(a) - monthOrder.indexOf(b);
    });
  }


  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();
  }

  barchart() {

    const tatGraph = this.tat_graph;

    // console.log(this.tat_graph);
    // console.log('API Response:', tatGraph);

    var categories = tatGraph.months;
    const columnData1 = tatGraph.maxdays;
    const columnData2 = tatGraph.mindays;
    const lineData = tatGraph.averagedays;

    let barchart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: '',
        style: {
          fontFamily: 'Poppins',
          fontSize: '18px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {
        title: {
          // text: 'percentialambulance25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        min: 0, // Set the minimum value of the y-axis to 0
        max: 100, // Set the maximum value of the y-axis to 100
        gridLineWidth: 0,
        labels: {
        },
      },
      plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                format: '{point.y}', // Display the count on top of each bar
                style: {
                    color: 'black'
                }
            }
        }
      },
      series: [
        {
          name: 'Max. No of Days',
          type: 'column',
          data: columnData1,

        },
        {
          name: 'Min. No of Days',
          type: 'column',
          data: columnData2,
          color: '#212e76'
        },
        {
          name: 'Avg. No of Days',
          type: 'line',
          data: lineData,
          color: 'red'
      }
      ],
      exporting: {
        enabled: false
      }
    });
    this.bar = barchart;
    barchart.ref;
  }

  setlabel(e:any){
    this.label_text = $('#type').find('option:selected').text();
    let val:any = $('#type').val();
    localStorage.setItem('TAT_type', val);
    localStorage.setItem('TAT_label_text', this.label_text);
    window.location.reload();
  }

  // dimension dropdown
  getclientwisepolicy_list(){

    var brokerid:any;

    if(localStorage.getItem('broker_id') == ''){
      brokerid = 0;
    }else{
      brokerid = localStorage.getItem('broker_id');
    }

    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":brokerid,
      "employer_id":$('#client_name').val(),
    }

    this.dataservice.getclientwisepolicy_list(postdata)
        .subscribe((result: any) => {

          this.clientwisepolicy = result.data;

          this.getInfo();

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

          this.clientwisepolicy = [];

      });

  }

  getInfodropdown(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id')
    };

    this.dataservice.getInfodropdown(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.ClientId = result.data;

        }, (error) => {

          this.ClientId = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }
}
