import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-cd-updation',
  templateUrl: './cd-updation.component.html',
  styleUrls: ['./cd-updation.component.css']
})
export class CdUpdationComponent implements OnInit {

 
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  ClientId:any = [];
  clientwisepolicy :any = [];
  InfoArray:any = [];
  InfoPolicy:any = []; 
  InfoArraydropdown: any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  displayStyleAdd = "none";

  clientname: any;
  clientid: any;

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
        this.clientname = localStorage.getItem('clientname');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
  }

  initGroup(){

    this.InfoArray = [];
    // this.getInfo('', 'default');
    this.getInfodropdown();    

    // this.getInfo();
    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      entity_name: new FormControl('', Validators.required),  
      deposit_date: new FormControl('', Validators.required),  
      cd_amount:new FormControl('', Validators.required),
      trans_no:new FormControl('', Validators.required),
      bank_name:new FormControl('', Validators.required),
      bank_branch:new FormControl('', Validators.required),
      payment_mode:new FormControl('', Validators.required),

    });

  }

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "entity_name":this.AddInfo.controls['entity_name'].value,
        "deposit_date":this.AddInfo.controls['deposit_date'].value,
        "cd_amount":this.AddInfo.controls['cd_amount'].value,
        "trans_no":this.AddInfo.controls['trans_no'].value,
        "bank_name":this.AddInfo.controls['bank_name'].value,
        "bank_branch":this.AddInfo.controls['bank_branch'].value,
        "payment_mode":this.AddInfo.controls['payment_mode'].value,
        // "inception_premium":$('#inception_premium').val(),
      };
      
      console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  getInfodropdown(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id')
    };

    this.dataservice.getInfodropdown(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.ClientId = result.data; 

        }, (error) => {          
          
          this.ClientId = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  closePopupAdd(){
    // this.addflag = 0;      
    this.displayStyleAdd = "none";
    window.location.reload();   
  }

  openPopupAdd(){
    // this.addflag = 1; 
    // this.getInfodropdown();
    this.displayStyleAdd = "block";   
  }


  getInfo(val:any, value:any){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    this.InfoArray = [];
    var postdata:any;

    if(value == 'default'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": 0,
        "broker_id": localStorage.getItem('broker_id')
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": val.target.value,
        "broker_id": localStorage.getItem('broker_id')
      };
    }
    
    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {

          this.show = false;
          this.InfoArray = result.data;
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });

        }, (error) => {

          this.show = false;
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  ondownload_uploaded(fileUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
