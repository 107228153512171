<div id="stepper2" class="bs-stepper bg-white my-4 mb-5 rounded p-3 mainstepper_div">
    
    <div class="flagDiv">

        <span class="plan_step3">GMC(B)</span>
    
        <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1" style="margin-left: 20px;">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Product</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Policy Details</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Policy Features</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-4">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">4</span>
                    <span class="bs-stepper-label">Document Upload</span>
                </button>
            </div>
        </div>
    </div>

    <div class="progressroot">
        <div [ngClass]="{'step1': stepcomplete === 'step1'}"></div>
        <div [ngClass]="{'step2': stepcomplete === 'step2'}"></div>
        <div [ngClass]="{'step3': stepcomplete === 'step3'}"></div>
        <div [ngClass]="{'step4': stepcomplete === 'step4'}"></div>
    </div>

    <div class="bs-stepper-content">

            <div id="test-l-1" class="content">                
                <form [formGroup]="policyDetails">
                    <h3 class="bs-title">Product Name </h3>
                    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                        <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                        <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    <hr>
                    <div class="row">                        

                        <!--------Section 1------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label mt-2">Product Sub Category</h5>
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                        </div>
                        <div class="col-sm-8">

                            <div class="form-group">
                                <!-- <label class="bs-form-label" for="policy_sub_category">Product Sub Category *</label> -->
                                <select class="form-select" formControlName="policy_sub_category" id="policy_sub_category" (change)="changepage($event)">
                                    <!-- <option value="">Select</option> -->
                                </select>
                                <!-- <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.policy_sub_category.errors">
                                    <p class="error">
                                    Product Sub Category is required
                                    </p>
                                </ng-container> -->
                            </div>
                            
                            <!-- <div class="form-group">
                                <label class="bs-form-label" for="policy_sub_category">Product Sub Category *</label>                               
                                <input type="text" class="form-control" formControlName="policy_sub_category" id="policy_sub_category" style="pointer-events: none; background: #aaaaaa5c;" />
                            </div> -->

                        </div>
                        <hr class="my-3">
                        <!--------END------->                                                

                        <!--------Section 3------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label">Policy Details</h5>
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                        </div>
                        <div class="col-sm-8 row">
                            <!-- <div class="form-group mt-3">
                                <div class="mb-2 bs-form-label">Do you have an existing policy? <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <div class="switch-field wFC">                                
                                    <input type="radio" id="radio-two_existing_policy_check" formControlName="existing_policy_check" name="existing_policy_check" checked value="no" (click)="existing_policy_check()" />
                                    <label for="radio-two_existing_policy_check" class="bs-form-label">No</label>	
                                    <input type="radio" id="radio-one_existing_policy_check" formControlName="existing_policy_check" name="existing_policy_check" value="yes" (click)="existing_policy_check()" />
                                    <label for="radio-one_existing_policy_check" class="bs-form-label">Yes</label>
                                </div>
                            </div> -->

                            <div class="form-group">
                                <div class="mb-2 bs-form-label">Benefit Structure <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <input type="radio" class="form-radio" value="manually" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Enter Manually </span>                            
                                &nbsp;
                                <input type="radio" class="form-radio" value="policy_template" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Upload Excel Template </span>                                                        
                                &nbsp;                                
                                <input type="radio" class="form-radio" value="clone_plan" formControlName="upload_check" id="clone_plan" (change)="entermanually();" /> <span class="bs-radio-label"> Clone Plan </span>                                                        
                                &nbsp;
                                <input type="radio" class="form-radio" value="existing_doc" formControlName="upload_check" id="upload_check" style="pointer-events:none;" /> <span class="bs-radio-label" style="color:#AAAAAA"> Upload Policy PDF </span>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadPolicyDetails()">Download Sample Template</span>                                                                        
                            </div>
                            

                            <div class="form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="insurance_company">Insurance company *</label>
                                
                                <!-- <select class="form-select" formControlName="insurance_company" id="insurance_company" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                required >
                                    <option value="" selected>Select</option>
                                </select>                            
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container> -->

                                <!-- <ng-select formControlName="insurance_company" id="insurance_company">
                                    <ng-option [value]="" selected>Selected</ng-option>
                                </ng-select> -->
                                 
                                <ng-select formControlName="insurance_company" id="insurance_company" 
                                            [items]="isuranceCompanyDetailsarr" 
                                            bindLabel="name" 
                                            bindValue="insure_comp_id"
                                            [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                            required>                                    
                                </ng-select>
                                 <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container>

                            </div>                        

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="tpa">Third Party Administrators(TPA) Name *</label>
                                <select class="form-select" formControlName="tpa" id="tpa" style="line-height: 1.7;" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.tpa.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                </select>                                                        
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.tpa.errors">
                                    <p class="error">
                                    TPA is required
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="broker_name">Broker Name *</label>
                                <select class="form-select" formControlName="broker_name" id="broker_name" style="line-height: 1.7;"
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                </select>
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors">
                                    <p class="error">
                                    Broker Name is required
                                    </p>
                                </ng-container>
                            </div>                        
                                                
                            <div class="form-group mt-3" id="div_upload_policydocument" style="display: none">
                                <label class="bs-form-label" for="upload_policydocument">Upload policy document</label>
                                <label for="file" id="custom-file-upload_upload_policydocument" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_policydocument" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                </label>
                                <input id="file" type="file" (change)="upload_policydocument_change($event)" enctype="multipart/form-data" accept=".xlsx, .xls, .csv, application/pdf" style="display:none" />
                                <small style="font-size: 12px; color:#605D62" id="small_upload_policydocument">Upload only .xlxs, .xls files (Max size 4MB)</small>
                            </div>

                            <div class="col-sm-4 mt-3" id="div_clone_plan" style="display: none">
                                <div class="form-group">
                                    <label class="bs-form-label">Base plan / Plan Id *</label>                                
                                    <select class="form-select" formControlName="base_plan" id="base_plan">
                                        <!-- <option value="" selected>Select</option>                                     -->
                                    </select>                                    
                                </div>
                            </div>

                        </div>
                        <hr class="my-3">
                        <!--------END------->

                        <div>
                            <button (click)="next(1)" id="manual_flow_save" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="next(1)" id="excel_flow_save" class="btn btn-primary" style="float: right;display:none;">Upload</button>
                        </div>
                    
                    </div>
                    
                </form>
            </div>

            <div id="test-l-2" class="content">
                <form [formGroup]="verifypolicyDetails">
                <h3 class="bs-title">Policy Details</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Policy Details</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-6 form-group policy_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="policy_date">Policy inception date *</label>
                                <input type="date" class="form-control" formControlName="policy_date" id="policy_date" (change)="loadecpirydate()"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors">
                                    <p class="error">
                                    Policy inception date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group how_soon_buy">
                                <label class="bs-form-label" for="how_soon_buy">How soon you planning to buy *</label>
                                <select class="form-select" formControlName="how_soon_buy" id="how_soon_buy" (change)="how_soon_buy_check($event)"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Immediatly">Immediatly</option>
                                    <option value="Within a month">Within a month</option>
                                    <option value="Specific month">Specific month</option>
                                    <option value="Not yet decided">Not yet decided</option>
                                </select>                            
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors">
                                    <p class="error">
                                    This is required field
                                    </p>
                                </ng-container>
                            </div>                            
                            <div class="col-sm-6 form-group step2hideFields">
                                <label class="bs-form-label" for="policy_expirydate">Policy expiry date *</label>
                                <input type="date" class="form-control" formControlName="policy_expirydate" id="policy_expirydate"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 data_report_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="data_report_date">Data report date *</label>
                                <input type="date" class="form-control" formControlName="data_report_date" id="data_report_date" min="{{min_date}}" max="{{max_date}}"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors">
                                    <p class="error">
                                    Data report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group choose_month" style="display:none">
                                <label class="bs-form-label" for="choose_month">Choose month *</label>
                                <select class="form-select" formControlName="choose_month" id="choose_month"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors}"
                                required>
                                    <option value="0" selected>Select</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 step2hideFields">
                                <label class="bs-form-label" for="policy_number">Policy number *</label>
                                <input type="text" class="form-control" formControlName="policy_number" id="policy_number" placeholder="340100502022000275"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors">
                                    <p class="error">
                                    Policy number is required
                                    </p>
                                </ng-container>
                            </div>                                                        
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-sm-4">
                            <div class="p-5 px-0 pb-0 step2hideFields">
                                <label class="bs-form-label" style="color:#000">Lives at Inception</label>
                            </div>
                            <div class="p-5 px-0 pt-3 step2hideFields">
                                <label class="bs-form-label" style="position: relative; top: 30px;color:#000">Lives Lives at Renewal</label>
                            </div>
                        </div>
                        <div class="row col-sm-8 p-0 m-0">
                            <div class="col-sm-6 form-group emp_atstart" style="margin-top: 16px !important;"> 
                                <label class="bs-form-label" for="emp_atstart">No of employees *</label>
                                <input type="text" class="form-control" formControlName="emp_atstart" id="emp_atstart" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors">
                                    <p class="error">
                                    No of employees at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group lives_covered mt-3 step2hideFields" style="display:none">
                                <label class="bs-form-label" for="lives_covered">No of total lives *</label>
                                <input type="text" class="form-control" formControlName="lives_covered" id="lives_covered" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors">
                                    <p class="error">
                                    No of lives covered at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="emp_atreport">No of employees *</label>
                                <input type="text" class="form-control" formControlName="emp_atreport" id="emp_atreport" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors">
                                    <p class="error">
                                    No of employees as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="lives_atreport">No of total lives *</label>
                                <input type="text" class="form-control" formControlName="lives_atreport" id="lives_atreport" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors">
                                    <p class="error">
                                    No of lives covered as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(2)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>                

                </form>
            </div>

            <div id="test-l-3" class="content">

                <!--------- Banner ---------->
                <div class="row">
                    <div class="col-sm-6">
                        <img src="../../assets/img/policy/policybanner.png" class="img-fluid" alt="Img" style="display:block; margin:auto; width:300px" />
                    </div>
                    <div class="col-sm-6 textm">
                        <h3 class="bs-title alignc">Policy Features</h3>
                        <p class="bs-p-normal alignc"> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                            Nam non metus id augue tempor volutpat sit amet sit amet nunc.
                        </p>
                    </div>
                </div>
                <hr class="my-4">
                <!--------- END ------------>

                <form [formGroup]="policyFeatures">
                <!-- <h3 class="bs-title">Policy Features</h3> -->
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <!-- <hr> -->

                <!---- Family Construct Accordian ---->
                <h5 class="bs-left-form-label accordheading FCheading" (click)="basicaccord()">Family Construct
                    <img id="basic_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="basic_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/Group.png" class="headicon" />
                    <img id="FCtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="FCtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgFC">
                        <div class="value-container" id="ValueFC">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="basics_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-3"> -->
                            <!-- <h5 class="bs-left-form-label">Basics</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12 form-group">
                                    <!-- <label class="bs-form-label" for="family_def">Family *</label>
                                    <p class="error" id="family_def_error" style="display:none">
                                    Family Definition is required, check at least one option
                                    </p>                                     -->

                                    <div class="col-sm-12 form-group">
                                        
                                        <!---- Employees --->
                                        <div style="display: flex">
                                            <div class="col-sm-2" style="padding: 10px;">
                                                <!-- <input type="checkbox" class="form-check-input" formControlName="Employees" name="Employees" id="Employees" (click)="show_family_otp('Employees')" /> -->
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Employees</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Employees" class="Employees" formControlName="Employees" name="Employees" value="No" (change)="show_family_otp1('Employees', 'no')" />
                                                    <label for="radio-two_Employees" id="Employees_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_Employees" class="Employees" formControlName="Employees" name="Employees" value="Yes" (change)="show_family_otp1('Employees', 'yes')" />
                                                    <label for="radio-one_Employees" id="Employees_yes" class="bs-form-label">Yes</label>	
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Employees" style="display:none;margin-top: 40px !important;">
                                                <div class="form-check col-sm-3">
                                                    <input type="checkbox" class="form-check-input empcheck" value="On Roll Employees" formControlName="On_Roll_Employees" name="On_Roll_Employees" id="On_Roll_Employees" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">On Roll Employees</label>
                                                </div>
                                                <div class="form-check col-sm-4">
                                                    <input type="checkbox" class="form-check-input empcheck" value="Contractual Employees" formControlName="Contractual_Employees" name="Contractual_Employees" id="Contractual_Employees" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Contractual Employees</label>
                                                </div>
                                                <div class="form-check col-sm-3">
                                                    <input type="checkbox" class="form-check-input empcheck" value="Retired Employees" formControlName="Retired_Employees" name="Retired_Employees" id="Retired_Employees" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Retired Employees</label>
                                                </div>
                                                <div class="form-check col-sm-2">
                                                    <input type="checkbox" class="form-check-input empcheck" value="Consultants" formControlName="Consultants" name="Consultants" id="Consultants" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Consultants</label>
                                                </div>
                                            </div>                                            
                                        </div>    
                                        <p class="error" id="empcheck_error" style="display:none">
                                            Choose at least one optiion
                                        </p>
                                        <p class="error" id="empcheck_error_1" style="display:none">
                                            This is required field
                                        </p>   
                                        <hr style="margin-bottom: 0px;">                                                                        

                                        <!---- Spouse --->
                                        <div class="div_Spouse" style="display: none;padding-bottom: 10px;">
                                            <div class="col-sm-2" style="padding: 10px">
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Spouse</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Spouse" formControlName="Spouse" name="Spouse" value="No" (change)="show_family_otp1('Spouse', 'no')" />
                                                    <label for="radio-two_Spouse" id="Spouse_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_Spouse" formControlName="Spouse" name="Spouse" value="Yes" (change)="show_family_otp1('Spouse', 'yes')" />
                                                    <label for="radio-one_Spouse" id="Spouse_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Spouse" style="display:none; margin-top: 40px !important;">
                                                <div class="form-check col-sm-3">
													<input type="checkbox" class="form-check-input spousecheck" value="Legally Wedded Spouse" formControlName="Legally_Wedded_Spouse" name="Legally_Wedded_Spouse" id="Legally_Wedded_Spouse" />
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Legally Wedded Spouse</label>
												</div>
												<div class="form-check col-sm-4">
													<input type="checkbox" class="form-check-input spousecheck" value="Domestic Partner" formControlName="Domestic_Partner" name="Domestic_Partner" id="Domestic_Partner" />
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Domestic Partner</label>
												</div>
												<div class="form-check col-sm-4">
													<input type="checkbox" class="form-check-input spousecheck" value="LGBTQ Cover" formControlName="LGBTQ_Cover" name="LGBTQ_Cover" id="LGBTQ_Cover" />
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Same Sex Partner(LGBTQ)</label>
												</div>
                                            </div>                                                                                                                                    
                                        </div>
                                        <p class="error" id="spousecheck_error" style="display:none">
                                            Choose at least one optiion
                                        </p>
                                        <p class="error" id="empcheck_error_2" style="display:none">
                                            This is required field
                                        </p>
                                        <hr class="div_Spouse" style="margin-bottom: 0px;display:none">                                         
                                        
                                        <!---- Childrens --->
                                        <div class="div_Childrens" style="display: none;padding-bottom: 10px;">
                                            <div class="col-sm-2" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle; margin-top:5px">Childrens</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Childrens" formControlName="Childrens" name="Childrens" value="No" (change)="show_family_otp1('Childrens', 'no')" />
                                                    <label for="radio-two_Childrens" id="Childrens_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_Childrens" formControlName="Childrens" name="Childrens" value="Yes" (change)="show_family_otp1('Childrens', 'yes')" />
                                                    <label for="radio-one_Childrens" id="Childrens_yes" class="bs-form-label">Yes</label>	
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Childrens" style="display:none;">
                                                <div class="form-check col-sm-5 p-0">
													<div class="switch-field" style="margin-top:36px; width: 262px;">
														<input type="radio" id="radio-one_children_type" formControlName="children_type" name="children_type" value="Upto 2 Child" />
														<label for="radio-one_children_type" class="bs-form-label">Upto 2 child</label>
														<input type="radio" id="radio-two_children_type" formControlName="children_type" name="children_type" value="More Then 2 Child" />
														<label for="radio-two_children_type" class="bs-form-label">More Then 2 Child</label>	
													</div>
												</div>
                                                <div class="col-sm-4 p-0" style="margin-top: 10px;">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="Age_Limit_of_the_Child_text">Age Limit of the Child (In Years) *</label>
                                                        <input type="text" class="form-control" formControlName="Age_Limit_of_the_Child_text" name="Age_Limit_of_the_Child_text" id="Age_Limit_of_the_Child_text" (keypress)="isNumber($event)" (input)="age_limit($event,'children')" /> 
                                                        <p class="error" id="Age_Limit_of_the_Child_text_error" style="display:none">
                                                            Age Limit is required
                                                        </p>
                                                    </div>

                                                    <!-- <div class="row">
                                                        <div class="col-sm-6">
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;margin-top: 3px;">Age Limit of the Child</label>
                                                        </div>

                                                        <div class="col-sm-6" style="display:flex; position: relative; left: -80px; top: -5px;">
                                                            <input type="text" class="form-control" formControlName="Age_Limit_of_the_Child_text" name="Age_Limit_of_the_Child_text" id="Age_Limit_of_the_Child_text" (keypress)="isNumber($event)" (input)="age_limit($event,'children')" style="width:40%; margin-top: 3px;" /> 
                                                            &nbsp; <span style="margin-top: 10px;font-size: 13px;font-family: 'Poppins';font-style: normal;">(Years)</span>
                                                        </div>                                                
                                                    </div>        -->

                                                </div>												
                                            </div>
                                        </div>
                                        <p class="error" id="empcheck_error_3" style="display:none">
                                            This is required field
                                        </p>                                          
                                        <hr class="div_Childrens" style="margin-bottom: 0px;display:none">                                      

                                        <!---- Parents --->
                                        <div class="div_Parents" style="display: none;padding-bottom: 10px;">
                                            <div class="col-sm-2" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Parents</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Parents" formControlName="Parents" name="Parents" value="No" (change)="show_family_otp1('Parents', 'no')" />
                                                    <label for="radio-two_Parents" id="Parents_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_Parents" formControlName="Parents" name="Parents" value="Yes" (change)="show_family_otp1('Parents', 'yes')" />
                                                    <label for="radio-one_Parents" id="Parents_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Parents" style="display:none; margin-top: 40px !important;">
                                                <div class="form-check col-sm-2" style="display:flex;padding: 5px 0px;">
													<input type="radio" class="form-radio-input parentcheck" value="Parent only" formControlName="Parent_only" name="Parent_only" id="Parent_only" (click)="show_cross_combination('Parent only')" style="background: #fff" />
													<label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Parent only</label>
												</div>                                                                                        
												<div class="form-check col-sm-3" style="display:flex;padding: 5px 0px;">
													<input type="radio" class="form-radio-input parentcheck" value="Parent or Parent Inlaws" formControlName="Parent_only" name="Parent_only" (click)="show_cross_combination('Parent or Parent Inlaws')" id="Parent_only" style="background: #fff" />
													<label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Parents OR Parents Inlaws</label>
												</div>
												<div class="form-check col-sm-3" style="display:flex;padding: 5px 0px; padding-left: 5px;">
													<input type="radio" class="form-radio-input parentcheck" value="Parent and Parent Inlaws" formControlName="Parent_only" name="Parent_only" (click)="show_cross_combination('Parent and Parent Inlaws')" id="Parent_only" style="background: #fff" />
													<label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Parents & Parents Inlaws</label>
												</div>
												<div class="form-check col-sm-4 Cross_Combination_status" style="display: none">
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;margin-right: 10px;margin-top: 2px;">Cross Combination</label>
													<div class="switch-field wFC" style="height: 35px;">														
														<input type="radio" id="radio-two_Cross_Combination_status" formControlName="Cross_Combination_status" name="Cross_Combination_status" value="No" />
														<label for="radio-two_Cross_Combination_status" class="bs-form-label">No</label>	
                                                        <input type="radio" id="radio-one_Cross_Combination_status" formControlName="Cross_Combination_status" name="Cross_Combination_status" value="Yes" />
														<label for="radio-one_Cross_Combination_status" class="bs-form-label">Yes</label>
													</div>
												</div>
                                            </div>                                            
                                        </div>                                         
                                        <div class="error my-2" id="parentcheck_error" style="display:none">                                            
                                                Choose at least one optiion                                            
                                        </div>
                                        <p class="error" id="empcheck_error_4" style="display:none">
                                            This is required field
                                        </p> 
                                        <hr class="div_Parents" style="margin-bottom: 0px;display:none">                                                                                                                    

                                        <!---- Siblings_or_disabled_siblings --->
                                        <div class="div_Siblings_or_disabled_siblings" style="display: none;padding-bottom: 10px;">
                                            <div class="col-sm-3" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Siblings/disabled siblings</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Siblings_or_disabled_siblings" formControlName="Siblings_or_disabled_siblings" name="Siblings_or_disabled_siblings" value="No" (change)="show_family_otp1('Siblings_or_disabled_siblings', 'no')" />
                                                    <label for="radio-two_Siblings_or_disabled_siblings" id="Siblings_or_disabled_siblings_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_Siblings_or_disabled_siblings" formControlName="Siblings_or_disabled_siblings" name="Siblings_or_disabled_siblings" value="Yes" (change)="show_family_otp1('Siblings_or_disabled_siblings', 'yes')" />
                                                    <label for="radio-one_Siblings_or_disabled_siblings" id="Siblings_or_disabled_siblings_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-3" id="show_family_otp_Siblings_or_disabled_siblings" style="display:none;margin-top: 10px;">                                                
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="Age_Limit_of_the_Siblings_text">Minimum Range (In Years) *</label>
                                                    <input type="text" class="form-control" formControlName="Age_Limit_of_the_Siblings_text" name="Age_Limit_of_the_Siblings_text" id="Age_Limit_of_the_Siblings_text" (keypress)="isNumber($event)" (input)="age_limit($event,'sibling')" /> 
                                                    <p class="error" id="Age_Limit_of_the_Siblings_text_error" style="display:none">
                                                        Min Range is required
                                                    </p>
                                                </div>

                                                <!-- <div class="form-check col-sm-12 p-0">
                                                    <div class="row mt-3">
                                                        <div class="form-check col-sm-4">
															<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Age Limit of the Siblings</label>
														</div>
														<div class="form-check col-sm-8" style="position: relative; left: -60px; top: -5px;">
															<input type="text" class="form-control" formControlName="Age_Limit_of_the_Siblings_text" name="Age_Limit_of_the_Siblings_text" id="Age_Limit_of_the_Siblings_text" (keypress)="isNumber($event)" (input)="age_limit($event,'sibling')" style="width:20%" /> 
															&nbsp; <span style="margin-top: 6px;font-size: 13px;font-family: 'Poppins';font-style: normal;">(Years)</span>
														</div>                                                
                                                    </div>       
                                                </div> -->

                                            </div>
                                        </div>      
                                        <p class="error" id="empcheck_error_5" style="display:none">
                                            This is required field
                                        </p>                                  
                                        <hr class="div_Siblings_or_disabled_siblings" style="margin-bottom: 0px;display:none">                                      

                                        <!---- Overall Age limit --->
                                        <div class="div_Overall_Age_limit" style="display: flex;">
                                            <div class="col-sm-2 pt-3" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Overall age limit</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_overall_age_range" formControlName="overall_age_range" value="No" (change)="show_family_otp1('overall_age_range', 'no')" />
                                                    <label for="radio-two_overall_age_range" id="overall_age_range_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_overall_age_range" formControlName="overall_age_range" name="overall_age_range" value="Yes" (change)="show_family_otp1('overall_age_range', 'yes')" />
                                                    <label for="radio-one_overall_age_range" id="overall_age_range_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12" id="show_family_otp_overall_age_range" style="display:none;margin-top: 10px;width: 80%;">
                                                <div class="col-sm-3 mt-2" style="margin: 0px 15px;">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="age_range_min">Minimum Range (In Years) *</label>
                                                        <input type="text" class="form-control" formControlName="age_range_min" name="age_range_min" id="age_range_min" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />
                                                        <p class="error" id="age_range_min_error" style="display:none">
                                                            Min Range is required
                                                        </p>
                                                    </div>
                                                </div>                                                
                                                <div class="col-sm-4 mt-2">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="age_range_max">Maximum Range (In Years) *</label>
                                                        <input type="text" class="form-control" formControlName="age_range_max" name="age_range_max" id="age_range_max" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />
                                                        <p class="error" id="age_range_max_error" style="display:none">
                                                                Max Range is required
                                                        </p>
                                                    </div>
                                                </div>                                                

                                                <!-- <div class="form-check col-sm-12 p-0">
                                                    <div class="row mt-2">
                                                        <div class="form-check col-sm-2">
															<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Min Range</label>
														</div>
														<div class="form-check col-sm-3 px-5" style="display:flex; position: relative; left: -60px; top: -5px;">
															<input type="text" class="form-control" formControlName="age_range_min" name="age_range_min" id="age_range_min" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />
															
														</div>
														<div class="form-check col-sm-2">
															<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Max Range</label>
														</div>
														<div class="form-check col-sm-3 px-5" style="display:flex; position: relative; left: -60px; top: -5px;">
															<input type="text" class="form-control" formControlName="age_range_max" name="age_range_max" id="age_range_max" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />															
														</div>														
                                                    </div>       
                                                </div> -->

                                            </div>
                                        </div>
                                        <p class="error" id="empcheck_error_6" style="display:none">
                                            This is required field
                                        </p>

                                    </div>                                    
                                    
                                </div>                                

                            </div>
                        </div>
                    </div>
                </div>
                <!----------------------------->

                <!---- Sum Insured Coverage Accordian ---->
                <h5 class="bs-left-form-label accordheading SIheading" (click)="sumisuredaccord()">Sum Insured Coverage
                    <img id="sumisured_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="sumisured_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/sum-insured-coverage.png" class="headicon" />
                    <img id="SItick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="SItick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgSI">
                        <div class="value-container" id="ValueSI">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="sumisured_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-3"> -->
                            <!-- <h5 class="bs-left-form-label">Sum Insured Coverage</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">                                                                

                                <div class="col-sm-3 form-group">
                                    <div class="form-group mt-3">
                                        <label class="bs-form-label" for="sum_insured_type">Sum insured type <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sum_insured_type_div')" (mouseleave)="mouseLeave($event, 'sum_insured_type_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="sum_insured_type_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Sum insured type</p>
                                        </div>
                                    </div>
                                    <div class="switch-field floaternon">
                                        <input type="radio" id="radio-one_sum_insured_type" formControlName="sum_insured_type" name="sum_insured_type" value="Floater" (change)="purple_white_change('sum_insured_type', 'no')" />
                                        <label for="radio-one_sum_insured_type" id="sum_insured_type_no" class="bs-form-label">Floater</label>
                                        <input type="radio" id="radio-two_sum_insured_type" formControlName="sum_insured_type" name="sum_insured_type" value="Not Floater" (change)="purple_white_change('sum_insured_type', 'yes')" />
                                        <label for="radio-two_sum_insured_type" id="sum_insured_type_yes" class="bs-form-label">Not Floater</label>	
                                    </div>
                                </div>                                

                                <div class="col-sm-6 form-group">
                                    <div class="form-group mt-3">
                                        <label class="bs-form-label" for="sum_insured_approach">Sum insured approach <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sum_insured_approach_div')" (mouseleave)="mouseLeave($event, 'sum_insured_approach_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="sum_insured_approach_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Sum insured approach</p>
                                        </div>
                                    </div>
                                    <div class="switch-field unigraded">
                                        <input type="radio" id="radio-one_sum_insured_approach" formControlName="sum_insured_approach" name="sum_insured_approach" value="Uniform" (change)="sumapproach('Uniform'); purple_white_change('sum_insured_approach', 'no')" />
                                        <label for="radio-one_sum_insured_approach" id="sum_insured_approach_no" class="bs-form-label">Uniform</label>
                                        <input type="radio" id="radio-two_sum_insured_approach" formControlName="sum_insured_approach" name="sum_insured_approach" value="Graded" (change)="sumapproach('Graded'); purple_white_change('sum_insured_approach', 'yes')" />
                                        <label for="radio-two_sum_insured_approach" id="sum_insured_approach_yes" class="bs-form-label">Graded</label>	
                                    </div>
                                    <p class="error" id="sum_insured_approach_error" style="display:none">
                                        Sum insured approach is required
                                    </p>
                                </div>

                                <div class="col-sm-12 div_sum_insured_approach" style="display: none">
                                    <div class="row append_sum_insured_approach">

                                    </div>

                                    <div class="col-sm-12 mt-3">
                                        <label class="bs-form-label" (click)="sumapproach('Graded')" style="cursor: pointer">
                                            <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                            <span style="color: #1489F3;">Add Amount</span>
                                        </label>
                                    </div>

                                </div>

                                <div class="col-sm-12 div_sum_insured_approach_uniform" style="display: none">
                                  <div class="row append_sum_insured_approach_uniform">

                                  </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!----------------------------->

                <!---- Waiting period Condition Accordian ---->
                <h5 class="bs-left-form-label accordheading waitingheading" (click)="waitingPeriodCondition()">Waiting Period Condition
                    <img id="WPC_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="WPC_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/waiting.png" class="headicon" />
                    <img id="waitingtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="waitingtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="Progwaiting">
                        <div class="value-container" id="Valuewaiting">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="waitingPeriodCondition_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Waiting period Condition</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="thirty_days_waiting_period">30 Days Waiting Period <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'thirty_days_waiting_period_div')" (mouseleave)="mouseLeave($event, 'thirty_days_waiting_period_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="thirty_days_waiting_period_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">30 Days Waiting Period</p>
                                        </div>
                                    </div>                                    
                                    <div class="switch-field wWaiting">                                        
                                        <input type="radio" id="radio-two_thirty_days_waiting_period" formControlName="thirty_days_waiting_period" name="thirty_days_waiting_period" value="Not Applicable" (change)="checkradio('divthirty_days_waiting_period', 'NotCovered'); red_green_change('thirty_days_waiting_period', 'no')" />
                                        <label for="radio-two_thirty_days_waiting_period" id="thirty_days_waiting_period_no" class="bs-form-label">Not Applicable</label>	
                                        <input type="radio" id="radio-one_thirty_days_waiting_period" formControlName="thirty_days_waiting_period" name="thirty_days_waiting_period" value="Applicable" (change)="checkradio('divthirty_days_waiting_period', 'Covered'); red_green_change('thirty_days_waiting_period', 'yes')" />
                                        <label for="radio-one_thirty_days_waiting_period" id="thirty_days_waiting_period_yes" class="bs-form-label">Applicable</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="one_and_half_days_waiting_period">1 Year or 2 Years Waiting Period <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'one_and_half_days_waiting_period_div')" (mouseleave)="mouseLeave($event, 'one_and_half_days_waiting_period_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="one_and_half_days_waiting_period_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">1 Year or 2 Years Waiting Period</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wWaiting">                                        
                                        <input type="radio" id="radio-two_one_and_half_days_waiting_period" formControlName="one_and_half_days_waiting_period" name="one_and_half_days_waiting_period" value="Not Applicable" (change)="checkradio('divone_and_half_days_waiting_period', 'NotCovered'); red_green_change('one_and_half_days_waiting_period', 'no')" />
                                        <label for="radio-two_one_and_half_days_waiting_period" id="one_and_half_days_waiting_period_no" class="bs-form-label">Not Applicable</label>	
                                        <input type="radio" id="radio-one_one_and_half_days_waiting_period" formControlName="one_and_half_days_waiting_period" name="one_and_half_days_waiting_period" value="Applicable" (change)="checkradio('divone_and_half_days_waiting_period', 'Covered'); red_green_change('one_and_half_days_waiting_period', 'yes')" />
                                        <label for="radio-one_one_and_half_days_waiting_period" id="one_and_half_days_waiting_period_yes" class="bs-form-label">Applicable</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="pre_existing_condition">Pre-Existing Condtion <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'pre_existing_condition_div')" (mouseleave)="mouseLeave($event, 'pre_existing_condition_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="pre_existing_condition_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Pre-Existing Condtion</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wWaiting">                                        
                                        <input type="radio" id="radio-two_pre_existing_condition" formControlName="pre_existing_condition" name="pre_existing_condition" value="Not Applicable" (change)="checkradio('divpre_existing_condition', 'NotCovered'); red_green_change('pre_existing_condition', 'no')" />
                                        <label for="radio-two_pre_existing_condition" id="pre_existing_condition_no" class="bs-form-label">Not Applicable</label>	
                                        <input type="radio" id="radio-one_pre_existing_condition" formControlName="pre_existing_condition" name="pre_existing_condition" value="Applicable" (change)="checkradio('divpre_existing_condition', 'Covered'); red_green_change('pre_existing_condition', 'yes')" />
                                        <label for="radio-one_pre_existing_condition" id="pre_existing_condition_yes" class="bs-form-label">Applicable</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->

                <!------- Hospitalisation Accordian --------->      
                <h5 class="bs-left-form-label accordheading hospiheading" (click)="hospitalisationaccord()">Hospitalisation Details
                    <img id="hospitalisationaccord_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="hospitalisationaccord_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />                    
                    <img src="../../assets/img/policy/hospitalization.png" class="headicon" />
                    <img id="hosptick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="hosptick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="Proghosp">
                        <div class="value-container" id="Valuehosp">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="hospitalisationaccord_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Hospitalisation details</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                 
                            <!---------------->
                            <div class="col-sm-12 form-group mt-3 is_standard_room_rent">
                                <div class="col-sm-12 form-group">
                                    <div class="form-group">                                        
                                        <label class="bs-form-label" *ngIf="no_flow_flag == true" for="is_standard_room_rent">Is Your Room Rent Condition Standard For all Sum Insured Grades?</label>
                                        <label class="bs-form-label" *ngIf="no_flow_flag == false" for="is_standard_room_rent">Will Room Rent Condition Standard For all Sum Insured Grades?</label>
                                    </div>
                                    <div class="switch-field wFC">                                        
                                        <input type="radio" id="radio-two_is_standard_room_rent" formControlName="is_standard_room_rent" name="is_standard_room_rent" value="No" (click)="is_standard('no'); green_red_change('is_standard_room_rent','no')" />
                                        <label for="radio-two_is_standard_room_rent" id="is_standard_room_rent_no" class="bs-form-label">No</label>	
                                        <input type="radio" id="radio-one_is_standard_room_rent" formControlName="is_standard_room_rent" name="is_standard_room_rent" value="Yes" (click)="is_standard('yes'); green_red_change('is_standard_room_rent','yes')" />
                                        <label for="radio-one_is_standard_room_rent" id="is_standard_room_rent_yes" class="bs-form-label">Yes</label>
                                    </div>
                                    <p class="error" id="is_standard_room_rent_error" style="display:none">
                                        Room rent condition is required
                                    </p>
                                </div>                                
                            </div>

                            <div class="col-sm-12 form-group mt-2" id="is_standard_active" style="display:none">
                                <div class="row">
                                    <div class="col-sm-6 form-group">
                                        <label class="bs-form-label" for="room_rent_condition_one">Normal Room Rent *</label>                                                
                                        <select class="form-select room_rent_condition_one" formControlName="room_rent_condition_one" id="room_rent_condition_one" style="line-height: 1.7;" (change)="checkopt_table_normal('room_rent_condition_one', 'hosp')">
                                            <option value="0">Select</option>
                                            <option value="1% of SI for Normal">1% of SI for Normal</option>
                                            <option value="2% of SI for Normal">2% of SI for Normal</option>
                                            <option value="3% of SI for Normal">3% of SI for Normal</option>
                                            <option value="4% of SI for Normal">4% of SI for Normal</option>
                                            <option value="5% of SI for Normal">5% of SI for Normal</option>
                                            <option value="Single private AC Room">Single Private AC Room</option>
                                            <option value="No Capping">No Capping</option>
                                            <option value="Others Please specify">Others Please specify</option>
                                        </select>
                                        <p class="error" id="room_rent_condition_one_error" style="display:none">
                                            Normal Room Rent is required
                                        </p>
                                    </div>
                                                                        
                                    <div class="col-sm-6 form-group">
                                        <label class="bs-form-label" for="c_section_limit">ICU Room Rent *</label>                                                
                                        <select class="form-select ICU_room_rent" formControlName="ICU_room_rent" id="ICU_room_rent" style="line-height: 1.7;" (change)="checkopt_table_normal('ICU_room_rent', 'ICU')">
                                            <option value="0" selected>Select</option>
                                            <option value="2% of SI for ICU">2% of SI for ICU</option>
                                            <option value="3% of SI for ICU">3% of SI for ICU</option>
                                            <option value="4% of SI for ICU">4% of SI for ICU</option>
                                            <option value="5% of SI for ICU">5% of SI for ICU</option>
                                            <option value="No Capping">No Capping</option>
                                            <option value="Others Please specify">Others Please specify</option>
                                        </select>
                                        <p class="error" id="ICU_room_rent_error" style="display:none">
                                            Normal Room Rent is required
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!----------------->

                            <!------------- TABLE and Accordian HOSP ------------------->
                            <div class="mt-3 is_standard_room_rent" *ngIf="mygradedarray.length > 0" style="display:none" id="hospitalizationTablediv">                                                                

                                <div class="col-sm-12 pb-2" id="hospitalizationTable" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                        
                                    <div class="row m-0 p-1 TableAccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        <div class="col-sm-4">
                                            Sum Insured
                                        </div>
                                        <div class="col-sm-4">
                                            Normal Room Rent
                                        </div>
                                        <div class="col-sm-4">
                                            ICU Room Rent
                                        </div>
                                    </div>

                                    <div class="row my-2 mx-0" *ngFor="let group of mygradedarray; let i = index">

                                        <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" (click)="Mobileaccord(i)" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                            Sum Insured - {{group}}
                                            <!-- <img id="MobileCover_down_arrow_{{i}}" src="../../assets/img/common icons/arrow-down.png" class="arrowup" style="margin-top: 3px;" />
                                            <img id="MobileCover_up_arrow_{{i}}" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" style="margin-top: 3px;" />	 -->
                                        </h5>

                                        <div id="MobileCover_accord_{{i}}" class="mobaccordDiv">

                                            <div class="col-sm-4 TableAccord">
                                                <div class="form-group"  style="width: 100%;">
                                                    <label class="bs-form-label my-2 MobileAccord">Sum Insured</label>                                                
                                                    <input type="text" value="{{group}}" id="number_separator_{{i}}" class="number_separator" readonly />
                                                </div>
                                            </div>
                                            <!-- <hr class="my-2 MobileAccord"> -->
                                            <div class="col-sm-4">
                                                <div class="form-group" id="checkopt_table_room_rent_condition_one_{{i}}">
                                                    <label class="bs-form-label my-2 MobileAccord">Normal Room Rent</label>
                                                    <select class="form-select room_rent_condition_one" id="room_rent_condition_one_{{i}}" style="line-height: 1.7;" (change)="checkopt_table('room_rent_condition_one_'+i, 'hosp')">
                                                        <option value="0">Select</option>
                                                        <option value="1% of SI for Normal">1% of SI for Normal</option>
                                                        <option value="2% of SI for Normal">2% of SI for Normal</option>
                                                        <option value="3% of SI for Normal">3% of SI for Normal</option>
                                                        <option value="4% of SI for Normal">4% of SI for Normal</option>
                                                        <option value="5% of SI for Normal">5% of SI for Normal</option>
                                                        <option value="Single private AC Room">Single Private AC Room</option>
                                                        <option value="No Capping">No Capping</option>
                                                        <option value="Others Please specify">Others Please specify</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- <hr class="my-2 MobileAccord"> -->
                                            <div class="col-sm-4">
                                                <div class="form-group" id="checkopt_table_ICU_room_rent_{{i}}">
                                                    <label class="bs-form-label my-2 MobileAccord">ICU Room Rent</label>
                                                    <select class="form-select ICU_room_rent" id="ICU_room_rent_{{i}}" style="line-height: 1.7;" (change)="checkopt_table('ICU_room_rent_'+i, 'ICU')">
                                                        <option value="0" selected>Select</option>
                                                        <option value="2% of SI for ICU">2% of SI for ICU</option>
                                                        <option value="3% of SI for ICU">3% of SI for ICU</option>
                                                        <option value="4% of SI for ICU">4% of SI for ICU</option>
                                                        <option value="5% of SI for ICU">5% of SI for ICU</option>
                                                        <option value="No Capping">No Capping</option>
                                                        <option value="Others Please specify">Others Please specify</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        

                                    </div>
                                    
                                </div>

                            </div>
                            <!--------------- END ---------------------->                            
                                
                            <div class="col-sm-12 form-group mt-3">
                                <label class="bs-form-label" for="pre_post_hospitalisation">Pre and Post hospitalisation cover *</label>
                                <select class="form-select" formControlName="pre_post_hospitalisation" id="pre_post_hospitalisation" style="line-height: 1.7;" (change)="checkopt('pre_post_hospitalisation')"
                                [ngClass]="{'control-red': policyFeaturesSubmitted && getpolicyFeaturesFormControls.pre_post_hospitalisation.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="30 days pre and 60 days post hospitalization">30 days pre and 60 days post hospitalization</option>
                                    <option value="45 days pre and 90 days post hospitalization">45 days pre and 90 days post hospitalization</option>
                                    <option value="60 days pre and 120 days post hospitalization">60 days pre and 120 days post hospitalization</option>
                                    <option value="Others Please specify">Others Please specify</option>
                                    <!-- <option *ngFor="let country of countryCodes" [value]="country.abbreviation">{{ country.abbreviation }}</option> -->
                                </select>
                                <ng-container
                                    *ngIf="policyFeaturesSubmitted && getpolicyFeaturesFormControls.pre_post_hospitalisation.errors">
                                    <p class="error">
                                    Pre and Post hospitalisation is required
                                    </p>
                                </ng-container>
                            </div>                            

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group p-0">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="day_care_procedures">Day care procedures <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'day_care_procedures_div')" (mouseleave)="mouseLeave($event, 'day_care_procedures_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="day_care_procedures_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Day care procedures</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_day_care_procedures" formControlName="day_care_procedures" name="day_care_procedures" name="day_care_procedures" value="Not Covered" checked (change)="checkradio('divday_care_procedures_limit', 'NotCovered'); green_red_change('day_care_procedures','no')" />
                                        <label for="radio-two_day_care_procedures" id="day_care_procedures_no" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_day_care_procedures" formControlName="day_care_procedures" name="day_care_procedures" value="Covered" (change)="checkradio('divday_care_procedures_limit', 'Covered'); green_red_change('day_care_procedures','yes')" />
                                        <label for="radio-one_day_care_procedures" id="day_care_procedures_yes" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <!-- <div class="col-sm-9 form-group" id="divday_care_procedures_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="day_care_procedures_limit_perc" id="day_care_procedures_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="day_care_procedures_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>                                        

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_day_care_procedures" value="min_day_care_procedures" formControlName="min_max_day_care_procedures" name="min_max_day_care_procedures" type="radio" (click)="check_limit_toggle('day_care_procedures','yes')" />
                                                <label for="min_day_care_procedures" >Min</label>   

                                                <input class="hiddenRadio" id="na_day_care_procedures" value="na_day_care_procedures" formControlName="min_max_day_care_procedures" name="min_max_day_care_procedures" type="radio" (click)="check_limit_toggle('day_care_procedures','no')" />
                                                <label for="na_day_care_procedures" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_day_care_procedures" value="max_day_care_procedures" formControlName="min_max_day_care_procedures" name="min_max_day_care_procedures" type="radio" (click)="check_limit_toggle('day_care_procedures','yes')" />
                                                <label for="max_day_care_procedures" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 day_care_procedures_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="day_care_procedures_limit_value" id="day_care_procedures_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="day_care_procedures_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>                                        

                                    </div>
                                </div>                                 -->
                            </div>
                            
                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group p-0">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="domiciliary_hospitalisation">Domiciliary hospitalisation <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'domiciliary_hospitalisation_div')" (mouseleave)="mouseLeave($event, 'domiciliary_hospitalisation_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="domiciliary_hospitalisation_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Domiciliary hospitalisation</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_domiciliary_hospitalisation" formControlName="domiciliary_hospitalisation" name="domiciliary_hospitalisation" name="domiciliary_hospitalisation" value="Not Covered" checked (change)="checkradio('divdomiciliary_hospitalisation_limit', 'NotCovered'); green_red_change('domiciliary_hospitalisation', 'no')" />
                                        <label for="radio-two_domiciliary_hospitalisation" id="domiciliary_hospitalisation_no" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_domiciliary_hospitalisation" formControlName="domiciliary_hospitalisation" name="domiciliary_hospitalisation" value="Covered" (change)="checkradio('divdomiciliary_hospitalisation_limit', 'Covered'); green_red_change('domiciliary_hospitalisation', 'yes')" />
                                        <label for="radio-one_domiciliary_hospitalisation" id="domiciliary_hospitalisation_yes" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divdomiciliary_hospitalisation_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="domiciliary_hospitalisation_limit_perc" id="domiciliary_hospitalisation_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="domiciliary_hospitalisation_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_domiciliary_hospitalisation" value="min_domiciliary_hospitalisation" formControlName="min_max_domiciliary_hospitalisation" name="min_max_domiciliary_hospitalisation" type="radio" (click)="check_limit_toggle('domiciliary_hospitalisation','yes')" />
                                                <label for="min_domiciliary_hospitalisation" >Min</label>   

                                                <input class="hiddenRadio" id="na_domiciliary_hospitalisation" value="na_domiciliary_hospitalisation" formControlName="min_max_domiciliary_hospitalisation" name="min_max_domiciliary_hospitalisation" type="radio" (click)="check_limit_toggle('domiciliary_hospitalisation','no')" />
                                                <label for="na_domiciliary_hospitalisation" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_domiciliary_hospitalisation" value="max_domiciliary_hospitalisation" formControlName="min_max_domiciliary_hospitalisation" name="min_max_domiciliary_hospitalisation" type="radio" (click)="check_limit_toggle('domiciliary_hospitalisation','yes')" />
                                                <label for="max_domiciliary_hospitalisation" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 domiciliary_hospitalisation_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="domiciliary_hospitalisation_limit_value" id="domiciliary_hospitalisation_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="domiciliary_hospitalisation_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                        </div>
                    </div>
                </div>
                <!----------------------------->

                <!---- Maternity Coverage Accordian ---->
                <div id="root_maternity">
                
                    <h5 class="bs-left-form-label accordheading maternityheading" (click)="maternityccord()">Maternity Coverage
                        <img id="maternityaccord_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                        <img id="maternityaccord_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                        <img src="../../assets/img/policy/maternity.png" class="headicon" />                        
                        <img id="maternitytick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                        <img id="maternitytick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                        <div class="circular-progress" id="Progmaternity">
                            <div class="value-container" id="Valuematernity">0%</div>
                        </div>
                        <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                    </h5>
                    <div id="maternity_accord" class="accord_div">
                        <div class="row">
                            <!-- <div class="col-sm-4"> -->
                                <!-- <h5 class="bs-left-form-label">Maternity Coverage</h5> -->
                                <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                            </div> -->

                            <div class="col-sm-12">
                                <div class="row">

                                    <div class="col-sm-12 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="do_you_want_maternity">Do you want maternity cover? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_want_maternity_div')" (mouseleave)="mouseLeave($event, 'do_you_want_maternity_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="do_you_want_maternity_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Do you want maternity cover?</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-one_do_you_want_maternity" formControlName="do_you_want_maternity" (click)="do_you_want_maternity('no'); green_red_change('do_you_want_maternity', 'no')"  name="do_you_want_maternity" value="No" />
                                            <label for="radio-one_do_you_want_maternity" id="do_you_want_maternity_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-two_do_you_want_maternity" formControlName="do_you_want_maternity" (click)="do_you_want_maternity('yes'); green_red_change('do_you_want_maternity', 'yes')" name="do_you_want_maternity" value="Yes" />
                                            <label for="radio-two_do_you_want_maternity" id="do_you_want_maternity_yes" class="bs-form-label">Yes</label>	
                                        </div>
                                        <p class="error" id="do_you_want_maternity_error" style="display:none">
                                            Maternity cover is required field
                                        </p>
                                    </div>

                                    <div class="col-sm-12 do_you_want_maternity_yesdiv" style="display: none">                                    

                                        <div class="col-sm-12 form-group mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="nine_month_waiting_period">Nine month waiting period <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'nine_month_waiting_period_div')" (mouseleave)="mouseLeave($event, 'nine_month_waiting_period_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="nine_month_waiting_period_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Nine month waiting period</p>
                                                </div>
                                            </div>
                                            <div class="switch-field ninemonth">
                                                <input type="radio" id="radio-one_nine_month_waiting_period" formControlName="nine_month_waiting_period" name="nine_month_waiting_period" value="Applicable" />
                                                <label for="radio-one_nine_month_waiting_period" class="bs-form-label">Applicable</label>
                                                <input type="radio" id="radio-two_nine_month_waiting_period" formControlName="nine_month_waiting_period" name="nine_month_waiting_period" value="Waived Off" />
                                                <label for="radio-two_nine_month_waiting_period" class="bs-form-label">Waived Off</label>	
                                            </div>
                                        </div>

                                        <!---------------->
                                        <div class="col-sm-12 form-group mt-3 is_standard_room_rent_maternity">
                                            <div class="col-sm-12 form-group">
                                                <div class="form-group">
                                                    <label class="bs-form-label" *ngIf="no_flow_flag == true" for="is_standard_room_rent_maternity">Is Your maternity limit Standard For all Sum Insured Grades?</label>
                                                    <label class="bs-form-label" *ngIf="no_flow_flag == false" for="is_standard_room_rent_maternity">Will maternity limit Standard For all Sum Insured Grades?</label>
                                                    <!-- <label class="bs-form-label" for="is_standard_room_rent_maternity">Is Your Room Rent Condition Standard For all Sum Insured Grades?</label> -->
                                                </div>
                                                <div class="switch-field wFC">                                            
                                                    <input type="radio" id="radio-two_is_standard_room_rent_maternity" formControlName="is_standard_room_rent_maternity" name="is_standard_room_rent_maternity" value="No" (click)="is_standard_maternity('no'); green_red_change('is_standard_room_rent_maternity', 'no')" />
                                                    <label for="radio-two_is_standard_room_rent_maternity" id="is_standard_room_rent_maternity_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_is_standard_room_rent_maternity" formControlName="is_standard_room_rent_maternity" name="is_standard_room_rent_maternity" value="Yes" (click)="is_standard_maternity('yes'); green_red_change('is_standard_room_rent_maternity', 'yes')" />
                                                    <label for="radio-one_is_standard_room_rent_maternity" id="is_standard_room_rent_maternity_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>    
                                            <p class="error" id="is_standard_room_rent_maternity_error" style="display:none">
                                                Maternity limit standard is required
                                            </p>                            
                                        </div>

                                        <div class="col-sm-12 form-group mt-2" id="maternity_is_standard_active" style="display:none">
                                            <div class="row">
                                                <div class="col-sm-6 form-group">
                                                    <label class="bs-form-label" for="normal_delivery_limit_maternity">Normal delivery limit</label>                                                
                                                    <input type="text" class="form-control" formControlName="normal_delivery_limit_maternity" id="normal_delivery_limit_maternity" (keypress)="isNumber($event)" (input)="isformat($event)" />                                                                                                                
                                                    <p class="error" id="normal_delivery_limit_maternity_error" style="display:none">
                                                        Normal delivery limit is required
                                                    </p>
                                                </div>
                                                                                    
                                                <div class="col-sm-6 form-group">
                                                    <label class="bs-form-label" for="c_section_limit_maternity">C section limit</label>                                                
                                                    <input type="text" class="form-control" formControlName="c_section_limit_maternity" id="c_section_limit_maternity" (keypress)="isNumber($event)" (input)="isformat($event)" />                                                                                                                
                                                    <p class="error" id="c_section_limit_maternity_error" style="display:none">
                                                        C section limit is required
                                                    </p>
                                                </div>
                                            </div>                                        
                                        </div>
                                        <!----------------->

                                        <!------------- TABLE and Accordian Maternity ------------------->
                                        <div class="mt-3 is_standard_room_rent_maternity" *ngIf="mygradedarray.length > 0" style="display:none" id="maternitytable_div">                                                                

                                            <div class="col-sm-12 pb-2" id="hospitalizationTable" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                                    
                                                <div class="row m-0 p-1 TableAccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                                    <div class="col-sm-4">
                                                        Sum Insured
                                                    </div>
                                                    <div class="col-sm-4">
                                                        Normal delivery limit
                                                    </div>
                                                    <div class="col-sm-4">
                                                        C section limit
                                                    </div>
                                                </div>

                                                <div class="row my-2 mx-0" *ngFor="let group of mygradedarray; let i = index">

                                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" (click)="Mobileaccord(i)" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                                        Sum Insured - {{group}}
                                                        <!-- <img id="MobileCover_down_arrow_{{i}}" src="../../assets/img/common icons/arrow-down.png" class="arrowup" style="margin-top: 3px;" />
                                                        <img id="MobileCover_up_arrow_{{i}}" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" style="margin-top: 3px;" />	 -->
                                                    </h5>

                                                    <div id="MobileCover_accord_{{i}}" class="mobaccordDiv">

                                                        <div class="col-sm-4 TableAccord">
                                                            <div class="form-group"  style="width: 100%;">
                                                                <label class="bs-form-label my-2 MobileAccord">Sum Insured</label>                                                
                                                                <input type="text" value="{{group}}" id="number_separator_maternity_{{i}}" class="number_separator" readonly />
                                                            </div>
                                                        </div>
                                                        <!-- <hr class="my-2 MobileAccord"> -->
                                                        <div class="col-sm-4">
                                                            <div class="form-group" id="checkopt_table_room_rent_condition_one_{{i}}">
                                                                <label class="bs-form-label my-2 MobileAccord">Normal delivery limit</label>
                                                                    <input type="text" class="form-control" formControlName="normal_delivery_limit_{{i}}" id="normal_delivery_limit_{{i}}" (change)="checkopt_table('normal_delivery_limit_'+i, 'normal_delivery_limit')" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                            </div>
                                                        </div>
                                                        <!-- <hr class="my-2 MobileAccord"> -->
                                                        <div class="col-sm-4">
                                                            <div class="form-group" id="checkopt_table_ICU_room_rent_{{i}}">
                                                                <label class="bs-form-label my-2 MobileAccord">C section limit</label>
                                                                    <input type="text" class="form-control" formControlName="c_section_limit_{{i}}" id="c_section_limit_{{i}}" (change)="checkopt_table('c_section_limit_'+i, 'c_section_limit')" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    

                                                </div>
                                                
                                            </div>

                                        </div>
                                        <!--------------- END ---------------------->

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="room_rent_natal_cover">Pre and post natal cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'room_rent_natal_cover_div')" (mouseleave)="mouseLeave($event, 'room_rent_natal_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="room_rent_natal_cover_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Pre and post natal cover</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_room_rent_natal_cover" formControlName="room_rent_natal_cover" name="room_rent_natal_cover" value="Not Covered" (change)="checkradio('divroom_rent_natal_cover', 'NotCovered'); green_red_change('room_rent_natal_cover', 'no')" />
                                                    <label for="radio-two_room_rent_natal_cover" id="room_rent_natal_cover_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_room_rent_natal_cover" formControlName="room_rent_natal_cover" name="room_rent_natal_cover" value="Covered" (change)="checkradio('divroom_rent_natal_cover', 'Covered'); green_red_change('room_rent_natal_cover', 'yes')" />
                                                    <label for="radio-one_room_rent_natal_cover" id="room_rent_natal_cover_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divroom_rent_natal_cover" style="display: none">
                                                <label class="bs-form-label" for="room_rent_natal_cover_isCover">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_room_rent_natal_cover_isCover" formControlName="room_rent_natal_cover_isCover" name="room_rent_natal_cover_isCover" value="within maternity limit" />
                                                    <label for="radio-two_room_rent_natal_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_room_rent_natal_cover_isCover" formControlName="room_rent_natal_cover_isCover" name="room_rent_natal_cover_isCover" value="Over and above" />
                                                    <label for="radio-one_room_rent_natal_cover_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>		                                            								
                                            </div>
                                            <div class="col-sm-3 form-group divroom_rent_natal_cover" style="display: none">
                                                <label class="bs-form-label" for="room_rent_natal_cover_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="room_rent_natal_cover_limit" id="room_rent_natal_cover_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />                                            
                                                <p class="error" id="room_rent_natal_cover_limit_error" style="display:none; margin-bottom: -10px;;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="bay_day_one">Baby Day one <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'bay_day_one_div')" (mouseleave)="mouseLeave($event, 'bay_day_one_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="bay_day_one_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Baby day one</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_bay_day_one" formControlName="bay_day_one" name="bay_day_one" value="Not Covered" (change)="checkradio('divbay_day_one', 'NotCovered'); green_red_change('bay_day_one', 'no')" />
                                                    <label for="radio-two_bay_day_one" id="bay_day_one_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_bay_day_one" formControlName="bay_day_one" name="bay_day_one" value="Covered" (change)="checkradio('divbay_day_one', 'Covered'); green_red_change('bay_day_one', 'yes')" />
                                                    <label for="radio-one_bay_day_one" id="bay_day_one_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divbay_day_one" style="display: none">
                                                <label class="bs-form-label" for="bay_day_one_isCover">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_bay_day_one_isCover" formControlName="bay_day_one_isCover" name="bay_day_one_isCover" value="within maternity limit" />
                                                    <label for="radio-two_bay_day_one_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_bay_day_one_isCover" formControlName="bay_day_one_isCover" name="bay_day_one_isCover" value="Over and above" />
                                                    <label for="radio-one_bay_day_one_isCover" class="bs-form-label">Over and above</label>                                        	                                                										
                                                </div>                            
                                            </div>
                                            <div class="col-sm-3 form-group divbay_day_one" style="display: none">
                                                <label class="bs-form-label" for="baby_specific_covered_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="baby_specific_covered_limit" id="baby_specific_covered_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="baby_specific_covered_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="well_baby_cover">Well baby cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'well_baby_cover_div')" (mouseleave)="mouseLeave($event, 'well_baby_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="well_baby_cover_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Well baby cover</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_well_baby_cover" formControlName="well_baby_cover" name="well_baby_cover" value="Not Covered" (change)="checkradio('divwell_baby_cover', 'NotCovered'); green_red_change('well_baby_cover', 'no')" />
                                                    <label for="radio-two_well_baby_cover" id="well_baby_cover_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_well_baby_cover" formControlName="well_baby_cover" name="well_baby_cover" value="Covered" (change)="checkradio('divwell_baby_cover', 'Covered'); green_red_change('well_baby_cover', 'yes')" />
                                                    <label for="radio-one_well_baby_cover" id="well_baby_cover_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divwell_baby_cover" style="display: none">
                                                <label class="bs-form-label" for="well_baby_cover_isCover">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_well_baby_cover_isCover" formControlName="well_baby_cover_isCover" name="well_baby_cover_isCover" value="within maternity limit" />
                                                    <label for="radio-two_well_baby_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_well_baby_cover_isCover" formControlName="well_baby_cover_isCover" name="well_baby_cover_isCover" value="Over and above" />
                                                    <label for="radio-one_well_baby_cover_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divwell_baby_cover" style="display: none">
                                                <label class="bs-form-label" for="well_baby_covered_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="well_baby_covered_limit" id="well_baby_covered_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="well_baby_covered_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="infertility_cover">Infertility Cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'infertility_cover_div')" (mouseleave)="mouseLeave($event, 'infertility_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="infertility_cover_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Infertility cover</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_infertility_cover" formControlName="infertility_cover" name="infertility_cover" value="Not Covered" (change)="checkradio('divinfertility_cover', 'NotCovered'); green_red_change('infertility_cover', 'no')" />
                                                    <label for="radio-two_infertility_cover" id="infertility_cover_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_infertility_cover" formControlName="infertility_cover" name="infertility_cover" value="Covered" (change)="checkradio('divinfertility_cover', 'Covered'); green_red_change('infertility_cover', 'yes')" />
                                                    <label for="radio-one_infertility_cover" id="infertility_cover_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divinfertility_cover" style="display: none">
                                                <label class="bs-form-label" for="infertility_cover_isCover">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_infertility_cover_isCover" formControlName="infertility_cover_isCover" name="infertility_cover_isCover" value="within maternity limit" />
                                                    <label for="radio-two_infertility_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_infertility_cover_isCover" formControlName="infertility_cover_isCover" name="infertility_cover_isCover" value="Over and above" />
                                                    <label for="radio-one_infertility_cover_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divinfertility_cover" style="display: none">
                                                <label class="bs-form-label" for="infertility_covered_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="infertility_covered_limit" id="infertility_covered_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="infertility_covered_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="surrogacy_cover">Surrogacy cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'surrogacy_cover_div')" (mouseleave)="mouseLeave($event, 'surrogacy_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="surrogacy_cover_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Surrogacy cover</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_surrogacy_cover" formControlName="surrogacy_cover" name="surrogacy_cover" value="Not Covered" (change)="checkradio('divsurrogacy_cover', 'NotCovered'); green_red_change('surrogacy_cover', 'no')" />
                                                    <label for="radio-two_surrogacy_cover" id="surrogacy_cover_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_surrogacy_cover" formControlName="surrogacy_cover" name="surrogacy_cover" value="Covered" (change)="checkradio('divsurrogacy_cover', 'Covered'); green_red_change('surrogacy_cover', 'yes')" />
                                                    <label for="radio-one_surrogacy_cover" id="surrogacy_cover_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divsurrogacy_cover" style="display: none">
                                                <label class="bs-form-label" for="surrogacy_cover_isCover">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_surrogacy_cover_isCover" formControlName="surrogacy_cover_isCover" name="surrogacy_cover_isCover" value="within maternity limit" />
                                                    <label for="radio-two_surrogacy_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_surrogacy_cover_isCover" formControlName="surrogacy_cover_isCover" name="surrogacy_cover_isCover" value="Over and above" />
                                                    <label for="radio-one_surrogacy_cover_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divsurrogacy_cover" style="display: none">
                                                <label class="bs-form-label" for="surrogacy_covered_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="surrogacy_covered_limit" id="surrogacy_covered_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="surrogacy_covered_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="maternity_new_born_vaccination_yes">New born vaccination <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'maternity_new_born_vaccination_yes_div')" (mouseleave)="mouseLeave($event, 'maternity_new_born_vaccination_yes_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="maternity_new_born_vaccination_yes_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">New born vaccination</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_maternity_new_born_vaccination_yes" formControlName="maternity_new_born_vaccination_yes" name="maternity_new_born_vaccination_yes" value="Not Covered" (change)="checkradio('divmaternity_new_born_vaccination_yes', 'NotCovered'); green_red_change('maternity_new_born_vaccination_yes', 'no')" />
                                                    <label for="radio-two_maternity_new_born_vaccination_yes" id="maternity_new_born_vaccination_yes_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_maternity_new_born_vaccination_yes" formControlName="maternity_new_born_vaccination_yes" name="maternity_new_born_vaccination_yes" value="Covered" (change)="checkradio('divmaternity_new_born_vaccination_yes', 'Covered'); green_red_change('maternity_new_born_vaccination_yes', 'yes')" />
                                                    <label for="radio-one_maternity_new_born_vaccination_yes" id="maternity_new_born_vaccination_yes_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divmaternity_new_born_vaccination_yes" style="display: none">
                                                <label class="bs-form-label" for="maternity_new_born_vaccination_yes_limit">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_maternity_new_born_vaccination_yes_isCover" formControlName="maternity_new_born_vaccination_yes_isCover" name="maternity_new_born_vaccination_yes_isCover" value="within maternity limit" />
                                                    <label for="radio-two_maternity_new_born_vaccination_yes_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_maternity_new_born_vaccination_yes_isCover" formControlName="maternity_new_born_vaccination_yes_isCover" name="maternity_new_born_vaccination_yes_isCover" value="Over and above" />
                                                    <label for="radio-one_maternity_new_born_vaccination_yes_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divmaternity_new_born_vaccination_yes" style="display: none">
                                                <label class="bs-form-label" for="maternity_new_born_vaccination_yes_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="maternity_new_born_vaccination_yes_limit" id="maternity_new_born_vaccination_yes_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="maternity_new_born_vaccination_yes_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="mother_cover">Well Mother cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'mother_cover_div')" (mouseleave)="mouseLeave($event, 'mother_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="mother_cover_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Well Mother cover</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_mother_cover" formControlName="mother_cover" name="mother_cover" value="Not Covered" (change)="checkradio('divmother_cover', 'NotCovered'); green_red_change('mother_cover', 'no')" />
                                                    <label for="radio-two_mother_cover" id="mother_cover_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_mother_cover" formControlName="mother_cover" name="mother_cover" value="Covered" (change)="checkradio('divmother_cover', 'Covered'); green_red_change('mother_cover', 'yes')" />
                                                    <label for="radio-one_mother_cover" id="mother_cover_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divmother_cover" style="display: none">
                                                <label class="bs-form-label" for="mother_cover_limit">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_mother_cover_isCover" formControlName="mother_cover_isCover" name="mother_cover_isCover" value="within maternity limit" />
                                                    <label for="radio-two_mother_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_mother_cover_isCover" formControlName="mother_cover_isCover" name="mother_cover_isCover" value="Over and above" />
                                                    <label for="radio-one_mother_cover_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divmother_cover" style="display: none">
                                                <label class="bs-form-label" for="mother_cover_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="mother_cover_limit" id="mother_cover_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="mother_cover_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="maternity_related_complications_yes">Maternity Related Complications <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'maternity_related_complications_yes_div')" (mouseleave)="mouseLeave($event, 'maternity_related_complications_yes_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="maternity_related_complications_yes_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Maternity Related Complications</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_maternity_related_complications_yes" formControlName="maternity_related_complications_yes" name="maternity_related_complications_yes" value="Not Covered" (change)="checkradio('divmaternity_related_complications_yes', 'NotCovered'); green_red_change('maternity_related_complications_yes', 'no')" />
                                                    <label for="radio-two_maternity_related_complications_yes" id="maternity_related_complications_yes_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_maternity_related_complications_yes" formControlName="maternity_related_complications_yes" name="maternity_related_complications_yes" value="Covered" (change)="checkradio('divmaternity_related_complications_yes', 'Covered'); green_red_change('maternity_related_complications_yes', 'yes')" />
                                                    <label for="radio-one_maternity_related_complications_yes" id="maternity_related_complications_yes_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-8 form-group divmaternity_related_complications_yes" style="display: none">
                                                <label class="bs-form-label" for="maternity_related_complications_yes_limit">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height" style="width:250px">
                                                    <input type="radio" id="radio-two_maternity_related_complications_yes_isCover" formControlName="maternity_related_complications_yes_isCover" name="maternity_related_complications_yes_isCover" value="within maternity limit" />
                                                    <label for="radio-two_maternity_related_complications_yes_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_maternity_related_complications_yes_isCover" formControlName="maternity_related_complications_yes_isCover" name="maternity_related_complications_yes_isCover" value="Over and above" />
                                                    <label for="radio-one_maternity_related_complications_yes_isCover" class="bs-form-label">SI limit</label>                                        	
                                                </div>										
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="baby_vaccination_yes">Baby Vaccination <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'baby_vaccination_yes_div')" (mouseleave)="mouseLeave($event, 'baby_vaccination_yes_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="baby_vaccination_yes_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Baby Vaccination</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_baby_vaccination_yes" formControlName="baby_vaccination_yes" name="baby_vaccination_yes" value="Not Covered" (change)="checkradio('divbaby_vaccination_yes', 'NotCovered'); green_red_change('baby_vaccination_yes', 'no')" />
                                                    <label for="radio-two_baby_vaccination_yes" id="baby_vaccination_yes_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_baby_vaccination_yes" formControlName="baby_vaccination_yes" name="baby_vaccination_yes" value="Covered" (change)="checkradio('divbaby_vaccination_yes', 'Covered'); green_red_change('baby_vaccination_yes', 'yes')" />
                                                    <label for="radio-one_baby_vaccination_yes" id="baby_vaccination_yes_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divbaby_vaccination_yes" style="display: none">
                                                <label class="bs-form-label" for="baby_vaccination_yes_limit">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_baby_vaccination_yes_isCover" formControlName="baby_vaccination_yes_isCover" name="baby_vaccination_yes_isCover" value="within maternity limit" />
                                                    <label for="radio-two_baby_vaccination_yes_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_baby_vaccination_yes_isCover" formControlName="baby_vaccination_yes_isCover" name="baby_vaccination_yes_isCover" value="Over and above" />
                                                    <label for="radio-one_baby_vaccination_yes_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divbaby_vaccination_yes" style="display: none">
                                                <label class="bs-form-label" for="baby_vaccination_yes_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="baby_vaccination_yes_limit" id="baby_vaccination_yes_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="baby_vaccination_yes_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>
                                    
                                    </div>

                                    <div class="col-sm-12 do_you_want_maternity_nodiv" style="display: none">

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="maternity_new_born_vaccination_no">New born vaccination <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'maternity_new_born_vaccination_no_div')" (mouseleave)="mouseLeave($event, 'maternity_new_born_vaccination_no_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="maternity_new_born_vaccination_no_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">New born vaccination</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_maternity_new_born_vaccination_no" formControlName="maternity_new_born_vaccination_no" name="maternity_new_born_vaccination_no" value="Not Covered" (change)="checkradio('divmaternity_new_born_vaccination_no', 'NotCovered'); green_red_change('maternity_new_born_vaccination_no', 'no')" />
                                                    <label for="radio-two_maternity_new_born_vaccination_no" id="maternity_new_born_vaccination_no_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_maternity_new_born_vaccination_no" formControlName="maternity_new_born_vaccination_no" name="maternity_new_born_vaccination_no" value="Covered" (change)="checkradio('divmaternity_new_born_vaccination_no', 'Covered'); green_red_change('maternity_new_born_vaccination_no', 'yes')" />
                                                    <label for="radio-one_maternity_new_born_vaccination_no" id="maternity_new_born_vaccination_no_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divmaternity_new_born_vaccination_no" style="display: none">
                                                <label class="bs-form-label" for="maternity_new_born_vaccination_no_limit">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_maternity_new_born_vaccination_no_isCover" formControlName="maternity_new_born_vaccination_no_isCover" name="maternity_new_born_vaccination_no_isCover" value="within maternity limit" />
                                                    <label for="radio-two_maternity_new_born_vaccination_no_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_maternity_new_born_vaccination_no_isCover" formControlName="maternity_new_born_vaccination_no_isCover" name="maternity_new_born_vaccination_no_isCover" value="Over and above" />
                                                    <label for="radio-one_maternity_new_born_vaccination_no_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divmaternity_new_born_vaccination_no" style="display: none">
                                                <label class="bs-form-label" for="maternity_new_born_vaccination_no_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="maternity_new_born_vaccination_no_limit" id="maternity_new_born_vaccination_no_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="maternity_new_born_vaccination_no_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="maternity_related_complications_no">Maternity Related Complications <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'maternity_related_complications_no_div')" (mouseleave)="mouseLeave($event, 'maternity_related_complications_no_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="maternity_related_complications_no_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Maternity Related Complications</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_maternity_related_complications_no" formControlName="maternity_related_complications_no" name="maternity_related_complications_no" value="Not Covered" (change)="checkradio('divmaternity_related_complications_no', 'NotCovered'); green_red_change('maternity_related_complications_no', 'no')" />
                                                    <label for="radio-two_maternity_related_complications_no" id="maternity_related_complications_no_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_maternity_related_complications_no" formControlName="maternity_related_complications_no" name="maternity_related_complications_no" value="Covered" (change)="checkradio('divmaternity_related_complications_no', 'Covered'); green_red_change('maternity_related_complications_no', 'yes')" />
                                                    <label for="radio-one_maternity_related_complications_no" id="maternity_related_complications_no_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-8 form-group divmaternity_related_complications_no" style="display: none">
                                                <label class="bs-form-label" for="two_maternity_related_complications_no_isCover">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height" style="width:250px">
                                                    <input type="radio" id="radio-two_maternity_related_complications_no_isCover" formControlName="maternity_related_complications_no_isCover" name="maternity_related_complications_no_isCover" value="within maternity limit" />
                                                    <label for="radio-two_maternity_related_complications_no_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_maternity_related_complications_no_isCover" formControlName="maternity_related_complications_no_isCover" name="maternity_related_complications_no_isCover" value="Over and above" />
                                                    <label for="radio-one_maternity_related_complications_no_isCover" class="bs-form-label">SI limit</label>                                        	
                                                </div>										
                                            </div>
                                        </div>

                                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                            <div class="col-sm-4 form-group px-0">
                                            <label class="bs-form-label" for="baby_vaccination_no">Baby Vaccination <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'baby_vaccination_no_div')" (mouseleave)="mouseLeave($event, 'baby_vaccination_no_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="baby_vaccination_no_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Baby Vaccination</p>
                                                </div>
                                                <div class="switch-field">
                                                    <input type="radio" id="radio-two_baby_vaccination_no" formControlName="baby_vaccination_no" name="baby_vaccination_no" value="Not Covered" (change)="checkradio('divbaby_vaccination_no', 'NotCovered'); green_red_change('baby_vaccination_no', 'no')" />
                                                    <label for="radio-two_baby_vaccination_no" id="baby_vaccination_no_no" class="bs-form-label">Not Covered</label>
                                                    <input type="radio" id="radio-one_baby_vaccination_no" formControlName="baby_vaccination_no" name="baby_vaccination_no" value="Covered" (change)="checkradio('divbaby_vaccination_no', 'Covered'); green_red_change('baby_vaccination_no', 'yes')" />
                                                    <label for="radio-one_baby_vaccination_no" id="baby_vaccination_no_yes" class="bs-form-label">Covered</label>                                        	
                                                </div>
                                            </div>
                                            <div class="col-sm-5 form-group divbaby_vaccination_no" style="display: none">
                                                <label class="bs-form-label" for="baby_vaccination_no_limit">Is Covered</label>
                                                <div class="switch-field switch-field_mobile_height matcovered">
                                                    <input type="radio" id="radio-two_baby_vaccination_no_isCover" formControlName="baby_vaccination_no_isCover" name="baby_vaccination_no_isCover" value="within maternity limit" />
                                                    <label for="radio-two_baby_vaccination_no_isCover" class="bs-form-label">Witihn maternity limit</label>
                                                    <input type="radio" id="radio-one_baby_vaccination_no_isCover" formControlName="baby_vaccination_no_isCover" name="baby_vaccination_no_isCover" value="Over and above" />
                                                    <label for="radio-one_baby_vaccination_no_isCover" class="bs-form-label">Over and above</label>                                        	
                                                </div>										
                                            </div>
                                            <div class="col-sm-3 form-group divbaby_vaccination_no" style="display: none">
                                                <label class="bs-form-label" for="baby_vaccination_no_limit">Limit *</label>
                                                <input type="text" class="form-control" formControlName="baby_vaccination_no_limit" id="baby_vaccination_no_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="baby_vaccination_no_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>                

                </div>
                <!----------------------------->

                <!---- Claim control measures Accordian ---->
                <h5 class="bs-left-form-label accordheading CCMheading" (click)="ccmaccord()">Claim Control Measures
                    <img id="CCM_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="CCM_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/claim.png" class="headicon" />
                    <img id="CCMtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="CCMtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgCCM">
                        <div class="value-container" id="ValueCCM">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="CCM_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Claim Control Measures</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->                                          
                        
                        <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                            <div class="form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="copay">CoPay <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'copay_div')" (mouseleave)="mouseLeave($event, 'copay_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="copay_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">CoPay</p>
                                    </div>
                                </div>
                                <div class="switch-field wclaim">                                    
                                    <input type="radio" id="radio-two_copay" formControlName="copay" name="copay" value="Not Applicable" (change)="show_family_otp_tr('copay', 'no'); red_green_change('copay', 'no')" />
                                    <label for="radio-two_copay" id="copay_no" class="bs-form-label">Not Applicable</label>                                                                                        
                                    <input type="radio" id="radio-one_copay" formControlName="copay" name="copay" value="Applicable" (change)="show_family_otp_tr('copay', 'yes'); red_green_change('copay', 'yes')" />
                                    <label for="radio-one_copay" id="copay_yes" class="bs-form-label">Applicable</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                            <div class="form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="deductable">Deductible <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'deductable_div')" (mouseleave)="mouseLeave($event, 'deductable_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="deductable_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Deductible</p>
                                    </div>
                                </div>
                                <div class="switch-field wclaim">                                    
                                    <input type="radio" id="radio-two_deductable" formControlName="deductable" name="deductable" value="Not Applicable" (change)="show_family_otp_tr('deductable', 'no'); red_green_change('deductable', 'no')" />
                                    <label for="radio-two_deductable" id="deductable_no" class="bs-form-label">Not Applicable</label>                                                                                        
                                    <input type="radio" id="radio-one_deductable" formControlName="deductable" name="deductable" value="Applicable" (change)="show_family_otp_tr('deductable', 'yes'); red_green_change('deductable', 'yes')" />
                                    <label for="radio-one_deductable" id="deductable_yes" class="bs-form-label">Applicable</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                            <div class="form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="sum_incured_sub_limit">Sum Insured Sub Limit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sum_incured_sub_limit_div')" (mouseleave)="mouseLeave($event, 'sum_incured_sub_limit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="sum_incured_sub_limit_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Sum incured sub limit</p>
                                    </div>
                                </div>
                                <div class="switch-field wclaim">                                    
                                    <input type="radio" id="radio-two_sum_incured_sub_limit" formControlName="sum_incured_sub_limit" name="sum_incured_sub_limit" value="Not Applicable" (change)="show_family_otp_tr('sum_incured_sub_limit', 'no'); red_green_change('sum_incured_sub_limit', 'no')" />
                                    <label for="radio-two_sum_incured_sub_limit" id="sum_incured_sub_limit_no" class="bs-form-label">Not Applicable</label>                                                                                        
                                    <input type="radio" id="radio-one_sum_incured_sub_limit" formControlName="sum_incured_sub_limit" name="sum_incured_sub_limit" value="Applicable" (change)="show_family_otp_tr('sum_incured_sub_limit', 'yes'); red_green_change('sum_incured_sub_limit', 'yes')" />
                                    <label for="radio-one_sum_incured_sub_limit" id="sum_incured_sub_limit_yes" class="bs-form-label">Applicable</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                            <div class="form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="claim_diseasewisecapping_condition">Disease Wise Capping <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'claim_diseasewisecapping_condition_div')" (mouseleave)="mouseLeave($event, 'claim_diseasewisecapping_condition_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="claim_diseasewisecapping_condition_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Disease wise capping</p>
                                    </div>
                                </div>
                                <div class="switch-field wclaim">                                    
                                    <input type="radio" id="radio-two_claim_diseasewisecapping_condition" formControlName="claim_diseasewisecapping_condition" name="claim_diseasewisecapping_condition" (change)="show_family_otp_tr('claim_diseasewisecapping_condition', 'no'); red_green_change('claim_diseasewisecapping_condition', 'no')" value="Not Applicable" />
                                    <label for="radio-two_claim_diseasewisecapping_condition" id="claim_diseasewisecapping_condition_no" class="bs-form-label">Not Applicable</label>                                                                                        
                                    <input type="radio" id="radio-one_claim_diseasewisecapping_condition" formControlName="claim_diseasewisecapping_condition" name="claim_diseasewisecapping_condition" (change)="show_family_otp_tr('claim_diseasewisecapping_condition', 'yes'); red_green_change('claim_diseasewisecapping_condition', 'yes')" value="Applicable" />
                                    <label for="radio-one_claim_diseasewisecapping_condition" id="claim_diseasewisecapping_condition_yes" class="bs-form-label">Applicable</label>
                                </div>
                            </div>
                        </div>

                        <!------ Table -------->
                        <!-- <div class="table-responsive mt-3 claim_table" style="display:none">
                            <table class="table table-bordered table-sm row-border hover mt-2 w1000" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                <thead style="border: #4e0099;">
                                    <tr style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        <td>Relationship</td>
                                        <td id="thead_Employees" style="display:none">Employees</td>
                                        <td id="thead_Spouse" style="display:none">Spouse</td>
                                        <td id="thead_Childrens" style="display:none">Children</td>
                                        <td id="thead_Parents" style="display:none">Parents</td>
                                        <td id="thead_Siblings_or_disabled_siblings" style="display:none">Siblings</td>
                                        <td id="thead_claim_diseasewisecapping_condition" style="display:none">Disease wise capping</td>
                                    </tr>
                                </thead>
                                <tbody id="tr_copay" style="display:none">
                                    <tr>
                                        <td class="bs-form-label">CoPay</td>
                                        <td id="tbody_Employees" style="display:none">
                                            <select class="form-select" formControlName="claim_copay_condition_1" id="claim_copay_condition_1" style="line-height: 1.7;">
                                                <option value="select">Select</option>
                                                <option value="0">0%</option>
                                                <option value="5">5%</option>
                                                <option value="10">10%</option>
                                                <option value="15">15%</option>
                                                <option value="20">20%</option>
                                                <option value="25">25%</option>
                                                <option value="30">30%</option>
                                                <option value="35">35%</option>
                                                <option value="40">40%</option>
                                                <option value="45">45%</option>
                                                <option value="50">50%</option>                                                        
                                            </select>
                                            <p class="error" id="claim_copay_condition_1_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="tbody_Spouse" style="display:none">
                                            <select class="form-select" formControlName="claim_copay_condition_2" id="claim_copay_condition_2" style="line-height: 1.7;">
                                                <option value="select">Select</option>
                                                <option value="0">0%</option>
                                                <option value="5">5%</option>
                                                <option value="10">10%</option>
                                                <option value="15">15%</option>
                                                <option value="20">20%</option>
                                                <option value="25">25%</option>
                                                <option value="30">30%</option>
                                                <option value="35">35%</option>
                                                <option value="40">40%</option>
                                                <option value="45">45%</option>
                                                <option value="50">50%</option>                                                        
                                            </select>
                                            <p class="error" id="claim_copay_condition_2_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="tbody_Childrens" style="display:none">
                                            <select class="form-select" formControlName="claim_copay_condition_3" id="claim_copay_condition_3" style="line-height: 1.7;">
                                                <option value="select">Select</option>
                                                <option value="0">0%</option>
                                                <option value="5">5%</option>
                                                <option value="10">10%</option>
                                                <option value="15">15%</option>
                                                <option value="20">20%</option>
                                                <option value="25">25%</option>
                                                <option value="30">30%</option>
                                                <option value="35">35%</option>
                                                <option value="40">40%</option>
                                                <option value="45">45%</option>
                                                <option value="50">50%</option>                                                        
                                            </select>
                                            <p class="error" id="claim_copay_condition_3_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="tbody_Parents" style="display:none">
                                            <select class="form-select" formControlName="claim_copay_condition_4" id="claim_copay_condition_4" style="line-height: 1.7;">
                                                <option value="select">Select</option>
                                                <option value="0">0%</option>
                                                <option value="5">5%</option>
                                                <option value="10">10%</option>
                                                <option value="15">15%</option>
                                                <option value="20">20%</option>
                                                <option value="25">25%</option>
                                                <option value="30">30%</option>
                                                <option value="35">35%</option>
                                                <option value="40">40%</option>
                                                <option value="45">45%</option>
                                                <option value="50">50%</option>                                                       
                                            </select>
                                            <p class="error" id="claim_copay_condition_4_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="tbody_Siblings_or_disabled_siblings" style="display:none">
                                            <select class="form-select" formControlName="claim_copay_condition_5" id="claim_copay_condition_5" style="line-height: 1.7;">
                                                <option value="select">Select</option>
                                                <option value="0">0%</option>
                                                <option value="5">5%</option>
                                                <option value="10">10%</option>
                                                <option value="15">15%</option>
                                                <option value="20">20%</option>
                                                <option value="25">25%</option>
                                                <option value="30">30%</option>
                                                <option value="35">35%</option>
                                                <option value="40">40%</option>
                                                <option value="45">45%</option>
                                                <option value="50">50%</option>                                                        
                                            </select>
                                            <p class="error" id="claim_copay_condition_5_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody id="tr_deductable" style="display:none">
                                    <tr>
                                        <td class="bs-form-label">Deductible</td>
                                        <td id="deductible_tbody_Employees" style="display:none">
                                            <input type="text" class="form-control" formControlName="deductible_1" name="deductible_1" id="deductible_1" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="deductible_1_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="deductible_tbody_Spouse" style="display:none">
                                            <input type="text" class="form-control" formControlName="deductible_2" name="deductible_2" id="deductible_2" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="deductible_2_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="deductible_tbody_Childrens" style="display:none">
                                            <input type="text" class="form-control" formControlName="deductible_3" name="deductible_3" id="deductible_3" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="deductible_3_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="deductible_tbody_Parents" style="display:none">                                                    
                                            <input type="text" class="form-control" formControlName="deductible_4" name="deductible_4" id="deductible_4" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="deductible_4_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="deductible_tbody_Siblings_or_disabled_siblings" style="display:none">                                                   
                                            <input type="text" class="form-control" formControlName="deductible_5" name="deductible_5" id="deductible_5" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="deductible_5_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody id="tr_sum_incured_sub_limit" style="display:none">
                                    <tr>
                                        <td class="bs-form-label">SI Sub-Limit</td>
                                        <td id="sum_insured_tbody_Employees" style="display:none">
                                            <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_1" name="SI_for_parents_1" id="SI_for_parents_1" (input)="check_SI_for_parents($event)" />
                                            <p class="error" id="SI_for_parents_error_1" style="display: none">
                                                Value must not be greater than {{greatest_graded_value}}
                                            </p>
                                            <p class="error" id="SI_for_parents_1_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="sum_insured_tbody_Spouse" style="display:none">
                                            <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_2" name="SI_for_parents_2" id="SI_for_parents_2" (input)="check_SI_for_parents($event)" />
                                            <p class="error" id="SI_for_parents_error_2" style="display: none">
                                                Value must not be greater than {{greatest_graded_value}}
                                            </p>
                                            <p class="error" id="SI_for_parents_2_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="sum_insured_tbody_Childrens" style="display:none">
                                            <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_3" name="SI_for_parents_3" id="SI_for_parents_3" (input)="check_SI_for_parents($event)" />
                                            <p class="error" id="SI_for_parents_error_3" style="display: none">
                                                Value must not be greater than {{greatest_graded_value}}
                                            </p>
                                            <p class="error" id="SI_for_parents_3_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="sum_insured_tbody_Parents" style="display:none">
                                            <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_4" name="SI_for_parents_4" id="SI_for_parents_4" (input)="check_SI_for_parents($event)" />
                                            <p class="error" id="SI_for_parents_error_4" style="display: none">
                                                Value must not be greater than {{greatest_graded_value}}
                                            </p>
                                            <p class="error" id="SI_for_parents_4_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="sum_insured_tbody_Siblings_or_disabled_siblings" style="display:none">
                                            <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_5" name="SI_for_parents_5" id="SI_for_parents_5" (input)="check_SI_for_parents($event)" />
                                            <p class="error" id="SI_for_parents_error_5" style="display: none">
                                                Value must not be greater than {{greatest_graded_value}}
                                            </p>
                                            <p class="error" id="SI_for_parents_5_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>  
                                <tbody id="tr_claim_diseasewisecapping_condition" style="display:none">
                                    <tr>
                                        <td class="bs-form-label">Disease wise capping</td>
                                        <td id="claim_diseasewisecapping_condition_tbody_Employees" style="display:none">
                                            <select class="form-select" formControlName="claim_diseasewisecapping_condition_1" id="claim_diseasewisecapping_condition_1" style="line-height: 1.7;" (change)="show_metro_table('show')">
                                                <option value="select">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <p class="error" id="claim_diseasewisecapping_condition_1_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="claim_diseasewisecapping_condition_tbody_Spouse" style="display:none">
                                            <select class="form-select" formControlName="claim_diseasewisecapping_condition_2" id="claim_diseasewisecapping_condition_2" style="line-height: 1.7;" (change)="show_metro_table('show')">
                                                <option value="select">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <p class="error" id="claim_diseasewisecapping_condition_2_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="claim_diseasewisecapping_condition_tbody_Childrens" style="display:none">
                                            <select class="form-select" formControlName="claim_diseasewisecapping_condition_3" id="claim_diseasewisecapping_condition_3" style="line-height: 1.7;" (change)="show_metro_table('show')">
                                                <option value="select">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <p class="error" id="claim_diseasewisecapping_condition_3_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="claim_diseasewisecapping_condition_tbody_Parents" style="display:none">
                                            <select class="form-select" formControlName="claim_diseasewisecapping_condition_4" id="claim_diseasewisecapping_condition_4" style="line-height: 1.7;" (change)="show_metro_table('show')">
                                                <option value="select">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <p class="error" id="claim_diseasewisecapping_condition_4_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td id="claim_diseasewisecapping_condition_tbody_Siblings_or_disabled_siblings" style="display:none">
                                            <select class="form-select" formControlName="claim_diseasewisecapping_condition_5" id="claim_diseasewisecapping_condition_5" style="line-height: 1.7;" (change)="show_metro_table('show')">
                                                <option value="select">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <p class="error" id="claim_diseasewisecapping_condition_5_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>                              
                            </table>
                        </div> -->
                        <!--------------------->

                        <!------------- TABLE and Accordian CLaim ------------------->
                        <div class="mt-3 claim_table" style="display:none">                                                                

                            <div class="col-sm-12 pb-2" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                    
                                <div class="row m-0 p-1 TableAccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                    <div class="col-sm-2">
                                        Relationship
                                    </div>
                                    <div id="thead_Employees" style="display:none" class="col-sm-2">
                                        Employees
                                    </div>
                                    <div id="thead_Spouse" style="display:none" class="col-sm-2">
                                        Spouse
                                    </div>                        
                                    <div id="thead_Childrens" style="display:none" class="col-sm-2">
                                        Children
                                    </div>                        
                                    <div id="thead_Parents" style="display:none" class="col-sm-2">
                                        Parents
                                    </div>
                                    <div id="thead_Siblings_or_disabled_siblings" style="display:none" class="col-sm-2">
                                        Siblings
                                    </div>										
                                </div>

                                <!------- Copay ---->
                                <div class="my-2 mx-0" id="tr_copay" style="display:none">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        CoPay
                                    </h5>

                                    <div id="MobileCover_accord" class="claimaccordDiv row mt-2"> 
                                        <div class="col-sm-2 TableAccord">
                                            <label class="bs-form-label my-2" style="width: 100%;">CoPay</label>
                                        </div>                                           
                                        <div class="col-sm-2" id="tbody_Employees" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Employee</label>
                                                <select class="form-select" formControlName="claim_copay_condition_1" id="claim_copay_condition_1" style="line-height: 1.7;">
                                                    <option value="select">Select</option>
                                                    <option value="0">0%</option>
                                                    <option value="5">5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="15">15%</option>
                                                    <option value="20">20%</option>
                                                    <option value="25">25%</option>
                                                    <option value="30">30%</option>
                                                    <option value="35">35%</option>
                                                    <option value="40">40%</option>
                                                    <option value="45">45%</option>
                                                    <option value="50">50%</option>                                                        
                                                </select>
                                                <p class="error" id="claim_copay_condition_1_error" style="display:none">
                                                    This is required field
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="tbody_Spouse" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Spouse</label>
                                                <select class="form-select" formControlName="claim_copay_condition_2" id="claim_copay_condition_2" style="line-height: 1.7;">
                                                    <option value="select">Select</option>
                                                    <option value="0">0%</option>
                                                    <option value="5">5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="15">15%</option>
                                                    <option value="20">20%</option>
                                                    <option value="25">25%</option>
                                                    <option value="30">30%</option>
                                                    <option value="35">35%</option>
                                                    <option value="40">40%</option>
                                                    <option value="45">45%</option>
                                                    <option value="50">50%</option>                                                        
                                                </select>
                                                <p class="error" id="claim_copay_condition_2_error" style="display:none">
                                                    This is required field
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="tbody_Childrens" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Children</label>
                                                <select class="form-select" formControlName="claim_copay_condition_3" id="claim_copay_condition_3" style="line-height: 1.7;">
                                                    <option value="select">Select</option>
                                                    <option value="0">0%</option>
                                                    <option value="5">5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="15">15%</option>
                                                    <option value="20">20%</option>
                                                    <option value="25">25%</option>
                                                    <option value="30">30%</option>
                                                    <option value="35">35%</option>
                                                    <option value="40">40%</option>
                                                    <option value="45">45%</option>
                                                    <option value="50">50%</option>                                                        
                                                </select>
                                                <p class="error" id="claim_copay_condition_3_error" style="display:none">
                                                    This is required field
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="tbody_Parents" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Parents</label>
                                                <select class="form-select" formControlName="claim_copay_condition_4" id="claim_copay_condition_4" style="line-height: 1.7;">
                                                    <option value="select">Select</option>
                                                    <option value="0">0%</option>
                                                    <option value="5">5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="15">15%</option>
                                                    <option value="20">20%</option>
                                                    <option value="25">25%</option>
                                                    <option value="30">30%</option>
                                                    <option value="35">35%</option>
                                                    <option value="40">40%</option>
                                                    <option value="45">45%</option>
                                                    <option value="50">50%</option>                                                       
                                                </select>
                                                <p class="error" id="claim_copay_condition_4_error" style="display:none">
                                                    This is required field
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="tbody_Siblings_or_disabled_siblings" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Siblings</label>
                                                <select class="form-select" formControlName="claim_copay_condition_5" id="claim_copay_condition_5" style="line-height: 1.7;">
                                                    <option value="select">Select</option>
                                                    <option value="0">0%</option>
                                                    <option value="5">5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="15">15%</option>
                                                    <option value="20">20%</option>
                                                    <option value="25">25%</option>
                                                    <option value="30">30%</option>
                                                    <option value="35">35%</option>
                                                    <option value="40">40%</option>
                                                    <option value="45">45%</option>
                                                    <option value="50">50%</option>                                                        
                                                </select>
                                                <p class="error" id="claim_copay_condition_5_error" style="display:none">
                                                    This is required field
                                                </p>
                                            </div>
                                        </div>                                                                                                                                
                                    </div>
                                    
                                </div>

                                <hr class="my-3 mt-2 MobileAccord">

                                <!------- Deductible ---->
                                <div class="my-2 mx-0" id="tr_deductable" style="display:none">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Deductible
                                    </h5>

                                    <div id="MobileCover_accord" class="claimaccordDiv row"> 
                                        <div class="col-sm-2 TableAccord">
                                            <label class="bs-form-label my-2" style="width: 100%;">Deductible</label>
                                        </div>                                           
                                        <div class="col-sm-2" id="deductible_tbody_Employees" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Employee</label>
                                                <input type="text" class="form-control" formControlName="deductible_1" name="deductible_1" id="deductible_1" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="deductible_1_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="deductible_tbody_Spouse" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Spouse</label>
                                                <input type="text" class="form-control" formControlName="deductible_2" name="deductible_2" id="deductible_2" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="deductible_2_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="deductible_tbody_Childrens" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Children</label>
                                                <input type="text" class="form-control" formControlName="deductible_3" name="deductible_3" id="deductible_3" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="deductible_3_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="deductible_tbody_Parents" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Parents</label>
                                                <input type="text" class="form-control" formControlName="deductible_4" name="deductible_4" id="deductible_4" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="deductible_4_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="deductible_tbody_Siblings_or_disabled_siblings" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Siblings</label>
                                                <input type="text" class="form-control" formControlName="deductible_5" name="deductible_5" id="deductible_5" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="deductible_5_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>                                                                                                                                
                                    </div>
                                    
                                </div>

                                <hr class="my-3 mt-2 MobileAccord">

                                <!------- SI Sub-Limit ---->
                                <div class="my-2 mx-0" id="tr_sum_incured_sub_limit" style="display:none">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        SI Sub-Limit
                                    </h5>

                                    <div id="MobileCover_accord" class="claimaccordDiv row"> 
                                        <div class="col-sm-2 TableAccord">
                                            <label class="bs-form-label my-2" style="width: 100%;">SI Sub-Limit</label>
                                        </div>                                           
                                        <div class="col-sm-2" id="sum_insured_tbody_Employees" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Employee</label>
                                                <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_1" name="SI_for_parents_1" id="SI_for_parents_1" (input)="check_SI_for_parents($event)" />
												<p class="error" id="SI_for_parents_error_1" style="display: none">
													Value must not be greater than {{greatest_graded_value}}
												</p>
												<p class="error" id="SI_for_parents_1_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="sum_insured_tbody_Spouse" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Spouse</label>
                                                <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_2" name="SI_for_parents_2" id="SI_for_parents_2" (input)="check_SI_for_parents($event)" />
												<p class="error" id="SI_for_parents_error_2" style="display: none">
													Value must not be greater than {{greatest_graded_value}}
												</p>
												<p class="error" id="SI_for_parents_2_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="sum_insured_tbody_Childrens" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Children</label>
                                                <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_3" name="SI_for_parents_3" id="SI_for_parents_3" (input)="check_SI_for_parents($event)" />
												<p class="error" id="SI_for_parents_error_3" style="display: none">
													Value must not be greater than {{greatest_graded_value}}
												</p>
												<p class="error" id="SI_for_parents_3_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="sum_insured_tbody_Parents" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Parents</label>
                                                <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_4" name="SI_for_parents_4" id="SI_for_parents_4" (input)="check_SI_for_parents($event)" />
												<p class="error" id="SI_for_parents_error_4" style="display: none">
													Value must not be greater than {{greatest_graded_value}}
												</p>
												<p class="error" id="SI_for_parents_4_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="sum_insured_tbody_Siblings_or_disabled_siblings" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Siblings</label>
                                                <input type="text" maxlength="{{greatest_graded_value}}" class="form-control" formControlName="SI_for_parents_5" name="SI_for_parents_5" id="SI_for_parents_5" (input)="check_SI_for_parents($event)" />
												<p class="error" id="SI_for_parents_error_5" style="display: none">
													Value must not be greater than {{greatest_graded_value}}
												</p>
												<p class="error" id="SI_for_parents_5_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>                                                                                                                                
                                    </div>
                                    
                                </div>

                                <hr class="my-3 mt-2 MobileAccord">

                                <!------- Disease wise capping ---->
                                <div class="my-2 mx-0" id="tr_claim_diseasewisecapping_condition" style="display:none">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Disease wise capping
                                    </h5>

                                    <div id="MobileCover_accord" class="claimaccordDiv row"> 
                                        <div class="col-sm-2 TableAccord">
                                            <label class="bs-form-label my-2" style="width: 100%;">Disease wise capping</label>
                                        </div>                                           
                                        <div class="col-sm-2" id="claim_diseasewisecapping_condition_tbody_Employees" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Employee</label>
                                                <select class="form-select" formControlName="claim_diseasewisecapping_condition_1" id="claim_diseasewisecapping_condition_1" style="line-height: 1.7;" (change)="show_metro_table('show')">
													<option value="select">Select</option>
													<option value="yes">Yes</option>
													<option value="no">No</option>
												</select>
												<p class="error" id="claim_diseasewisecapping_condition_1_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="claim_diseasewisecapping_condition_tbody_Spouse" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Spouse</label>
                                                <select class="form-select" formControlName="claim_diseasewisecapping_condition_2" id="claim_diseasewisecapping_condition_2" style="line-height: 1.7;" (change)="show_metro_table('show')">
													<option value="select">Select</option>
													<option value="yes">Yes</option>
													<option value="no">No</option>
												</select>
												<p class="error" id="claim_diseasewisecapping_condition_2_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="claim_diseasewisecapping_condition_tbody_Childrens" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Children</label>
                                                <select class="form-select" formControlName="claim_diseasewisecapping_condition_3" id="claim_diseasewisecapping_condition_3" style="line-height: 1.7;" (change)="show_metro_table('show')">
													<option value="select">Select</option>
													<option value="yes">Yes</option>
													<option value="no">No</option>
												</select>
												<p class="error" id="claim_diseasewisecapping_condition_3_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="claim_diseasewisecapping_condition_tbody_Parents" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Parents</label>
                                                <select class="form-select" formControlName="claim_diseasewisecapping_condition_4" id="claim_diseasewisecapping_condition_4" style="line-height: 1.7;" (change)="show_metro_table('show')">
													<option value="select">Select</option>
													<option value="yes">Yes</option>
													<option value="no">No</option>
												</select>
												<p class="error" id="claim_diseasewisecapping_condition_4_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" id="claim_diseasewisecapping_condition_tbody_Siblings_or_disabled_siblings" style="display:none; padding: 0px 20px;">                                                
                                            <div class="form-group metrodisplay">
                                                <label class="bs-form-label my-2 MobileAccord">Siblings</label>
                                                <select class="form-select" formControlName="claim_diseasewisecapping_condition_5" id="claim_diseasewisecapping_condition_5" style="line-height: 1.7;" (change)="show_metro_table('show')">
													<option value="select">Select</option>
													<option value="yes">Yes</option>
													<option value="no">No</option>
												</select>
												<p class="error" id="claim_diseasewisecapping_condition_5_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>                                                                                                                                
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <!--------------- END ---------------------->

                        <!-------Claim Table----->
                        <!-- <div class="table-responsive mt-3 metro_table" style="display:none">
                            <table class="table table-bordered table-sm row-border hover mt-2 w1000" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                <thead style="border: #4e0099;">
                                    <tr style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        <th>Disease</th>
                                        <th>Metro</th>
                                        <th>Non-Metro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="bs-form-label pt-2">Capped Ailments</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Capped_Ailments_metro" name="Capped_Ailments_metro" id="Capped_Ailments_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Capped_Ailments_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Capped_Ailments_nonmetro" name="Capped_Ailments_nonmetro" id="Capped_Ailments_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Capped_Ailments_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Joint/Knee/Hip Replacement</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="JHK_Replacement_metro" name="JHK_Replacement_metro" id="JHK_Replacement_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="JHK_Replacement_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="JHK_Replacement_nonmetro" name="JHK_Replacement_nonmetro" id="JHK_Replacement_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="JHK_Replacement_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Hernia</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Hernia_metro" name="Hernia_metro" id="Hernia_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Hernia_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Hernia_nonmetro" name="Hernia_nonmetro" id="Hernia_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Hernia_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Hysterectomy</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Hysterectomy_metro" name="Hysterectomy_metro" id="Hysterectomy_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Hysterectomy_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Hysterectomy_nonmetro" name="Hysterectomy_nonmetro" id="Hysterectomy_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Hysterectomy_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Urinary Stone/Calculi</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Urinary_SC_metro" name="Urinary_SC_metro" id="Urinary_SC_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Urinary_SC_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Urinary_SC_nonmetro" name="Urinary_SC_nonmetro" id="Urinary_SC_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Urinary_SC_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Angiogram</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Angiogram_metro" name="Angiogram_metro" id="Angiogram_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Angiogram_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Angiogram_nonmetro" name="Angiogram_nonmetro" id="Angiogram_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Angiogram_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Cholecystectomy</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Cholecystectomy_metro" name="Cholecystectomy_metro" id="Cholecystectomy_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Cholecystectomy_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Cholecystectomy_nonmetro" name="Cholecystectomy_nonmetro" id="Cholecystectomy_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Cholecystectomy_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Appendectomy</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Appendectomy_metro" name="Appendectomy_metro" id="Appendectomy_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Appendectomy_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Appendectomy_nonmetro" name="Appendectomy_nonmetro" id="Appendectomy_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Appendectomy_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">CABG</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="CABG_metro" name="CABG_metro" id="CABG_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="CABG_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="CABG_nonmetro" name="CABG_nonmetro" id="CABG_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="CABG_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bs-form-label pt-2">Others</td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Others_metro" name="Others_metro" id="Others_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Others_metro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="Others_nonmetro" name="Others_nonmetro" id="Others_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="Others_nonmetro_error" style="display:none">
                                                This is required field
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label class="bs-form-label" (click)="addMetroField()" style="cursor: pointer">
                                                <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                <span style="color: #1489F3;">Add more</span>
                                            </label>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                <tbody class="newmetroField">

                                </tbody>
                            </table>
                        </div> -->

                        <!------------- TABLE and Accordian Metro/Non Metro ------------------->
                        <div class="mt-3 metro_table" style="display:none">                                                                

                            <div class="col-sm-12 pb-2" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                    
                                <div class="row m-0 p-1 TableAccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                    <div class="col-sm-4">
                                        Disease
                                    </div>
                                    <div class="col-sm-4">
                                        Metro
                                    </div>
                                    <div class="col-sm-4">
                                        Non-Metro
                                    </div>                                        
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Capped Ailments
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Capped Ailments</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Capped_Ailments_metro" name="Capped_Ailments_metro" id="Capped_Ailments_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="Capped_Ailments_metro_error" style="display:none">
                                                    This is required field
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Capped_Ailments_nonmetro" name="Capped_Ailments_nonmetro" id="Capped_Ailments_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="Capped_Ailments_nonmetro_error" style="display:none">
                                                    This is required field
                                                </p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>
                                            
                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Joint/Knee/Hip Replacement
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Joint/Knee/Hip Replacement</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="JHK_Replacement_metro" name="JHK_Replacement_metro" id="JHK_Replacement_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="JHK_Replacement_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="JHK_Replacement_nonmetro" name="JHK_Replacement_nonmetro" id="JHK_Replacement_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="JHK_Replacement_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Hernia
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Hernia</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Hernia_metro" name="Hernia_metro" id="Hernia_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Hernia_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Hernia_nonmetro" name="Hernia_nonmetro" id="Hernia_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Hernia_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Hysterectomy
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Hysterectomy</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Hysterectomy_metro" name="Hysterectomy_metro" id="Hysterectomy_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Hysterectomy_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Hysterectomy_nonmetro" name="Hysterectomy_nonmetro" id="Hysterectomy_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Hysterectomy_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Urinary Stone/Calculi
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Urinary Stone/Calculi</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Urinary_SC_metro" name="Urinary_SC_metro" id="Urinary_SC_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Urinary_SC_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Urinary_SC_nonmetro" name="Urinary_SC_nonmetro" id="Urinary_SC_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Urinary_SC_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Angiogram
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Angiogram</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Angiogram_metro" name="Angiogram_metro" id="Angiogram_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Angiogram_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Angiogram_nonmetro" name="Angiogram_nonmetro" id="Angiogram_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Angiogram_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Cholecystectomy
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Cholecystectomy</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Cholecystectomy_metro" name="Cholecystectomy_metro" id="Cholecystectomy_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Cholecystectomy_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Cholecystectomy_nonmetro" name="Cholecystectomy_nonmetro" id="Cholecystectomy_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Cholecystectomy_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Appendectomy
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Appendectomy</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Appendectomy_metro" name="Appendectomy_metro" id="Appendectomy_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Appendectomy_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Appendectomy_nonmetro" name="Appendectomy_nonmetro" id="Appendectomy_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Appendectomy_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        CABG
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">CABG</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="CABG_metro" name="CABG_metro" id="CABG_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="CABG_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="CABG_nonmetro" name="CABG_nonmetro" id="CABG_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="CABG_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="my-2 mx-0" style="padding: 0px 10px; padding-top: 10px;">

                                    <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        Others
                                    </h5>

                                    <div id="MobileCover_accord" class="mobaccordDiv row">   
                                        <div class="col-sm-4 TableAccord">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one" style="width:100%; display:block">
                                                <label class="bs-form-label my-2">Others</label>                                                
                                            </div>
                                        </div>                                         
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Metro</label>
                                                <input type="text" class="form-control" formControlName="Others_metro" name="Others_metro" id="Others_metro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Others_metro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">                                                
                                            <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
                                                <label class="bs-form-label my-2 MobileAccord">Non Metro</label>
                                                <input type="text" class="form-control" formControlName="Others_nonmetro" name="Others_nonmetro" id="Others_nonmetro" (keypress)="isNumber($event)" (input)="isformat($event)" />
												<p class="error" id="Others_nonmetro_error" style="display:none">
													This is required field
												</p>
                                            </div>
                                        </div>											
                                    </div>
                                    
                                </div>

                                <div class="newmetroField">

                                </div>
                                <div class="mt-3">
                                    <div>
                                        <label class="bs-form-label" (click)="addMetroField()" style="cursor: pointer">
                                            <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                            <span style="color: #1489F3;">Add more</span>
                                        </label>
                                    </div>                                    
                                </div>
                                
                                
                            </div>
                        </div>
                        <!--------------- END ---------------------->

                    </div>
                </div>
                <!----------------------------->

                <!---- OPD benefits Accordian ---->
                <h5 class="bs-left-form-label accordheading OPDheading" (click)="OPDaccord()">OPD Benefits
                    <img id="OPD_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="OPD_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/opd.png" class="headicon" />
                    <img id="OPDtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="OPDtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgOPD">
                        <div class="value-container" id="ValueOPD">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="OPD_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">OPD Benefits</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">

                                <div class="col-sm-12 row form-group m-0 p-0">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="out_patient_benefits">Out patient benefits <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'out_patient_benefits_div')" (mouseleave)="mouseLeave($event, 'out_patient_benefits_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="out_patient_benefits_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Out patient benefits</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_out_patient_benefits" formControlName="out_patient_benefits" name="out_patient_benefits" value="Not Covered" (change)="checkradio('divout_patient_benefits_limit', 'NotCovered'); green_red_change('out_patient_benefits', 'no')" />
                                            <label for="radio-two_out_patient_benefits" id="out_patient_benefits_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_out_patient_benefits" formControlName="out_patient_benefits" name="out_patient_benefits" value="Covered" (change)="checkradio('divout_patient_benefits_limit', 'Covered'); green_red_change('out_patient_benefits', 'yes')" />
                                            <label for="radio-one_out_patient_benefits" id="out_patient_benefits_yes" class="bs-form-label">Covered</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divout_patient_benefits_limit" style="display: none">
                                        <label class="bs-form-label" for="out_patient_benefits_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="out_patient_benefits_limit" id="out_patient_benefits_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="out_patient_benefits_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="opd_dental">Dental <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'opd_dental_div')" (mouseleave)="mouseLeave($event, 'opd_dental_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="opd_dental_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Dental</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_opd_dental" formControlName="opd_dental" name="opd_dental" value="Not Covered" (change)="checkradio('divopd_dental_limit', 'NotCovered'); green_red_change('opd_dental', 'no')" />
                                            <label for="radio-two_opd_dental" id="opd_dental_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_opd_dental" formControlName="opd_dental" name="opd_dental" value="Covered" (change)="checkradio('divopd_dental_limit', 'Covered'); green_red_change('opd_dental', 'yes')" />
                                            <label for="radio-one_opd_dental" id="opd_dental_yes" class="bs-form-label">Covered</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divopd_dental_limit" style="display: none">
                                        <label class="bs-form-label" for="opd_dental_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="opd_dental_limit" id="opd_dental_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="opd_dental_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="opd_vision">Vision <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'opd_vision_div')" (mouseleave)="mouseLeave($event, 'opd_vision_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="opd_vision_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Vision</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_opd_vision" formControlName="opd_vision" name="opd_vision" value="Not Covered" (change)="checkradio('divopd_vision_limit', 'NotCovered'); green_red_change('opd_vision', 'no')" />
                                            <label for="radio-two_opd_vision" id="opd_vision_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_opd_vision" formControlName="opd_vision" name="opd_vision" value="Covered" (change)="checkradio('divopd_vision_limit', 'Covered'); green_red_change('opd_vision', 'yes')" />
                                            <label for="radio-one_opd_vision" id="opd_vision_yes" class="bs-form-label">Covered</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divopd_vision_limit" style="display: none">
                                        <label class="bs-form-label" for="opd_vision_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="opd_vision_limit" id="opd_vision_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="opd_vision_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="preventive_health_checkup">Preventive health checkup <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'preventive_health_checkup_div')" (mouseleave)="mouseLeave($event, 'preventive_health_checkup_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="preventive_health_checkup_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Preventive health checkup</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_preventive_health_checkup" formControlName="preventive_health_checkup" name="preventive_health_checkup" value="Not Covered" (change)="checkradio('divpreventive_health_checkup_limit', 'NotCovered'); green_red_change('preventive_health_checkup', 'no')" />
                                            <label for="radio-two_preventive_health_checkup" id="preventive_health_checkup_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_preventive_health_checkup" formControlName="preventive_health_checkup" name="preventive_health_checkup" value="Covered" (change)="checkradio('divpreventive_health_checkup_limit', 'Covered'); green_red_change('preventive_health_checkup', 'yes')" />
                                            <label for="radio-one_preventive_health_checkup" id="preventive_health_checkup_yes" class="bs-form-label">Covered</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divpreventive_health_checkup_limit" style="display: none">
                                        <label class="bs-form-label" for="preventive_health_checkup_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="preventive_health_checkup_limit" id="preventive_health_checkup_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="preventive_health_checkup_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="doctor_consultation">Doctor consultation <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'doctor_consultation_div')" (mouseleave)="mouseLeave($event, 'doctor_consultation_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="doctor_consultation_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Doctor consultation</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_doctor_consultation" formControlName="doctor_consultation" name="doctor_consultation" value="Not Covered" (change)="checkradio('divdoctor_consultation_limit', 'NotCovered'); green_red_change('doctor_consultation', 'no')" />
                                            <label for="radio-two_doctor_consultation" id="doctor_consultation_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_doctor_consultation" formControlName="doctor_consultation" name="doctor_consultation" value="Covered" (change)="checkradio('divdoctor_consultation_limit', 'Covered'); green_red_change('doctor_consultation', 'yes')" />
                                            <label for="radio-one_doctor_consultation" id="doctor_consultation_yes" class="bs-form-label">Covered</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divdoctor_consultation_limit" style="display: none">
                                        <label class="bs-form-label" for="doctor_consultation_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="doctor_consultation_limit" id="doctor_consultation_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="doctor_consultation_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="diagnostics">Diagnostics <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'diagnostics_div')" (mouseleave)="mouseLeave($event, 'diagnostics_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="diagnostics_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Diagnostics</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_diagnostics" formControlName="diagnostics" name="diagnostics" value="Not Covered" (change)="checkradio('divdiagnostics_limit', 'NotCovered'); green_red_change('diagnostics', 'no')" />
                                            <label for="radio-two_diagnostics" id="diagnostics_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_diagnostics" formControlName="diagnostics" name="diagnostics" value="Covered" (change)="checkradio('divdiagnostics_limit', 'Covered'); green_red_change('diagnostics', 'yes')" />
                                            <label for="radio-one_diagnostics" id="diagnostics_yes" class="bs-form-label">Covered</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divdiagnostics_limit" style="display: none">
                                        <label class="bs-form-label" for="diagnostics_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="diagnostics_limit" id="diagnostics_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="diagnostics_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="pharmacy">Pharmacy <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'pharmacy_div')" (mouseleave)="mouseLeave($event, 'pharmacy_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="pharmacy_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Pharmacy</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_pharmacy" formControlName="pharmacy" name="pharmacy" value="Not Covered" (change)="checkradio('divpharmacy_limit', 'NotCovered'); green_red_change('pharmacy', 'no')" />
                                            <label for="radio-two_pharmacy" id="pharmacy_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_pharmacy" formControlName="pharmacy" name="pharmacy" value="Covered" (change)="checkradio('divpharmacy_limit', 'Covered'); green_red_change('pharmacy', 'yes')" />
                                            <label for="radio-one_pharmacy" id="pharmacy_yes" class="bs-form-label">Covered</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divpharmacy_limit" style="display: none">
                                        <label class="bs-form-label" for="pharmacy_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="pharmacy_limit" id="pharmacy_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="pharmacy_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="new_born_vaccination">New born vaccination <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'new_born_vaccination_div')" (mouseleave)="mouseLeave($event, 'new_born_vaccination_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="new_born_vaccination_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">New born vaccination</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_new_born_vaccination" formControlName="new_born_vaccination" name="new_born_vaccination" value="Not Covered" (change)="checkradio('divnew_born_vaccination_limit', 'NotCovered'); green_red_change('new_born_vaccination', 'no')" />
                                            <label for="radio-two_new_born_vaccination" id="new_born_vaccination_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_new_born_vaccination" formControlName="new_born_vaccination" name="new_born_vaccination" value="Covered" (change)="checkradio('divnew_born_vaccination_limit', 'Covered'); green_red_change('new_born_vaccination', 'yes')" />
                                            <label for="radio-one_new_born_vaccination" id="new_born_vaccination_yes" class="bs-form-label">Covered</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divnew_born_vaccination_limit" style="display: none">
                                        <label class="bs-form-label" for="new_born_vaccination_limit">If Covered then specify the limit *</label>
                                        <input type="text" class="form-control" formControlName="new_born_vaccination_limit" id="new_born_vaccination_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="new_born_vaccination_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="benefit_name">Benefit Name *</label>
                                    <input type="text" class="form-control" formControlName="benefit_name" id="benefit_name"
                                    [ngClass]="{'control-red': policyFeaturesSubmitted && getpolicyFeaturesFormControls.benefit_name.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="policyFeaturesSubmitted && getpolicyFeaturesFormControls.benefit_name.errors">
                                        <p class="error"
                                        *ngIf="policyFeaturesSubmitted && getpolicyFeaturesFormControls.benefit_name.errors.required">
                                        Field is required
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="benefit_limit">Benefit limit *</label>
                                    <input type="text" class="form-control" formControlName="benefit_limit" id="benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)"
                                    [ngClass]="{'control-red': policyFeaturesSubmitted && getpolicyFeaturesFormControls.benefit_limit.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="policyFeaturesSubmitted && getpolicyFeaturesFormControls.benefit_limit.errors">
                                        <p class="error"
                                        *ngIf="policyFeaturesSubmitted && getpolicyFeaturesFormControls.benefit_limit.errors.required">
                                        Field is required
                                        </p>
                                    </ng-container>
                                </div>                                                                 -->

                                <div class="col-sm-12">
                                    <div class="row newbenifitField">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addBenefitField()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add New Benefit Field</span>
                                    </label>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->

                <!---- Additional Benefits Accordian ---->
                <h5 class="bs-left-form-label accordheading additionalbenefitsheading" (click)="Othersaccord()">Additional Benefits
                    <img id="Others_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="Others_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/benefit.png" class="headicon" />
                    <img id="additionalbenefitstick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="additionalbenefitstick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgOthers">
                        <div class="value-container" id="ValueOthers">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="Others_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Others</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">

                                <div class="col-sm-12 row form-group m-0 p-0">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="organ_donor_cover">Organ donor cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'organ_donor_cover_div')" (mouseleave)="mouseLeave($event, 'organ_donor_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="organ_donor_cover_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Organ donor cover</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_organ_donor_cover" formControlName="organ_donor_cover" name="organ_donor_cover" name="organ_donor_cover" value="Not Covered" checked (change)="checkradio('divorgan_donor_cover_limit', 'NotCovered'); green_red_change('organ_donor_cover', 'no')" />
                                            <label for="radio-two_organ_donor_cover" id="organ_donor_cover_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_organ_donor_cover" formControlName="organ_donor_cover" name="organ_donor_cover" value="Covered" (change)="checkradio('divorgan_donor_cover_limit', 'Covered'); green_red_change('organ_donor_cover', 'yes')" />
                                            <label for="radio-one_organ_donor_cover" id="organ_donor_cover_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divorgan_donor_cover_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="organ_donor_cover_limit_perc" id="organ_donor_cover_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="organ_donor_cover_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required
                                                </p>
                                            </div>                                            
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_organ_donor_cover" value="min_organ_donor_cover" formControlName="min_max_organ_donor_cover" name="min_max_organ_donor_cover" type="radio" (click)="check_limit_toggle('organ_donor_cover','yes')" />
                                                    <label for="min_organ_donor_cover" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_organ_donor_cover" value="na_organ_donor_cover" formControlName="min_max_organ_donor_cover" name="min_max_organ_donor_cover" type="radio" (click)="check_limit_toggle('organ_donor_cover','no')" />
                                                    <label for="na_organ_donor_cover" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_organ_donor_cover" value="max_organ_donor_cover" formControlName="min_max_organ_donor_cover" name="min_max_organ_donor_cover" type="radio" (click)="check_limit_toggle('organ_donor_cover','yes')" />
                                                    <label for="max_organ_donor_cover" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 organ_donor_cover_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="organ_donor_cover_limit_value" id="organ_donor_cover_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="organ_donor_cover_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>                                            

                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="hiv_cover">Hiv cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'hiv_cover_div')" (mouseleave)="mouseLeave($event, 'hiv_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="hiv_cover_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Hiv cover</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_hiv_cover" formControlName="hiv_cover" name="hiv_cover" name="hiv_cover" value="Not Covered" checked (change)="checkradio('divhiv_cover_limit', 'NotCovered'); green_red_change('hiv_cover', 'no')" />
                                            <label for="radio-two_hiv_cover" id="hiv_cover_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_hiv_cover" formControlName="hiv_cover" name="hiv_cover" value="Covered" (change)="checkradio('divhiv_cover_limit', 'Covered'); green_red_change('hiv_cover', 'yes')" />
                                            <label for="radio-one_hiv_cover" id="hiv_cover_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divhiv_cover_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="hiv_cover_limit_perc" id="hiv_cover_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="hiv_cover_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_hiv_cover" value="min_hiv_cover" formControlName="min_max_hiv_cover" name="min_max_hiv_cover" type="radio" (click)="check_limit_toggle('hiv_cover','yes')" />
                                                    <label for="min_hiv_cover" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_hiv_cover" value="na_hiv_cover" formControlName="min_max_hiv_cover" name="min_max_hiv_cover" type="radio" (click)="check_limit_toggle('hiv_cover','no')" />
                                                    <label for="na_hiv_cover" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_hiv_cover" value="max_hiv_cover" formControlName="min_max_hiv_cover" name="min_max_hiv_cover" type="radio" (click)="check_limit_toggle('hiv_cover','yes')" />
                                                    <label for="max_hiv_cover" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 hiv_cover_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="hiv_cover_limit_value" id="hiv_cover_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="hiv_cover_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="genetic_disorder">Genetic disorder <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'genetic_disorder_div')" (mouseleave)="mouseLeave($event, 'genetic_disorder_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="genetic_disorder_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Genetic disorder</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_genetic_disorder" formControlName="genetic_disorder" name="genetic_disorder" name="genetic_disorder" value="Not Covered" checked (change)="checkradio('divgenetic_disorder_limit', 'NotCovered'); green_red_change('genetic_disorder', 'no')" />
                                            <label for="radio-two_genetic_disorder" id="genetic_disorder_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_genetic_disorder" formControlName="genetic_disorder" name="genetic_disorder" value="Covered" (change)="checkradio('divgenetic_disorder_limit', 'Covered'); green_red_change('genetic_disorder', 'yes')" />
                                            <label for="radio-one_genetic_disorder" id="genetic_disorder_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divgenetic_disorder_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="genetic_disorder_limit_perc" id="genetic_disorder_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="genetic_disorder_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_genetic_disorder" value="min_genetic_disorder" formControlName="min_max_genetic_disorder" name="min_max_genetic_disorder" type="radio" (click)="check_limit_toggle('genetic_disorder','yes')" />
                                                    <label for="min_genetic_disorder" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_genetic_disorder" value="na_genetic_disorder" formControlName="min_max_genetic_disorder" name="min_max_genetic_disorder" type="radio" (click)="check_limit_toggle('genetic_disorder','no')" />
                                                    <label for="na_genetic_disorder" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_genetic_disorder" value="max_genetic_disorder" formControlName="min_max_genetic_disorder" name="min_max_genetic_disorder" type="radio" (click)="check_limit_toggle('genetic_disorder','yes')" />
                                                    <label for="max_genetic_disorder" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 genetic_disorder_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="genetic_disorder_limit_value" id="genetic_disorder_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="genetic_disorder_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="lucentis_Avastin_injections">Lucentis Avastin injections <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'lucentis_Avastin_injections_div')" (mouseleave)="mouseLeave($event, 'lucentis_Avastin_injections_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="lucentis_Avastin_injections_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Lucentis Avastin injections</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_lucentis_Avastin_injections" formControlName="lucentis_Avastin_injections" name="lucentis_Avastin_injections" name="lucentis_Avastin_injections" value="Not Covered" checked (change)="checkradio('divlucentis_Avastin_injections_limit', 'NotCovered'); green_red_change('lucentis_Avastin_injections', 'no')" />
                                            <label for="radio-two_lucentis_Avastin_injections" id="lucentis_Avastin_injections_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_lucentis_Avastin_injections" formControlName="lucentis_Avastin_injections" name="lucentis_Avastin_injections" value="Covered" (change)="checkradio('divlucentis_Avastin_injections_limit', 'Covered'); green_red_change('lucentis_Avastin_injections', 'yes')" />
                                            <label for="radio-one_lucentis_Avastin_injections" id="lucentis_Avastin_injections_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divlucentis_Avastin_injections_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="lucentis_Avastin_injections_limit_perc" id="lucentis_Avastin_injections_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="lucentis_Avastin_injections_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_lucentis_Avastin_injections" value="min_lucentis_Avastin_injections" formControlName="min_max_lucentis_Avastin_injections" name="min_max_lucentis_Avastin_injections" type="radio" (click)="check_limit_toggle('lucentis_Avastin_injections','yes')" />
                                                    <label for="min_lucentis_Avastin_injections" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_lucentis_Avastin_injections" value="na_lucentis_Avastin_injections" formControlName="min_max_lucentis_Avastin_injections" name="min_max_lucentis_Avastin_injections" type="radio" (click)="check_limit_toggle('lucentis_Avastin_injections','no')" />
                                                    <label for="na_lucentis_Avastin_injections" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_lucentis_Avastin_injections" value="max_lucentis_Avastin_injections" formControlName="min_max_lucentis_Avastin_injections" name="min_max_lucentis_Avastin_injections" type="radio" (click)="check_limit_toggle('lucentis_Avastin_injections','yes')" />
                                                    <label for="max_lucentis_Avastin_injections" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 lucentis_Avastin_injections_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="lucentis_Avastin_injections_limit_value" id="lucentis_Avastin_injections_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="lucentis_Avastin_injections_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="oral_chemo_therapy">Oral chemo therapy <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'oral_chemo_therapy_div')" (mouseleave)="mouseLeave($event, 'oral_chemo_therapy_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="oral_chemo_therapy_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Oral chemo therapy</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_oral_chemo_therapy" formControlName="oral_chemo_therapy" name="oral_chemo_therapy" name="oral_chemo_therapy" value="Not Covered" checked (change)="checkradio('divoral_chemo_therapy_limit', 'NotCovered'); green_red_change('oral_chemo_therapy', 'no')" />
                                            <label for="radio-two_oral_chemo_therapy" id="oral_chemo_therapy_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_oral_chemo_therapy" formControlName="oral_chemo_therapy" name="oral_chemo_therapy" value="Covered" (change)="checkradio('divoral_chemo_therapy_limit', 'Covered'); green_red_change('oral_chemo_therapy', 'yes')" />
                                            <label for="radio-one_oral_chemo_therapy" id="oral_chemo_therapy_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divoral_chemo_therapy_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="oral_chemo_therapy_limit_perc" id="oral_chemo_therapy_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="oral_chemo_therapy_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_oral_chemo_therapy" value="min_oral_chemo_therapy" formControlName="min_max_oral_chemo_therapy" name="min_max_oral_chemo_therapy" type="radio" (click)="check_limit_toggle('oral_chemo_therapy','yes')" />
                                                    <label for="min_oral_chemo_therapy" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_oral_chemo_therapy" value="na_oral_chemo_therapy" formControlName="min_max_oral_chemo_therapy" name="min_max_oral_chemo_therapy" type="radio" (click)="check_limit_toggle('oral_chemo_therapy','no')" />
                                                    <label for="na_oral_chemo_therapy" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_oral_chemo_therapy" value="max_oral_chemo_therapy" formControlName="min_max_oral_chemo_therapy" name="min_max_oral_chemo_therapy" type="radio" (click)="check_limit_toggle('oral_chemo_therapy','yes')" />
                                                    <label for="max_oral_chemo_therapy" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 oral_chemo_therapy_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="oral_chemo_therapy_limit_value" id="oral_chemo_therapy_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="oral_chemo_therapy_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="cochlear_implant">Cochlear implant <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'cochlear_implant_div')" (mouseleave)="mouseLeave($event, 'cochlear_implant_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="cochlear_implant_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Cochlear implant</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_cochlear_implant" formControlName="cochlear_implant" name="cochlear_implant" name="cochlear_implant" value="Not Covered" checked (change)="checkradio('divcochlear_implant_limit', 'NotCovered'); green_red_change('cochlear_implant', 'no')" />
                                            <label for="radio-two_cochlear_implant" id="cochlear_implant_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_cochlear_implant" formControlName="cochlear_implant" name="cochlear_implant" value="Covered" (change)="checkradio('divcochlear_implant_limit', 'Covered'); green_red_change('cochlear_implant', 'yes')" />
                                            <label for="radio-one_cochlear_implant" id="cochlear_implant_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divcochlear_implant_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="cochlear_implant_limit_perc" id="cochlear_implant_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="cochlear_implant_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_cochlear_implant" value="min_cochlear_implant" formControlName="min_max_cochlear_implant" name="min_max_cochlear_implant" type="radio" (click)="check_limit_toggle('cochlear_implant','yes')" />
                                                    <label for="min_cochlear_implant" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_cochlear_implant" value="na_cochlear_implant" formControlName="min_max_cochlear_implant" name="min_max_cochlear_implant" type="radio" (click)="check_limit_toggle('cochlear_implant','no')" />
                                                    <label for="na_cochlear_implant" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_cochlear_implant" value="max_cochlear_implant" formControlName="min_max_cochlear_implant" name="min_max_cochlear_implant" type="radio" (click)="check_limit_toggle('cochlear_implant','yes')" />
                                                    <label for="max_cochlear_implant" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 cochlear_implant_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="cochlear_implant_limit_value" id="cochlear_implant_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="cochlear_implant_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="ayush_treatment">Ayush treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'ayush_treatment_div')" (mouseleave)="mouseLeave($event, 'ayush_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="ayush_treatment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Ayush treatment</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_ayush_treatment" formControlName="ayush_treatment" name="ayush_treatment" name="ayush_treatment" value="Not Covered" checked (change)="checkradio('divayush_treatment_limit', 'NotCovered'); green_red_change('ayush_treatment', 'no')" />
                                            <label for="radio-two_ayush_treatment" id="ayush_treatment_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_ayush_treatment" formControlName="ayush_treatment" name="ayush_treatment" value="Covered" (change)="checkradio('divayush_treatment_limit', 'Covered'); green_red_change('ayush_treatment', 'yes')" />
                                            <label for="radio-one_ayush_treatment" id="ayush_treatment_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divayush_treatment_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="ayush_treatment_limit_perc" id="ayush_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="ayush_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_ayush_treatment" value="min_ayush_treatment" formControlName="min_max_ayush_treatment" name="min_max_ayush_treatment" type="radio" (click)="check_limit_toggle('ayush_treatment','yes')" />
                                                    <label for="min_ayush_treatment" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_ayush_treatment" value="na_ayush_treatment" formControlName="min_max_ayush_treatment" name="min_max_ayush_treatment" type="radio" (click)="check_limit_toggle('ayush_treatment','no')" />
                                                    <label for="na_ayush_treatment" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_ayush_treatment" value="max_ayush_treatment" formControlName="min_max_ayush_treatment" name="min_max_ayush_treatment" type="radio" (click)="check_limit_toggle('ayush_treatment','yes')" />
                                                    <label for="max_ayush_treatment" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 ayush_treatment_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="ayush_treatment_limit_value" id="ayush_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="ayush_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <!-- <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="lasik_treatment">Lasik treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'lasik_treatment_div')" (mouseleave)="mouseLeave($event, 'lasik_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="lasik_treatment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Lasik treatment</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_lasik_treatment" formControlName="lasik_treatment" name="lasik_treatment" name="lasik_treatment" value="Not Covered" checked (change)="checkradio('divlasik_treatment_limit', 'NotCovered')" />
                                            <label for="radio-two_lasik_treatment" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_lasik_treatment" formControlName="lasik_treatment" name="lasik_treatment" value="Covered" (change)="checkradio('divlasik_treatment_limit', 'Covered')" />
                                            <label for="radio-one_lasik_treatment" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divlasik_treatment_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="lasik_treatment_limit_perc" id="lasik_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="lasik_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_lasik_treatment" value="min_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','yes')" />
                                                    <label for="min_lasik_treatment" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_lasik_treatment" value="na_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','no')" />
                                                    <label for="na_lasik_treatment" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_lasik_treatment" value="max_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','yes')" />
                                                    <label for="max_lasik_treatment" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 lasik_treatment_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="lasik_treatment_limit_value" id="lasik_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="lasik_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div> -->

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="lasik_treatment">Lasik treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'lasik_treatment_div')" (mouseleave)="mouseLeave($event, 'lasik_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="lasik_treatment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Lasik treatment</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_lasik_treatment" formControlName="lasik_treatment" name="lasik_treatment" name="lasik_treatment" value="Not Covered" checked (change)="checkradio('divlasik_treatment_limit', 'NotCovered'); green_red_change('lasik_treatment', 'no')" />
                                            <label for="radio-two_lasik_treatment" id="lasik_treatment_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_lasik_treatment" formControlName="lasik_treatment" name="lasik_treatment" value="Covered" (change)="checkradio('divlasik_treatment_limit', 'Covered'); green_red_change('lasik_treatment', 'yes')" />
                                            <label for="radio-one_lasik_treatment" id="lasik_treatment_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divlasik_treatment_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-4 form-group"> 
                                                <select class="form-select" formControlName="lasik_treatment_limit_value" id="lasik_treatment_limit_value">
                                                    <option value="" selected>Select</option>
                                                    <option value="+/- 7.5">+/- 7.5</option>
                                                    <option value="+/- 7">+/- 7</option>
                                                    <option value="+/- 6.5">+/- 6.5</option>
                                                    <option value="+/- 6">+/- 6</option>
                                                    <option value="+/- 5.5">+/- 5.5</option>
                                                    <option value="+/- 5">+/- 5</option>
                                                </select>                                            
                                            </div>
                                            <!-- <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="lasik_treatment_limit_perc" id="lasik_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="lasik_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div> -->
    
                                            <!-- <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_lasik_treatment" value="min_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','yes')" />
                                                    <label for="min_lasik_treatment" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_lasik_treatment" value="na_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','no')" />
                                                    <label for="na_lasik_treatment" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_lasik_treatment" value="max_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','yes')" />
                                                    <label for="max_lasik_treatment" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 lasik_treatment_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="lasik_treatment_limit_value" id="lasik_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="lasik_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div> -->
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="psychiatry_mental_illness_treatment">Psychiatry mental illness treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'psychiatry_mental_illness_treatment_div')" (mouseleave)="mouseLeave($event, 'psychiatry_mental_illness_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="psychiatry_mental_illness_treatment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Psychiatry mental illness treatment</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_psychiatry_mental_illness_treatment" formControlName="psychiatry_mental_illness_treatment" name="psychiatry_mental_illness_treatment" name="psychiatry_mental_illness_treatment" value="Not Covered" checked (change)="checkradio('divpsychiatry_mental_illness_treatment_limit', 'NotCovered'); green_red_change('psychiatry_mental_illness_treatment', 'no')" />
                                            <label for="radio-two_psychiatry_mental_illness_treatment" id="psychiatry_mental_illness_treatment_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_psychiatry_mental_illness_treatment" formControlName="psychiatry_mental_illness_treatment" name="psychiatry_mental_illness_treatment" value="Covered" (change)="checkradio('divpsychiatry_mental_illness_treatment_limit', 'Covered'); green_red_change('psychiatry_mental_illness_treatment', 'yes')" />
                                            <label for="radio-one_psychiatry_mental_illness_treatment" id="psychiatry_mental_illness_treatment_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divpsychiatry_mental_illness_treatment_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="psychiatry_mental_illness_treatment_limit_perc" id="psychiatry_mental_illness_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="psychiatry_mental_illness_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_psychiatry_mental_illness_treatment" value="min_psychiatry_mental_illness_treatment" formControlName="min_max_psychiatry_mental_illness_treatment" name="min_max_psychiatry_mental_illness_treatment" type="radio" (click)="check_limit_toggle('psychiatry_mental_illness_treatment','yes')" />
                                                    <label for="min_psychiatry_mental_illness_treatment" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_psychiatry_mental_illness_treatment" value="na_psychiatry_mental_illness_treatment" formControlName="min_max_psychiatry_mental_illness_treatment" name="min_max_psychiatry_mental_illness_treatment" type="radio" (click)="check_limit_toggle('psychiatry_mental_illness_treatment','no')" />
                                                    <label for="na_psychiatry_mental_illness_treatment" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_psychiatry_mental_illness_treatment" value="max_psychiatry_mental_illness_treatment" formControlName="min_max_psychiatry_mental_illness_treatment" name="min_max_psychiatry_mental_illness_treatment" type="radio" (click)="check_limit_toggle('psychiatry_mental_illness_treatment','yes')" />
                                                    <label for="max_psychiatry_mental_illness_treatment" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 psychiatry_mental_illness_treatment_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="psychiatry_mental_illness_treatment_limit_value" id="psychiatry_mental_illness_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="psychiatry_mental_illness_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="endoscopic_sinus_surgery">Endoscopic sinus surgery <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'endoscopic_sinus_surgery_div')" (mouseleave)="mouseLeave($event, 'endoscopic_sinus_surgery_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="endoscopic_sinus_surgery_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Endoscopic sinus surgery</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_endoscopic_sinus_surgery" formControlName="endoscopic_sinus_surgery" name="endoscopic_sinus_surgery" name="endoscopic_sinus_surgery" value="Not Covered" checked (change)="checkradio('divendoscopic_sinus_surgery_limit', 'NotCovered'); green_red_change('endoscopic_sinus_surgery', 'no')" />
                                            <label for="radio-two_endoscopic_sinus_surgery" id="endoscopic_sinus_surgery_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_endoscopic_sinus_surgery" formControlName="endoscopic_sinus_surgery" name="endoscopic_sinus_surgery" value="Covered" (change)="checkradio('divendoscopic_sinus_surgery_limit', 'Covered'); green_red_change('endoscopic_sinus_surgery', 'yes')" />
                                            <label for="radio-one_endoscopic_sinus_surgery" id="endoscopic_sinus_surgery_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divendoscopic_sinus_surgery_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="endoscopic_sinus_surgery_limit_perc" id="endoscopic_sinus_surgery_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="endoscopic_sinus_surgery_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_endoscopic_sinus_surgery" value="min_endoscopic_sinus_surgery" formControlName="min_max_endoscopic_sinus_surgery" name="min_max_endoscopic_sinus_surgery" type="radio" (click)="check_limit_toggle('endoscopic_sinus_surgery','yes')" />
                                                    <label for="min_endoscopic_sinus_surgery" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_endoscopic_sinus_surgery" value="na_endoscopic_sinus_surgery" formControlName="min_max_endoscopic_sinus_surgery" name="min_max_endoscopic_sinus_surgery" type="radio" (click)="check_limit_toggle('endoscopic_sinus_surgery','no')" />
                                                    <label for="na_endoscopic_sinus_surgery" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_endoscopic_sinus_surgery" value="max_endoscopic_sinus_surgery" formControlName="min_max_endoscopic_sinus_surgery" name="min_max_endoscopic_sinus_surgery" type="radio" (click)="check_limit_toggle('endoscopic_sinus_surgery','yes')" />
                                                    <label for="max_endoscopic_sinus_surgery" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 endoscopic_sinus_surgery_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="endoscopic_sinus_surgery_limit_value" id="endoscopic_sinus_surgery_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="endoscopic_sinus_surgery_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="internal_congenital">Internal congenital <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'internal_congenital_div')" (mouseleave)="mouseLeave($event, 'internal_congenital_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="internal_congenital_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Internal congenital</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_internal_congenital" formControlName="internal_congenital" name="internal_congenital" name="internal_congenital" value="Not Covered" checked (change)="checkradio('divinternal_congenital_limit', 'NotCovered'); green_red_change('internal_congenital', 'no')" />
                                            <label for="radio-two_internal_congenital" id="internal_congenital_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_internal_congenital" formControlName="internal_congenital" name="internal_congenital" value="Covered" (change)="checkradio('divinternal_congenital_limit', 'Covered'); green_red_change('internal_congenital', 'yes')" />
                                            <label for="radio-one_internal_congenital" id="internal_congenital_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divinternal_congenital_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="internal_congenital_limit_perc" id="internal_congenital_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="internal_congenital_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_internal_congenital" value="min_internal_congenital" formControlName="min_max_internal_congenital" name="min_max_internal_congenital" type="radio" (click)="check_limit_toggle('internal_congenital','yes')" />
                                                    <label for="min_internal_congenital" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_internal_congenital" value="na_internal_congenital" formControlName="min_max_internal_congenital" name="min_max_internal_congenital" type="radio" (click)="check_limit_toggle('internal_congenital','no')" />
                                                    <label for="na_internal_congenital" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_internal_congenital" value="max_internal_congenital" formControlName="min_max_internal_congenital" name="min_max_internal_congenital" type="radio" (click)="check_limit_toggle('internal_congenital','yes')" />
                                                    <label for="max_internal_congenital" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 internal_congenital_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="internal_congenital_limit_value" id="internal_congenital_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="internal_congenital_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="external_congenital">External congenital <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'external_congenital_div')" (mouseleave)="mouseLeave($event, 'external_congenital_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="external_congenital_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">External congenital</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_external_congenital" formControlName="external_congenital" name="external_congenital" name="external_congenital" value="Not Covered" checked (change)="checkradio('divexternal_congenital_limit', 'NotCovered'); green_red_change('external_congenital', 'no')" />
                                            <label for="radio-two_external_congenital" id="external_congenital_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_external_congenital" formControlName="external_congenital" name="external_congenital" value="Covered" (change)="checkradio('divexternal_congenital_limit', 'Covered'); green_red_change('external_congenital', 'yes')" />
                                            <label for="radio-one_external_congenital" id="external_congenital_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divexternal_congenital_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="external_congenital_limit_perc" id="external_congenital_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="external_congenital_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_external_congenital" value="min_external_congenital" formControlName="min_max_external_congenital" name="min_max_external_congenital" type="radio" (click)="check_limit_toggle('external_congenital','yes')" />
                                                    <label for="min_external_congenital" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_external_congenital" value="na_external_congenital" formControlName="min_max_external_congenital" name="min_max_external_congenital" type="radio" (click)="check_limit_toggle('external_congenital','no')" />
                                                    <label for="na_external_congenital" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_external_congenital" value="max_external_congenital" formControlName="min_max_external_congenital" name="min_max_external_congenital" type="radio" (click)="check_limit_toggle('external_congenital','yes')" />
                                                    <label for="max_external_congenital" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 external_congenital_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="external_congenital_limit_value" id="external_congenital_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="external_congenital_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 col-md-12 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="portability_cover">Portability cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'portability_cover_div')" (mouseleave)="mouseLeave($event, 'portability_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="portability_cover_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Portability cover</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_portability_cover" formControlName="portability_cover" name="portability_cover" value="Not Covered" (change)="checkradio('divportability_cover_limit', 'NotCovered'); green_red_change('portability_cover', 'no')" />
                                            <label for="radio-two_portability_cover" id="portability_cover_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_portability_cover" formControlName="portability_cover" name="portability_cover" value="Covered" (change)="checkradio('divportability_cover_limit', 'Covered'); green_red_change('portability_cover', 'yes')" />
                                            <label for="radio-one_portability_cover" id="portability_cover_yes" class="bs-form-label">Covered</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <label class="bs-form-label" for="corporate_buffer">Corporate buffer <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'corporate_buffer_div')" (mouseleave)="mouseLeave($event, 'corporate_buffer_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="corporate_buffer_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Corporate buffer</p>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_corporate_buffer" formControlName="corporate_buffer" name="corporate_buffer" value="Not Covered" (change)="checkradio('divcorporate_buffer', 'NotCovered'); green_red_change('corporate_buffer', 'no')" />
                                            <label for="radio-two_corporate_buffer" id="corporate_buffer_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_corporate_buffer" formControlName="corporate_buffer" name="corporate_buffer" value="Covered" (change)="checkradio('divcorporate_buffer', 'Covered'); green_red_change('corporate_buffer', 'yes')" />
                                            <label for="radio-one_corporate_buffer" id="corporate_buffer_yes" class="bs-form-label">Covered</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 form-group divcorporate_buffer" style="display: none">
                                        <label class="bs-form-label" for="corporate_buffer_limit">Is Covered</label>
                                        <div class="switch-field switch-field_mobile_height cb" style="height:36px;">
                                            <input type="radio" id="radio-two_corporate_buffer_isCover" formControlName="corporate_buffer_isCover" name="corporate_buffer_isCover" value="Applicable for Critical illness" />
                                            <label for="radio-two_corporate_buffer_isCover" class="bs-form-label">Applicable for Critical illness</label>
                                            <input type="radio" id="radio-one_corporate_buffer_isCover" formControlName="corporate_buffer_isCover" name="corporate_buffer_isCover" value="Applicable for all illness" />
                                            <label for="radio-one_corporate_buffer_isCover" class="bs-form-label">Applicable for all illness</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-2 form-group divcorporate_buffer" style="display: none; margin-bottom: 20px;">
                                        <label class="bs-form-label" for="corporate_buffer_limit">Policy Limit *</label>
                                        <input type="text" class="form-control" formControlName="corporate_buffer_limit" id="corporate_buffer_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="corporate_buffer_limit_error" style="display:none; position: absolute;">
                                            This field is required 
                                        </p>
                                    </div>
                                    <div class="col-sm-2 form-group divcorporate_buffer" style="display: none">
                                        <label class="bs-form-label" for="corporate_buffer_family_limit">Family Limit *</label>
                                        <input type="text" class="form-control" formControlName="corporate_buffer_family_limit" id="corporate_buffer_family_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="corporate_buffer_family_limit_error" style="display:none; position: absolute;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>                                                                                                

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 col-md-12 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="pandemic_and_epidemic">Pandemic and epidemic <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'pandemic_and_epidemic_div')" (mouseleave)="mouseLeave($event, 'pandemic_and_epidemic_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="pandemic_and_epidemic_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Pandemic and epidemic</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_pandemic_and_epidemic" formControlName="pandemic_and_epidemic" name="pandemic_and_epidemic" value="Not Covered" (change)="checkradio('divpandemic_and_epidemic_limit', 'NotCovered'); green_red_change('pandemic_and_epidemic', 'no')" />
                                            <label for="radio-two_pandemic_and_epidemic" id="pandemic_and_epidemic_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_pandemic_and_epidemic" formControlName="pandemic_and_epidemic" name="pandemic_and_epidemic" value="Covered" (change)="checkradio('divpandemic_and_epidemic_limit', 'Covered'); green_red_change('pandemic_and_epidemic', 'yes')" />
                                            <label for="radio-one_pandemic_and_epidemic" id="pandemic_and_epidemic_yes" class="bs-form-label">Covered</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="autism">Autism <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'autism_div')" (mouseleave)="mouseLeave($event, 'autism_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="autism_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Autism</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_autism" formControlName="autism" name="autism" name="autism" value="Not Covered" checked (change)="checkradio('divautism_limit', 'NotCovered'); green_red_change('autism', 'no')" />
                                            <label for="radio-two_autism" id="autism_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_autism" formControlName="autism" name="autism" value="Covered" (change)="checkradio('divautism_limit', 'Covered'); green_red_change('autism', 'yes')" />
                                            <label for="radio-one_autism" id="autism_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divautism_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="autism_limit_perc" id="autism_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="autism_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_autism" value="min_autism" formControlName="min_max_autism" name="min_max_autism" type="radio" (click)="check_limit_toggle('autism','yes')" />
                                                    <label for="min_autism" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_autism" value="na_autism" formControlName="min_max_autism" name="min_max_autism" type="radio" (click)="check_limit_toggle('autism','no')" />
                                                    <label for="na_autism" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_autism" value="max_autism" formControlName="min_max_autism" name="min_max_autism" type="radio" (click)="check_limit_toggle('autism','yes')" />
                                                    <label for="max_autism" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 autism_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="autism_limit_value" id="autism_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="autism_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="bariatric_surgery">Bariatric Surgery <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'bariatric_surgery_div')" (mouseleave)="mouseLeave($event, 'bariatric_surgery_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="bariatric_surgery_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Bariatric Surgery</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_bariatric_surgery" formControlName="bariatric_surgery" name="bariatric_surgery" name="bariatric_surgery" value="Not Covered" checked (change)="checkradio('divbariatric_surgery_limit', 'NotCovered'); green_red_change('bariatric_surgery', 'no')" />
                                            <label for="radio-two_bariatric_surgery" id="bariatric_surgery_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_bariatric_surgery" formControlName="bariatric_surgery" name="bariatric_surgery" value="Covered" (change)="checkradio('divbariatric_surgery_limit', 'Covered'); green_red_change('bariatric_surgery', 'yes')" />
                                            <label for="radio-one_bariatric_surgery" id="bariatric_surgery_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divbariatric_surgery_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="bariatric_surgery_limit_perc" id="bariatric_surgery_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="bariatric_surgery_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_bariatric_surgery" value="min_bariatric_surgery" formControlName="min_max_bariatric_surgery" name="min_max_bariatric_surgery" type="radio" (click)="check_limit_toggle('bariatric_surgery','yes')" />
                                                    <label for="min_bariatric_surgery" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_bariatric_surgery" value="na_bariatric_surgery" formControlName="min_max_bariatric_surgery" name="min_max_bariatric_surgery" type="radio" (click)="check_limit_toggle('bariatric_surgery','no')" />
                                                    <label for="na_bariatric_surgery" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_bariatric_surgery" value="max_bariatric_surgery" formControlName="min_max_bariatric_surgery" name="min_max_bariatric_surgery" type="radio" (click)="check_limit_toggle('bariatric_surgery','yes')" />
                                                    <label for="max_bariatric_surgery" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 bariatric_surgery_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="bariatric_surgery_limit_value" id="bariatric_surgery_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="bariatric_surgery_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="cyberknife_treatment">Cyberknife treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'cyberknife_treatment_div')" (mouseleave)="mouseLeave($event, 'cyberknife_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="cyberknife_treatment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Cyberknife treatment</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_cyberknife_treatment" formControlName="cyberknife_treatment" name="cyberknife_treatment" name="cyberknife_treatment" value="Not Covered" checked (change)="checkradio('divcyberknife_treatment_limit', 'NotCovered'); green_red_change('cyberknife_treatment', 'no')" />
                                            <label for="radio-two_cyberknife_treatment" id="cyberknife_treatment_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_cyberknife_treatment" formControlName="cyberknife_treatment" name="cyberknife_treatment" value="Covered" (change)="checkradio('divcyberknife_treatment_limit', 'Covered'); green_red_change('cyberknife_treatment', 'yes')" />
                                            <label for="radio-one_cyberknife_treatment" id="cyberknife_treatment_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divcyberknife_treatment_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="cyberknife_treatment_limit_perc" id="cyberknife_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="cyberknife_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_cyberknife_treatment" value="min_cyberknife_treatment" formControlName="min_max_cyberknife_treatment" name="min_max_cyberknife_treatment" type="radio" (click)="check_limit_toggle('cyberknife_treatment','yes')" />
                                                    <label for="min_cyberknife_treatment" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_cyberknife_treatment" value="na_cyberknife_treatment" formControlName="min_max_cyberknife_treatment" name="min_max_cyberknife_treatment" type="radio" (click)="check_limit_toggle('cyberknife_treatment','no')" />
                                                    <label for="na_cyberknife_treatment" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_cyberknife_treatment" value="max_cyberknife_treatment" formControlName="min_max_cyberknife_treatment" name="min_max_cyberknife_treatment" type="radio" (click)="check_limit_toggle('cyberknife_treatment','yes')" />
                                                    <label for="max_cyberknife_treatment" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 cyberknife_treatment_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="cyberknife_treatment_limit_value" id="cyberknife_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="cyberknife_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="gender_affirmation">Gender affirmation <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'gender_affirmation_div')" (mouseleave)="mouseLeave($event, 'gender_affirmation_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="gender_affirmation_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Gender affirmation</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_gender_affirmation" formControlName="gender_affirmation" name="gender_affirmation" name="gender_affirmation" value="Not Covered" checked (change)="checkradio('divgender_affirmation_limit', 'NotCovered'); green_red_change('gender_affirmation', 'no')" />
                                            <label for="radio-two_gender_affirmation" id="gender_affirmation_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_gender_affirmation" formControlName="gender_affirmation" name="gender_affirmation" value="Covered" (change)="checkradio('divgender_affirmation_limit', 'Covered'); green_red_change('gender_affirmation', 'yes')" />
                                            <label for="radio-one_gender_affirmation" id="gender_affirmation_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divgender_affirmation_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="gender_affirmation_limit_perc" id="gender_affirmation_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="gender_affirmation_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_gender_affirmation" value="min_gender_affirmation" formControlName="min_max_gender_affirmation" name="min_max_gender_affirmation" type="radio" (click)="check_limit_toggle('gender_affirmation','yes')" />
                                                    <label for="min_gender_affirmation" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_gender_affirmation" value="na_gender_affirmation" formControlName="min_max_gender_affirmation" name="min_max_gender_affirmation" type="radio" (click)="check_limit_toggle('gender_affirmation','no')" />
                                                    <label for="na_gender_affirmation" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_gender_affirmation" value="max_gender_affirmation" formControlName="min_max_gender_affirmation" name="min_max_gender_affirmation" type="radio" (click)="check_limit_toggle('gender_affirmation','yes')" />
                                                    <label for="max_gender_affirmation" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 gender_affirmation_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="gender_affirmation_limit_value" id="gender_affirmation_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="gender_affirmation_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="hormonal_therapy_for_cancer">Hormonal therapy for cancer <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'hormonal_therapy_for_cancer_div')" (mouseleave)="mouseLeave($event, 'hormonal_therapy_for_cancer_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="hormonal_therapy_for_cancer_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Hormonal therapy for cancer</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_hormonal_therapy_for_cancer" formControlName="hormonal_therapy_for_cancer" name="hormonal_therapy_for_cancer" name="hormonal_therapy_for_cancer" value="Not Covered" checked (change)="checkradio('divhormonal_therapy_for_cancer_limit', 'NotCovered'); green_red_change('hormonal_therapy_for_cancer', 'no')" />
                                            <label for="radio-two_hormonal_therapy_for_cancer" id="hormonal_therapy_for_cancer_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_hormonal_therapy_for_cancer" formControlName="hormonal_therapy_for_cancer" name="hormonal_therapy_for_cancer" value="Covered" (change)="checkradio('divhormonal_therapy_for_cancer_limit', 'Covered'); green_red_change('hormonal_therapy_for_cancer', 'yes')" />
                                            <label for="radio-one_hormonal_therapy_for_cancer" id="hormonal_therapy_for_cancer_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divhormonal_therapy_for_cancer_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="hormonal_therapy_for_cancer_limit_perc" id="hormonal_therapy_for_cancer_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="hormonal_therapy_for_cancer_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_hormonal_therapy_for_cancer" value="min_hormonal_therapy_for_cancer" formControlName="min_max_hormonal_therapy_for_cancer" name="min_max_hormonal_therapy_for_cancer" type="radio" (click)="check_limit_toggle('hormonal_therapy_for_cancer','yes')" />
                                                    <label for="min_hormonal_therapy_for_cancer" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_hormonal_therapy_for_cancer" value="na_hormonal_therapy_for_cancer" formControlName="min_max_hormonal_therapy_for_cancer" name="min_max_hormonal_therapy_for_cancer" type="radio" (click)="check_limit_toggle('hormonal_therapy_for_cancer','no')" />
                                                    <label for="na_hormonal_therapy_for_cancer" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_hormonal_therapy_for_cancer" value="max_hormonal_therapy_for_cancer" formControlName="min_max_hormonal_therapy_for_cancer" name="min_max_hormonal_therapy_for_cancer" type="radio" (click)="check_limit_toggle('hormonal_therapy_for_cancer','yes')" />
                                                    <label for="max_hormonal_therapy_for_cancer" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 hormonal_therapy_for_cancer_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="hormonal_therapy_for_cancer_limit_value" id="hormonal_therapy_for_cancer_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="hormonal_therapy_for_cancer_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="prosthetic_devices">Prosthetic devices <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'prosthetic_devices_div')" (mouseleave)="mouseLeave($event, 'prosthetic_devices_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="prosthetic_devices_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Prosthetic devices</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_prosthetic_devices" formControlName="prosthetic_devices" name="prosthetic_devices" name="prosthetic_devices" value="Not Covered" checked (change)="checkradio('divprosthetic_devices_limit', 'NotCovered'); green_red_change('prosthetic_devices', 'no')" />
                                            <label for="radio-two_prosthetic_devices" id="prosthetic_devices_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_prosthetic_devices" formControlName="prosthetic_devices" name="prosthetic_devices" value="Covered" (change)="checkradio('divprosthetic_devices_limit', 'Covered'); green_red_change('prosthetic_devices', 'yes')" />
                                            <label for="radio-one_prosthetic_devices" id="prosthetic_devices_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divprosthetic_devices_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="prosthetic_devices_limit_perc" id="prosthetic_devices_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="prosthetic_devices_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_prosthetic_devices" value="min_prosthetic_devices" formControlName="min_max_prosthetic_devices" name="min_max_prosthetic_devices" type="radio" (click)="check_limit_toggle('prosthetic_devices','yes')" />
                                                    <label for="min_prosthetic_devices" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_prosthetic_devices" value="na_prosthetic_devices" formControlName="min_max_prosthetic_devices" name="min_max_prosthetic_devices" type="radio" (click)="check_limit_toggle('prosthetic_devices','no')" />
                                                    <label for="na_prosthetic_devices" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_prosthetic_devices" value="max_prosthetic_devices" formControlName="min_max_prosthetic_devices" name="min_max_prosthetic_devices" type="radio" (click)="check_limit_toggle('prosthetic_devices','yes')" />
                                                    <label for="max_prosthetic_devices" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 prosthetic_devices_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="prosthetic_devices_limit_value" id="prosthetic_devices_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="prosthetic_devices_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="robotic_surgery">Robotic surgery <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'robotic_surgery_div')" (mouseleave)="mouseLeave($event, 'robotic_surgery_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="robotic_surgery_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Robotic surgery</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_robotic_surgery" formControlName="robotic_surgery" name="robotic_surgery" name="robotic_surgery" value="Not Covered" checked (change)="checkradio('divrobotic_surgery_limit', 'NotCovered'); green_red_change('robotic_surgery', 'no')" />
                                            <label for="radio-two_robotic_surgery" id="robotic_surgery_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_robotic_surgery" formControlName="robotic_surgery" name="robotic_surgery" value="Covered" (change)="checkradio('divrobotic_surgery_limit', 'Covered'); green_red_change('robotic_surgery', 'yes')" />
                                            <label for="radio-one_robotic_surgery" id="robotic_surgery_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divrobotic_surgery_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="robotic_surgery_limit_perc" id="robotic_surgery_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="robotic_surgery_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_robotic_surgery" value="min_robotic_surgery" formControlName="min_max_robotic_surgery" name="min_max_robotic_surgery" type="radio" (click)="check_limit_toggle('robotic_surgery','yes')" />
                                                    <label for="min_robotic_surgery" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_robotic_surgery" value="na_robotic_surgery" formControlName="min_max_robotic_surgery" name="min_max_robotic_surgery" type="radio" (click)="check_limit_toggle('robotic_surgery','no')" />
                                                    <label for="na_robotic_surgery" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_robotic_surgery" value="max_robotic_surgery" formControlName="min_max_robotic_surgery" name="min_max_robotic_surgery" type="radio" (click)="check_limit_toggle('robotic_surgery','yes')" />
                                                    <label for="max_robotic_surgery" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 robotic_surgery_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="robotic_surgery_limit_value" id="robotic_surgery_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="robotic_surgery_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="sexually_transmitted_diseases">Sexually transmitted diseases <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sexually_transmitted_diseases_div')" (mouseleave)="mouseLeave($event, 'sexually_transmitted_diseases_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="sexually_transmitted_diseases_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Sexually transmitted diseases</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_sexually_transmitted_diseases" formControlName="sexually_transmitted_diseases" name="sexually_transmitted_diseases" name="sexually_transmitted_diseases" value="Not Covered" checked (change)="checkradio('divsexually_transmitted_diseases_limit', 'NotCovered'); green_red_change('sexually_transmitted_diseases', 'no')" />
                                            <label for="radio-two_sexually_transmitted_diseases" id="sexually_transmitted_diseases_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_sexually_transmitted_diseases" formControlName="sexually_transmitted_diseases" name="sexually_transmitted_diseases" value="Covered" (change)="checkradio('divsexually_transmitted_diseases_limit', 'Covered'); green_red_change('sexually_transmitted_diseases', 'yes')" />
                                            <label for="radio-one_sexually_transmitted_diseases" id="sexually_transmitted_diseases_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divsexually_transmitted_diseases_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="sexually_transmitted_diseases_limit_perc" id="sexually_transmitted_diseases_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="sexually_transmitted_diseases_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_sexually_transmitted_diseases" value="min_sexually_transmitted_diseases" formControlName="min_max_sexually_transmitted_diseases" name="min_max_sexually_transmitted_diseases" type="radio" (click)="check_limit_toggle('sexually_transmitted_diseases','yes')" />
                                                    <label for="min_sexually_transmitted_diseases" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_sexually_transmitted_diseases" value="na_sexually_transmitted_diseases" formControlName="min_max_sexually_transmitted_diseases" name="min_max_sexually_transmitted_diseases" type="radio" (click)="check_limit_toggle('sexually_transmitted_diseases','no')" />
                                                    <label for="na_sexually_transmitted_diseases" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_sexually_transmitted_diseases" value="max_sexually_transmitted_diseases" formControlName="min_max_sexually_transmitted_diseases" name="min_max_sexually_transmitted_diseases" type="radio" (click)="check_limit_toggle('sexually_transmitted_diseases','yes')" />
                                                    <label for="max_sexually_transmitted_diseases" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 sexually_transmitted_diseases_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="sexually_transmitted_diseases_limit_value" id="sexually_transmitted_diseases_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="sexually_transmitted_diseases_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="sleep_apnea">Sleep apnea <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sleep_apnea_div')" (mouseleave)="mouseLeave($event, 'sleep_apnea_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="sleep_apnea_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Sleep apnea</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_sleep_apnea" formControlName="sleep_apnea" name="sleep_apnea" name="sleep_apnea" value="Not Covered" checked (change)="checkradio('divsleep_apnea_limit', 'NotCovered'); green_red_change('sleep_apnea', 'no')" />
                                            <label for="radio-two_sleep_apnea" id="sleep_apnea_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_sleep_apnea" formControlName="sleep_apnea" name="sleep_apnea" value="Covered" (change)="checkradio('divsleep_apnea_limit', 'Covered'); green_red_change('sleep_apnea', 'yes')" />
                                            <label for="radio-one_sleep_apnea" id="sleep_apnea_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divsleep_apnea_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="sleep_apnea_limit_perc" id="sleep_apnea_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="sleep_apnea_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_sleep_apnea" value="min_sleep_apnea" formControlName="min_max_sleep_apnea" name="min_max_sleep_apnea" type="radio" (click)="check_limit_toggle('sleep_apnea','yes')" />
                                                    <label for="min_sleep_apnea" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_sleep_apnea" value="na_sleep_apnea" formControlName="min_max_sleep_apnea" name="min_max_sleep_apnea" type="radio" (click)="check_limit_toggle('sleep_apnea','no')" />
                                                    <label for="na_sleep_apnea" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_sleep_apnea" value="max_sleep_apnea" formControlName="min_max_sleep_apnea" name="min_max_sleep_apnea" type="radio" (click)="check_limit_toggle('sleep_apnea','yes')" />
                                                    <label for="max_sleep_apnea" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 sleep_apnea_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="sleep_apnea_limit_value" id="sleep_apnea_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="sleep_apnea_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="stem_cell_treatment">Stem Cell treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'stem_cell_treatment_div')" (mouseleave)="mouseLeave($event, 'stem_cell_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="stem_cell_treatment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Stem Cell treatment</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_stem_cell_treatment" formControlName="stem_cell_treatment" name="stem_cell_treatment" name="stem_cell_treatment" value="Not Covered" checked (change)="checkradio('divstem_cell_treatment_limit', 'NotCovered'); green_red_change('stem_cell_treatment', 'no')" />
                                            <label for="radio-two_stem_cell_treatment" id="stem_cell_treatment_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_stem_cell_treatment" formControlName="stem_cell_treatment" name="stem_cell_treatment" value="Covered" (change)="checkradio('divstem_cell_treatment_limit', 'Covered'); green_red_change('stem_cell_treatment', 'yes')" />
                                            <label for="radio-one_stem_cell_treatment" id="stem_cell_treatment_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divstem_cell_treatment_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="stem_cell_treatment_limit_perc" id="stem_cell_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="stem_cell_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_stem_cell_treatment" value="min_stem_cell_treatment" formControlName="min_max_stem_cell_treatment" name="min_max_stem_cell_treatment" type="radio" (click)="check_limit_toggle('stem_cell_treatment','yes')" />
                                                    <label for="min_stem_cell_treatment" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_stem_cell_treatment" value="na_stem_cell_treatment" formControlName="min_max_stem_cell_treatment" name="min_max_stem_cell_treatment" type="radio" (click)="check_limit_toggle('stem_cell_treatment','no')" />
                                                    <label for="na_stem_cell_treatment" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_stem_cell_treatment" value="max_stem_cell_treatment" formControlName="min_max_stem_cell_treatment" name="min_max_stem_cell_treatment" type="radio" (click)="check_limit_toggle('stem_cell_treatment','yes')" />
                                                    <label for="max_stem_cell_treatment" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 stem_cell_treatment_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="stem_cell_treatment_limit_value" id="stem_cell_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="stem_cell_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12">
                                    <div class="row newothersField">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addOthersField()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add New Field</span>
                                    </label>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->

                <!---- Ambulance Cover Accordian ---->
                <h5 class="bs-left-form-label accordheading ambulanceheading" (click)="Ambulanceaccord()">Ambulance Cover
                    <img id="AmbulanceCover_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="AmbulanceCover_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/ambulance.png" class="headicon" />
                    <img id="ambulancetick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="ambulancetick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgAmbulance">
                        <div class="value-container" id="ValueAmbulance">0%</div>
                    </div>                    
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="AmbulanceCover_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Ambulance Cover</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">

                                <div class="col-sm-12 row form-group m-0 p-0">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="road_ambulance">Road ambulance <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'road_ambulance_div')" (mouseleave)="mouseLeave($event, 'road_ambulance_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="road_ambulance_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Road ambulance</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_road_ambulance" formControlName="road_ambulance" name="road_ambulance" name="road_ambulance" value="Not Covered" checked (change)="checkradio('divroad_ambulance_limit', 'NotCovered'); green_red_change('road_ambulance', 'no')" />
                                            <label for="radio-two_road_ambulance" id="road_ambulance_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_road_ambulance" formControlName="road_ambulance" name="road_ambulance" value="Covered" (change)="checkradio('divroad_ambulance_limit', 'Covered'); green_red_change('road_ambulance', 'yes')" />
                                            <label for="radio-one_road_ambulance" id="road_ambulance_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divroad_ambulance_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="road_ambulance_limit_perc" id="road_ambulance_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="road_ambulance_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_road_ambulance" value="min_road_ambulance" formControlName="min_max_road_ambulance" name="min_max_road_ambulance" type="radio" (click)="check_limit_toggle('road_ambulance','yes')" />
                                                    <label for="min_road_ambulance" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_road_ambulance" value="na_road_ambulance" formControlName="min_max_road_ambulance" name="min_max_road_ambulance" type="radio" (click)="check_limit_toggle('road_ambulance','no')" />
                                                    <label for="na_road_ambulance" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_road_ambulance" value="max_road_ambulance" formControlName="min_max_road_ambulance" name="min_max_road_ambulance" type="radio" (click)="check_limit_toggle('road_ambulance','yes')" />
                                                    <label for="max_road_ambulance" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 road_ambulance_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="road_ambulance_limit_value" id="road_ambulance_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="road_ambulance_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="cardiac_ambulance">Cardiac ambulance <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'cardiac_ambulance_div')" (mouseleave)="mouseLeave($event, 'cardiac_ambulance_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="cardiac_ambulance_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Cardiac ambulance</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_cardiac_ambulance" formControlName="cardiac_ambulance" name="cardiac_ambulance" name="cardiac_ambulance" value="Not Covered" checked (change)="checkradio('divcardiac_ambulance_limit', 'NotCovered'); green_red_change('cardiac_ambulance', 'no')" />
                                            <label for="radio-two_cardiac_ambulance" id="cardiac_ambulance_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_cardiac_ambulance" formControlName="cardiac_ambulance" name="cardiac_ambulance" value="Covered" (change)="checkradio('divcardiac_ambulance_limit', 'Covered'); green_red_change('cardiac_ambulance', 'yes')" />
                                            <label for="radio-one_cardiac_ambulance" id="cardiac_ambulance_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divcardiac_ambulance_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="cardiac_ambulance_limit_perc" id="cardiac_ambulance_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="cardiac_ambulance_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_cardiac_ambulance" value="min_cardiac_ambulance" formControlName="min_max_cardiac_ambulance" name="min_max_cardiac_ambulance" type="radio" (click)="check_limit_toggle('cardiac_ambulance','yes')" />
                                                    <label for="min_cardiac_ambulance" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_cardiac_ambulance" value="na_cardiac_ambulance" formControlName="min_max_cardiac_ambulance" name="min_max_cardiac_ambulance" type="radio" (click)="check_limit_toggle('cardiac_ambulance','no')" />
                                                    <label for="na_cardiac_ambulance" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_cardiac_ambulance" value="max_cardiac_ambulance" formControlName="min_max_cardiac_ambulance" name="min_max_cardiac_ambulance" type="radio" (click)="check_limit_toggle('cardiac_ambulance','yes')" />
                                                    <label for="max_cardiac_ambulance" >Max</label>
                                                </div>                                              
                                            </div>
    
                                            <div class="col-sm-4 cardiac_ambulance_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="cardiac_ambulance_limit_value" id="cardiac_ambulance_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="cardiac_ambulance_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-3 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="air_ambulance">Air ambulance <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'air_ambulance_div')" (mouseleave)="mouseLeave($event, 'air_ambulance_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="air_ambulance_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Air ambulance</p>
                                            </div>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_air_ambulance" formControlName="air_ambulance" name="air_ambulance" name="air_ambulance" value="Not Covered" checked (change)="checkradio('divair_ambulance_limit', 'NotCovered'); green_red_change('air_ambulance', 'no')" />
                                            <label for="radio-two_air_ambulance" id="air_ambulance_no" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_air_ambulance" formControlName="air_ambulance" name="air_ambulance" value="Covered" (change)="checkradio('divair_ambulance_limit', 'Covered'); green_red_change('air_ambulance', 'yes')" />
                                            <label for="radio-one_air_ambulance" id="air_ambulance_yes" class="bs-form-label">Covered</label>                                        
                                        </div>
                                    </div>
                                    <div class="col-sm-9 form-group" id="divair_ambulance_limit" style="display: none;padding-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-5" style="display:flex">
                                                <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="air_ambulance_limit_perc" id="air_ambulance_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                                &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                                <p class="error" id="air_ambulance_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>
    
                                            <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                                <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                    <input class="hiddenRadio" id="min_air_ambulance" value="min_air_ambulance" formControlName="min_max_air_ambulance" name="min_max_air_ambulance" type="radio" (click)="check_limit_toggle('air_ambulance','yes')" />
                                                    <label for="min_air_ambulance" >Min</label>   
    
                                                    <input class="hiddenRadio" id="na_air_ambulance" value="na_air_ambulance" formControlName="min_max_air_ambulance" name="min_max_air_ambulance" type="radio" (click)="check_limit_toggle('air_ambulance','no')" />
                                                    <label for="na_air_ambulance" class="disabled" >N/A</label>
                                                    
                                                    <input class="hiddenRadio" id="max_air_ambulance" value="max_air_ambulance" formControlName="min_max_air_ambulance" name="min_max_air_ambulance" type="radio" (click)="check_limit_toggle('air_ambulance','yes')" />
                                                    <label for="max_air_ambulance" >Max</label>
                                                </div>                                              
                                            </div>                                           
    
                                            <div class="col-sm-4 air_ambulance_limit_value" style="display:none">
                                                <input type="text" class="form-control" formControlName="air_ambulance_limit_value" id="air_ambulance_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                                &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                                <p class="error" id="air_ambulance_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                    This field is required 
                                                </p>
                                            </div>                                            

                                        </div>
                                    </div>                                
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!----------------------------->

                <!---- Premium Contribution Accordian ---->
                <h5 class="bs-left-form-label accordheading PCheading" (click)="PCaccord()">Premium Contribution
                    <img id="PC_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="PC_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/contribution.png" class="headicon" />
                    <img id="PCtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="PCtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgPC">
                        <div class="value-container" id="ValuePC">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="PC_accord" class="accord_div">
                    <div class="row">
                        <!-- <div class="col-sm-4">
                            <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <!-------Table----->
                            <!-- <div class="table-responsive PC_table" style="display:none">
                                <table class="table table-bordered table-sm row-border hover mt-2 w1000" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                    <thead style="border: #4e0099;">
                                        <tr style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                            <th>RelationShip</th>
                                            <th>Employee Contribution</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tbody_PC_Employees" style="display:none">
                                        <tr>
                                            <td class="bs-form-label">Employee</td>
                                            <td>
                                                <div style="display:flex">
                                                    <input type="text" class="form-control" formControlName="metrol_1" id="metrol_1" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                    
                                                </div>
                                                <p class="error" id="metrol_1_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                    This field is required
                                                </p>                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody id="tbody_PC_Spouse" style="display:none">
                                        <tr>
                                            <td class="bs-form-label">Spouse</td>
                                            <td>
                                                <div style="display:flex">
                                                    <input type="text" class="form-control" formControlName="metrol_2" id="metrol_2" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                    
                                                </div>
                                                <p class="error" id="metrol_2_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                    This field is required
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody id="tbody_PC_Childrens" style="display:none">
                                        <tr>
                                            <td class="bs-form-label">Children</td>
                                            <td>
                                                <div style="display:flex">
                                                    <input type="text" class="form-control" formControlName="metrol_3" id="metrol_3" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                    
                                                </div>
                                                <p class="error" id="metrol_3_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                    This field is required
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody id="tbody_PC_Parents" style="display:none">
                                        <tr>
                                            <td class="bs-form-label">Parents</td>
                                            <td>
                                                <div style="display:flex">
                                                    <input type="text" class="form-control" formControlName="metrol_4" id="metrol_4" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                    
                                                </div>
                                                <p class="error" id="metrol_4_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                    This field is required
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody id="tbody_PC_Siblings_or_disabled_siblings" style="display:none">
                                        <tr>
                                            <td class="bs-form-label">Siblings/disabled Siblings</td>
                                            <td>
                                                <div style="display:flex">
                                                    <input type="text" class="form-control" formControlName="metrol_5" id="metrol_5" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                    
                                                </div>
                                                <p class="error" id="metrol_5_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                    This field is required
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>                                    
                                </table>
                            </div> -->
                            <!------- END --------->


                            <!------------- TABLE and Accordian HOSP ------------------->
                            <div class="mt-3 PC_table" style="display:none">                                                                

                                <div class="col-sm-12 pb-2" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                        
                                    <div class="row m-0 p-1 TableAccord" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                        <div class="col-sm-6">
                                            RelationShip
                                        </div>
                                        <div class="col-sm-6">
                                            Employee Contribution
                                        </div>                                        
                                    </div>

                                    <div class="row my-2 mx-0" id="tbody_PC_Employees">

                                        <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" (click)="Mobileaccord(1)" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                            RelationShip - Employee                                            
                                        </h5>
                                        <label class="bs-form-label my-2 MobileAccord">Employee Contribution</label>

                                        <div id="MobileCover_accord" class="mobaccordDiv mt-3">
                                            <div class="col-sm-6 TableAccord">
                                                <div class="form-group" style="width: 100%;">
                                                    <label class="bs-form-label my-2">Employee</label>                                                													                                                    
                                                </div>
                                            </div>
                                            <div class="col-sm-6">                                                
                                                <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">
													<input type="text" class="form-control" formControlName="metrol_1" id="metrol_1" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                                                                    
                                                    <p class="error" id="metrol_1_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                        This field is required
                                                    </p>
												</div>
                                            </div>                                                                                        
                                        </div>
                                        
                                    </div>

									<div class="row my-2 mx-0" id="tbody_PC_Spouse">

                                        <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" (click)="Mobileaccord(2)" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                            RelationShip - Spouse
                                        </h5>

                                        <label class="bs-form-label my-2 MobileAccord">Employee Contribution</label>

                                        <div id="MobileCover_accord" class="mobaccordDiv">
                                            <div class="col-sm-6 TableAccord">
                                                <div class="form-group" style="width: 100%;">
                                                    <label class="bs-form-label my-2">Spouse</label>                                                													
                                                </div>
                                            </div>
                                            <div class="col-sm-6">                                                
                                                <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">                                                    
													<input type="text" class="form-control" formControlName="metrol_2" id="metrol_2" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                                                                     
                                                    <p class="error" id="metrol_2_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                        This field is required
                                                    </p>
												</div>
                                            </div>                                                                                        
                                        </div>
                                        
                                    </div>

                                    <div class="row my-2 mx-0" id="tbody_PC_Childrens">

                                        <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" (click)="Mobileaccord(2)" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                            RelationShip - Children
                                        </h5>

                                        <label class="bs-form-label my-2 MobileAccord">Employee Contribution</label>

                                        <div id="MobileCover_accord" class="mobaccordDiv">
                                            <div class="col-sm-6 TableAccord">
                                                <div class="form-group" style="width: 100%;">
                                                    <label class="bs-form-label my-2">Children</label>                                                													
                                                </div>
                                            </div>
                                            <div class="col-sm-6">                                                
                                                <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">                                                    
													<input type="text" class="form-control" formControlName="metrol_3" id="metrol_3" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                                                                     
                                                    <p class="error" id="metrol_3_error" style="display:none; margin-bottom: 0px; text-align: left;">
                                                        This field is required
                                                    </p>
												</div>
                                            </div>                                                                                        
                                        </div>
                                        
                                    </div>

                                    <div class="row my-2 mx-0" id="tbody_PC_Parents">

                                        <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" (click)="Mobileaccord(2)" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                            RelationShip - Parents
                                        </h5>

                                        <label class="bs-form-label my-2 MobileAccord">Employee Contribution</label>

                                        <div id="MobileCover_accord" class="mobaccordDiv">
                                            <div class="col-sm-6 TableAccord">
                                                <div class="form-group" style="width: 100%;">
                                                    <label class="bs-form-label my-2">Parents</label>                                                													
                                                </div>
                                            </div>
                                            <div class="col-sm-6">                                                
                                                <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">                                                    
													<input type="text" class="form-control" formControlName="metrol_4" id="metrol_4" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                                                                     
                                                    <p class="error" id="metrol_4_error" style="display:none; margin-bottom: 0px; text-align: left;">
														This field is required
													</p>
												</div>
                                            </div>                                                                                        
                                        </div>
                                        
                                    </div>

                                    <div class="row my-2 mx-0" id="tbody_PC_Siblings_or_disabled_siblings">

                                        <h5 class="bs-left-form-label accordheading Mobileheading mt-3 MobileAccord mobaccord" (click)="Mobileaccord(2)" style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                            RelationShip - Siblings
                                        </h5>

                                        <label class="bs-form-label my-2 MobileAccord">Employee Contribution</label>

                                        <div id="MobileCover_accord" class="mobaccordDiv">
                                            <div class="col-sm-6 TableAccord">
                                                <div class="form-group" style="width: 100%;">
                                                    <label class="bs-form-label my-2">Siblings</label>                                                													
                                                </div>
                                            </div>
                                            <div class="col-sm-6">                                                
                                                <div class="form-group metrodisplay" id="checkopt_table_room_rent_condition_one">                                                    
													<input type="text" class="form-control" formControlName="metrol_5" id="metrol_5" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" style="width:90%;height: 35px;" /> 
                                                    &nbsp; <span class="bs-radio-label mt-1">(%)</span>                                                                                                     
                                                    <p class="error" id="metrol_5_error" style="display:none; margin-bottom: 0px; text-align: left;">
														This field is required
													</p>
												</div>
                                            </div>                                                                                        
                                        </div>
                                        
                                    </div>
									
                                </div>
                            </div>
                            <!--------------- END ---------------------->

                        </div>

                    </div>
                </div>
                <!----------------------------->
                <hr>

                <div class="row">

                    <!-- <hr class="my-3"> -->
                    <div>
                        <button (click)="next(3);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <!-- <button (click)="next(3);" class="btn btn-primary" *ngIf="stepper_flag == false" style="float: right;">Finish</button> -->
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>

                </form>
            </div>

            <div id="test-l-4" class="content mt-3">                

                <form [formGroup]="documentUpload">
                <h3 class="bs-title">Document upload</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Document upload</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <!-- <div *ngIf="stepper_flag == false">
                            As New policy is selected no need for uploading any doc, directly click on Finish
                        </div> -->

                        <div class="row">     
                            
                            <div class="col-sm-12" *ngIf="stepper_flag == true">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="policy_document">Policy Document</label>

                                    <label for="file_field5" id="custom-file-upload_policy_document" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field5" type="file" (change)="policy_document_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_policy_document">Upload only PDF files (Max size 4MB)</small>
                            </div>

                            <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="member_data">Member Data Upload *</label>
                                    <!-- <input type="file" class="form-control" formControlName="member_data" (change)="member_data_change($event)" id="file" enctype="multipart/form-data" /> -->

                                    <label for="file_field6" id="custom-file-upload_member_data" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    
                                    <input id="file_field6" type="file" (change)="member_data_change($event)" formControlName="member_data" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none"
                                    [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors}"
                                    required />                           
                                    <ng-container
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors">
                                        <p class="error">
                                        Please upload member data
                                        </p>
                                    </ng-container>

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_member_data">Upload only .xlsx, .xls, .csv files (Max size 4MB)</small>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadMemberdata()">Download Sample File</span>
                            </div>                            

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3" *ngIf="stepper_flag == true">
                                <div class="col-sm-12 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="claims_in_current_year">Do you have claims in current year? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'claims_in_current_year_div')" (mouseleave)="mouseLeave($event, 'claims_in_current_year_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="claims_in_current_year_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Do you have claims in current year?</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_claims_in_current_year" formControlName="claims_in_current_year" name="claims_in_current_year" value="No" (change)="checkradio('divclaims_in_current_year_limit', 'NotCovered'); green_red_change('claims_in_current_year', 'no')" />
                                        <label for="radio-two_claims_in_current_year" id="claims_in_current_year_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_claims_in_current_year" formControlName="claims_in_current_year" name="claims_in_current_year" value="Yes" (change)="checkradio('divclaims_in_current_year_limit', 'Covered'); green_red_change('claims_in_current_year', 'yes')" />
                                        <label for="radio-one_claims_in_current_year" id="claims_in_current_year_yes" class="bs-form-label">Yes</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-12 form-group mt-3" id="divclaims_in_current_year_limit" style="display: none">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="claims_in_current_year">Upload Document</label>

                                            <label for="file_field9" id="custom-file-claims_in_current_year" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_claims_in_current_year" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                            </label>
                                            <input id="file_field9" type="file" (change)="claims_in_current_year_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                        </div>
                                        <small style="font-size: 12px; color:#605D62" id="small_claims_in_current_year">Upload only PDF files (Max size 4MB)</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3" *ngIf="stepper_flag == true">
                                <div class="col-sm-12 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="previous_two_years">Do you want to upload claims data of previous two years? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'claims_in_current_year_div')" (mouseleave)="mouseLeave($event, 'claims_in_current_year_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="claims_in_current_year_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Do you want to upload claims data of previous two years?</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_previous_two_years" formControlName="previous_two_years" name="previous_two_years" value="No" (change)="checkradio('divprevious_two_years_limit', 'NotCovered'); green_red_change('previous_two_years', 'no')" />
                                        <label for="radio-two_previous_two_years" id="previous_two_years_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_previous_two_years" formControlName="previous_two_years" name="previous_two_years" value="Yes" (change)="checkradio('divprevious_two_years_limit', 'Covered'); green_red_change('previous_two_years', 'yes')" />
                                        <label for="radio-one_previous_two_years" id="previous_two_years_yes" class="bs-form-label">Yes</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-12 form-group mt-3" id="divprevious_two_years_limit" style="display: none">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="previous_year_one">Previous Year One</label>

                                            <label for="file_field7" id="custom-file-previous_year_one" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_previous_year_one" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                            </label>
                                            <input id="file_field7" type="file" (change)="previous_year_one_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                        </div>
                                        <small style="font-size: 12px; color:#605D62" id="small_previous_year_one">Upload only PDF files (Max size 4MB)</small>
                                    </div>
                                    <div class="col-sm-12 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="previous_year_two">Previous Year Two</label>

                                            <label for="file_field8" id="custom-file-previous_year_two" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_previous_year_two" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                            </label>
                                            <input id="file_field8" type="file" (change)="previous_year_two_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                        </div>
                                        <small style="font-size: 12px; color:#605D62" id="small_previous_year_two">Upload only PDF files (Max size 4MB)</small>
                                    </div>
                                </div>
                            </div>
                                
                            <!-- <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="upload_your_active_member_data">Upload your active member data(CSV Template)</label>

                                    <label for="file_field2" id="custom-file-upload_upload_your_active_member_data" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_your_active_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field2" type="file" (change)="upload_your_active_member_data_change($event)" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_upload_your_active_member_data">Upload only policy template in .csv, .xls & .xlxs format (Max size 4MB)</small>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadcsv()">Download CSV Template</span>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="active_member_data_report_date">Active member data report date *</label>
                                    <input type="date" class="form-control" formControlName="active_member_data_report_date" id="active_member_data_report_date"
                                    [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.active_member_data_report_date.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.active_member_data_report_date.errors">
                                        <p class="error"
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.active_member_data_report_date.errors.required">
                                        Active member data report date is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="upload_claim_MIS">Upload claim MIS(csv template as per the TPA)</label>

                                    <label for="file_field3" id="custom-file-upload_upload_claim_MIS" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_claim_MIS" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field3" type="file" (change)="upload_claim_MIS_change($event)" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_upload_claim_MIS">Upload only policy template in .csv, .xls & .xlxs format (Max size 4MB)</small>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadclaimMIS()">Download Claim MIS Template</span>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="claim_MIS_date">Claim MIS date *</label>
                                    <input type="date" class="form-control" formControlName="claim_MIS_date" id="claim_MIS_date"
                                    [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.claim_MIS_date.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.claim_MIS_date.errors">
                                        <p class="error"
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.claim_MIS_date.errors.required">
                                        Company Headcount is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div> -->

                            <!-- <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="upload_claim_pdf_summary">Upload claim pdf summary</label>

                                    <label for="file_field4" id="custom-file-upload_upload_claim_pdf_summary" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_claim_pdf_summary" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field4" type="file" (change)="upload_claim_pdf_summary_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_upload_claim_pdf_summary">Upload only PDF files (Max size 4MB)</small>
                            </div> -->
                            
                            <!-- <div class="col-sm-12">
                                <div class="row newuploadField">

                                </div>
                            </div>

                            <div class="col-sm-12 mt-3">
                                <label class="bs-form-label" (click)="addUploadField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New Upload Field</span>
                                </label>
                            </div> -->


                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(4);" class="btn btn-primary" style="float: right;">Finish</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>
                </form>
            </div>
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 80%; padding-bottom: 10px; margin: auto; top:40px">
                <!-- <div class="modal-header">
                    <h4 class="modal-title">Hurray!!</h4>
                </div> -->
                <div class="modal-body">                    
                    <div class="row">

                        <div class="col-sm-12">
                            <img src="../../assets/img/policy/hurray.jpg" class="img-fluid mb-3" style="width:50%; margin: auto; display: block; margin-bottom: 0px !important;" />
                        </div>

                        <div class="col-sm-12">
                            
                            <h5 style="text-align: center; font-weight: bold;">Hooray!!</h5>
                            <h6 class="mt-3 text-center" style="font-size: 14px;"> Plan created successfully.</h6>
                            <h6 class="mt-2 text-center" style="font-weight: 600; font-size: 14px;">Your Plan ID : {{proposal_number_show}}</h6>

                            <div class="form-group mt-3">
                                <label class="bs-form-label text-center" style="margin: auto; display: block;"> Enter Plan Name </label>
                                <input type="text" class="form-control" id="plan_name" name="plan_name" />
                                <p class="error" id="plan_name_error" style="display: none">
                                    This field is required
                                </p>
                            </div>

                        </div>                

                    </div>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="closePopup()" style="margin: auto;"> Submit & Close</button>
                </div>
            </div>
        </div>
    </div>

</div>
