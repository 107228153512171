<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Dashboard</h3>
            <hr class="mb-2">

            <div class="row mt-4">

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Master Policy Holders</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{master_policy_holders}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card1.png" class="icon" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Active Policies</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{active_policies}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card3.png" class="icon" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Inactive Policies</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{inactive_policies}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card2.png" class="icon" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Total Active Policy Members</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{total_active_policy_members}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card4.png" class="icon" />
                        </div>
                    </div>
                </div>                

                <div class="col-sm-6 my-3" *ngIf="readaccess==1">
                    <div class="cardcustom">
                        <h3 class="bs-title">Policies</h3>
                        <hr class="mb-2">

                        <!-- Listing -->
                        <div class="table-responsive datatable-container">
                            <table class="table table-sm row-border" datatable [dtOptions]="dtOptions"
                                [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <!-- <th class="w20">Policy Number</th> -->
                                        <th class="w20">Policy Name</th>
                                        <th class="w20">Policy Start date</th>
                                        <th class="w20">Policy End Date</th>
                                        <th class="w20">Download Policy Copy</th>
                                        <!-- <th>Download Invoice</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let group of InfoPolicy; let i = index">
                                        <!-- <td class="w20">{{group['policy_number']}}</td> -->
                                        <td class="w20">{{group['policy_name']}}</td>
                                        <td class="w20">{{group['policy_start_date']}}</td>
                                        <td class="w20">{{group['policy_end_date']}}</td>
                                        <td class="w20">
                                            <img src="../../../assets/img/common icons/downloaddoc.png" style="width: 30px; border-radius: 0px;" (click)="copypolicy(group['policy_copy'])" *ngIf="group['policy_copy'] != ''" />
                                            <span *ngIf="group['policy_copy'] == ''">-</span>
                                        </td>
                                        <!-- <td><img src="../../../assets/img/common icons/downloaddoc.png"
                                                style="width: 30px; border-radius: 0px;" /></td> -->
                                        <!-- <td> <img src="../../../assets/img/common icons/adduser.png"  *ngIf="writeaccess==1" id="adduser" (click)="adduser(group)" role="button" title="Intimate Claim" /></td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div class="col-sm-6 my-3">
                    <div class="cardcustom">
                        <h3 class="bs-title">TAT Endorsement
                            <!-- <button class="btn btn-primary" style="float: right; margin-top: -8px;" (click)="goto()">Download</button> -->
                        </h3>
                        <hr class="mb-2">
                        <div [chart]="bar" class="chartdiv" style="height: 340px;"></div>
                    </div>                    
                </div>

            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>