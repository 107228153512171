import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
@Component({
  selector: 'app-endorsementrequest',
  templateUrl: './endorsementrequest.component.html',
  styleUrls: ['./endorsementrequest.component.css']
})
export class EndorsementrequestComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;
  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;
  companyDetails:any = [];
  policy_type:any =[];
  policynumbers:any = [];
  document_file: any;
  downloadbuttontooltip:any;
  samplefile: any;
  Policyuploaddoclist:any = [];

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    $('#defaultOpen')[0].click()


  }

  initGroup(){
    
    this.getcompanyDetails();
    this.getPolicyuploaddoclist();

    $('#validmsg').css('display', 'none');
    this.basicInfo = this.fb.group({
      company_id: new FormControl('', Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_number: new FormControl('', Validators.required),
      typeofdata: new FormControl('inception', Validators.required),
      document_file: new FormControl('', Validators.required),
    });
  }
  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.companyDetails = result.data;

      }, (error) => {
          // console.log(result);

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }

  getpolicytype(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['company_id'].value,
    }
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){
    console.log(this.basicInfo);
    console.log(this.basicInfo.controls['policy_type'].value);
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['company_id'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getsamplefile(){


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "master_policies_id":this.basicInfo.controls['policy_number'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file").css("display", "block");
          this.samplefile=result.data['policy_member_sample'];
        }else{
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

  }

  openCity(evt:any, cityName:any) {
    console.log(evt);
    console.log(cityName);

    var i, tabcontent, tablinks;
    tabcontent = $(".tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    $("#"+cityName).css("display", "block")
    evt.currentTarget.className += " active";

  }

  ondownload(val: any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if(val == "policyfeatures"){
      link.setAttribute('href', this.samplefile);
      link.setAttribute('download', 'Sample_Policy_Member_Data.xls');
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  uploadfile(event: any){
    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  onSubmitAdd(){

    this.basicInfoSubmitted = true;
    if (this.basicInfoSubmitted && this.basicInfo.valid) {

      this.show = true;
  
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.basicInfo.controls['company_id'].value,
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
        "master_policies_id":this.basicInfo.controls['policy_number'].value,
        policy_member_data : this.document_file
      };
      console.log(postdata);

      this.show = true;
      this.service.uploadsamplefile(postdata)
        .subscribe((result) => {
          if(result.status){
            this.show = false;

            this.validmsg = result.message;

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            this.route.navigate(['endorsement-request']);
          }
        }, (error) => {
            console.log(error);
      });
      
    }    

  }

  closealert(){

  }

  getPolicyuploaddoclist(){    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getPolicyuploaddoclist(postdata)
      .subscribe((result) => {

        this.Policyuploaddoclist = result.data;
        this.dtTrigger.next();

      }, (error) => {
          // console.log(result);
          this.Policyuploaddoclist = [];
    });
  }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":id,
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.error_filename,'_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
          // console.log(result);
    });    

  }

}
