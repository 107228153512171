import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-ops-new-relationship-request',
  templateUrl: './ops-new-relationship-request.component.html',
  styleUrls: ['./ops-new-relationship-request.component.css']
})
export class OpsNewRelationshipRequestComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  addflag: any = 0;
  DeclineRequestId: any;
  AcceptRequestId: any;
  
  show = false;
  fullScreen = true;
  template = ``;

  RelationType:any =[];
  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  InfoArray: any = [];
  InfoArray_Role: any = [];
  InfoArraydropdown: any = [];
  selectedPolicyId: any;


  displayStyleAccept = "none";
  displayStyleDecline = "none";

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  readaccess: any = 1;
  writeaccess: any = 1;
  deleteaccess: any = 1;
  modulename = 'opsmanager_relationship_request';


  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo('', 'default');
    this.getInfodropdown();
    this.getInfoRelation();
    this.getPolicyEndDate();
    this.assignrights();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    // this.getInfo();
    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      relationshipType: new FormControl('', Validators.required),
      new_relationships: new FormControl('', Validators.required),
      coverStartDate: new FormControl('', Validators.required),
      coverEndDate: new FormControl('',Validators.required),      
      individualCoverAmount:new FormControl('',Validators.required),
      noOfMembers:new FormControl('',Validators.required),      
      totalPremium:new FormControl(''),
      coverageDays:new FormControl(''),
      gst:new FormControl('',Validators.required),
      rate_per_milli:new FormControl(''),
      totalSI:new FormControl(''),
      prorata_premium:new FormControl(''),
      endo_gross_premium:new FormControl(''),
    });

  }

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  openPopupAdd(){
    this.addflag = 1;    
  }

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();   
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "new_relationships":this.AddInfo.controls['new_relationships'].value,
        "relationshipType":this.AddInfo.controls['relationshipType'].value,
        "coverStartDate":this.AddInfo.controls['coverStartDate'].value,
        "coverEndDate":this.AddInfo.controls['coverEndDate'].value,
        "individualCoverAmount":this.AddInfo.controls['individualCoverAmount'].value,
        "noOfMembers":this.AddInfo.controls['noOfMembers'].value,
        "totalPremium": $('#totalPremium').val(),
        "coverageDays": $('#coverageDays').val(),
        "totalSI": $('#totalSI').val(),
        "prorata_premium": $('#prorata_premium').val(),
        "rate_per_milli": $('#rate_per_milli').val(),
        "gst": $('#gst').val(),
        "gst_amount": $('#gst_amount').val(),
        "endo_gross_premium": $('#endo_gross_premium').val(),
        "employer_id":localStorage.getItem('employer_id')
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  calPremium(){

    let totalSI:any = $('#totalSI').val();
    let rate_per_milli:any = $('#rate_per_milli').val();
    let coverageDays:any = $('#coverageDays').val();
    let gst:any = $('#gst').val();
    let totalPremium:any 
    let singleDay:any 
    let prorata_premium:any 
    let gst_amount:any 
    let endo_gross_premium:any 

    if(totalSI != '' && rate_per_milli != ''){
      totalPremium = totalSI*rate_per_milli/1000;
      $('#totalPremium').val(totalPremium.toFixed(2))
    }

    if(prorata_premium != ''){
      singleDay = totalPremium/366;
      prorata_premium = singleDay * coverageDays

      if(!isNaN(prorata_premium)){
      $('#prorata_premium').val(prorata_premium.toFixed(2))
      }
    }

    if(gst != ''){
      gst_amount = prorata_premium * gst/100

      if(!isNaN(gst_amount)){
      $('#gst_amount').val(gst_amount.toFixed(2))
      }
    }

    if(endo_gross_premium != ''){
      endo_gross_premium = prorata_premium + gst_amount

      if(!isNaN(endo_gross_premium)){
      $('#endo_gross_premium').val(endo_gross_premium.toFixed(2))
      }
    }


  }

  calSI(){
    let a:any = $('#individualCoverAmount').val();
    let b:any = $('#noOfMembers').val();
    if(a != '' && b != ''){
      let d:any = a*b;
      $('#totalSI').val(d)
      this.calPremium();
    }
  }

  calGST(){

  let premium:any = $('#totalPremium').val();
  let premium_tax: any = $('#gst').val();

  if (premium != '' && premium_tax != '') {
    premium_tax = parseFloat(premium_tax) / 100;

    let premium_total = parseFloat(premium) * premium_tax;

    let finaltotal = parseFloat(premium) + premium_total;

    if (!isNaN(finaltotal)) {
      $('#totalPremiumWithGST').val(finaltotal.toFixed(2)); // Adjust the number of decimal places as needed
    }
  }
  }

  getInfoRelation(){

    this.RelationType = [];


    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    }
    this.dataservice.getInfoRelation(postdata)
        .subscribe((result: any) => {
          
          this.RelationType = result.data;
                             

        }, (error) => {

          this.RelationType = [];

      });
  }
  
  onRelationTypeChange(event: any): void {

    this.selectedPolicyId = event.target.value;

    let a = this.RelationType
    for(var i = 0; i < a.length; i++){
      if(this.selectedPolicyId == a[i]['id']){
        $('#rate_per_milli').val(a[i]['rate_per_milli']);
      }
    }
  
  }

  getPolicyEndDate(){


    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    }
    this.dataservice.getPolicyEndDate(postdata)
        .subscribe((result: any) => {
          
          this.AddInfo.controls['coverEndDate'].setValue(result.data[0]['end_date']);
                             

        }, (error) => {

      });
  } 

  getInfo(val:any, value:any){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    this.InfoArray = [];
    var postdata:any;

    if(value == 'default'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": 0,
        "broker_id": localStorage.getItem('broker_id')
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": val.target.value,
        "broker_id": localStorage.getItem('broker_id')
      };
    }
    
    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.InfoArray = result.data;
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });

        }, (error) => {

          this.show = false;
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }  

  getInfodropdown(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "employer_id": localStorage.getItem('employer_id')
    };

    this.dataservice.getInfodropdown(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArraydropdown = result.data; 

        }, (error) => {          
          
          this.InfoArraydropdown = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  fn_decline_request(id:any){

    this.displayStyleDecline = "block";

    this.DeclineRequestId = id;

  }

  on_submitDecline(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "request_id": this.DeclineRequestId,
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "is_request_status":2
    };

    this.dataservice.activedeactive(postdata)
        .subscribe((result: any) => {
          $("#client_name").val('');
          this.show = false;
          this.displayStyleDecline = "none";
          this.getInfo('', 'default');
          this.dtTrigger.next();

        }, (error) => {
          this.show = false;
          console.log(error);
          this.InfoArray = [];

      });
  }

  fn_accept_request(id:any){

    this.displayStyleAccept = "block";

    this.AcceptRequestId = id;
  }

  on_submitAccept(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    var postdata = {
      "request_id":this.AcceptRequestId,
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "is_request_status":1
    };

    this.dataservice.activedeactive(postdata)
        .subscribe((result: any) => {
          $("#client_name").val('');
          this.displayStyleAccept = "none";
          this.show = false;
          this.getInfo('', 'default');
          this.dtTrigger.next();

        }, (error) => {
          this.show = false;
          console.log(error);
          this.InfoArray = [];

      });
  }

  closePopup() {

    this.displayStyleAccept = "none";
    this.displayStyleDecline = "none";
  }

  assignrights() {
    let module_info_arr: any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for (var i = 0; i < module_info.length; i++) {
      if (module_info[i]['child'].length == 0) {
        if (module_info[i]['module_content'] == this.modulename) {
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      } else {
        for (var j = 0; j < module_info[i]['child'].length; j++) {
          let json = module_info[i]['child'][j];
          if (json['module_content'] == this.modulename) {
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

  loadecpirydate(){

    var policy_date = this.AddInfo.controls['coverStartDate'].value;
    var parts = policy_date.match(/(\d+)/g);
    var d = new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
    var newdate = new Date(d.getTime() + 365*24*60*60*1000);

    // check whether start date is in leap year
    let year:any = policy_date.substring(0,4);
    let leapyear:any = (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0));      
    if(leapyear == true){        
      newdate.setDate(newdate.getDate() + 1);
    }            
    var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];      
    this.AddInfo.controls['coverEndDate'].setValue(dateString);

  }  

  // calculateCoverageDays() {
  //   const startDate = new Date(this.AddInfo.value.coverStartDate);
  //   const endDate = new Date(this.AddInfo.value.coverEndDate);
  //   const differenceInTime = endDate.getTime() - startDate.getTime();
  //   const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  //   this.AddInfo.patchValue({ coverageDays: differenceInDays });
  // }

  calculateCoverageDays() {
    const startDateValue = this.AddInfo.value.coverStartDate;
    const endDateValue = this.AddInfo.value.coverEndDate;

    // Check if both start date and end date are selected
    if(startDateValue && endDateValue) {
        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);
        
        // Check if the dates are valid
        if(!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            const differenceInTime = endDate.getTime() - startDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
            this.AddInfo.patchValue({ coverageDays: differenceInDays });
        } else {
            // Handle invalid dates
            console.error("Invalid start or end date");
        }
    }
}

}
