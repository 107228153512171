import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ops-endorsement',
  templateUrl: './ops-endorsement.component.html',
  styleUrls: ['./ops-endorsement.component.css']
})
export class OpsEndorsementComponent implements OnInit {


  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;


  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  ClientId:any = [];
  clientwisepolicy :any = [];
  InfoArray:any = [];
  InfoPolicy:any = [];
  InfoArraydropdown: any = [];
  policy_type: any = [];
  document_file: any;
  bulkfile: any;


  selectedPolicyId: any;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  readaccess   : any = 1;
  writeaccess  : any = 1;
  deleteaccess :  any = 1;
  modulename = 'opsmanager_endorsement';

  clientname: any;
  clientid: any;


  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
        this.clientname = localStorage.getItem('clientname');
        // this.clientid = localStorage.getItem('clientid');

      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo('', 'default');
    this.getInfodropdown();
    this.assignrights();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    // this.getInfo();
    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      client_id: new FormControl('', Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_no: new FormControl('', Validators.required),
      endo_date: new FormControl('', Validators.required),
      new_member_count: new FormControl('',Validators.required),
      permili_rate:new FormControl('',Validators.required),
      individualCoverAmount:new FormControl('',Validators.required),
      totalSI:new FormControl(''),
      totalPremium:new FormControl(''),
      gst:new FormControl(''),
      totalPremiumwithGST:new FormControl(''),
      remarks:new FormControl('')
    });


  }

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.AddInfo.controls['client_id'].value,
        "policy_type":this.AddInfo.controls['policy_type'].value,
        "policy_no":this.AddInfo.controls['policy_no'].value,
        "endo_date":this.AddInfo.controls['endo_date'].value,
        "new_member_count":this.AddInfo.controls['new_member_count'].value,
        "permili_rate":this.AddInfo.controls['permili_rate'].value,
        "individualCoverAmount":this.AddInfo.controls['individualCoverAmount'].value,
        "totalSI": $('#totalPremium').val(),
        "gst":this.AddInfo.controls['gst'].value,
        "remarks":this.AddInfo.controls['remarks'].value,
        "totalPremium": $('#totalPremium').val(),
        "totalPremiumwithGST": $('#totalPremiumwithGST').val(),
        "employer_id":localStorage.getItem('employer_id')
      };

      console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            this.show = false;
            // this.AddInfo.reset();
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();
            this.validmsg = result.message;

          }else {
            this.show = false;
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    } else {
      // validate all form fields
    }
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  getInfodropdown(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "employer_id": localStorage.getItem('employer_id')
    };

    this.dataservice.getInfodropdown(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.ClientId = result.data;

        }, (error) => {

          this.ClientId = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  getclientwisepolicy_list(){

    var brokerid:any;

    if(localStorage.getItem('broker_id') == ''){
      brokerid = 0;
    }else{
      brokerid = localStorage.getItem('broker_id');
    }

    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":brokerid,
      "employer_id":localStorage.getItem('employer_id'),
      "policy_type":this.AddInfo.controls['policy_type'].value,
    }

    this.dataservice.getclientwisepolicy_list(postdata)
        .subscribe((result: any) => {

          this.clientwisepolicy = result.data;

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

          this.clientwisepolicy = [];

      });

  }

  getInfo(val:any, value:any){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    this.InfoArray = [];
    var postdata:any;

    if(value == 'default'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": 0,
        "broker_id": localStorage.getItem('broker_id')
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": val.target.value,
        "broker_id": localStorage.getItem('broker_id')
      };
    }

    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.InfoArray = result.data;

          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };

          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });

        }, (error) => {

          this.show = false;

          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  onPolicyNumberChange(event: any): void {

    this.selectedPolicyId = event.target.value;

    let a = this.clientwisepolicy
    for(var i = 0; i < a.length; i++){
      if(this.selectedPolicyId == a[i]['policyid']){
        this.AddInfo.controls['permili_rate'].setValue(a[i]['rate_per_milli']);
        this.AddInfo.controls['gst'].setValue(a[i]['gpa_gst']);
      }
    }

  }

  calGST(){

    let premium:any = $('#totalPremium').val();
    let premium_tax: any = $('#gst').val();

    if (premium != '' && premium_tax != '') {
      premium_tax = parseFloat(premium_tax) / 100;

      let premium_total = parseFloat(premium) * premium_tax;

      let finaltotal = parseFloat(premium) + premium_total;

      if (!isNaN(finaltotal)) {
        $('#totalPremiumwithGST').val(finaltotal.toFixed(2)); // Adjust the number of decimal places as needed
      }
    }
    }

    calPremium(){

      let a:any = $('#new_member_count').val();
      let b:any = $('#individualCoverAmount').val();
      let c:any = $('#permili_rate').val();
      let d:any

      if(a != '' && b != ''){
        d = a*b;
        $('#totalSI').val(d)
      }

      if(d != '' && c != '' && d>0 && c>0){
        let e:any = d*c/1000;
        $('#totalPremium').val(e)
      }

    }

    closePopupAdd(){
      this.addflag = 0;
      window.location.reload();
    }

    openPopupAdd(){
      this.addflag = 1;
      this.getclientwisepolicy_list();
      this.assignrights();
    }

    getpolicytype(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.AddInfo.controls['client_id'].value,
      }
      this.dataservice.getpolicytype(postdata)
        .subscribe((result) => {

          this.policy_type = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    ondownload_uploaded(fileUrl: string, fileName: string) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', fileUrl);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    assignrights(){
      let module_info_arr:any = localStorage.getItem("module_info");
      let module_info = JSON.parse(module_info_arr);
      for(var i=0;i<module_info.length;i++){
        if(module_info[i]['child'].length==0){
          if(module_info[i]['module_content']==this.modulename){
            this.writeaccess = module_info[i]['module_write'];
            this.readaccess = module_info[i]['module_read'];
            this.deleteaccess = module_info[i]['module_delete'];
          }
        }else{
          for(var j=0;j<module_info[i]['child'].length;j++){
            let json = module_info[i]['child'][j];
            if(json['module_content']==this.modulename){
              this.writeaccess = json['module_write'];
              this.readaccess = json['module_read'];
              this.deleteaccess = json['module_delete'];
            }
          }
        }

      }
    }

    endorsement_doc(event: any){

      if(event.target.files.length > 0){
        this.document_file = event.target.files[0];
        $("#features-file-upload_document_file").empty();
        $('#features-file-upload_document_file').html(event.target.files[0]['name']);
        $('#features-file-upload_document_file').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);

        // iSize = (Math.round(iSize * 100) / 100);

        // $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        // $('.sizeerror').css('display', 'none');
        $('#submitbtn').css('pointer-events', 'auto');
        $('#submitbtn').css('background', 'var(--bs-btn-bg)');
        $('#submitbtn').css('border-color', 'var(--bs-btn-border)');

      }
    }

    onSubmit(){

      var document_name = $('#document').val();
      var client_id = $('#client_id').val();
      var policy_type = $('#policy_type').val();

      if(this.document_file == "" || this.document_file == null || this.document_file == undefined || document_name == ""){

        if(this.document_file == "" || this.document_file == null || this.document_file == undefined){
          $('#document_file_error').css('display', 'block');
          $('#features-file-upload_document_file').css('border', 'solid 1px red');
        }else{
          $('#document_file_error').css('display', 'none');
          $('#features-file-upload_document_file').css('border', '1px solid #ced4da;');
        }

        if(document_name == ""){
          $('#document_error').css('display', 'block');
          $('#document').css('border', 'solid 1px red');
        }else{
          $('#document_error').css('display', 'none');
          $('#document').css('border', '1px solid #ced4da;');
        }

        if(client_id == ""){
          $('#client_error').css('display', 'block');
          $('#client_id').css('border', 'solid 1px red');
        }else{
          $('#client_error').css('display', 'none');
          $('#client_id').css('border', '1px solid #ced4da;');
        }

        if(policy_type == ""){
          $('#policy_type_error').css('display', 'block');
          $('#policy_type').css('border', 'solid 1px red');
        }else{
          $('#policy_type_error').css('display', 'none');
          $('#policy_type').css('border', '1px solid #ced4da;');
        }

      }else{

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id": $('#client_id').val(),
          "policy_type":$('#policy_type').val(),
          "document_file": this.document_file,
        };

        console.log(postdata);

        this.dataservice.onSubmitFirst(postdata, localStorage.getItem('Token'))
          .subscribe((result: any) => {
            console.log(result);
            if (result.status == true) {
              // console.log(result);

              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');
              setTimeout(() => {
                window.location.reload();
              }, 3000);

            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

      }

    }

    download_bulkFile(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id": $('#client_id').val(),
        "policy_type":$('#policy_type').val(),

      }
      this.dataservice.download_bulkFile(postdata)
        .subscribe((result) => {

          // console.log(result.data[0]['policy_features_uploadfiles']);

          this.bulkfile = result.data['endorsement_sample'];

        }, (error) => {
            // console.log(result);
      });
    }

    ondownload(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', this.bulkfile);
      link.setAttribute('download', 'Endorsement_File.xlsx');

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    downloadCSV() {    

      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'), 
        "broker_id": 0,    
        "employer_id": $('#client_id').val(),    
      }
  
      this.dataservice.download(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
  
          let finalArr:any = result.data;
      
          console.log(finalArr);    
      
          /* pass here the table id */
          let element = document.getElementById('excel-table');
          const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalArr);
      
          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
          /* save to file */  
          XLSX.writeFile(wb, 'OPS_Endorsement_Data.xlsx');
  
        }, (error) => {    
  
          if (error.error.message == 'Unauthenticated.') {
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
  
        });    
  
    }
  
}
