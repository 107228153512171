import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-manage-annoucements',
  templateUrl: './manage-annoucements.component.html',
  styleUrls: ['./manage-annoucements.component.css']
})
export class ManageAnnoucementsComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_article_title = localStorage.getItem('type_article_title');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];    
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_article_title');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      article_by: new FormControl('', Validators.required),
      article_title: new FormControl('', Validators.required),
      article_description: new FormControl('', Validators.required),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      article_by_edit: new FormControl('', Validators.required),
      article_title_edit: new FormControl('', Validators.required),
      article_description_edit: new FormControl('', Validators.required),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;
    // this.EditInfo.controls['module_url'].setValue('');
    // this.EditInfo.controls['module_name'].setValue('');
    // this.displayStyle_add = "block";
  } 
  
  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  } 

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;  
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "article_by":this.AddInfo.controls['article_by'].value,
        "article_title":this.AddInfo.controls['article_title'].value,
        "article_description":this.AddInfo.controls['article_description'].value,
        "client_id":localStorage.getItem('client_id')
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.announcement_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['userarticle_title']);

        this.EditInfo.controls['article_by_edit'].setValue(result.data[0]['article_by']);
        this.EditInfo.controls['article_title_edit'].setValue(result.data[0]['article_title']);
        this.EditInfo.controls['article_description_edit'].setValue(result.data[0]['article_description']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['announcement_id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "article_by":this.EditInfo.controls['article_by_edit'].value,
        "article_title":this.EditInfo.controls['article_title_edit'].value,
        "article_description":this.EditInfo.controls['article_description_edit'].value,
        "client_id":localStorage.getItem('client_id'),
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();

            window.location.reload();

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.announcement_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['announcement_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.announcement_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['announcement_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray = result.data; 
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
