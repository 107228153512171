<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
      <div class="content-wrapper pt-3">
          
        <h3 class="bs-title">Welcome<span class="spanbs-title" style="color: #053BBD"> {{username}}</span></h3>        
        <hr>        
        
      </div>
      <app-footer></app-footer>
    </div>
  
  </div>