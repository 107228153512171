<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
      <div class="content-wrapper pt-3">
        <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

        <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
            <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
            <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <h3 class="bs-title">Member<span class="spanbs-title"> Upload</span></h3>
        <hr class="mb-2">

        <!-- Add -->
        <form [formGroup]="basicInfo">
          <div class="row">

            <div class="col-sm-4">
              <div class="form-group">
                  <label class="bs-form-label" for="company_id">Company *</label>
                  <select class="form-select" formControlName="company_id" id="company_id" (change)="getpolicytype()"
                  [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.company_id.errors}"
                  required>
                      <option value="" selected>Select Company</option>
                      <option *ngFor="let companydetails of companyDetails" [value]="companydetails.id">{{ companydetails.name }}</option>
                  </select>
                  <ng-container
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_id.errors">
                  <p class="error"
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_id.errors.required">
                  Policy Type is required
                  </p>
                </ng-container>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                  <label class="bs-form-label" for="policy_type">Policy Type *</label>
                  <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getpolicynumbers()"
                  [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors}"
                  required>
                      <option value="" selected>Select Policy Type</option>
                      <option *ngFor="let type of policy_type" [value]="type.sub_type_id">{{ type.name }}</option>
                  </select>
                  <ng-container
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors">
                  <p class="error"
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors.required">
                  Policy Number is required
                  </p>
                </ng-container>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                  <label class="bs-form-label" for="policy_number">Policy Number *</label>
                  <select class="form-select" formControlName="policy_number" id="policy_number"
                  [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors}" (change)="getsamplefile()"
                  required>
                      <option value="" selected>Select Policy Type</option>
                      <option *ngFor="let type of policynumbers" [value]="type.id">{{ type.policy_name }} / {{ type.policy_number }}</option>
                  </select>
                  <ng-container
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors">
                  <p class="error"
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors.required">
                  Company is required
                  </p>
                </ng-container>
              </div>
            </div>
                          
            <!-- Tabs -->
            <div class="col-sm-12 mt-4">
              
              <!-- Tabs Headings -->
              <ul class="nav nav-tabs" id="myTab" role="tablist" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 10px 10px 0px 0px;">
                <li class="nav-item liwidth" role="presentation">
                    <button class="nav-link active w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add" type="button"
                        role="tab" aria-controls="home" aria-selected="true">Add</button>
                </li>
                <li class="nav-item liwidth" role="presentation">
                    <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" data-bs-target="#Remove" type="button"
                        role="tab" aria-controls="Remove" aria-selected="false">Remove</button>
                </li>
                <li class="nav-item liwidth" role="presentation">
                    <button class="nav-link w100" id="Corrections-tab" data-bs-toggle="tab" data-bs-target="#Corrections" type="button"
                        role="tab" aria-controls="Corrections" aria-selected="false">Corrections</button>
                </li>                      
              </ul>

              <!-- Tabs Content -->
              <div class="tab-content" id="myTabContent" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 0px 0px 10px 10px;padding: 10px 20px;">
              
                <!-- Tab Add -->
                <div class="tab-pane fade show active" id="Add" role="tabpanel" aria-labelledby="Add-tab">
                  <div class="row">
                    
                    <div class="col-sm-6">
                      <label class="bs-form-label" for="no_of_members_allowed"><h4>Type of Data</h4></label>
                      <div class="row">
                          <!-- <div class="col-sm-4">
                              <div class="form-radio">
                                  <input type="radio" class="form-radio-input" formControlName="typeofdata" value="endorsement" name="typeofdata" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                  <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Endorsement</label>
                              </div>
                          </div> -->
                          <div class="col-sm-4">
                              <div class="form-radio">
                                  <input type="radio" class="form-radio-input" formControlName="typeofdata" value="inception" name="typeofdata" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                  <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Inception</label>
                              </div>
                          </div>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="bs-form-label" for="document_file">Attached Sample File</label>
                          <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px;">
                              <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                          </label>
                          <input id="document_file" type="file" (change)="uploadfile($event)" formControlName="document_file" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                        </div>
                        <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .xlsx, .xls, .csv files</small>
                      </div>
                      <div class="col-sm-3" id="download_sample_file" style="text-align: center;margin: auto;display:none">
                          <div class="form-group mt-3">
                              <label class="bs-form-label" for=""></label>
                              <button (click)="ondownload('policyfeatures')" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                          </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              
                <!-- Tab Remove -->
                <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab">
                  <label class="bs-form-label"><h4>Comming Soon</h4></label>
                </div>
              
                <!-- Tab Corrections -->
                <div class="tab-pane fade" id="Corrections" role="tabpanel" aria-labelledby="Corrections-tab">
                  <label class="bs-form-label"><h4>Comming Soon</h4></label>
                </div>
              
              </div>
              
            </div>
            <!-- End -->

            <div class="col-sm-12" align="center">
                <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>
            </div>
          </div>
        </form>

        <hr>

        <!-- List -->
        <div class="table-responsive">
          <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                  <tr>
                      <th style="width:5%">SrNo</th>
                      <th>Policy No.</th>
                      <th>Policy Name</th>
                      <th>Company</th>
                      <th>Upload Member Status</th>
                      <th>Original Document</th>
                      <th>Failed Document</th>                      
                      <th>Success</th>
                      <th>Uploaded At</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let group of Policyuploaddoclist; let i = index">
                      <td style="width:5%">{{i+1}}</td>
                      <td>{{group['policy_number']}}</td>
                      <td>{{group['policy_name']}}</td>
                      <td>{{group['company_name']}}</td>
                      <td>
                        <p style="color: #0382cc; font-size: 13px;">
                          <i class="mdi mdi-account"></i>
                          Total Uploaded: {{group['total_member']}} 
                          <span *ngIf="group['total_member'] == 1">Member</span>
                          <span *ngIf="group['total_member'] != 1">Members</span>
                        </p>
                        <p style="color: #32cc03; font-size: 13px;">
                          <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                          Added Successfully: {{group['sucess_member']}} 
                          <span *ngIf="group['sucess_member'] == 1">Member</span>
                          <span *ngIf="group['sucess_member'] != 1">Members</span>
                        </p>
                        <p style="color: #f03800; font-size: 13px;" *ngIf="group['fail_member'] >= 1">
                          <i class="mdi mdi-close"></i>
                          Failed To Add: {{group['fail_member']}} 
                          <span *ngIf="group['fail_member'] == 1">Member</span>
                          <span *ngIf="group['fail_member'] != 1">Members</span>
                        </p>                        
                      </td>
                      <td>
                        <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc(group['original_document'])" style="cursor: pointer" />                        
                      </td>
                      <td>
                        <span *ngIf="group['status'] == 'success' || group['status'] == 'pending'"> - </span>
                        <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc_failed(group['uploadid'])" style="cursor: pointer" *ngIf="group['fail_member'] >= 1" />                        
                      </td>                      
                      <td>
                        <span *ngIf="group['status'] == 'pending'" class="btn btn-inverse-warning btn-fw">Pending</span>
                        <span *ngIf="group['status'] == 'success'" class="btn btn-inverse-success btn-fw">Success</span>
                        <span *ngIf="group['status'] == 'failed'" class="btn btn-inverse-danger btn-fw">Falied</span>
                      </td>
                      <td>{{group['uploaded_at']}}</td>          
                  </tr>
              </tbody>
          </table>
        </div>

      </div>
      <app-footer></app-footer>
  </div>
</div>
