<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Flex Configurator
                <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button"
                    style="float:right">Add
                    New</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Flex Configurator
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit Flex Configurator
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>

            <h3 class="bs-title" *ngIf="addflag == 3">Edit Flex Configurator
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>
            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2">

            <!-- List -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Employer Name</th>
                            <th>Policy Type</th>
                            <th>Policy No.</th>
                            <th>Policy Name</th>
                            <th>Plan Name</th>
                            <th>Created On</th>
                            <th>Employe Opted Flex</th>
                            <th>Audit</th>
                            <th>Status</th>
                            <th style="text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['employer_name']}}</td>
                            <td>{{group['policy_type']}}</td>
                            <td>{{group['policy_number']}}</td>
                            <td>{{group['policy_name']}}</td>
                            <td>{{group['plan_name']}}</td>
                            <td>{{group['added_date']}}</td>
                            <td>
                                <button type="button" class="btn btn-primary mt-3" style="cursor: pointer" role="button">View Employee</button>   <br>                         
                                <button type="button" class="btn btn-primary mt-3" style="cursor: pointer" role="button">Download List</button>                            
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary mt-3" (click)="showPopupAudit()"style="cursor: pointer" role="button">Audit Details</button>                            
                            </td>
                            <td>
                                <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                                <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                            </td>
                            <td style="width: 15%" align="center">
                                <img src="../../../assets/img/common icons/edit.png" id="edituser"
                                    (click)="openPopupEdit(group)" role="button" title="Edit" />
                                <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png"
                                    id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button"
                                    title="Deactive" />
                                <img *ngIf="group['is_active'] == 0" src="../../../assets/img/selectproduct/active.png"
                                    id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button"
                                    title="Active" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">
                <form [formGroup]="AddInfo">

                    <!-- Plan Details -->
                    <h3 class="bs-title mt-4" style="font-size: 18px;">Plan Details</h3>
                    <hr>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="corporate">Corporate</label>
                                <select class="form-select" formControlName="corporate" id="corporate">
                                    <option value="">Select Corporate</option>
                                    <option value="Corporate 1">Corporate 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.corporate}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_type">Policy Type</label>
                                <select class="form-select" formControlName="policy_type" id="policy_type">
                                    <option value="">Select Policy Type</option>
                                    <option value="1">Policy Type</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.policy_type}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_name">Policy Name</label>
                                <select class="form-select" formControlName="policy_name" id="policy_name">
                                    <option value="">Select Policy Name</option>
                                    <option value="Policy Name 1">Policy Name 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.policy_name}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="sum_insured">Sum Insured</label>
                                <select class="form-select" formControlName="sum_insured" id="sum_insured">
                                    <option value="">Select Sum Insured</option>
                                    <option value="10000">10000</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.policy_name}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="plan_name">Plan Name</label>
                                <input type="text" class="form-control" formControlName="plan_name" id="plan_name" placeholder="Plan Name" />
                            </div>
                        </div>
                        <div class="col-sm-12"></div>
                        <div class="col-sm-2 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="roll_back_allowed">Roll Back Allowed</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_roll_back_allowed" formControlName="roll_back_allowed" name="roll_back_allowed" value="No" />
                                    <label for="radio-two_roll_back_allowed" id="roll_back_allowed_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_roll_back_allowed" formControlName="roll_back_allowed" name="roll_back_allowed" value="Yes" />
                                    <label for="radio-one_roll_back_allowed" id="roll_back_allowed_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="show_diff_premium">Show Difference Premium</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_show_diff_premium" formControlName="show_diff_premium" name="show_diff_premium" value="No" />
                                    <label for="radio-two_show_diff_premium" id="show_diff_premium_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_show_diff_premium" formControlName="show_diff_premium" name="show_diff_premium" value="Yes" />
                                    <label for="radio-one_show_diff_premium" id="show_diff_premium_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="plan_description">Plan Discription</label>
                                <textarea type="text" class="form-control" formControlName="plan_description" id="plan_description" placeholder="Enter Comment Here..."></textarea>
                            </div>
                        </div>
                    </div>


                    <!-- ADD On's -->
                    <div class="col-sm-12 mt-5"></div>
                    <h3 class="bs-title" style="font-size: 18px;">Add On's</h3>
                    <hr>
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_name">Benefit Name</label>
                                <select class="form-select" formControlName="benefit_name" id="benefit_name">
                                    <option value="">Select Benefit Name</option>
                                    <option value="Benefit 1">Benefit 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.corporate}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_description">Benefit Description</label>
                                <input type="text" class="form-control" formControlName="benefit_description" id="benefit_description" placeholder="Benefit Description" />
                            </div>
                        </div>
                        <div class="col-sm-3 mt-2">
                            <button type="button" class="btn btn-primary mt-3" (click)="showPopup()" style="cursor: pointer" role="button">Benefit Details +</button>
                        </div>

                        <!-- Benefits Popup -->
                        <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':PopupBenefits}"
                            style="overflow:hidden">
                            <div class="modal-dialog modal-dialog_view" role="document" style="margin-top: 10px;">
                                <div class="modal-content model-content-view" style="overflow-y: hidden !important;">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Update Benefits
                                            <button type="button" class="btn btn-danger" (click)="closePopup()" style="position: absolute;right: 10px;"> &#10006;</button>
                                        </h4>
                                    </div>
                                    <form [formGroup]="benefit_info">
                                        <div class="modal-body modal-body_edit">
                                            <div class="row">

                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_name_val">Benefit Name</label>
                                                        <select class="form-select" formControlName="benefit_name_val" id="benefit_name_val">
                                                            <option value="" selected>Select Benefit Name</option>
                                                            <option value="Benefit 1">Benefit 1</option>
                                                            <!-- <option *ngFor="let policyFeatures of PolicyFeatures" [value]="policyFeatures.id">{{ policyFeatures.title }}</option> -->
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_description_val">Benefit Description</label>
                                                        <input type="text" class="form-control" formControlName="benefit_description_val" id="benefit_description_val" placeholder="Benefit Description" />
                                                    </div>
                                                </div>

                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_visible_from_SI">Benefit Visible From SI</label>
                                                        <input type="text" class="form-control" formControlName="benefit_visible_from_SI" id="benefit_visible_from_SI" placeholder="Benefit Visible From SI" (keypress)="isNumber($event)" />
                                                    </div>
                                                </div>

                                                <div class="col-sm-3 mt-3">
                                                    <label class="bs-form-label" for="no_of_members_allowed">Benefit
                                                        Mandatory</label>

                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input" formControlName="benefit_mandatory" value="Mandatory" name="benefit_mandatory" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                                                <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Mandatory</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="benefit_mandatory" value="Optional"
                                                                    name="benefit_mandatory"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Optional</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-3 mt-3">
                                                    <label class="bs-form-label" for="no_of_members_allowed">Prorata
                                                        Calculation
                                                        Applicable</label>

                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="prorata_calculation_applicable"
                                                                    value="Yes" name="prorata_calculation_applicable"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                    checked />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="prorata_calculation_applicable"
                                                                    value="No" name="prorata_calculation_applicable"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12">
                                                    <hr>
                                                </div>

                                                <!-- Add More -->
                                                <div class="col-sm-12">
                                                    <div class="newbenefitData">

                                                    </div>
                                                </div>

                                                <div class="col-sm-12 mt-3">
                                                    <label class="bs-form-label" (click)="addBenefitData()"
                                                        style="cursor: pointer">
                                                        <img src="../../assets/img/policy/add.png"
                                                            style="width: 20px; margin-top: -4px;" />&nbsp;
                                                        <span style="color: #1489F3;">Add</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-primary" style="float: right;"
                                                (click)="savebenefit()">Save</button>
                                            <button type="button" class="btn btn-danger" (click)="closePopup()">
                                                Close</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                        <!-- List -->
                        <div class="table-responsive col-sm-12 mt-3" *ngIf="BenefitTableArray.length > 0">
                            <table class="table table-bordered table-sm row-border">
                                <thead>
                                    <tr>
                                        <th style="width:5%">SrNo</th>
                                        <th>Benefit Name</th>
                                        <th>Benefit Desc</th>
                                        <th style="text-align: center;">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr *ngFor="let group of BenefitTableArray; let i = index">
                                        <td style="width:5%">{{i+1}}</td>
                                        <td>{{group['benefit_name']}}</td>
                                        <td>{{group['benefit_description']}}</td>
                                        <td style="width: 15%" align="center">
                                            <!-- <img src="../../../assets/img/common icons/edit.png" id="editbefefit"
                                                role="button" title="Edit" /> -->
                                            <img src="../../../assets/img/common icons/trash.png" id="deleteuser"
                                                (click)="removeBenefitTableArray(i)" class="ml-4" role="button"
                                                title="Delete" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>


                    <!-- What's Not Covered -->
                    <div class="col-sm-12 mt-5">
                        <h3 class="bs-title" style="font-size: 18px;">What's Not Covered</h3>
                    </div>
                    <hr>
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_name">Benefit Name</label>
                                <select class="form-select" formControlName="benefit_name" id="benefit_name_not_cover">
                                    <option value="">Select Benefit Name</option>
                                    <option value="Benefit 1">Benefit 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.corporate}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_description">Benefit
                                    Description</label>
                                <input type="text" class="form-control" formControlName="benefit_description"
                                    id="benefit_description_not_cover" placeholder="Benefit Description" />
                            </div>
                        </div>
                        <div class="col-sm-3 mt-2">
                            <button type="button" class="btn btn-primary mt-3" (click)="saveWhatsNotCover()"
                                style="cursor: pointer" role="button">Add +</button>
                        </div>

                        <div class="table-responsive col-sm-12 mt-3" *ngIf="NotCoverTableArray.length > 0">
                            <table class="table table-bordered table-sm row-border">
                                <thead>
                                    <tr>
                                        <th style="width:5%">SrNo</th>
                                        <th>Benefit Name</th>
                                        <th>Benefit Desc</th>
                                        <th style="text-align: center;">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr *ngFor="let group of NotCoverTableArray; let i = index">
                                        <td style="width:5%">{{i+1}}</td>
                                        <td>{{group['benefit_name']}}</td>
                                        <td>{{group['benefit_description']}}</td>
                                        <td style="width: 15%" align="center">
                                            <img src="../../../assets/img/common icons/trash.png" id="benefit_row" (click)="removeNotCoverTableArray(i)" role="button" title="Delete" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <!-- Additional Features -->
                    <div class="col-sm-12 mt-5">
                        <h3 class="bs-title" style="font-size: 18px;">Additional Features</h3>
                    </div>
                    <hr>
                    <div class="divgrey">
                        <p>Premium Credid/Debit based on member Addition/Deletion </p>
                        <div>
                            <input type="checkbox" class="form-check-input" id="will_there_be_any_premium" (click)="checkwill_there_be_any_premium()">
                            <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Will there be any Premium Credid/Debit based on member Addition/Deletion ? </label>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 mt-3 will_there_be_any_premiumDiv" style="display: none">
                                <div class="form-group">
                                    <label class="bs-form-label" for="feature_desc">Feature Description</label>
                                    <input type="text" class="form-control" formControlName="feature_desc" id="feature_desc" placeholder="Enter Feature Description" />
                                </div>
                            </div>
    
                            <div class="col-sm-3 mt-4 will_there_be_any_premiumDiv" style="display: none;">
                                <button type="button" class="btn btn-primary mt-3" (click)="showPopupFeatures()" style="cursor: pointer" role="button">Fearture Details +</button>    
                            </div>
                        </div>                        

                    </div>

                    <!-- Features Popup -->
                    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':PopupFeatures}"
                        style="overflow:hidden">
                        <div class="modal-dialog modal-dialog_view" role="document" style="margin-top: 10px;">
                            <div class="modal-content model-content-view" style="overflow-y: hidden !important;">
                                <div class="modal-header">
                                    <h4 class="modal-title">Create Benefits
                                        <button type="button" class="btn btn-danger" (click)="closePopup()"
                                            style="position: absolute;right: 10px;"> &#10006;</button>
                                    </h4>
                                </div>
                                <form [formGroup]="features_info">
                                    <div class="modal-body modal-body_edit">
                                        <div class="row">

                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="feature_desc">Feature
                                                        Description</label>
                                                    <textarea type="text" class="form-control"
                                                        formControlName="feature_desc" id="feature_desc_val"
                                                        placeholder="Enter Feature Description">
                                                                </textarea>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="realations">Relations</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="realations" id="realations"
                                                        placeholder="Relations " />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="to_hide_detail">To
                                                        Hide Detail</label>
                                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                                        <input type="radio" id="radio-two_to_hide_detail"
                                                            formControlName="to_hide_detail" name="to_hide_detail"
                                                            value="No" />
                                                        <label for="radio-two_to_hide_detail" id="to_hide_detail"
                                                            class="bs-form-label">No</label>
                                                        <input type="radio" id="radio-one_to_hide_detail"
                                                            formControlName="to_hide_detail" name="to_hide_detail"
                                                            value="Yes" />
                                                        <label for="radio-one_to_hide_detail"
                                                            id="to_hide_detail_yes"
                                                            class="bs-form-label">Yes</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="relation_inclusive">Relation
                                                        Inclusive</label>
                                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                                        <input type="radio" id="radio-two_relation_inclusive"
                                                            formControlName="relation_inclusive"
                                                            name="relation_inclusive" value="No" />
                                                        <label for="radio-two_relation_inclusive"
                                                            id="relation_inclusive" class="bs-form-label">No</label>
                                                        <input type="radio" id="radio-one_relation_inclusive"
                                                            formControlName="relation_inclusive"
                                                            name="relation_inclusive" value="Yes" />
                                                        <label for="radio-one_relation_inclusive"
                                                            id="relation_inclusive_yes"
                                                            class="bs-form-label">Yes</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="sum_insured">Sum
                                                        Insured</label>
                                                    <input type="number" class="form-control"
                                                        formControlName="sum_insured_features" id="sum_insured_features"
                                                        placeholder="0" />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <label class="bs-form-label" for="sum_insured_by">Sum
                                                    Insured By</label>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="sum_insured_by" value="By value"
                                                                name="sum_insured_by"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                checked />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                Value</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="sum_insured_by" value="By SI"
                                                                name="sum_insured_by"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                SI</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="sum_insured_type">Sum
                                                        Insured Type</label>
                                                    <select class="form-select" formControlName="sum_insured_type"
                                                        id="sum_insured_type">
                                                        <option value="0">Select Sum Insured Type</option>
                                                        <option value="Including">Including</option>
                                                        <option value="Additional" selected>Additional</option>
                                                        <option value="No Cover">No Cover</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="premium_by">Premium
                                                        By</label>
                                                    <input type="number" class="form-control"
                                                        formControlName="premium_by" id="premium_by"
                                                        placeholder="0" />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="premium_type">Premium
                                                        Type</label>
                                                    <select class="form-select" formControlName="premium_type"
                                                        id="premium_type">
                                                        <option value="0">Select Premium Type</option>
                                                        <option value="No Premium" selected>No Premium</option>
                                                        <option value="Discount">Discount</option>
                                                        <option value="Loading">Loading</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <label class="bs-form-label" for="is_optional">Is
                                                    optional</label>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="is_optional" value="Yes"
                                                                name="is_optional"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                checked />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="is_optional" value="No"
                                                                name="is_optional"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12">
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-primary" style="float: right;"
                                            (click)="savefeature()">Save</button>
                                        <button type="button" class="btn btn-danger" (click)="closePopup()">
                                            Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- List -->
                    <div class="table-responsive col-sm-12 mt-3" *ngIf="FeatureTableArray.length > 0">
                        <table class="table table-bordered table-sm row-border">
                            <thead>
                                <tr>
                                    <th style="width:5%">SrNo</th>
                                    <th>Feature Description </th>
                                    <!-- <th>Benefit Desc</th>                                         -->
                                    <th style="text-align: center;">Action</th>
                                </tr>
                            </thead>
                            <tbody style="background: #fff;">
                                <tr *ngFor="let group of FeatureTableArray; let i = index">
                                    <td style="width:5%">{{i+1}}</td>
                                    <td>{{group['feature_desc']}}</td>
                                    <!-- <td>{{group['benefit_description']}}</td>                                                                               -->
                                    <td style="width: 15%" align="center">
                                        <img src="../../../assets/img/common icons/trash.png" (click)="removeFeatureTableArray(i)" id="delete_feature" role="button" title="Delete" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                        

                    <div class="divgrey">
                        <div>
                            <p> Initial Premium will be paid by employer </p>
                        </div>
                        <div>
                            <input type="checkbox" class="form-check-input" id="initial_Premium_will_be_paid">
                            <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Will Initial Premium will be paid by employer ? </label>
                        </div>
                    </div>                                            

                    <div class="divgrey">
                        <p> Update Cover Level Configuration</p>
                        <div class="row">                           
                            <div class="col-sm-4">                                
                                <input type="checkbox" class="form-check-input" id="set_allowed_increase" (click)="checkset_allowed_increase()">
                                <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Set Allowed Increase Cover Level ? </label>                                
                            </div>
                            <div class="col-sm-3 form-group set_allowed_increaseDiv" style="display: none">                                
                                <label class="bs-form-label" for="increase_cover_level">Increase Cover Level</label>
                                <input type="text" class="form-control" formControlName="increase_cover_level" id="increase_cover_level" placeholder="Enter Increase Cover Level" (keypress)="isNumber($event)" />                                
                            </div>

                            <div class="col-sm-12"></div>
    
                            <div class="col-sm-4 mt-3">                                
                                <input type="checkbox" class="form-check-input" id="set_allowed_decrease" (click)="checkset_allowed_decrease()">
                                <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Set Allowed Decrease Cover Level ? </label>                                
                            </div>
                            <div class="col-sm-3 form-group mt-3 set_allowed_decreaseDiv" style="display: none">                                
                                <label class="bs-form-label" for="decrease_cover_level">Decrease Cover Level</label>
                                <input type="text" class="form-control" formControlName="decrease_cover_level" id="decrease_cover_level" placeholder="Enter Decrease Cover Level" (keypress)="isNumber($event)" />                                
                            </div>
                        </div>                        
                    </div>                    

                    <!-- Final Submit -->
                    <div class="col-sm-12" align="right">
                        <hr>
                        <button type="button" class="btn btn-primary my-2" (click)="onSubmitAdd()" role="button">Submit</button>
                    </div>

                </form>
            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">

                    <!-- Plan Details -->
                    <h3 class="bs-title mt-4" style="font-size: 18px;">Plan Details</h3>
                    <hr>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="corporate_edit">Corporate</label>
                                <select class="form-select" formControlName="corporate_edit" id="corporate_edit">
                                    <option value="">Select Corporate</option>
                                    <option value="Corporate 1">Corporate 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.corporate_edit}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_type_edit">Policy Type</label>
                                <select class="form-select" formControlName="policy_type_edit" id="policy_type_edit">
                                    <option value="">Select Policy Type</option>
                                    <option value="1">Policy Type</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.policy_type_edit}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_name_edit">Policy Name</label>
                                <select class="form-select" formControlName="policy_name_edit" id="policy_name_edit">
                                    <option value="">Select Policy Name</option>
                                    <option value="Policy Name 1">Policy Name 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.policy_name_edit}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="sum_insured_edit">Sum Insured</label>
                                <select class="form-select" formControlName="sum_insured_edit" id="sum_insured_edit">
                                    <option value="">Select Sum Insured</option>
                                    <option value="10000">10000</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.policy_name_edit}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="plan_name_edit">Plan Name</label>
                                <input type="text" class="form-control" formControlName="plan_name_edit" id="plan_name_edit" placeholder="Plan Name" />
                            </div>
                        </div>
                        <div class="col-sm-12"></div>
                        <div class="col-sm-2 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="roll_back_allowed_edit">Roll Back Allowed</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_roll_back_allowed_edit" formControlName="roll_back_allowed_edit" name="roll_back_allowed_edit" value="No" />
                                    <label for="radio-two_roll_back_allowed_edit" id="roll_back_allowed_edit_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_roll_back_allowed_edit" formControlName="roll_back_allowed_edit" name="roll_back_allowed_edit" value="Yes" />
                                    <label for="radio-one_roll_back_allowed_edit" id="roll_back_allowed_edit_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="show_diff_premium_edit">Show Difference Premium</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_show_diff_premium_edit" formControlName="show_diff_premium_edit" name="show_diff_premium_edit" value="No" />
                                    <label for="radio-two_show_diff_premium_edit" id="show_diff_premium_edit_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_show_diff_premium_edit" formControlName="show_diff_premium_edit" name="show_diff_premium_edit" value="Yes" />
                                    <label for="radio-one_show_diff_premium_edit" id="show_diff_premium_edit_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="plan_description_edit">Plan Discription</label>
                                <textarea type="text" class="form-control" formControlName="plan_description_edit" id="plan_description_edit" placeholder="Enter Comment Here..."></textarea>
                            </div>
                        </div>
                    </div>


                    <!-- ADD On's -->
                    <div class="col-sm-12 mt-5"></div>
                    <h3 class="bs-title" style="font-size: 18px;">Add On's</h3>
                    <hr>
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_name_edit">Benefit Name</label>
                                <select class="form-select" formControlName="benefit_name_edit" id="benefit_name_edit">
                                    <option value="">Select Benefit Name</option>
                                    <option value="Benefit 1">Benefit 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.corporate}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_description_edit">Benefit Description</label>
                                <input type="text" class="form-control" formControlName="benefit_description_edit" id="benefit_description_edit" placeholder="Benefit Description" />
                            </div>
                        </div>
                        <div class="col-sm-3 mt-2">
                            <button type="button" class="btn btn-primary mt-3" (click)="showPopup_edit()" style="cursor: pointer" role="button">Benefit Details +</button>
                        </div>

                        <!-- Benefits Popup -->
                        <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':PopupBenefits_edit}"
                            style="overflow:hidden">
                            <div class="modal-dialog modal-dialog_view" role="document" style="margin-top: 10px;">
                                <div class="modal-content model-content-view" style="overflow-y: hidden !important;">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Update Benefits
                                            <button type="button" class="btn btn-danger" (click)="closePopup()" style="position: absolute;right: 10px;"> &#10006;</button>
                                        </h4>
                                    </div>
                                    <form [formGroup]="benefit_info">
                                        <div class="modal-body modal-body_edit">
                                            <div class="row">

                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_name_edit_val_edit">Benefit Name</label>
                                                        <select class="form-select" formControlName="benefit_name_edit_val_edit" id="benefit_name_edit_val_edit">
                                                            <option value="" selected>Select Benefit Name</option>
                                                            <option value="Benefit 1">Benefit 1</option>
                                                            <!-- <option *ngFor="let policyFeatures of PolicyFeatures" [value]="policyFeatures.id">{{ policyFeatures.title }}</option> -->
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_description_edit_val_edit">Benefit Description</label>
                                                        <input type="text" class="form-control" formControlName="benefit_description_edit_val_edit" id="benefit_description_edit_val_edit" placeholder="Benefit Description" />
                                                    </div>
                                                </div>

                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_visible_from_SI_edit">Benefit Visible From SI</label>
                                                        <input type="text" class="form-control" formControlName="benefit_visible_from_SI_edit" id="benefit_visible_from_SI_edit" placeholder="Benefit Visible From SI" (keypress)="isNumber($event)" />
                                                    </div>
                                                </div>

                                                <div class="col-sm-3 mt-3">
                                                    <label class="bs-form-label" for="no_of_members_allowed">Benefit
                                                        Mandatory</label>

                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input" formControlName="benefit_mandatory_edit" value="Mandatory" name="benefit_mandatory_edit" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                                                <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Mandatory</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="benefit_mandatory_edit" value="Optional"
                                                                    name="benefit_mandatory_edit"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Optional</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-3 mt-3">
                                                    <label class="bs-form-label" for="no_of_members_allowed">Prorata
                                                        Calculation
                                                        Applicable</label>

                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="prorata_calculation_applicable_edit_edit"
                                                                    value="Yes" name="prorata_calculation_applicable_edit_edit"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                    checked />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="prorata_calculation_applicable_edit_edit"
                                                                    value="No" name="prorata_calculation_applicable_edit_edit"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12">
                                                    <hr>
                                                </div>

                                                <!-- Add More -->
                                                <div class="col-sm-12">
                                                    <div class="newbenefitData_edit">

                                                    </div>
                                                </div>

                                                <div class="col-sm-12 mt-3">
                                                    <label class="bs-form-label" (click)="addBenefitData_edit()"
                                                        style="cursor: pointer">
                                                        <img src="../../assets/img/policy/add.png"
                                                            style="width: 20px; margin-top: -4px;" />&nbsp;
                                                        <span style="color: #1489F3;">Add</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-primary" style="float: right;"
                                                (click)="savebenefit_edit()">Save</button>
                                            <button type="button" class="btn btn-danger" (click)="closePopup()">
                                                Close</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                        <!-- List -->
                        <div class="table-responsive col-sm-12 mt-3" *ngIf="BenefitTableArray_edit.length > 0">
                            <table class="table table-bordered table-sm row-border">
                                <thead>
                                    <tr>
                                        <th style="width:5%">SrNo</th>
                                        <th>Benefit Name</th>
                                        <th>Benefit Desc</th>
                                        <th style="text-align: center;">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr *ngFor="let group of BenefitTableArray_edit; let i = index">
                                        <td style="width:5%">{{i+1}}</td>
                                        <td>{{group['benefit_name']}}</td>
                                        <td>{{group['benefit_description']}}</td>
                                        <td style="width: 15%" align="center">
                                            <!-- <img src="../../../assets/img/common icons/edit.png" id="editbefefit"
                                                role="button" title="Edit" /> -->
                                            <img src="../../../assets/img/common icons/trash.png" id="deleteuser"
                                                (click)="removeBenefitTableArray_edit(i)" class="ml-4" role="button"
                                                title="Delete" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>           
                    
                    
                    <!-- What's Not Covered -->
                    <div class="col-sm-12 mt-5">
                        <h3 class="bs-title" style="font-size: 18px;">What's Not Covered</h3>
                    </div>
                    <hr>
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_name_not_cover_edit">Benefit Name</label>
                                <select class="form-select" id="benefit_name_not_cover_edit">
                                    <option value="">Select Benefit Name</option>
                                    <option value="Benefit 1">Benefit 1</option>
                                    <!-- <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">
                                        {{brokerlist.corporate}}</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_description_not_cover_edit">Benefit Description</label>
                                <input type="text" class="form-control" id="benefit_description_not_cover_edit" placeholder="Benefit Description" />
                            </div>
                        </div>
                        <div class="col-sm-3 mt-2">
                            <button type="button" class="btn btn-primary mt-3" (click)="saveWhatsNotCover_edit()" style="cursor: pointer" role="button">Add +</button>
                        </div>

                        <div class="table-responsive col-sm-12 mt-3" *ngIf="NotCoverTableArray_edit.length > 0">
                            <table class="table table-bordered table-sm row-border">
                                <thead>
                                    <tr>
                                        <th style="width:5%">SrNo</th>
                                        <th>Benefit Name</th>
                                        <th>Benefit Desc</th>
                                        <th style="text-align: center;">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr *ngFor="let groupnotcover of NotCoverTableArray_edit; let i = index">
                                        <td style="width:5%">{{i+1}}</td>
                                        <td>{{groupnotcover['benefit_name']}}</td>
                                        <td>{{groupnotcover['benefit_description']}}</td>
                                        <td style="width: 15%" align="center">
                                            <img src="../../../assets/img/common icons/trash.png" id="benefit_row" (click)="removeNotCoverTableArray_edit(i)" role="button" title="Delete" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <!-- Additional Features -->
                    <div class="col-sm-12 mt-5">
                        <h3 class="bs-title" style="font-size: 18px;">Additional Features</h3>
                    </div>
                    <hr>
                    <div class="divgrey">
                        <p>Premium Credid/Debit based on member Addition/Deletion </p>
                        <div>
                            <input type="checkbox" class="form-check-input" id="will_there_be_any_premium_edit" (click)="checkwill_there_be_any_premium_edit()">
                            <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Will there be any Premium Credid/Debit based on member Addition/Deletion ? </label>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 mt-3 will_there_be_any_premium_editDiv_edit" style="display: none">
                                <div class="form-group">
                                    <label class="bs-form-label" for="feature_desc_edit">Feature Description</label>
                                    <input type="text" class="form-control" formControlName="feature_desc_edit" id="feature_desc_edit" placeholder="Enter Feature Description" />
                                </div>
                            </div>
    
                            <div class="col-sm-3 mt-4 will_there_be_any_premium_editDiv_edit" style="display: none;">
                                <button type="button" class="btn btn-primary mt-3" (click)="showPopupFeatures_edit()" style="cursor: pointer" role="button">Fearture Details +</button>    
                            </div>
                        </div>                        

                    </div>

                    <!-- Features Popup -->
                    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':PopupFeatures_edit}"
                        style="overflow:hidden">
                        <div class="modal-dialog modal-dialog_view" role="document" style="margin-top: 10px;">
                            <div class="modal-content model-content-view" style="overflow-y: hidden !important;">
                                <div class="modal-header">
                                    <h4 class="modal-title">Create Benefits
                                        <button type="button" class="btn btn-danger" (click)="closePopup()"
                                            style="position: absolute;right: 10px;"> &#10006;</button>
                                    </h4>
                                </div>
                                <form [formGroup]="features_info_edit">
                                    <div class="modal-body modal-body_edit">
                                        <div class="row">

                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="feature_desc_edit">Feature
                                                        Description</label>
                                                    <textarea type="text" class="form-control"
                                                        formControlName="feature_desc_edit" id="feature_desc_edit_val"
                                                        placeholder="Enter Feature Description">
                                                                </textarea>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="realations_edit">Relations</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="realations_edit" id="realations_edit"
                                                        placeholder="Relations " />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="to_hide_detail_edit">To
                                                        Hide Detail</label>
                                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                                        <input type="radio" id="radio-two_to_hide_detail_edit"
                                                            formControlName="to_hide_detail_edit" name="to_hide_detail_edit"
                                                            value="No" />
                                                        <label for="radio-two_to_hide_detail_edit" id="to_hide_detail_edit"
                                                            class="bs-form-label">No</label>
                                                        <input type="radio" id="radio-one_to_hide_detail_edit"
                                                            formControlName="to_hide_detail_edit" name="to_hide_detail_edit"
                                                            value="Yes" />
                                                        <label for="radio-one_to_hide_detail_edit"
                                                            id="to_hide_detail_edit_yes"
                                                            class="bs-form-label">Yes</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="relation_inclusive_edit">Relation
                                                        Inclusive</label>
                                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                                        <input type="radio" id="radio-two_relation_inclusive_edit"
                                                            formControlName="relation_inclusive_edit"
                                                            name="relation_inclusive_edit" value="No" />
                                                        <label for="radio-two_relation_inclusive_edit"
                                                            id="relation_inclusive_edit" class="bs-form-label">No</label>
                                                        <input type="radio" id="radio-one_relation_inclusive_edit"
                                                            formControlName="relation_inclusive_edit"
                                                            name="relation_inclusive_edit" value="Yes" />
                                                        <label for="radio-one_relation_inclusive_edit"
                                                            id="relation_inclusive_edit_yes"
                                                            class="bs-form-label">Yes</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="sum_insured_edit_features_edit">Sum
                                                        Insured</label>
                                                    <input type="number" class="form-control"
                                                        formControlName="sum_insured_edit_features_edit" id="sum_insured_edit_features_edit"
                                                        placeholder="0" />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <label class="bs-form-label" for="sum_insured_edit_by_edit">Sum
                                                    Insured By</label>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="sum_insured_edit_by_edit" value="By value"
                                                                name="sum_insured_edit_by_edit"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                checked />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                Value</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="sum_insured_edit_by_edit" value="By SI"
                                                                name="sum_insured_edit_by_edit"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                SI</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="sum_insured_edit_type_edit">Sum
                                                        Insured Type</label>
                                                    <select class="form-select" formControlName="sum_insured_edit_type_edit"
                                                        id="sum_insured_edit_type_edit">
                                                        <option value="0">Select Sum Insured Type</option>
                                                        <option value="Including">Including</option>
                                                        <option value="Additional" selected>Additional</option>
                                                        <option value="No Cover">No Cover</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="premium_by_edit">Premium
                                                        By</label>
                                                    <input type="number" class="form-control"
                                                        formControlName="premium_by_edit" id="premium_by_edit"
                                                        placeholder="0" />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="premium_type_edit">Premium
                                                        Type</label>
                                                    <select class="form-select" formControlName="premium_type_edit"
                                                        id="premium_type_edit">
                                                        <option value="0">Select Premium Type</option>
                                                        <option value="No Premium" selected>No Premium</option>
                                                        <option value="Discount">Discount</option>
                                                        <option value="Loading">Loading</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <label class="bs-form-label" for="is_optional_edit">Is
                                                    optional</label>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="is_optional_edit" value="Yes"
                                                                name="is_optional_edit"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                checked />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="is_optional_edit" value="No"
                                                                name="is_optional_edit"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12">
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-primary" style="float: right;"
                                            (click)="savefeature_edit()">Save</button>
                                        <button type="button" class="btn btn-danger" (click)="closePopup()">
                                            Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- List -->
                    <div class="table-responsive col-sm-12 mt-3" *ngIf="FeatureTableArray_edit.length > 0">
                        <table class="table table-bordered table-sm row-border">
                            <thead>
                                <tr>
                                    <th style="width:5%">SrNo</th>
                                    <th>Feature Description </th>
                                    <!-- <th>Benefit Desc</th>                                         -->
                                    <th style="text-align: center;">Action</th>
                                </tr>
                            </thead>
                            <tbody style="background: #fff;">
                                <tr *ngFor="let group of FeatureTableArray_edit; let i = index">
                                    <td style="width:5%">{{i+1}}</td>
                                    <td>{{group['feature_desc']}}</td>
                                    <!-- <td>{{group['benefit_description']}}</td>                                                                               -->
                                    <td style="width: 15%" align="center">
                                        <img src="../../../assets/img/common icons/trash.png" (click)="removeFeatureTableArray_edit(i)" id="delete_feature" role="button" title="Delete" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                        

                    <div class="divgrey">
                        <div>
                            <p> Initial Premium will be paid by employer </p>
                        </div>
                        <div>
                            <input type="checkbox" class="form-check-input" id="initial_Premium_will_be_paid_edit">
                            <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Will Initial Premium will be paid by employer ? </label>
                        </div>
                    </div>                                            

                    <div class="divgrey">
                        <p> Update Cover Level Configuration</p>
                        <div class="row">                           
                            <div class="col-sm-4">                                
                                <input type="checkbox" class="form-check-input" id="set_allowed_increase_edit" (click)="checkset_allowed_increase_edit()">
                                <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Set Allowed Increase Cover Level ? </label>                                
                            </div>
                            <div class="col-sm-3 form-group set_allowed_increase_editDiv_edit" style="display: none">                                
                                <label class="bs-form-label" for="increase_cover_level_edit">Increase Cover Level</label>
                                <input type="text" class="form-control" formControlName="increase_cover_level_edit" id="increase_cover_level_edit" placeholder="Enter Increase Cover Level" (keypress)="isNumber($event)" />                                
                            </div>

                            <div class="col-sm-12"></div>
    
                            <div class="col-sm-4 mt-3">                                
                                <input type="checkbox" class="form-check-input" id="set_allowed_decrease_edit" (click)="checkset_allowed_decrease_edit()">
                                <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Set Allowed Decrease Cover Level ? </label>                                
                            </div>
                            <div class="col-sm-3 form-group mt-3 set_allowed_decrease_editDiv_edit" style="display: none">                                
                                <label class="bs-form-label" for="decrease_cover_level_edit">Decrease Cover Level</label>
                                <input type="text" class="form-control" formControlName="decrease_cover_level_edit" id="decrease_cover_level_edit" placeholder="Enter Decrease Cover Level" (keypress)="isNumber($event)" />                                
                            </div>
                        </div>                        
                    </div>

                    <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />    

                    <!-- Final Submit -->
                    <div class="col-sm-12" align="right">
                        <hr>
                        <button type="button" class="btn btn-primary my-2" (click)="onSubmitAdd()" role="button">Submit</button>
                    </div>

                </form>
            </div>
            
            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Flex Configurator</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Flex Configurator</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id"
                                id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>