<div class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title" *ngIf="addflag == 0">OnBoard Employer
      <button type="button" class="btn btn-success" (click)="openPopupAdd()" role="button" style="float:right">Add New</button>
    </h3>
    <h3 class="bs-title" *ngIf="addflag == 1">OnBoard Employer
        <button type="button" class="btn btn-success" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
    </h3>
    <h3 class="bs-title" *ngIf="addflag == 2">Edit Employer
        <button type="button" class="btn btn-success" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
    </h3>
    
    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <hr>

    <div class="table-responsive mt-4" *ngIf="addflag == 0">
        <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Employer name</th>
                <!-- <th>PAN</th> -->
                <!-- <th>Added date</th> -->
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of InfoArray; let i = index">
                    <td style="width:5%">{{i+1}}</td>
                   <td>{{group['name']}}</td>
                   <!-- <td>{{group['pan']}}</td>                    -->
                   <!-- <td>{{group['added_date']}}</td> -->
                   <td style="width: 15%" align="center">
                    <img src="../../../assets/img/common icons/adduser.png" id="adduser" (click)="adduser(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Add User" />
                    <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/common icons/trash.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:20px" title="Delete" />
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <!-- Add Form -->
    <div *ngIf="addflag == 1">                                        
        <form [formGroup]="AddInfo">                    
            <div class="row">

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="name">Employer Name *</label>
                        <input type="text" class="form-control" formControlName="name" id="name" placeholder="Employer Name"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                        required />
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                Name is required
                            </p>
                        </ng-container>
                    </div>
                </div>                                                                            
                
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="email_1">Email *</label>
                        <input type="text" class="form-control" formControlName="email_1" id="email_1" placeholder="Email" />                                                
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="contact_1">Mobile No. *</label>
                        <input type="text" class="form-control" formControlName="contact_1" id="contact_1" placeholder="Mobile No." (keypress)="isNumber($event)" maxlength="10" />                         
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="employer_code">Employer Code</label>
                        <input type="text" class="form-control" formControlName="employer_code" id="employer_code" placeholder="Employer Code" />                                            
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="status">Status</label>
                        <select class="form-select" formControlName="status" id="status">
                            <option value="" selected>Select</option>
                            <option value="1">Active</option>
                            <option value="0">Deactive</option>
                        </select>                       
                    </div>
                </div>
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pan">Employer PAN No. *</label>
                        <input type="text" class="form-control" formControlName="pan" id="pan" placeholder="PAN" maxlength="10"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.pan.errors}"
                        required />
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan.errors.required">
                              PAN is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="gstin">Employer GST No. *</label>
                        <input type="text" class="form-control" formControlName="gstin" id="gstin" placeholder="GST" maxlength="15"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.gstin.errors}"
                        required />
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin.errors.required">
                              GST is required
                            </p>
                        </ng-container>
                    </div>
                </div>               

                <div class="col-sm-4 mt-3">
                    <div class="form-group">          
                        <label class="bs-form-label" for="industry">Industry</label>                 
                        <ng-select formControlName="industry" id="industry"
                                    [items]="InfoArrayIndustry" 
                                    bindLabel="industry_name" 
                                    bindValue="industry_id"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.industry.errors}" >                                    
                        </ng-select>          
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry.errors">
                            <p class="error">
                            Industry is required
                            </p>
                        </ng-container>              
                    </div>
                </div>

                <div class="col-sm-12 mt-3">
                    <div class="row" style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">

                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" formControlName="hr_info" id="hr_info" (click)="hr_check()" />
                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Should create employer user(HR)?</label>
                        </div>        
                                              
                        <div id="showaddrow" style="display: none">
                            <div class="col-sm-12">
                                <div class="newhr_row">
    
                                </div>
                            </div>
    
                            <div class="col-sm-3 mt-3" id="adrowdiv" style="display: none">
                                <label class="bs-form-label" (click)="addHrRow()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New Row</span>
                                </label>
                            </div>
                        </div>                        

                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_1">Address 1 </label>
                        <input type="text" class="form-control" formControlName="address_line_1" id="address_line_1" placeholder="Address 1" />                        
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_2">Address 2 </label>
                        <input type="text" class="form-control" formControlName="address_line_2" id="address_line_2" placeholder="Address 2" />                        
                    </div>
                </div>                               

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="country">Country</label>
                        <select class="form-select" formControlName="country" id="country" (change)="getState($event)">
                            <option value="" selected>Select Country</option>
                            <option *ngFor="let title of InfoArray_country" [value]="title.country_id">
                                {{title.country_name}}
                            </option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pincode">Pincode</label>
                        <input type="text" class="form-control" formControlName="pincode" id="pincode" placeholder="Pincode" maxlength="6" (keypress)="isNumber($event)" />                        
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="state">State</label>
                        <select class="form-select" formControlName="state" id="state" (change)="getCity($event)">
                            <option value="" selected>Select State</option>
                            <option *ngFor="let title of InfoArray_state" [value]="title.state_id">
                                {{title.state_name}}
                            </option>
                        </select>                       
                    </div>
                </div>
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="city">City</label>
                        <select class="form-select" formControlName="city" id="city">
                            <option value="" selected>Select City</option>
                            <option *ngFor="let title of InfoArray_city" [value]="title.pincode_id">
                                {{title.taluk}}
                            </option>
                        </select>                       
                    </div>
                </div>  
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="employee_type">Type</label>
                        <select class="form-select" formControlName="employee_type" id="employee_type" (change)="getemployeetype($event)">
                            <option value="" selected>Select Type</option>                            
                            <option value="Group">Group</option>                            
                            <option value="Master">Master</option>                            
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-4 mt-3" id="employee_type_div" style="display: none">
                    <div class="form-group">
                        <label class="bs-form-label" for="group_company_name">Group Company Name</label>
                        <select class="form-select" formControlName="group_company_name" id="group_company_name">
                            <option value="" selected>Select Group Company Name</option>                            
                            <option *ngFor="let groupcompany of InfoArray_group_company_name" [value]="groupcompany.id">
                                {{groupcompany.name}}
                            </option>
                        </select>                       
                    </div>
                </div>
              
                <div class="col-sm-12"></div>

                <div class="col-sm-4 mt-3" *ngFor="let theme of InfoArray_themelist; let i = index">
                  

                    <div class="labl" id="div{{theme.id}}" style="padding: 10px; border: solid 1px #949494; border-radius: 10px 0px 10px 0px; cursor: pointer;" (click)="setthemId(theme.id)">
                        <h6>
                            {{theme.name}}    
                        </h6>
                    
                        <!-- button -->
                        <div style="border: solid 1px #949494; display: flex">
                            <span title="button-danger" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['danger']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-default" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['default']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-warning" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['warning']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-outline" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['outline']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-square_outline" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['square_outline']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                        </div>

                        <!-- card -->
                        <div style="border: solid 1px #949494;">
                            <div  style="display: flex">
                                <span title="Card-color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Card']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- CardBlue -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="CardBlue-color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['CardBlue']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- Chip -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="Chip-background" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Chip']['background']}">&nbsp;</span>
                                <span title="Chip-color" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Chip']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- PrimaryColors -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="PrimaryColors-color1" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color1']}">&nbsp;</span>
                                <span title="PrimaryColors-color2" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color2']}">&nbsp;</span>
                                <span title="PrimaryColors-color3" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color3']}">&nbsp;</span>
                                <span title="PrimaryColors-color4" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color4']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- Tab -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="Tab-color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Tab']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>
                         
                    </div>

                </div>

                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-success mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                </div>
            </div>            
        </form>                       
    </div>    

    <!-- Edit Form -->
    <div *ngIf="addflag == 2">                                        
        <form [formGroup]="EditInfo">                    
            <div class="row">

                <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="name_edit">Employer Name *</label>
                        <input type="text" class="form-control" formControlName="name_edit" id="name_edit" placeholder="Employer Name"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name_edit.errors}"
                        required />
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.name_edit.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.name_edit.errors.required">
                                Name is required
                            </p>
                        </ng-container>
                    </div>
                </div>                                                                            
                
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="email_1_edit">Email *</label>
                        <input type="text" class="form-control" formControlName="email_1_edit" id="email_1_edit" placeholder="Email" />                                                
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="contact_1_edit">Mobile No. *</label>
                        <input type="text" class="form-control" formControlName="contact_1_edit" id="contact_1_edit" placeholder="Mobile No." (keypress)="isNumber($event)" maxlength="10" />                         
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="employer_code_edit">Employer Code</label>
                        <input type="text" class="form-control" formControlName="employer_code_edit" id="employer_code_edit" placeholder="Employer Code" />                                            
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="status_edit">Status</label>
                        <select class="form-select" formControlName="status_edit" id="status_edit">
                            <option value="" selected>Select</option>
                            <option value="1">Active</option>
                            <option value="0">Deactive</option>
                        </select>                       
                    </div>
                </div>
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pan_edit">Employer PAN No. *</label>
                        <input type="text" class="form-control" formControlName="pan_edit" id="pan_edit" placeholder="PAN" maxlength="10"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.pan_edit.errors}"
                        required />
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan_edit.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan_edit.errors.required">
                              PAN is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="gstin_edit">Employer GST No. *</label>
                        <input type="text" class="form-control" formControlName="gstin_edit" id="gstin_edit" placeholder="GST" maxlength="15"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.gstin_edit.errors}"
                        required />
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin_edit.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin_edit.errors.required">
                              GST is required
                            </p>
                        </ng-container>
                    </div>
                </div>               

                <div class="col-sm-4 mt-3">
                    <div class="form-group">          
                        <label class="bs-form-label" for="industry_edit">Industry</label>                 
                        <ng-select formControlName="industry_edit" id="industry_edit"
                                    [items]="InfoArrayIndustry" 
                                    bindLabel="industry_name" 
                                    bindValue="industry_id"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.industry_edit.errors}" >                                    
                        </ng-select>          
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_edit.errors">
                            <p class="error">
                            Industry is required
                            </p>
                        </ng-container>              
                    </div>
                </div>

                <div class="col-sm-12 mt-3">
                    <div class="row" style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">

                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" formControlName="hr_info_edit" id="hr_info_edit" (click)="hr_check_edit()" />
                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Should create employer user(HR)?</label>
                        </div>        
                                              
                        <div id="showaddrow_edit" style="display: none">
                            <div class="col-sm-12">
                                <div class="newhr_row_edit">
    
                                </div>
                            </div>
    
                            <div class="col-sm-3 mt-3" id="adrowdiv_edit" style="display: none">
                                <label class="bs-form-label" (click)="addHrRow_edit()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New Row</span>
                                </label>
                            </div>
                        </div>                        

                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_1_edit">Address 1 </label>
                        <input type="text" class="form-control" formControlName="address_line_1_edit" id="address_line_1_edit" placeholder="Address 1" />                        
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_2_edit">Address 2 </label>
                        <input type="text" class="form-control" formControlName="address_line_2_edit" id="address_line_2_edit" placeholder="Address 2" />                        
                    </div>
                </div>                               

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="country_edit">Country</label>
                        <select class="form-select" formControlName="country_edit" id="country_edit" (change)="getState($event)">
                            <option value="" selected>Select Country</option>
                            <option *ngFor="let title of InfoArray_country" [value]="title.country_id">
                                {{title.country_name}}
                            </option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pincode_edit">Pincode</label>
                        <input type="text" class="form-control" formControlName="pincode_edit" id="pincode_edit" placeholder="Pincode" maxlength="6" (keypress)="isNumber($event)" />                        
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="state_edit">State</label>
                        <select class="form-select" formControlName="state_edit" id="state_edit" (change)="getCity($event)">
                            <option value="" selected>Select State</option>
                            <option *ngFor="let title of InfoArray_state" [value]="title.state_id">
                                {{title.state_name}}
                            </option>
                        </select>                       
                    </div>
                </div>
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="city_edit">City</label>
                        <select class="form-select" formControlName="city_edit" id="city_edit">
                            <option value="" selected>Select City</option>
                            <option *ngFor="let title of InfoArray_city" [value]="title.pincode_id">
                                {{title.taluk}}
                            </option>
                        </select>                       
                    </div>
                </div>  
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="employee_type_edit">Type</label>
                        <select class="form-select" formControlName="employee_type_edit" id="employee_type_edit" (change)="getemployeetype_edit($event)">
                            <option value="" selected>Select Type</option>                            
                            <option value="Group">Group</option>                            
                            <option value="Master">Master</option>                            
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-4 mt-3" id="employee_type_edit_div" style="display: none">
                    <div class="form-group">
                        <label class="bs-form-label" for="group_company_name_edit">Group Compan_edity Name</label>
                        <select class="form-select" formControlName="group_company_name_edit" id="group_company_name_edit">
                            <option value="" selected>Select Group Compan_edity Name</option>                            
                            <option *ngFor="let groupcompan_edity of InfoArray_group_company_name" [value]="groupcompan_edity.id">
                                {{groupcompan_edity.name}}
                            </option>
                        </select>                       
                    </div>
                </div>
              
                <div class="col-sm-12"></div>

                <div class="col-sm-4 mt-3" *ngFor="let theme of InfoArray_themelist; let i = index">
                  

                    <div class="labl" id="divedit{{theme.id}}" style="padding: 10px; border: solid 1px #949494; border-radius: 10px 0px 10px 0px; cursor: pointer;" (click)="setthemId_edit(theme.id)">
                        <h6>
                            {{theme.name}}    
                        </h6>
                    
                        <!-- button -->
                        <div style="border: solid 1px #949494; display: flex">
                            <span title="button-danger" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['danger']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-default" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['default']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-warning" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['warning']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-outline" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['outline']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button-square_outline" [ngStyle]="{'width':'20%', height:'20px', 'background' : theme.themejson['Button']['square_outline']['background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                        </div>

                        <!-- card -->
                        <div style="border: solid 1px #949494;">
                            <div  style="display: flex">
                                <span title="Card-color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Card']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- CardBlue -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="CardBlue-color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['CardBlue']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- Chip -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="Chip-background" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Chip']['background']}">&nbsp;</span>
                                <span title="Chip-color" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Chip']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- PrimaryColors -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="PrimaryColors-color1" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color1']}">&nbsp;</span>
                                <span title="PrimaryColors-color2" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color2']}">&nbsp;</span>
                                <span title="PrimaryColors-color3" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color3']}">&nbsp;</span>
                                <span title="PrimaryColors-color4" [ngStyle]="{'width':'25%', height:'20px', 'background' : theme.themejson['PrimaryColors']['color4']}">&nbsp;</span>
                            </div>                                    
                        </div>

                        <!-- Tab -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="Tab-color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Tab']['color']}">&nbsp;</span>
                            </div>                                    
                        </div>
                         
                    </div>

                </div>

                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-success mt-3" (click)="onSubmitEdit()" role="button">Update</button>                    
                </div>
            </div>            
        </form>                       
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Delete OnBoard Employer</h4>
              </div>
              <form [formGroup]="DeleteInfo">
                  <div class="modal-body">                                
                      Are you sure you want to delete?
                  </div>
                  <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                  <div class="modal-footer">
                      <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>                    
                      <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                  </div>
              </form>            
          </div>
      </div>
    </div>    

</div>