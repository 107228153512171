import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ops-bulk-endorsement',
  templateUrl: './ops-bulk-endorsement.component.html',
  styleUrls: ['./ops-bulk-endorsement.component.css']
})
export class OpsBulkEndorsementComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;
  InfoArraydropdown: any = [];
  document_file: any;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.getInfodropdown();
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  getInfodropdown(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "employer_id": localStorage.getItem('employer_id')
    };

    this.dataservice.getInfodropdown(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArraydropdown = result.data; 

        }, (error) => {          
          
          this.InfoArraydropdown = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  endorsement_doc(event: any){   

    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      // iSize = (Math.round(iSize * 100) / 100);

      // $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      // $('.sizeerror').css('display', 'none');
      $('#submitbtn').css('pointer-events', 'auto');
      $('#submitbtn').css('background', 'var(--bs-btn-bg)');
      $('#submitbtn').css('border-color', 'var(--bs-btn-border)');

    }
  }

  onSubmit(){

    var document_name = $('#document').val();
    var client_name = $('#client_name').val();

    if(this.document_file == "" || this.document_file == null || this.document_file == undefined || document_name == ""){

      if(this.document_file == "" || this.document_file == null || this.document_file == undefined){
        $('#document_file_error').css('display', 'block');
        $('#features-file-upload_document_file').css('border', 'solid 1px red');
      }else{
        $('#document_file_error').css('display', 'none');
        $('#features-file-upload_document_file').css('border', '1px solid #ced4da;');
      }

      if(document_name == ""){
        $('#document_error').css('display', 'block');
        $('#document').css('border', 'solid 1px red');
      }else{
        $('#document_error').css('display', 'none');
        $('#document').css('border', '1px solid #ced4da;');
      }

      
      if(client_name == ""){
        $('#client_name_error').css('display', 'block');
        $('#client_name').css('border', 'solid 1px red');
      }else{
        $('#client_name_error').css('display', 'none');
        $('#client_name').css('border', 'solid 1px #ced4da;');
      }

    }else{

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),

        "client_id": $('#client_name').val(),
        "document_file": this.document_file,
      };

      console.log(postdata);

      this.dataservice.onSubmitFirst(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {
            // console.log(result);
            window.location.reload();

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    }

  }
}
