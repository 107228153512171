import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { TpaDetailsService } from '../tpa-details/tpa-details';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-tpa-details',
  templateUrl: './tpa-details.component.html',
  styleUrls: ['./tpa-details.component.css']
})
export class TpaDetailsComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayBroker: any = [];    
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: TpaDetailsService, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getBroker();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      name: new FormControl('', Validators.required),
      contact_1: new FormControl('', Validators.required),
      email_1: new FormControl('', Validators.required),      
      broker_idfk:new FormControl(''),
      address_line_1:new FormControl('', Validators.required)
    });

    // Form element defined below
    this.EditInfo = this.fb.group({      
      name_edit: new FormControl('', Validators.required),
      contact_1_edit: new FormControl('', Validators.required),
      email_1_edit: new FormControl('', Validators.required),
      broker_idfk_edit:new FormControl(''),
      address_line_1_edit:new FormControl('', Validators.required),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "contact_1":this.AddInfo.controls['contact_1'].value,
        "email_1":this.AddInfo.controls['email_1'].value,
        "name":this.AddInfo.controls['name'].value,
        "broker_idfk":this.AddInfo.controls['broker_idfk'].value,
        "address_line_1":this.AddInfo.controls['address_line_1'].value,
        "client_id":localStorage.getItem('client_id')
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          


    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['contact_1_edit'].setValue(result.data[0]['contact_1']);
        this.EditInfo.controls['email_1_edit'].setValue(result.data[0]['email_1']);
        this.EditInfo.controls['name_edit'].setValue(result.data[0]['name']);
        this.EditInfo.controls['broker_idfk_edit'].setValue(result.data[0]['broker_idfk']);
        this.EditInfo.controls['address_line_1_edit'].setValue(result.data[0]['address_line_1']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['tpa_id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "contact_1":this.EditInfo.controls['contact_1_edit'].value,
        "email_1":this.EditInfo.controls['email_1_edit'].value,
        "name":this.EditInfo.controls['name_edit'].value,
        "broker_idfk":this.EditInfo.controls['broker_idfk_edit'].value,
        "address_line_1":this.EditInfo.controls['address_line_1_edit'].value,
        "client_id":localStorage.getItem('client_id'),
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.dataservice.InfoUpdate(postdata)
      .subscribe((result: any) => {
          
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none'); 
          this.closePopup();         
          this.validmsg = result.message;
          // this.getInfo();
          // this.dtTrigger.unsubscribe();

          window.location.reload();

        }else {
            this.show = false;
        }
      }, (error) => {          
        this.closePopup();         
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['tpa_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['tpa_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  getBroker(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getBroker()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

}
