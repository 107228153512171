import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }    

  // getInfo(data:any): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
  //   };
  //   return this.http.post(this.AUTH_API + 'employeedashboard/getdashboard', 
  //     data,
  //     httpOptions);
  // }  

  getpolicy(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'commonclaimpolicy-list', 
      data,
      httpOptions);
  }  

  getdashboard(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'commonclaimpolicy-dashboard', 
      data,
      httpOptions);
  }  

  getSummary(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'commonclaimpolicy/summary/' + data.id, 
      data,
      httpOptions);
  }

  AddInfo(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'opsmanager/endorsementstore', 
      data,
      httpOptions);
  }

  getInfodropdown(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwise', 
      data,
      httpOptions);
  }

  getclientwisepolicy_list(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'clientwisepolicy-list', 
      data,
      httpOptions);
  }

  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'broker/endorsement-list',
      data,
      httpOptions);
  }
  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'broker/endorsement-show', 
      data
    , httpOptions);
  }

  // InfoUpdate(data: any): Observable<any> {
  //   let filedata = new FormData();

  //   filedata.append("tokenable_id",data.tokenable_id);
  //   filedata.append("endorsement_id",data.endorsement_id);
  //   filedata.append("broker_id",data.broker_id);
  //   filedata.append("status_name",data.status_name);
  //   filedata.append("endo_copy",data.endo_copy);

  //   const httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
  //   };
  //   return this.http.post(this.AUTH_API + 'broker/endorsement-update',
  //   filedata
  //   , httpOptions);
  // }

  InfoUpdate(data: any, token: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("endorsement_id",data.endorsement_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("status_name",data.status_name);
    filedata.append("insurer_endo_no",data.insurer_endo_no);
    filedata.append("endo_copy",data.endo_copy);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'broker/endorsement-update',
      filedata
    , httpOptions);
  }

  download_EndoFile(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'broker/downloadendoexcel',
      data
    , httpOptions);
  }

  UploadEndoCopy(data: any, token: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("endorsement_id",data.endorsement_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("document_file",data.document_file);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'broker/endorsementuploadbroker',
      filedata
    , httpOptions);
  }

  download(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ token})
    };
    return this.http.post(this.AUTH_API + 'endorsement-download',
      data
    , httpOptions);
  }
}