<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

           <div class="row">
                <div class="col-sm-8">

                    <div class="row" style="margin-top: -15px;">
                        <div class="col-sm-6 p-0">
                            <div class="p-3">
                                <div class="card">
                                    <div class="p-2 section1">
                                        <img src="../../../../../assets/img/employee/group1.png" alt="img" style="width: 30px;" />
                                        <label class="bs-label" style="margin-left: 10px; font-size: 13px; font-weight: bold;">Group Mediclaim Policy</label>
                                    </div>
                                    <div class="p-2 section2">
                                        <p>
                                            4016/x/2222904122/02/000
                                        </p>
                                        <p>
                                            Enrollment Confirmation
                                        </p>
                                        <p>
                                            Status - <span style="color: #F8934C"><b>Done</b></span>
                                        </p>
                                        <div align="center">
                                            <button class="btn btn-primary" (click)="navigateto()" role="button">Enrollment Window Open</button>
                                            <!-- <button class="btn btn-primary" style="background: #fff; border: solid 1px #F8934C; color: #F8934C;">Enrollment Window Closed</button> -->
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 p-0">
                            <div class="p-3">
                                <div class="card">
                                    <div class="p-2 section1">
                                        <img src="../../../../../assets/img/employee/group2.png" alt="img" style="width: 30px;" />
                                        <label class="bs-label" style="margin-left: 10px; font-size: 13px; font-weight: bold;">Voluntary Parents Policy</label>
                                    </div>
                                    <div class="p-2 section2">
                                        <p>
                                            4016/x/2222904122/02/000
                                        </p>
                                        <p>
                                            Enrollment Confirmation
                                        </p>
                                        <p>
                                            Status - <span style="color: #F8934C"><b>Pending</b></span>
                                        </p>
                                        <div align="center">
                                            <!-- <button class="btn btn-primary">Enrollment Window Open</button> -->
                                            <button class="btn btn-primary" style="background: #fff; border: solid 1px #F8934C; color: #F8934C;">Enrollment Window Closed</button>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 p-0">
                            <div class="p-3 pt-0">
                                <div class="card">
                                    <div class="p-2 section1">
                                        <img src="../../../../../assets/img/employee/group3.png" alt="img" style="width: 30px;" />
                                        <label class="bs-label" style="margin-left: 10px; font-size: 13px; font-weight: bold;">Voluntary Parents In Laws Policy</label>
                                    </div>
                                    <div class="p-2 section2">
                                        <p>
                                            4016/x/2222904122/02/000
                                        </p>
                                        <p>
                                            Enrollment Confirmation
                                        </p>
                                        <p>
                                            Status - <span style="color: #F8934C"><b>Done</b></span>
                                        </p>
                                        <div align="center">
                                            <button class="btn btn-primary" (click)="navigateto()" role="button">Enrollment Window Open</button>
                                            <!-- <button class="btn btn-primary" style="background: #fff; border: solid 1px #F8934C; color: #F8934C;">Enrollment Window Closed</button> -->
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 p-0">
                            <div class="p-3 pt-0">
                                <div class="card">
                                    <div class="p-2 section1">
                                        <img src="../../../../../assets/img/employee/group4.png" alt="img" style="width: 30px;" />
                                        <label class="bs-label" style="margin-left: 10px; font-size: 13px; font-weight: bold;">Group Personal Accident Policy</label>
                                    </div>
                                    <div class="p-2 section2">
                                        <p>
                                            4016/x/2222904122/02/000
                                        </p>
                                        <p>
                                            Enrollment Confirmation
                                        </p>
                                        <p>
                                            Status - <span style="color: #F8934C"><b>Done</b></span>
                                        </p>
                                        <div align="center">
                                            <button class="btn btn-primary" (click)="navigateto()" role="button">Enrollment Window Open</button>
                                            <!-- <button class="btn btn-primary" style="background: #fff; border: solid 1px #F8934C; color: #F8934C;">Enrollment Window Closed</button> -->
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-1">
                        <iframe width="100%" height="300"
                            src="https://www.youtube.com/embed/tgbNymZ7vqY">
                        </iframe>
                    </div>
                    
                </div>
                <div class="col-sm-4">
                    <div style="background-color: #fff; padding: 10px; box-shadow: 0px 0px 10px 1px #EEEEEE;">
                        <h5 style="color: #3c0111; font-size: 14px; font-weight: bold;">Space to show some related content</h5>
                        <hr>
                        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>

                        <h5 style="color: #3c0111; font-size: 14px; font-weight: bold; margin-top: 20px;">Space to show some related content</h5>
                        <hr>
                        <ul>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                        </ul>


                        <h5 style="color: #3c0111; font-size: 14px; font-weight: bold; margin-top: 20px;">Space to show some related content</h5>
                        <hr>

                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../../assets/img/employee/rectangle.png" alt="img" />
                            </div>
                            <div class="col-sm-10">
                                <label class="bs-label" style="font-size: 13px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit.</label>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-sm-2">
                                <img src="../../../../../assets/img/employee/rectangle.png" alt="img" />
                            </div>
                            <div class="col-sm-10">
                                <label class="bs-label" style="font-size: 13px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit.</label>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-sm-2">
                                <img src="../../../../../assets/img/employee/rectangle.png" alt="img" />
                            </div>
                            <div class="col-sm-10">
                                <label class="bs-label" style="font-size: 13px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit.</label>
                            </div>
                        </div>
                        
                    </div>
                </div>
           </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
