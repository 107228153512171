import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-flex-configurator',
  templateUrl: './flex-configurator.component.html',
  styleUrls: ['./flex-configurator.component.css']
})
export class FlexConfiguratorComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  cnt_BenefitData = 1;
  cnt_allowed = 1;

  cnt_BenefitData_edit = 1;
  cnt_allowed_edit = 1;

  public benefit_info:FormGroup;
  public features_info:FormGroup;

  public benefit_info_edit:FormGroup;
  public features_info_edit:FormGroup;

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayBroker: any = [];
  
  BenefitTableArray: any = [];
  FeatureTableArray: any = [];
  NotCoverTableArray: any = [];

  BenefitTableArray_edit: any = [];
  FeatureTableArray_edit: any = [];
  NotCoverTableArray_edit: any = [];
  
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  PopupBenefits = "none";
  PopupFeatures = "none";
  PopupBenefits_edit = "none";
  PopupFeatures_edit = "none";
  PopupAuditDetails = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getPolicy();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    //Add
    // Form element defined below
    this.AddInfo = this.fb.group({
      corporate: new FormControl(''),
      policy_type: new FormControl(''),  
      policy_name: new FormControl(''),    
      sum_insured: new FormControl(''),    
      plan_name: new FormControl(''),    
      roll_back_allowed: new FormControl('Yes'),
      show_diff_premium: new FormControl('Yes'),
      plan_description: new FormControl(''),
      benefit_name: new FormControl(''),
      benefit_description: new FormControl(''),

      set_allowed_increase: new FormControl(''),
      increase_cover_level: new FormControl(''),
      set_allowed_decrease: new FormControl(''),      
      decrease_cover_level: new FormControl(''),
     
    });        

    this.benefit_info = this.fb.group({        
      benefit_name_val:new FormControl(''),
      benefit_description_val:new FormControl(''),
      benefit_visible_from_SI:new FormControl(''),
      benefit_mandatory:new FormControl('Mandatory'),
      prorata_calculation_applicable:new FormControl('Yes'),
    });

    this.features_info = this.fb.group({        
      feature_desc:new FormControl(''),
      realations:new FormControl(''),
      to_hide_detail:new FormControl('Yes'),
      relation_inclusive:new FormControl('Yes'),
      sum_insured:new FormControl(''),
      sum_insured_type:new FormControl(''),
      sum_insured_by:new FormControl(''),
      premium_by:new FormControl(''),
      premium_type:new FormControl(''),
      is_optional:new FormControl('Yes'),
    });

    //Edit
    // Form element defined below
    this.EditInfo = this.fb.group({      
     
      corporate_edit: new FormControl(''),
      policy_type_edit: new FormControl(''),  
      policy_name_edit: new FormControl(''),    
      sum_insured_edit: new FormControl(''),    
      plan_name_edit: new FormControl(''),    
      roll_back_allowed_edit: new FormControl('Yes'),
      show_diff_premium_edit: new FormControl('Yes'),
      plan_description_edit: new FormControl(''),
      benefit_name_edit: new FormControl(''),
      benefit_description_edit: new FormControl(''),

      set_allowed_increase_edit: new FormControl(''),
      increase_cover_level_edit: new FormControl(''),
      set_allowed_decrease_edit: new FormControl(''),      
      decrease_cover_level_edit: new FormControl(''),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    this.benefit_info_edit = this.fb.group({        
      benefit_name_val_edit:new FormControl(''),
      benefit_description_val_edit:new FormControl(''),
      benefit_visible_from_SI_edit:new FormControl(''),
      benefit_mandatory_edit:new FormControl('Mandatory'),
      prorata_calculation_applicable_edit:new FormControl('Yes'),
    });

    this.features_info_edit = this.fb.group({        
      feature_desc_edit:new FormControl(''),
      realations_edit:new FormControl(''),
      to_hide_detail_edit:new FormControl('Yes'),
      relation_inclusive_edit:new FormControl('Yes'),
      sum_insured_edit:new FormControl(''),
      sum_insured_type_edit:new FormControl(''),
      sum_insured_by_edit:new FormControl(''),
      premium_by_edit:new FormControl(''),
      premium_type_edit:new FormControl(''),
      is_optional_edit:new FormControl('Yes'),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      // this.show = true;
      // this.fullScreen = true;
      // this.template = ``; 

      var roll_back_allowed:any;

      if(this.AddInfo.controls['roll_back_allowed'].value == 'Yes'){
        roll_back_allowed = 1;
      }else{
        roll_back_allowed = 0;
      }      
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "roll_back_allowed": roll_back_allowed,
        "corporate":this.AddInfo.controls['corporate'].value,        
        "policy_type":this.AddInfo.controls['policy_type'].value,        
        "policy_name":this.AddInfo.controls['policy_name'].value,        
        "sum_insured":this.AddInfo.controls['sum_insured'].value,        
        "plan_name":this.AddInfo.controls['plan_name'].value,        
        "plan_description":this.AddInfo.controls['plan_description'].value,                

        "will_there_be_any_premium":$('#will_there_be_any_premium').is(':checked'),        
        "initial_Premium_will_be_paid":$('#initial_Premium_will_be_paid').is(':checked'),
                
        "set_allowed_increase":$('#set_allowed_increase').is(':checked'),
        "increase_cover_level":this.AddInfo.controls['increase_cover_level'].value,
        "set_allowed_decrease":$('#set_allowed_decrease').is(':checked'),
        "decrease_cover_level":this.AddInfo.controls['decrease_cover_level'].value,                      

        "benefitTableArray": JSON.stringify(this.BenefitTableArray),
        "notCoverTableArray": JSON.stringify(this.NotCoverTableArray),
        "featureTableArray": JSON.stringify(this.FeatureTableArray),

      };

      console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {
          this.show = false;          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }


  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          
    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        // this.EditInfo.controls['corporate_edit'].setValue(result.data[0]['corporate']);  
        // this.EditInfo.controls['policy_type_edit'].setValue(result.data[0]['policy_type']);
        // this.EditInfo.controls['policy_name_edit'].setValue(result.data[0]['policy_name']);
        // this.EditInfo.controls['sum_insured_edit'].setValue(result.data[0]['sum_insured']);
        // this.EditInfo.controls['plan_name_edit'].setValue(result.data[0]['plan_name']);
        // this.EditInfo.controls['roll_back_allowed_edit'].setValue(result.data[0]['roll_back_allowed']);
        // this.EditInfo.controls['show_diff_premium_edit'].setValue(result.data[0]['show_diff_premium']);
        // this.EditInfo.controls['plan_description_edit'].setValue(result.data[0]['plan_description']);
        // this.EditInfo.controls['benefit_name_edit'].setValue(result.data[0]['benefit_name']);
        // this.EditInfo.controls['benefit_description_edit'].setValue(result.data[0]['benefit_description']);

        // this.EditInfo.controls['set_allowed_increase_edit'].setValue(result.data[0]['set_allowed_increase']);
        // this.EditInfo.controls['increase_cover_level_edit'].setValue(result.data[0]['increase_cover_level']);

        // this.EditInfo.controls['set_allowed_decrease_edit'].setValue(result.data[0]['set_allowed_decrease']);
        // this.EditInfo.controls['decrease_cover_level_edit'].setValue(result.data[0]['decrease_cover_level']);

        // if(result.data[0]['roll_back_allowed'] == 1){
        //   this.EditInfo.controls['is_included_in_incurred_amount_edit'].setValue('Yes');
        // }else{
        //   this.EditInfo.controls['is_included_in_incurred_amount_edit'].setValue('No');
        // }
        
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    var roll_back_allowed_edit:any;

    if(this.EditInfo.controls['roll_back_allowed_edit'].value == 'Yes'){
      roll_back_allowed_edit = 1;
    }else{
      roll_back_allowed_edit = 0;
    }      
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "roll_back_allowed": roll_back_allowed_edit,
      "corporate":this.EditInfo.controls['corporate_edit'].value,        
      "policy_type":this.EditInfo.controls['policy_type_edit'].value,        
      "policy_name":this.EditInfo.controls['policy_name_edit'].value,        
      "sum_insured":this.EditInfo.controls['sum_insured_edit'].value,        
      "plan_name":this.EditInfo.controls['plan_name_edit'].value,        
      "plan_description":this.EditInfo.controls['plan_description_edit'].value,                

      "will_there_be_any_premium":$('#will_there_be_any_premium_edit').is(':checked'),        
      "initial_Premium_will_be_paid":$('#initial_Premium_will_be_paid_edit').is(':checked'),
              
      "set_allowed_increase":$('#set_allowed_increase_edit').is(':checked'),
      "increase_cover_level":this.EditInfo.controls['increase_cover_level_edit'].value,
      "set_allowed_decrease":$('#set_allowed_decrease_edit').is(':checked'),
      "decrease_cover_level":this.EditInfo.controls['decrease_cover_level_edit'].value,                      

      "benefitTableArray": JSON.stringify(this.BenefitTableArray_edit),
      "notCoverTableArray": JSON.stringify(this.NotCoverTableArray_edit),
      "featureTableArray": JSON.stringify(this.FeatureTableArray_edit),

      "id": id,
      "is_active":this.EditInfo.controls['edit_is_active'].value

    };
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;     

      this.dataservice.InfoUpdate(postdata)
      .subscribe((result: any) => {
          
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none'); 
          this.closePopup();         
          this.validmsg = result.message;
          // this.getInfo();
          // this.dtTrigger.unsubscribe();

          window.location.reload();

        }else {
            this.show = false;
        }
      }, (error) => {          
        this.closePopup();         
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "status": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "status": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  getPolicy(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getPolicy()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    
    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none"; 
    this.PopupBenefits="none";
    this.PopupFeatures="none";
    this.PopupBenefits_edit="none";
    this.PopupFeatures_edit="none";
    this.PopupAuditDetails="none";
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

  showPopup(){

    this.PopupBenefits = "block";

    let val1:any = $('#benefit_name').val();
    $('#benefit_name_val').val(val1);

    let val2:any = $('#benefit_description').val();
    $('#benefit_description_val').val(val2);

  }

   /***** Add Field *****/
   addBenefitData(){

    let html = `
    <div id="this_Data_benefit_row_`+this.cnt_BenefitData+`" class="row mt-3">
      
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="feature_name_`+this.cnt_BenefitData+`">Feature Name </label>
          <input type="text" class="form-control" formControlName="feature_name_`+this.cnt_BenefitData+`" id="feature_name_`+this.cnt_BenefitData+`" placeholder="Feature Name" />
      </div>

      <div class="col-sm-6 form-group mt-3">
          <label class="bs-form-label" for="feature_description_`+this.cnt_BenefitData+`">Feature Description </label>
          <input type="text" class="form-control" formControlName="feature_description_`+this.cnt_BenefitData+`" id="feature_description_`+this.cnt_BenefitData+`" placeholder="Enter Content Here..." />
      </div>        

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="add_on_SI_`+this.cnt_BenefitData+`">Add On SI</label>
          <input type="text" class="form-control" formControlName="add_on_SI_`+this.cnt_BenefitData+`" id="add_on_SI_`+this.cnt_BenefitData+`" placeholder="Add On SI" value="0" />
      </div>     

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="cover_by_`+this.cnt_BenefitData+`">Cover By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+this.cnt_BenefitData+`" value="by_value" name="cover_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+this.cnt_BenefitData+`" value="by_SI" name="cover_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By SI %</label>
                  </div>
              </div>
          </div>
      </div>
        
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="cover_type_`+this.cnt_BenefitData+`">Cover Type</label>
          <select class="form-select" formControlName="cover_type_`+this.cnt_allowed+`" id="cover_type_`+this.cnt_allowed+`">
                <option value="" selected>Select Cover Type</option>
                <option value="Cover Type 1">Cover Type 1</option>
          </select>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="sum_insured_cap_`+this.cnt_BenefitData+`">Sum Insured Cap</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+this.cnt_BenefitData+`" value="Yes" name="sum_insured_cap_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+this.cnt_BenefitData+`" value="No" name="sum_insured_cap_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="premium_by_`+this.cnt_BenefitData+`">Premium By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+this.cnt_BenefitData+`" value="by_value" name="premium_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+this.cnt_BenefitData+`" value="by_premium" name="premium_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Premium %</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_value_`+this.cnt_BenefitData+`">Premium Value</label>
          <input type="text" class="form-control" formControlName="premium_value_`+this.cnt_BenefitData+`" id="premium_value_`+this.cnt_BenefitData+`" placeholder="Premium Value" value="0" />
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_type_`+this.cnt_BenefitData+`">Premium Type</label>
          <select class="form-select" formControlName="premium_type_`+this.cnt_allowed+`" id="premium_type_`+this.cnt_allowed+`">
                <option value="" selected>Select Premium Type</option>
                <option value="Premium Type 1">Premium Type 1</option>
          </select>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="visible_from_SI_value_`+this.cnt_BenefitData+`">Visible From SI Value</label>
          <input type="text" class="form-control" formControlName="visible_from_SI_value_`+this.cnt_BenefitData+`" id="visible_from_SI_value_`+this.cnt_BenefitData+`" placeholder="Visible From SI Value" value="0" />
      </div>
      
      <div class="col-sm-2 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="Data_benefit_row_`+this.cnt_BenefitData+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
      <div class="col-sm-12"><hr></div>
    </div>      
    `;

    $('.newbenefitData').append(html);

    // for(var i=0; i<this.Membertype.length; i++){
    //   var val = '#' + 'cover_type_'+ this.cnt;
    //   $(val)
    //     .append($("<option></option>")
    //       .attr("value", this.Membertype[i]['id'])
    //       .text(this.Membertype[i]['name']));
    // }

    // this.familyInfo.addControl("feature_name_"+this.cnt_BenefitData, new FormControl(''));
    // this.familyInfo.addControl("feature_description_"+this.cnt_BenefitData, new FormControl(''));
    // this.familyInfo.addControl("add_on_SI_"+this.cnt_BenefitData, new FormControl(''));

    // this.BenefitDataArr[this.cnt_BenefitData] = {member_type: "", no_age: "", min_age: "", max_age: ""};

    let flag = this;
    $("#Data_benefit_row_"+this.cnt_BenefitData).on('click',function(e) {
      flag.removeRow_Benefit(e);
    });

    $("#add_on_SI_"+this.cnt_BenefitData).on('keyup',function(e) {
      flag.isnumberBenefit_benefit(e);
    });

    $("#premium_value_"+this.cnt_BenefitData).on('keyup',function(e) {
      flag.isnumberBenefit_benefit(e);
    });

    $("#visible_from_SI_value_"+this.cnt_BenefitData).on('keyup',function(e) {
      flag.isnumberBenefit_benefit(e);
    });

    this.cnt_BenefitData++;

  }


  removeRow_Benefit(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_Data_benefit_row_' + id;
    $(idstr).remove();

  }


  isnumberBenefit_benefit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  showPopupFeatures(){

    this.PopupFeatures = "block";

    let val1:any = $('#feature_desc').val();
    $('#feature_desc_val').val(val1);

  }

  //On click save button in benefit popup
  savebenefit(){

    this.PopupBenefits="none";

    let val1:any = $('#benefit_name_val').val();
    let val2:any = $('#benefit_description_val').val();
    let val3 = $('#benefit_visible_from_SI').val();        
    let val4 = $('input[name="benefit_mandatory"]:checked').val();
    let val5 = $('input[name="prorata_calculation_applicable"]:checked').val();

    let arr:any = {};
    for(var i = 1; i < this.cnt_BenefitData; i++){     
      arr[i] = {
        feature_name: $('#feature_name_'+i).val(), 
        feature_description:$('#feature_description_'+i).val(),
        add_on_SI: $('#add_on_SI_'+i).val(), 
        cover_by: $('input[name="cover_by_'+i+'"]:checked').val(), 
        cover_type: $('#cover_type_'+i).val(), 
        sum_insured_cap: $('input[name="sum_insured_cap_'+i+'"]:checked').val(),       
        premium_by: $('input[name="premium_by_'+i+'"]:checked').val(),
        premium_value: $('#premium_value_'+i).val(), 
        premium_type: $('#premium_type_'+i).val(),           
        visible_from_SI_value: $('#visible_from_SI_value_'+i).val(),
      };
    }

    this.BenefitTableArray.push(
      {
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "benefit_mandatory": val4,
        "prorata_calculation_applicable": val5,
        "tableArray": arr,
      }
    )

  

    // show this array in table of added benefit
    console.log(this.BenefitTableArray);        
    $('.newbenefitData').remove();
    this.cnt_BenefitData = 1;
    $('#benefit_visible_from_SI').val('');
    $('#benefit_name').val('');
    $('#benefit_description').val('');
  }  

  savefeature(){

    this.PopupFeatures="none";

    let val1:any = $('#feature_desc').val();
    let val2:any = $('#realations').val();
    let val3 = $('#sum_insured_features').val();        
    let val4 = $('#sum_insured_type').val();        
    let val5 = $('input[name="to_hide_detail"]:checked').val();
    let val6 = $('input[name="relation_inclusive"]:checked').val();
    let val7 = $('input[name="sum_insured_by"]:checked').val();
    let val8 = $('input[name="is_optional"]:checked').val();
    let val9 = $('#premium_type').val();
    let val10 = $('#premium_by').val();
   
    this.FeatureTableArray.push(
      {
        
        "feature_desc": val1,
        "realations": val2,
        "sum_insured": val3,
        "sum_insured_type": val4,
        "to_hide_detail": val5,
        "relation_inclusive": val6,
        "sum_insured_by": val7,
        "is_optional": val8,
        "premium_type": val9,
        "premium_by": val10,
        
      }
    )
  
    // show this array in table of added benefit
    console.log(this.FeatureTableArray);
    $('#feature_desc').val('');
    $('#feature_desc_val').val('');
    $('#realations').val('');
    $('#sum_insured_features').val('');
    $('#sum_insured_type').val('0');
    $('#premium_by').val('');
    $('#premium_type').val('0');

  }  

  saveWhatsNotCover(){
    let val1:any = $('#benefit_name_not_cover').val();
    let val2:any = $('#benefit_description_not_cover').val();

    this.NotCoverTableArray.push(
      {
        
        "benefit_name": val1,
        "benefit_description": val2,
        
      }
    )

    console.log(this.NotCoverTableArray);

    $('#benefit_name_not_cover').val('');
    $('#benefit_description_not_cover').val('');


  }  

  removeBenefitTableArray(i:any){
    this.BenefitTableArray.splice(i, 1);
    console.log(this.BenefitTableArray);
  }

  removeNotCoverTableArray(i:any){
    this.NotCoverTableArray.splice(i, 1);
    console.log(this.NotCoverTableArray);
  }

  removeFeatureTableArray(i:any){
    this.FeatureTableArray.splice(i, 1);
    console.log(this.FeatureTableArray);
  }

  checkset_allowed_increase(){
    let val = $('#set_allowed_increase').is(':checked');    
    if(val){
      $('.set_allowed_increaseDiv').css('display', 'block');
    }else{
      $('.set_allowed_increaseDiv').css('display', 'none');
    }
  }

  checkset_allowed_decrease(){
    let val = $('#set_allowed_decrease').is(':checked');    
    if(val){
      $('.set_allowed_decreaseDiv').css('display', 'block');
    }else{
      $('.set_allowed_decreaseDiv').css('display', 'none');
    }
  }

  checkwill_there_be_any_premium(){
    let val = $('#will_there_be_any_premium').is(':checked');    
    if(val){
      $('.will_there_be_any_premiumDiv').css('display', 'block');
    }else{
      $('.will_there_be_any_premiumDiv').css('display', 'none');
    }
  }

  showPopupAudit(){
    this.PopupAuditDetails = 'block';
  }

  /************************** EDIT **********************/
  showPopup_edit(){

    this.PopupBenefits_edit = "block";

    let val1:any = $('#benefit_name_edit').val();
    $('#benefit_name_edit_val_edit').val(val1);

    let val2:any = $('#benefit_description_edit').val();
    $('#benefit_description_edit_val_edit').val(val2);

  }

  /***** Add Field *****/
  addBenefitData_edit(){

    let html = `
    <div id="this_Data_benefit_row_edit_`+this.cnt_BenefitData_edit+`" class="row mt-3">
      
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="feature_name_edit_`+this.cnt_BenefitData_edit+`">Feature Name </label>
          <input type="text" class="form-control" formControlName="feature_name_edit_`+this.cnt_BenefitData_edit+`" id="feature_name_edit_`+this.cnt_BenefitData_edit+`" placeholder="Feature Name" />
      </div>

      <div class="col-sm-6 form-group mt-3">
          <label class="bs-form-label" for="feature_description_edit_`+this.cnt_BenefitData_edit+`">Feature Description </label>
          <input type="text" class="form-control" formControlName="feature_description_edit_`+this.cnt_BenefitData_edit+`" id="feature_description_edit_`+this.cnt_BenefitData_edit+`" placeholder="Enter Content Here..." />
      </div>        

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="add_on_SI_edit_`+this.cnt_BenefitData_edit+`">Add On SI</label>
          <input type="text" class="form-control" formControlName="add_on_SI_edit_`+this.cnt_BenefitData_edit+`" id="add_on_SI_edit_`+this.cnt_BenefitData_edit+`" placeholder="Add On SI" value="0" />
      </div>     

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="cover_by_edit_edit_`+this.cnt_BenefitData_edit+`">Cover By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_edit_edit_`+this.cnt_BenefitData_edit+`" value="by_value" name="cover_by_edit_edit_`+this.cnt_BenefitData_edit+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_edit_edit_`+this.cnt_BenefitData_edit+`" value="by_SI" name="cover_by_edit_edit_`+this.cnt_BenefitData_edit+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By SI %</label>
                  </div>
              </div>
          </div>
      </div>
        
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="cover_type_edit_`+this.cnt_BenefitData_edit+`">Cover Type</label>
          <select class="form-select" formControlName="cover_type_edit_`+this.cnt_allowed+`" id="cover_type_edit_`+this.cnt_allowed+`">
                <option value="" selected>Select Cover Type</option>
                <option value="Cover Type 1">Cover Type 1</option>
          </select>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="sum_insured_cap_edit_edit_`+this.cnt_BenefitData_edit+`">Sum Insured Cap</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_edit_edit_`+this.cnt_BenefitData_edit+`" value="Yes" name="sum_insured_cap_edit_edit_`+this.cnt_BenefitData_edit+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_edit_edit_`+this.cnt_BenefitData_edit+`" value="No" name="sum_insured_cap_edit_edit_`+this.cnt_BenefitData_edit+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="premium_by_edit_`+this.cnt_BenefitData_edit+`">Premium By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_edit_`+this.cnt_BenefitData_edit+`" value="by_value" name="premium_by_edit_`+this.cnt_BenefitData_edit+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_edit_`+this.cnt_BenefitData_edit+`" value="by_premium" name="premium_by_edit_`+this.cnt_BenefitData_edit+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Premium %</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_value_edit_`+this.cnt_BenefitData_edit+`">Premium Value</label>
          <input type="text" class="form-control" formControlName="premium_value_edit_`+this.cnt_BenefitData_edit+`" id="premium_value_edit_`+this.cnt_BenefitData_edit+`" placeholder="Premium Value" value="0" />
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_type_edit_`+this.cnt_BenefitData_edit+`">Premium Type</label>
          <select class="form-select" formControlName="premium_type_edit_`+this.cnt_allowed+`" id="premium_type_edit_`+this.cnt_allowed+`">
                <option value="" selected>Select Premium Type</option>
                <option value="Premium Type 1">Premium Type 1</option>
          </select>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="visible_from_SI_value_edit_`+this.cnt_BenefitData_edit+`">Visible From SI Value</label>
          <input type="text" class="form-control" formControlName="visible_from_SI_value_edit_`+this.cnt_BenefitData_edit+`" id="visible_from_SI_value_edit_`+this.cnt_BenefitData_edit+`" placeholder="Visible From SI Value" value="0" />
      </div>
      
      <div class="col-sm-2 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="Data_benefit_row_edit_`+this.cnt_BenefitData_edit+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
      <div class="col-sm-12"><hr></div>
    </div>      
    `;

    $('.newbenefitData_edit').append(html);

    // for(var i=0; i<this.Membertype.length; i++){
    //   var val = '#' + 'cover_type_edit_'+ this.cnt;
    //   $(val)
    //     .append($("<option></option>")
    //       .attr("value", this.Membertype[i]['id'])
    //       .text(this.Membertype[i]['name']));
    // }

    // this.familyInfo_edit.addControl("feature_name_edit_"+this.cnt_BenefitData_edit, new FormControl(''));
    // this.familyInfo_edit.addControl("feature_description_edit_"+this.cnt_BenefitData_edit, new FormControl(''));
    // this.familyInfo_edit.addControl("add_on_SI_edit_"+this.cnt_BenefitData_edit, new FormControl(''));

    // this.BenefitDataArr[this.cnt_BenefitData_edit] = {member_type: "", no_age: "", min_age: "", max_age: ""};

    let flag = this;
    $("#Data_benefit_row_edit_"+this.cnt_BenefitData_edit).on('click',function(e) {
      flag.removeRow_Benefit_edit(e);
    });

    $("#add_on_SI_edit_"+this.cnt_BenefitData_edit).on('keyup',function(e) {
      flag.isnumberBenefit_benefit_edit(e);
    });

    $("#premium_value_edit_"+this.cnt_BenefitData_edit).on('keyup',function(e) {
      flag.isnumberBenefit_benefit_edit(e);
    });

    $("#visible_from_SI_value_edit_"+this.cnt_BenefitData_edit).on('keyup',function(e) {
      flag.isnumberBenefit_benefit_edit(e);
    });

    this.cnt_BenefitData_edit++;

  }

  removeRow_Benefit_edit(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_Data_benefit_row_edit_' + id;
    $(idstr).remove();

  }

  isnumberBenefit_benefit_edit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  showPopupFeatures_edit(){

    this.PopupFeatures_edit = "block";

    let val1:any = $('#feature_desc_edit').val();
    $('#feature_desc_edit_val').val(val1);

  }

  //On click save button in benefit popup
  savebenefit_edit(){

    this.PopupBenefits_edit="none";

    let val1:any = $('#benefit_name_edit_val_edit').val();
    let val2:any = $('#benefit_description_edit_val_edit').val();
    let val3 = $('#benefit_visible_from_SI_edit').val();        
    let val4 = $('input[name="benefit_mandatory_edit"]:checked').val();
    let val5 = $('input[name="prorata_calculation_applicable_edit"]:checked').val();

    let arr:any = {};
    for(var i = 1; i < this.cnt_BenefitData_edit; i++){     
      arr[i] = {
        feature_name: $('#feature_name_edit_'+i).val(), 
        feature_description:$('#feature_description_edit_'+i).val(),
        add_on_SI: $('#add_on_SI_edit_'+i).val(), 
        cover_by: $('input[name="cover_by_edit_edit_'+i+'"]:checked').val(), 
        cover_type: $('#cover_type_edit_'+i).val(), 
        sum_insured_cap: $('input[name="sum_insured_cap_edit_edit_'+i+'"]:checked').val(),       
        premium_by: $('input[name="premium_by_edit_'+i+'"]:checked').val(),
        premium_value: $('#premium_value_edit_'+i).val(), 
        premium_type: $('#premium_type_edit_'+i).val(),           
        visible_from_SI_value: $('#visible_from_SI_value_edit_'+i).val(),
      };
    }

    this.BenefitTableArray_edit.push(
      {
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "benefit_mandatory": val4,
        "prorata_calculation_applicable": val5,
        "tableArray": arr,
      }
    )

  

    // show this array in table of added benefit
    console.log(this.BenefitTableArray_edit);        
    $('.newbenefitData_edit').remove();
    this.cnt_BenefitData_edit = 1;
    $('#benefit_name_edit').val('');
    $('#benefit_description_edit').val('');
    $('#benefit_visible_from_SI_edit').val('');
    $('#benefit_name_edit_val_edit').val('');
    $('#benefit_description_edit_val_edit').val('');
  }  

  savefeature_edit(){

    this.PopupFeatures_edit="none";

    let val1:any = $('#feature_desc_edit').val();
    let val2:any = $('#realations_edit').val();
    let val3 = $('#sum_insured_edit_features_edit').val();        
    let val4 = $('#sum_insured_edit_type_edit').val();        
    let val5 = $('input[name="to_hide_detail_edit"]:checked').val();
    let val6 = $('input[name="relation_inclusive_edit"]:checked').val();
    let val7 = $('input[name="sum_insured_edit_by_edit"]:checked').val();
    let val8 = $('input[name="is_optional_edit"]:checked').val();
    let val9 = $('#premium_type_edit').val();
    let val10 = $('#premium_by_edit').val();
   
    this.FeatureTableArray_edit.push(
      {
        
        "feature_desc": val1,
        "realations": val2,
        "sum_insured": val3,
        "sum_insured_type": val4,
        "to_hide_detail": val5,
        "relation_inclusive": val6,
        "sum_insured_by": val7,
        "is_optional": val8,
        "premium_type": val9,
        "premium_by": val10,
        
      }
    )
  
    // show this array in table of added benefit
    console.log(this.FeatureTableArray_edit);
    $('#feature_desc_edit').val('');
    $('#feature_desc_val_edit').val('');
    $('#realations_edit').val('');
    $('#sum_insured_features_edit').val('');
    $('#sum_insured_type_edit').val('0');
    $('#premium_by_edit').val('');
    $('#premium_type_edit').val('0');

  }  

  saveWhatsNotCover_edit(){
    let val1:any = $('#benefit_name_not_cover_edit').val();
    let val2:any = $('#benefit_description_not_cover_edit').val();

    this.NotCoverTableArray_edit.push(
      {
        
        "benefit_name": val1,
        "benefit_description": val2,
        
      }
    )

    console.log(this.NotCoverTableArray_edit);

    $('#benefit_name_not_cover_edit').val('');
    $('#benefit_description_not_cover_edit').val('');


  }  

  removeBenefitTableArray_edit(i:any){
    this.BenefitTableArray_edit.splice(i, 1);
    console.log(this.BenefitTableArray_edit);
  }

  removeNotCoverTableArray_edit(i:any){
    this.NotCoverTableArray_edit.splice(i, 1);
    console.log(this.NotCoverTableArray_edit);
  }

  removeFeatureTableArray_edit(i:any){
    this.FeatureTableArray_edit.splice(i, 1);
    console.log(this.FeatureTableArray_edit);
  }

  checkset_allowed_increase_edit(){
    let val = $('#set_allowed_increase_edit').is(':checked');    
    if(val){
      $('.set_allowed_increase_editDiv_edit').css('display', 'block');
    }else{
      $('.set_allowed_increase_editDiv_edit').css('display', 'none');
    }
  }

  checkset_allowed_decrease_edit(){
    let val = $('#set_allowed_decrease_edit').is(':checked');    
    if(val){
      $('.set_allowed_decrease_editDiv_edit').css('display', 'block');
    }else{
      $('.set_allowed_decrease_editDiv_edit').css('display', 'none');
    }
  }

  checkwill_there_be_any_premium_edit(){
    let val = $('#will_there_be_any_premium_edit').is(':checked');    
    if(val){
      $('.will_there_be_any_premium_editDiv_edit').css('display', 'block');
    }else{
      $('.will_there_be_any_premium_editDiv_edit').css('display', 'none');
    }
  }

}
