<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
    <div class="content-wrapper pt-3">
        
      <h3 class="bs-title">Welcome<span class="spanbs-title" style="color: #053BBD"> {{username}}</span></h3>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
      </p>
      <!-- <hr class="mt-0"> -->

      <div class="row mt-4">
                         
        <div class="col-sm-3" *ngFor="let data of InfoArray; let i = index;">
          <div class="pt-2 cardcustom cardbg" [ngClass]="{'mt-3': i > 3}" (click)="navigateto(data.id)" style="cursor: pointer;">
            <img src="../../../assets/img/employee/{{data.image_url}}" class="icon" />
            <div class="text-center pt-4">
              <b>{{data.title}}</b>
              <img src="../../../assets/img/employee/arrow_circle_right_FILL0_wght300_GRAD0_opsz48 2.png" style="cursor: pointer;" />              
            </div>
          </div>
        </div>

      </div>


      <div class="row mt-4" style="background-color: #fff; padding: 10px; border-radius: 8px; margin: 0px; margin-bottom: 10px;">

        <div class="col-sm-9">
          <img src="../../../assets/img/employee/2742 1 (1).png" />           
        </div>

        <div class="col-sm-3 p-5">        
          <p class="mt-5">
            In publishing and graphic design, Lorem ipsum is a placeholder text commonly usaed to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available
          </p>
          <button class="btn btn-warning" style="background-color: #FFA12D; color: #fff; width: 180px; border: none; border-radius: 50px; height: 50px">
            <span style="font-size: 15px;
            position: relative;
            top: 9px;">More Details </span>
            <img src="../../../assets/img/employee/arrow_circle_right_FILL0_wght300_GRAD0_opsz48 2.png" style="float: right; width: 35px; cursor: pointer;" />            
          </button>
          
        </div>

      </div>
      
    </div>
    <app-footer></app-footer>
  </div>

</div>