<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Claims TAT Report</h3>
            <hr class="mb-2">

            <div class="row mt-4">

                <div class="col-sm-4 mb-2">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_name">Client Name</label>
                        <select class="form-select" id="client_name">
                            <option value="">Select Client</option>
                            <option value="{{clientname}}" selected>{{clientname}}</option>
                            <!-- <option *ngFor="let SumAssured of InfoSumAssured" [value]="SumAssured.flat_suminsured">{{SumAssured.flat_suminsured}}</option> -->
                        </select>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="dimensions">Dimensions</label>
                        <select class="form-select" id="dimensions">
                            <option value="0" selected>All</option>
                            <option *ngFor="let res2 of clientwisepolicy" [value]="res2.policyid">{{res2.policy_name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="type">Type</label>
                        <select class="form-select" id="type" (change)="setlabel($event)">
                            <option value="">Type</option>
                            <option value="2">Documents Received to Settlement</option>
                            <option value="1">Claims Intimated to Settlement</option>
                            <!-- <option *ngFor="let SumAssured of InfoSumAssured" [value]="SumAssured.flat_suminsured">{{SumAssured.flat_suminsured}}</option> -->
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 my-3">
                    <div class="cardcustom">
                        <h3 class="bs-title">TAT for {{label_text}}</h3>
                        <hr class="mb-2">
                        <div [chart]="bar" class="chartdiv" style="height: 340px;"></div>
                    </div>
                </div>

                <div class="col-sm-6 my-3">
                    <div class="cardcustom">
                        <h3 class="bs-title">Claims Summary</h3>
                        <hr class="mb-2">
                        <!-- Listing -->
                        <div style="max-height: 340px; overflow-y: auto;">
                            <table class="table table-sm row-border">
                                <thead style="position: sticky; top: 0; background-color: #fff;">
                                    <tr>
                                        <th class="w25">Month of Claim Settlement</th>
                                        <th class="w25">Average of No. of Days</th>
                                        <th class="w25">Max of No. of Days</th>
                                        <th class="w25">Min of No. of Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container  *ngFor="let month of getSortedMonths(); let i = index" >
                                    <tr *ngIf="InfoPolicyMonth && InfoPolicyMonth[month]">
                                        <td class="w25">{{month}}</td>
                                        <td class="w25">{{InfoPolicyMonth[month]?.average_days}}</td>
                                        <td class="w25">{{InfoPolicyMonth[month]?.max_days}}</td>
                                        <td class="w25">{{InfoPolicyMonth[month]?.min_days}}</td>
                                    </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>

                <div class="col-sm-12 my-3">
                    <div class="cardcustom">
                        <h3 class="bs-title">{{label_text}} List</h3>
                        <hr class="mb-2">

                        <!-- Listing -->
                        <table class="table table-sm row-border wrap" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>Claim Intimation Number</th>
                                    <th>Date of {{label_text}}</th>
                                    <th>Date Claim Settled</th>
                                    <th>No. of Days TAT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let group of InfoPolicy; let i = index">
                                    <td>{{group['claim_number_auto']}}</td>
                                    <td *ngIf="group['date_claim_intimated'] !== '' && group['date_claim_intimated'] !== null">
                                        {{ group['date_claim_intimated'] }}
                                    </td>
                                    <td *ngIf="group['date_claim_intimated'] === '' || group['date_claim_intimated'] === null">
                                        {{ group['date_document_recived'] }}
                                    </td>
                                    <td>{{group['date_claim_settled']}}</td>
                                    <td>{{group['no_of_days_tat']}}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>