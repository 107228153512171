<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <!-- <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader> -->


                <h3 class="bs-title" *ngIf="writeaccess==1"><span class="spanbs-title">New Relationship Request</span>
                    <!-- <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button> -->
                </h3>

                <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                    <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                </div>
                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                    <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                </div>

            <hr class="mb-2" *ngIf="writeaccess==1">

             <!-- Add New Relation Request -->
             <form [formGroup]="AddInfo" *ngIf="writeaccess==1">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="new_relationships">New Relationship Name </label>
                            <input type="text" class="form-control" formControlName="new_relationships" id="new_relationships" placeholder="New Relationship Name"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.new_relationships.errors}"
                                required />
                        </div>
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.new_relationships.errors">
                        <p class="error"
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.new_relationships.errors.required">
                            New Relationship Name required
                        </p>
                    </ng-container>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="relationshipType">Relationship
                                Type</label>
                            <select class="form-select"
                                formControlName="relationshipType"
                                id="relationshipType" (change)="calPremium()" (change)="calSI()" (change)="onRelationTypeChange($event)"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.relationshipType.errors}"
                                required>
                                <option value="" selected>Select Relationship
                                    Type</option>
                                <option *ngFor="let res2 of RelationType"
                                    [value]="res2.id">{{res2.relation_title}}</option>
                            </select>
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.relationshipType.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.relationshipType.errors.required">
                                    Relationship Type is required
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="coverStartDate">Cover Start Date/Policy
                                Start Date</label>
                            <input type="date" class="form-control"
                                formControlName="coverStartDate"
                                id="coverStartDate" (change)="calculateCoverageDays()" (change)="calPremium()"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.coverStartDate.errors}"
                                required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.coverStartDate.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.coverStartDate.errors.required">
                                    Cover Start Date is required
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="coverEndDate">Cover End Date/Policy End
                                Date</label>
                            <input type="date" class="form-control"
                                formControlName="coverEndDate" id="coverEndDate" (change)="calculateCoverageDays()" readonly
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.coverEndDate.errors}"
                                required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.coverEndDate.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.coverEndDate.errors.required">
                                    Cover End Date is required
                                </p>
                            </ng-container>
                        </div>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="coverageDays">Coverage Days</label>
                            <input type="text" class="form-control"
                                formControlName="coverageDays" id="coverageDays" readonly
                                placeholder="Coverage Days"
                                (keypress)="isNumber($event)" />
                        </div>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="individualCoverAmount">Individual Cover
                                Amount(Lacs)</label>
                            <input type="text" class="form-control"
                                formControlName="individualCoverAmount"
                                id="individualCoverAmount" (keyup)="calSI()" (keydown)="calSI()"
                                placeholder="Individual Cover Amount (Lacs)"
                                (keypress)="isNumber($event)"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors}"
                                required />
                        </div>
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors.required">
                                Individual Cover Amount is required
                            </p>
                        </ng-container>
                    </div>
                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="noOfMembers">Count Of Members</label>
                            <input type="text" class="form-control"
                                formControlName="noOfMembers" id="noOfMembers" (keyup)="calSI()" (keydown)="calSI()"
                                placeholder="Count Of Members"
                                (keypress)="isNumber($event)"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.noOfMembers.errors}"
                                required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.noOfMembers.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.noOfMembers.errors.required">
                                    Count Of Members is required
                                </p>
                            </ng-container>
                        </div>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="totalSI">Total Sum Insured</label>
                            <input type="text" class="form-control"
                                formControlName="totalSI" id="totalSI" readonly
                                placeholder="Total Sum Insured" />
                        </div>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="rate_per_milli">Per Mili Rate</label>
                            <input type="text" class="form-control"
                                formControlName="rate_per_milli" id="rate_per_milli" readonly
                                placeholder="Per Mili Rate" />
                        </div>
                    </div>
                    

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="totalPremium">Net Premium</label>
                            <input type="text" class="form-control"
                                formControlName="totalPremium" id="totalPremium"
                                placeholder="Net Premium" readonly
                                (keypress)="isNumber($event)" />
                        </div>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="prorata_premium">Prorata Premium</label>
                            <input type="text" class="form-control"
                                formControlName="prorata_premium" id="prorata_premium" readonly
                                placeholder="Prorata Premium" />
                        </div>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="gst">GST %</label>
                            <input type="text" class="form-control"
                                formControlName="gst" id="gst" (keyup)="calPremium()" (keydown)="calPremium()" [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.gst.errors}"
                                required
                                placeholder="GST %" />
                        </div>
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.gst.errors">
                        <p class="error"
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.gst.errors.required">
                       GST % is required
                        </p>
                    </ng-container>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="gst_amount">GST Amount</label>
                            <input type="text" class="form-control"
                                formControlName="gst_amount" id="gst_amount"
                                placeholder="GST Amount" readonly />
                        </div>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <div class="form-group">
                            <label for="endo_gross_premium">Gross Premium</label>
                            <input type="text" class="form-control"
                                formControlName="endo_gross_premium" id="endo_gross_premium"
                                placeholder="Endorsement Gross Premium" readonly />
                        </div>
                    </div>
                    
                </div>
                <!-- Add a submit button or additional form elements if needed -->
                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-primary mt-3"
                        (click)="onSubmitAdd()" role="button">Save</button>
                </div>
            </form>

            <hr>

            <h3 class="bs-title" *ngIf="addflag == 0">Relationship Request Listing
                <!-- <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button> -->
            </h3>


             <!-- Filters -->
             <div class="row"  *ngIf="addflag == 0">
                <div class="col-sm-4 mt-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_name">Client Name</label>
                        <select class="form-select" id="client_name" (change)="getInfo($event, 'custom')">
                            <option value="" selected>Select Client Name</option>
                            <option *ngFor="let res of InfoArraydropdown" [value]="res.employer_id">{{ res.employer_name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- Listing -->
            <div class="table-responsive mt-3" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Relationships</th>
                        <th>Relationship Type</th>
                        <th>Cover/Policy Start Date</th>
                        <th>Cover/Policy End Date</th>
                        <th>Coverage Days</th>
                        <th>Individual Cover Amount (Lacs)</th>
                        <th>Count of Members </th>
                        <th>Total Sum Insured </th>
                        <th>Per Mili Rate </th>
                        <th>Net Premium</th>
                        <th>Prorata Premium</th>
                        <th>GST %</th>
                        <th>Gross Premium</th>
                        <th>Status </th>
                        <!-- <th style="text-align: center;">Action</th> -->
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['new_relationship_title']}}</td>
                        <td>{{group['relation_title']}}</td>
                        <td>{{group['cover_start_date']}}</td>
                        <td>{{group['cover_end_date']}}</td>
                        <td>{{group['coverage_days']}}</td>
                        <td>{{group['individual_cover_amount']}}</td>
                        <td>{{group['member_count']}}</td>
                        <td>{{group['total_sum_insured']}}</td>
                        <td>{{group['rate_per_milli']}}</td>
                        <td>{{group['total_premium']}}</td>
                        <td>{{group['prorata_premium']}}</td>
                        <td>{{group['gst_per']}}</td>
                        <td>{{group['total_premium_with_gst']}}</td>
                        <td *ngIf="group['is_request_status']==1"><span _ngcontent-igh-c148="" class="btn btn-inverse-success btn-fw">Accepted</span></td>
                        <td *ngIf="group['is_request_status']==2"><span _ngcontent-igh-c148="" class="btn btn-inverse-danger btn-fw">Declined</span></td>
                        <td *ngIf="group['is_request_status']==0"><span _ngcontent-igh-c148="" class="btn btn-inverse-danger btn-fw">Pending</span></td>
                        <!-- <td style="width: 15%" align="center"> -->
                            <!-- <img  *ngIf="group['is_request_status'] === 0 && group['is_request_status'] !== 1 && group['is_request_status'] !== 2" src="../../../assets/img/selectproduct/deactive.png" (click)="fn_decline_request(group['id'])" id="decline"  class="ml-4" role="button" title="Decline" /> -->
                            <!-- <img  *ngIf="group['is_request_status'] != 1 && group['is_request_status'] != 2" src="../../../assets/img/selectproduct/active.png" (click)="fn_accept_request(group['id'])" id="accept" class="ml-4" role="button" title="Accept" /> -->
                        <!-- </td> -->
                    </tr>
                    </tbody>
                </table>
            </div>


             <!--Accept Form -->
             <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleAccept}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Accept Relationship Request</h4>
                        </div>
                            <div class="modal-body">
                                Are you sure you want to Accept?
                            </div>
                            <input type="hidden" class="form-control" formControlName="accept_id" id="accept_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="on_submitAccept()" role="button">Accept</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                    </div>
                </div>
            </div>

            <!-- Decline Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDecline}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Decline Relationship Request</h4>
                        </div>
                            <div class="modal-body">
                                Are you sure you want to Decline?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="on_submitDecline()" role="button">Decline</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>
