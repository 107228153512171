<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Policy Details
                <button type="button" class="btn btn-primary" (click)="showBenefitpopup()" role="button" style="float:right; margin-top: -5px; margin-right: 10px; padding: 5px 25px;">
                    <img src="../../../../../../assets/img/employee/view.png" />
                     Benefit Summary
                </button>
                <button type="button" class="btn btn-primary" (click)="goto()" role="button" style="float:right; margin-top: -5px; margin-right: 10px;">Back</button>
            </h3>
            <hr>           

            <div class="accordion" id="myAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne">1. Basic Policy Details</button>									
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                        <div class="card-body">                                                                
                            <form [formGroup]="basicInfo">
                                <!-- <h3 class="bs-title">Basic Policy Details </h3> -->
                                <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                    <strong>Basic Policy Details created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>                                                                
                                <!-- <hr> -->

                                <div class="row">

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_name">Employer Name</label>
                                            <input type="text" class="form-control" formControlName="employer_name" id="employer_name" placeholder="Employer Name"/>                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_dob">Date of Birth</label>
                                            <input type="date" class="form-control" formControlName="employer_dob" id="employer_dob" placeholder="dd/mm/yyyy" />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_joining">Date of Joining</label>
                                            <input type="date" class="form-control" formControlName="employer_joining" id="employer_joining" placeholder="dd/mm/yyyy" />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_email">Email</label>
                                            <input type="email" class="form-control" formControlName="employer_email" id="employer_email" placeholder="Email" />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_contact_number">Contact No. *</label>
                                            <input type="email" class="form-control" formControlName="employer_contact_number" id="employer_contact_number" placeholder="Contact No" maxlength="10" (keypress)="isNumber($event)"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.employer_contact_number.errors}"
                                            required />  
                                            <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employer_contact_number.errors">
                                                <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employer_contact_number.errors.required">
                                                    Contact No. is required
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_alternate_email">Alternate Email</label>
                                            <input type="email" class="form-control" formControlName="employer_alternate_email" id="employer_alternate_email" placeholder="Alternate Email" />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_emergency_person">Emergency Contact Person</label>
                                            <input type="text" class="form-control" formControlName="employer_emergency_person" id="employer_emergency_person" placeholder="Emergency Contact Person"/>                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_emergency_contact_number">Emergency Contact No.</label>
                                            <input type="email" class="form-control" formControlName="employer_emergency_contact_number" id="employer_emergency_contact_number" placeholder="Emergency Contact No." maxlength="10" (keypress)="isNumber($event)" />                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 mt-3">
                                        <div class="form-group">
                                            <button (click)="next(1)" class="btn btn-primary" style="float: right; display: flow-root;">Save & next</button>
                                        </div>
                                    </div>

                                </div>                                                             

                            </form>
                            
                            <!-- <form class="mt-5">
                                <img src="../../../../../assets/img/employee/step1_bg.png" class="step1bg" />
                            </form> -->
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">2. Insured Member</button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                        <div class="card-body">
                            <form [formGroup]="Insuredinfo">
                                <!-- <h3 class="bs-title">Insured Member</h3>                                 -->
                                <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                    <strong>Insured Member created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <!-- <hr> -->

                                <div class="row"> 
                                    
                                    <div class="col-sm-8 row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="relation_with_employee">Relation With Employee *</label>
                                                <select class="form-select" formControlName="relation_with_employee" id="relation_with_employee"
                                                    [ngClass]="{'control-red': InsuredinfoSubmitted && getInsuredinformationFormControls.relation_with_employee.errors}"
                                                    required>
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let covertype of Relation" [value]="covertype.id">{{ covertype.name }}</option>
                                                </select>
                                                <ng-container *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.relation_with_employee.errors">
                                                    <p class="error"
                                                        *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.relation_with_employee.errors.required">
                                                        Relation With Employee is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="gender">Gender *</label>
                                                <select class="form-select" formControlName="gender" id="gender"
                                                    [ngClass]="{'control-red': InsuredinfoSubmitted && getInsuredinformationFormControls.gender.errors}"
                                                    required>
                                                    <option value="" selected>Select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <ng-container *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.gender.errors">
                                                    <p class="error"
                                                        *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.gender.errors.required">
                                                        Gender is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>
                                                  
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="first_name">First Name *</label>
                                                <input type="text" class="form-control" formControlName="first_name" id="first_name" placeholder="First Name"
                                                    [ngClass]="{'control-red': InsuredinfoSubmitted && getInsuredinformationFormControls.first_name.errors}"
                                                    required />                                            
                                                <ng-container *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.first_name.errors">
                                                    <p class="error"
                                                        *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.first_name.errors.required">
                                                        First Name is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="last_name">Last Name</label>
                                                <input type="text" class="form-control" formControlName="last_name" id="last_name" placeholder="Last Name"/>                                            
                                            </div>
                                        </div>                                    
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="insured_contact_number">Contact No.</label>
                                                <input type="email" class="form-control" formControlName="insured_contact_number" id="insured_contact_number" placeholder="Contact No" maxlength="10" (keypress)="isNumber($event)" />                                            
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="insured_email">Email</label>
                                                <input type="email" class="form-control" formControlName="insured_email" id="insured_email" placeholder="Email" />                                            
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="insured_dob">Date of Birth</label>
                                                <input type="date" class="form-control" formControlName="insured_dob" id="insured_dob" placeholder="dd/mm/yyyy"
                                                    [ngClass]="{'control-red': InsuredinfoSubmitted && getInsuredinformationFormControls.insured_dob.errors}"
                                                    required />                                            
                                                <ng-container *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.insured_dob.errors">
                                                    <p class="error"
                                                        *ngIf="InsuredinfoSubmitted && getInsuredinformationFormControls.insured_dob.errors.required">
                                                        Date of Birth is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-4" style="border: dashed 2px #3c0111;">
                                        <img src="../../../../../../assets/img/employee/members.png" style="margin: auto; display: block; padding: 10px;" alt="img" />
                                        <p style="color: #FF0000; text-align: center;" (click)="showBenefitpopup()"><u>View Details</u></p>
                                    </div>
                                    
                                    <!-- Add Member Div -->
                                    
                                </div>

                                <div class="row mt-3">
                                    <div class="col-sm-12">                                        
                                        <div>
                                            <button (click)="next(2);" class="btn btn-primary" style="float: right;">Save & next</button>
                                            <button (click)="previous()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Previous</button>                                
                                        </div>
                                    </div>
                                </div>

                            </form>                                                        
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">3. Nominee Into Policy</button>                     
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                        <div class="card-body">
                            <form [formGroup]="Nomineeinfo">                                
                                <!-- <h3 class="bs-title">Nominee Into Policy</h3> -->
                                <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                    <strong>Nominee Into Policy created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <!-- <hr> -->

                                <div class="row">

                                    <div class="col-sm-8 row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="nominee_relation_with_employee">Relation With Employee *</label>
                                                <select class="form-select" formControlName="nominee_relation_with_employee" id="nominee_relation_with_employee"
                                                [ngClass]="{'control-red': NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_relation_with_employee.errors}"
                                                required>
                                                    <option value="" selected>Select Relation With Employee</option>
                                                    <option *ngFor="let covertype of Relation" [value]="covertype.id">{{ covertype.name }}</option>
                                                </select>
                                                <ng-container *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_relation_with_employee.errors">
                                                    <p class="error"
                                                        *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_relation_with_employee.errors.required">
                                                        Relation With Employee is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="col-sm-6"></div>

                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="nominee_first_name">First Name *</label>
                                                <input type="text" class="form-control" formControlName="nominee_first_name" id="nominee_first_name" placeholder="First Name"
                                                [ngClass]="{'control-red': NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_first_name.errors}"
                                                required />   
                                                <ng-container
                                                    *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_first_name.errors">
                                                    <p class="error"
                                                    *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_first_name.errors.required">
                                                    First Name is required
                                                    </p>
                                                </ng-container>                                         
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="nominee_last_name">Last Name</label>
                                                <input type="text" class="form-control" formControlName="nominee_last_name" id="nominee_last_name" placeholder="Last Name"/>                                            
                                            </div>
                                        </div>   
                                        
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="nominee_dob">Date of Birth *</label>
                                                <input type="date" class="form-control" formControlName="nominee_dob" id="nominee_dob" placeholder="dd/mm/yyyy"
                                                [ngClass]="{'control-red': NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_dob.errors}"
                                                required />                                            
                                                <ng-container
                                                    *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_dob.errors">
                                                    <p class="error"
                                                    *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_dob.errors.required">
                                                    Date of Birth is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="nominee_share">Share (%) *</label>
                                                <input type="email" class="form-control" formControlName="nominee_share" id="nominee_share" placeholder="Share (%)" maxlength="3" (keypress)="isNumber($event);" (keyup)="checkshare()" (keydown)="checkshare()"
                                                [ngClass]="{'control-red': NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_share.errors}"
                                                required />                                            
                                                <ng-container
                                                    *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_share.errors">
                                                    <p class="error"
                                                    *ngIf="NomineeinfoSubmitted && getNomineeinformationFormControls.nominee_share.errors.required">
                                                    Share is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-sm-4" style="border: dashed 2px #3c0111;">
                                        <img src="../../../../../../assets/img/employee/members.png" style="margin: auto; display: block; padding: 10px;" alt="img" />
                                        <p style="color: #FF0000; text-align: center;"><u>View Details</u></p>
                                    </div>

                                    <!-- Add More Div -->                                    
                                    
                                </div>                 
                                
                                <div class="row mt-3">
                                    <div class="col-sm-12">                                        
                                        <div>
                                            <button (click)="next(3);" class="btn btn-primary" style="float: right;">Save & next</button>
                                            <button (click)="previous()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Previous</button>                                
                                        </div>
                                    </div>
                                </div>

                            </form>                                                         
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">4. Enrollment Confirmation</button>                     
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                        <div class="card-body">
                            <form [formGroup]="enrollmentinfo">
                                <!-- <h3 class="bs-title">Enrollment Confirmation</h3> -->
                                <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                    <strong>Enrollment Confirmation created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <!-- <hr> -->

                                <div class="row">

                                    <div class="col-sm-6">
                                        
                                        <div>
                                            <label class="bs-form-label">Please verify Your Data for the following:</label>
                                        </div>

                                        <ul style="list-style: none;">
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Typographical errors</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Date of birth & Age Information</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Gender Information</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Relationship with Employee</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Selection in Core Benefits</b></li>
                                        </ul>                                      

                                    </div>                                        

                                    <div class="col-sm-6">
                                        <!-- <img src="../../../../../assets/img/employee/step4_bg.png" style="width: 250px; float: right; margin-bottom: 30px;" /> -->
                                    </div>

                                    <div class="col-sm-12">
                                        <div>
                                            <label class="bs-form-label">
                                                You cannot modify the enrollment information post enrollment period after 
                                                which the data submitted by you will not be editable andconsidered as final.
                                            </label>
                                        </div>
                                        <div class="mt-3">
                                            <label class="bs-form-label"><b>I confirm that the information provided above by me is correct</b></label>
                                            <div class="agreeradio my-2">
                                                <input type="checkbox" name="agree" formControlName="agree" (click)="checkagree()" /> 
                                                <span style="margin-left: 3px;"><label class="bs-form-label"><b> I Agree</b></label></span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>                                    

                                <div class="row mt-3">
                                    <div class="col-sm-12">                                        
                                        <div>
                                            <button (click)="next(4);" class="btn btn-primary finishbtn" style="float: right;">Submit</button>
                                            <button (click)="previous()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Previous</button>                                
                                        </div>
                                    </div>
                                </div>

                            </form>                           
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Standard Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Standardmsgtoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Oops!!</h4>
                        </div>
                        <div class="modal-body">
                            {{Validation_Error_message}}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Benefit Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Benefitpopup}">
                <div class="modal-dialog" role="document" style="margin-top: -30px;">
                    <div class="modal-content">
                        <div class="modal-header p-2">
                            <h4 class="modal-title">Benefit Summary</h4>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-6 p-2" style="background-color: #D9D9D9;">
                                    <b>Group Mediclaim Policy</b>
                                </div>
                                <div class="col-sm-6 p-2" style="background-color: #F8934C; text-align: center; color: #fff">
                                    Family Cover - 5,00,000
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 p-0">
                                    <label class="bs-title mt-3" style="color: #3c0111">Insured Member Details</label>
                                    <div class="table-responsive mt-2">
                                        <table class="table table-bordered table-condensed">
                                            <thead>
                                                <tr>
                                                    <th>Member Name</th>
                                                    <th>Relation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Name 1</td>
                                                    <td>Self</td>
                                                </tr>
                                                <tr>
                                                    <td>Name 2</td>
                                                    <td>Spouse/Partner</td>
                                                </tr>
                                                <tr>
                                                    <td>Name 3</td>
                                                    <td>Son</td>
                                                </tr>
                                                <tr>
                                                    <td>Name 4</td>
                                                    <td>Daughter</td>
                                                </tr>
                                                <tr>
                                                    <td>Name 5</td>
                                                    <td>Father</td>
                                                </tr>
                                                <tr>
                                                    <td>Name 6</td>
                                                    <td>Mother</td>
                                                </tr>
                                                <tr>
                                                    <td>Name 7</td>
                                                    <td>Father In-Law</td>
                                                </tr>
                                                <tr>
                                                    <td>Name 8</td>
                                                    <td>Mother In-Law</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <img src="../../../../../../assets/img/employee/members.png" style="margin: auto; display: block; position: relative; top: 60px;" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer p-2">
                            <button (click)="closePopup()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Close</button>                                
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
