<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

            <h3 class="bs-title" *ngIf="addflag == 0">Annoucements
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Annoucements</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Annoucements</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3> 
            
            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2">

            <!-- List -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Article By</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['article_by']}}</td>
                        <td>{{group['article_title']}}</td>                                      
                        <td>
                            <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                            <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                        </td>
                        <td style="width: 10%" align="center">
                            <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                            <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">
                <form [formGroup]="AddInfo">                    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="article_by">Article by *</label>
                                <input type="text" class="form-control" formControlName="article_by" id="article_by" placeholder="Article by"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.article_by.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.article_by.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.article_by.errors.required">
                                        Article by is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>            
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="article_title">Article Title *</label>
                                <input type="text" class="form-control" formControlName="article_title" id="article_title" placeholder="Article Title"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.article_title.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.article_title.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.article_title.errors.required">
                                        Article Title is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="article_description">Article Description *</label>
                                <input type="text" class="form-control" formControlName="article_description" id="article_description"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.article_description.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.article_description.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.article_description.errors.required">
                                        Article Description is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                            
                    </div>
                    <div class="col-sm-12" align="center">
                        <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                    </div>                    
                </form>
            </div>    

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">                    
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="article_by_edit">Articel By *</label>
                                <input type="text" class="form-control" formControlName="article_by_edit" id="article_by_edit" placeholder="Articel By"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.article_by_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.article_by_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.article_by_edit.errors.required">
                                        Articel By is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>            
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="article_title_edit">Articel Title *</label>
                                <input type="text" class="form-control" formControlName="article_title_edit" id="article_title_edit" placeholder="Articel Title"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.article_title_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.article_title_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.article_title_edit.errors.required">
                                        Articel Title is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                              <label class="bs-form-label" for="edit_is_active">Status *</label>
                              <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                                  <option [value]="1">Active</option>
                                  <option [value]="0">Deactive</option>
                              </select>                    
                          </div>
                      </div>
      
                      <div class="col-sm-12 mt-3">
                          <div class="form-group">
                              <label class="bs-form-label" for="article_description_edit">Article Description *</label>
                              <input type="text" class="form-control" formControlName="article_description_edit" id="article_description_edit"
                              [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.article_description_edit.errors}"
                              required />
                              <ng-container
                                  *ngIf="EditInfoSubmitted && getEditInformationFormControls.article_description_edit.errors">
                                  <p class="error"
                                      *ngIf="EditInfoSubmitted && getEditInformationFormControls.article_description_edit.errors.required">
                                      Article Description is required
                                  </p>
                              </ng-container>
                          </div>
                      </div>
                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                    </div>
                </form>
                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Save</button>                    
                </div>
            </div>   
            
            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>