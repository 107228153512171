import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { PolicyDetailsService } from '../policy-details/policy-details';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})

export class PolicyDetailsComponent implements OnInit {  

  // selectedCar: number;

  // cars = [
  //     { id: 1, name: 'Volvo' },
  //     { id: 2, name: 'Saab' },
  //     { id: 3, name: 'Opel' },
  //     { id: 4, name: 'Audi' },
  // ];
  
  public policyDetails:FormGroup;
  public verifypolicyDetails:FormGroup;
  public policyFeatures:FormGroup;
  public documentUpload:FormGroup;

  private stepper: Stepper;

  policyDetailsSubmitted: boolean = false;
  verifypolicyDetailsSubmitted: boolean = false;
  policyFeaturesSubmitted: boolean = false;
  documentUploadSubmitted: boolean = false;

  isuranceCompanyDetailsarr: any = [];
  tpadetailsArr: any = [];
  brokerdetailsArr: any = [];
  productsArr:  any = [];

  fileData: any;
  fileName: any = '';

  resstatus = localStorage.getItem('resstatus');
  CLIENT_ID = localStorage.getItem('client_id');
  plan_page_flag = localStorage.getItem('plan_page_flag');

  validmsg: any;

  upload_policydocument: any;
  upload_your_active_member_data: any;
  upload_claim_MIS: any;
  upload_claim_pdf_summary: any;
  policy_document: any;
  member_data: any;
  claims_in_current_year: any;
  previous_year_one: any;
  previous_year_two: any;


  cnt = 1;
  cnt_others = 1;
  uploadcnt = 1;
  amt = 1;
  checkopt_val = 1;

  benefitArr: any = {};
  othersArr: any = {};

  uploadExtraArr: any = {};
  gradedArr: any = {};
  metroArr: any = {};

  hospitalizationArr: any = {};
  hospitalization_tableArr: any = [];
  ICU_tableArr: any = [];

  maternityArr: any = {};
  normal_delivery_limit_Arr: any = [];
  c_section_limit_Arr: any = [];

  mygradedarray: any = [];
  mygradedarray_text: any = [];

  client_product_id: any = 0;
  client_product_policy_id: any = 0;
  benefit_id: any = 0;

  displayStyleThankyou = "none";

  dropdownList: any = [];
  selectedItems: any =[];
  dropdownSettings = {};
  familyArrData: any = [];
  familyArr: any = {};

  greatest_graded_value: any;

  no_flow_flag: boolean = false;

  isDisplay:any;

  stepper_flag: boolean = false;

  flag_firstcheck_FC:any = 0;
  flag_firstcheck_SI:any = 0;
  flag_firstcheck_hosp:any = 0;
  flag_firstcheck_additionalbenefits:any = 0;
  flag_firstcheck_ambulance:any = 0;
  flag_firstcheck_OPD:any = 0;
  flag_firstcheck_WPC:any = 0;
  flag_firstcheck_maternity:any = 0;
  flag_firstcheck_CCM:any = 0;
  flag_firstcheck_PC:any = 0;

  flagFC:any = 0;
  flagSI:any = 0;
  flaghosp:any = 0;
  flagadditionalbenefits:any = 0;
  flagambulance:any = 0;
  flagOPD:any = 0;
  flagWPC:any = 0;
  flagmaternity:any = 0;
  flagCCM:any = 0;
  flagPC:any = 0;

  emp_flag:any = 0;
  spo_flag:any = 0;
  child_flag:any = 0;
  par_flag:any = 0;
  sb_flag:any = 0;

  metrocnt = 1;

  gmc_policy_check:any;

  addtext:any;

  proposal_number_show: any;
  BasePlanArr: any = [];

  stepcomplete: any = '';

  min_date: any;
  max_date: any;

    constructor(private fb: FormBuilder, private policyDetailsService: PolicyDetailsService, private route: Router) {

      this.route.events.subscribe((e) => {
        if (this.resstatus == null) {
            this.route.navigate(['/home']);
        }
      });

    }

    ngOnInit(): void {

      this.initGroup();
      
      this.Prog(0,'FC');
      this.Prog(0,'SI');
      this.Prog(0,'waiting');
      this.Prog(0,'hosp');
      this.Prog(0,'maternity');
      this.Prog(0,'CCM');
      this.Prog(0,'OPD');
      this.Prog(0,'Others');
      this.Prog(0,'Ambulance');
      this.Prog(0,'PC'); 

    }

    initGroup(){

      this.gmc_policy_check = localStorage.getItem('gmc_policy_check');

      this.getIsuranceCompanyDetails();
      this.getTPADetails();
      this.getBrokerDetails();
      this.getSubCategory();
      this.getBasePlan();
      
      if(this.plan_page_flag == ''){
        this.plan_page_flag = '';
      }else{
        this.plan_page_flag = localStorage.getItem('plan_page_flag');
      }

      this.validmsg = '';
      $('#validmsg').css('display', 'none');
      $('#basics_accord').css('display','none');
      $('#sumisured_accord').css('display','none');
      $('#waitingPeriodCondition_accord').css('display','none');
      $('#hospitalisationaccord_accord').css('display','none');
      $('#maternity_accord').css('display','none');
      $('#CCM_accord').css('display','none');
      $('#OPD_accord').css('display','none');
      $('#Others_accord').css('display','none');
      $('#AmbulanceCover_accord').css('display','none');
      $('#PC_accord').css('display','none');
      $('#Mobileaccord').css('display','none');

      $('#EPD').prop('disabled',true);

      // Define Stepper
      const stepperElement = document.querySelector('#stepper2');
      if(stepperElement !== null){
      this.stepper = new Stepper(stepperElement , {
            linear: false,
            animation: true
          })
      }

      // Step 1 Form element defined below
      this.policyDetails = this.fb.group({
        policy_sub_category: new FormControl(''),
        proposal_name: new FormControl(''),
        proposal_number: new FormControl(''),        
        // existing_policy_check: new FormControl(),
        upload_check: new FormControl('manually'),
        insurance_company: new FormControl(1),
        tpa: new FormControl(1),
        broker_name: new FormControl(1),
        upload_policydocument: new FormControl(''),
        base_plan: new FormControl('')
      });
      $('.existing_policy_check_yesno').css('display','none');
      
      // Step 2 Form element defined below                    
      this.verifypolicyDetails = this.fb.group({
        policy_date: new FormControl(0),
        how_soon_buy: new FormControl(''),
        choose_month: new FormControl(0),
        policy_expirydate: new FormControl(''),
        data_report_date: new FormControl(''),
        policy_number: new FormControl(0),        
        emp_atstart: new FormControl(''),
        lives_covered: new FormControl(0),
        emp_atreport: new FormControl(0),
        lives_atreport: new FormControl(0)
      });

      // Step 3 Form element defined below
      this.policyFeatures = this.fb.group({

        //Family
        Employees: new FormControl(''),
        On_Roll_Employees: new FormControl(0),
        Contractual_Employees: new FormControl(0),
        Retired_Employees: new FormControl(0),
        Consultants: new FormControl(0),

        Spouse: new FormControl(''),
        Legally_Wedded_Spouse: new FormControl(0),
        Domestic_Partner: new FormControl(0),
        LGBTQ_Cover: new FormControl(0),
        
        Childrens: new FormControl(''),        
        children_type: new FormControl('More Then 2 Child'),
        Age_Limit_of_the_Child: new FormControl(0),
        Age_Limit_of_the_Child_text: new FormControl(''),

        Parents: new FormControl(''),
        Parent_only: new FormControl(''),
        // Parent_or_Parent_Inlaws: new FormControl(0),
        // Parent_and_Parent_Inlaws: new FormControl(0),
        Cross_Combination_status: new FormControl('No'),
        
        Siblings_or_disabled_siblings: new FormControl(''),
        Siblings_or_disabled_siblings_status: new FormControl('No'),
        Age_Limit_of_the_Siblings: new FormControl(0),
        Age_Limit_of_the_Siblings_text: new FormControl(''),

        overall_age_range: new FormControl(''),
        age_range_min: new FormControl(),
        age_range_max: new FormControl(),

        sum_insured_type: new FormControl('Not Floater'),                        
        sum_insured_approach: new FormControl(''),
        sum_insured_uniform: new FormControl(''),

        //Waiting
        thirty_days_waiting_period: new FormControl('Not Applicable'),
        one_and_half_days_waiting_period: new FormControl('Not Applicable'),
        pre_existing_condition: new FormControl('Not Applicable'),
        
        //Hospitalization        
        is_standard_room_rent: new FormControl(0),
        
        room_rent_condition_one: new FormControl(0),
        room_rent_condition_one_conditionbox: new FormControl(0),
        
        ICU_room_rent: new FormControl(0),
        ICU_room_rent_conditionbox: new FormControl(0),
        
        pre_post_hospitalisation: new FormControl(''),
        pre_post_hospitalisation_condition: new FormControl(''),                     

        day_care_procedures: new FormControl('Not Covered'),
        min_max_day_care_procedures: new FormControl('na_day_care_procedures'),
        day_care_procedures_limit_perc: new FormControl('100'),
        day_care_procedures_limit_value: new FormControl(''),

        domiciliary_hospitalisation: new FormControl('Not Covered'),
        min_max_domiciliary_hospitalisation: new FormControl('na_domiciliary_hospitalisation'),
        domiciliary_hospitalisation_limit_perc: new FormControl('100'),
        domiciliary_hospitalisation_limit_value: new FormControl(''),
        
        //Maternity
        //NO FLOW
        do_you_want_maternity: new FormControl(''),

        maternity_new_born_vaccination_no: new FormControl('Not Covered'),
        maternity_new_born_vaccination_no_isCover: new FormControl('within maternity limit'),
        maternity_new_born_vaccination_no_limit: new FormControl(''),

        maternity_related_complications_no: new FormControl('Not Covered'),
        maternity_related_complications_no_isCover: new FormControl('within maternity limit'),

        baby_vaccination_no: new FormControl('Not Covered'),
        baby_vaccination_no_isCover: new FormControl('within maternity limit'),
        baby_vaccination_no_limit: new FormControl(''),

        //YES FLOW
        nine_month_waiting_period: new FormControl('Applicable'),
        
        is_standard_room_rent_maternity: new FormControl(''),
        
        normal_delivery_limit_maternity: new FormControl(''),
        c_section_limit_maternity: new FormControl(''),

        room_rent_natal_cover: new FormControl('Not Covered'),
        room_rent_natal_cover_isCover: new FormControl('within maternity limit'),        
        room_rent_natal_cover_limit: new FormControl(''),

        bay_day_one: new FormControl('Not Covered'),
        bay_day_one_isCover: new FormControl('within maternity limit'),
        baby_specific_covered_limit: new FormControl(''),

        well_baby_cover: new FormControl('Not Covered'),
        well_baby_cover_isCover: new FormControl('within maternity limit'),
        well_baby_covered_limit: new FormControl(''),

        infertility_cover: new FormControl('Not Covered'),
        infertility_cover_isCover: new FormControl('within maternity limit'),
        infertility_covered_limit: new FormControl(''),

        surrogacy_cover: new FormControl('Not Covered'),
        surrogacy_cover_isCover: new FormControl('within maternity limit'),
        surrogacy_covered_limit: new FormControl(''),        

        maternity_new_born_vaccination_yes: new FormControl('Not Covered'),
        maternity_new_born_vaccination_yes_isCover: new FormControl('within maternity limit'),
        maternity_new_born_vaccination_yes_limit: new FormControl(''),

        mother_cover: new FormControl('Not Covered'),
        mother_cover_isCover: new FormControl('within maternity limit'),
        mother_cover_limit: new FormControl(''),

        maternity_related_complications_yes: new FormControl('Not Covered'),
        maternity_related_complications_yes_isCover: new FormControl('within maternity limit'),

        baby_vaccination_yes: new FormControl('Not Covered'),
        baby_vaccination_yes_isCover: new FormControl('within maternity limit'),
        baby_vaccination_yes_limit: new FormControl(''),
                
        //Claim        
        copay: new FormControl('Not Applicable'),
        deductable: new FormControl('Not Applicable'),
        sum_incured_sub_limit: new FormControl('Not Applicable'),
        claim_diseasewisecapping_condition: new FormControl('Not Applicable'),

        claim_copay_condition_1: new FormControl('select'),
        claim_copay_condition_2: new FormControl('select'),
        claim_copay_condition_3: new FormControl('select'),
        claim_copay_condition_4: new FormControl('select'),                        
        claim_copay_condition_5: new FormControl('select'),                        

        deductible_1: new FormControl(''),
        deductible_2: new FormControl(''),
        deductible_3: new FormControl(''),
        deductible_4: new FormControl(''),
        deductible_5: new FormControl(''),

        SI_for_parents_1: new FormControl(''),
        SI_for_parents_2: new FormControl(''),
        SI_for_parents_3: new FormControl(''),
        SI_for_parents_4: new FormControl(''),
        SI_for_parents_5: new FormControl(''),

        claim_diseasewisecapping_condition_1: new FormControl('select'),
        claim_diseasewisecapping_condition_2: new FormControl('select'),
        claim_diseasewisecapping_condition_3: new FormControl('select'),
        claim_diseasewisecapping_condition_4: new FormControl('select'),
        claim_diseasewisecapping_condition_5: new FormControl('select'),

        //Metro table
        Capped_Ailments_metro: new FormControl(''),
        Capped_Ailments_nonmetro: new FormControl(''),

        JHK_Replacement_metro: new FormControl(''),
        JHK_Replacement_nonmetro: new FormControl(''),

        Hernia_metro: new FormControl(''),
        Hernia_nonmetro: new FormControl(''),

        Hysterectomy_metro: new FormControl(''),
        Hysterectomy_nonmetro: new FormControl(''),

        Urinary_SC_metro: new FormControl(''),
        Urinary_SC_nonmetro: new FormControl(''),

        Angiogram_metro: new FormControl(''),
        Angiogram_nonmetro: new FormControl(''),

        Cholecystectomy_metro: new FormControl(''),
        Cholecystectomy_nonmetro: new FormControl(''),

        Appendectomy_metro: new FormControl(''),
        Appendectomy_nonmetro: new FormControl(''),

        CABG_metro: new FormControl(''),
        CABG_nonmetro: new FormControl(''),

        Others_metro: new FormControl(''),
        Others_nonmetro: new FormControl(''),
        
        // OPD
        out_patient_benefits: new FormControl('Not Covered'),
        out_patient_benefits_limit: new FormControl(''),
        opd_dental: new FormControl('Not Covered'),
        opd_dental_limit: new FormControl(''),
        opd_vision: new FormControl('Not Covered'),
        opd_vision_limit: new FormControl(''),
        preventive_health_checkup: new FormControl('Not Covered'),
        preventive_health_checkup_limit: new FormControl(''),
        doctor_consultation: new FormControl('Not Covered'),
        doctor_consultation_limit: new FormControl(''),
        diagnostics: new FormControl('Not Covered'),
        diagnostics_limit: new FormControl(''),
        pharmacy: new FormControl('Not Covered'),
        pharmacy_limit: new FormControl(''),
        new_born_vaccination: new FormControl('Not Covered'),
        new_born_vaccination_limit: new FormControl(''),
        // benefit_name: new FormControl(''),
        // benefit_limit: new FormControl(''),

        // Others
        // hospital_daily_cash: new FormControl('Not Covered'),
        // hospital_daily_cash_limit: new FormControl(''),
        // hospital_daily_cash_limit_perc: new FormControl('100'),
        // hospital_daily_cash_limit_value: new FormControl(''),

        organ_donor_cover: new FormControl('Not Covered'),
        min_max_organ_donor_cover: new FormControl('na_organ_donor_cover'),
        organ_donor_cover_limit_perc: new FormControl('100'),
        organ_donor_cover_limit_value: new FormControl(''),
        
        hiv_cover: new FormControl('Not Covered'),
        min_max_hiv_cover: new FormControl('na_hiv_cover'),
        hiv_cover_limit_perc: new FormControl('100'),
        hiv_cover_limit_value: new FormControl(''),

        genetic_disorder: new FormControl('Not Covered'),
        min_max_genetic_disorder: new FormControl('na_genetic_disorder'),
        genetic_disorder_limit_perc: new FormControl('100'),
        genetic_disorder_limit_value: new FormControl(''),

        lucentis_Avastin_injections: new FormControl('Not Covered'),
        min_max_lucentis_Avastin_injections: new FormControl('na_lucentis_Avastin_injections'),
        lucentis_Avastin_injections_limit_perc: new FormControl('100'),
        lucentis_Avastin_injections_limit_value: new FormControl(''),

        oral_chemo_therapy: new FormControl('Not Covered'),
        min_max_oral_chemo_therapy: new FormControl('na_oral_chemo_therapy'),
        oral_chemo_therapy_limit_perc: new FormControl('100'),
        oral_chemo_therapy_limit_value: new FormControl(''),

        cochlear_implant: new FormControl('Not Covered'),
        min_max_cochlear_implant: new FormControl('na_cochlear_implant'),
        cochlear_implant_limit_perc: new FormControl('100'),
        cochlear_implant_limit_value: new FormControl(''),

        ayush_treatment: new FormControl('Not Covered'),
        min_max_ayush_treatment: new FormControl('na_ayush_treatment'),
        ayush_treatment_limit_perc: new FormControl('100'),
        ayush_treatment_limit_value: new FormControl(''),

        lasik_treatment: new FormControl('Not Covered'),
        min_max_lasik_treatment: new FormControl('na_lasik_treatment'),
        lasik_treatment_limit_perc: new FormControl('100'),
        lasik_treatment_limit_value: new FormControl(''),

        psychiatry_mental_illness_treatment: new FormControl('Not Covered'),
        min_max_psychiatry_mental_illness_treatment: new FormControl('na_psychiatry_mental_illness_treatment'),
        psychiatry_mental_illness_treatment_limit_perc: new FormControl('100'),
        psychiatry_mental_illness_treatment_limit_value: new FormControl(''),

        endoscopic_sinus_surgery: new FormControl('Not Covered'),
        min_max_endoscopic_sinus_surgery: new FormControl('na_endoscopic_sinus_surgery'),
        endoscopic_sinus_surgery_limit_perc: new FormControl('100'),
        endoscopic_sinus_surgery_limit_value: new FormControl(''),

        internal_congenital: new FormControl('Not Covered'),
        min_max_internal_congenital: new FormControl('na_internal_congenital'),
        internal_congenital_limit_perc: new FormControl('100'),
        internal_congenital_limit_value: new FormControl(''),

        external_congenital: new FormControl('Not Covered'),
        min_max_external_congenital: new FormControl('na_external_congenital'),
        external_congenital_limit_perc: new FormControl('100'),
        external_congenital_limit_value: new FormControl(''),

        portability_cover: new FormControl('Not Covered'),

        corporate_buffer: new FormControl('Not Covered'),
        corporate_buffer_isCover: new FormControl('Applicable for Critical illness'),
        corporate_buffer_limit: new FormControl(''),
        corporate_buffer_family_limit: new FormControl(''),

        pandemic_and_epidemic: new FormControl('Not Covered'),

        autism: new FormControl('Not Covered'),
        min_max_autism: new FormControl('na_autism'),
        autism_limit_perc: new FormControl('100'),
        autism_limit_value: new FormControl(''),

        bariatric_surgery: new FormControl('Not Covered'),
        min_max_bariatric_surgery: new FormControl('na_bariatric_surgery'),
        bariatric_surgery_limit_perc: new FormControl('100'),
        bariatric_surgery_limit_value: new FormControl(''),

        cyberknife_treatment: new FormControl('Not Covered'),
        min_max_cyberknife_treatment: new FormControl('na_cyberknife_treatment'),
        cyberknife_treatment_limit_perc: new FormControl('100'),
        cyberknife_treatment_limit_value: new FormControl(''),

        gender_affirmation: new FormControl('Not Covered'),
        min_max_gender_affirmation: new FormControl('na_gender_affirmation'),
        gender_affirmation_limit_perc: new FormControl('100'),
        gender_affirmation_limit_value: new FormControl(''),

        hormonal_therapy_for_cancer: new FormControl('Not Covered'),
        min_max_hormonal_therapy_for_cancer: new FormControl('na_hormonal_therapy_for_cancer'),
        hormonal_therapy_for_cancer_limit_perc: new FormControl('100'),
        hormonal_therapy_for_cancer_limit_value: new FormControl(''),

        prosthetic_devices: new FormControl('Not Covered'),
        min_max_prosthetic_devices: new FormControl('na_prosthetic_devices'),
        prosthetic_devices_limit_perc: new FormControl('100'),
        prosthetic_devices_limit_value: new FormControl(''),

        robotic_surgery: new FormControl('Not Covered'),
        min_max_robotic_surgery: new FormControl('na_robotic_surgery'),
        robotic_surgery_limit_perc: new FormControl('100'),
        robotic_surgery_limit_value: new FormControl(''),

        sexually_transmitted_diseases: new FormControl('Not Covered'),
        min_max_sexually_transmitted_diseases: new FormControl('na_sexually_transmitted_diseases'),
        sexually_transmitted_diseases_limit_perc: new FormControl('100'),
        sexually_transmitted_diseases_limit_value: new FormControl(''),

        sleep_apnea: new FormControl('Not Covered'),
        min_max_sleep_apnea: new FormControl('na_sleep_apnea'),
        sleep_apnea_limit_perc: new FormControl('100'),
        sleep_apnea_limit_value: new FormControl(''),

        stem_cell_treatment: new FormControl('Not Covered'),
        min_max_stem_cell_treatment: new FormControl('na_stem_cell_treatment'),
        stem_cell_treatment_limit_perc: new FormControl('100'),
        stem_cell_treatment_limit_value: new FormControl(''),

        // Ambulance
        road_ambulance: new FormControl('Not Covered'),
        min_max_road_ambulance: new FormControl('na_road_ambulance'),
        road_ambulance_limit_perc: new FormControl(''),
        road_ambulance_limit_value: new FormControl(''),

        cardiac_ambulance: new FormControl('Not Covered'),
        min_max_cardiac_ambulance: new FormControl('na_cardiac_ambulance'),
        cardiac_ambulance_limit_perc: new FormControl(''),
        cardiac_ambulance_limit_value: new FormControl(''),

        air_ambulance: new FormControl('Not Covered'),
        min_max_air_ambulance: new FormControl('na_air_ambulance'),
        air_ambulance_limit_perc: new FormControl(''),
        air_ambulance_limit_value: new FormControl(''),

        // Premium Contribution
        metrol_1: new FormControl('0'),
        metrol_2: new FormControl('0'),
        metrol_3: new FormControl('0'),
        metrol_4: new FormControl('0'),
        metrol_5: new FormControl('0')

      });

      // Step 4 Form element defined below
      this.documentUpload = this.fb.group({
        upload_your_active_member_data: new FormControl(''),
        active_member_data_report_date: new FormControl(''),
        upload_claim_MIS: new FormControl(''),
        claim_MIS_date: new FormControl(''),
        upload_claim_pdf_summary: new FormControl(''),

        policy_document: new FormControl(''),
        member_data: new FormControl(''),
        claims_in_current_year: new FormControl('No'),
        previous_two_years: new FormControl('No'),
        previous_year_one: new FormControl(''),
        previous_year_two: new FormControl(''),
      });      

      
      if(this.gmc_policy_check == 'Yes'){
        this.no_flow_flag = true;
        $('.existing_policy_check_yesno').css('display','block');
        $('.policy_date').css('display','block'); 
        $('.how_soon_buy').css('display','none');  
        $('.lives_covered').css('display','block');
        this.policyDetails.controls['insurance_company'].setValue('');
        this.policyDetails.controls['tpa'].setValue('');
        this.policyDetails.controls['broker_name'].setValue('');       
      }else{
        this.no_flow_flag = false;
        $('.existing_policy_check_yesno').css('display','none');
        $('.policy_date').css('display','none'); 
        $('.how_soon_buy').css('display','block');
        $('.lives_covered').css('display','none');        
        this.policyDetails.controls['insurance_company'].setValue(1);
        this.policyDetails.controls['tpa'].setValue(1);
        this.policyDetails.controls['broker_name'].setValue(1);                
      }

    }    

    mouseEnter(evt : any, val:any){      
      $("#" + val).css('display', 'block');    
    }

    mouseLeave(evt : any, val:any){
      $("#" + val).css('display', 'none');
    }

    existing_policy_check(){
      
    }

    policy_number_text(e:any){      

      this.addtext = $("#" + e.target.id + " option:selected").text();

    }

    entermanually(){
      if(this.policyDetails.controls['upload_check'].value == 'manually'){
        $('#div_upload_policydocument').css('display','none');
        $('#sample_template_excel').css('display','none');
        $('#excel_flow_save').css('display','none');
        $('#manual_flow_save').css('display','block');        
      }else{
        $('#div_upload_policydocument').css('display','block');
        $('#sample_template_excel').css('display','block');
        $('#excel_flow_save').css('display','block');
        $('#manual_flow_save').css('display','none');
      }
      if(this.policyDetails.controls['upload_check'].value == 'clone_plan'){
        $('#div_clone_plan').css('display','block');
        $('#div_upload_policydocument').css('display','none');
        $('#excel_flow_save').css('display','none');
        $('#manual_flow_save').css('display','block');        
      }else{
        $('#div_clone_plan').css('display','none');
      }
    }

    how_soon_buy_check(evt:any){
      var val = evt.target.value;
      if(val == "Specific month"){
        $('.choose_month').css('display','block');
        $('.emp_atstart').addClass('mt-3');
      }else{
        $('.choose_month').css('display','none');
        $('.emp_atstart').removeClass('mt-3');
      }

    }

    lives_covered(evt: any){
      
      var id = evt.target.id;
      var value: any = evt.target.value.replaceAll(',', '');

      if(id == "lives_covered"){
        var temp:any = $('#emp_atstart').val();      
      }else{
        var temp:any = $('#emp_atreport').val();      
      }
      
      var emp_atstart:any = temp.replaceAll(',', '');
      console.log(value+" <",emp_atstart);

      if(value < emp_atstart){   
        console.log('yes');     
        $('#' + id).val('');        
      }else{
        console.log('no');
      }      
      
    }

    /**** Hospitalization ******/
    sumapproach(val: any){

      $('#sum_insured_approach_error').css('display', 'none');

      if(val == 'Graded'){        

        $('.div_sum_insured_approach').css('display', 'block');
        $('.append_sum_insured_approach_uniform').empty();
        $('.div_sum_insured_approach_uniform').css('display', 'none');
        $('.is_standard_room_rent').css('display', 'block');
        $('#is_standard_active').css('display', 'none');
        $('.is_standard_room_rent_maternity').css('display', 'block');
        $('#maternity_is_standard_active').css('display', 'none');

        let html = `
        <div id="this_graded_row_`+this.amt+`" class="col-sm-12 row">
            <div class="col-sm-4 form-group mt-3">
              <label class="bs-form-label" for="grade_text_`+this.amt+`">Grade </label>
              <input type="text" class="form-control" name="grade_text_`+this.amt+`" id="grade_text_`+this.amt+`" />
            </div>
            <div class="col-sm-4 form-group mt-3">
                <label class="bs-form-label" for="graded_amount_`+this.amt+`">Amount *</label>
                <input type="text" class="form-control graded_amount" name="graded_amount_`+this.amt+`" id="graded_amount_`+this.amt+`" />
                <p class="error" id="graded_amount_`+this.amt+`_error" style="display:none; color: #df0000; font-size: 13px;">
                    This field is required
                </p>
            </div>
            <div class="col-sm-2 form-group mt-3 pt-2">                        
                <img src="../../../assets/img/common icons/trash.png" id="graded_row_`+this.amt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
            </div>
        </div>
        `;

        $('.append_sum_insured_approach').append(html);
        let flag = this;
        let id = this.amt;
        $("#graded_amount_"+this.amt).on('change',function(e) {
          flag.navigate(e, id);
        });

        $("#grade_text_"+this.amt).on('change',function(e) {
          flag.navigate_text(e, id);
        });

        $("#graded_row_"+this.amt).on('click',function(e) {
          flag.removeGradedRow(e);
        });

        $("#graded_amount_"+this.amt).on('keyup',function(e) {
          flag.isnumberGraded(e);
        });

        this.amt++;        

      }else{
        
        this.mygradedarray = [];
        // console.log(this.mygradedarray);

        $('.append_sum_insured_approach').empty();
        $('.div_sum_insured_approach').css('display', 'none');
        $('.div_sum_insured_approach_uniform').css('display', 'block');
        $('.is_standard_room_rent').css('display', 'none');
        $('#is_standard_active').css('display', 'block');
        $('.is_standard_room_rent_maternity').css('display', 'none'); 
        $('#maternity_is_standard_active').css('display', 'block');

        let html = `
        <div class="col-sm-6 form-group mt-3">
            <label class="bs-form-label" for="sum_insured_uniform">Amount </label>
            <input type="text" class="form-control" name="sum_insured_uniform" id="sum_insured_uniform" />
        </div>
        `;

        $('.append_sum_insured_approach_uniform').append(html);                              

        let flag = this;
        $("#sum_insured_uniform").on('keyup',function(e) {
          flag.isnumberGraded(e);
        });

      }

      // console.log(this.mygradedarray);

      /**************************************************/

    }

    navigate_text(val:any, id: any){
      
      this.mygradedarray_text.push(val.target.value);
      $('#grade_text_' + id).css("pointer-events", "none");
      // console.log(this.mygradedarray_text);

    }

    navigate(val:any, id: any){      

      this.mygradedarray.push(val.target.value);
      // console.log(this.mygradedarray);
      this.greatest_graded_value = Math.max.apply(Math, this.mygradedarray);

      $('#graded_amount_' + id).css("pointer-events", "none");
      
      /********** Number Separator Starts ********/
      var gradedArr = this.mygradedarray;
      setTimeout(function() {

        for(var i = 0; i < gradedArr.length; i++){
          
          var value: any = gradedArr[i];

          if(value.length == 4){
            var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 5){
            var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 6){
            var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 7){
            var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 8){
            var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 9){
            var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }

        }                    
        
      }, 100);
      /********** Number Separator Ends ********/

    }

    removeGradedRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_graded_row_' + id;
      $(idstr).remove();

      this.mygradedarray.pop(e.target.value);
      this.mygradedarray_text.pop(e.target.value);
      console.log(this.mygradedarray);
      console.log(this.mygradedarray_text);
      this.greatest_graded_value = Math.max.apply(Math, this.mygradedarray);

    }

    isnumberGraded(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var value = '#' + evt.target.id;
          $(value).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
    }
    /***************************/

    onItemSelect(item: any) {
      // console.log(item);
      this.familyArrData.push(item);
    }

    onSelectAll(items: any) {
      // console.log(items);
      this.familyArrData = items;
    }

    isformat(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    isNumber(evt: any) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }  
      if(evt.target.value == "0"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }    
      return true;
    }

    closealert(){
      $("#validmsg").css('display', 'none');
      $("#errormsg").css('display', 'none');
    }

    get getpolicyDetailsFormControls() {
      return this.policyDetails.controls;
    }

    get getverifypolicyDetailsFormControls() {
      return this.verifypolicyDetails.controls;
    }

    get getpolicyFeaturesFormControls() {
      return this.policyFeatures.controls;
    }

    get getdocumentUploadFormControls() {
      return this.documentUpload.controls;
    }

    // Stepper Next Event
    next(step: number) {
      if (step == 1) {        
        
        this.policyDetailsSubmitted = true;
        if (this.policyDetailsSubmitted && this.policyDetails.valid) {
          if(this.policyDetails.controls['upload_check'].value == 'policy_template'){            
            this.onsubmit_first('policy_template');
          }else{            
            this.onsubmit_first('No');
            this.stepper.next(); 
            
            this.stepcomplete = 'step1';
            
            if(this.gmc_policy_check == "Yes"){
              this.stepper_flag = true;
              $('.step2hideFields').css('display','block');   
              this.verifypolicyDetails.controls['policy_date'].setValue('');
              this.verifypolicyDetails.controls['policy_expirydate'].setValue('');
              this.verifypolicyDetails.controls['data_report_date'].setValue('');
              this.verifypolicyDetails.controls['policy_number'].setValue('');
              this.verifypolicyDetails.controls['emp_atreport'].setValue('');
              this.verifypolicyDetails.controls['lives_atreport'].setValue('');
              this.verifypolicyDetails.controls['lives_covered'].setValue('');
              this.verifypolicyDetails.controls['emp_atstart'].setValue('');
              this.verifypolicyDetails.controls['how_soon_buy'].setValue('Immediatly');
              // $('.choose_month').css('display','none');           

            }else{
              this.stepper_flag = false;
              $('.step2hideFields').css('display','none');            
    
              this.verifypolicyDetails.controls['policy_date'].setValue(0);
              this.verifypolicyDetails.controls['policy_expirydate'].setValue(0);
              this.verifypolicyDetails.controls['data_report_date'].setValue(0);
              this.verifypolicyDetails.controls['policy_number'].setValue(0);
              this.verifypolicyDetails.controls['emp_atreport'].setValue(0);
              this.verifypolicyDetails.controls['lives_atreport'].setValue(0);   
              this.verifypolicyDetails.controls['lives_covered'].setValue(0);
              // this.verifypolicyDetails.controls['how_soon_buy'].setValue('');  
              // $('.choose_month').css('display','block');         

            }

          }          

          console.log(this.policyDetails.value);
        }
      }
      if (step == 2) {

        if(this.no_flow_flag == true){  
          console.log(this.verifypolicyDetails.value);        
          this.verifypolicyDetailsSubmitted = true;
          if (this.verifypolicyDetailsSubmitted && this.verifypolicyDetails.valid) {          
            this.onsubmit_second();
            this.stepper.next();
            this.stepcomplete = 'step2';
            console.log(this.verifypolicyDetails.value);
          }  
        }else{        
          console.log(this.verifypolicyDetails.value);  
          this.verifypolicyDetailsSubmitted = true;
          if (this.verifypolicyDetailsSubmitted && this.verifypolicyDetails.valid) {
            this.onsubmit_second();
            this.stepper.next();
            this.stepcomplete = 'step2';
            console.log(this.verifypolicyDetails.value);
          }
        }        
        
      }
      if (step == 3) {                                

        /********************** FC Show *********************/
        if ($('#basics_accord').css('display') == 'none'){
          $('#basics_accord').css('display','block');
          $('#basic_down_arrow').css('display','block');
          $('#basic_up_arrow').css('display','none');
        }
        /***************************** END *******************************/

        /********************** PC Show *********************/
        if ($('#sumisured_accord').css('display') == 'none'){
          $('#sumisured_accord').css('display','block');
          $('#sumisured_down_arrow').css('display','block');
          $('#sumisured_up_arrow').css('display','none');
        }
        /***************************** END *******************************/


        /********************** Waiting Show *********************/
        if ($('#waitingPeriodCondition_accord').css('display') == 'none'){
          $('#waitingPeriodCondition_accord').css('display','block');
          $('#WPC_down_arrow').css('display','block');
          $('#WPC_up_arrow').css('display','none');
        }
        /***************************** END *******************************/


        /********************** Hospitalization Show *********************/
        if ($('#hospitalisationaccord_accord').css('display') == 'none'){
          // console.log('first time');
          $('#hospitalisationaccord_accord').css('display','block');
          $('#hospitalisationaccord_down_arrow').css('display','block');
          $('#hospitalisationaccord_up_arrow').css('display','none');
        }
        /***************************** END *******************************/


        /********************** Maternity Show *********************/
        if ($('#maternity_accord').css('display') == 'none'){
          $('#maternity_accord').css('display','block');
          $('#maternityaccord_down_arrow').css('display','block');
          $('#maternityaccord_up_arrow').css('display','none');
        }
        /***************************** END *******************************/
        

        /********************** Claim Show *********************/
        if ($('#CCM_accord').css('display') == 'none'){
          $('#CCM_accord').css('display','block');
          $('#CCM_down_arrow').css('display','block');
          $('#CCM_up_arrow').css('display','none');
        }
        /***************************** END *******************************/

      
        /********************** OPD Show *********************/
        if ($('#OPD_accord').css('display') == 'none'){
          $('#OPD_accord').css('display','block');
          $('#OPD_down_arrow').css('display','block');
          $('#OPD_up_arrow').css('display','none');
        }
        /***************************** END *******************************/


        /********************** Additional benefits Show *****************/
        if ($('#Others_accord').css('display') == 'none'){
          $('#Others_accord').css('display','block');
          $('#Others_down_arrow').css('display','block');
          $('#Others_up_arrow').css('display','none');
        }
        /***************************** END *******************************/


        /********************** Ambulance Show *********************/
        if ($('#AmbulanceCover_accord').css('display') == 'none'){
          // console.log('first time');
          $('#AmbulanceCover_accord').css('display','block');
          $('#AmbulanceCover_down_arrow').css('display','block');
          $('#AmbulanceCover_up_arrow').css('display','none');
        }
        /***************************** END *******************************/        


        /********************** PC Show *********************/
        if ($('#PC_accord').css('display') == 'none'){
          $('#PC_accord').css('display','block');
          $('#PC_down_arrow').css('display','block');
          $('#PC_up_arrow').css('display','none');
        }
        /***************************** END *******************************/


        /***************** Validation of toggle buttons ******************/
        if(this.policyFeatures.controls['sum_insured_approach'].value == ''){
          $('#sum_insured_approach_error').css('display', 'block');
        }else{
          $('#sum_insured_approach_error').css('display', 'none');
        }
        
        if(this.policyFeatures.controls['is_standard_room_rent'].value == ''){
          $('#is_standard_room_rent_error').css('display', 'block');
        }else{
          $('#is_standard_room_rent_error').css('display', 'none');
        }
        
        if(this.policyFeatures.controls['do_you_want_maternity'].value == ''){
          $('#do_you_want_maternity_error').css('display', 'block');
        }else{
          $('#do_you_want_maternity_error').css('display', 'none');
        }
      
        if(this.policyFeatures.controls['is_standard_room_rent_maternity'].value == ''){
          $('#is_standard_room_rent_maternity_error').css('display', 'block');
        }else{
          $('#is_standard_room_rent_maternity_error').css('display', 'none');
        }
        /*************************** END ***********************************/


        /******************* FC required fields *********************/        

        var errorArr_FC = ['Age_Limit_of_the_Child_text', 'Age_Limit_of_the_Siblings_text', 
							'age_range_min', 'age_range_max'];
        
        for(var i = 0; i < errorArr_FC.length; i++){
          
          var val = errorArr_FC[i];
          var id = errorArr_FC[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == '' || this.policyFeatures.controls[val].value == null){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }                                

        }       
        
        /************** HIDE/SHOW error ********************/
        if($('.empcheck' + ':visible').length == 0){
          
        }else{

          if($(".empcheck").is(":checked")){
            $('#empcheck_error').css('display', 'none');
          }else{
            $('#empcheck_error').css('display', 'block');            
          }          

        }                

        if($('.Employees' + ':visible').length == 0){
                    
        }else{
          var a = this.policyFeatures.controls['Employees'].value;
          if(a == ''){
            $('#empcheck_error_1').css('display', 'block');
          }else{
            $('#empcheck_error_1').css('display', 'none');            
          }
        }
        
        if($('.spousecheck' + ':visible').length == 0){
          
        }else{
          if($(".spousecheck").is(":checked")){
            $('#spousecheck_error').css('display', 'none');
          }else{
            $('#spousecheck_error').css('display', 'block');            
          }
        }

        if($('.div_Spouse' + ':visible').length == 0){          
          
        }else{                    
          var a1 = this.policyFeatures.controls['Spouse'].value;        
          if(a1 == ''){
            $('#empcheck_error_2').css('display', 'block');
          }else{
            $('#empcheck_error_2').css('display', 'none');            
          }
        }

        if($('.div_Childrens' + ':visible').length == 0){          
          
        }else{
          var a2 = this.policyFeatures.controls['Childrens'].value;
          if(a2 == ''){
            $('#empcheck_error_3').css('display', 'block');
          }else{
            $('#empcheck_error_3').css('display', 'none');            
          }
        }

        if($('.parentcheck' + ':visible').length == 0){
          
        }else{
          if($('.parentcheck').is(':checked')) { 
            $('#parentcheck_error').css('display', 'none');
          }else{
            $('#parentcheck_error').css('display', 'block');             
          }
        }

        if($('.div_Parents' + ':visible').length == 0){
                    
        }else{
          var a3 = this.policyFeatures.controls['Parents'].value;
          if(a3 == ''){
            $('#empcheck_error_4').css('display', 'block');
          }else{
            $('#empcheck_error_4').css('display', 'none');            
          }
        }

        if($('.div_Siblings_or_disabled_siblings' + ':visible').length == 0){
                    
        }else{
          var a4 = this.policyFeatures.controls['Siblings_or_disabled_siblings'].value;
          if(a4 == ''){
            $('#empcheck_error_5').css('display', 'block');
          }else{
            $('#empcheck_error_5').css('display', 'none');            
          }
        }

        if($('.div_Overall_Age_limit' + ':visible').length == 0){          
         
        }else{
          var a5 = this.policyFeatures.controls['overall_age_range'].value;
          if(a5 == ''){
            $('#empcheck_error_6').css('display', 'block');
          }else{
            $('#empcheck_error_6').css('display', 'none');            
          } 
        }
        /************* END ***********/
        /******************************  END *******************************/



        /******************* SI required fields ****************************/        

        var errorArr_SI = ['sum_insured_approach'];
        
        for(var i = 0; i < errorArr_SI.length; i++){
          
          var val = errorArr_SI[i];
          var id = errorArr_SI[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == ''){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }  

        /****** For dynamic added amount fields *********/
        $(".graded_amount").each(function() {
          
          var value = $(this).val();
          var field_id = $(this).attr('id');
          var id = $(this).attr('id') + '_error';

          // if(value == ''){
          //   $('#' + id).css('display', 'block');
          //   $('#' + field_id).addClass('control-red');
          // }else{
          //   $('#' + id).css('display', 'none');
          //   $('#' + field_id).removeClass('control-red');
          // }

          if(value == ''){
            $('#' + id).css('display', 'block');
            $('#' + field_id).css('border', 'solid 1px #df0000');
          }else{
            $('#' + id).css('display', 'none');
            $('#' + field_id).css('border', '1px solid #ced4da');
          }

        });
        /*********************** END ********************/

        /******************************  END *******************************/



        /****************** Hospitalization required fields ****************/

        var errorArr = ['day_care_procedures_limit_perc','day_care_procedures_limit_value',
                        'domiciliary_hospitalisation_limit_perc', 'domiciliary_hospitalisation_limit_value',
                        'room_rent_condition_one' , 'ICU_room_rent'];
        
        for(var i = 0; i < errorArr.length; i++){
          
          var val = errorArr[i];
          var id = errorArr[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == '' || this.policyFeatures.controls[val].value == 'select' || this.policyFeatures.controls[val].value == 0){              
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }             
        
        /******************************  END *******************************/
        


        /*************** Maternity and OPD required fields *****************/      

        var errorArr_maternity = ['room_rent_natal_cover_limit', 'baby_specific_covered_limit',
                                  'well_baby_covered_limit', 'infertility_covered_limit',
                                  'surrogacy_covered_limit', 'maternity_new_born_vaccination_yes_limit',
                                  'mother_cover_limit', 'baby_vaccination_yes_limit',
                                  'maternity_new_born_vaccination_no_limit', 'baby_vaccination_no_limit',
                                  'normal_delivery_limit_maternity', 'c_section_limit_maternity',
                                  'out_patient_benefits_limit', 'opd_dental_limit',
                                  'opd_vision_limit', 'preventive_health_checkup_limit',
                                  'doctor_consultation_limit', 'diagnostics_limit',
                                  'pharmacy_limit', 'new_born_vaccination_limit'];
        
        for(var i = 0; i < errorArr_maternity.length; i++){
          
          var val = errorArr_maternity[i];
          var id = errorArr_maternity[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == ''){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }             
        /******************************  END *******************************/


        /******************* claim required fields *********************/        

        var errorArr_claim = ['claim_copay_condition_1', 'claim_copay_condition_2', 'claim_copay_condition_3', 'claim_copay_condition_4', 'claim_copay_condition_5',
                              'deductible_1' , 'deductible_2', 'deductible_3', 'deductible_4', 'deductible_5',
                              'SI_for_parents_1' ,'SI_for_parents_2', 'SI_for_parents_3', 'SI_for_parents_4', 'SI_for_parents_5',
                              'claim_diseasewisecapping_condition_1', 'claim_diseasewisecapping_condition_2', 'claim_diseasewisecapping_condition_3', 'claim_diseasewisecapping_condition_4', 'claim_diseasewisecapping_condition_5',
                              'Capped_Ailments_metro', 'Capped_Ailments_nonmetro', 
                              'JHK_Replacement_metro', 'JHK_Replacement_metro',
                              'Hernia_metro', 'Hernia_nonmetro',
                              'Hysterectomy_metro', 'Hysterectomy_nonmetro',
                              'Urinary_SC_metro', 'Urinary_SC_nonmetro',
                              'Angiogram_metro', 'Angiogram_nonmetro',
                              'Cholecystectomy_metro', 'Cholecystectomy_nonmetro',
                              'Appendectomy_metro', 'Appendectomy_nonmetro',
                              'CABG_metro', 'CABG_nonmetro',
                              'Others_metro', 'Others_nonmetro'];
        
        for(var i = 0; i < errorArr_claim.length; i++){
          
          var val = errorArr_claim[i];
          var id = errorArr_claim[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == '' || this.policyFeatures.controls[val].value == 'select'){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }           
        
        /******************* Metro required fields *********************/        

        var errorArr_Metro = ['Capped_Ailments_metro', 'Capped_Ailments_nonmetro', 
                              'JHK_Replacement_metro', 'JHK_Replacement_nonmetro',
                              'Hernia_metro', 'Hernia_nonmetro',
                              'Hysterectomy_metro', 'Hysterectomy_nonmetro',
                              'Urinary_SC_metro', 'Urinary_SC_nonmetro',
                              'Angiogram_metro', 'Angiogram_nonmetro',
                              'Cholecystectomy_metro', 'Cholecystectomy_nonmetro',
                              'Appendectomy_metro', 'Appendectomy_nonmetro',
                              'CABG_metro', 'CABG_nonmetro',
                              'Others_metro', 'Others_nonmetro'];
        
        for(var i = 0; i < errorArr_Metro.length; i++){
          
          var val = errorArr_Metro[i];
          var id = errorArr_Metro[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == ''){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }             
        /******************************  END *******************************/

        /******************************  END *******************************/


        /**************** Additional benefits required fields **************/        

        var errorArr_others = ['organ_donor_cover_limit_perc', 'organ_donor_cover_limit_value',
                        'hiv_cover_limit_perc', 'hiv_cover_limit_value',
                        'genetic_disorder_limit_perc', 'genetic_disorder_limit_value',
                        'lucentis_Avastin_injections_limit_perc', 'lucentis_Avastin_injections_limit_value',
                        'oral_chemo_therapy_limit_perc', 'oral_chemo_therapy_limit_value',
                        'cochlear_implant_limit_perc', 'cochlear_implant_limit_value',
                        'ayush_treatment_limit_perc', 'ayush_treatment_limit_value',
                        'lasik_treatment_limit_perc', 'lasik_treatment_limit_value',
                        'psychiatry_mental_illness_treatment_limit_perc', 'psychiatry_mental_illness_treatment_limit_value',
                        'endoscopic_sinus_surgery_limit_perc', 'endoscopic_sinus_surgery_limit_value',
                        'internal_congenital_limit_perc', 'internal_congenital_limit_value',
                        'external_congenital_limit_perc', 'external_congenital_limit_value',
                        'corporate_buffer_limit', 'corporate_buffer_family_limit',
                        'autism_limit_perc', 'autism_limit_value',
                        'bariatric_surgery_limit_perc', 'bariatric_surgery_limit_value',
                        'cyberknife_treatment_limit_perc', 'cyberknife_treatment_limit_value',
                        'gender_affirmation_limit_perc', 'gender_affirmation_limit_value',
                        'hormonal_therapy_for_cancer_limit_perc', 'hormonal_therapy_for_cancer_limit_value',
                        'prosthetic_devices_limit_perc', 'prosthetic_devices_limit_value',
                        'robotic_surgery_limit_perc', 'robotic_surgery_limit_value',
                        'sexually_transmitted_diseases_limit_perc', 'sexually_transmitted_diseases_limit_value',
                        'sleep_apnea_limit_perc', 'sleep_apnea_limit_value',
                        'stem_cell_treatment_limit_perc', 'stem_cell_treatment_limit_value'];
        
        for(var i = 0; i < errorArr_others.length; i++){
          
          var val = errorArr_others[i];
          var id = errorArr_others[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == ''){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }             
        /******************************  END *******************************/



        /******************* Ambulance required fields *********************/        

        var errorArr_ambulance = ['road_ambulance_limit_perc', 'road_ambulance_limit_value',
                        'cardiac_ambulance_limit_perc' , 'cardiac_ambulance_limit_value',
                        'air_ambulace_limit_perc' ,'air_ambulace_limit_value'];
        
        for(var i = 0; i < errorArr_ambulance.length; i++){
          
          var val = errorArr_ambulance[i];
          var id = errorArr_ambulance[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == ''){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }             
        /******************************  END *******************************/        


        /******************* PC required fields *********************/        

        var errorArr_PC = ['metrol_1', 'metrol_2', 'metrol_3', 'metrol_4', 'metrol_5'];
        
        for(var i = 0; i < errorArr_PC.length; i++){
          
          var val = errorArr_PC[i];
          var id = errorArr_PC[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == ''){
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }             
        /******************************  END *******************************/                



        /********************* Open/Close FC ******************/              
        
        var id_FC = '#basics_accord';        

        var errorArr_FC = ['Age_Limit_of_the_Child_text', 'Age_Limit_of_the_Siblings_text', 
							'age_range_min', 'age_range_max'];
                
        for(var i = 0; i < errorArr_FC.length; i++){
                    
          var val = errorArr_FC[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_FC + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagFC = 0;
            }else{
              // console.log('no error');
              this.flagFC = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_FC + ' .error').is(':visible');

            if (cond2){
              this.flagFC = 0;
            }else{
              this.flagFC = 1;
            }

          }                    
          
        }

        console.log('FC flag - ', this.flagFC);
        
        if(this.flagFC == 0){
          this.flag_firstcheck_FC = 1;
		      $('#FCtick').css('display','none');
          $('#FCtick_error').css('display','block');
          if ($(id_FC).css('display') == 'none'){
            $(id_FC).css('display','block');            
            
            $('#basic_down_arrow').css('display','none');
            $('#basic_up_arrow').css('display','block');
          }

          // $('.FCheading').css('box-shadow','0px 0px 5px 2px #df0000');
          this.Prog(50, 'FC');
          
        }else{

            // $(id_FC).css('display','none');
            $(id_FC).css('display','none');            
            
            if(this.flag_firstcheck_FC == 1){
              $('#FCtick').css('display','block');
              $('#FCtick_error').css('display','none');
              $('#basic_down_arrow').css('display','block');
              $('#basic_up_arrow').css('display','none');            
              // $('.FCheading').css('box-shadow','0px 0px 5px 2px #00bf0b');            
              this.Prog(100, 'FC');
            }
          
        }        
        /**************************** END ***********************************/



        /********************* Open/Close SI ******************/              
        
        var id_SI = '#sumisured_accord';        

        var errorArr_SI = ['sum_insured_approach'];
                
        for(var i = 0; i < errorArr_SI.length; i++){
                    
          var val = errorArr_SI[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_SI + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagSI = 0;
            }else{
              // console.log('no error');
              this.flagSI = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_SI + ' .error').is(':visible');

            if (cond2){
              this.flagSI = 0;
            }else{
              this.flagSI = 1;
            }

          }                    
          
        }

        console.log('SI flag - ', this.flagSI);
        
        if(this.flagSI == 0){
          this.flag_firstcheck_SI = 1;
		      $('#SItick').css('display','none');
          $('#SItick_error').css('display','block');
          if ($(id_SI).css('display') == 'none'){
            $(id_SI).css('display','block');            
            
            $('#sumisured_down_arrow').css('display','none');
            $('#sumisured_up_arrow').css('display','block');
          }

          // $('.SIheading').css('box-shadow','0px 0px 5px 2px #df0000');
          this.Prog(50, 'SI');
          
        }else{
          // $(id_SI).css('display','none');
          $(id_SI).css('display','none');

          if(this.flag_firstcheck_SI == 1){
            $('#SItick').css('display','block');          
            $('#SItick_error').css('display','none');
            $('#sumisured_down_arrow').css('display','block');
            $('#sumisured_up_arrow').css('display','none');            
            // $('.SIheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'SI');
          }
        }        
        /**************************** END ***********************************/


        /********************* Open/Close WPC ******************/              
        
        var id_WPC = '#waitingPeriodCondition_accord';        

        var check_errorArr_WPC = ['thirty_days_waiting_period', 'one_and_half_days_waiting_period',
                                  'pre_existing_condition'];
                
        for(var i = 0; i < check_errorArr_WPC.length; i++){
                    
          var val = check_errorArr_WPC[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_WPC + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagWPC = 0;
            }else{
              // console.log('no error');
              this.flagWPC = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_WPC + ' .error').is(':visible');

            if (cond2){
              this.flagWPC = 0;
            }else{
              this.flagWPC = 1;
            }

          }                    
          
        }

        console.log('WPC flag - ', this.flagWPC);
        
        if(this.flagWPC == 0){
        this.flag_firstcheck_WPC = 1;
          $('#waitingtick').css('display','none');
          $('#waitingtick_error').css('display','block');
          if ($(id_WPC).css('display') == 'none'){
            $(id_WPC).css('display','block');            
            
            $('#WPC_down_arrow').css('display','none');
            $('#WPC_up_arrow').css('display','block');
          }

          // $('.waitingheading').css('box-shadow','0px 0px 5px 2px #df0000');
          this.Prog(50, 'waiting');
          
        }else{
          // $(id_WPC).css('display','none');
          $(id_WPC).css('display','none');

          if(this.flag_firstcheck_WPC == 1){
            $('#waitingtick').css('display','block');
            $('#waitingtick_error').css('display','none');
            
            $('#WPC_down_arrow').css('display','block');
            $('#WPC_up_arrow').css('display','none');
            
            // $('.waitingheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'waiting');
          }
        }        
        /**************************** END ***********************************/


        /********************* Open/Close Hospitalization ******************/              
        
        var id = '#hospitalisationaccord_accord';        

        var check_errorArr = ['day_care_procedures_limit_perc','day_care_procedures_limit_value',
        'domiciliary_hospitalisation_limit_perc', 'domiciliary_hospitalisation_limit_value',
        'room_rent_condition_one' , 'ICU_room_rent'];
                
        for(var i = 0; i < check_errorArr.length; i++){
                    
          var val = check_errorArr[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flaghosp = 0;
            }else{
              // console.log('no error');
              this.flaghosp = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id + ' .error').is(':visible');

            if (cond2){
              this.flaghosp = 0;
            }else{
              this.flaghosp = 1;
            }

          }                    
          
        }

        console.log('Hospitalization flag - ', this.flaghosp);
        
        if(this.flaghosp == 0){
          this.flag_firstcheck_hosp = 1;
          $('#hosptick').css('display','none');
          $('#hosptick_error').css('display','block');
          if ($(id).css('display') == 'none'){
            // console.log('second time');
            $(id).css('display','block');            
            
            $('#hospitalisationaccord_down_arrow').css('display','none');
            $('#hospitalisationaccord_up_arrow').css('display','block');
          }

          // $('.hospiheading').css('box-shadow','0px 0px 5px 2px #df0000');
          this.Prog(50, 'hosp');
          
        }else{
          // $(id).css('display','none');
          $(id).css('display','none');

          if(this.flag_firstcheck_hosp == 1){
            $('#hosptick').css('display','block');
            $('#hosptick_error').css('display','none');
            
            $('#hospitalisationaccord_down_arrow').css('display','block');
            $('#hospitalisationaccord_up_arrow').css('display','none');
            
            // $('.hospiheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'hosp');
          }
        }        
        /******************************  END *******************************/


        /********************* Open/Close Maternity ******************/              
        
        var id_maternity = '#maternity_accord';        

        var check_errorArr_maternity = ['room_rent_natal_cover_limit', 'baby_specific_covered_limit',
                                  'well_baby_covered_limit', 'infertility_covered_limit',
                                  'surrogacy_covered_limit', 'maternity_new_born_vaccination_yes_limit',
                                  'mother_cover_limit', 'baby_vaccination_yes_limit',
                                  'maternity_new_born_vaccination_no_limit', 'baby_vaccination_no_limit',
                                  'normal_delivery_limit_maternity', 'c_section_limit_maternity',
                                  'out_patient_benefits_limit', 'opd_dental_limit',
                                  'opd_vision_limit', 'preventive_health_checkup_limit',
                                  'doctor_consultation_limit', 'diagnostics_limit',
                                  'pharmacy_limit', 'new_born_vaccination_limit'];
                
        for(var i = 0; i < check_errorArr_maternity.length; i++){
                    
          var val = check_errorArr_maternity[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_maternity + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagmaternity = 0;
            }else{
              // console.log('no error');
              this.flagmaternity = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_maternity + ' .error').is(':visible');

            if (cond2){
              this.flagmaternity = 0;
            }else{
              this.flagmaternity = 1;
            }

          }                    
          
        }

        console.log('maternity flag - ', this.flagmaternity);
        
        if(this.flagmaternity == 0){
          this.flag_firstcheck_maternity = 1;
		      $('#maternitytick').css('display','none');
          $('#maternitytick_error').css('display','block');
          if ($(id_maternity).css('display') == 'none'){
            $(id_maternity).css('display','block');            
            
            $('#maternityaccord_down_arrow').css('display','none');
            $('#maternityaccord_up_arrow').css('display','block');
          }

          // $('.maternityheading').css('box-shadow','0px 0px 5px 2px #df0000');
          this.Prog(50, 'maternity');
          
        }else{
          // $(id_maternity).css('display','none');
          $(id_maternity).css('display','none');

          if(this.flag_firstcheck_maternity == 1){
            $('#maternitytick').css('display','block');
            $('#maternitytick_error').css('display','none');
            
            $('#maternityaccord_down_arrow').css('display','block');
            $('#maternityaccord_up_arrow').css('display','none');
            
            // $('.maternityheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'maternity');
          }
        }        
        /**************************** END ***********************************/


        /********************* Open/Close Claim ******************/              
        
        var id_CCM = '#CCM_accord';        

        var errorArr_claim = ['claim_copay_condition_1', 'claim_copay_condition_2', 'claim_copay_condition_3', 'claim_copay_condition_4', 'claim_copay_condition_5',
                              'deductible_1' , 'deductible_2', 'deductible_3', 'deductible_4', 'deductible_5',
                              'SI_for_parents_1' ,'SI_for_parents_2', 'SI_for_parents_3', 'SI_for_parents_4', 'SI_for_parents_5',
                              'claim_diseasewisecapping_condition_1', 'claim_diseasewisecapping_condition_2', 'claim_diseasewisecapping_condition_3', 'claim_diseasewisecapping_condition_4', 'claim_diseasewisecapping_condition_5'];
                
        for(var i = 0; i < errorArr_claim.length; i++){
                    
          var val = errorArr_claim[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_CCM + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagCCM = 0;
            }else{
              // console.log('no error');
              this.flagCCM = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_CCM + ' .error').is(':visible');

            if (cond2){
              this.flagCCM = 0;
            }else{
              this.flagCCM = 1;
            }

          }                    
          
        }

        console.log('CCM flag - ', this.flagCCM);
        
        if(this.flagCCM == 0){
          this.flag_firstcheck_CCM = 1;
		      $('#CCMtick').css('display','none');
          $('#CCMtick_error').css('display','block');
          if ($(id_CCM).css('display') == 'none'){
            $(id_CCM).css('display','block');            
            
            $('#CCM_down_arrow').css('display','none');
            $('#CCM_up_arrow').css('display','block');
          }

          // $('.CCMheading').css('box-shadow','0px 0px 5px 2px #df0000');

          this.Prog(50, 'CCM');
          
        }else{
          // $(id_CCM).css('display','none');
          $(id_CCM).css('display','none');

          if(this.flag_firstcheck_CCM == 1){
            $('#CCMtick').css('display','block');
            $('#CCMtick_error').css('display','none');
            
            $('#CCM_down_arrow').css('display','block');
            $('#CCM_up_arrow').css('display','none');
            
            // $('.CCMheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'CCM');
          }
        }        
        /**************************** END ***********************************/


        /********************* Open/Close OPD ******************/              
                
        var id_OPD = '#OPD_accord';        

        var check_errorArr_OPD = ['out_patient_benefits_limit', 'opd_dental_limit',
                                  'opd_vision_limit', 'preventive_health_checkup_limit',
                                  'doctor_consultation_limit', 'diagnostics_limit',
                                  'pharmacy_limit', 'new_born_vaccination_limit'];
                
        for(var i = 0; i < check_errorArr_OPD.length; i++){
                    
          var val = check_errorArr_OPD[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_OPD + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagOPD = 0;
            }else{
              // console.log('no error');
              this.flagOPD = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_OPD + ' .error').is(':visible');

            if (cond2){
              this.flagOPD = 0;
            }else{
              this.flagOPD = 1;
            }

          }                    
          
        }

        console.log('OPD flag - ', this.flagOPD);

        if(this.flagOPD == 0){
        this.flag_firstcheck_OPD = 1;
        $('#OPDtick').css('display','none');
          if ($(id_OPD).css('display') == 'none'){
            $(id_OPD).css('display','block');            
            
            $('#OPD_down_arrow').css('display','none');
            $('#OPD_up_arrow').css('display','block');
          }

          // $('.OPDheading').css('box-shadow','0px 0px 5px 2px #df0000');

          this.Prog(50, 'OPD');
          
        }else{
          // $(id_OPD).css('display','none');
          $(id_OPD).css('display','none');

          if(this.flag_firstcheck_OPD == 1){
            $('#OPDtick').css('display','block');
            
            $('#OPD_down_arrow').css('display','block');
            $('#OPD_up_arrow').css('display','none');
            
            // $('.OPDheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'OPD');
          }
          
        }        
        /**************************** END ***********************************/


        /********************* Open/Close Additional Benefits ******************/              
        
        var id_additionalbenefits = '#Others_accord';        

        var check_errorArr_others = ['organ_donor_cover_limit_perc', 'organ_donor_cover_limit_value',
                        'hiv_cover_limit_perc', 'hiv_cover_limit_value',
                        'genetic_disorder_limit_perc', 'genetic_disorder_limit_value',
                        'lucentis_Avastin_injections_limit_perc', 'lucentis_Avastin_injections_limit_value',
                        'oral_chemo_therapy_limit_perc', 'oral_chemo_therapy_limit_value',
                        'cochlear_implant_limit_perc', 'cochlear_implant_limit_value',
                        'ayush_treatment_limit_perc', 'ayush_treatment_limit_value',
                        'lasik_treatment_limit_perc', 'lasik_treatment_limit_value',
                        'psychiatry_mental_illness_treatment_limit_perc', 'psychiatry_mental_illness_treatment_limit_value',
                        'endoscopic_sinus_surgery_limit_perc', 'endoscopic_sinus_surgery_limit_value',
                        'internal_congenital_limit_perc', 'internal_congenital_limit_value',
                        'external_congenital_limit_perc', 'external_congenital_limit_value',
                        'corporate_buffer_limit', 'corporate_buffer_family_limit',
                        'autism_limit_perc', 'autism_limit_value',
                        'bariatric_surgery_limit_perc', 'bariatric_surgery_limit_value',
                        'cyberknife_treatment_limit_perc', 'cyberknife_treatment_limit_value',
                        'gender_affirmation_limit_perc', 'gender_affirmation_limit_value',
                        'hormonal_therapy_for_cancer_limit_perc', 'hormonal_therapy_for_cancer_limit_value',
                        'prosthetic_devices_limit_perc', 'prosthetic_devices_limit_value',
                        'robotic_surgery_limit_perc', 'robotic_surgery_limit_value',
                        'sexually_transmitted_diseases_limit_perc', 'sexually_transmitted_diseases_limit_value',
                        'sleep_apnea_limit_perc', 'sleep_apnea_limit_value',
                        'stem_cell_treatment_limit_perc', 'stem_cell_treatment_limit_value'];
                
        for(var i = 0; i < check_errorArr_others.length; i++){
                    
          var val = check_errorArr_others[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_additionalbenefits + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagadditionalbenefits = 0;
            }else{
              // console.log('no error');
              this.flagadditionalbenefits = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_additionalbenefits + ' .error').is(':visible');

            if (cond2){
              this.flagadditionalbenefits = 0;
            }else{
              this.flagadditionalbenefits = 1;
            }

          }                    
          
        }

        console.log('Additional benefits flag - ', this.flagadditionalbenefits);
        
        if(this.flagadditionalbenefits == 0){
          this.flag_firstcheck_additionalbenefits = 1;
			    $('#additionalbenefitstick').css('display','none');
          $('#additionalbenefitstick_error').css('display','block');
          if ($(id_additionalbenefits).css('display') == 'none'){
            // console.log('second time');
            $(id_additionalbenefits).css('display','block');            
            
            $('#Others_down_arrow').css('display','none');
            $('#Others_up_arrow').css('display','block');
          }

          // $('.additionalbenefitsheading').css('box-shadow','0px 0px 5px 2px #df0000');

          this.Prog(50, 'Others');
          
        }else{
          // $(id_additionalbenefits).css('display','none');
          $(id_additionalbenefits).css('display','none');

          if(this.flag_firstcheck_additionalbenefits == 1){
            $('#additionalbenefitstick').css('display','block');
            $('#additionalbenefitstick_error').css('display','none');
            
            $('#Others_down_arrow').css('display','block');
            $('#Others_up_arrow').css('display','none');
            
            // $('.additionalbenefitsheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'Others');
          }
          
        }        
        /**************************** END ***********************************/


        /********************* Open/Close Ambulance ******************/              
        
        var id_ambulance = '#AmbulanceCover_accord';        

        var check_errorArr_ambulance = ['road_ambulance_limit_perc', 'road_ambulance_limit_value',
                        'cardiac_ambulance_limit_perc' , 'cardiac_ambulance_limit_value',
                        'air_ambulace_limit_perc' ,'air_ambulace_limit_value'];
                
        for(var i = 0; i < check_errorArr_ambulance.length; i++){
                    
          var val_ambulance = check_errorArr_ambulance[i]; 
          var cond = $('#' + val_ambulance).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_ambulance + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagambulance = 0;
            }else{
              // console.log('no error');
              this.flagambulance = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_ambulance + ' .error').is(':visible');

            if (cond2){
              this.flagambulance = 0;
            }else{
              this.flagambulance = 1;
            }

          }                    
          
        }

        console.log('Ambulance flag - ', this.flagambulance);
        
        if(this.flagambulance == 0){
            
          this.flag_firstcheck_ambulance = 1;
          $('#ambulancetick').css('display','none');
          $('#ambulancetick_error').css('display','block');

          if ($(id_ambulance).css('display') == 'none'){
            // console.log('second time');
            $(id_ambulance).css('display','block');            
            
            $('#AmbulanceCover_down_arrow').css('display','none');
            $('#AmbulanceCover_up_arrow').css('display','block');
          }

          // $('.ambulanceheading').css('box-shadow','0px 0px 5px 2px #df0000');

          this.Prog(50, 'Ambulance');
          
        }else{
          // $(id_ambulance).css('display','none');
          $(id_ambulance).css('display','none');

          if(this.flag_firstcheck_ambulance == 1){
            $('#ambulancetick').css('display','block');
            $('#ambulancetick_error').css('display','none');
            
            $('#AmbulanceCover_down_arrow').css('display','block');
            $('#AmbulanceCover_up_arrow').css('display','none');
            
            // $('.ambulanceheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'Ambulance');
          }
        }        
        /*************************** END ***********************************/


        /********************* Open/Close Claim ******************/              
        
        var id_PC = '#PC_accord';        

        var errorArr_PC = ['metrol_1', 'metrol_2', 'metrol_3', 'metrol_4', 'metrol_5'];
                
        for(var i = 0; i < errorArr_PC.length; i++){
                    
          var val = errorArr_PC[i];
          var cond = $('#' + val).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_PC + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagPC = 0;
            }else{
              // console.log('no error');
              this.flagPC = 1;
            }

          }else{
            // console.log('input not visible');            

            var cond2 = $(id_PC + ' .error').is(':visible');

            if (cond2){
              this.flagPC = 0;
            }else{
              this.flagPC = 1;
            }

          }                    
          
        }

        console.log('PC flag - ', this.flagPC);
        
        if(this.flagPC == 0){
          this.flag_firstcheck_PC = 1;
		      $('#PCtick').css('display','none');
          $('#PCtick_error').css('display','block');
          if ($(id_PC).css('display') == 'none'){
            $(id_PC).css('display','block');            
            
            $('#PC_down_arrow').css('display','none');
            $('#PC_up_arrow').css('display','block');
          }

          // $('.PCheading').css('box-shadow','0px 0px 5px 2px #df0000');

          this.Prog(50, 'PC');
          
        }else{
          // $(id_PC).css('display','none');
          $(id_PC).css('display','none');

          if(this.flag_firstcheck_PC == 1){
            $('#PCtick').css('display','block');
            $('#PCtick_error').css('display','none');
            
            $('#PC_down_arrow').css('display','block');
            $('#PC_up_arrow').css('display','none');
            
            // $('.PCheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
            this.Prog(100, 'PC');
          }
        }        
        /**************************** END ***********************************/        

        this.policyFeaturesSubmitted = true;

        /************* SI Approach Array ****************/                  
        var SIval = this.policyFeatures.controls['sum_insured_approach'].value;
        console.log(SIval);
        if(SIval == "Graded"){

          this.gradedArr = {graded_text: this.mygradedarray_text, graded_sum_insured_amount: this.mygradedarray};
          
        }else{
          
          this.gradedArr = $('#sum_insured_uniform').val();

        }
        // console.log(this.gradedArr);
        /****************** END *************************/


        /************* Hospitalization Array ****************/                  
        /******** Create Arr as no of rows from UI table *********/
        var mygradedarray:any =[];
        var normal_room_rent:any =[];   
        var ICU_room_rent:any =[];
        let gArr = this.mygradedarray;
                    
        for(var i = 0; i < gArr.length; i++){
          mygradedarray.push(gArr[i]);
          normal_room_rent.push($('#room_rent_condition_one_' + i).val());
          ICU_room_rent.push($('#ICU_room_rent_' + i).val());            
        }
        
        this.hospitalizationArr = {
          graded_sum_insured_amount: mygradedarray,
          normal_room_rent: normal_room_rent,
          ICU_room_rent: ICU_room_rent
        };

        // console.log(this.hospitalizationArr);
        /****************** END *************************/


        /************* Maternity Array ****************/
        /******** Create Arr as no of rows from UI table *********/
        var graded_sum_insured_amount:any =[];
        var normal_delivery_limit:any =[];   
        var c_section_limit:any =[];
        let gArr2 = this.mygradedarray;
                    
        for(var i = 0; i < gArr2.length; i++){
          graded_sum_insured_amount.push(gArr2[i]);
          normal_delivery_limit.push($('#normal_delivery_limit_' + i).val());
          c_section_limit.push($('#c_section_limit_' + i).val());            
        }                    

        this.maternityArr = {
          graded_sum_insured_amount: graded_sum_insured_amount,
          normal_delivery_limit: normal_delivery_limit,
          c_section_limit: c_section_limit
        };              

        // console.log(this.maternityArr);
        /****************** END *************************/


        /********* Benefit Array ********/
        for(var i = 1; i < this.cnt; i++){            
          this.benefitArr[i] = {bname: $('#benefit_name_'+i).val(), blimit:$('#benefit_limit_'+i).val()};                       
        }

        let object = this.benefitArr;
        for (var key in object) {
          if (object.hasOwnProperty(key)) {
              if (object[key]['bname'] === undefined || object[key]['bname'] === null){
                  delete object[key];
              }
          }
        }
        // console.log("before ==> ", this.benefitArr);
        /****************** END *************************/


        /********* Others Array ********/
        for(var i = 1; i < this.cnt_others; i++){            
          this.othersArr[i] = {bname: $('#others_name_'+i).val(), blimit:$('#others_limit_'+i).val()};                       
        }

        let objecto = this.othersArr;
        for (var key in objecto) {
          if (objecto.hasOwnProperty(key)) {
              if (objecto[key]['bname'] === undefined || objecto[key]['bname'] === null){
                  delete objecto[key];
              }
          }
        }
        // console.log("before ==> ", this.othersArr);


        /********* Metro Array ********/
        for(var i = 1; i < this.metrocnt; i++){            
          this.metroArr[i] = {metro_name: $('#metro_name_'+i).val(), metro: $('#metro_'+i).val(), nonmetro:$('#nonmetro_'+i).val()};                       
        }

        let objectmetroArr = this.metroArr;
        for (var key in objectmetroArr) {
          if (objectmetroArr.hasOwnProperty(key)) {
              if (objectmetroArr[key]['metro'] === undefined || objectmetroArr[key]['nonmetro'] === null){
                  delete objectmetroArr[key];
              }
          }
        }
        // console.log("before ==> ", this.metroArr);
        /****************** END *************************/

        // Call API
        this.onsubmit_third();
        console.log(this.policyFeatures.value);                        



        if(this.flagFC == 1 && this.flagSI == 1 && this.flaghosp == 1 && this.flagadditionalbenefits == 1 &&
          this.flagambulance == 1 && this.flagOPD == 1 && this.flagWPC == 1 &&
          this.flagmaternity == 1 && this.flagCCM == 1 && this.flagPC == 1 && 
          this.policyFeaturesSubmitted && this.policyFeatures.valid &&
          this.flag_firstcheck_FC == 1 && this.flag_firstcheck_SI == 1 && 
          this.flag_firstcheck_hosp == 1 && this.flag_firstcheck_additionalbenefits == 1 && 
          this.flag_firstcheck_ambulance == 1 && this.flag_firstcheck_OPD == 1 && 
          this.flag_firstcheck_WPC == 1 && this.flag_firstcheck_maternity == 1 && 
          this.flag_firstcheck_CCM == 1 && this.flag_firstcheck_PC == 1)
          {                        
              
              $('#family_def_error').css('display', 'none');            
              
              // Giving Call to API and next step
              this.stepper.next();  
              this.stepcomplete = 'step3';            

          }else{
          
          }                                                 

      }
      if (step == 4) {
        this.documentUploadSubmitted = true;
        if (this.documentUploadSubmitted && this.documentUpload.valid) {
          console.log(this.documentUpload.value);

          this.onsubmit_fourth();
          this.stepcomplete = 'step4';

        }
      }
    }

    // Load onclick expire date
    loadecpirydate(){

      var policy_date = this.verifypolicyDetails.controls['policy_date'].value;

      var parts = policy_date.match(/(\d+)/g);
      var d = new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based

      var newdate = new Date(d.getTime() + 365*24*60*60*1000);
      var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];

      this.verifypolicyDetails.controls['policy_expirydate'].setValue(dateString);
      
      this.min_date = policy_date;
      this.max_date = dateString;

    }

    // Stepper Previous Event
    previous() {
      this.stepper.previous();
    }

    getIsuranceCompanyDetails(){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        'product_id': 1,
      };

      this.policyDetailsService.getIsuranceCompanyDetails(postdata)
        .subscribe((result: any) => {
          
          this.isuranceCompanyDetailsarr = result.data;          

        }, (error) => {
            // console.log(result);
      });
    }

    getTPADetails(){
      this.policyDetailsService.getTPADetails()
        .subscribe((result: any) => {

          // console.log(result);
          this.tpadetailsArr = result;

          for(var i = 0; i < result.length; i++){

            let html = `
              <option value="`+result[i]['tpa_id']+`">`+result[i]['name']+`</option>
            `;

            $('#tpa').append(html);

          }

        }, (error) => {
            // console.log(result);
      });
    }

    getBrokerDetails(){
      this.policyDetailsService.getBrokerDetails()
        .subscribe((result: any) => {

          // console.log(result);
          this.brokerdetailsArr = result;

          for(var i = 0; i < result.length; i++){

            let html = `
              <option value="`+result[i]['broker_id']+`">`+result[i]['name']+`</option>
            `;

            $('#broker_name').append(html);

          }


        }, (error) => {
            // console.log(result);
      });

    }

    getSubCategory(){
      var postdata = {
        'id': 1
      };

      this.policyDetailsService.getSubCategory(postdata)
        .subscribe((result: any) => {

          // console.log(result.data);
          this.productsArr = result.data;

          var val;

          for(var i = 0; i < result.data.length; i++){

            if(result.data[i]['sub_product_id'] == 1){
              val = 'selected';
            }else{
              val = '';
            }
  
            let html = `
              <option value="`+result.data[i]['sub_product_id']+`" `+ val +`>`+result.data[i]['sub_product_name']+`</option>
            `;

            $('#policy_sub_category').append(html);

          }


        }, (error) => {
            // console.log(result);
      });

    }

    getBasePlan(){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        'product_id': 1,
        'sub_product_id': 1
      };
  
      this.policyDetailsService.getBasePlan(postdata)
        .subscribe((result: any) => {
  
          // console.log(result);
          this.BasePlanArr = result.data.clientPolicyList;
  
          for(var i = 0; i < result.data.clientPolicyList.length; i++){
  
            let html = `
              <option value="`+result.data.clientPolicyList[i]['cpp_id']+`">`+result.data.clientPolicyList[i]['proposal_name']+`</option>
            `;
  
            $('#base_plan').append(html);
  
          }
  
        }, (error) => {
            // console.log(result);
      });
    }

    /********* GET Upload Files Data ************/

    // step 1 field
    upload_policydocument_change(event: any){
      if (event.target.files.length > 0) {

        this.upload_policydocument = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_upload_policydocument").empty();
        $('#custom-file-upload_upload_policydocument').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_policydocument').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_policydocument').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_policydocument').empty();
        $('#small_upload_policydocument').html('');
        $('#small_upload_policydocument').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
    }

    // step 4 fields
    upload_your_active_member_data_change(event: any){
      if (event.target.files.length > 0) {
        // this.upload_your_active_member_data = event.target.files[0];
        this.upload_your_active_member_data = event.target.files[0];
        // console.log(event.target.files[0]);

        $("#custom-file-upload_upload_your_active_member_data").empty();
        $('#custom-file-upload_upload_your_active_member_data').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_your_active_member_data').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_your_active_member_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_your_active_member_data').empty();
        $('#small_upload_your_active_member_data').html('');
        $('#small_upload_your_active_member_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
    }

    upload_claim_MIS_change(event: any){
      if (event.target.files.length > 0) {

        this.upload_claim_MIS = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_upload_claim_MIS").empty();
        $('#custom-file-upload_upload_claim_MIS').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_claim_MIS').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_claim_MIS').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_claim_MIS').empty();
        $('#small_upload_claim_MIS').html('');
        $('#small_upload_claim_MIS').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

    }

    upload_claim_pdf_summary_change(event: any) {
      if (event.target.files.length > 0) {

        this.upload_claim_pdf_summary = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_upload_claim_pdf_summary").empty();
        $('#custom-file-upload_upload_claim_pdf_summary').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_claim_pdf_summary').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_claim_pdf_summary').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_claim_pdf_summary').empty();
        $('#small_upload_claim_pdf_summary').html('');
        $('#small_upload_claim_pdf_summary').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

    }

    policy_document_change(event: any) {
      if (event.target.files.length > 0) {

        this.policy_document = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_policy_document").empty();
        $('#custom-file-upload_policy_document').html(event.target.files[0]['name']);
        $('#custom-file-upload_policy_document').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_policy_document').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_policy_document').empty();
        $('#small_policy_document').html('');
        $('#small_policy_document').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

    }

    member_data_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.member_data = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-upload_member_data").empty();
        $('#custom-file-upload_member_data').html(event.target.files[0]['name']);
        $('#custom-file-upload_member_data').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-upload_member_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_member_data').empty();
        $('#small_member_data').html('');
        $('#small_member_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }

    claims_in_current_year_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.claims_in_current_year = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-claims_in_current_year").empty();
        $('#custom-file-claims_in_current_year').html(event.target.files[0]['name']);
        $('#custom-file-claims_in_current_year').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-claims_in_current_year').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_claims_in_current_year').empty();
        $('#small_claims_in_current_year').html('');
        $('#small_claims_in_current_year').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }

    previous_year_one_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.previous_year_one = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-previous_year_one").empty();
        $('#custom-file-previous_year_one').html(event.target.files[0]['name']);
        $('#custom-file-previous_year_one').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-previous_year_one').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_previous_year_one').empty();
        $('#small_previous_year_one').html('');
        $('#small_previous_year_one').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }

    previous_year_two_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.previous_year_two = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-previous_year_two").empty();
        $('#custom-file-previous_year_two').html(event.target.files[0]['name']);
        $('#custom-file-previous_year_two').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-previous_year_two').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_previous_year_two').empty();
        $('#small_previous_year_two').html('');
        $('#small_previous_year_two').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }
    /********************************************/


    /******************************/
    basicaccord(){
      this.flag_firstcheck_FC = 1;
      if($('#basics_accord').css('display') == 'none'){
        $('#basics_accord').slideToggle(1000);
        // $('#basics_accord').css('display','block');
        $('#basic_down_arrow').css('display','none');
        $('#basic_up_arrow').css('display','block');
      }else{
        $('#basics_accord').slideToggle(1000);
        // $('#basics_accord').css('display','none');
        $('#basic_down_arrow').css('display','block');
        $('#basic_up_arrow').css('display','none');
      }
    }

    sumisuredaccord(){
      this.flag_firstcheck_SI = 1;
      if($('#sumisured_accord').css('display') == 'none'){
        $('#sumisured_accord').slideToggle(1000);
        // $('#basics_accord').css('display','block');
        $('#sumisured_down_arrow').css('display','none');
        $('#sumisured_up_arrow').css('display','block');
      }else{
        $('#sumisured_accord').slideToggle(1000);
        // $('#basics_accord').css('display','none');
        $('#sumisured_down_arrow').css('display','block');
        $('#sumisured_up_arrow').css('display','none');
      }
    }

    waitingPeriodCondition(){
      this.flag_firstcheck_WPC = 1;
      if($('#waitingPeriodCondition_accord').css('display') == 'none'){
        $('#waitingPeriodCondition_accord').slideToggle(1000);
        // $('#waitingPeriodCondition_accord').css('display','block');
        $('#WPC_down_arrow').css('display','none');
        $('#WPC_up_arrow').css('display','block');
      }else{
        $('#waitingPeriodCondition_accord').slideToggle(1000);
        // $('#waitingPeriodCondition_accord').css('display','none');
        $('#WPC_down_arrow').css('display','block');
        $('#WPC_up_arrow').css('display','none');
      }
    }

    hospitalisationaccord(){
      this.flag_firstcheck_hosp = 1;
      if($('#hospitalisationaccord_accord').css('display') == 'none'){
        $('#hospitalisationaccord_accord').slideToggle(1000);
        // $('#hospitalisationaccord_accord').css('display','block');
        $('#hospitalisationaccord_down_arrow').css('display','none');
        $('#hospitalisationaccord_up_arrow').css('display','block');
      }else{
        $('#hospitalisationaccord_accord').slideToggle(1000);
        // $('#hospitalisationaccord_accord').css('display','none');
        $('#hospitalisationaccord_down_arrow').css('display','block');
        $('#hospitalisationaccord_up_arrow').css('display','none');
      }
    }

    maternityccord(){
      this.flag_firstcheck_maternity = 1;
      if($('#maternity_accord').css('display') == 'none'){
        $('#maternity_accord').slideToggle(1000);
        // $('#maternity_accord').css('display','block');
        $('#maternityaccord_down_arrow').css('display','none');
        $('#maternityaccord_up_arrow').css('display','block');
      }else{
        $('#maternity_accord').slideToggle(1000);
        // $('#maternity_accord').css('display','none');
        $('#maternityaccord_down_arrow').css('display','block');
        $('#maternityaccord_up_arrow').css('display','none');
      }
    }

    ccmaccord(){
      this.flag_firstcheck_CCM = 1;
      if($('#CCM_accord').css('display') == 'none'){
        $('#CCM_accord').slideToggle(1000);
        // $('#CCM_accord').css('display','block');
        $('#CCM_down_arrow').css('display','none');
        $('#CCM_up_arrow').css('display','block');
      }else{
        $('#CCM_accord').slideToggle(1000);
        // $('#CCM_accord').css('display','none');
        $('#CCM_down_arrow').css('display','block');
        $('#CCM_up_arrow').css('display','none');
      }
    }

    OPDaccord(){
      this.flag_firstcheck_OPD = 1;
      if($('#OPD_accord').css('display') == 'none'){
        $('#OPD_accord').slideToggle(1000);
        // $('#OPD_accord').css('display','block');
        $('#OPD_down_arrow').css('display','none');
        $('#OPD_up_arrow').css('display','block');
      }else{
        $('#OPD_accord').slideToggle(1000);
        // $('#OPD_accord').css('display','none');
        $('#OPD_down_arrow').css('display','block');
        $('#OPD_up_arrow').css('display','none');
      }
    }

    Othersaccord(){
      this.flag_firstcheck_additionalbenefits = 1;
      if($('#Others_accord').css('display') == 'none'){
        $('#Others_accord').slideToggle(1000);
        // $('#Others_accord').css('display','block');
        $('#Others_down_arrow').css('display','none');
        $('#Others_up_arrow').css('display','block');
      }else{
        $('#Others_accord').slideToggle(1000);
        // $('#Others_accord').css('display','none');
        $('#Others_down_arrow').css('display','block');
        $('#Others_up_arrow').css('display','none');
      }
    }

    Ambulanceaccord(){
      this.flag_firstcheck_ambulance = 1;
      if($('#AmbulanceCover_accord').css('display') == 'none'){
        $('#AmbulanceCover_accord').slideToggle(1000);
        // $('#AmbulanceCover_accord').css('display','block');
        $('#AmbulanceCover_down_arrow').css('display','none');
        $('#AmbulanceCover_up_arrow').css('display','block');
      }else{
        $('#AmbulanceCover_accord').slideToggle(1000);
        // $('#AmbulanceCover_accord').css('display','none');
        $('#AmbulanceCover_down_arrow').css('display','block');
        $('#AmbulanceCover_up_arrow').css('display','none');
      }
    }

    PCaccord(){
      this.flag_firstcheck_PC = 1;
      if($('#PC_accord').css('display') == 'none'){
        $('#PC_accord').slideToggle(1000);
        // $('#PC_accord').css('display','block');
        $('#PC_down_arrow').css('display','none');
        $('#PC_up_arrow').css('display','block');
      }else{
        $('#PC_accord').slideToggle(1000);
        // $('#PC_accord').css('display','none');
        $('#PC_down_arrow').css('display','block');
        $('#PC_up_arrow').css('display','none');
      }
    }

    Mobileaccord(i:any){
      var id = '#MobileCover_accord_' + i;
      var id_up = '#MobileCover_up_arrow_' + i;
      var id_down = '#MobileCover_down_arrow_' + i;

      if($(id).css('display') == 'none'){
        $(id).css('display','block');
        $(id_down).css('display','none');
        $(id_up).css('display','block');
      }else{
        $(id).css('display','none');
        $(id_down).css('display','block');
        $(id_up).css('display','none');
      }
    }
    /*****************************/


    /***** Add Benefit Field *****/
    addBenefitField(){

      let html = `
      <div id="this_benefit_row_`+this.cnt+`" class="row">      
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="benefit_name_`+this.cnt+`">Benefit Name </label>
            <input type="text" class="form-control" formControlName="benefit_name_`+this.cnt+`" id="benefit_name_`+this.cnt+`" />
        </div>
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="benefit_limit_`+this.cnt+`">Benefit limit </label>
            <input type="text" class="form-control" formControlName="benefit_limit_`+this.cnt+`" id="benefit_limit_`+this.cnt+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="benefit_row_`+this.cnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newbenifitField').append(html);

      this.policyFeatures.addControl("benefit_name_"+this.cnt, new FormControl(''));
      this.policyFeatures.addControl("benefit_limit_"+this.cnt, new FormControl(''));

      var bname = "benefit_name_"+this.cnt;
      var blimit = "benefit_limit_"+this.cnt;

      this.benefitArr[this.cnt] = {bname: "", blimit: ""};

      let flag = this;
      $("#benefit_row_"+this.cnt).on('click',function(e) {
        flag.removeBenefitRow(e);
      });

      $("#benefit_limit_"+this.cnt).on('keyup',function(e) {
        flag.isnumberBenefit(e);
      });
      
      $("#benefit_limit_"+this.cnt).on('input',function(e) {
        flag.isformatbenefit(e);
      });

      this.cnt++;

      // console.log(this.cnt);

    }

    removeBenefitRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_benefit_row_' + id;
      $(idstr).remove();

    }


    isnumberBenefit(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatbenefit(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }


    /************* END ****************/


    /***** Add Benefit Field *****/
    addOthersField(){

      let html = `
      <div id="this_others_row_`+this.cnt_others+`" class="row">      
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="others_name_`+this.cnt_others+`">Benefit Name </label>
            <input type="text" class="form-control" formControlName="others_name_`+this.cnt_others+`" id="others_name_`+this.cnt_others+`" />
        </div>
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="others_limit_`+this.cnt_others+`">Benefit limit </label>
            <input type="text" class="form-control" formControlName="others_limit_`+this.cnt_others+`" id="others_limit_`+this.cnt_others+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="others_row_`+this.cnt_others+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newothersField').append(html);

      this.policyFeatures.addControl("others_name_"+this.cnt_others, new FormControl(''));
      this.policyFeatures.addControl("others_limit_"+this.cnt_others, new FormControl(''));

      var bname = "others_name_"+this.cnt_others;
      var blimit = "others_limit_"+this.cnt_others;

      this.othersArr[this.cnt_others] = {bname: "", blimit: ""};

      let flag = this;
      $("#others_row_"+this.cnt_others).on('click',function(e) {
        flag.removeOthersRow(e);
      });

      $("#others_limit_"+this.cnt_others).on('keyup',function(e) {
        flag.isnumberOthers(e);
      });
      
      $("#others_limit_"+this.cnt_others).on('input',function(e) {
        flag.isformatothers(e);
      });

      this.cnt_others++;

      // console.log(this.cnt_others);

    }

    removeOthersRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_others_row_' + id;
      $(idstr).remove();

    }

    isnumberOthers(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatothers(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    /************* END ****************/
    

    /***** Add Upload Field *****/
    addUploadField(){

      let html = `
      <div class="row" id="this_upload_row_`+this.uploadcnt+`"> 
        <div class="col-sm-11">
            <div class="form-group mt-3">
                <label class="bs-form-label" for="upload_field_`+this.uploadcnt+`">Document</label>

                <label for="file_field_extra`+this.uploadcnt+`" id="custom-file-upload_`+this.uploadcnt+`" class="custom-file-upload form-control extrafile" style="text-align:center; cursor:pointer">
                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                </label>
                <input id="file_field_extra`+this.uploadcnt+`" type="file" accept="application/pdf" class="extrafile" enctype="multipart/form-data" style="display: none" />
                <input id="`+this.uploadcnt+`" type="hidden" class="filehidden" />

            </div>
            <small style="font-size: 12px; color:#605D62" id="small_extra`+this.uploadcnt+`">Upload only PDF files (Max size 4MB)</small>
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">                        
              <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      // $('.newuploadField').append(html);

      $(html).insertBefore('.newuploadField');

      var uploadcnt = this.uploadcnt;
      const extraUploadArr: any = {};

      $('.extrafile').on("change", function(event: any, extrafileArr: []){

        // console.log(uploadcnt);

        if (event.target.files.length > 0) {

          extrafileArr = event.target.files[0];

          $("#custom-file-upload_" + uploadcnt).empty();
          $('#custom-file-upload_' + uploadcnt).html(event.target.files[0]['name']);
          $('#custom-file-upload_' + uploadcnt).css('text-align', 'left');

          var iSize = Math.round(event.target.files[0]['size'] / 1024);
          // console.log(iSize);
          iSize = (Math.round(iSize * 100) / 100);

          $('#custom-file-upload_' + uploadcnt).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

          $('#small_extra'+ uploadcnt).empty();
          $('#small_extra'+ uploadcnt).html('');
          $('#small_extra'+ uploadcnt).append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
        }

        // console.log(extrafileArr);

        extraUploadArr[uploadcnt] = extrafileArr;
        // console.log(extraUploadArr);

      });

      // console.log(extraUploadArr);
      this.uploadExtraArr[this.uploadcnt] = extraUploadArr;
      console.log(this.uploadExtraArr);      

      let flag = this;
      $("#upload_row_"+this.uploadcnt).on('click',function(e) {
        flag.removeUploadRow(e);
      });

      this.uploadcnt++;

    }

    removeUploadRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_upload_row_' + id;
      $(idstr).remove();
      delete this.uploadExtraArr[id];      
      console.log(this.uploadExtraArr);      
    }
    /************** Step 3 download links download files ********/
    downloadcsv(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
      link.setAttribute('download', `Sample Template.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    downloadclaimMIS(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
      link.setAttribute('download', `Sample Template.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    downloadPolicyDetails(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../assets/policy_details.xlsx');
      link.setAttribute('download', `Policy Details.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    downloadMemberdata(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../assets/Member Data Sample Files/Active_Memeber_Data_Format_gmc.xlsx');
      link.setAttribute('download', `Active_Memeber_Data_Format_gmc.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    /*************** Add Metro Field *****************/
    addMetroField(){

      let html = `
      <hr class="my-2 mt-3 MobileAccord">
      <div class="extraclaimfields row" id="this_metro_row_`+this.metrocnt+`">      
        <div class="col-sm-4" style="padding: 0px 20px;">
          <label class="bs-form-label my-2 MobileAccord">Diesease</label>            
          <input type="text" class="form-control" formControlName="metro_name_`+this.metrocnt+`" id="metro_name_`+this.metrocnt+`" />            
        </div>        
        <div class="col-sm-4" style="padding: 0px 20px;">
          <label class="bs-form-label my-2 MobileAccord">Metro</label>
          <input type="text" class="form-control" formControlName="metro_`+this.metrocnt+`" id="metro_`+this.metrocnt+`" />            
        </div>
        <div class="col-sm-4" style="padding: 0px 20px;">
          <label class="bs-form-label my-2 MobileAccord">Non metro</label>
          <div style="display:flex">
            <input type="text" class="form-control" formControlName="nonmetro_`+this.metrocnt+`" id="nonmetro_`+this.metrocnt+`" />
            <img src="../../../assets/img/common icons/trash.png" id="metro_row_`+this.metrocnt+`" role="button" class="img-fluid" style="width: 20px; height: 20px;margin-left: 10px; margin-top: 7px;" title="Remove" />
          </div>
        </div>        
      </div>
      <hr class="my-2 mt-3 MobileAccord">
      `;

      $('.newmetroField').append(html);

      this.policyFeatures.addControl("metro_name_"+this.metrocnt, new FormControl(''));
      this.policyFeatures.addControl("metro_"+this.metrocnt, new FormControl(''));
      this.policyFeatures.addControl("nonmetro_"+this.metrocnt, new FormControl(''));

      var metro_name = "metro_name_"+this.metrocnt;
      var metro = "metro_"+this.metrocnt;
      var nonmetro = "nonmetro_"+this.metrocnt;

      this.metroArr[this.metrocnt] = {metro_name: "", metro: "", nonmetro: ""};

      let flag = this;
      $("#metro_row_"+this.metrocnt).on('click',function(e) {
        flag.removeMetroRow(e);
      });

      $("#metro_"+this.metrocnt).on('keyup',function(e) {
        flag.isnumberMetro(e);
      });
      
      $("#nonmetro_"+this.metrocnt).on('keyup',function(e) {
        flag.isnumberMetro(e);
      });

      $("#metro_"+this.metrocnt).on('input',function(e) {
        flag.isformatmetro(e);
      });

      $("#nonmetro_"+this.metrocnt).on('input',function(e) {
        flag.isformatmetro(e);
      });

      this.metrocnt++;

      // console.log(this.metrocnt);

    }

    removeMetroRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_metro_row_' + id;      
      $(idstr).remove();

    }

    isnumberMetro(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatmetro(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    /************ Check If Select Box has Other option and add textfield ******/
    checkopt(t: any){

      var id = t;
      var val = $('#' + t).val();
      var appendid = t + '_conditionbox';

      if(val == "Others Please specify"){

        var html = `
        <div class="col-sm-12 form-group mt-3" id="`+appendid+`">
          <label class="bs-form-label" for="`+appendid+`">Specify condition</label>
          <input type="text" class="form-control" formControlName="`+appendid+`" id="`+appendid+`" />
        </div>
        `;

        // console.log($('#' + id).parent());

        $('#' + id).parent().append(html);

      }else{
        $('#' + appendid).remove();
      }

    }

    checkopt_table(t: any, value:any){
      // alert($("#"+t).val());
      var id = t;
      var val = $('#' + t).val();
      var appendid = t + '_conditionbox';

      if(val == "Others Please specify"){

        var html = `
        <div class="col-sm-12 form-group mt-3" id="`+appendid+`">
          <label class="bs-form-label" for="`+appendid+`">Specify condition</label>
          <input type="text" class="form-control" formControlName="`+appendid+`" id="`+appendid+`" />
        </div>
        `;

        $('#checkopt_table_' + id).append(html);

      }else{
        $('#' + appendid).remove();
      }

      /*********/
      let flag = this;
      if(value == 'hosp'){
        this.navigate_hostpitalization($("#"+t).val());
      }
      if(value == 'ICU'){
        this.navigate_ICU($("#"+t).val());
      }

      if(value == 'normal_delivery_limit'){
        this.navigate_normal_delivery_limit($("#"+t).val());
      }

      if(value == 'c_section_limit'){
        this.navigate_c_section_limit($("#"+t).val());
      }

      console.log(this.mygradedarray);

    }
    
    navigate_hostpitalization(val:any){
      // console.log(val);
      if(this.hospitalization_tableArr.indexOf(val) >= 0){

      }else{
        this.hospitalization_tableArr.push(val);
      }
      // console.log(this.hospitalization_tableArr);      
    }

    navigate_ICU(val:any){

      if(this.ICU_tableArr.indexOf(val) >= 0){

      }else{
        this.ICU_tableArr.push(val);
      }
      // console.log(this.ICU_tableArr);

    }


    navigate_normal_delivery_limit(val:any){

      if(this.normal_delivery_limit_Arr.indexOf(val) >= 0){

      }else{
        this.normal_delivery_limit_Arr.push(val);
      }
      // console.log(this.normal_delivery_limit_Arr);

    }

    navigate_c_section_limit(val:any){

      if(this.c_section_limit_Arr.indexOf(val) >= 0){

      }else{
        this.c_section_limit_Arr.push(val);
      }
      // console.log(this.c_section_limit_Arr);

    }

    checkopt_table_normal(t: any, value:any){
      var id = t;
      var val = $('#' + t).val();
      var appendid = t + '_conditionbox';

      if(val == "Others Please specify"){

        var html = `
        <div class="col-sm-12 form-group mt-3" id="`+appendid+`">
          <label class="bs-form-label" for="`+appendid+`">Specify condition</label>
          <input type="text" class="form-control" formControlName="`+appendid+`" id="`+appendid+`" />
        </div>
        `;

        $('#' + id).parent().append(html);

      }else{
        $('#' + appendid).remove();
      }
      
    }

    /************* Show and hide limit input fields ************/
    checkradio(id: any, val: any){

      // console.log(id,val);
      if(val == "Covered"){
        $('#'+id).css("display", "block");
      }else{
        $('#'+id).css("display", "none");
      }

      if(val == "Covered"){
        $('.'+id).css("display", "block");
      }else{
        $('.'+id).css("display", "none");
      }     

      // let flag = this;
      // $('#'+id).on('input',function(e) {
      //   flag.checkempty(e);
      // });
      // $('.'+id).on('input',function(e) {
      //   flag.checkempty(e);
      // });

    }

    checkempty(e:any){
      
      var id = e.target.id;
      var value = $('#' + id).val();
      var appendid = '#' + id + '_error';      

      if(value == ''){                

      }else{

        $(appendid).remove();

      }      

    }

    /************* show_family_otp ************/
    show_family_otp(val:any){    
      if($('#show_family_otp_' + val + ':visible').length == 0){
        $('#show_family_otp_' + val).css('display', 'flex');
        $('#thead_' + val).css('display', 'table-cell');
        $('#tbody_' + val).css('display', 'table-cell');
        $('#deductible_tbody_' + val).css('display', 'table-cell');
        $('#sum_insured_tbody_' + val).css('display', 'table-cell');
      }else{
        $('#show_family_otp_' + val).css('display', 'none');
        $('#thead_' + val).css('display', 'none');
        $('#tbody_' + val).css('display', 'none');
        $('#deductible_tbody_' + val).css('display', 'none');
        $('#sum_insured_tbody_' + val).css('display', 'none');
      }
    }

    show_family_otp1(val:any, text: any){     

      // console.log(text,val);
      if(text == 'yes'){
        $('#show_family_otp_' + val).css('display', 'flex');
        $('#thead_' + val).css('display', 'table-cell');
        $('#tbody_' + val).css('display', 'table-cell');
        $('#deductible_tbody_' + val).css('display', 'table-cell');
        $('#sum_insured_tbody_' + val).css('display', 'table-cell');
        $('#claim_diseasewisecapping_condition_tbody_' + val).css('display', 'table-cell');

        $('#'+val+'_'+text).css('background', '#217e2b');
        $('#'+val+'_'+text).css('color', '#fff');
        $('#'+val+'_no').css('background', '#f1f1f1');
        $('#'+val+'_no').css('color', '#000');

        //metro table
        // $('.metro_table').css('display', 'block');
        $('#tbody_PC_' + val).css('display', 'contents'); 
        
        if(val == 'Employees'){
          this.emp_flag = 1;
        }
        if(val == 'Spouse'){
          this.spo_flag = 1;
        }
        if(val == 'Childrens'){
          this.child_flag = 1;
        }
        if(val == 'Parents'){
          this.par_flag = 1;
        }
        if(val == 'Siblings_or_disabled_siblings'){
          this.sb_flag = 1;
        }
                                        
      }else{
        $('#show_family_otp_' + val).css('display', 'none');
        $('#thead_' + val).css('display', 'none');
        $('#tbody_' + val).css('display', 'none');
        $('#deductible_tbody_' + val).css('display', 'none');
        $('#sum_insured_tbody_' + val).css('display', 'none');
        $('#claim_diseasewisecapping_condition_tbody_' + val).css('display', 'none');

        $('#'+val+'_'+text).css('background', '#ff4052');
        $('#'+val+'_'+text).css('color', '#fff');
        $('#'+val+'_yes').css('background', '#f1f1f1');
        $('#'+val+'_yes').css('color', '#000');

        //metro
        $('#tbody_PC_' + val).css('display', 'none');

        if(val == 'Employees'){
          this.emp_flag = 0;
        }
        if(val == 'Spouse'){
          this.spo_flag = 0;
        }
        if(val == 'Childrens'){
          this.child_flag = 0;
        }
        if(val == 'Parents'){
          this.par_flag = 0;
        }
        if(val == 'Siblings_or_disabled_siblings'){
          this.sb_flag = 0;
        }

      }

      /********* Check if all no then hide metrol table **********/                  

      // console.log(this.emp_flag,this.spo_flag,this.child_flag,this.par_flag,this.sb_flag);

      if(this.emp_flag == 0 && this.spo_flag == 0 && this.child_flag == 0 && this.par_flag == 0 && this.sb_flag == 0){
        $('.PC_table').css('display', 'none');
      }else{
        $('.PC_table').css('display', 'block');
      }
      /************************** END ****************************/

      /************* SHOW/HIDE each section **********************/

      if(val == 'Employees'){
        $('.div_Spouse').css('display', 'flex');
      }
      if(val == 'Spouse'){
        $('.div_Childrens').css('display', 'flex');
      }
      if(val == 'Childrens'){
        $('.div_Parents').css('display', 'flex');
      }
      if(val == 'Parents'){
        $('.div_Siblings_or_disabled_siblings').css('display', 'flex');
      }
      if(val == 'Siblings_or_disabled_siblings'){
        $('.div_Overall_Age_limit').css('display', 'flex');
      }
      /************************** END ****************************/   
      
      /********************** SHOW/HIDE maternity section ********/     

      if(this.policyFeatures.controls['Employees'].value == 'No' && 
      this.policyFeatures.controls['Spouse'].value == 'No' &&
      this.policyFeatures.controls['Parents'].value == 'Yes' &&
      this.policyFeatures.controls['Childrens'].value == 'No' &&
      this.policyFeatures.controls['Siblings_or_disabled_siblings'].value == 'No'){
        $("#root_maternity").css('display', 'none');       
      }else{
        $("#root_maternity").css('display', 'block');        
      }
      
      /********************** END ********************************/

    }

    show_family_otp_tr(val:any, text: any){     

      // console.log(text,val);
      if(text == 'yes'){
        $('.claim_table').css('display', 'block');
        $('#tr_' + val).css('display', 'contents');
      }else{        
        $('#tr_' + val).css('display', 'none');
      }

      /****** Check if all not application then *******/      
      var copay = this.policyFeatures.controls['copay'].value;
      var deductable = this.policyFeatures.controls['deductable'].value;
      var sum_incured_sub_limit = this.policyFeatures.controls['sum_incured_sub_limit'].value;
      var claim_diseasewisecapping_condition = this.policyFeatures.controls['claim_diseasewisecapping_condition'].value;      
      
      if(copay == 'Not Applicable' && deductable == 'Not Applicable' && 
          sum_incured_sub_limit == 'Not Applicable' && claim_diseasewisecapping_condition == 'Not Applicable')
      {
        $('.claim_table').css('display', 'none');
      }
      
      if(claim_diseasewisecapping_condition == 'Not Applicable'){
        $('.metro_table').css('display', 'none');
      }
      /************************** END *************************** */

    }

    age_limit(evt: any, val:any){

      let id = evt.target.id;                    
      var value: any = evt.target.value;

      if(val == 'children'){
        if(value > 35 || value == 0){
          $('#' + id).val('');
        }
      }
      if(val == 'sibling'){
        if(value > 60 || value == 0){
          $('#' + id).val('');
        }
      }                     
      if(val == 'agerange'){
        
        if(value > 100){
          $('#' + id).val('');
        }
                
        if(value == '00'){
          $('#' + id).val('');
        }

      }                     

    }
    
    /******  Hospitalization first question show/hide *********/
    is_standard(val:any){                
      
      $('#is_standard_room_rent_error').css('display', 'none');

      if(val == 'yes'){
        $('#is_standard_active').css('display', 'block');
        $('#hospitalizationTablediv').css('display', 'none');        
      }else{
        $('#is_standard_active').css('display', 'none');
        $('#hospitalizationTablediv').css('display', 'block');        
      }
    }

    is_standard_maternity(val:any){   

      $('#is_standard_room_rent_maternity_error').css('display', 'none');            

      if(val == 'yes'){
        $('#maternity_is_standard_active').css('display', 'block');
        $('#maternitytable_div').css('display', 'none');
      }else{
        $('#maternity_is_standard_active').css('display', 'none');
        $('#maternitytable_div').css('display', 'block');
      }
    }

    /**************/
    check_SI_for_parents(event: any){
      let val: any;
      val = event.target.value;
      var str:any = event.target.id;
      var id = str.charAt(str.length -1 );
      // console.log(id);
      // console.log(this.greatest_graded_value);
      if(val > this.greatest_graded_value){
        $('#SI_for_parents_error_' + id).css('display', 'block');
        var valid = '#SI_for_parents_' + id;
        $(valid).val('');
      }else{
        $('#SI_for_parents_error_' + id).css('display', 'none');
      }
    }
    
    /**********Limit Option*************/
    limitOption(evt: any, val: any){
      
      var value = evt.target.value;
      var perc = "." + val + "_limit_perc";
      var inr = "." + val + "_limit_value";

      if(value == "per of Sum Insured"){        
        $(perc).css("display","block");
        $(inr).css("display","none");
      }else{
        $(perc).css("display","none");
        $(inr).css("display","block");
      }

    }

    /************** Submit *************/
    onSubmit() {

      if (this.documentUploadSubmitted && this.documentUpload.valid) {

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'),
          "client_product_policy_id": this.client_product_policy_id,

          "policy_sub_category": this.policyDetails.controls['policy_sub_category'].value,
          "existing_policy_check":this.gmc_policy_check,
          "upload_check":this.policyDetails.controls['upload_check'].value,
          "insurance_company":this.policyDetails.controls['insurance_company'].value,
          "upload_policydocument": this.upload_policydocument,

          "policy_date":this.verifypolicyDetails.controls['policy_date'].value,
          "policy_expirydate":this.verifypolicyDetails.controls['policy_expirydate'].value,
          "policy_number":this.verifypolicyDetails.controls['policy_number'].value,
          "previous_insurer":this.verifypolicyDetails.controls['previous_insurer'].value,
          "broker_name":this.verifypolicyDetails.controls['broker_name'].value,
          "tpa":this.verifypolicyDetails.controls['tpa'].value,
          "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value,
          "lives_covered":this.verifypolicyDetails.controls['lives_covered'].value,
          "emp_atreport":this.verifypolicyDetails.controls['emp_atreport'].value,
          "lives_atreport":this.verifypolicyDetails.controls['lives_atreport'].value,

          "family_def":this.policyFeatures.controls['family_def'].value,
          "sum_insured_type":this.policyFeatures.controls['sum_insured_type'].value,
          "sum_insured_approach":this.policyFeatures.controls['sum_insured_approach'].value,
          "age_range":this.policyFeatures.controls['age_range'].value,
          "mygradedarray": this.mygradedarray,

          "thirty_days_waiting_period":this.policyFeatures.controls['thirty_days_waiting_period'].value,
          "one_and_half_days_waiting_period":this.policyFeatures.controls['one_and_half_days_waiting_period'].value,
          "pre_existing_condition":this.policyFeatures.controls['pre_existing_condition'].value,

          "hospitalization_tableArr": this.hospitalizationArr,
          "pre_post_hospitalisation":this.policyFeatures.controls['pre_post_hospitalisation'].value,
          "domiciliary_hospitalisation":this.policyFeatures.controls['domiciliary_hospitalisation'].value,
          "domiciliary_hospitalisation_limit":this.policyFeatures.controls['domiciliary_hospitalisation_limit'].value,
          "day_care_procedures":this.policyFeatures.controls['day_care_procedures'].value,
          "day_care_procedures_limit": this.policyFeatures.controls['day_care_procedures_limit'].value,

          "maternity_tableArr": this.maternityArr,
          "nine_month_waiting_period":this.policyFeatures.controls['nine_month_waiting_period'].value,
          "room_rent_natal_cover":this.policyFeatures.controls['room_rent_natal_cover'].value,
          "bay_day_one":this.policyFeatures.controls['bay_day_one'].value,
          "bay_day_one_isCover":this.policyFeatures.controls['bay_day_one_isCover'].value,
          "baby_specific_covered_limit":this.policyFeatures.controls['baby_specific_covered_limit'].value,
          "well_baby_cover":this.policyFeatures.controls['baby_specific_covered_limit'].value,
          "well_baby_cover_isCover":this.policyFeatures.controls['well_baby_cover_isCover'].value,
          "well_baby_covered_limit":this.policyFeatures.controls['well_baby_covered_limit'].value,
          "infertility_cover":this.policyFeatures.controls['infertility_cover'].value,
          "infertility_cover_isCover":this.policyFeatures.controls['infertility_cover_isCover'].value,
          "infertility_covered_limit":this.policyFeatures.controls['infertility_covered_limit'].value,
          "surrogacy_cover":this.policyFeatures.controls['surrogacy_cover'].value,
          "surrogacy_cover_covered_limit":this.policyFeatures.controls['surrogacy_cover_covered_limit'].value,
          "maternity_new_born_vaccination":this.policyFeatures.controls['maternity_new_born_vaccination'].value,
          "maternity_new_born_vaccination_isCover":this.policyFeatures.controls['maternity_new_born_vaccination_isCover'].value,
          "maternity_new_born_vaccination_limit":this.policyFeatures.controls['maternity_new_born_vaccination_limit'].value,
          "mother_cover":this.policyFeatures.controls['mother_cover'].value,
          "mother_cover_isCover":this.policyFeatures.controls['mother_cover_isCover'].value,
          "mother_cover_covered_limit":this.policyFeatures.controls['mother_cover_covered_limit'].value,

          "claim_copay_condition":this.policyFeatures.controls['claim_copay_condition'].value,
          "claim_copay_condition_applicable":this.policyFeatures.controls['claim_copay_condition_applicable'].value,
          "claim_diseasewisecapping_condition":this.policyFeatures.controls['claim_diseasewisecapping_condition'].value,
          "claim_diseasewisecapping_condition_applicable":this.policyFeatures.controls['claim_diseasewisecapping_condition_applicable'].value,
          "deductible": this.policyFeatures.controls['deductible'].value,
          "deductible_condition_applicable": this.policyFeatures.controls['deductible_condition_applicable'].value,
          "SI_for_parents": this.policyFeatures.controls['SI_for_parents'].value,
          "SI_for_parents_condition_applicable": this.policyFeatures.controls['SI_for_parents_condition_applicable'].value,

          "out_patient_benefits":this.policyFeatures.controls['out_patient_benefits'].value,
          "out_patient_benefits_limit":this.policyFeatures.controls['out_patient_benefits_limit'].value,
          "opd_dental":this.policyFeatures.controls['opd_dental'].value,
          "opd_dental_limit":this.policyFeatures.controls['opd_dental_limit'].value,
          "opd_vision":this.policyFeatures.controls['opd_vision'].value,
          "opd_vision_limit":this.policyFeatures.controls['opd_vision_limit'].value,
          "preventive_health_checkup":this.policyFeatures.controls['preventive_health_checkup'].value,
          "preventive_health_checkup_limit":this.policyFeatures.controls['preventive_health_checkup_limit'].value,
          "doctor_consultation":this.policyFeatures.controls['doctor_consultation'].value,
          "doctor_consultation_limit":this.policyFeatures.controls['doctor_consultation_limit'].value,
          "diagnostics":this.policyFeatures.controls['diagnostics'].value,
          "diagnostics_limit":this.policyFeatures.controls['diagnostics_limit'].value,
          "pharmacy":this.policyFeatures.controls['pharmacy'].value,
          "pharmacy_limit":this.policyFeatures.controls['pharmacy_limit'].value,
          "new_born_vaccination":this.policyFeatures.controls['new_born_vaccination'].value,
          "new_born_vaccination_limit":this.policyFeatures.controls['new_born_vaccination_limit'].value,
          // "benefit_name":this.policyFeatures.controls['benefit_name'].value,
          // "benefit_limit":this.policyFeatures.controls['benefit_limit'].value,
          "benefit_extra_fields": this.benefitArr,

          "hospital_daily_cash":this.policyFeatures.controls['hospital_daily_cash'].value,
          "hospital_daily_cash_limit":this.policyFeatures.controls['hospital_daily_cash_limit'].value,
          "organ_donor_cover":this.policyFeatures.controls['organ_donor_cover'].value,
          "organ_donor_cover_limit":this.policyFeatures.controls['organ_donor_cover_limit'].value,
          "hiv_cover":this.policyFeatures.controls['hiv_cover'].value,
          "hiv_cover_limit":this.policyFeatures.controls['hiv_cover_limit'].value,
          "genetic_disorder":this.policyFeatures.controls['genetic_disorder'].value,
          "genetic_disorder_limit":this.policyFeatures.controls['genetic_disorder_limit'].value,
          "lucentis_Avastin_injections":this.policyFeatures.controls['lucentis_Avastin_injections'].value,
          "lucentis_Avastin_injections_limit":this.policyFeatures.controls['lucentis_Avastin_injections_limit'].value,
          "oral_chemo_therapy":this.policyFeatures.controls['oral_chemo_therapy'].value,
          "oral_chemo_therapy_limit":this.policyFeatures.controls['oral_chemo_therapy_limit'].value,
          "cochlear_implant":this.policyFeatures.controls['cochlear_implant'].value,
          "cochlear_implant_limit":this.policyFeatures.controls['cochlear_implant_limit'].value,
          "ayush_treatment":this.policyFeatures.controls['ayush_treatment'].value,
          "ayush_treatment_limit":this.policyFeatures.controls['ayush_treatment_limit'].value,
          "lasik_treatment":this.policyFeatures.controls['lasik_treatment'].value,
          "lasik_treatment_limit":this.policyFeatures.controls['lasik_treatment_limit'].value,
          "psychiatry_mental_illness_treatment":this.policyFeatures.controls['psychiatry_mental_illness_treatment'].value,
          "psychiatry_mental_illness_treatment_limit":this.policyFeatures.controls['psychiatry_mental_illness_treatment_limit'].value,
          "endoscopic_sinus_surgery":this.policyFeatures.controls['endoscopic_sinus_surgery'].value,
          "endoscopic_sinus_surgery_limit":this.policyFeatures.controls['endoscopic_sinus_surgery_limit'].value,
          "internal_congenital":this.policyFeatures.controls['internal_congenital'].value,
          "internal_congenital_limit":this.policyFeatures.controls['internal_congenital_limit'].value,
          "external_congenital":this.policyFeatures.controls['external_congenital'].value,
          "external_congenital_limit":this.policyFeatures.controls['external_congenital_limit'].value,
          "portability_cover":this.policyFeatures.controls['portability_cover'].value,
          "corporate_buffer":this.policyFeatures.controls['corporate_buffer'].value,
          "corporate_buffer_limit":this.policyFeatures.controls['corporate_buffer_limit'].value,
          "pandemic_and_epidemic":this.policyFeatures.controls['pandemic_and_epidemic'].value,

          "road_ambulance":this.policyFeatures.controls['road_ambulance'].value,
          "road_ambulance_limit":this.policyFeatures.controls['road_ambulance_limit'].value,
          "cardiac_ambulance":this.policyFeatures.controls['cardiac_ambulance'].value,
          "cardiac_ambulance_limit":this.policyFeatures.controls['cardiac_ambulance_limit'].value,
          "air_ambulace":this.policyFeatures.controls['air_ambulace'].value,
          "air_ambulace_limit":this.policyFeatures.controls['air_ambulace_limit'].value,

          "settlement_type":this.policyFeatures.controls['settlement_type'].value,


          // "upload_your_active_member_data":this.upload_your_active_member_data,
          // "active_member_data_report_date":this.documentUpload.controls['active_member_data_report_date'].value,
          // "upload_claim_MIS": this.upload_claim_MIS,
          // "claim_MIS_date":this.documentUpload.controls['claim_MIS_date'].value,
          // "upload_claim_pdf_summary": this.upload_claim_pdf_summary,
          "policy_document": this.policy_document,
          "extra_upload_files": this.uploadExtraArr


        };

        console.log(postdata);

        this.policyDetailsService.policyDetailsCreate(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              this.policyDetails.reset();
              this.verifypolicyDetails.reset();
              this.policyFeatures.reset();
              this.documentUpload.reset();

              this.displayStyleThankyou = "block";

              localStorage.setItem('flow3','yes');

              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');
              var flag = this;
              setTimeout(function() {
                flag.route.navigate(['/Client-dashboard']);
              }, 3000);


            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

      }

    }

    onsubmit_first(value: any){

      var postdata;
      var text_policy_sub_category = $("#policy_sub_category option:selected").text();      


      if(this.gmc_policy_check == 'Yes'){
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'),
          "client_product_id": this.client_product_id,
          "client_product_policy_id": this.client_product_policy_id,
          "benefit_id": this.benefit_id,
  
          "policy_sub_category": 1,
          // "proposal_name": this.policyDetails.controls['proposal_name'].value + "_ClientID-001_" + text_policy_sub_category,
          // "proposal_number": this.policyDetails.controls['proposal_number'].value,
          "select_product": 1,

          "existing_policy_check":this.gmc_policy_check,
          "upload_check":this.policyDetails.controls['upload_check'].value,
          "insurance_company":this.policyDetails.controls['insurance_company'].value,
          "tpa":this.policyDetails.controls['tpa'].value,
          "broker_name":this.policyDetails.controls['broker_name'].value,        
          "upload_policydocument": this.upload_policydocument,
          "base_plan": this.policyDetails.controls['base_plan'].value,        
        }
      }else{
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'),
          "client_product_id": this.client_product_id,
          "client_product_policy_id": this.client_product_policy_id,
          "benefit_id": this.benefit_id,
           
          "policy_sub_category": 1,
          "proposal_name": this.policyDetails.controls['proposal_name'].value + "_ClientID-001_" + text_policy_sub_category,
          "proposal_number": this.policyDetails.controls['proposal_number'].value,
          "select_product": 1,

          "existing_policy_check":this.gmc_policy_check,
          "upload_check":this.policyDetails.controls['upload_check'].value,
          "upload_policydocument": this.upload_policydocument,
          "base_plan": this.policyDetails.controls['base_plan'].value          
        }
      }      

      this.policyDetailsService.policyDetailsCreate_first(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              this.benefit_id = result.data.benefit_id;
              this.client_product_id = result.data.client_product_id;
              this.client_product_policy_id = result.data.client_product_policy_id;

              if(value == 'policy_template'){
                // this.displayStyleThankyou = "block";
              }else{

              }

              // this.validmsg = result.message;
              // $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');

              // var flag = this;
              // setTimeout(function() {
              //   // flag.route.navigate(['/Client-dashboard']);
              // }, 3000);


            } else {
              // console.log(result);
            }
          }, (error) => {
            // this.displayStyleThankyou = "block";
            // this.validmsg = error.error.message;
            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');
        });

    }

    onsubmit_second(){
      var postdata;
      if(this.no_flow_flag == true){
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'),          
          "client_product_id": this.client_product_id,
          "client_product_policy_id": this.client_product_policy_id,
          "existing_policy_check": this.gmc_policy_check,
          "benefit_id": this.benefit_id,
  
          "policy_date":this.verifypolicyDetails.controls['policy_date'].value,
          "policy_expirydate":this.verifypolicyDetails.controls['policy_expirydate'].value,
          "data_report_date":this.verifypolicyDetails.controls['data_report_date'].value,          
          "policy_number":this.verifypolicyDetails.controls['policy_number'].value,
          "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value,
          "lives_covered":this.verifypolicyDetails.controls['lives_covered'].value,
          "emp_atreport":this.verifypolicyDetails.controls['emp_atreport'].value,
          "lives_atreport":this.verifypolicyDetails.controls['lives_atreport'].value
        }
      }else{
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'),
          "client_product_id": this.client_product_id,
          "client_product_policy_id": this.client_product_policy_id,
          "benefit_id": this.benefit_id,
  
          "how_soon_buy":this.verifypolicyDetails.controls['how_soon_buy'].value,
          "choose_month":this.verifypolicyDetails.controls['choose_month'].value,
          "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value
        }
      }      

      this.policyDetailsService.policyDetailsCreate_second(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              this.benefit_id = result.data.benefit_id;              

              // this.validmsg = result.message;
              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');
              var flag = this;
              setTimeout(function() {
                // flag.route.navigate(['/Client-dashboard']);
              }, 3000);


            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

    }

    onsubmit_third(){      

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),      
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "existing_policy_check": this.gmc_policy_check,
        "benefit_id": this.benefit_id,

        // Family
        "Employees": this.policyFeatures.controls['Employees'].value,
        "On_Roll_Employees": this.policyFeatures.controls['On_Roll_Employees'].value,
        "Contractual_Employees": this.policyFeatures.controls['Contractual_Employees'].value,
        "Retired_Employees": this.policyFeatures.controls['Retired_Employees'].value,
        "Consultants": this.policyFeatures.controls['Consultants'].value,

        "Spouse": this.policyFeatures.controls['Spouse'].value,
        "Legally_Wedded_Spouse": this.policyFeatures.controls['Legally_Wedded_Spouse'].value,
        "Domestic_Partner": this.policyFeatures.controls['Domestic_Partner'].value,
        "LGBTQ_Cover": this.policyFeatures.controls['LGBTQ_Cover'].value,

        "Childrens": this.policyFeatures.controls['Childrens'].value,        
        "children_type": this.policyFeatures.controls['children_type'].value,
        "Age_Limit_of_the_Child": this.policyFeatures.controls['Age_Limit_of_the_Child'].value,
        "Age_Limit_of_the_Child_text": this.policyFeatures.controls['Age_Limit_of_the_Child_text'].value,

        "Parents": this.policyFeatures.controls['Parents'].value,
        "Parent_only": this.policyFeatures.controls['Parent_only'].value,
        // "Parent_or_Parent_Inlaws": this.policyFeatures.controls['Parent_or_Parent_Inlaws'].value,
        // "Parent_and_Parent_Inlaws": this.policyFeatures.controls['Parent_and_Parent_Inlaws'].value,        
        "Cross_Combination_status": this.policyFeatures.controls['Cross_Combination_status'].value,        
        
        "Siblings_or_disabled_siblings": this.policyFeatures.controls['Siblings_or_disabled_siblings'].value,
        "Siblings_or_disabled_siblings_status": this.policyFeatures.controls['Siblings_or_disabled_siblings_status'].value,
        "Age_Limit_of_the_Siblings": this.policyFeatures.controls['Age_Limit_of_the_Siblings'].value,
        "Age_Limit_of_the_Siblings_text": this.policyFeatures.controls['Age_Limit_of_the_Siblings_text'].value,
        
        "overall_age_range": this.policyFeatures.controls['overall_age_range'].value,
        "age_range_min": this.policyFeatures.controls['age_range_min'].value,
        "age_range_max": this.policyFeatures.controls['age_range_max'].value,
        
        // Sum Incured
        "sum_insured_type": this.policyFeatures.controls['sum_insured_type'].value,                        
        "sum_insured_approach": this.policyFeatures.controls['sum_insured_approach'].value,
        "sum_insured_uniform": this.policyFeatures.controls['sum_insured_uniform'].value,
        "mygradedarray": this.gradedArr,

        // Waiting Period
        "thirty_days_waiting_period":this.policyFeatures.controls['thirty_days_waiting_period'].value,
        "one_and_half_days_waiting_period":this.policyFeatures.controls['one_and_half_days_waiting_period'].value,
        "pre_existing_condition":this.policyFeatures.controls['pre_existing_condition'].value,

        // Hospitalization
        "hospitalization_tableArr": this.hospitalizationArr,                
        "is_standard_room_rent":this.policyFeatures.controls['is_standard_room_rent'].value,
        "room_rent_condition_one":this.policyFeatures.controls['room_rent_condition_one'].value,
        "room_rent_condition_one_conditionbox":this.policyFeatures.controls['room_rent_condition_one_conditionbox'].value,
        "ICU_room_rent":this.policyFeatures.controls['ICU_room_rent'].value,
        "ICU_room_rent_conditionbox":this.policyFeatures.controls['ICU_room_rent_conditionbox'].value,
        "pre_post_hospitalisation":this.policyFeatures.controls['pre_post_hospitalisation'].value,
        "pre_post_hospitalisation_condition":this.policyFeatures.controls['pre_post_hospitalisation_condition'].value,
        
        "domiciliary_hospitalisation":this.policyFeatures.controls['domiciliary_hospitalisation'].value,
        "min_max_domiciliary_hospitalisation":this.policyFeatures.controls['min_max_domiciliary_hospitalisation'].value,
        "domiciliary_hospitalisation_limit_perc":this.policyFeatures.controls['domiciliary_hospitalisation_limit_perc'].value,
        "domiciliary_hospitalisation_limit_value":this.policyFeatures.controls['domiciliary_hospitalisation_limit_value'].value,
        
        "day_care_procedures":this.policyFeatures.controls['day_care_procedures'].value,
        "min_max_day_care_procedures": this.policyFeatures.controls['min_max_day_care_procedures'].value,
        "day_care_procedures_limit_perc":this.policyFeatures.controls['day_care_procedures_limit_perc'].value,
		    "day_care_procedures_limit_value":this.policyFeatures.controls['day_care_procedures_limit_value'].value,

        // Maternity
        "do_you_want_maternity": this.policyFeatures.controls['do_you_want_maternity'].value,

        //NO FLOW
        "maternity_new_born_vaccination_no":this.policyFeatures.controls['maternity_new_born_vaccination_no'].value,
        "maternity_new_born_vaccination_no_isCover":this.policyFeatures.controls['maternity_new_born_vaccination_no_isCover'].value,
        "maternity_new_born_vaccination_no_limit":this.policyFeatures.controls['maternity_new_born_vaccination_no_limit'].value,
        "maternity_related_complications_no":this.policyFeatures.controls['maternity_related_complications_no'].value,
        "maternity_related_complications_no_isCover":this.policyFeatures.controls['maternity_related_complications_no_isCover'].value,
        "baby_vaccination_no":this.policyFeatures.controls['baby_vaccination_no'].value,
        "baby_vaccination_no_isCover":this.policyFeatures.controls['baby_vaccination_no_isCover'].value,
        "baby_vaccination_no_limit":this.policyFeatures.controls['baby_vaccination_no_limit'].value,        

        //YES FLOW       
        "maternity_tableArr": this.maternityArr,
        "nine_month_waiting_period":this.policyFeatures.controls['nine_month_waiting_period'].value,      
        "is_standard_room_rent_maternity":this.policyFeatures.controls['is_standard_room_rent_maternity'].value,

        "normal_delivery_limit_maternity":this.policyFeatures.controls['normal_delivery_limit_maternity'].value,
        "c_section_limit_maternity":this.policyFeatures.controls['c_section_limit_maternity'].value,

        "room_rent_natal_cover":this.policyFeatures.controls['room_rent_natal_cover'].value,
        "room_rent_natal_cover_isCover":this.policyFeatures.controls['room_rent_natal_cover_isCover'].value,
        "room_rent_natal_cover_limit":this.policyFeatures.controls['room_rent_natal_cover_limit'].value,        

        "bay_day_one":this.policyFeatures.controls['bay_day_one'].value,
        "bay_day_one_isCover":this.policyFeatures.controls['bay_day_one_isCover'].value,
        "baby_specific_covered_limit":this.policyFeatures.controls['baby_specific_covered_limit'].value,

        "well_baby_cover":this.policyFeatures.controls['baby_specific_covered_limit'].value,
        "well_baby_cover_isCover":this.policyFeatures.controls['well_baby_cover_isCover'].value,
        "well_baby_covered_limit":this.policyFeatures.controls['well_baby_covered_limit'].value,

        "infertility_cover":this.policyFeatures.controls['infertility_cover'].value,
        "infertility_cover_isCover":this.policyFeatures.controls['infertility_cover_isCover'].value,
        "infertility_covered_limit":this.policyFeatures.controls['infertility_covered_limit'].value,

        "surrogacy_cover":this.policyFeatures.controls['surrogacy_cover'].value,
        "surrogacy_cover_isCover":this.policyFeatures.controls['surrogacy_cover_isCover'].value,
        "surrogacy_covered_limit":this.policyFeatures.controls['surrogacy_covered_limit'].value,

        "maternity_new_born_vaccination_yes":this.policyFeatures.controls['maternity_new_born_vaccination_yes'].value,
        "maternity_new_born_vaccination_yes_isCover":this.policyFeatures.controls['maternity_new_born_vaccination_yes_isCover'].value,
        "maternity_new_born_vaccination_yes_limit":this.policyFeatures.controls['maternity_new_born_vaccination_yes_limit'].value,
        
        "mother_cover":this.policyFeatures.controls['mother_cover'].value,
        "mother_cover_isCover":this.policyFeatures.controls['mother_cover_isCover'].value,
        "mother_cover_limit":this.policyFeatures.controls['mother_cover_limit'].value,

        "baby_vaccination_yes":this.policyFeatures.controls['baby_vaccination_yes'].value,
        "baby_vaccination_yes_isCover":this.policyFeatures.controls['baby_vaccination_yes_isCover'].value,
        "baby_vaccination_yes_limit":this.policyFeatures.controls['baby_vaccination_yes_limit'].value,        
        
        // Claim                
        "copay":this.policyFeatures.controls['copay'].value,
        "deductable":this.policyFeatures.controls['deductable'].value,
        "sum_incured_sub_limit":this.policyFeatures.controls['sum_incured_sub_limit'].value,
        "claim_diseasewisecapping_condition":this.policyFeatures.controls['claim_diseasewisecapping_condition'].value,

        "claim_copay_condition_1":this.policyFeatures.controls['claim_copay_condition_1'].value,
        "claim_copay_condition_2":this.policyFeatures.controls['claim_copay_condition_2'].value,
        "claim_copay_condition_3":this.policyFeatures.controls['claim_copay_condition_3'].value,
        "claim_copay_condition_4":this.policyFeatures.controls['claim_copay_condition_4'].value,
        "claim_copay_condition_5":this.policyFeatures.controls['claim_copay_condition_5'].value,

        "deductible_1": this.policyFeatures.controls['deductible_1'].value,
        "deductible_2": this.policyFeatures.controls['deductible_2'].value,
        "deductible_3": this.policyFeatures.controls['deductible_3'].value,
        "deductible_4": this.policyFeatures.controls['deductible_4'].value,        
        "deductible_5": this.policyFeatures.controls['deductible_5'].value,        

        "SI_for_parents_1": this.policyFeatures.controls['SI_for_parents_1'].value,
        "SI_for_parents_2": this.policyFeatures.controls['SI_for_parents_2'].value,
        "SI_for_parents_3": this.policyFeatures.controls['SI_for_parents_3'].value,
        "SI_for_parents_4": this.policyFeatures.controls['SI_for_parents_4'].value,
        "SI_for_parents_5": this.policyFeatures.controls['SI_for_parents_5'].value,

        "claim_diseasewisecapping_condition_1": this.policyFeatures.controls['claim_diseasewisecapping_condition_1'].value,
        "claim_diseasewisecapping_condition_2": this.policyFeatures.controls['claim_diseasewisecapping_condition_2'].value,
        "claim_diseasewisecapping_condition_3": this.policyFeatures.controls['claim_diseasewisecapping_condition_3'].value,
        "claim_diseasewisecapping_condition_4": this.policyFeatures.controls['claim_diseasewisecapping_condition_4'].value,        
        "claim_diseasewisecapping_condition_5": this.policyFeatures.controls['claim_diseasewisecapping_condition_5'].value,   
        
        //Metro table
        "Capped_Ailments_metro": this.policyFeatures.controls['Capped_Ailments_metro'].value,
        "Capped_Ailments_nonmetro": this.policyFeatures.controls['Capped_Ailments_nonmetro'].value,

        "JHK_Replacement_metro": this.policyFeatures.controls['JHK_Replacement_metro'].value,
        "JHK_Replacement_nonmetro": this.policyFeatures.controls['JHK_Replacement_nonmetro'].value,        

        "Hernia_metro": this.policyFeatures.controls['Hernia_metro'].value,
        "Hernia_nonmetro": this.policyFeatures.controls['Hernia_nonmetro'].value,
        
        "Hysterectomy_metro": this.policyFeatures.controls['Hysterectomy_metro'].value,
        "Hysterectomy_nonmetro": this.policyFeatures.controls['Hysterectomy_nonmetro'].value,

        "Urinary_SC_metro": this.policyFeatures.controls['Urinary_SC_metro'].value,
        "Urinary_SC_nonmetro": this.policyFeatures.controls['Urinary_SC_nonmetro'].value,
        
        "Angiogram_metro": this.policyFeatures.controls['Angiogram_metro'].value,
        "Angiogram_nonmetro": this.policyFeatures.controls['Angiogram_nonmetro'].value,

        "Cholecystectomy_metro": this.policyFeatures.controls['Cholecystectomy_metro'].value,
        "Cholecystectomy_nonmetro": this.policyFeatures.controls['Cholecystectomy_nonmetro'].value,

        "Appendectomy_metro": this.policyFeatures.controls['Appendectomy_metro'].value,
        "Appendectomy_nonmetro": this.policyFeatures.controls['Appendectomy_nonmetro'].value,

        "CABG_metro": this.policyFeatures.controls['CABG_metro'].value,
        "CABG_nonmetro": this.policyFeatures.controls['CABG_nonmetro'].value,
        
        "Others_metro": this.policyFeatures.controls['Others_metro'].value,
        "Others_nonmetro": this.policyFeatures.controls['Others_nonmetro'].value,      

        "metro_extra_fields": this.metroArr,

        // OPD
        "out_patient_benefits":this.policyFeatures.controls['out_patient_benefits'].value,
        "out_patient_benefits_limit":this.policyFeatures.controls['out_patient_benefits_limit'].value,
        "opd_dental":this.policyFeatures.controls['opd_dental'].value,
        "opd_dental_limit":this.policyFeatures.controls['opd_dental_limit'].value,
        "opd_vision":this.policyFeatures.controls['opd_vision'].value,
        "opd_vision_limit":this.policyFeatures.controls['opd_vision_limit'].value,
        "preventive_health_checkup":this.policyFeatures.controls['preventive_health_checkup'].value,
        "preventive_health_checkup_limit":this.policyFeatures.controls['preventive_health_checkup_limit'].value,
        "doctor_consultation":this.policyFeatures.controls['doctor_consultation'].value,
        "doctor_consultation_limit":this.policyFeatures.controls['doctor_consultation_limit'].value,
        "diagnostics":this.policyFeatures.controls['diagnostics'].value,
        "diagnostics_limit":this.policyFeatures.controls['diagnostics_limit'].value,
        "pharmacy":this.policyFeatures.controls['pharmacy'].value,
        "pharmacy_limit":this.policyFeatures.controls['pharmacy_limit'].value,
        "new_born_vaccination":this.policyFeatures.controls['new_born_vaccination'].value,
        "new_born_vaccination_limit":this.policyFeatures.controls['new_born_vaccination_limit'].value,
        // "benefit_name":this.policyFeatures.controls['benefit_name'].value,
        // "benefit_limit":this.policyFeatures.controls['benefit_limit'].value,
        "benefit_extra_fields": this.benefitArr,

        // Others
        // "hospital_daily_cash":this.policyFeatures.controls['hospital_daily_cash'].value,
        // "hospital_daily_cash_limit":this.policyFeatures.controls['hospital_daily_cash_limit'].value,
        // "hospital_daily_cash_limit_perc":this.policyFeatures.controls['hospital_daily_cash_limit_perc'].value,
				// "hospital_daily_cash_limit_value":this.policyFeatures.controls['hospital_daily_cash_limit_value'].value,

        "organ_donor_cover":this.policyFeatures.controls['organ_donor_cover'].value,
        "min_max_organ_donor_cover":this.policyFeatures.controls['min_max_organ_donor_cover'].value,
        "organ_donor_cover_limit_perc":this.policyFeatures.controls['organ_donor_cover_limit_perc'].value,
				"organ_donor_cover_limit_value":this.policyFeatures.controls['organ_donor_cover_limit_value'].value,

        "hiv_cover":this.policyFeatures.controls['hiv_cover'].value,
        "min_max_hiv_cover":this.policyFeatures.controls['min_max_hiv_cover'].value,
        "hiv_cover_limit_perc":this.policyFeatures.controls['hiv_cover_limit_perc'].value,
				"hiv_cover_limit_value":this.policyFeatures.controls['hiv_cover_limit_value'].value,

        "genetic_disorder":this.policyFeatures.controls['genetic_disorder'].value,
        "min_max_genetic_disorder":this.policyFeatures.controls['min_max_genetic_disorder'].value,
        "genetic_disorder_limit_perc":this.policyFeatures.controls['genetic_disorder_limit_perc'].value,
				"genetic_disorder_limit_value":this.policyFeatures.controls['genetic_disorder_limit_value'].value,

        "lucentis_Avastin_injections":this.policyFeatures.controls['lucentis_Avastin_injections'].value,
        "min_max_lucentis_Avastin_injections":this.policyFeatures.controls['min_max_lucentis_Avastin_injections'].value,
        "lucentis_Avastin_injections_limit_perc":this.policyFeatures.controls['lucentis_Avastin_injections_limit_perc'].value,
				"lucentis_Avastin_injections_limit_value":this.policyFeatures.controls['lucentis_Avastin_injections_limit_value'].value,

        "oral_chemo_therapy":this.policyFeatures.controls['oral_chemo_therapy'].value,
        "min_max_oral_chemo_therapy":this.policyFeatures.controls['min_max_oral_chemo_therapy'].value,
        "oral_chemo_therapy_limit_perc":this.policyFeatures.controls['oral_chemo_therapy_limit_perc'].value,
				"oral_chemo_therapy_limit_value":this.policyFeatures.controls['oral_chemo_therapy_limit_value'].value,

        "cochlear_implant":this.policyFeatures.controls['cochlear_implant'].value,
        "min_max_cochlear_implant":this.policyFeatures.controls['min_max_cochlear_implant'].value,
        "cochlear_implant_limit_perc":this.policyFeatures.controls['cochlear_implant_limit_perc'].value,
				"cochlear_implant_limit_value":this.policyFeatures.controls['cochlear_implant_limit_value'].value,

        "ayush_treatment":this.policyFeatures.controls['ayush_treatment'].value,
        "min_max_ayush_treatment":this.policyFeatures.controls['min_max_ayush_treatment'].value,
        "ayush_treatment_limit_perc":this.policyFeatures.controls['ayush_treatment_limit_perc'].value,
				"ayush_treatment_limit_value":this.policyFeatures.controls['ayush_treatment_limit_value'].value,

        "lasik_treatment":this.policyFeatures.controls['lasik_treatment'].value,
        "min_max_lasik_treatment":this.policyFeatures.controls['min_max_lasik_treatment'].value,
        "lasik_treatment_limit_perc":this.policyFeatures.controls['lasik_treatment_limit_perc'].value,
				"lasik_treatment_limit_value":this.policyFeatures.controls['lasik_treatment_limit_value'].value,

        "psychiatry_mental_illness_treatment":this.policyFeatures.controls['psychiatry_mental_illness_treatment'].value,
        "min_max_psychiatry_mental_illness_treatment":this.policyFeatures.controls['min_max_psychiatry_mental_illness_treatment'].value,
        "psychiatry_mental_illness_treatment_limit_perc":this.policyFeatures.controls['psychiatry_mental_illness_treatment_limit_perc'].value,
				"psychiatry_mental_illness_treatment_limit_value":this.policyFeatures.controls['psychiatry_mental_illness_treatment_limit_value'].value,

        "endoscopic_sinus_surgery":this.policyFeatures.controls['endoscopic_sinus_surgery'].value,
        "min_max_endoscopic_sinus_surgery":this.policyFeatures.controls['min_max_endoscopic_sinus_surgery'].value,
        "endoscopic_sinus_surgery_limit_perc":this.policyFeatures.controls['endoscopic_sinus_surgery_limit_perc'].value,
				"endoscopic_sinus_surgery_limit_value":this.policyFeatures.controls['endoscopic_sinus_surgery_limit_value'].value,

        "internal_congenital":this.policyFeatures.controls['internal_congenital'].value,
        "min_max_internal_congenital":this.policyFeatures.controls['min_max_internal_congenital'].value,
        "internal_congenital_limit_perc":this.policyFeatures.controls['internal_congenital_limit_perc'].value,
				"internal_congenital_limit_value":this.policyFeatures.controls['internal_congenital_limit_value'].value,

        "external_congenital":this.policyFeatures.controls['external_congenital'].value,
        "min_max_external_congenital":this.policyFeatures.controls['min_max_external_congenital'].value,
        "external_congenital_limit_perc":this.policyFeatures.controls['external_congenital_limit_perc'].value,
				"external_congenital_limit_value":this.policyFeatures.controls['external_congenital_limit_value'].value,

        "portability_cover":this.policyFeatures.controls['portability_cover'].value,

        "corporate_buffer":this.policyFeatures.controls['corporate_buffer'].value,
        "corporate_buffer_limit":this.policyFeatures.controls['corporate_buffer_limit'].value,
        "corporate_buffer_isCover":this.policyFeatures.controls['corporate_buffer_isCover'].value,
        "corporate_buffer_family_limit":this.policyFeatures.controls['corporate_buffer_family_limit'].value,

        "pandemic_and_epidemic":this.policyFeatures.controls['pandemic_and_epidemic'].value,

        "autism":this.policyFeatures.controls['autism'].value,
        "min_max_autism":this.policyFeatures.controls['min_max_autism'].value,
        "autism_limit_perc":this.policyFeatures.controls['autism_limit_perc'].value,
				"autism_limit_value":this.policyFeatures.controls['autism_limit_value'].value,

        "bariatric_surgery":this.policyFeatures.controls['bariatric_surgery'].value,
        "min_max_bariatric_surgery":this.policyFeatures.controls['min_max_bariatric_surgery'].value,
        "bariatric_surgery_limit_perc":this.policyFeatures.controls['bariatric_surgery_limit_perc'].value,
				"bariatric_surgery_limit_value":this.policyFeatures.controls['bariatric_surgery_limit_value'].value,

        "cyberknife_treatment":this.policyFeatures.controls['cyberknife_treatment'].value,
        "min_max_cyberknife_treatment":this.policyFeatures.controls['min_max_cyberknife_treatment'].value,
        "cyberknife_treatment_limit_perc":this.policyFeatures.controls['cyberknife_treatment_limit_perc'].value,
				"cyberknife_treatment_limit_value":this.policyFeatures.controls['cyberknife_treatment_limit_value'].value,

        "gender_affirmation":this.policyFeatures.controls['gender_affirmation'].value,
        "min_max_gender_affirmation":this.policyFeatures.controls['min_max_gender_affirmation'].value,
        "gender_affirmation_limit_perc":this.policyFeatures.controls['gender_affirmation_limit_perc'].value,
				"gender_affirmation_limit_value":this.policyFeatures.controls['gender_affirmation_limit_value'].value,

        "hormonal_therapy_for_cancer":this.policyFeatures.controls['hormonal_therapy_for_cancer'].value,
        "min_max_hormonal_therapy_for_cancer":this.policyFeatures.controls['min_max_hormonal_therapy_for_cancer'].value,
        "hormonal_therapy_for_cancer_limit_perc":this.policyFeatures.controls['hormonal_therapy_for_cancer_limit_perc'].value,
				"hormonal_therapy_for_cancer_limit_value":this.policyFeatures.controls['hormonal_therapy_for_cancer_limit_value'].value,

        "prosthetic_devices":this.policyFeatures.controls['prosthetic_devices'].value,
        "min_max_prosthetic_devices":this.policyFeatures.controls['min_max_prosthetic_devices'].value,
        "prosthetic_devices_limit_perc":this.policyFeatures.controls['prosthetic_devices_limit_perc'].value,
				"prosthetic_devices_limit_value":this.policyFeatures.controls['prosthetic_devices_limit_value'].value,

        "robotic_surgery":this.policyFeatures.controls['robotic_surgery'].value,
        "min_max_robotic_surgery":this.policyFeatures.controls['min_max_robotic_surgery'].value,
        "robotic_surgery_limit_perc":this.policyFeatures.controls['robotic_surgery_limit_perc'].value,
				"robotic_surgery_limit_value":this.policyFeatures.controls['robotic_surgery_limit_value'].value,

        "sexually_transmitted_diseases":this.policyFeatures.controls['sexually_transmitted_diseases'].value,
        "min_max_sexually_transmitted_diseases":this.policyFeatures.controls['min_max_sexually_transmitted_diseases'].value,
        "sexually_transmitted_diseases_limit_perc":this.policyFeatures.controls['sexually_transmitted_diseases_limit_perc'].value,
				"sexually_transmitted_diseases_limit_value":this.policyFeatures.controls['sexually_transmitted_diseases_limit_value'].value,

        "sleep_apnea":this.policyFeatures.controls['sleep_apnea'].value,
        "min_max_sleep_apnea":this.policyFeatures.controls['min_max_sleep_apnea'].value,
        "sleep_apnea_limit_perc":this.policyFeatures.controls['sleep_apnea_limit_perc'].value,
				"sleep_apnea_limit_value":this.policyFeatures.controls['sleep_apnea_limit_value'].value,

        "stem_cell_treatment":this.policyFeatures.controls['stem_cell_treatment'].value,
        "min_max_stem_cell_treatment":this.policyFeatures.controls['min_max_stem_cell_treatment'].value,
        "stem_cell_treatment_limit_perc":this.policyFeatures.controls['stem_cell_treatment_limit_perc'].value,
				"stem_cell_treatment_limit_value":this.policyFeatures.controls['stem_cell_treatment_limit_value'].value,

        "others_extra_fields": this.othersArr,

        // Ambulance
        "road_ambulance":this.policyFeatures.controls['road_ambulance'].value,
        "min_max_road_ambulance":this.policyFeatures.controls['min_max_road_ambulance'].value,
        "road_ambulance_limit_perc":this.policyFeatures.controls['road_ambulance_limit_perc'].value,
				"road_ambulance_limit_value":this.policyFeatures.controls['road_ambulance_limit_value'].value,
       
        "cardiac_ambulance":this.policyFeatures.controls['cardiac_ambulance'].value,
        "min_max_cardiac_ambulance":this.policyFeatures.controls['min_max_cardiac_ambulance'].value,
        "cardiac_ambulance_limit_perc":this.policyFeatures.controls['cardiac_ambulance_limit_perc'].value,
				"cardiac_ambulance_limit_value":this.policyFeatures.controls['cardiac_ambulance_limit_value'].value,    

        "air_ambulance":this.policyFeatures.controls['air_ambulance'].value,
        "min_max_air_ambulance":this.policyFeatures.controls['min_max_air_ambulance'].value,
        "air_ambulance_limit_perc":this.policyFeatures.controls['air_ambulance_limit_perc'].value,
				"air_ambulance_limit_value":this.policyFeatures.controls['air_ambulance_limit_value'].value,   

        "metrol_1":this.policyFeatures.controls['metrol_1'].value,
        "metrol_2":this.policyFeatures.controls['metrol_2'].value,
        "metrol_3":this.policyFeatures.controls['metrol_3'].value,
        "metrol_4":this.policyFeatures.controls['metrol_4'].value,
        "metrol_5":this.policyFeatures.controls['metrol_5'].value,

        // Claim Settlement
        // "settlement_type":this.policyFeatures.controls['settlement_type'].value

      }

      this.policyDetailsService.policyDetailsCreate_third(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              // if(this.gmc_policy_check == "No"){
              //   this.displayStyleThankyou = "block";
              //   var flag = this;
              //   setTimeout(function() {
              //     flag.route.navigate(['/Client-dashboard']);
              //   }, 3000);
              // }else{

              // }

              this.benefit_id = result.data.benefit_id;

              // this.validmsg = result.message;              
              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');              

            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

    }

    onsubmit_fourth(){      

    var postdata;

    if(this.gmc_policy_check == 'Yes'){

      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_policy_id": this.client_product_policy_id,
        "existing_policy_check":this.gmc_policy_check,

        "policy_document": this.policy_document,
        "member_data": this.member_data,
        "claims_in_current_year" : this.documentUpload.controls['claims_in_current_year'].value,
        "claims_in_current_year_upload_file": this.claims_in_current_year,
        "previous_two_years": this.documentUpload.controls['previous_two_years'].value,
        "previous_year_one": this.previous_year_one,
        "previous_year_two": this.previous_year_two
      }

    }else{

      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_policy_id": this.client_product_policy_id,
        "existing_policy_check":this.gmc_policy_check,

        "member_data": this.member_data        
      }

    }

      this.policyDetailsService.policyDetailsCreate_fourth(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              this.proposal_number_show = result.data.proposal_number;
              this.displayStyleThankyou = "block";

              $('#sticky-footer').css('opacity', '0');
              $('.navbar').css('opacity', '0');
              $('#sidebar-wrapper').css('z-index', '0');

              // $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');

              // var flag = this;
              // setTimeout(function() {
              //   flag.route.navigate(['/Client-dashboard']);
              // }, 3000);


            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

    }

    closePopup() {

      var val = $('#plan_name').val();

      if(val == ''){
        
        $('#plan_name_error').css('display','block');
        $('#plan_name').addClass('control-red');

      }else{
        
        $('#plan_name_error').css('display','none');
        this.displayStyleThankyou = "none";

        $('#sticky-footer').css('opacity', '1');
        $('.navbar').css('opacity', '1');
        $('#sidebar-wrapper').css('z-index', '500');

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'),
          "client_product_policy_id": this.client_product_policy_id,
          "plan_name": val
        }
        
        this.policyDetailsService.policyDetails_planName(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              // this.displayStyleThankyou = "block";

              // $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');

              // this.route.navigate(['/select-product']);


            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

        this.route.navigate(['/select-product']);        
      }
      
    }

    show_cross_combination(val: any){
      if(val == "Parent only"){
        $(".Cross_Combination_status").css('display', 'none');
      }else{
        $(".Cross_Combination_status").css('display', 'contents');
      }
    }

    do_you_want_maternity(val:any){

      $('#do_you_want_maternity_error').css('display', 'none');

      if(val == "yes"){
        $(".do_you_want_maternity_yesdiv").css('display', 'block');
        $(".do_you_want_maternity_nodiv").css('display', 'none');
      }else{
        $(".do_you_want_maternity_yesdiv").css('display', 'none');
        $(".do_you_want_maternity_nodiv").css('display', 'block');
      }
    }

    show_metro_table(val:any){
      if(val == "show"){
        $(".metro_table").css('display', 'block');
      }else{
        $(".metro_table").css('display', 'none');
      }
    }

    check_limit_toggle(val:any, text:any){

      if(text == 'yes'){
        $('.' + val + '_limit_value').css('display','flex');
      }else{
        $('.' + val + '_limit_value').css('display','none');
      }

    }

    changepage(e: any){
      
      var val = e.target.value;

      localStorage.setItem('plan_page_flag', val);

      if(val == 1){
        this.route.navigate(['/policy-details']);
      }
      if(val == 2){
        this.route.navigate(['/topup-policy-details']);
      }

    }    

    Prog(val:any, text:any){
      let id = "#Prog" + text;
      let value = "#Value" + text;
      let progressBar:any = document.querySelector(id);
      let valueContainer:any = document.querySelector(value);
      let progressValue = 0;
      let progressEndValue = val;
      let speed = 30;

      if(val == 0){

        let progress = setInterval(() => {
          // progressValue++;
          valueContainer.textContent = `${progressValue}%`;
          progressBar.style.background = `conic-gradient(
              #58D68C ${progressValue * 3.6}deg,
              #DEDEDE ${progressValue * 3.6}deg
          )`;
          if (progressValue == progressEndValue) {
            clearInterval(progress);
          }
        }, speed);

      }else{

        let progress = setInterval(() => {
          progressValue++;
          valueContainer.textContent = `${progressValue}%`;
          progressBar.style.background = `conic-gradient(
              #58D68C ${progressValue * 3.6}deg,
              #DEDEDE ${progressValue * 3.6}deg
          )`;
          if (progressValue == progressEndValue) {
            clearInterval(progress);
          }
        }, speed);

      }
      
    }


    purple_white_change(id:any, val:any){

      if(val == 'yes'){

        $('#'+id+'_'+val).css('background', '#4e0099');
        $('#'+id+'_'+val).css('color', '#fff');
        $('#'+id+'_no').css('background', '#f1f1f1');
        $('#'+id+'_no').css('color', '#000');

      }else{

        $('#'+id+'_'+val).css('background', '#4e0099');
        $('#'+id+'_'+val).css('color', '#fff');
        $('#'+id+'_yes').css('background', '#f1f1f1');
        $('#'+id+'_yes').css('color', '#000');

      }

    }

    green_red_change(id:any, val:any){

      if(val == 'yes'){

        $('#'+id+'_'+val).css('background', '#217e2b');
        $('#'+id+'_'+val).css('color', '#fff');
        $('#'+id+'_no').css('background', '#f1f1f1');
        $('#'+id+'_no').css('color', '#000');

      }else{

        $('#'+id+'_'+val).css('background', '#ff4052');
        $('#'+id+'_'+val).css('color', '#fff');
        $('#'+id+'_yes').css('background', '#f1f1f1');
        $('#'+id+'_yes').css('color', '#000');

      }

    }

    red_green_change(id:any, val:any){

      if(val == 'no'){
        
        $('#'+id+'_'+val).css('background', '#217e2b');
        $('#'+id+'_'+val).css('color', '#fff');
        $('#'+id+'_yes').css('background', '#f1f1f1');
        $('#'+id+'_yes').css('color', '#000');        

      }else{

        $('#'+id+'_'+val).css('background', '#ff4052');
        $('#'+id+'_'+val).css('color', '#fff');
        $('#'+id+'_no').css('background', '#f1f1f1');
        $('#'+id+'_no').css('color', '#000');        

      }

    }

}
