<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
        
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
                
            <h3 class="bs-title" *ngIf="addflag == 0">Master <span class="spanbs-title">Modules</span>
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Master Module</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Master Module</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            
            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2">
            
            <!-- List Table -->
            <div *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Module Name</th>
                        <th>Module URL</th>
                        <th>Module Status</th>
                        <th>View Sub Modules</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['module_name']}}</td>
                            <td>{{group['module_url']}}</td>
                            <td>
                                <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                                <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                            </td>
                            <td style="width: 15%" align="center">
                                <button type="button" class="btn btn-warning" (click)="viewuser(group)" role="button" title="View User" style="font-size: 12px;">View</button>
                            </td>
                            <td style="width: 15%" align="center">                    
                                <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                                <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                                <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">                                        
                <form [formGroup]="AddInfo">                    
                    <div class="row">

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="module_name">Module Name *</label>
                                <input type="text" class="form-control" formControlName="module_name" id="module_name" placeholder="Module Name"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.module_name.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.module_name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.module_name.errors.required">
                                        Module Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                            

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="module_url">Module URL *</label>
                                <input type="text" class="form-control" formControlName="module_url" id="module_url" placeholder="Module URL"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.module_url.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.module_url.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.module_url.errors.required">
                                        Module URL is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="module_content">Module Content *</label>
                                <input type="text" class="form-control" formControlName="module_content" id="module_content" placeholder="Module Content"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.module_content.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.module_content.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.module_content.errors.required">
                                        Module Content is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                

                        <!-- <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="status">Status</label>
                                <select class="form-select" formControlName="status" id="status">
                                    <option value="" selected>Select</option>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>                       
                            </div>
                        </div> -->

                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                        </div>
                    </div>            
                </form>                       
            </div>    

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">                                        
                <form [formGroup]="EditInfo">                    
                    <div class="row">

                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="module_name_edit">Model Name *</label>
                                <input type="text" class="form-control" formControlName="module_name_edit" id="module_name_edit" placeholder="Module Name"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.module_name_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.module_name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.module_name_edit.errors.required">
                                        Model Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                            

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="module_url_edit">Model URL *</label>
                                <input type="text" class="form-control" formControlName="module_url_edit" id="module_url_edit" placeholder="Model URL"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.module_url_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.module_url_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.module_url_edit.errors.required">
                                        Model URL is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="module_content_edit">Module Content *</label>
                                <input type="text" class="form-control" formControlName="module_content_edit" id="module_content_edit" placeholder="Module Content"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.module_content_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.module_content_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.module_content_edit.errors.required">
                                        Module Content is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                

                        <!-- <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="status_edit">Status</label>
                                <select class="form-select" formControlName="status_edit" id="status_edit">
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>                       
                            </div>
                        </div> -->               

                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>                    
                        </div>
                    </div>            
                </form>                       
            </div>
            
            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>
        <app-footer></app-footer>
    </div>      
</div>