<div id="stepper1" class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <div class="bs-stepper-header">
        <div class="step" data-target="#test-l-1">
            <button class="step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">Basic Details</span>
            </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#test-l-2">
            <button class="step-trigger">
                <span class="bs-stepper-circle">2</span>
                <span class="bs-stepper-label">Contact</span>
            </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#test-l-3">
            <button class="step-trigger">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Company Details</span>
            </button>
        </div>
    </div>
    <div class="bs-stepper-content">        

            <div id="test-l-1" class="content">
                <form [formGroup]="basicInfo">
                <h3 class="bs-title">Basic Details </h3>
                <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                    <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                </div>
                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                </div>
                <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Company Info</h5>
                        <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label class="bs-form-label" for="company_name">Company Name *</label>
                            <input type="text" class="form-control" formControlName="company_name" id="company_name" placeholder="Company Name" 
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.company_name.errors}"
                            required />
                            <ng-container
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_name.errors">
                            <p class="error"
                              *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_name.errors.required">
                              Company name is required
                            </p>
                          </ng-container>
                        </div>                                               

                        <div class="form-group mt-3">
                            <label class="bs-form-label" for="group_company_name">Group Company Name</label>
                            <input type="text" class="form-control" formControlName="group_company_name" id="group_company_name" placeholder="Group Company Name" />
                        </div>
                        <div class="row">
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="industry">Industry *</label>
                                <select class="form-select" formControlName="industry" id="industry" 
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.industry.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    
                                    <optgroup label="AGRICULTURE BASED">
                                        <option *ngFor="let title of Array1" [value]="title.industry_name">
                                        {{title.industry_name}}
                                        </option>                        
                                    </optgroup>
                                
                                    <optgroup label="AUTO & ANCILLIARY">
                                        <option *ngFor="let title of Array2" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="CONSUMER DURABLES">
                                        <option *ngFor="let title of Array3" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="FINANCIAL & BANKING">
                                        <option *ngFor="let title of Array4" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="FMCG FAST MOVING CONSUMER GOODS">
                                        <option *ngFor="let title of Array5" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="HEALTH CARE">
                                        <option *ngFor="let title of Array6" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="INFORMATION TECHNOLOGY & COMMUNICATION">
                                        <option *ngFor="let title of Array7" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>
                                
                                    <optgroup label="METAL & MINNING">
                                        <option *ngFor="let title of Array8" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="OTHER INDUSTRIES">
                                        <option *ngFor="let title of Array9" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="POWER">
                                        <option *ngFor="let title of Array10" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="RETAILING">
                                        <option *ngFor="let title of Array11" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="SERVICE SECTOR">
                                        <option *ngFor="let title of Array12" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>

                                    <optgroup label="TEXTILES">
                                        <option *ngFor="let title of Array13" [value]="title.industry_name">
                                            {{title.industry_name}}
                                        </option>
                                    </optgroup>  
                                    

                                </select>
                                <ng-container
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.industry.errors">
                                    <p class="error">
                                    Industry is required
                                    </p>
                                </ng-container>
                            </div>                            
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="head_quarter_country">Head Quarter Country</label>
                                <select class="form-select" formControlName="head_quarter_country" id="head_quarter_country"
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.head_quarter_country.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                </select>
                                <ng-container
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.head_quarter_country.errors">
                                    <p class="error">
                                        Head Quarter Country is required
                                    </p>
                                </ng-container>
                            </div>                            
                            <div class="col-sm-6 form-group mt-3">
                                <div class="mb-2 bs-form-label">Company Type * <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>
                                <input type="radio" class="form-radio" value="yes" formControlName="MNC" id="MNC" /> <span class="bs-radio-label"> MNC </span>
                                &nbsp; 
                                <input type="radio" class="form-radio" value="no" formControlName="MNC" id="MNC" /> <span class="bs-radio-label"> Non-MNC </span>
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <div class="mb-2 bs-form-label">Company Established * <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>
                                <input type="radio" class="form-radio" value="startup" formControlName="company_year_of_inception" id="company_year_of_inception" checked /> <span class="bs-radio-label"> Startup ( < 5y) </span>
                                &nbsp; <input type="radio" class="form-radio" value="established" formControlName="company_year_of_inception" id="company_year_of_inception" /> <span class="bs-radio-label"> Established ( > 5y) </span>
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="employee_headcount">Company Headcount *</label>
                                <select class="form-select" formControlName="employee_headcount" id="employee_headcount" 
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.employee_headcount.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Upto 100 (usually lifes will be multiply by 4 or 5)">Upto 100 (usually lifes will be multiply by 4 or 5)</option>
                                    <option value="101 to 250">101 to 250</option>
                                    <option value="251 to 500">251 to 500</option>
                                    <option value="501 to 1000">501 to 1000</option>
                                    <option value="1001 to 2000">1001 to 2000</option>
                                    <option value="2001 to 5000">2001 to 5000</option>
                                    <option value="5001 to 10000">5001 to 10000</option>
                                    <option value="10001 to 25000">10001 to 25000</option>
                                    <option value="25001 and more">25001 and more</option>
                                </select>                                
                                <ng-container
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employee_headcount.errors">
                                    <p class="error">
                                    Company Headcount is required
                                    </p>
                                </ng-container>
                            </div> 
                            <!-- <div class="col-sm-6 form-group mt-3">
                                <div class="mb-2 bs-form-label">Unicorn <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>
                                <input type="radio" class="form-radio" value="yes" formControlName="unicorn" id="unicorn" /> <span class="bs-radio-label"> Yes </span>
                                &nbsp; <input type="radio" class="form-radio" value="no" formControlName="unicorn" id="unicorn" /> <span class="bs-radio-label"> No </span> 
                            </div>    -->
                        </div>                                                                                                
                    </div>
                    <hr class="my-3">
                    <div>
                        <button (click)="next(1)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                    </div>
                </div> 
                </form>               
            </div>

            <div id="test-l-2" class="content">
                <form [formGroup]="contactInfo">
                <h3 class="bs-title">Contact</h3>
                <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Contact details</h5>
                        <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label class="bs-form-label" for="contact_person">Contact Person *</label>
                            <input type="text" class="form-control" formControlName="contact_person" id="contact_person" placeholder="John Doe" 
                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.contact_person.errors}"
                            required />
                            <ng-container
                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.contact_person.errors">
                                <p class="error"
                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.contact_person.errors.required">
                                Contact Person is required
                                </p>
                            </ng-container>
                        </div>                        
                        <div class="row">
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="email_address">Email</label>
                                <input type="email" class="form-control" formControlName="email_address" id="email_address" placeholder="abc@xyz.com" />
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="contact_phone_code">Mobile No *</label>
                                <div class="input-group mb-3">                                    
                                    <input type="text" class="form-control" formControlName="contact_number" id="contact_number" placeholder="1223 4455 7878" (keypress)="isNumber($event)" />                                
                                </div>
                            </div>             
                            <div class="col-sm-6 form-group">
                                <label class="bs-form-label" for="landline">Landline</label>
                                <input type="email" class="form-control" formControlName="landline" id="landline" placeholder="234 098 783" (keypress)="isNumber($event)" />
                            </div>                 
                        </div>                                                                                                
                    </div>
                    <hr class="my-3">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Registered Address</h5>
                        <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label class="bs-form-label" for="registered_address">Address Line 1 *</label>
                            <textarea class="form-control" formControlName="registered_address" id="registered_address" rows="3" 
                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.registered_address.errors}"
                            >
                            </textarea>
                            <!-- <input type="text" class="form-control" formControlName="addressline1" id="addressline1" placeholder="House number, House name" 
                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.addressline1.errors}"
                            required /> -->
                            <ng-container
                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.registered_address.errors">
                                <p class="error"
                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.registered_address.errors.required">
                                Address is required
                                </p>
                            </ng-container>
                        </div>
                        <!-- <div class="form-group mt-3">
                            <label class="bs-form-label" for="addressline1_1">Address Line 1 *</label>
                            <input type="text" class="form-control" formControlName="addressline1_1" id="addressline1_1" placeholder="Street name, Village name" 
                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.addressline1_1.errors}"
                            required />
                            <ng-container
                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.addressline1_1.errors">
                                <p class="error"
                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.addressline1_1.errors.required">
                                Address is required
                                </p>
                            </ng-container>
                        </div>                         -->
                        <div class="row">     
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="zipcode">Zip / Postal Code *</label>
                                <input type="text" class="form-control" formControlName="zipcode" id="zipcode" placeholder="123456" (keyup)="isNumber_tab2($event)"
                                [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.zipcode.errors}"
                                required />
                                <ng-container
                                    *ngIf="contactInfoSubmitted && getContactInformationFormControls.zipcode.errors">
                                    <p class="error"
                                    *ngIf="contactInfoSubmitted && getContactInformationFormControls.zipcode.errors.required">
                                    Zip / Postal Code is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="city">City</label>
                                <input type="text" class="form-control" formControlName="city" id="city" placeholder="City Name" />
                            </div>                                                   
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="state">State</label>
                                <input type="text" class="form-control" formControlName="state" id="state" placeholder="State" />
                                <!-- <select class="form-select" formControlName="state" id="state">
                                    <option value="0">Select</option>
                                    <option *ngFor="let state of statesarr1" [value]="state">{{ state }}</option>
                                </select> -->
                            </div>                                                   
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="country">Country *</label>
                                <input type="text" class="form-control" formControlName="country" id="country" placeholder="Country" 
                                [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.country.errors}"
                                required />
                                <!-- <select class="form-select" formControlName="country" id="country" (change)="loadstate();">
                                    <option selected>Select</option>
                                    <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                </select> -->
                                <ng-container
                                    *ngIf="contactInfoSubmitted && getContactInformationFormControls.country.errors">
                                    <p class="error"
                                    *ngIf="contactInfoSubmitted && getContactInformationFormControls.country.errors.required">
                                    Country is required
                                    </p>
                                </ng-container>
                            </div>                                   
                        </div>                                                                                                
                    </div>
                    <hr class="my-4 mx-2">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Communication Address</h5>
                        <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label class="bs-form-label" for="company_person">Same as Registered Address</label>
                        </div>
                        <div>
                            <input type="radio" class="form-radio" value="yes_as_register" name="communication_address" formControlName="communication_address" (click)="ifradioclicked()" id="yes_as_register" /> <span class="bs-radio-label"> Yes </span> &nbsp;
                            <input type="radio" class="form-radio" value="no_as_register" name="communication_address" formControlName="communication_address" (click)="ifradioclicked()" id="no_as_register" /> <span class="bs-radio-label"> No </span>
                        </div>
                        <div id="yes_or_no_div" class="my-3" style="display: none;">
                            <div class="form-group">
                                <label class="bs-form-label" for="c_address">Address Line 1 *</label>
                                <!-- <input type="text" class="form-control" formControlName="new_addressline1" id="new_addressline1" placeholder="House number, House name" 
                               /> -->
                               <textarea class="form-control" formControlName="c_address" id="c_address" rows="3">
                               </textarea>
                            </div>
                            <!-- <div class="form-group mt-3">
                                <label class="bs-form-label" for="new_addressline1_1">Address Line 1 *</label>
                                <input type="text" class="form-control" formControlName="new_addressline1_1" id="new_addressline1_1" placeholder="Street name, Village name" 
                                 />
                                
                            </div>                         -->
                            <div class="row">
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="c_zipcode">Zip / Postal Code *</label>
                                    <input type="text" class="form-control" formControlName="c_zipcode" id="c_zipcode" placeholder="123456" (keyup)="isNumber_addr_repeat($event)" />                                    
                                </div> 
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="c_city">City</label>
                                    <input type="text" class="form-control" formControlName="c_city" id="c_city" placeholder="City Name" />
                                </div>                                                           
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="c_state">State</label>
                                    <input type="text" class="form-control" formControlName="c_state" id="c_state" placeholder="State" />

                                    <!-- <select class="form-select" formControlName="c_state" id="c_state">
                                        <option value="0">Select</option>
                                        <option *ngFor="let state of statesarr2" [value]="state">{{ state }}</option>
                                    </select> -->
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="c_country">Country *</label>
                                    <input type="text" class="form-control" formControlName="c_country" id="c_country" placeholder="Country" />

                                    <!-- <select class="form-select" formControlName="c_country" id="c_country" (change)="loadstate2()"
                                     >
                                        <option selected>Select</option>
                                        <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                    </select> -->
                                    
                                </div>                                                                                              
                            </div>
                        </div>                                                                                         
                    </div>
                    <div>
                        <button (click)="next(2)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>
                </form>
            </div>

            <div id="test-l-3" class="content mt-3">
                <form [formGroup]="companyInfo">
                <h3 class="bs-title">Company Details</h3>
                <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Revenue</h5>
                        <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- <div class="form-group">
                                    <label class="bs-form-label" for="employee_headcount">Company Headcount *</label>
                                    <input type="text" class="form-control" formControlName="employee_headcount" id="employee_headcount" placeholder="Company Headcount" (keypress)="isNumber($event)"
                                    [ngClass]="{'control-red': companyInfoSubmitted && getCompanyInformationFormControls.employee_headcount.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="companyInfoSubmitted && getCompanyInformationFormControls.employee_headcount.errors">
                                        <p class="error"
                                        *ngIf="companyInfoSubmitted && getCompanyInformationFormControls.employee_headcount.errors.required">
                                        Company Headcount is required
                                        </p>
                                    </ng-container>
                                </div> -->
                            </div>
                            <div class="col-sm-12 form-group">
                                <label class="bs-form-label" for="turnover">Turnover / Revenue</label>
                                <select class="form-select" id="turnover" formControlName="turnover">
                                    <option value="" selected>Select</option>
                                    <option value="Upto 10 Cr.">Upto 10 Cr.</option>
                                    <option value="11 Cr to 100 Cr.">11 Cr to 100 Cr.</option>
                                    <option value="101 Cr to 250 Cr.">101 Cr to 250 Cr.</option>
                                    <option value="251 Cr to 1,000 Cr.">251 Cr to 1,000 Cr.</option>
                                    <option value="1,001 Cr to 5,000 Cr.">1,001 Cr to 5,000 Cr.</option>
                                    <option value="5,001 Cr to 10,000 Cr.">5,001 Cr to 10,000 Cr.</option>
                                    <option value="Above 10,000 Cr.">Above 10,000 Cr.</option>
                                </select>
                                <!-- <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                                                        
                                    </div>
                                    <input type="text" class="form-control" formControlName="company_turnover_val" id="company_turnover_val" placeholder="1223 4455 7878" (keypress)="isNumber($event)">
                                </div> -->
                            </div>                            
                        </div>                                                                                                                        
                    </div>
                    <hr class="my-3">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Company Details</h5>
                        <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label class="bs-form-label" for="type_of_firm">Company Type *</label>
                            <select class="form-select" formControlName="type_of_firm" id="type_of_firm" (change)="getcompanytype()" 
                            [ngClass]="{'control-red': companyInfoSubmitted && getCompanyInformationFormControls.type_of_firm.errors}"
                            required>
                                <option value="" selected>Select</option>
                                <option value="Private Limited Company">Private Limited Company</option>
                                <option value="Public Limited Company">Public Limited Company</option>
                                <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                                <option value="Partnerships Company">Partnerships Company</option>
                                <option value="One Person Company">One Person Company</option>
                                <option value="Sole Proprietorship">Sole Proprietorship</option>
                                <option value="Section 8 Company">Section 8 Company</option>

                            </select>
                            <ng-container
                                *ngIf="companyInfoSubmitted && getCompanyInformationFormControls.type_of_firm.errors">
                                <p class="error">
                                Company Type is required
                                </p>
                            </ng-container>
                        </div>

                        <div id="listeddiv" style="display:none">
                            <div class="form-group mt-3">
                                <label class="bs-form-label" for="company_person">Listed / Unlisted</label>
                            </div>
                            <div>
                                <input type="radio" class="form-radio" value="yes" formControlName="listed" name="listed" id="yes_listed" /> <span class="bs-radio-label"> Yes </span> &nbsp;
                                <input type="radio" class="form-radio" value="no" formControlName="listed" name="listed" id="no_unlisted" /> <span class="bs-radio-label"> No </span>
                            </div>                                                  
                        </div>
                                            
                        <div class="row">                            
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="company_registration_no">Company Registration Number</label>
                                <input type="text" class="form-control" formControlName="company_registration_no" id="company_registration_no" placeholder="ABDDHJH3647264" />
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="GST">GST</label>
                                <input type="text" class="form-control" formControlName="GST" id="GST" maxlength="15" placeholder="ABDDHJH3647264" />
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="PAN">PAN</label>
                                <input type="text" class="form-control" formControlName="PAN" id="PAN" maxlength="10" placeholder="ABDDHJH" />
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <label class="bs-form-label" for="TAN">TAN</label>
                                <input type="text" class="form-control" formControlName="TAN" id="TAN" maxlength="10" placeholder="123456" />
                            </div>                              
                        </div>                                                                                                
                    </div>
                    <hr class="my-3">
                    <div>
                        <button (click)="next(3);" class="btn btn-primary" style="float: right;">Finish</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>
                </form>
            </div>        
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Thank You !</h4>
                </div>                
                <div class="modal-body">                                
                    Your information is stored successfully.
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                </div>
            </div>
        </div>
    </div>

</div>