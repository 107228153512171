<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

            <h3 class="bs-title">Broker <span class="spanbs-title">User Management</span>
                <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button>
            </h3>
            <hr>

            <!-- Add -->
            <div>                                        
                        
                <form [formGroup]="AddInfo">                    
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="name">Name *</label>
                                <input type="text" class="form-control" formControlName="name" id="name" placeholder="Name"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                                                                                                                          

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email">Email *</label>
                                <input type="text" class="form-control" formControlName="email" id="email" placeholder="email"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.email.errors}"
                                required />                        
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.email.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.email.errors.required">
                                        Email is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="mobile">Contact *</label>
                                <input type="text" class="form-control" formControlName="mobile" id="mobile" placeholder="contact" (keypress)="isNumber($event)" maxlength="10"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.mobile.errors}"
                                required /> 
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.mobile.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.mobile.errors.required">
                                        Contact is required
                                    </p>
                                </ng-container>                       
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="role">Role *</label>
                                <select class="form-select" formControlName="role" id="role" 
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.role.errors}"
                                required>
                                    <option value="" selected>Select Role</option>
                                    <option value="4">Broker admin</option>
                                    <option value="5">Broker user</option>
                                    <!-- <option *ngFor="let title of InfoArray_Role" [value]="title.role_id">
                                        {{title.role_name}}
                                    </option> -->
                                </select>        
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.role.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.role.errors.required">
                                        Role is required
                                    </p>
                                </ng-container>               
                            </div>
                        </div>  

                        <div class="col-sm-1" align="center">
                            <button type="button" class="btn btn-primary mt-4" (click)="onSubmitAdd()" role="button">Save</button>                    
                        </div>
                    </div>            
                </form>                   

            </div>
            
            <!-- List  -->
            <div class="table-responsive mt-4">
                <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Username</th>
                        <th>Type</th>
                        <th>Onboarded Date</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['username']}}</td>
                        <td>{{group['type_name']}}</td>                   
                        <td>{{group['added_date']}}</td>                   
                        <td style="width: 15%" align="center">
                            <!-- <img src="../../../assets/img/common icons/Add.png" id="Add" (click)="Add(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Add User" /> -->
                            <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="ml-4" title="Delete" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Edit -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_edit}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Edit Broker User</h4>
                    </div>
                    <div class="modal-body">
                        
                        <form [formGroup]="EditInfo">                    
                            <div class="row">
                
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="name_edit">Name *</label>
                                        <input type="text" class="form-control" formControlName="name_edit" id="name_edit" placeholder="Name"
                                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.name_edit.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors">
                                            <p class="error"
                                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors.required">
                                                Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>                                                                                                                                          
                
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="email_edit">Email *</label>
                                        <input type="text" class="form-control" formControlName="email_edit" id="email_edit" placeholder="email" readonly
                                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.email_edit.errors}"
                                        required />                        
                                        <ng-container
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_edit.errors">
                                            <p class="error"
                                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_edit.errors.required">
                                                Email is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                            
                                <div class="col-sm-6 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="mobile_edit">Contact *</label>
                                        <input type="text" class="form-control" formControlName="mobile_edit" id="mobile_edit" placeholder="contact" (keypress)="isNumber($event)" maxlength="10"
                                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.mobile_edit.errors}"
                                        required /> 
                                        <ng-container
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.mobile_edit.errors">
                                            <p class="error"
                                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.mobile_edit.errors.required">
                                                Contact is required
                                            </p>
                                        </ng-container>                       
                                    </div>
                                </div>
                
                                <div class="col-sm-6 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="role_edit">Role *</label>
                                        <select class="form-select" formControlName="role_edit" id="role_edit" 
                                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.role_edit.errors}"
                                        required>
                                            <!-- <option value="" selected>Select Role</option> -->
                                            <option [value]="4">Broker admin</option>
                                            <option [value]="5">Broker user</option>
                                            <!-- <option *ngFor="let title of InfoArray_Role" [value]="title.role_id">
                                                {{title.role_name}}
                                            </option> -->
                                        </select>        
                                        <ng-container
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.role_edit.errors">
                                            <p class="error"
                                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.role_edit.errors.required">
                                                Role is required
                                            </p>
                                        </ng-container>               
                                    </div>
                                </div>  
                                    
                            </div>            
                        </form>
        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" (click)="onSubmitEdit()" role="button">Save</button> 
                        <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>                   
                    </div>
                    </div>
                </div>
            </div>

            <!-- Delete -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete <span class="spanbs-title">Broker User</span></h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>  
</div>