<div id="users" class="bs-stepper bg-white my-4 mb-5 rounded p-3">   
    
        <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

        <h3 class="bs-title">Survey Manager
            <button type="button" class="btn btn-success" (click)="openPopupAdd()" role="button" style="float:right;">Add New</button>
            <button type="button" class="btn btn-success" (click)="openPopupupload()" role="button" style="float:right; margin-right: 10px;">Document Upload</button>
            <button type="button" class="btn btn-success" (click)="onSubmitsurvey()" role="button" style="float:right; margin-right: 10px;">Send Survey</button>
        </h3>
        <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
            <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
            <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <hr>        

    <div class="table-responsive mt-4">
        <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>
                    <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()" /> <strong></strong>
                </th>
                <th>Username</th>
                <th>Type</th>
                <th>Plan</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
             <tr *ngFor="let group of usersArray; let i = index;">
                    <td style="width:5%">{{i+1}}</td>
                    <td>
                        <input type="checkbox" [(ngModel)]="group.isSelected" name="list_name" value="{{group.user_id}}" (change)="isAllSelected()" />
                    </td>
                   <td>{{group['username']}}</td>
                   <td>{{group['type_name']}}</td>
                   <td>{{group['plan_name']}}</td>
                   <td style="width: 15%" align="center">
                    <img src="../../assets/img/common icons/edit.png" id="edituser" (click)="openPopup(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:15px; margin-left: 5px;" title="Soft Delete" />
                    <!-- <img src="../../assets/img/common icons/send-mail.png" id="sendsurvey" (click)="sendsurvey(group)" role="button" class="img-fluid" style="width:24px; margin-left: 5px;" title="Send Survey" /> -->
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Edit User</h4>
            </div>
            <div class="modal-body">
                
                <form [formGroup]="usersEdit">                    
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_email">Email *</label>
                                <input type="edit_email" class="form-control" formControlName="edit_email" id="edit_email" placeholder="Email" style="pointer-events: none;"
                                [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_email.errors}"
                                required />
                                <ng-container
                                    *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_email.errors">
                                    <p class="error">
                                        Email is required
                                    </p>
                                </ng-container>                    
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_company_name">Company Name *</label>
                                <input type="text" class="form-control" formControlName="edit_company_name" id="edit_company_name" placeholder="Company Name" 
                                [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_company_name.errors}" 
                                required />                    
                                <ng-container
                                    *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_company_name.errors">
                                    <p class="error">
                                        Company Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>            
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_contact_person_name">Contact Person Name *</label>
                                <input type="text" class="form-control" formControlName="edit_contact_person_name" id="edit_contact_person_name" placeholder="Contact Person Name"
                                [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_contact_person_name.errors}"
                                required />
                                <ng-container
                                    *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_contact_person_name.errors">
                                    <p class="error">
                                        Contact Person is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                        

                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_mobile">Mobile</label>
                                <input type="text" class="form-control" id="edit_mobile" formControlName="edit_mobile" minlength="10" maxlength="10" placeholder="Enter edit_mobile" (keypress)="isNumber($event)" /> 
                                <!-- [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_mobile.errors}"
                                required />                    
                                <ng-container
                                    *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_mobile.errors">
                                    <p class="error">
                                        Mobile is required
                                    </p>
                                </ng-container> -->
                            </div>
                        </div>

                        <div class="col-lg-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_address">Address </label>
                                <textarea class="form-control" id="edit_address" formControlName="edit_address"></textarea>                                                    
                            </div>
                        </div>

                        <div class="col-sm-12 form-group mt-3">
                            <label class="bs-form-label" for="edit_insurance_company">Industry *</label>
                            <select class="form-select" formControlName="edit_insurance_company" id="edit_insurance_company" 
                            [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_insurance_company.errors}"
                            required>
                                <option value="" selected>Select</option>
                                <optgroup label="AUTO & ANCILLIARY">
                                    <option value="Automobile Industry">Automobile Industry</option>
                                    <option value="Aviation Industry">Aviation Industry </option>
                                    <option value="Tractor Industry">Tractor Industry</option>
                                </optgroup>
                            
                                <optgroup label="FINANCIAL & BANKING">
                                    <option value="Banking Industry">Banking Industry</option>
                                    <option value="Insurance Industry">Insurance Industry</option>
                                    <option value="Mutual Fund Industry">Mutual Fund Industry</option>
                                    <option value="Real Estate Industry">Real Estate Industry</option>
                                </optgroup>
                            
                                <optgroup label="CONSUMER DURABLES">
                                    <option value="Furniture Industry">Furniture Industry</option>
                                    <option value="Jute Industry">Jute Industry</option>
                                    <option value="Leather Industry">Leather Industry</option>
                                    <option value="Paper Industry">Paper Industry</option>
                                    <option value="Plastic Industry">Plastic Industry</option>
                                    <option value="Rubber Industry">Rubber Industry</option>
                                    <option value="Silk Industry">Silk Industry</option>
                                    <option value="Television Industry">Television Industry</option>
                                </optgroup>

                                <optgroup label="TEXTILES">
                                    <option value="Textile Industry">Textile Industry</option>
                                    <option value="Garment Industry">Garment Industry</option>
                                    <option value="Weaving Industry">Weaving Industry</option>
                                </optgroup>

                                <optgroup label="FMCG - FAST MOVING CONSUMER GOODS">
                                    <option value="Biscuit Industry">Biscuit Industry</option>
                                    <option value="Soap Industry">Soap Industry</option>
                                </optgroup>

                                <optgroup label="HEALTH CARE">
                                    <option value="Bio technology Industry">Bio technology Industry</option>
                                    <option value="Health Care Industry">Health Care Industry</option>
                                    <option value="Pharamaceutical Industry">Pharamaceutical Industry</option>
                                </optgroup>

                                <optgroup label="INFORMATION TECHNOLOGY & COMMUNICATIION">
                                    <option value="IT Industry">IT Industry</option>
                                </optgroup>

                                <optgroup label="METAL & MINING">
                                    <option value="Aluminium Industry">Aluminium Industry</option>
                                    <option value="Copper Industry">Copper Industry</option>
                                    <option value="Diamond Industry">Diamond Industry</option>
                                    <option value="Granite Industry">Granite Industry</option>
                                    <option value="Mining Industry">Mining Industry</option>
                                    <option value="Pearl Industry">Pearl Industry</option>
                                    <option value="Zinc Industry">Zinc Industry</option>
                                    <option value="Steel Industry">Steel Industry</option>
                                </optgroup>

                                <optgroup label="OIL & GAS">
                                    <option value="IT Industry">IT Industry</option>
                                </optgroup>

                                <optgroup label="POWER">
                                    <option value="Power Industry">Power Industry</option>
                                </optgroup>

                                <optgroup label="RETAILING">
                                    <option value="Chocolate Industry">Chocolate Industry</option>
                                    <option value="Cosmetic Industry">Cosmetic Industry</option>
                                    <option value="Food Processing Industry">Food Processing Industry</option>
                                    <option value="Jewellery Industry">Jewellery Industry</option>
                                    <option value="Music Industry">Music Industry</option>
                                    <option value="Retail Industry">Retail Industry</option>
                                    <option value="Toy Industry">Toy Industry</option>
                                </optgroup>

                                <optgroup label="SERVICE SECTOR">
                                    <option value="Advertising Industry">Advertising Industry</option>
                                    <option value="Electronic Industry">Electronic Industry</option>
                                    <option value="Hotel Industry">Hotel Industry</option>
                                    <option value="Railway Industry">Railway Industry</option>
                                    <option value="Shipping Industry">Shipping Industry</option>
                                    <option value="Telecom Industry">Telecom Industry</option>
                                    <option value="Tourism Industry">Tourism Industry</option>
                                </optgroup>

                                <optgroup label="AGRICULTURE-BASED">
                                    <option value="Agricultural Industry">Agricultural Industry</option>
                                    <option value="Tobacco Industry">Tobacco Industry</option>
                                    <option value="Dairy Industry">Dairy Industry</option>
                                    <option value="Cotton Industry">Cotton Industry</option>
                                    <option value="Sugar Industry">Sugar Industry</option>
                                    <option value="Tea Industry">Tea Industry</option>
                                    <option value="Poultry Industry">Poultry Industry</option>
                                </optgroup>

                                <optgroup label="OTHER INDUSTRIES">
                                    <option value="Cement Industry">Cement Industry</option>
                                    <option value="Coir Industry">Coir Industry</option>
                                    <option value="Construction Industry">Construction Industry</option>
                                    <option value="Cottage Industry">Cottage Industry</option>
                                    <option value="Fashion Industry">Fashion Industry</option>
                                    <option value="Fertilizer Industry">Fertilizer Industry</option>
                                    <option value="Film Industry">Film Industry</option>
                                    <option value="Paint Industry">Paint Industry</option>
                                    <option value="Printing Industry">Printing Industry</option>
                                    <option value="Solar Industry">Solar Industry</option>
                                    <option value="Turbine Industry">Turbine Industry</option>
                                </optgroup>

                            </select>
                            <ng-container
                                *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_insurance_company.errors">
                                <p class="error">
                                Industry is required
                                </p>
                            </ng-container>
                        </div>

                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />                                                        
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Update</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Deactivate User</h4>
                </div>
                <form [formGroup]="usersDelete">
                    <div class="modal-body">                                
                        Are you sure you want to Deactivate?
                    </div>
                    <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Deactivate</button>                    
                        <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                    </div>
                </form>            
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_add}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add User Info</h4>
            </div>
            <div class="modal-body">
                
                <form [formGroup]="usersInfo">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="email">Email *</label>
                                <input type="email" class="form-control" formControlName="email" id="email" placeholder="Email" 
                                [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.email.errors}"
                                required />
                                <ng-container
                                    *ngIf="usersInfoSubmitted && getUsersInformationFormControls.email.errors">
                                    <p class="error">
                                        Email is required
                                    </p>
                                </ng-container>                    
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="company_name">Company Name *</label>
                                <input type="text" class="form-control" formControlName="company_name" id="company_name" placeholder="Company Name" 
                                [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.company_name.errors}" 
                                required />                    
                                <ng-container
                                    *ngIf="usersInfoSubmitted && getUsersInformationFormControls.company_name.errors">
                                    <p class="error">
                                        Company Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>            
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_person_name">Contact Person Name *</label>
                                <input type="text" class="form-control" formControlName="contact_person_name" id="contact_person_name" placeholder="Contact Person Name"
                                [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.contact_person_name.errors}"
                                required />
                                <ng-container
                                    *ngIf="usersInfoSubmitted && getUsersInformationFormControls.contact_person_name.errors">
                                    <p class="error">
                                        Contact Person is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                        

                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="mobile">Mobile</label>
                                <input type="text" class="form-control" id="mobile" formControlName="mobile" minlength="10" maxlength="10" placeholder="Enter mobile" (keypress)="isNumber($event)" /> 
                                <!-- [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.mobile.errors}"
                                required />                    
                                <ng-container
                                    *ngIf="usersInfoSubmitted && getUsersInformationFormControls.mobile.errors">
                                    <p class="error">
                                        Mobile is required
                                    </p>
                                </ng-container> -->
                            </div>
                        </div>

                        <div class="col-lg-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="address">Address </label>
                                <textarea class="form-control" id="address" formControlName="address"></textarea>                                                    
                            </div>
                        </div>

                        <div class="col-sm-12 form-group mt-3">
                            <label class="bs-form-label" for="insurance_company">Industry *</label>
                            <select class="form-select" formControlName="insurance_company" id="insurance_company" 
                            [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.insurance_company.errors}"
                            required>
                                <option value="" selected>Select</option>
                                <optgroup label="AUTO & ANCILLIARY">
                                    <option value="Automobile Industry">Automobile Industry</option>
                                    <option value="Aviation Industry">Aviation Industry </option>
                                    <option value="Tractor Industry">Tractor Industry</option>
                                </optgroup>
                            
                                <optgroup label="FINANCIAL & BANKING">
                                    <option value="Banking Industry">Banking Industry</option>
                                    <option value="Insurance Industry">Insurance Industry</option>
                                    <option value="Mutual Fund Industry">Mutual Fund Industry</option>
                                    <option value="Real Estate Industry">Real Estate Industry</option>
                                </optgroup>
                            
                                <optgroup label="CONSUMER DURABLES">
                                    <option value="Furniture Industry">Furniture Industry</option>
                                    <option value="Jute Industry">Jute Industry</option>
                                    <option value="Leather Industry">Leather Industry</option>
                                    <option value="Paper Industry">Paper Industry</option>
                                    <option value="Plastic Industry">Plastic Industry</option>
                                    <option value="Rubber Industry">Rubber Industry</option>
                                    <option value="Silk Industry">Silk Industry</option>
                                    <option value="Television Industry">Television Industry</option>
                                </optgroup>

                                <optgroup label="TEXTILES">
                                    <option value="Textile Industry">Textile Industry</option>
                                    <option value="Garment Industry">Garment Industry</option>
                                    <option value="Weaving Industry">Weaving Industry</option>
                                </optgroup>

                                <optgroup label="FMCG - FAST MOVING CONSUMER GOODS">
                                    <option value="Biscuit Industry">Biscuit Industry</option>
                                    <option value="Soap Industry">Soap Industry</option>
                                </optgroup>

                                <optgroup label="HEALTH CARE">
                                    <option value="Bio technology Industry">Bio technology Industry</option>
                                    <option value="Health Care Industry">Health Care Industry</option>
                                    <option value="Pharamaceutical Industry">Pharamaceutical Industry</option>
                                </optgroup>

                                <optgroup label="INFORMATION TECHNOLOGY & COMMUNICATIION">
                                    <option value="IT Industry">IT Industry</option>
                                </optgroup>

                                <optgroup label="METAL & MINING">
                                    <option value="Aluminium Industry">Aluminium Industry</option>
                                    <option value="Copper Industry">Copper Industry</option>
                                    <option value="Diamond Industry">Diamond Industry</option>
                                    <option value="Granite Industry">Granite Industry</option>
                                    <option value="Mining Industry">Mining Industry</option>
                                    <option value="Pearl Industry">Pearl Industry</option>
                                    <option value="Zinc Industry">Zinc Industry</option>
                                    <option value="Steel Industry">Steel Industry</option>
                                </optgroup>

                                <optgroup label="OIL & GAS">
                                    <option value="IT Industry">IT Industry</option>
                                </optgroup>

                                <optgroup label="POWER">
                                    <option value="Power Industry">Power Industry</option>
                                </optgroup>

                                <optgroup label="RETAILING">
                                    <option value="Chocolate Industry">Chocolate Industry</option>
                                    <option value="Cosmetic Industry">Cosmetic Industry</option>
                                    <option value="Food Processing Industry">Food Processing Industry</option>
                                    <option value="Jewellery Industry">Jewellery Industry</option>
                                    <option value="Music Industry">Music Industry</option>
                                    <option value="Retail Industry">Retail Industry</option>
                                    <option value="Toy Industry">Toy Industry</option>
                                </optgroup>

                                <optgroup label="SERVICE SECTOR">
                                    <option value="Advertising Industry">Advertising Industry</option>
                                    <option value="Electronic Industry">Electronic Industry</option>
                                    <option value="Hotel Industry">Hotel Industry</option>
                                    <option value="Railway Industry">Railway Industry</option>
                                    <option value="Shipping Industry">Shipping Industry</option>
                                    <option value="Telecom Industry">Telecom Industry</option>
                                    <option value="Tourism Industry">Tourism Industry</option>
                                </optgroup>

                                <optgroup label="AGRICULTURE-BASED">
                                    <option value="Agricultural Industry">Agricultural Industry</option>
                                    <option value="Tobacco Industry">Tobacco Industry</option>
                                    <option value="Dairy Industry">Dairy Industry</option>
                                    <option value="Cotton Industry">Cotton Industry</option>
                                    <option value="Sugar Industry">Sugar Industry</option>
                                    <option value="Tea Industry">Tea Industry</option>
                                    <option value="Poultry Industry">Poultry Industry</option>
                                </optgroup>

                                <optgroup label="OTHER INDUSTRIES">
                                    <option value="Cement Industry">Cement Industry</option>
                                    <option value="Coir Industry">Coir Industry</option>
                                    <option value="Construction Industry">Construction Industry</option>
                                    <option value="Cottage Industry">Cottage Industry</option>
                                    <option value="Fashion Industry">Fashion Industry</option>
                                    <option value="Fertilizer Industry">Fertilizer Industry</option>
                                    <option value="Film Industry">Film Industry</option>
                                    <option value="Paint Industry">Paint Industry</option>
                                    <option value="Printing Industry">Printing Industry</option>
                                    <option value="Solar Industry">Solar Industry</option>
                                    <option value="Turbine Industry">Turbine Industry</option>
                                </optgroup>

                            </select>
                            <ng-container
                                *ngIf="usersInfoSubmitted && getUsersInformationFormControls.insurance_company.errors">
                                <p class="error">
                                Industry is required
                                </p>
                            </ng-container>
                        </div> 

                    </div>
                </form>
  
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmit()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_upload}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Bulk Upload</h4>
            </div>
            <div class="modal-body">
                
                <form [formGroup]="documentUpload">
                    <div class="row">
                        
						<div class="col-sm-12">
							<div class="form-group">
								<label class="bs-form-label" for="member_data">Data Upload *</label>
								<!-- <input type="file" class="form-control" formControlName="member_data" (change)="member_data_change($event)" id="file" enctype="multipart/form-data" /> -->

								<label for="file_field6" id="custom-file-upload_member_data" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
									<img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
								</label>
								
								<input id="file_field6" type="file" (change)="member_data_change($event)" formControlName="member_data" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none"
								[ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors}"
								required />                           
								<ng-container
									*ngIf="documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors">
									<p class="error">
									Please upload data
									</p>
								</ng-container>

							</div>
							<small style="font-size: 12px; color:#605D62" id="small_member_data">Upload only .xlsx, .xls, .csv files (Max size 4MB)</small>
							<!-- <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadMemberdata()">Download Sample File</span> -->
						</div>

                    </div>
                </form>
  
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success" (click)="submit_upload()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete2}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Send Survey</h4>
                </div>
                <div class="modal-body">                                
                    Are you sure you want to send survey?
                </div>                    
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="sendsurvey()" role="button">Send</button>                    
                    <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                </div>
            </div>
        </div>
      </div>

</div>