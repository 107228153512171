<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">

    <!-- <div  *ngIf="user_role == 'brokeradmin'">

      <div *ngFor="let item of json[0]['data']['module_info']; let i = index">
        <li class="nav-item"  style="margin-top: 20px;" [ngClass]="{'active': url === item['module_url']}" *ngIf="item['child'].length == 0">
          <a class="nav-link" routerLink="{{item['module_url']}}">
            <i class="mdi {{item['module_icon']}} menu-icon"></i>
            <span class="menu-title">{{item['module_name']}}</span>
          </a>
        </li>
      </div>

      <div *ngFor="let subitem of json[0]['data']['module_info']; let i = index" class="my-3">
        <li class="nav-item" *ngIf="subitem['child'].length > 0">
          <a class="nav-link" data-bs-toggle="collapse" href="#{{subitem['module_url']}}" aria-expanded="false">
            <i class="menu-icon mdi {{subitem['module_icon']}}"></i>
            <span class="menu-title">{{subitem['module_name']}}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="{{subitem['module_url']}}">
            <ul class="nav flex-column sub-menu">
              <div *ngFor="let submenu of subitem['child']; let j = index">
                <li class="nav-item" [ngClass]="{'active': url === submenu['module_url']}">
                  <a routerLink="{{submenu['module_url']}}" class="nav-link">{{submenu['module_name']}}</a>
                </li>
              </div>
            </ul>
          </div>
        </li>

      </div>

    </div>  -->

    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'superadmin'" [ngClass]="{'active': url === '/SuperAdmin-dashboard'}">
      <a class="nav-link" routerLink="/SuperAdmin-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'superadmin'">
      <a class="nav-link" data-bs-toggle="collapse" href="#managelist" aria-expanded="false" aria-controls="managelist">
        <i class="menu-icon mdi mdi-settings"></i>
        <span class="menu-title">Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="managelist">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-theme'}">
            <a routerLink="/admin-manage-theme" class="nav-link">Manage Theme</a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-news'}">
            <a routerLink="/admin-manage-news" class="nav-link">Manage News</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-annoucements'}">
            <a routerLink="/admin-manage-annoucements" class="nav-link">Manage Annoucements</a>
          </li> -->
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-country'}" >
            <a routerLink="/admin-manage-country" class="nav-link">Manage Country</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-state'}">
            <a routerLink="/admin-manage-state" class="nav-link">Manage State</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-city'}">
            <a routerLink="/admin-manage-city" class="nav-link">Manage City</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-industry'}">
            <a routerLink="/admin-manage-industry" class="nav-link">Manage Industry</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-user-types'}">
            <a routerLink="/admin-manage-user-types" class="nav-link">Manage User Types</a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-plan'}">
            <a routerLink="/admin-manage-plan" class="nav-link">Manage Plan</a>
          </li> -->
          <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}">
            <a routerLink="/admin-tpa-details" class="nav-link">TPA Details</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-insurance-company-insurer'}">
            <a routerLink="/admin-insurance-company-insurer" class="nav-link">Insurance Company</a>
          </li>

        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="user_role == 'superadmin'">
      <a class="nav-link" data-bs-toggle="collapse" href="#masterlist" aria-expanded="false" aria-controls="masterlist">
        <i class="menu-icon mdi mdi-apps"></i>
        <span class="menu-title">Masters</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="masterlist">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/admin-mastermodules'}">
            <a routerLink="/admin-mastermodules" id="admin-mastermodules" class="nav-link">Master Modules</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-master-broker'}">
            <a routerLink="/admin-manage-master-broker" id="admin-manage-master-broker" class="nav-link">Master Broker</a>
          </li>

        </ul>
      </div>
    </li>

    <!-- Broker -->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/broker-dashboard'}">
      <a routerLink="/broker-dashboard" id="broker-dashboard" class="nav-link">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/broker-manage-master'}">
      <a routerLink="/broker-manage-master" class="nav-link">
        <i class="mdi mdi-human-child menu-icon"></i>
        <span class="menu-title">Manage Client</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/broker-manage-master-employer/user-management'}">
      <a routerLink="/broker-manage-master-employer/user-management" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">User Management</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/product-feature-master'}">
      <a routerLink="/product-feature-master" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Product Feature Master</span>
      </a>
    </li>
    <!-- <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/policies'}">
      <a routerLink="/policies" class="nav-link">
        <i class="mdi mdi-snowflake menu-icon"></i>
        <span class="menu-title">Policies</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'">
      <a class="nav-link" data-bs-toggle="collapse" href="#policies" aria-expanded="false" aria-controls="policies">
        <i class="menu-icon mdi mdi-snowflake menu-icon"></i>
        <span class="menu-title">Policy</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="policies">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/policies'}">
            <a routerLink="/policies" class="nav-link">Create Policy</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/listpolicies'}">
            <a routerLink="/listpolicies" class="nav-link">List Policies</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/accept-relationship-request'}">
            <a routerLink="/accept-relationship-request" class="nav-link">Accept Relationship <br> Request</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/listendorsement'}">
            <a routerLink="/listendorsement" class="nav-link">Endorsement</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/broker-endorsement-tat-report'}">
            <a routerLink="/broker-endorsement-tat-report" class="nav-link">Endorsement TAT Report</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/cd-updation'}">
            <a routerLink="/cd-updation" class="nav-link">CD Updation</a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/endorsement-request'}">
            <a routerLink="/endorsement-request" class="nav-link">Member Upload</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/flex-configurator'}">
            <a routerLink="/flex-configurator" class="nav-link">Flex Configurator</a>
          </li> -->
        </ul>
      </div>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'">
      <a class="nav-link" data-bs-toggle="collapse" href="#claims" aria-expanded="false" aria-controls="claims">
        <i class="menu-icon mdi mdi-human-child menu-icon"></i>
        <span class="menu-title">Claims</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="claims">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/claim-intimation'}">
            <a class="nav-link" (click)="checkclaim()" style="cursor: pointer;">Claims List</a>
          </li>          
          <li class="nav-item" [ngClass]="{'active': url === '/broker-endorsement-claims-report'}">
            <a routerLink="/broker-claims-tat-report" class="nav-link">Claim TAT Report</a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/claim-document'}">
            <a routerLink="/claim-document" class="nav-link">Claim Document</a>
          </li>          -->
        </ul>
      </div>
    </li> 

    <!-- Claims Executive User -->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'claimsexecutiveuser' && claimsexecutiveuser_dashboard == '1'" [ngClass]="{'active': url === '/claimsexecutiveuser-dashboard'}">
      <a routerLink="/claimsexecutiveuser-dashboard" id="claimsexecutiveuser-dashboard" class="nav-link">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>   

    <!-- Employer -->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'employeradmin'" [ngClass]="{'active': url === '/employer-dashboard'}">
      <a class="nav-link" routerLink="/employer-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'employeradmin'" [ngClass]="{'active': url === '/employee-user-management'}">
      <a class="nav-link" routerLink="/employee-user-management">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">User Management</span>
      </a>
    </li>

   
    
    <li class="nav-item" *ngIf="user_role == 'employeradmin' || user_role == 'claimsexecutiveuser' && claim_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" href="#claims" aria-expanded="false" aria-controls="claims">
        <i class="menu-icon mdi mdi-human-child menu-icon"></i>
        <span class="menu-title">Claims</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="claims">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/claim-intimation'}"  *ngIf="executive_user_claim_intimation == '1'">
            <a class="nav-link" (click)="checkclaim()" style="cursor: pointer;">Claims List</a>
          </li>          
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/'}">
            <a routerLink="/broker-dashboard" class="nav-link">Claim Submit</a>
          </li> -->
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/claim-document'}">
            <a routerLink="/claim-document" class="nav-link">Claim Document</a>
          </li>          -->
        </ul>
      </div>
    </li>

    <li class="nav-item" *ngIf="user_role == 'claimsexecutiveuser' && claim_tat_report == 1" [ngClass]="{'active': url === '/claim-tat-report'}">
      <a routerLink="/claim-tat-report" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Claims TAT Report</span>
      </a>
    </li>

    <!-- <li class="nav-item" *ngIf="user_role == 'brokeradmin' || user_role == 'employeradmin'" [ngClass]="{'active': url === '/'}">
      <a routerLink="/broker-dashboard" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Reports</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/dynamic-fields'}" style="display: none;">
      <a routerLink="/dynamic-fields" id="dynamic-fields" class="nav-link">
        <i class="mdi mdi-more menu-icon"></i>
        <span class="menu-title">Dynamic Fields</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/claim-status'}">
      <a routerLink="/claim-status" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Claim Status Master</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/admin-manage-theme'}">
      <a routerLink="/admin-manage-theme" class="nav-link">
        <i class="mdi mdi-theme-light-dark menu-icon"></i>
        <span class="menu-title">Manage Theme</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/broker-insurance-company'}">
      <a routerLink="/broker-insurance-company" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Insurance Company</span>
      </a>
    </li>    

    <!-- HR Info -->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'commonhr'" [ngClass]="{'active': url === '/hr-info-dashboard'}">
      <a class="nav-link" routerLink="/hr-info-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <!-- Client -->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'client'" [ngClass]="{'active': url === '/Client-dashboard'}">
      <a class="nav-link" routerLink="/Client-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <!-- Employee User -->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === '/employeruser-dashboard'}">
      <a class="nav-link" routerLink="/employeruser-dashboard">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Dashboard</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === '/employeruser-dashboard/family-enrollment'}">
      <a class="nav-link" routerLink="/employeruser-dashboard/family-enrollment">
        <img src="../../../assets/img/employee/enrollment.png" alt="Icon" class="menuicon">
        <span class="menu-title">Enrollment</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/application_form.png" alt="Icon" class="menuicon">
        <span class="menu-title">Claims</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/contact_us.png" alt="Icon" class="menuicon">
        <span class="menu-title">Contact Us</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/id_card.png" alt="Icon" class="menuicon">
        <span class="menu-title">Download E-Card</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/hospital.png" alt="Icon" class="menuicon">
        <span class="menu-title">Network Hospital</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/help.png" alt="Icon" class="menuicon">
        <span class="menu-title">Help</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/form.png" alt="Icon" class="menuicon">
        <span class="menu-title">Form Center</span>
      </a>
    </li>   

    <!-- OPS Manager -->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'opsmanageruser' && opsmanager_dashboard == 1" [ngClass]="{'active': url === '/ops-dashboard'}">
      <a class="nav-link" routerLink="/ops-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title" style="margin-top: 5px;">Dashboard</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'opsmanageruser' && ops_endorsement == 1" [ngClass]="{'active': url === '/ops-endorsement'}">
      <a routerLink="/ops-endorsement" id="dynamic-fields" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Endorsement</span>
      </a>
    </li>

    <!-- <li class="nav-item" *ngIf="user_role == 'opsmanageruser'" [ngClass]="{'active': url === '/ops-bulk-endorsement'}">
      <a routerLink="/ops-bulk-endorsement" id="dynamic-fields" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Bulk Endorsement</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'opsmanageruser' && ops_endorsement_tat == 1" [ngClass]="{'active': url === '/ops-endorsement-tat-report'}">
      <a routerLink="/ops-endorsement-tat-report" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Endorsement TAT Report</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'opsmanageruser' && ops_relation == 1" [ngClass]="{'active': url === '/ops-new-relationship-request'}">
      <a routerLink="/ops-new-relationship-request" class="nav-link">
        <i class="mdi mdi-human-child menu-icon"></i>
        <span class="menu-title">Relationship Request</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'opsmanageruser' && ops_cd_summary == 1" [ngClass]="{'active': url === '/ops-cd-summary'}">
      <a routerLink="/ops-cd-summary" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">CD Summary</span>
      </a>
    </li>
    <!-- End -->

  </ul>
</nav>
