<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Relationship Request Listing <span class="spanbs-title"></span>
            <!-- <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button> -->
            </h3>
            <!-- <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Employer User</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Employer User</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3> -->

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2">

            <!-- Filters -->
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_name">Client Name</label>
                        <select class="form-select" id="client_name" (change)="getInfo($event, 'custom')">
                            <option value="" selected>Select Client Name</option>
                            <option *ngFor="let res of InfoArraydropdown" [value]="res.id">{{ res.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- Listing -->
            <div class="table-responsive mt-3" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Relationships</th>
                        <th>Relationship Type</th>
                        <th>Cover/Policy Start Date</th>
                        <th>Cover/Policy End Date</th>
                        <th>Coverage Days</th>
                        <th>Individual Cover Amount (Lacs)</th>
                        <th>Count of Members </th>
                        <th>Total Sum Insured </th>
                        <th>Per Mili Rate </th>
                        <th>Net Premium</th>
                        <th>Prorata Premium</th>
                        <th>GST %</th>
                        <th>Gross Premium</th>
                        <th style="text-align: center;">Status </th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['new_relationship_title']}}</td>
                        <td>{{group['relation_title']}}</td>
                        <td>{{group['cover_start_date']}}</td>
                        <td>{{group['cover_end_date']}}</td>
                        <td>{{group['coverage_days']}}</td>
                        <td>{{group['individual_cover_amount']}}</td>
                        <td>{{group['member_count']}}</td>
                        <td>{{group['total_sum_insured']}}</td>
                        <td>{{group['rate_per_milli']}}</td>
                        <td>{{group['total_premium']}}</td>
                        <td>{{group['prorata_premium']}}</td>
                        <td>{{group['gst_per']}}</td>
                        <td>{{group['total_premium_with_gst']}}</td>
                        <td *ngIf="group['is_request_status']==1"><span _ngcontent-igh-c148="" class="btn btn-inverse-success btn-fw">Accepted</span></td>
                        <td *ngIf="group['is_request_status']==2"><span _ngcontent-igh-c148="" class="btn btn-inverse-danger btn-fw">Declined</span></td>
                        <td *ngIf="group['is_request_status']==0"><span _ngcontent-igh-c148="" class="btn btn-inverse-warning btn-fw">Pending</span></td>
                        <td style="width: 15%" align="center">
                            <img  *ngIf="group['is_request_status'] === 0 && group['is_request_status'] !== 1 && group['is_request_status'] !== 2" src="../../../assets/img/selectproduct/deactive.png" (click)="fn_decline_request(group['id'])" id="decline"  class="ml-4" role="button" title="Decline" />
                            <img  *ngIf="group['is_request_status'] != 1 && group['is_request_status'] != 2" src="../../../assets/img/selectproduct/active.png" (click)="fn_accept_request(group['id'])" id="accept" class="ml-4" role="button" title="Accept" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!--Accept Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleAccept}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Accept Relationship Request</h4>
                        </div>
                            <div class="modal-body">
                                Are you sure you want to Accept?
                            </div>
                            <input type="hidden" class="form-control" formControlName="accept_id" id="accept_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="on_submitAccept()" role="button">Accept</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                    </div>
                </div>
            </div>

            <!-- Decline Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDecline}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Decline Relationship Request</h4>
                        </div>
                            <div class="modal-body">
                                Are you sure you want to Decline?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="on_submitDecline()" role="button">Decline</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>
