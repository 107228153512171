<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title" *ngIf="addflag == 0"> <span class="spanbs-title">Endorsement </span>
                <!-- <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button> -->
            </h3>

            <!-- <h3 class="bs-title" *ngIf="addflag == 0">Endorsement Listing
                <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3> -->

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Backend Error! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>

            <hr class="mb-2">

            <form [formGroup]="AddInfo">
                <div class="row">

                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="client_id">Client Name *</label>
                            <select class="form-select" id="client_id" formControlName="client_id" (change)="getpolicytype()" (change)="getInfo($event, 'custom')" [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.client_id.errors}"
                            required>
                                <option value="" >Select Client Name</option>
                                <option *ngFor="let res of ClientId" [value]="res.employer_id">{{ res.employer_name}}</option>
                            </select>
                            <small id="client_error" class="bs-form-label" style="color: red; display: none; float: right;">Client Required</small>

                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.client_id.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.client_id.errors.required">
                                    Client Name is required
                                </p>
                            </ng-container>
                        </div>
                    </div>

                  <div class="col-sm-4" *ngIf="writeaccess==1">
                    <div class="form-group">
                        <label class="bs-form-label" for="policy_type">Policy Type *</label>
                        <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getclientwisepolicy_list()" (change)="download_bulkFile()"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.policy_type.errors}"
                        required>
                            <option value="" selected>Select Policy Type</option>
                            <option *ngFor="let type of policy_type" [value]="type.sub_type_id">{{ type.name }}</option>
                        </select>
                        <small id="policy_type_error" class="bs-form-label" style="color: red; display: none; float: right;">Policy Type Required</small>
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.policy_type.errors">
                        <p class="error"
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.policy_type.errors.required">
                        Policy Number is required
                        </p>
                      </ng-container>
                    </div>
                  </div>

                    <!-- <div class="col-sm-4" *ngIf="writeaccess==1">
                        <div class="form-group">
                            <label for="policy_no">Policy Name </label>
                            <select class="form-select" formControlName="policy_no" id="policy_no" (change)="onPolicyNumberChange($event)" (change)="calGST()" (change)="calPremium()"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.policy_no.errors}"
                                required>
                                <option value="" selected>Select Policy Name</option>
                                <option *ngFor="let res2 of clientwisepolicy"
                                    [value]="res2.policyid">{{res2.policy_name}}</option>
                            </select>
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.policy_no.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.policy_no.errors.required">
                                    Policy Name is required
                                </p>
                            </ng-container>
                        </div>
                    </div> -->

                  <!-- Tabs -->
                  <!-- <div class="col-sm-12 mt-4" *ngIf="writeaccess==1"> -->

                    <!-- Tabs Headings -->
                    <!-- <ul class="nav nav-tabs" id="myTab" role="tablist" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 10px 10px 0px 0px;">
                      <li class="nav-item liwidth" role="presentation">
                          <button class="nav-link active w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add" type="button"
                              role="tab" aria-controls="home" aria-selected="true">Add</button>
                      </li>
                      <li class="nav-item liwidth" role="presentation">
                          <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" data-bs-target="#Remove" type="button"
                              role="tab" aria-controls="Remove" aria-selected="false">Remove</button>
                      </li>
                      <li class="nav-item liwidth" role="presentation">
                          <button class="nav-link w100" id="Corrections-tab" data-bs-toggle="tab" data-bs-target="#Corrections" type="button"
                              role="tab" aria-controls="Corrections" aria-selected="false">Corrections</button>
                      </li>
                    </ul> -->

                    <!-- Tabs Content -->
                    <!-- <div class="tab-content" id="myTabContent" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 0px 0px 10px 10px;padding: 10px 20px;"> -->

                      <!-- Tab Add -->
                      <!-- <div class="tab-pane fade show active" id="Add" role="tabpanel" aria-labelledby="Add-tab">
                        <div class="row">

                            <div class="col-sm-4">
                                <div class="form-group datediv">
                                    <label for="endo_date">Endorsement For The Month</label>
                                    <input type="month" class="form-control" formControlName="endo_date" id="endo_date"  [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.endo_date.errors}"
                                    required style="appearance: none;" />
                                </div>
                                <ng-container
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.endo_date.errors">
                                        <p class="error"
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.endo_date.errors.required">
                                            Endorsement For The Month is required
                                        </p>
                                    </ng-container>
                            </div>

                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="new_member_count">Additions New</label>
                                    <input type="text" class="form-control" formControlName="new_member_count" id="new_member_count" placeholder="Additions New" (keyup)="calPremium()" (keydown)="calPremium()" (keyup)="calGST()" (keydown)="calGST()"
                                        (keypress)="isNumber($event)"
                                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.new_member_count.errors}"
                                        required />
                                </div>
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors.required">
                                        Additions New is required
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="individualCoverAmount">Individual Cover</label>
                                    <input type="text" class="form-control" formControlName="individualCoverAmount" id="individualCoverAmount" placeholder="Individual Cover Amount" (keypress)="isNumber($event)" (keyup)="calPremium()" (keydown)="calPremium()" (keyup)="calGST()" (keydown)="calGST()"
                                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors}"
                                        required />
                                </div>
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.individualCoverAmount.errors.required">
                                        Individual Cover is required
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-sm-4 mt-3">
                                <div class="form-group">
                                    <label for="totalSI">Total Sum Insured </label>
                                    <input type="text" class="form-control" formControlName="totalSI" id="totalSI" placeholder="Total Sum Insured " (keyup)="calPremium()" (keydown)="calPremium()" (keyup)="calGST()" (keydown)="calGST()"
                                        (keypress)="isNumber($event)" />
                                </div>
                            </div>

                            <div class="col-sm-4 mt-3">
                                <div class="form-group">
                                    <label for="permili_rate">Premium Rate Per Milli</label>
                                    <input type="text" class="form-control"
                                        formControlName="permili_rate" id="permili_rate"
                                        placeholder="Premium Rate Per Milli" (keyup)="calPremium()" (keydown)="calPremium()" (keyup)="calGST()" (keydown)="calGST()"
                                        (keypress)="isNumber($event)"
                                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.permili_rate.errors}"
                                        required />
                                    <ng-container
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.permili_rate.errors">
                                        <p class="error"
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.permili_rate.errors.required">
                                            Premium Rate Per Milli is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div>


                            <div class="col-sm-4 mt-3">
                                <div class="form-group">
                                    <label for="totalPremium">Total Premium</label>
                                    <input type="text" class="form-control"
                                        formControlName="totalPremium" id="totalPremium"
                                        placeholder="Total Premium" (keyup)="calGST()" (keydown)="calGST()"
                                        (keypress)="isNumber($event)" />
                                </div>
                            </div>

                            <div class="col-sm-4 mt-3">
                                <div class="form-group">
                                    <label for="gst">GST Percantage</label>
                                    <input type="text" class="form-control"
                                        formControlName="gst" id="gst" (keyup)="calGST()" (keydown)="calGST()"
                                        placeholder="GST Percantage"
                                        (keypress)="isNumber($event)" />
                                </div>
                            </div>

                            <div class="col-sm-4 mt-3">
                                <div class="form-group">
                                    <label for="totalPremiumwithGST">Total Premium with GST</label>
                                    <input type="text" class="form-control"
                                        formControlName="totalPremiumwithGST" id="totalPremiumwithGST" placeholder="Total Premium With GST" (keypress)="isNumber($event)" />
                                </div>
                            </div>

                            <div class="col-sm-12 mt-3">
                                <div class="form-group">
                                    <label for="remarks">Remarks</label>
                                    <input type="text" class="form-control"
                                        formControlName="remarks" id="remarks" placeholder="Remarks" />
                                </div>
                            </div>

                            <input type="hidden" class="form-control"
                                        formControlName="permili_rate" id="permili_rate"
                                        placeholder="Premium Rate Per Milli" (keyup)="calPremium()" (keydown)="calPremium()" (keyup)="calGST()" (keydown)="calGST()"
                                        (keypress)="isNumber($event)" />

                        </div>
                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>
                          </div>
                      </div> -->

                      <!-- Tab Remove -->
                      <!-- <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab">
                        <label class="bs-form-label"><h4>Comming Soon</h4></label>
                      </div> -->

                      <!-- Tab Corrections -->
                      <!-- <div class="tab-pane fade" id="Corrections" role="tabpanel" aria-labelledby="Corrections-tab">
                        <label class="bs-form-label"><h4>Comming Soon</h4></label>
                      </div> -->

                    <!-- </div> -->

                  <!-- </div> -->
                  <!-- End -->

                </div>
            </form>

              <hr>

              <h3 class="bs-title" *ngIf="addflag == 0"> <span class="spanbs-title">Bulk Upload </span> </h3>

                <div class="row mt-3" style="border-radius: 10px 10px 10px 10px; box-shadow: 0px 0px 10px 1px #0000004f; padding: 10px 20px; background-color: #fff; margin-left: 5px; width: 99%">
                    <!-- <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="client_name">Client Name</label>
                            <select class="form-select" id="client_name">
                                <option value="" selected>Select Client Name</option>
                                <option *ngFor="let res of InfoArraydropdown" [value]="res.employer_id">{{ res.employer_name }}</option>
                            </select>
                            <small id="client_name_error" class="bs-form-label" style="color: red; display: none; float: right;">Client Name Required</small>
                        </div>
                    </div> -->

                    <div class="col-sm-7">
                        <div class="form-group">
                          <label class="bs-form-label" for="document_file">Upload Endorsement *</label>
                          <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                              <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                          </label>
                          <input id="document_file" type="file" (change)="endorsement_doc($event)" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />
                          <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;" id="document_file">Upload only .xls, .xlsx files</small>
                          <small id="document_file_error" class="bs-form-label" style="color: red; display: none; float: right;">Please Upload Document</small>
                          <!-- <small class="sizeerror" style="font-size: 12px; color:#df3603; position: relative; top: -5px; display: none;">File size must be less than 5MB</small>                       -->
                        </div>
                    </div>

                    <div class="col-sm-2 mt-3">
                        <button type="button" class="btn btn-primary mt-3"
                            (click)="onSubmit()" role="button">Upload</button>
                    </div>

                    <div class="col-sm-3 mt-3">
                        <button type="button" class="btn btn-primary mt-3"
                        (click)="ondownload()" role="button" [disabled]="clientwisepolicy.length == 0">Download Endorsement Details</button>
                    </div>

                </div>

            <hr>


            <div class="row mt-3">
                <div class="col-sm-12">
                     <button type="button" class="btn btn-primary" [disabled]="policy_type.length == 0"  (click)="downloadCSV()" role="button" style="float:right">Download Excel</button>
                </div>
            </div>

            <!-- Listing -->
            <div class="mt-3" *ngIf="addflag == 0">
                <table class="table table-sm row-border wrap"  width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Endorsement No.</th>
                        <!-- <th>Policy No.</th> -->
                        <th>Policy Name</th>
                        <th>Count of Members</th>
                        <th>Insurer Endorsement Number</th>
                        <th>Status</th>
                        <th>Uploaded At</th>
                        <th>Endorsement Copy </th>
                        <!-- <th>Status </th> -->
                        <!-- <th style="text-align: center;">Action</th> -->
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['auto_endorsement_no']}}</td>
                        <!-- <td>{{group['policy_number']}}</td> -->
                        <td>{{group['policy_name']}}</td>
                        <td>{{group['member_count']}}</td>
                        <td>{{group['insurer_endo_number']}}</td>
                        <td>{{group['current_status']}}</td>
                        <td>{{group['added_date']}}</td>
                        <td *ngIf="group['broker_endo_copy_file']==='' && group['broker_endo_copy_file']===null"><span>NA</span></td>
                        <td  style="width: 15%" align="center">
                            <img *ngIf="group['broker_endo_copy_file']!=='' && group['broker_endo_copy_file']!== null" src="../../../assets/img/common icons/downloaddoc.png" id="download" (click)="ondownload_uploaded(group['broker_endo_copy_file'], group['endocopy'])" class="ml-4" role="button" title="Download" />
                        </td>
                        <!-- <td *ngIf="group['is_request_status']==1"><span _ngcontent-igh-c148="" class="btn btn-inverse-success btn-fw">Accepted</span></td>
                        <td *ngIf="group['is_request_status']==2"><span _ngcontent-igh-c148="" class="btn btn-inverse-danger btn-fw">Declined</span></td>
                        <td *ngIf="group['is_request_status']==0"><span _ngcontent-igh-c148="" class="btn btn-inverse-danger btn-fw">Pending</span></td> -->
                        <!-- <td style="width: 15%" align="center"> -->
                            <!-- <img  *ngIf="group['is_request_status'] === 0 && group['is_request_status'] !== 1 && group['is_request_status'] !== 2" src="../../../assets/img/selectproduct/deactive.png" (click)="fn_decline_request(group['id'])" id="decline"  class="ml-4" role="button" title="Decline" /> -->
                            <!-- <img  *ngIf="group['is_request_status'] != 1 && group['is_request_status'] != 2" src="../../../assets/img/selectproduct/active.png" (click)="fn_accept_request(group['id'])" id="accept" class="ml-4" role="button" title="Accept" /> -->
                        <!-- </td> -->
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>
