<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
    <div class="content-wrapper pt-3">
      
      <h3 class="bs-title">HR<span class="spanbs-title">Info Dashboard</span></h3>
      <hr class="mt-0">
      
    </div>
  </div>

</div>