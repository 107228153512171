import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-policieslisting',
  templateUrl: './policieslisting.component.html',
  styleUrls: ['./policieslisting.component.css']
})
export class PolicieslistingComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeleteInfo:FormGroup;

  InfoArray: any = [];
  InfoArray_Role: any = [];
  InfoArraydropdown: any = [];

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDelete = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: false,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo('', 'default');
    this.getInfodropdown();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  //Common functions
  getInfo(val:any, value:any){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    this.InfoArray = [];
    var postdata:any;

    if(value == 'default'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": 0,
        "broker_id": localStorage.getItem('broker_id')
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": val.target.value,
        "broker_id": localStorage.getItem('broker_id')
      };
    }

    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.InfoArray = result.data;
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });

        }, (error) => {

          this.show = false;

          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  getInfodropdown(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id')
    };

    this.dataservice.getInfodropdown(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.InfoArraydropdown = result.data;

        }, (error) => {

          this.InfoArraydropdown = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  fn_deactivatepolicy(id:any){

    var postdata = {
      "id":id,
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "is_active":0
    };

    this.dataservice.activedeactive(postdata)
        .subscribe((result: any) => {
          $("#client_name").val('');
          this.show = false;
          window.location.reload();

        }, (error) => {
          this.show = false;
          console.log(error);
          this.InfoArray = [];

      });
  }

  fn_activatepolicy(id:any){

    var postdata = {
      "id":id,
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "is_active":1
    };

    this.dataservice.activedeactive(postdata)
        .subscribe((result: any) => {
          $("#client_name").val('');
          this.show = false;
          window.location.reload();

        }, (error) => {
          this.show = false;
          console.log(error);
          this.InfoArray = [];

      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDelete = "none";
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  isDecimal(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    if (ipaddress.test(content) == false) {
      $("#ip_address").val('');
    } else {
      $("#ip_address").val(content);
    }
  }

  isDecimal_edit(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    if (ipaddress.test(content) == false) {
      $("#ip_address_edit").val('');
    } else {
      $("#ip_address_edit").val(content);
    }
  }

  goback(){

    this.route.navigate([`/broker-manage-master`]);

  }

  editpolicy(group: any){
    localStorage.setItem('master_policies_id', group.id);
    this.route.navigate(['policy-edit']);
  }

}
