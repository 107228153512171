import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-manage-user-types',
  templateUrl: './manage-user-types.component.html',
  styleUrls: ['./manage-user-types.component.css']
})
export class ManageUserTypesComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];    
  InfomoduleArray: any = [];
  InfomoduleArray_edit: any = [];

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();  
    this.getmoduleInfo();  

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      type_name: new FormControl(''),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      type_name_edit: new FormControl(''),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;
    // this.EditInfo.controls['module_url'].setValue('');
    // this.EditInfo.controls['module_name'].setValue('');
    // this.displayStyle_add = "block";
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      let read:any = [];      
      $('input.read:checkbox').each(function () {

        let isChecked:any;
        if($("#" + $(this).attr('id')).is(":checked") == true){
          isChecked = 1;
        }else{
          isChecked = 0;
        }

        let id:any = $(this).attr('id');
        let myString = id.split("_").pop();
        
        read.push(
          {id: myString, read: isChecked}
        );
      });
      
      let write:any = [];
      $('input.write:checkbox').each(function () {

        let isCheckedwrite:any;
        if($("#" + $(this).attr('id')).is(":checked") == true){
          isCheckedwrite = 1;
        }else{
          isCheckedwrite = 0;
        }

        let id:any = $(this).attr('id');
        let myString = id.split("_").pop();

        write.push(
          {id: myString, write: isCheckedwrite}
        );
      });

      let deleteval:any = [];
      $('input.delete:checkbox').each(function () {
        
        let isCheckeddelete:any;
        if($("#" + $(this).attr('id')).is(":checked") == true){
          isCheckeddelete = 1;
        }else{
          isCheckeddelete = 0;
        }

        let id:any = $(this).attr('id');
        let myString = id.split("_").pop();

        deleteval.push(
          {id: myString, deleteval: isCheckeddelete}
        );
      });

      // console.log(roles);

      var roles = {
        'read': read,
        'write': write,
        'delete': deleteval
      };
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "type_name":this.AddInfo.controls['type_name'].value,
        "client_id":localStorage.getItem('client_id'),
        "roles": JSON.stringify(roles),
      };
      // console.log(postdata);

      this.show = false;

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);                    

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    // this.displayStyle_edit = "block";

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_type_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        this.EditInfo.controls['type_name_edit'].setValue(result.data[0]['type_name']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['user_type_id']);                      

      }, (error) => {          
               
    });

    /*************/

    var postdata2 = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "user_type_id":res.user_type_id
    };

    this.dataservice.getmoduleInfo_edit(postdata2)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfomoduleArray_edit = result.data; 

        }, (error) => {          
          
          this.InfomoduleArray_edit = [];
          
      });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      // let parent:any = [];
      // $('input.parent:checkbox:checked').each(function () {
      //   parent.push($(this).val());
      // });

      let read:any = [];      
      $('input.readedit:checkbox').each(function () {

        let isChecked:any;
        if($("#" + $(this).attr('id')).is(":checked") == true){
          isChecked = 1;
        }else{
          isChecked = 0;
        }

        let id:any = $(this).attr('id');
        let myString = id.split("_").pop();
        
        read.push(
          {paret_id: $(this).attr('value'), 
          id: myString, 
          read: isChecked}
        );
      });
      
      let write:any = [];
      $('input.writeedit:checkbox').each(function () {

        let isCheckedwrite:any;
        if($("#" + $(this).attr('id')).is(":checked") == true){
          isCheckedwrite = 1;
        }else{
          isCheckedwrite = 0;
        }

        let id:any = $(this).attr('id');
        let myString = id.split("_").pop();

        write.push(
          {paret_id: $(this).attr('value'), id: myString, write: isCheckedwrite}
        );
      });

      let deleteval:any = [];
      $('input.deleteedit:checkbox').each(function () {
        
        let isCheckeddelete:any;
        if($("#" + $(this).attr('id')).is(":checked") == true){
          isCheckeddelete = 1;
        }else{
          isCheckeddelete = 0;
        }

        let id:any = $(this).attr('id');
        let myString = id.split("_").pop();

        deleteval.push(
          {paret_id: $(this).attr('value'), id: myString, deleteval: isCheckeddelete}
        );
      });

      var roles = {
        'read': read,
        'write': write,
        'delete': deleteval
      };

      // console.log(roles);
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "type_name":this.EditInfo.controls['type_name_edit'].value,
        "is_active":this.EditInfo.controls['edit_is_active'].value,
        "roles": JSON.stringify(roles),        
      };

      // console.log(postdata);

      this.show = false;

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();

            window.location.reload();

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_type_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['user_type_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_type_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['user_type_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray = result.data; 
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }  

  getmoduleInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    this.dataservice.getmoduleInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfomoduleArray = result.data; 

        }, (error) => {          
          
          this.InfomoduleArray = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }
  
}
