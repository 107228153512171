<div class="container-scroller">
    
  <!-- Navbar -->
  <nav id="my_nav_bar" class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row" *ngIf="restatus == 'true'">
    <div class="text-center navbar-brand-wrapper align-items-center justify-content-start">
      
      <div>
        <a class="navbar-brand brand-logo">
          <!-- <img src="../../../assets/homelogo.png" alt="logo" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin'" />
          <img src="{{broker_logo}}" alt="logo" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin'" /> -->

          <span *ngIf="client_logo == '' || client_logo == null || client_logo == undefined">
            <label title="{{clientname}}" class="namelabel">{{clientname}}</label>                
          </span>

          <span *ngIf="client_logo != ''">
            <img src="../../../assets/homelogo.png" style="margin-top: -40px;" alt="logo" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin' && user_role != 'claimsexecutiveuser' && user_role != 'brokeradmin' && user_role != 'opsmanageruser'" />
            <img src="{{client_logo}}" alt="logo" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin' || user_role == 'claimsexecutiveuser' || user_role == 'opsmanageruser'" />
          </span>
          <img  src="{{broker_logo}}" alt="Profile image" *ngIf="user_role == 'brokeradmin'" style="width: 150px; margin-top: -40px" />

        </a>            
        <!-- <div class="triangle-down"></div>     -->
      </div>      
    </div> 

    <div class="navbar-menu-wrapper d-flex align-items-top">
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" (click)="checknav()" data-bs-toggle="minimize">
        <img src="../../../assets/img/common icons/arrow-left1.png" style="width: 30px;" />
    </button>
      <ul class="navbar-nav">
        <li class="nav-item font-weight-semibold d-none d-lg-block ms-0">
          <h6 class="welcome-text">Welcome 
            <span class="fw-bold" *ngIf="user_type_id != '10' && user_type_id != '11'">{{username}}</span>
            <span class="fw-bold" *ngIf="user_type_id == '10' || user_type_id == '11'">{{name}}</span>
          </h6>
          <!-- <h6 class="welcome-text" *ngIf="user_type_id != '3'">Welcome, <span class="fw-bold">{{username}}</span></h6> -->
        </li>
      </ul>
      <ul class="navbar-nav ms-auto"> 
        <li>
          <span *ngIf="broker_logo == '' || broker_logo == null || broker_logo == undefined">
            <label title="{{brokername}}" class="namelabel" style="text-align: right;" *ngIf="user_type_id != '4'">{{brokername}}</label>
          </span>

          <span *ngIf="broker_logo != ''">
            <span *ngIf="user_type_id != '4'">
              <img class="img-md rounded-circle" src="assets/images/faces/face8.jpg" alt="Profile image" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin' && user_role != 'claimsexecutiveuser' && user_role != 'brokeradmin' && user_role != 'opsmanageruser'">
            </span>
            <img  src="{{broker_logo}}" alt="Profile image" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin' || user_role == 'claimsexecutiveuser' || user_role == 'opsmanageruser'" style="width: 150px; height: 50px;" />
          </span>
        </li>                   
        <li class="nav-item dropdown d-none d-lg-block user-dropdown">
          <a class="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            
            <!-- <img class="img-xs rounded-circle" src="assets/images/faces/face8.jpg" alt="Profile image" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin'"> 
            <img src="{{client_logo}}" alt="logo" alt="Profile image" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin'" style="width: 150px; position: absolute; right: -30px; top: -16px;" /> -->

            <i class="dropdown-item-icon mdi mdi-power text-primary me-2" style="background-color: #00000017; padding: 6px 6px 2px 6px; border-radius: 10px;"></i>            

          </a> 

          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
            <div class="dropdown-header text-center">
              
              <!-- <img class="img-md rounded-circle" src="assets/images/faces/face8.jpg" alt="Profile image" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin' ">
              <img  src="{{broker_logo}}" alt="Profile image" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin'" style="width: 150px;" /> -->

              <!-- <label title="{{brokername}}" class="namelabel" style="font-size: 14px;" *ngIf="brokername != ''">{{brokername}}</label>               -->              
              <p class="mb-1 mt-3 font-weight-semibold">{{username}}</p>
              <p class="fw-light text-muted mb-0">{{user_company}}</p>
            </div>
            <a class="dropdown-item" *ngIf="user_role == 'client'" routerLink="/profile"><i class="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> My
              Profile</a>              
            <a class="dropdown-item" (click)="signout()"><i class="dropdown-item-icon mdi mdi-power text-primary me-2"></i>Sign Out</a>
          </div>                  

        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
        data-bs-toggle="offcanvas">
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </nav>

  <router-outlet>
        
  </router-outlet>          
  
</div>