import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SuperAdminDashboard } from './superAdmin/dashboard/dashboard.component';
import { AdminDashboard } from './admin/dashboard/dashboard.component';
import { ClientDashboard } from './client/dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { ClientCreationComponent } from './client-creation/client-creation.component';
import { PricingComponent } from './client/pricing/pricing.component';
import { ProfileComponent } from './client/profile/profile.component';
import { UsersComponent } from './superAdmin/user mangement/users/users.component';
import { UsersClientComponent } from './superAdmin/user mangement/users-client/users-client.component';
import { EmployeeDependencyComponent } from './employee-dependency/employee-dependency.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { VerifymobileComponent } from './auth/verification/verifymobile/verifymobile.component';
import { VerifyemailComponent } from './auth/verification/verifyemail/verifyemail.component';
import { MarketDataComponent } from './superAdmin/market-data/market-data.component';
import { TpaDetailsComponent } from './superAdmin/tpa-details/tpa-details.component';
import { InsuranceCompanyInsurerComponent } from './superAdmin/insurance-company-insurer/insurance-company-insurer.component';
import { SelectProductComponent } from './client/select-product/select-product.component';
import { SelectProductMobileComponent } from './client/select-product-mobile/select-product-mobile.component';
import { ProductDetailsComponent } from './superAdmin/product-mgmt/product-details/product-details.component';
import { SubproductDetailsComponent } from './superAdmin/product-mgmt/subproduct-details/subproduct-details.component';
import { TopupPolicyDetailsComponent } from './topup-policy-details/topup-policy-details.component';
import { GpaPolicyDetailsComponent } from './gpa-policy-details/gpa-policy-details.component';
import { GtlPolicyDetailsComponent } from './gtl-policy-details/gtl-policy-details.component';
import { OpdPolicyDetailsComponent } from './opd-policy-details/opd-policy-details.component';
import { GoToPoliciesComponent } from './go-to-policies/go-to-policies.component';

import { PolicyConversionGmcComponent } from './superAdmin/policy-conversion-gmc/policy-conversion-gmc.component';
import { PolicyConversionGpaComponent } from './superAdmin/policy-conversion-gpa/policy-conversion-gpa.component';
import { PolicyConversionGtlComponent } from './superAdmin/policy-conversion-gtl/policy-conversion-gtl.component';
import { PolicyConversionOpdComponent } from './superAdmin/policy-conversion-opd/policy-conversion-opd.component';

import { PolicyFinalizeGmcComponent } from './superAdmin/policy-finalize-gmc/policy-finalize-gmc.component';
import { PolicyFinalizeGpaComponent } from './superAdmin/policy-finalize-gpa/policy-finalize-gpa.component';
import { PolicyFinalizeGtlComponent } from './superAdmin/policy-finalize-gtl/policy-finalize-gtl.component';
import { PolicyFinalizeOpdComponent } from './superAdmin/policy-finalize-opd/policy-finalize-opd.component';

import { SurveyLoginComponent } from './survey/auth/survey-login/survey-login.component';
import { SurveyRegisterComponent } from './survey/auth/survey-register/survey-register.component';
import { SurveyEmailVerificationComponent } from './survey/auth/survey-verification/survey-email-verification/survey-email-verification.component';

import { SurveySelectProductComponent } from './survey/survey-select-product/survey-select-product.component';

import { SurveyGmcPolicyDetailsComponent } from './survey/survey-gmc-policy-details/survey-gmc-policy-details.component';
import { SurveyGpaPolicyDetailsComponent } from './survey/survey-gpa-policy-details/survey-gpa-policy-details.component';
import { SurveyGtlPolicyDetailsComponent } from './survey/survey-gtl-policy-details/survey-gtl-policy-details.component';
import { SurveyOpdPolicyDetailsComponent } from './survey/survey-opd-policy-details/survey-opd-policy-details.component';

import { AboutUsComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';

import { BenefitBenchmarkingComponent } from './survey/benefit-benchmarking/benefit-benchmarking.component';

import { SurveyManagerComponent } from './superAdmin/survey-manager/survey-manager.component';

import { ManageCountryComponent } from './superAdmin/manage-country/manage-country.component';
import { ManageStateComponent } from './superAdmin/manage-state/manage-state.component';
import { ManageCityComponent } from './superAdmin/manage-city/manage-city.component';
import { ManageBrokerComponent } from './superAdmin/manage-broker/manage-broker.component';
import { ManageIndustryComponent } from './superAdmin/manage-industry/manage-industry.component';
import { ManageUserTypesComponent } from './superAdmin/manage-user-types/manage-user-types.component';
import { ManagePlanComponent } from './superAdmin/manage-plan/manage-plan.component';
import { ManageAnnoucementsComponent } from './superAdmin/manage-annoucements/manage-annoucements.component';
import { ManageNewsComponent } from './superAdmin/manage-news/manage-news.component';
import { MasterBrokerComponent } from './superAdmin/master-broker/master-broker.component';
import { AddUserComponent } from './superAdmin/master-broker/add-user/add-user.component';
import { AdminthemeComponent } from './superAdmin/admintheme/admintheme.component';

//broker
import { DashboardComponent } from './broker/dashboard/dashboard.component';
import { EmployerDashboardComponent } from './broker/employer-dashboard/employer-dashboard.component';
import { BrokerManageMasterEmployerComponent } from './broker/broker-manage-master-employer/broker-manage-master-employer.component';
import { AddBrokerUserComponent } from './broker/add-broker-user/add-broker-user.component';
import { UserManagementComponent } from './broker/user-management/user-management.component';
import { EmployeeUserManagementComponent } from './broker/employee-user-management/employee-user-management.component';
import { BrokerManageMasterComponent } from './broker/broker-manage-master/broker-manage-master.component';
import { AdminmastermodulesComponent } from './superAdmin/adminmastermodules/adminmastermodules.component';
import { SubModulesComponent } from './superAdmin/adminmastermodules/sub-modules/sub-modules.component';

import { HrInfoDashboardComponent } from './hr_info/hr-info-dashboard/hr-info-dashboard.component';

import { BrokeruserDashboardComponent } from './broker/brokeruser-dashboard/brokeruser-dashboard.component';
import { EmployeruserDashboardComponent } from './broker/employeruser-dashboard/employeruser-dashboard.component';

import { PoliciesComponent } from './superAdmin/policies/policies.component';
import { PolicieslistingComponent } from './superAdmin/policieslisting/policieslisting.component';
import { EndorsementrequestComponent } from './broker/endorsementrequest/endorsementrequest.component';

import { FamilyContructComponent } from './broker/employeruser-dashboard/family-contruct/family-contruct.component';
import { EmployeeBenefitComponent } from './broker/employeruser-dashboard/employee-benefit/employee-benefit.component';
import { FamilyEnrollmentComponent } from './broker/employeruser-dashboard/family-contruct/family-enrollment/family-enrollment.component';

import { BrokerManageMasterEditComponent } from './broker/broker-manage-master/broker-manage-master-edit/broker-manage-master-edit.component';

import { PolicyEditComponent } from './broker/policy-edit/policy-edit.component';

import { ClaimStatusComponent } from './broker/claim-status/claim-status.component';
import { ClaimIntimationComponent } from './broker/claim-intimation/claim-intimation.component';
import { ClaimDocumentComponent } from './broker/claim-document/claim-document.component';

import { ClaimsexecutiveuserDashboardComponent } from './broker/add-broker-user/claimsexecutiveuser-dashboard/claimsexecutiveuser-dashboard.component';

import { ClaimIntimationClientexetutiveuserComponent } from './broker/claim-intimation-clientexetutiveuser/claim-intimation-clientexetutiveuser.component';
import { ProductFeatureMasterComponent } from './broker/product-feature-master/product-feature-master.component';

import { FlexConfiguratorComponent } from './broker/flex-configurator/flex-configurator.component';

import { PolicyDetailsFamilyComponent } from './broker/employeruser-dashboard/family-contruct/family-enrollment/policy-details-family/policy-details-family.component';

import { BrokerInsuranceCompanyComponent } from './broker/broker-insurance-company/broker-insurance-company.component';

import { AcceptRelationshipRequestComponent } from './broker/accept-relationship-request/accept-relationship-request.component';

import { CdUpdationComponent } from './broker/cd-updation/cd-updation.component';

import { EndorsementListingComponent } from './broker/endorsement-listing/endorsement-listing.component';

import { BrokerEndoTatReportComponent } from './broker/broker-endo-tat-report/broker-endo-tat-report.component';
import { BrokerClaimTatReportComponent } from './broker/broker-claim-tat-report/broker-claim-tat-report.component';

// Client user execute lisiting
import { ListingComponent } from './broker/claim-intimation-clientexetutiveuser/listing/listing.component';
import { ClaimTatReportComponent } from './broker/claim-tat-report/claim-tat-report.component';

// OPS Manager
import { OpsDasboardComponent } from './ops-manager/ops-dasboard/ops-dasboard.component';
import { OpsEndorsementComponent } from './ops-manager/ops-endorsement/ops-endorsement.component';
import { OpsNewRelationshipRequestComponent } from './ops-manager/ops-new-relationship-request/ops-new-relationship-request.component';
import { OpsCdSummaryComponent } from './ops-manager/ops-cd-summary/ops-cd-summary.component';
import { OpsEndorsementTatReportComponent } from './ops-manager/ops-endorsement-tat-report/ops-endorsement-tat-report.component';
import { OpsBulkEndorsementComponent } from './ops-manager/ops-bulk-endorsement/ops-bulk-endorsement.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'SuperAdmin-dashboard', component: SuperAdminDashboard },
  { path: 'Client-dashboard', component: ClientDashboard },
  { path: 'Admin-dashboard', component: AdminDashboard },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'home', component: LoginComponent },
  { path: 'clientCreation', component: ClientCreationComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'admin-users', component: UsersComponent },
  { path: 'admin-users-Client', component: UsersClientComponent },
  { path: 'employee-Dependency', component: EmployeeDependencyComponent },
  { path: 'policy-details', component: PolicyDetailsComponent },
  { path: 'verify-mobile', component: VerifymobileComponent },
  { path: 'verify-email', component: VerifyemailComponent },
  { path: 'admin-market-data', component: MarketDataComponent },
  { path: 'admin-tpa-details', component: TpaDetailsComponent },
  { path: 'admin-insurance-company-insurer', component: InsuranceCompanyInsurerComponent },
  { path: 'select-product', component: SelectProductComponent },
  { path: 'select-product-mobile', component: SelectProductMobileComponent },
  { path: 'admin-product-details', component: ProductDetailsComponent },
  { path: 'admin-subproduct-details', component: SubproductDetailsComponent },
  { path: 'topup-policy-details', component: TopupPolicyDetailsComponent },
  { path: 'gpa-policy-details', component: GpaPolicyDetailsComponent },
  { path: 'gtl-policy-details', component: GtlPolicyDetailsComponent },
  { path: 'opd-policy-details', component: OpdPolicyDetailsComponent },
  { path: 'go-to-policies', component: GoToPoliciesComponent },
  { path: 'admin-policy-conversion-gmc', component: PolicyConversionGmcComponent },
  { path: 'admin-policy-conversion-gpa', component: PolicyConversionGpaComponent },
  { path: 'admin-policy-conversion-gtl', component: PolicyConversionGtlComponent },
  { path: 'admin-policy-conversion-opd', component: PolicyConversionOpdComponent },
  { path: 'admin-policy-finalize-gmc', component: PolicyFinalizeGmcComponent},
  { path: 'admin-policy-finalize-gpa', component: PolicyFinalizeGpaComponent},
  { path: 'admin-policy-finalize-gtl', component: PolicyFinalizeGtlComponent},
  { path: 'admin-policy-finalize-opd', component: PolicyFinalizeOpdComponent},
  { path: 'survey-login', component: SurveyLoginComponent},
  { path: 'survey-register', component: SurveyRegisterComponent},
  { path: 'survey-verify-email', component: SurveyEmailVerificationComponent},
  { path: 'survey-select-product', component: SurveySelectProductComponent},
  { path: 'survey-gmc-policy-details', component: SurveyGmcPolicyDetailsComponent},
  { path: 'survey-gpa-policy-details', component: SurveyGpaPolicyDetailsComponent},
  { path: 'survey-gtl-policy-details', component: SurveyGtlPolicyDetailsComponent},
  { path: 'survey-opd-policy-details', component: SurveyOpdPolicyDetailsComponent},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'benefit-benchmarking', component: BenefitBenchmarkingComponent},
  { path: 'admin-survey-manager', component: SurveyManagerComponent},
  { path: 'admin-manage-country', component: ManageCountryComponent},
  { path: 'admin-manage-state', component: ManageStateComponent},
  { path: 'admin-manage-city', component: ManageCityComponent},
  { path: 'admin-manage-broker', component: ManageBrokerComponent},
  { path: 'admin-manage-industry', component: ManageIndustryComponent},
  { path: 'admin-manage-user-types', component: ManageUserTypesComponent},
  { path: 'admin-manage-plan', component: ManagePlanComponent},
  { path: 'admin-manage-annoucements', component: ManageAnnoucementsComponent},
  { path: 'admin-manage-news', component: ManageNewsComponent},
  { path: 'broker-dashboard', component: DashboardComponent},
  { path: 'admin-manage-master-broker', component: MasterBrokerComponent},
  { path: 'admin-manage-master-broker/broker-user', component: AddUserComponent},
  { path: 'broker-manage-master-employer', component: BrokerManageMasterEmployerComponent},
  { path: 'employer-dashboard', component: EmployerDashboardComponent},
  { path: 'broker-manage-master-employer/employer-user', component: AddBrokerUserComponent},
  { path: 'admin-manage-theme', component: AdminthemeComponent},
  { path: 'admin-mastermodules', component: AdminmastermodulesComponent},
  { path: 'broker-manage-master-employer/user-management', component: UserManagementComponent},
  { path: 'employee-user-management', component: EmployeeUserManagementComponent},
  { path: 'broker-manage-master', component: BrokerManageMasterComponent},
  { path: 'admin-mastermodules/sub-modules', component: SubModulesComponent},
  { path: 'hr-info-dashboard', component: HrInfoDashboardComponent},
  { path: 'brokeruser-dashboard', component: BrokeruserDashboardComponent},
  { path: 'employeruser-dashboard', component: EmployeruserDashboardComponent},
  { path: 'policies', component: PoliciesComponent},
  { path: 'listpolicies', component: PolicieslistingComponent},
  { path: 'accept-relationship-request', component: AcceptRelationshipRequestComponent},
  { path: 'listendorsement', component: EndorsementListingComponent},
  { path: 'cd-updation', component: CdUpdationComponent},
  { path: 'endorsement-request', component: EndorsementrequestComponent},
  { path: 'employeruser-dashboard/family-contruct', component: FamilyContructComponent},
  { path: 'employeruser-dashboard/employee_benefit', component: EmployeeBenefitComponent},
  { path: 'employeruser-dashboard/family-enrollment', component: FamilyEnrollmentComponent},    
  { path: 'broker-manage-master-edit', component: BrokerManageMasterEditComponent},
  { path: 'broker-endorsement-tat-report', component: BrokerEndoTatReportComponent},
  { path: 'broker-claims-tat-report', component: BrokerClaimTatReportComponent},
  { path: 'policy-edit', component: PolicyEditComponent},
  { path: 'claim-status', component: ClaimStatusComponent},
  { path: 'claim-intimation', component: ClaimIntimationComponent},
  { path: 'claim-document', component: ClaimDocumentComponent},
  { path: 'claimsexecutiveuser-dashboard', component: ClaimsexecutiveuserDashboardComponent},
  { path: 'executive-user/claim-intimation', component: ClaimIntimationClientexetutiveuserComponent},
  { path: 'product-feature-master', component: ProductFeatureMasterComponent},
  { path: 'flex-configurator', component: FlexConfiguratorComponent},
  { path: 'employeruser-dashboard/family-enrollment/policy-details-family', component: PolicyDetailsFamilyComponent}, 
  { path: 'broker-insurance-company', component: BrokerInsuranceCompanyComponent},
  { path: 'ops-dashboard', component: OpsDasboardComponent},
  { path: 'ops-endorsement', component: OpsEndorsementComponent},
  { path: 'ops-endorsement-tat-report', component: OpsEndorsementTatReportComponent},
  { path: 'ops-new-relationship-request', component: OpsNewRelationshipRequestComponent},
  { path: 'ops-cd-summary', component: OpsCdSummaryComponent},    
  { path: 'ops-bulk-endorsement', component: OpsBulkEndorsementComponent},    
  
  // Client user execute lisiting
  { path: 'client-user-listing', component: ListingComponent},
  { path: 'claim-tat-report', component: ClaimTatReportComponent}, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
