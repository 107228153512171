import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { OPDPolicyDetailsService } from '../opd-policy-details/opd-details';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-opd-policy-details',
  templateUrl: './opd-policy-details.component.html',
  styleUrls: ['./opd-policy-details.component.css']
})
export class OpdPolicyDetailsComponent implements OnInit {
  
  public policyDetails:FormGroup;
  public verifypolicyDetails:FormGroup;
  public policyFeatures:FormGroup;
  public documentUpload:FormGroup;

  private stepper: Stepper;

  policyDetailsSubmitted: boolean = false;
  verifypolicyDetailsSubmitted: boolean = false;
  policyFeaturesSubmitted: boolean = false;
  documentUploadSubmitted: boolean = false;

  isuranceCompanyDetailsarr: any = [];
  tpadetailsArr: any = [];
  brokerdetailsArr: any = [];
  productsArr:  any = [];

  fileData: any;
  fileName: any = '';

  resstatus = localStorage.getItem('resstatus');
  CLIENT_ID = localStorage.getItem('client_id');
  plan_page_flag = localStorage.getItem('plan_page_flag');

  validmsg: any;

  upload_policydocument: any;
  upload_your_active_member_data: any;
  upload_claim_MIS: any;
  upload_claim_pdf_summary: any;
  policy_document: any;

  cnt = 1;
  cnt_others = 1;
  cnt_graded = 1;
  cnt_graded_topup = 1;
  uploadcnt = 1;
  amt = 1;
  checkopt_val = 1;

  benefitArr: any = {};
  othersArr: any = {};
  gradedArrSI: any = {};
  gradedArrSI_topup: any = {};

  uploadExtraArr: any = {};
  gradedArr: any = {};
  metroArr: any = {};

  hospitalizationArr: any = {};
  hospitalization_tableArr: any = [];
  ICU_tableArr: any = [];

  maternityArr: any = {};
  normal_delivery_limit_Arr: any = [];
  c_section_limit_Arr: any = [];

  mygradedarray: any = [];
  mygradedarray_text: any = [];

  client_product_id: any = 0;
  client_product_policy_id: any = 0;
  benefit_id: any = 0;

  displayStyleThankyou = "none";

  dropdownList: any = [];
  selectedItems: any =[];
  dropdownSettings = {};
  familyArrData: any = [];
  familyArr: any = {};

  greatest_graded_value: any;

  no_flow_flag: boolean = false;

  isDisplay:any;

  stepper_flag: boolean = false;

  flag_firstcheck_SI:any = 0;  
  flag_firstcheck_additionalbenefits:any = 0;  
  flag_firstcheck_WPC:any = 0;
  
  flagSI:any = 0;  
  flagadditionalbenefits:any = 0;  
  flagWPC:any = 0;

  metrocnt = 1;
  
  opd_policy_check:any;
  
  addtext:any;

  proposal_number_show: any;

  MOSArr: any;

  stepcomplete: any = '';

  min_date: any;
  max_date: any;

  constructor(private fb: FormBuilder, private opdPolicyDetailsService: OPDPolicyDetailsService, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == null) {
          this.route.navigate(['/home']);
      }
    });

  }

  ngOnInit(): void {

    this.initGroup();
    
    this.Prog(0,'SI');
    this.Prog(0,'waiting');
    this.Prog(0,'Others');

  }

  initGroup(){

    this.opd_policy_check = localStorage.getItem('opd_policy_check');

    this.getIsuranceCompanyDetails();
    this.getBrokerDetails();
    
    if(this.plan_page_flag == ''){
      this.plan_page_flag = '';
    }else{
      this.plan_page_flag = localStorage.getItem('plan_page_flag');
    }

    this.validmsg = '';
    $('#validmsg').css('display', 'none');      
    $('#sumisured_accord').css('display','none');
    $('#waitingPeriodCondition_accord').css('display','none');      
    $('#OPD_accord').css('display','none');
    $('#Others_accord').css('display','none')

    $('#EPD').prop('disabled',true);

    // Define Stepper
    const stepperElement = document.querySelector('#stepper2');
    if(stepperElement !== null){
    this.stepper = new Stepper(stepperElement , {
          linear: false,
          animation: true
        })
    }

    // Step 1 Form element defined below
    this.policyDetails = this.fb.group({            
      upload_check: new FormControl('manually'),
      OPD_program: new FormControl('Insurance company'),
      insurance_company: new FormControl(1),
      broker_name: new FormControl(1),
      upload_policydocument: new FormControl('')
    });
    $('.existing_policy_check_yesno').css('display','none');
    
    // Step 2 Form element defined below                    
    this.verifypolicyDetails = this.fb.group({
      policy_date: new FormControl(0),
      how_soon_buy: new FormControl(''),
      choose_month: new FormControl(0),
      policy_expirydate: new FormControl(''),
      data_report_date: new FormControl(''),
      policy_number: new FormControl(0),        
      emp_atstart: new FormControl(''),
      lives_covered: new FormControl(0),
      emp_atreport: new FormControl(0),
      lives_atreport: new FormControl(0)
    });

    // Step 3 Form element defined below
    this.policyFeatures = this.fb.group({        
      
      employees: new FormControl('No'),
      employees_limit: new FormControl(''),

      spouse: new FormControl('No'),
      spouse_limit: new FormControl(''),

      child: new FormControl('No'),
      child_limit: new FormControl(''),

      parents: new FormControl('No'),
      parents_limit: new FormControl(''),

      sibling: new FormControl('No'),
      sibling_limit: new FormControl(''),

      // Others              
      prescription_medications: new FormControl('No'),
      prescription_medications_limit: new FormControl(''),

      consultations: new FormControl('No'),
      consultations_limit: new FormControl(''),

      diagnostics_or_health_checkups: new FormControl('No'),
      diagnostics_or_health_checkups_limit: new FormControl(''),

      dental: new FormControl('No'),
      dental_limit: new FormControl(''),

      vision: new FormControl('No'),
      vision_limit: new FormControl(''),

      vision_physiotherapy_hospitalizationlimit: new FormControl('No'),
      vision_physiotherapy_hospitalizationlimit_limit: new FormControl(''),
      
      physiotherapy_hospitalization: new FormControl('No'),
      physiotherapy_hospitalization_limit: new FormControl(''),

      psychiatric_treatment: new FormControl('No'),
      psychiatric_treatment_limit: new FormControl(''),

      vaccinations: new FormControl('No'),
      vaccinations_limit: new FormControl(''),     

      //Policy Restrictions        
      copay_applicable_OPD_program: new FormControl('No'),
      copay_applicable_OPD_program_limit: new FormControl(''),  
      
      OPD_restricted_network: new FormControl('No'),
      OPD_restricted_network_limit: new FormControl(''),
      
    });

    // Step 4 Form element defined below
    this.documentUpload = this.fb.group({
      upload_your_active_member_data: new FormControl(''),
      active_member_data_report_date: new FormControl(''),
      upload_claim_MIS: new FormControl(''),
      claim_MIS_date: new FormControl(''),
      upload_claim_pdf_summary: new FormControl(''),
      policy_document: new FormControl('')
    });      

    
    if(this.opd_policy_check == 'Yes'){
      this.no_flow_flag = true;
      $('.existing_policy_check_yesno').css('display','block');
      $('.policy_date').css('display','block'); 
      $('.how_soon_buy').css('display','none');  
      $('.lives_covered').css('display','block');
      this.policyDetails.controls['insurance_company'].setValue('');
      this.policyDetails.controls['broker_name'].setValue('');       
    }else{
      this.no_flow_flag = false;
      $('.existing_policy_check_yesno').css('display','none');
      $('.policy_date').css('display','none'); 
      $('.how_soon_buy').css('display','block');
      $('.lives_covered').css('display','none');        
      this.policyDetails.controls['insurance_company'].setValue(1);
      this.policyDetails.controls['broker_name'].setValue(1);                
    }

  }    

  mouseEnter(evt : any, val:any){      
    $("#" + val).css('display', 'block');    
  }

  mouseLeave(evt : any, val:any){
    $("#" + val).css('display', 'none');
  }   

  entermanually(){
    if(this.policyDetails.controls['upload_check'].value == 'manually'){
      $('#div_upload_policydocument').css('display','none');
      $('#sample_template_excel').css('display','none');
      $('#excel_flow_save').css('display','none');
      $('#manual_flow_save').css('display','block');
    }else{
      $('#div_upload_policydocument').css('display','block');
      $('#sample_template_excel').css('display','block');
      $('#excel_flow_save').css('display','block');
      $('#manual_flow_save').css('display','none');
    }
  }

  how_soon_buy_check(evt:any){
    var val = evt.target.value;
    if(val == "Specific month"){
      $('.choose_month').css('display','block');
      $('.emp_atstart').addClass('mt-3');
    }else{
      $('.choose_month').css('display','none');
      $('.emp_atstart').removeClass('mt-3');
    }

  }

  lives_covered(evt: any){
    
    var id = evt.target.id;
    var value: any = evt.target.value.replaceAll(',', '');

    if(id == "lives_covered"){
      var temp:any = $('#emp_atstart').val();      
    }else{
      var temp:any = $('#emp_atreport').val();      
    }
    
    var emp_atstart:any = temp.replaceAll(',', '');
    console.log(value+" <",emp_atstart);

    if(value < emp_atstart){   
      console.log('yes');     
      $('#' + id).val('');        
    }else{
      console.log('no');
    }      
    
  }


  onItemSelect(item: any) {
    // console.log(item);
    this.familyArrData.push(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
    this.familyArrData = items;
  }

  isformat(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }  
    if(evt.target.value == "0"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }    
    return true;
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  get getpolicyDetailsFormControls() {
    return this.policyDetails.controls;
  }

  get getverifypolicyDetailsFormControls() {
    return this.verifypolicyDetails.controls;
  }

  get getpolicyFeaturesFormControls() {
    return this.policyFeatures.controls;
  }

  get getdocumentUploadFormControls() {
    return this.documentUpload.controls;
  }

  // Stepper Next Event
  next(step: number) {
    if (step == 1) {        
      
      this.policyDetailsSubmitted = true;
      if (this.policyDetailsSubmitted && this.policyDetails.valid) {
        if(this.policyDetails.controls['upload_check'].value == 'policy_template'){
          this.onsubmit_first('policy_template');
        }else{
          this.onsubmit_first('No');
          this.stepper.next();
          
          this.stepcomplete = 'step1';
          
          if(this.opd_policy_check == "Yes"){
            this.stepper_flag = true;
            $('.step2hideFields').css('display','block');   
            this.verifypolicyDetails.controls['policy_date'].setValue('');
            this.verifypolicyDetails.controls['policy_expirydate'].setValue('');
            this.verifypolicyDetails.controls['data_report_date'].setValue('');
            this.verifypolicyDetails.controls['policy_number'].setValue('');
            this.verifypolicyDetails.controls['emp_atreport'].setValue('');
            this.verifypolicyDetails.controls['lives_atreport'].setValue('');
            this.verifypolicyDetails.controls['lives_covered'].setValue('');
            this.verifypolicyDetails.controls['emp_atstart'].setValue('');
            this.verifypolicyDetails.controls['how_soon_buy'].setValue('Immediatly');
            // $('.choose_month').css('display','none');           

          }else{
            this.stepper_flag = false;
            $('.step2hideFields').css('display','none');            
  
            this.verifypolicyDetails.controls['policy_date'].setValue(0);
            this.verifypolicyDetails.controls['policy_expirydate'].setValue(0);
            this.verifypolicyDetails.controls['data_report_date'].setValue(0);
            this.verifypolicyDetails.controls['policy_number'].setValue(0);
            this.verifypolicyDetails.controls['emp_atreport'].setValue(0);
            this.verifypolicyDetails.controls['lives_atreport'].setValue(0);   
            this.verifypolicyDetails.controls['lives_covered'].setValue(0);
            // this.verifypolicyDetails.controls['how_soon_buy'].setValue('');  
            // $('.choose_month').css('display','block');         

          }

        }          

        console.log(this.policyDetails.value);
      }
    }
    if (step == 2) {

      if(this.no_flow_flag == true){  
        console.log(this.verifypolicyDetails.value);        
        this.verifypolicyDetailsSubmitted = true;
        if (this.verifypolicyDetailsSubmitted && this.verifypolicyDetails.valid) {          
          this.onsubmit_second();
          this.stepper.next();
          this.stepcomplete = 'step2';
          console.log(this.verifypolicyDetails.value);
        }  
      }else{        
        console.log(this.verifypolicyDetails.value);  
        this.verifypolicyDetailsSubmitted = true;
        if (this.verifypolicyDetailsSubmitted && this.verifypolicyDetails.valid) {
          this.onsubmit_second();
          this.stepper.next();
          this.stepcomplete = 'step2';
          console.log(this.verifypolicyDetails.value);
        }
      }
      
    }
    if (step == 3) {                                        

      /********************** Family Show *********************/
      if ($('#sumisured_accord').css('display') == 'none'){
        $('#sumisured_accord').css('display','block');
        $('#sumisured_down_arrow').css('display','block');
        $('#sumisured_up_arrow').css('display','none');
      }
      /***************************** END *******************************/


      /********************** PR Show *********************/
      if ($('#waitingPeriodCondition_accord').css('display') == 'none'){
        $('#waitingPeriodCondition_accord').css('display','block');
        $('#WPC_down_arrow').css('display','block');
        $('#WPC_up_arrow').css('display','none');
      }
      /***************************** END *******************************/              


      /********************** Additional benefits Show *****************/
      if ($('#Others_accord').css('display') == 'none'){
        $('#Others_accord').css('display','block');
        $('#Others_down_arrow').css('display','block');
        $('#Others_up_arrow').css('display','none');
      }
      /***************************** END *******************************/

      /*************** Family fields *****************/      

      var errorArr_SI_type = ['employees_limit', 'spouse_limit', 'child_limit', 'parents_limit', 'sibling_limit'];
      
      for(var i = 0; i < errorArr_SI_type.length; i++){
        
        var val = errorArr_SI_type[i];
        var id = errorArr_SI_type[i] + '_error';
       
        if($('#' + val + ':visible').length == 0){                                    

        }else{
          if(this.policyFeatures.controls[val].value == ''){
            $('#' + id).css('display', 'block');
            $('#' + val).addClass('control-red');
          }else{
            $('#' + id).css('display', 'none');
            $('#' + val).removeClass('control-red');
          }
        }          

      }             
      /******************************  END *******************************/


      /*************** Policy Restriction required fields *****************/      

      var errorArr_waiting = ['copay_applicable_OPD_program_limit'];
      
      for(var i = 0; i < errorArr_waiting.length; i++){
        
        var val = errorArr_waiting[i];
        var id = errorArr_waiting[i] + '_error';
       
        if($('#' + val + ':visible').length == 0){                                    

        }else{
          if(this.policyFeatures.controls[val].value == ''){
            if(val == 'is_spouse_life_cover_limit' || val == 'is_spouse_life_cover_limit_INR'){
              $('#' + id).css('display', 'block');
              $('#' + id).css('position', 'absolute');
              $('#' + id).css('margin-top', '30px');
            }else{
              $('#' + id).css('display', 'block');
            }
            $('#' + id).css('display', 'block');
            $('#' + val).addClass('control-red');
          }else{
            $('#' + id).css('display', 'none');
            $('#' + val).removeClass('control-red');
          }
        }          

      }             
      /******************************  END *******************************/


      
      /**************** Additional benefits required fields **************/        

      var errorArr_others = ['OPD_coverage_limit', 'prescription_medications_limit',
                              'consultations_limit', 'diagnostics_or_health_checkups_limit',
                              'dental_limit', 'vision_limit',
                              'vision_physiotherapy_hospitalizationlimit_limit', 'physiotherapy_hospitalization_limit',
                              'psychiatric_treatment_limit',
                              'vaccinations_limit'];
      
      for(var i = 0; i < errorArr_others.length; i++){
        
        var val = errorArr_others[i];
        var id = errorArr_others[i] + '_error';
       
        if($('#' + val + ':visible').length == 0){                                    

        }else{
          if(this.policyFeatures.controls[val].value == ''){
            $('#' + id).css('display', 'block');
            $('#' + val).addClass('control-red');
          }else{
            $('#' + id).css('display', 'none');
            $('#' + val).removeClass('control-red');
          }
        }          

      }             
      /******************************  END *******************************/
            

      /********************* Open/Close Family ******************/              
      
      var id_SI = '#sumisured_accord';        

      var errorArr_SI = ['employees_limit', 'spouse_limit', 'child_limit', 'parents_limit', 'sibling_limit'];
              
      for(var i = 0; i < errorArr_SI.length; i++){
                  
        var val = errorArr_SI[i];
        var cond = $('#' + val).is(':visible');
        
        if(cond){

          // console.log('input visible');

          var cond2 = $(id_SI + ' .error').is(':visible');

          if (cond2){  
            // console.log('yes error'); 
            this.flagSI = 0;
          }else{
            // console.log('no error');
            this.flagSI = 1;
          }

        }else{
          // console.log('input not visible');            

          var cond2 = $(id_SI + ' .error').is(':visible');

          if (cond2){
            this.flagSI = 0;
          }else{
            this.flagSI = 1;
          }

        }                    
        
      }

      console.log('SI flag - ', this.flagSI);
      
      if(this.flagSI == 0){
        this.flag_firstcheck_SI = 1;
        $('#SItick').css('display','none');
        $('#SItick_error').css('display','block');
        if ($(id_SI).css('display') == 'none'){
          $(id_SI).css('display','block');            
          
          $('#sumisured_down_arrow').css('display','none');
          $('#sumisured_up_arrow').css('display','block');
        }

        // $('.SIheading').css('box-shadow','0px 0px 5px 2px #df0000');
        this.Prog(50, 'SI');
        
      }else{
        // $(id_SI).css('display','none');
        $(id_SI).css('display','none');

        if(this.flag_firstcheck_SI == 1){
          $('#SItick').css('display','block');          
          $('#SItick_error').css('display','none');
          $('#sumisured_down_arrow').css('display','block');
          $('#sumisured_up_arrow').css('display','none');            
          // $('.SIheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
          this.Prog(100, 'SI');
        }
      }        
      /**************************** END ***********************************/


      /********************* Open/Close PR ******************/              
      
      var id_WPC = '#waitingPeriodCondition_accord';        

      var check_errorArr_WPC = ['copay_applicable_OPD_program_limit'];
              
      for(var i = 0; i < check_errorArr_WPC.length; i++){
                  
        var val = check_errorArr_WPC[i];
        var cond = $('#' + val).is(':visible');
        
        if(cond){

          // console.log('input visible');

          var cond2 = $(id_WPC + ' .error').is(':visible');

          if (cond2){  
            // console.log('yes error'); 
            this.flagWPC = 0;
          }else{
            // console.log('no error');
            this.flagWPC = 1;
          }

        }else{
          // console.log('input not visible');            

          var cond2 = $(id_WPC + ' .error').is(':visible');

          if (cond2){
            this.flagWPC = 0;
          }else{
            this.flagWPC = 1;
          }

        }                    
        
      }

      console.log('WPC flag - ', this.flagWPC);
      
      if(this.flagWPC == 0){
      this.flag_firstcheck_WPC = 1;
      $('#waitingtick').css('display','none');
      $('#waitingtick_error').css('display','block');
        if ($(id_WPC).css('display') == 'none'){
          $(id_WPC).css('display','block');            
          
          $('#WPC_down_arrow').css('display','none');
          $('#WPC_up_arrow').css('display','block');
        }

        // $('.waitingheading').css('box-shadow','0px 0px 5px 2px #df0000');
        this.Prog(50, 'waiting');
        
      }else{
        // $(id_WPC).css('display','none');
        $(id_WPC).css('display','none');

        if(this.flag_firstcheck_WPC == 1){
          $('#waitingtick').css('display','block');
          $('#waitingtick_error').css('display','none');
          
          $('#WPC_down_arrow').css('display','block');
          $('#WPC_up_arrow').css('display','none');
          
          // $('.waitingheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
          this.Prog(100, 'waiting');
        }
      }        
      /**************************** END ***********************************/      


      /********************* Open/Close Additional Benefits ******************/              
      
      var id_additionalbenefits = '#Others_accord';        

      var check_errorArr_others = ['OPD_coverage_limit', 'prescription_medications_limit',
                                    'consultations_limit', 'diagnostics_or_health_checkups_limit',
                                    'dental_limit', 'vision_limit',
                                    'vision_physiotherapy_hospitalizationlimit_limit', 'physiotherapy_hospitalization_limit',
                                    'psychiatric_treatment_limit',
                                    'vaccinations_limit'];
              
      for(var i = 0; i < check_errorArr_others.length; i++){
                  
        var val = check_errorArr_others[i];
        var cond = $('#' + val).is(':visible');
        
        if(cond){

          // console.log('input visible');

          var cond2 = $(id_additionalbenefits + ' .error').is(':visible');

          if (cond2){  
            // console.log('yes error'); 
            this.flagadditionalbenefits = 0;
          }else{
            // console.log('no error');
            this.flagadditionalbenefits = 1;
          }

        }else{
          // console.log('input not visible');            

          var cond2 = $(id_additionalbenefits + ' .error').is(':visible');

          if (cond2){
            this.flagadditionalbenefits = 0;
          }else{
            this.flagadditionalbenefits = 1;
          }

        }                    
        
      }

      console.log('Additional benefits flag - ', this.flagadditionalbenefits);
      
      if(this.flagadditionalbenefits == 0){
        this.flag_firstcheck_additionalbenefits = 1;
        $('#additionalbenefitstick').css('display','none');
        $('#additionalbenefitstick_error').css('display','block');
        if ($(id_additionalbenefits).css('display') == 'none'){
          console.log('second time');
          $(id_additionalbenefits).css('display','block');            
          
          $('#Others_down_arrow').css('display','none');
          $('#Others_up_arrow').css('display','block');
        }

        // $('.additionalbenefitsheading').css('box-shadow','0px 0px 5px 2px #df0000');
        this.Prog(50, 'Others');
        
      }else{
        // $(id_additionalbenefits).css('display','none');
        $(id_additionalbenefits).css('display','none');

        if(this.flag_firstcheck_additionalbenefits == 1){
          $('#additionalbenefitstick').css('display','block');
          $('#additionalbenefitstick_error').css('display','none');
          
          $('#Others_down_arrow').css('display','block');
          $('#Others_up_arrow').css('display','none');
          
          // $('.additionalbenefitsheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
          this.Prog(100, 'Others');
        }
      }        
      /**************************** END ***********************************/
              

      this.policyFeaturesSubmitted = true;       
           
      /********* Others Array ********/
      for(var i = 1; i < this.cnt_others; i++){            
        this.othersArr[i] = {bname: $('#others_name_'+i).val(), blimit:$('#others_limit_'+i).val()};                       
      }

      let objecto = this.othersArr;
      for (var key in objecto) {
        if (objecto.hasOwnProperty(key)) {
            if (objecto[key]['bname'] === undefined || objecto[key]['bname'] === null){
                delete objecto[key];
            }
        }
      }
      // console.log("before ==> ", this.othersArr);

      // Call API
      this.onsubmit_third();
      console.log(this.policyFeatures.value);                        



      if(this.flagSI == 1 && 
        this.flagadditionalbenefits == 1 &&        
        this.flagWPC == 1 &&          
        this.policyFeaturesSubmitted && this.policyFeatures.valid &&
        this.flag_firstcheck_SI == 1 && 
        this.flag_firstcheck_additionalbenefits == 1 &&          
        this.flag_firstcheck_WPC == 1)
        {                                                    
            // Giving Call to API and next step
            this.stepper.next();              
            this.stepcomplete = 'step3';

        }else{
        
        }                                                 

    }
    if (step == 4) {
      this.documentUploadSubmitted = true;
      if (this.documentUploadSubmitted && this.documentUpload.valid) {
        console.log(this.documentUpload.value);

        this.onsubmit_fourth();
        this.stepcomplete = 'step4';

      }
    }
  }

  // Load onclick expire date
  loadecpirydate(){

    var policy_date = this.verifypolicyDetails.controls['policy_date'].value;

    var parts = policy_date.match(/(\d+)/g);
    var d = new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based

    var newdate = new Date(d.getTime() + 365*24*60*60*1000);
    var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];

    this.verifypolicyDetails.controls['policy_expirydate'].setValue(dateString);

    this.min_date = policy_date;
    this.max_date = dateString;

  }

  // Stepper Previous Event
  previous() {
    this.stepper.previous();
  }

  getIsuranceCompanyDetails(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":localStorage.getItem('client_id'),
      'product_id': 4,
    };

    this.opdPolicyDetailsService.getIsuranceCompanyDetails(postdata)
      .subscribe((result: any) => {
        
        this.isuranceCompanyDetailsarr = result.data;          

      }, (error) => {
          // console.log(result);
    });
  }

  getBrokerDetails(){
    this.opdPolicyDetailsService.getBrokerDetails()
      .subscribe((result: any) => {

        // console.log(result);
        this.brokerdetailsArr = result;

        for(var i = 0; i < result.length; i++){

          let html = `
            <option value="`+result[i]['broker_id']+`">`+result[i]['name']+`</option>
          `;

          $('#broker_name').append(html);

        }


      }, (error) => {
          // console.log(result);
    });

  }    

  /********* GET Upload Files Data ************/

  // step 1 field
  upload_policydocument_change(event: any){
    if (event.target.files.length > 0) {

      this.upload_policydocument = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_upload_policydocument").empty();
      $('#custom-file-upload_upload_policydocument').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_policydocument').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_policydocument').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_policydocument').empty();
      $('#small_upload_policydocument').html('');
      $('#small_upload_policydocument').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }
  }

  // step 4 fields
  upload_your_active_member_data_change(event: any){
    if (event.target.files.length > 0) {
      // this.upload_your_active_member_data = event.target.files[0];
      this.upload_your_active_member_data = event.target.files[0];
      // console.log(event.target.files[0]);

      $("#custom-file-upload_upload_your_active_member_data").empty();
      $('#custom-file-upload_upload_your_active_member_data').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_your_active_member_data').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_your_active_member_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_your_active_member_data').empty();
      $('#small_upload_your_active_member_data').html('');
      $('#small_upload_your_active_member_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }
  }

  upload_claim_MIS_change(event: any){
    if (event.target.files.length > 0) {

      this.upload_claim_MIS = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_upload_claim_MIS").empty();
      $('#custom-file-upload_upload_claim_MIS').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_claim_MIS').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_claim_MIS').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_claim_MIS').empty();
      $('#small_upload_claim_MIS').html('');
      $('#small_upload_claim_MIS').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }

  upload_claim_pdf_summary_change(event: any) {
    if (event.target.files.length > 0) {

      this.upload_claim_pdf_summary = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_upload_claim_pdf_summary").empty();
      $('#custom-file-upload_upload_claim_pdf_summary').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_claim_pdf_summary').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_claim_pdf_summary').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_claim_pdf_summary').empty();
      $('#small_upload_claim_pdf_summary').html('');
      $('#small_upload_claim_pdf_summary').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }

  policy_document_change(event: any) {
    if (event.target.files.length > 0) {

      this.policy_document = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_policy_document").empty();
      $('#custom-file-upload_policy_document').html(event.target.files[0]['name']);
      $('#custom-file-upload_policy_document').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_policy_document').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_policy_document').empty();
      $('#small_policy_document').html('');
      $('#small_policy_document').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }
  /********************************************/


  /******************************/ 
  sumisuredaccord(){
    this.flag_firstcheck_SI = 1;
    if($('#sumisured_accord').css('display') == 'none'){
      $('#sumisured_accord').slideToggle(1000);
      // $('#basics_accord').css('display','block');
      $('#sumisured_down_arrow').css('display','none');
      $('#sumisured_up_arrow').css('display','block');
    }else{
      $('#sumisured_accord').slideToggle(1000);
      // $('#basics_accord').css('display','none');
      $('#sumisured_down_arrow').css('display','block');
      $('#sumisured_up_arrow').css('display','none');
    }
  }

  waitingPeriodCondition(){
    this.flag_firstcheck_WPC = 1;
    if($('#waitingPeriodCondition_accord').css('display') == 'none'){
      $('#waitingPeriodCondition_accord').slideToggle(1000);
      // $('#waitingPeriodCondition_accord').css('display','block');
      $('#WPC_down_arrow').css('display','none');
      $('#WPC_up_arrow').css('display','block');
    }else{
      $('#waitingPeriodCondition_accord').slideToggle(1000);
      // $('#waitingPeriodCondition_accord').css('display','none');
      $('#WPC_down_arrow').css('display','block');
      $('#WPC_up_arrow').css('display','none');
    }
  } 

  Othersaccord(){
    this.flag_firstcheck_additionalbenefits = 1;
    if($('#Others_accord').css('display') == 'none'){
      $('#Others_accord').slideToggle(1000);
      // $('#Others_accord').css('display','block');
      $('#Others_down_arrow').css('display','none');
      $('#Others_up_arrow').css('display','block');
    }else{
      $('#Others_accord').slideToggle(1000);
      // $('#Others_accord').css('display','none');
      $('#Others_down_arrow').css('display','block');
      $('#Others_up_arrow').css('display','none');
    }
  }

  /*****************************/


  /***** Add Benefit Field *****/
  addOthersField(){

    let html = `
    <div id="this_others_row_`+this.cnt_others+`" class="row">      
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="others_name_`+this.cnt_others+`">Benefit Name </label>
          <input type="text" class="form-control" formControlName="others_name_`+this.cnt_others+`" id="others_name_`+this.cnt_others+`" />
      </div>
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="others_limit_`+this.cnt_others+`">Benefit limit </label>
          <input type="text" class="form-control" formControlName="others_limit_`+this.cnt_others+`" id="others_limit_`+this.cnt_others+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">                        
          <img src="../../../assets/img/common icons/trash.png" id="others_row_`+this.cnt_others+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newothersField').append(html);

    this.policyFeatures.addControl("others_name_"+this.cnt_others, new FormControl(''));
    this.policyFeatures.addControl("others_limit_"+this.cnt_others, new FormControl(''));

    var bname = "others_name_"+this.cnt_others;
    var blimit = "others_limit_"+this.cnt_others;

    this.othersArr[this.cnt_others] = {bname: "", blimit: ""};

    let flag = this;
    $("#others_row_"+this.cnt_others).on('click',function(e) {
      flag.removeOthersRow(e);
    });

    $("#others_limit_"+this.cnt_others).on('keyup',function(e) {
      flag.isnumberOthers(e);
    });
    
    $("#others_limit_"+this.cnt_others).on('input',function(e) {
      flag.isformatothers(e);
    });

    this.cnt_others++;

    // console.log(this.cnt_others);

  }

  removeOthersRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_others_row_' + id;
    $(idstr).remove();

  }

  isnumberOthers(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  isformatothers(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  /************* END ****************/
  

  /***** Add Upload Field *****/
  addUploadField(){

    let html = `
    <div class="row" id="this_upload_row_`+this.uploadcnt+`"> 
      <div class="col-sm-11">
          <div class="form-group mt-3">
              <label class="bs-form-label" for="upload_field_`+this.uploadcnt+`">Document</label>

              <label for="file_field_extra`+this.uploadcnt+`" id="custom-file-upload_`+this.uploadcnt+`" class="custom-file-upload form-control extrafile" style="text-align:center; cursor:pointer">
                  <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
              </label>
              <input id="file_field_extra`+this.uploadcnt+`" type="file" accept="application/pdf" class="extrafile" enctype="multipart/form-data" style="display: none" />
              <input id="`+this.uploadcnt+`" type="hidden" class="filehidden" />

          </div>
          <small style="font-size: 12px; color:#605D62" id="small_extra`+this.uploadcnt+`">Upload only PDF files (Max size 4MB)</small>
      </div>
      <div class="col-sm-1 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    // $('.newuploadField').append(html);

    $(html).insertBefore('.newuploadField');

    var uploadcnt = this.uploadcnt;
    const extraUploadArr: any = {};

    $('.extrafile').on("change", function(event: any, extrafileArr: []){

      // console.log(uploadcnt);

      if (event.target.files.length > 0) {

        extrafileArr = event.target.files[0];

        $("#custom-file-upload_" + uploadcnt).empty();
        $('#custom-file-upload_' + uploadcnt).html(event.target.files[0]['name']);
        $('#custom-file-upload_' + uploadcnt).css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_' + uploadcnt).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_extra'+ uploadcnt).empty();
        $('#small_extra'+ uploadcnt).html('');
        $('#small_extra'+ uploadcnt).append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

      // console.log(extrafileArr);

      extraUploadArr[uploadcnt] = extrafileArr;
      // console.log(extraUploadArr);

    });

    // console.log(extraUploadArr);
    this.uploadExtraArr[this.uploadcnt] = extraUploadArr;
    console.log(this.uploadExtraArr);      

    let flag = this;
    $("#upload_row_"+this.uploadcnt).on('click',function(e) {
      flag.removeUploadRow(e);
    });

    this.uploadcnt++;

  }

  removeUploadRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_upload_row_' + id;
    $(idstr).remove();
    delete this.uploadExtraArr[id];      
    console.log(this.uploadExtraArr);      
  }
  /************** Step 3 download links download files ********/
  downloadcsv(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
    link.setAttribute('download', `Sample Template.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadclaimMIS(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
    link.setAttribute('download', `Sample Template.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadPolicyDetails(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/policy_details.xlsx');
    link.setAttribute('download', `Policy Details.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadMemberdata(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/Member Data Sample Files/Active_Memeber_Data_Format_opd.xlsx');
    link.setAttribute('download', `Active_Memeber_Data_Format_opd.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /************* Show and hide limit input fields ************/
  checkradio(id: any, val: any){

    // console.log(id,val);
    if(val == "Covered"){
      $('#'+id).css("display", "block");
    }else{
      $('#'+id).css("display", "none");
    }

    if(val == "Covered"){
      $('.'+id).css("display", "block");
    }else{
      $('.'+id).css("display", "none");
    }     

    // let flag = this;
    // $('#'+id).on('input',function(e) {
    //   flag.checkempty(e);
    // });
    // $('.'+id).on('input',function(e) {
    //   flag.checkempty(e);
    // });

  }

  checkempty(e:any){
    
    var id = e.target.id;
    var value = $('#' + id).val();
    var appendid = '#' + id + '_error';      

    if(value == ''){                

    }else{

      $(appendid).remove();

    }      

  }

  
  /**********Limit Option*************/
  limitOption(evt: any, val: any){
    
    var value = evt.target.value;
    var perc = "." + val + "_limit_perc";
    var inr = "." + val + "_limit_value";

    if(value == "per of Sum Insured"){        
      $(perc).css("display","block");
      $(inr).css("display","none");
    }else{
      $(perc).css("display","none");
      $(inr).css("display","block");
    }

  }

  age_limit(evt: any, val:any){

    let id = evt.target.id;                    
    var value: any = evt.target.value;

    if(val == 'children'){
      if(value > 35 || value == 0){
        $('#' + id).val('');
      }
    }
    if(val == 'sibling'){
      if(value > 60 || value == 0){
        $('#' + id).val('');
      }
    }                     
    if(val == 'agerange'){
      
      if(value > 100){
        $('#' + id).val('');
      }
              
      if(value == '00'){
        $('#' + id).val('');
      }

    }                     

  }

  /************** Submit *************/   

  onsubmit_first(value: any){

    var postdata;


    if(this.opd_policy_check == 'Yes'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "benefit_id": this.benefit_id,

        "select_product": 4,
        // "opd_policy_check_limit": localStorage.getItem('opd_policy_check_limit'),
        
        "existing_policy_check":this.opd_policy_check,
        "upload_check":this.policyDetails.controls['upload_check'].value,
        "OPD_program":this.policyDetails.controls['OPD_program'].value,
        "insurance_company":this.policyDetails.controls['insurance_company'].value,
        "broker_name":this.policyDetails.controls['broker_name'].value,        
        "upload_policydocument": this.upload_policydocument,

        "Company_paid": localStorage.getItem('Company_paid'),
        "Company_paid_flat": localStorage.getItem('Define_flat_SI'),
        "Company_graded_array": localStorage.getItem('graded_array'),

        "Voluntary_paid": localStorage.getItem('Voluntary_paid'),
        "Voluntary_paid_flat": localStorage.getItem('Voluntary_Define_flat_SI'),
        "Voluntary_graded_array": localStorage.getItem('graded_array_Voluntary'),

        "Company_paid_or_Voluntary": localStorage.getItem('Company_paid_or_Voluntary'),
        "Company_paid_or_Voluntary_flat": localStorage.getItem('CPV_Define_flat_SI'),
        "Company_paid_or_Voluntary_graded_array": localStorage.getItem('graded_array_CPV'),

        "Company_paid_or_Voluntary_yesflow": localStorage.getItem('Company_paid_or_Voluntary_yesflow')

      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "benefit_id": this.benefit_id,
         
        "select_product": 4,
        // "opd_policy_check_limit": localStorage.getItem('opd_policy_check_limit'),

        "existing_policy_check":this.opd_policy_check,
        "upload_check":this.policyDetails.controls['upload_check'].value,
        "OPD_program":this.policyDetails.controls['OPD_program'].value,
        "upload_policydocument": this.upload_policydocument,
        
        "Company_paid": localStorage.getItem('Company_paid'),
        "Company_paid_flat": localStorage.getItem('Define_flat_SI'),
        "Company_graded_array": localStorage.getItem('graded_array'),

        "Voluntary_paid": localStorage.getItem('Voluntary_paid'),
        "Voluntary_paid_flat": localStorage.getItem('Voluntary_Define_flat_SI'),
        "Voluntary_graded_array": localStorage.getItem('graded_array_Voluntary'),

        "Company_paid_or_Voluntary": localStorage.getItem('Company_paid_or_Voluntary'),
        "Company_paid_or_Voluntary_flat": localStorage.getItem('CPV_Define_flat_SI'),
        "Company_paid_or_Voluntary_graded_array": localStorage.getItem('graded_array_CPV'),

        "Company_paid_or_Voluntary_yesflow": localStorage.getItem('Company_paid_or_Voluntary_yesflow')

      }
    }      

    this.opdPolicyDetailsService.policyDetailsCreate_first(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            this.benefit_id = result.data.benefit_id;
            this.client_product_id = result.data.client_product_id;
            this.client_product_policy_id = result.data.client_product_policy_id;

            if(value == 'policy_template'){
              // this.displayStyleThankyou = "block";
            }else{

            }

            // this.validmsg = result.message;
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            // var flag = this;
            // setTimeout(function() {
            //   // flag.route.navigate(['/Client-dashboard']);
            // }, 3000);


          } else {
            // console.log(result);
          }
        }, (error) => {
          // this.displayStyleThankyou = "block";
          // this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');
      });

  }

  onsubmit_second(){
    var postdata;
    if(this.no_flow_flag == true){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),          
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "existing_policy_check": this.opd_policy_check,
        "benefit_id": this.benefit_id,

        "policy_date":this.verifypolicyDetails.controls['policy_date'].value,
        "policy_expirydate":this.verifypolicyDetails.controls['policy_expirydate'].value,
        "data_report_date":this.verifypolicyDetails.controls['data_report_date'].value,          
        "policy_number":this.verifypolicyDetails.controls['policy_number'].value,
        "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value,
        "lives_covered":this.verifypolicyDetails.controls['lives_covered'].value,
        "emp_atreport":this.verifypolicyDetails.controls['emp_atreport'].value,
        "lives_atreport":this.verifypolicyDetails.controls['lives_atreport'].value
      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "benefit_id": this.benefit_id,

        "how_soon_buy":this.verifypolicyDetails.controls['how_soon_buy'].value,
        "choose_month":this.verifypolicyDetails.controls['choose_month'].value,
        "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value
      }
    }      

    this.opdPolicyDetailsService.policyDetailsCreate_second(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            this.benefit_id = result.data.benefit_id;
            this.proposal_number_show = result.data.proposal_number;

            // this.validmsg = result.message;
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            var flag = this;
            setTimeout(function() {
              // flag.route.navigate(['/Client-dashboard']);
            }, 3000);


          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

  }

  onsubmit_third(){      

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":localStorage.getItem('client_id'),      
      "client_product_id": this.client_product_id,
      "client_product_policy_id": this.client_product_policy_id,
      "existing_policy_check": this.opd_policy_check,
      "benefit_id": this.benefit_id,                   
           
      // Family
      "employees":this.policyFeatures.controls['employees'].value,
      "employees_limit":this.policyFeatures.controls['employees_limit'].value,
      
      "spouse":this.policyFeatures.controls['spouse'].value,
      "spouse_limit":this.policyFeatures.controls['spouse_limit'].value,

      "child":this.policyFeatures.controls['child'].value,
      "child_limit":this.policyFeatures.controls['child_limit'].value,           

      "parents":this.policyFeatures.controls['parents'].value,
      "parents_limit":this.policyFeatures.controls['parents_limit'].value,           
            
      "sibling":this.policyFeatures.controls['sibling'].value,
      "sibling_limit":this.policyFeatures.controls['sibling_limit'].value,               
      
      // Others      
      "prescription_medications":this.policyFeatures.controls['prescription_medications'].value,
      "prescription_medications_limit":this.policyFeatures.controls['prescription_medications_limit'].value,

      "consultations":this.policyFeatures.controls['consultations'].value,
      "consultations_limit":this.policyFeatures.controls['consultations_limit'].value,

      "diagnostics_or_health_checkups":this.policyFeatures.controls['diagnostics_or_health_checkups'].value,
      "diagnostics_or_health_checkups_limit":this.policyFeatures.controls['diagnostics_or_health_checkups_limit'].value,

      "dental":this.policyFeatures.controls['dental'].value,
      "dental_limit":this.policyFeatures.controls['dental_limit'].value,

      "vision":this.policyFeatures.controls['vision'].value,
      "vision_limit":this.policyFeatures.controls['vision_limit'].value,

      "vision_physiotherapy_hospitalizationlimit":this.policyFeatures.controls['vision_physiotherapy_hospitalizationlimit'].value,
      "vision_physiotherapy_hospitalizationlimit_limit":this.policyFeatures.controls['vision_physiotherapy_hospitalizationlimit_limit'].value,      

      "physiotherapy_hospitalization":this.policyFeatures.controls['physiotherapy_hospitalization'].value,
      "physiotherapy_hospitalization_limit":this.policyFeatures.controls['physiotherapy_hospitalization_limit'].value,
      
      "psychiatric_treatment":this.policyFeatures.controls['psychiatric_treatment'].value,
      "psychiatric_treatment_limit":this.policyFeatures.controls['psychiatric_treatment_limit'].value,

      "vaccinations":this.policyFeatures.controls['vaccinations'].value,
      "vaccinations_limit":this.policyFeatures.controls['vaccinations_limit'].value,    

      "others_extra_fields": this.othersArr,

      // Policy Restrictions
      "copay_applicable_OPD_program":this.policyFeatures.controls['copay_applicable_OPD_program'].value,
      "copay_applicable_OPD_program_limit":this.policyFeatures.controls['copay_applicable_OPD_program_limit'].value,      

      "OPD_restricted_network":this.policyFeatures.controls['OPD_restricted_network'].value,
      "OPD_restricted_network_limit":this.policyFeatures.controls['OPD_restricted_network_limit'].value,
      
    }

    this.opdPolicyDetailsService.policyDetailsCreate_third(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {
           
            this.benefit_id = result.data.benefit_id;

            // this.validmsg = result.message;              
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');              

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

  }

  onsubmit_fourth(){      

    // const propertyNames = Object.keys(this.uploadExtraArr);
    // console.log("==>", propertyNames);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":localStorage.getItem('client_id'),
      "client_product_policy_id": this.client_product_policy_id,

      "policy_document": this.policy_document,
      "extra_upload_files": this.uploadExtraArr
    }

    this.opdPolicyDetailsService.policyDetailsCreate_fourth(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            this.proposal_number_show = result.data.proposal_number;
            this.displayStyleThankyou = "block";

            $('#sticky-footer').css('opacity', '0');
            $('.navbar').css('opacity', '0');
            $('#sidebar-wrapper').css('z-index', '0');

            // $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            // var flag = this;
            // setTimeout(function() {
            //   flag.route.navigate(['/Client-dashboard']);
            // }, 3000);


          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

  }

  closePopup() {

    var val = $('#plan_name').val();

    if(val == ''){
      
      $('#plan_name_error').css('display','block');
      $('#plan_name').addClass('control-red');

    }else{
      
      $('#plan_name_error').css('display','none');
      this.displayStyleThankyou = "none";

      $('#sticky-footer').css('opacity', '1');
      $('.navbar').css('opacity', '1');
      $('#sidebar-wrapper').css('z-index', '500');

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_policy_id": this.client_product_policy_id,
        "plan_name": val
      }
      
      this.opdPolicyDetailsService.policyDetails_planName(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            // this.displayStyleThankyou = "block";

            // $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            // this.route.navigate(['/select-product']);


          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

      this.route.navigate(['/select-product']);        
    }
    
  }

  show_SI_div(val: any){
    if(val == 'Multiple of Salary'){
      $('#div_Multiple_of_Salary').css('display','block');
      $('#div_Flat').css('display','none');
      $('#div_Graded').css('display','none');
    }
    if(val == 'Flat'){
      $('#div_Multiple_of_Salary').css('display','none');
      $('#div_Flat').css('display','block');
      $('#div_Graded').css('display','none');
    }
    if(val == 'Graded'){
      $('#div_Multiple_of_Salary').css('display','none');
      $('#div_Flat').css('display','none');
      $('#div_Graded').css('display','block');
    }
  }

  /***** Add Graded Field *****/
  addGradedField(){

    let html = `
    <div id="this_graded_row_`+this.cnt_graded+`" class="row">      
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_name_`+this.cnt_graded+`">Graded </label>
          <input type="text" class="form-control" formControlName="graded_name_`+this.cnt_graded+`" id="graded_name_`+this.cnt_graded+`" />
      </div>
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_limit_`+this.cnt_graded+`">Amount </label>
          <input type="text" class="form-control" formControlName="graded_limit_`+this.cnt_graded+`" id="graded_limit_`+this.cnt_graded+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">                        
          <img src="../../../assets/img/common icons/trash.png" id="graded_row_`+this.cnt_graded+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newgradedField').append(html);

    this.policyFeatures.addControl("graded_name_"+this.cnt_graded, new FormControl(''));
    this.policyFeatures.addControl("graded_limit_"+this.cnt_graded, new FormControl(''));

    var bname = "graded_name_"+this.cnt_graded;
    var blimit = "graded_limit_"+this.cnt_graded;

    this.gradedArrSI[this.cnt_graded] = {bname: "", blimit: ""};

    let flag = this;
    $("#graded_row_"+this.cnt_graded).on('click',function(e) {
      flag.removeGradedRow(e);
    });

    $("#graded_limit_"+this.cnt_graded).on('keyup',function(e) {
      flag.isnumberGraded(e);
    });
    
    $("#graded_limit_"+this.cnt_graded).on('input',function(e) {
      flag.isformatgraded(e);
    });

    this.cnt_graded++;

    console.log(this.gradedArrSI);

  }

  removeGradedRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_graded_row_' + id;
    $(idstr).remove();

  }

  isnumberGraded(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  isformatgraded(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  /************* END ****************/

  /***** Add Graded Topup Field *****/
  addGradedFieldTopup(){

    let html = `
    <div id="this_graded_topup_row_`+this.cnt_graded_topup+`" class="row">      
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_topup_name_`+this.cnt_graded_topup+`">Graded </label>
          <input type="text" class="form-control" formControlName="graded_topup_name_`+this.cnt_graded_topup+`" id="graded_topup_name_`+this.cnt_graded_topup+`" />
      </div>
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_topup_limit_`+this.cnt_graded_topup+`">Amount </label>
          <input type="text" class="form-control" formControlName="graded_topup_limit_`+this.cnt_graded_topup+`" id="graded_topup_limit_`+this.cnt_graded_topup+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">                        
          <img src="../../../assets/img/common icons/trash.png" id="graded_topup_row_`+this.cnt_graded_topup+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newgradedtoptupField').append(html);

    this.policyFeatures.addControl("graded_topup_name_"+this.cnt_graded_topup, new FormControl(''));
    this.policyFeatures.addControl("graded_topup_limit_"+this.cnt_graded_topup, new FormControl(''));

    var bname = "graded_topup_name_"+this.cnt_graded_topup;
    var blimit = "graded_topup_limit_"+this.cnt_graded_topup;

    this.gradedArrSI_topup[this.cnt_graded_topup] = {bname: "", blimit: ""};

    let flag = this;
    $("#graded_topup_row_"+this.cnt_graded_topup).on('click',function(e) {
      flag.removegraded_topupRow(e);
    });

    $("#graded_topup_limit_"+this.cnt_graded_topup).on('keyup',function(e) {
      flag.isnumbergraded_topup(e);
    });
    
    $("#graded_topup_limit_"+this.cnt_graded_topup).on('input',function(e) {
      flag.isformatgraded_topup(e);
    });

    this.cnt_graded_topup++;

    console.log(this.gradedArrSI_topup);

  }

  removegraded_topupRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_graded_topup_row_' + id;
    $(idstr).remove();

  }

  isnumbergraded_topup(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  isformatgraded_topup(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  /************* END ****************/

  show_SI_div_topup(val: any){
    if(val == 'Multiple of Salary'){
      $('#div_multiple_of_salary_topup').css('display','block');
      $('#div_Flat_topup').css('display','none');
      $('#div_Graded_topup').css('display','none');
    }
    if(val == 'Flat'){
      $('#div_multiple_of_salary_topup').css('display','none');
      $('#div_Flat_topup').css('display','block');
      $('#div_Graded_topup').css('display','none');
    }
    if(val == 'Graded'){
      $('#div_multiple_of_salary_topup').css('display','none');
      $('#div_Flat_topup').css('display','none');
      $('#div_Graded_topup').css('display','block');
    }
  }

  checkMOS(e: any){

    $('#multiple_of_salary_topup').empty();

    var val = e.target.value;
    this.MOSArr = 11 - val;
    console.log(this.MOSArr);

    var Arrmos = [0,1,2,3,4,5,6,7,8,9,10];

    if(val == 10){
      
      let html = `
        <option value="0">0</option>
      `;

      $('#multiple_of_salary_topup').append(html);

    }else{
      for(var i = 1; i < this.MOSArr; i++){

        let html = `
          <option value="`+Arrmos[i]+`">`+Arrmos[i]+`</option>
        `;

        $('#multiple_of_salary_topup').append(html);

      }
    }      

  }

  Prog(val:any, text:any){
    let id = "#Prog" + text;
    let value = "#Value" + text;
    let progressBar:any = document.querySelector(id);
    let valueContainer:any = document.querySelector(value);
    let progressValue = 0;
    let progressEndValue = val;
    let speed = 30;

    if(val == 0){

      let progress = setInterval(() => {
        // progressValue++;
        valueContainer.textContent = `${progressValue}%`;
        progressBar.style.background = `conic-gradient(
            #58D68C ${progressValue * 3.6}deg,
            #DEDEDE ${progressValue * 3.6}deg
        )`;
        if (progressValue == progressEndValue) {
          clearInterval(progress);
        }
      }, speed);

    }else{

      let progress = setInterval(() => {
        progressValue++;
        valueContainer.textContent = `${progressValue}%`;
        progressBar.style.background = `conic-gradient(
            #58D68C ${progressValue * 3.6}deg,
            #DEDEDE ${progressValue * 3.6}deg
        )`;
        if (progressValue == progressEndValue) {
          clearInterval(progress);
        }
      }, speed);

    }
    
  }

  purple_white_change(id:any, val:any){

    if(val == 'yes'){

      $('#'+id+'_'+val).css('background', '#4e0099');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_no').css('background', '#f1f1f1');
      $('#'+id+'_no').css('color', '#000');

    }else{

      $('#'+id+'_'+val).css('background', '#4e0099');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_yes').css('background', '#f1f1f1');
      $('#'+id+'_yes').css('color', '#000');

    }

  }

  green_red_change(id:any, val:any){

    if(val == 'yes'){

      $('#'+id+'_'+val).css('background', '#217e2b');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_no').css('background', '#f1f1f1');
      $('#'+id+'_no').css('color', '#000');

    }else{

      $('#'+id+'_'+val).css('background', '#ff4052');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_yes').css('background', '#f1f1f1');
      $('#'+id+'_yes').css('color', '#000');

    }

  }

  red_green_change(id:any, val:any){

    if(val == 'no'){
      
      $('#'+id+'_'+val).css('background', '#217e2b');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_yes').css('background', '#f1f1f1');
      $('#'+id+'_yes').css('color', '#000');        

    }else{

      $('#'+id+'_'+val).css('background', '#ff4052');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_no').css('background', '#f1f1f1');
      $('#'+id+'_no').css('color', '#000');        

    }

  }

}
