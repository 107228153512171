import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { ClientCreationService } from './client-creation';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-claim-document',
  templateUrl: './claim-document.component.html',
  styleUrls: ['./claim-document.component.css']
})
export class ClaimDocumentComponent implements OnInit {

  InfoArray: any = [];
  InfoDocument: any = [];
  imageURL: any;
  cnt: any = 1;

  show = false;
  fullScreen = true;
  template = ``;

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');
  document_id = localStorage.getItem('document_id');

  validmsg: any;
  document_file: any;
  displayStyleDelete = "none";

  displayArray:any = [];

  public DeleteInfo:FormGroup;

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private clientCreationService: ClientCreationService, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });

  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){    
    this.validmsg = '';
    $('#validmsg').css('display', 'none');    
    this.getdocument();
    this.getInfo();

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    }); 

 }

  onSubmit(){

    var document_name = $('#document').val();

    if(this.document_file == "" || this.document_file == null || this.document_file == undefined || document_name == ""){

      if(this.document_file == "" || this.document_file == null || this.document_file == undefined){
        $('#document_file_error').css('display', 'block');
        $('#features-file-upload_document_file').css('border', 'solid 1px red');
      }else{
        $('#document_file_error').css('display', 'none');
        $('#features-file-upload_document_file').css('border', '1px solid #ced4da;');
      }

      if(document_name == ""){
        $('#document_error').css('display', 'block');
        $('#document').css('border', 'solid 1px red');
      }else{
        $('#document_error').css('display', 'none');
        $('#document').css('border', '1px solid #ced4da;');
      }

    }else{

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "claim_id":localStorage.getItem('claim_id'),
        "policy_id": this.document_id,

        "document": $('#document').val(),
        "document_file": this.document_file,
      };

      console.log(postdata);

      this.clientCreationService.onSubmitFirst(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {
            // console.log(result);
            window.location.reload();

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    }

  }

  claim_doc(event: any){   

    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      // // check file size less than 5MB
      // if(iSize > 50){
      //   $('.sizeerror').css('display', 'block');
      //   $('#submitbtn').css('pointer-events', 'none');
      //   $('#submitbtn').css('background', '#c4c4c4');
      //   $('#submitbtn').css('border-color', '#c4c4c4');
      // }else{
      //   $('.sizeerror').css('display', 'none');
      //   $('#submitbtn').css('pointer-events', 'auto');
      //   $('#submitbtn').css('background', 'var(--bs-btn-bg)');
      //   $('#submitbtn').css('border-color', 'var(--bs-btn-border)');
      // }
      $('.sizeerror').css('display', 'none');
      $('#submitbtn').css('pointer-events', 'auto');
      $('#submitbtn').css('background', 'var(--bs-btn-bg)');
      $('#submitbtn').css('border-color', 'var(--bs-btn-border)');

    }
  }

  onClose(){
    if(localStorage.getItem('user_type_id')=='10'){
      this.route.navigateByUrl('client-user-listing');
    }else{
      this.route.navigateByUrl('claim-intimation');
    }


  }

  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "claim_id":localStorage.getItem('claim_id'),
      "policy_id": this.document_id,
    };

    this.clientCreationService.getInfo(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.InfoArray = result.data;
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtTrigger.next();

        }, (error) => {

          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

      });
  }

  // Dropdown 1
  getdocument(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "claim_id":localStorage.getItem('claim_id'),
      "policy_id": this.document_id,
    };

    this.clientCreationService.getdropdown(postdata)
        .subscribe((result: any) => {

          // console.log(result);
          this.InfoDocument = result.data;

          console.log(this.InfoArray);
          console.log(this.InfoDocument);

          for(let i=0; i<this.InfoDocument.length; i++){            
                       
            if(this.InfoArray[i] == '' || this.InfoArray[i] == null || this.InfoArray[i] == undefined){
              
            }else{
              console.log(this.InfoArray[i]['policy_idfk']);
            }
            
            console.log(this.InfoDocument[i]['policy_idfk']);

            // if(this.InfoArray[i] == undefined){

            //   console.log(this.InfoArray[i]['policy_idfk']);
            //   console.log(this.InfoDocument[i]['policy_idfk']);

            //   if(this.InfoArray[i]['policy_idfk'] != this.InfoDocument[i]['policy_idfk']){
            //     this.displayArray.push(this.InfoDocument[i]);
            //   }
              
            // }
            
          }

          console.log(this.displayArray);

        }, (error) => {

          this.InfoDocument = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

      });
  }

  // PDF and Image Viewer
  showdocument(url:any){

    var extention:any = url.substr( (url.lastIndexOf('.') +1) );

    if(extention == 'pdf'){

      if(this.cnt > 1){
        var prevcnt:any = parseInt(this.cnt) - 1;
        $('.iframe' + prevcnt).remove();
      }

      let html = `<iframe src="`+url+`" style="width:100%; height:800px;" class="my-4 iframe`+this.cnt+`" frameborder="0"></iframe>`;
      $('.iframeDiv').append(html);
      $('.iframeDiv').css('display', 'block');
      $('.imgDiv').css('display', 'none');
      this.imageURL = '';
      this.cnt++;
    }else{
      $('.iframeDiv').css('display', 'none');
      $('.imgDiv').css('display', 'block');
      this.imageURL = url;
    }

  }

  //Delete
  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";  
    
    this.DeleteInfo.controls['delete_id'].setValue(res.id);

    // var postdata = {
    //   "tokenable_id":localStorage.getItem('tokenable_id'),
    //   "id":res.id
    // };

    // this.clientCreationService.InfoSingle(postdata)
    //   .subscribe((result: any) => {
        
    //     // console.log(result);
    //     this.DeleteInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
    //   }, (error) => {          
               
    // });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "doc_id": id,
      "broker_id":localStorage.getItem('broker_id'),
    };

    this.clientCreationService.InfoDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;
          window.location.reload();   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  closePopup() {    
    this.displayStyleDelete = "none";  
  }

}
