<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <!-- <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader> -->


                <h3 class="bs-title"><span class="spanbs-title">Bulk Endorsement</span>
                    <!-- <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button> -->
                </h3>

                <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                    <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                </div>
                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                    <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                </div>

            <hr class="mb-2">

             <!-- Add New Relation Request -->
             <form>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="client_name">Client Name</label>
                            <select class="form-select" id="client_name">
                                <option value="" selected>Select Client Name</option>
                                <option *ngFor="let res of InfoArraydropdown" [value]="res.employer_id">{{ res.employer_name }}</option>
                            </select>
                            <small id="client_name_error" class="bs-form-label" style="color: red; display: none; float: right;">Client Name Required</small>
                        </div>
                    </div>

                    <div class="col-sm-7">
                        <div class="form-group">
                          <label class="bs-form-label" for="document_file">Upload Document *</label>
                          <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                              <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                          </label>
                          <input id="document_file" type="file" (change)="endorsement_doc($event)" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />
                          <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;" id="document_file">Upload only .xls, .xlsx files</small>
                          <small id="document_file_error" class="bs-form-label" style="color: red; display: none; float: right;">Please Upload Document</small>
                          <!-- <small class="sizeerror" style="font-size: 12px; color:#df3603; position: relative; top: -5px; display: none;">File size must be less than 5MB</small>                       -->
                        </div>
                    </div>

                    <div class="col-sm-2 mt-3">
                        <button type="button" class="btn btn-primary mt-3"
                            (click)="onSubmit()" role="button">Save</button>
                    </div>
                    
                </div>                
            </form>

            <hr>


             <!-- Filters -->
             <div class="row">
                
            </div>
        </div>
        <app-footer></app-footer>
    </div>

</div>
