import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { ClientCreationService } from './client-creation';
// import data from '../../assets/jsonfiles/country.json';
@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {

  public basicInfo:FormGroup;
  public familyInfo:FormGroup;
  public SIPremiumInfo:FormGroup;
  public contactInfo:FormGroup;
  public additionalInfo:FormGroup;
  public claimInfo:FormGroup;
  public step7Info:FormGroup;
  public step7Info_popup:FormGroup;

  private stepper: Stepper;

  basicInfoSubmitted: boolean = false;
  familyInfoSubmitted: boolean = false;
  SIPremiumInfoSubmitted: boolean = false;
  contactInfoSubmitted: boolean = false;
  additionalInfoSubmitted: boolean = false;
  claimInfoSubmitted: boolean = false;
  step7InfoSubmitted: boolean = false;

  country: any;
  countryCodes: any;
  statesarr1: any;
  statesarr2: any;
  pincode: any;

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');
  phonenumber = localStorage.getItem('mobile_no');

  validmsg: any;
  listed: any;

  displayStyleThankyou = "none";

  /******************************/
  policyType: any = [];
  policySubType: any = [];
  parentpolicyType: any = [];
  Insurer:any = [];
  TPA:any = [];
  employeeTabView:any = [];
  RelationType:any = [];
  brokerBranchName:any = [];
  companyDetails:any = [];
  entityDetails:any = [];
  entityDetails_Insurer:any = [];
  cnt = 1;
  hr_info_arr: any = {};
  midterm_enrollement:any = [];
  co_insurer_perc_cnt: any = 0;
  co_insurer_perc_sum: any = 0;
  /******************************/

  /*******************************/
  allowedArr: any = {};
  cnt_allowed = 1;
  Membertype: any = [];
  coverType: any = [];
  SIType: any = [];
  SIBasis: any = [];
  premiumBasis: any = [];
  Spousemidterm: any = [];
  memberType:any = [];

  BenefitDataArr: any = {};
  cnt_BenefitData = 1;
  /*******************************/

  /*******************************/
  roleDesignationType: any = [];
  emailID: any = [];
  /*******************************/

  documentType: any = [];
  document_file: any;
  policy_feature_document: any;
  uploadcnt:any = 1;
  uploadExtraArr: any = {};

  spousedata:any = {};
  ChilData: any = {};

  /*******************************/

  RowCount:any = 0;
  GenerateSICount:any = [];
  diffArr: any = [];
  diffval: any;
  cnt_custom: any = 1;
  CustomArr: any = {};

  /*******************************/

  cnt_add_benefit: any = 1;
  add_benefit_arr: any = {};

  cnt_broker: any = 1;
  brokerArr_step4: any = {};

  cnt_employer: any = 1;
  employer_step4Arr: any = {};

  cnt_tpa: any = 1;
  tpa_step4Arr: any = {};

  cnt_Insurer: any = 1;
  Insurer_step4Arr: any = {};

  installmentArr: any = [];

  entityDetails_e:any = [];
  entityDetails_tpa:any = [];

  relationDetails:any = [];

  displayinceptionmsg = "none";
  displayfamilymsg = "none";
  Standardmsgtoggle = "none";
  PopupBenefits = "none";
  Validation_Error_message:any;

  policysamplefile:any;
  downloadbuttontooltip:any;

  perMili_rate:any;
  inception_premiumGST:any;
  inception_premium:any;

  MonthlyEquitible:any = [];
  AdditionalCoverArray:any = [];
  NoOfTimesSalary:any = [];

  employee_file:any;
  emmployeefile_id:any = 0;
  grade_SI:any = [];
  grade_SP:any = [];
  unnamed:any = [];
  permily_age:any = [];
  PolicyFeatures:any = [];

  contactroleArr:any = [];
  contactroleemployerArr:any = [];

  errrpolicyno:any;
  coverageDays:any;


  constructor(private fb: FormBuilder, private clientCreationService: ClientCreationService, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false' || this.resstatus == null) {
          this.route.navigate(['/home']);
      }
    });

  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    localStorage.setItem('master_policies_id', '0');

    this.getpolicytype();
    this.getparentpolicytypes();
    this.getInsurer();
    this.getemployeeTabView();
    this.getbrokerBranchName();
    this.getcompanyDetails();
    this.getTPA();
    this.getmidtermEnrollement();
    this.getInfoRelation();

    this.getspousemidterm();

    this.getMembertype();
    this.getCovertype();
    this.getSItype();
    this.getSIBasis();
    this.getpremiumBasis();

    this.getroleDesignationType();
    this.getemailID();

    this.getdocumenttype();
    this.getRelations();
    this.getPolicyFeatures();
    this.getcontactrole();
    this.getcontactroleemployer();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Define Stepper
    const stepperElement = document.querySelector('#stepper1');
    if(stepperElement !== null){
    this.stepper = new Stepper(stepperElement , {
          linear: false,
          animation: true
        })
    }

    // Step 1
    this.basicInfo = this.fb.group({
      //basic details
      policy_number: new FormControl('', Validators.required),
      policy_name: new FormControl('', Validators.required),
      policy_type_id: new FormControl('', Validators.required),
      policy_sub_type_id: new FormControl('', Validators.required),
      parent_policy_type_id: new FormControl('0'),
      insurer_id: new FormControl('',Validators.required),
      insurer_perc: new FormControl('',Validators.required),
      tpa_id: new FormControl(''),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      employee_tab_view: new FormControl('', Validators.required),
      relationshipType: new FormControl('', Validators.required),
      broker_commision: new FormControl('', Validators.required),
      co_oprate_buffer: new FormControl('', Validators.required),
      broker_branch_id: new FormControl(''),
      show_gst_flag: new FormControl('Yes', Validators.required),
      hide_cover: new FormControl('Yes', Validators.required),
      hide_premium: new FormControl('Yes', Validators.required),
      show_enhance: new FormControl('Yes', Validators.required),
      is_prorata_premium_allowed: new FormControl('Yes', Validators.required),
      description: new FormControl(''),
      //company details
      company_id: new FormControl(''),
      sub_entity: new FormControl(''),
      broker_verification_needed: new FormControl(''),
      employer_verification_needed: new FormControl(''),
      //enrolment details
      enrollement_status: new FormControl(''),
      enrollment_window_close_mail_effective_date: new FormControl(''),
      midterm_enrollement: new FormControl(''),
      enrollment_allowed_days_mid_term: new FormControl(''),
      enrollment_start_date: new FormControl(''),
      enrollment_end_date: new FormControl(''),
    });

    // Step 2
    this.familyInfo = this.fb.group({
      no_of_members_allowed: new FormControl('', Validators.required),
      employee_included: new FormControl('Yes'),
      member_type: new FormControl('Self'),
      member_typeselect: new FormControl(''),
      no_age: new FormControl(''),
      min_age: new FormControl(''),
      max_age: new FormControl(''),
      parent_cross_section: new FormControl(''),

      //spouse
      no_age_spouse: new FormControl(''),
      min_age_spouse: new FormControl(''),
      max_age_spouse: new FormControl(''),
      marraige_certificate:new FormControl(''),
      no_of_spouse:new FormControl(''),
      midterm_allowed_for_spouse:new FormControl(''),
      spouse_enrolment_allowed_days:new FormControl(''),
      spouse_enrolment_considered:new FormControl(''),
      lgbt:new FormControl(''),

      //Child
      Child_age_depend:new FormControl(''),
      no_age_Children:new FormControl(''),
      min_age_Child:new FormControl(''),
      max_age_Child:new FormControl(''),
      birth_certificate:new FormControl(''),
      age_difference_Child:new FormControl(''),
      no_of_Child:new FormControl(''),
      midterm_allowed_for_Child:new FormControl(''),
      special_Child:new FormControl(''),
      unmarried_Child:new FormControl(''),
      adopted_Child:new FormControl(''),
      no_of_twin_child:new FormControl(''),

      //Parent
      Parent_age_depend:new FormControl(''),
      no_age_Parent:new FormControl(''),
      min_age_parent:new FormControl(''),
      max_age_parent:new FormControl(''),
      age_difference_parent:new FormControl(''),
      no_of_Parents:new FormControl(''),
      employee_allowed_Parents:new FormControl(''),

      //Parents In Law
      Parents_In_Law_age_depend:new FormControl(''),
      no_age_Parents_In_Law:new FormControl(''),
      min_age_parent_in_law:new FormControl(''),
      max_age_parent_in_law:new FormControl(''),
      age_difference_parent_in_law:new FormControl(''),
      no_of_Parents_In_Laws:new FormControl(''),
      employee_allowed_Parents_In_Law:new FormControl(''),

      //Sibling
      no_of_Siblings:new FormControl(''),
      no_age_Sibling:new FormControl(''),
      min_age_sibling:new FormControl(''),
      max_age_sibling:new FormControl(''),

    });

    // Step 3
    this.SIPremiumInfo = this.fb.group({
      cover_type: new FormControl('0', Validators.required),
      salary_type: new FormControl(''),
      basic_policy_mapping: new FormControl(''),

      SI_Type: new FormControl('0', Validators.required),
      SI_Basis: new FormControl('0', Validators.required),
      premium_Basis: new FormControl('0', Validators.required),

      tax_status: new FormControl(),
      employee_premium_tax: new FormControl(''),

      tax_status1: new FormControl(),
      employee_premium_tax1: new FormControl(''),

      tax_status2: new FormControl(),
      employee_premium_tax2: new FormControl(''),

      tax_status3: new FormControl(),
      employee_premium_tax3: new FormControl(''),

      minimum_SI: new FormControl(''),
      maximum_SI: new FormControl(''),
      interval_between_min_max_SI: new FormControl(''),

      ca_employer: new FormControl(''),
      ca_employee: new FormControl(''),

      self_additional_employer: new FormControl(''),
      self_additional_employee: new FormControl(''),
      self_additional_premium: new FormControl(''),

      parent_in_laws_additional_employer: new FormControl(''),
      parent_in_laws_additional_employee: new FormControl(''),
      parent_in_laws_additional_premium: new FormControl(''),

      siblings_additional_employer: new FormControl(''),
      siblings_additional_employee: new FormControl(''),
      siblings_additional_premium: new FormControl(''),

      parent_additional_employer: new FormControl(''),
      parent_additional_employee: new FormControl(''),
      parent_additional_premium: new FormControl(''),

      spouse_additional_employer: new FormControl(''),
      spouse_additional_employee: new FormControl(''),
      spouse_additional_premium: new FormControl(''),

      children_additional_employer: new FormControl(''),
      children_additional_employee: new FormControl(''),
      children_additional_premium: new FormControl(''),

      special_child_contribution_all_employee: new FormControl(''),
      special_child_contribution_all_employer: new FormControl(''),
      special_child_contribution_all_premium: new FormControl(''),

      unmarried_child_contribution_all_employee: new FormControl(''),
      unmarried_child_contribution_all_employer: new FormControl(''),
      unmarried_child_contribution_all_premium: new FormControl(''),

      unmarried_child_contribution_additional_employee: new FormControl(''),
      unmarried_child_contribution_additional_employer: new FormControl(''),
      unmarried_child_contribution_additional_premium: new FormControl(''),

      special_child_contribution_additional_employee: new FormControl(''),
      special_child_contribution_additional_employer: new FormControl(''),
      special_child_contribution_additional_premium: new FormControl(''),

      use_flex:new FormControl(),
      payroll:new FormControl(),
      installment_allowed:new FormControl(),
      installment_prorata:new FormControl('Yes'),
      installment_type:new FormControl('0'),
      monthly_equitible_period:new FormControl(),

      additional_cover:new FormControl(),
      additional_benefit_label:new FormControl(),
      additional_benefit_limit:new FormControl(),

      no_of_times_salary:new FormControl(),
      lowest_SI_limit:new FormControl(),
      highest_SI_limit:new FormControl(),

      total_employee:new FormControl(),
      total_SI:new FormControl(),
      total_premium:new FormControl(),

      total_employee_unnamed:new FormControl('0'),
      gpa_individual_cover:new FormControl('0'),
      total_SI_unnamed:new FormControl(),
      total_premium_unnamed:new FormControl(),
      per_milliRate:new FormControl(),
      gst_unnamed:new FormControl('0'),
      total_premium_unnamedGST:new FormControl(),
      gpa_coverage_days:new FormControl(),
      prorata_premium_unnamed:new FormControl(),
      gst_amount_unnamed:new FormControl(),
    });

    // Step 4
    this.contactInfo = this.fb.group({
      b_role_designation_type: new FormControl('', Validators.required),
      b_emailID: new FormControl('', Validators.required),
      b_contactname: new FormControl('', Validators.required),
      b_mobileno: new FormControl('', Validators.required),
      b_level: new FormControl('', Validators.required),

      e_role_designation_type: new FormControl('', Validators.required),
      e_emailID: new FormControl('', Validators.required),
      e_contactname: new FormControl('', Validators.required),
      e_mobileno: new FormControl('', Validators.required),
      e_level: new FormControl('', Validators.required),

      tpa_role_designation_type: new FormControl(''),
      tpa_emailID: new FormControl(''),
      tpa_contactname: new FormControl(''),
      tpa_mobileno: new FormControl(''),
      tpa_level: new FormControl(''),

      insurer_id_step4: new FormControl(''),
      Insurer_emailID: new FormControl(''),
      Insurer_contactname: new FormControl(''),
      Insurer_mobileno: new FormControl(''),
      Insurer_level: new FormControl(''),

    });

    // Step 5
    this.additionalInfo = this.fb.group({
      add_benefit: new FormControl(''),
      cd_summary_checkbox: new FormControl(''),
      opening_cd_balance: new FormControl(''),
      cd_balance_thrsold: new FormControl(''),
      inception_premium: new FormControl(''),
      inception_premium_installment: new FormControl(''),
      nomination_applicable: new FormControl('Yes'),
      nomination_mandatory: new FormControl('Yes'),
      declaration_mandatory: new FormControl('Yes'),
      allowed_members: new FormControl('Selected Members'),

    });

    // Step 6
    this.claimInfo = this.fb.group({
      document_name: new FormControl(''),
      document_type: new FormControl('', Validators.required),
      document_mandatory: new FormControl('Yes'),
      no_of_back_days: new FormControl(0, Validators.required),
      digital_claim: new FormControl('Yes', Validators.required),
      claim_intimate_mandatory: new FormControl('Yes', Validators.required),
    });

    // Step 7
    this.step7Info = this.fb.group({
      benefit_name:new FormControl(''),
      benefit_description:new FormControl(''),
    });

    // Step 7 popup
    this.step7Info_popup = this.fb.group({
      benefit_name_val:new FormControl(''),
      benefit_description_val:new FormControl(''),
      benefit_visible_from_SI:new FormControl(''),
      benefit_mandatory:new FormControl('Mandatory'),
      prorata_calculation_applicable:new FormControl('Yes'),
    });

  }

  isNumber(evt: any) {
    evt = evt || window.event;
  var charCode = evt.which || evt.keyCode;

  // Allowing digits 0-9 and the decimal point
  if (
      (charCode >= 48 && charCode <= 57) || // Digits 0-9
      charCode === 46 // Decimal point
  ) {
      // Allowing only one decimal point
      if (charCode === 46 && evt.target.value.indexOf('.') !== -1) {
          evt.preventDefault();
          return false;
      }

      return true;
  } else {
      // Preventing input of other characters
      evt.preventDefault();
      return false;
  }

  }

  /************ ALL GET Step 1 ***************/
  getpolicytype(){
    this.clientCreationService.getpolicytype()
      .subscribe((result) => {

        this.policyType = result.data;

      }, (error) => {

        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/login']);
        }
    });
  }
  getpolicySubtype(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_type_id":this.basicInfo.controls['policy_type_id'].value,
    }
    this.clientCreationService.getpolicySubtype(postdata)
      .subscribe((result) => {

        this.policySubType = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  getparentpolicytypes(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "comp_type_id":12,
    }
    this.clientCreationService.getparentpolicytypes(postdata)
      .subscribe((result) => {

        this.parentpolicyType = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  getInsurer(){
    this.clientCreationService.getInsurer()
      .subscribe((result) => {
        this.Insurer = result.data;
        let insurerarr = [];
        for(var i=0; i<this.Insurer.length; i++){
          if(this.Insurer[i]['is_active']==1){
            insurerarr.push(this.Insurer[i]);
          }
        }
        this.Insurer = insurerarr;

      }, (error) => {
          // console.log(result);
    });
  }
  getTPA(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.clientCreationService.getTPA(postdata)
      .subscribe((result) => {

        this.TPA = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  getemployeeTabView(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_comp_param_type_id":1,
    }
    this.clientCreationService.getemployeeTabView(postdata)
      .subscribe((result) => {

        this.employeeTabView = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  getbrokerBranchName(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_comp_param_type_id":4,
    }
    this.clientCreationService.getbrokerBranchName(postdata)
      .subscribe((result) => {

        this.brokerBranchName = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.clientCreationService.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.companyDetails = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  getmidtermEnrollement(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_comp_param_type_id":2,
    }
    this.clientCreationService.getmidtermEnrollement(postdata)
      .subscribe((result) => {

        this.midterm_enrollement = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  getInfoRelation(){

    this.RelationType = [];


    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    }
    this.clientCreationService.getInfoRelation(postdata)
        .subscribe((result: any) => {

          this.RelationType = result.data;


        }, (error) => {

          this.RelationType = [];

      });
  }

  /************ ALL GET Step 2 ***************/

  getMembertype(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_comp_param_type_id":7,
    }
    this.clientCreationService.getMembertype(postdata)
      .subscribe((result) => {

        this.Membertype = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getCovertype(){
    this.clientCreationService.getCovertype()
      .subscribe((result) => {

        this.coverType = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getSItype(){
    this.clientCreationService.getSItype()
      .subscribe((result) => {

        this.SIType = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getSIBasis(){
    this.clientCreationService.getSIBasis()
      .subscribe((result) => {

        this.SIBasis = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getpremiumBasis(){
    this.clientCreationService.getpremiumBasis()
      .subscribe((result) => {

        this.premiumBasis = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getroleDesignationType(){
    this.clientCreationService.getpremiumBasis()
      .subscribe((result) => {

        this.roleDesignationType = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getemailID(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "user_type_id":localStorage.getItem('user_type_id'),
    }
    this.clientCreationService.getemailID(postdata)
      .subscribe((result) => {

        this.emailID = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getdocumenttype(){
    this.clientCreationService.getdocumenttype()
      .subscribe((result) => {

        this.documentType = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getspousemidterm(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_comp_param_type_id":5,
    }
    this.clientCreationService.getspousemidterm(postdata)
      .subscribe((result) => {

        this.Spousemidterm = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getEntity(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.clientCreationService.getEntity(postdata)
      .subscribe((result) => {

        this.entityDetails = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getRelations(){
    this.clientCreationService.getRelations()
      .subscribe((result) => {

        this.relationDetails = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  /***** Add Co Inurer Row *****/
  hr_check(){
    const valchecked = $("#hr_info").is(":checked");
    $('.newhr_row').html("");
    this.cnt = 1;
    if(valchecked == true){
      this.addHrRow();
      $("#adrowdiv").css('display', 'block');
      $("#showaddrow").css('display', 'block');
    }else{
      $("#adrowdiv").css('display', 'none');
      $("#showaddrow").css('display', 'none');
    }
  }
  addHrRow() {

    $("#adrowdiv").css('display', 'block');

    if(this.co_insurer_perc_sum > 100){
      $(".Insurersum").css('display', 'block');
    }else{

      let html = `
      <div id="hr_info_row`+ this.cnt + `" class="row mt-3">

        <div class="col-sm-4">
            <div class="form-group">
                <label class="bs-form-label" for="co_insurer_`+ this.cnt + `">Co Insurer </label>
                <select class="form-select" formControlName="co_insurer_`+ this.cnt + `" id="co_insurer_`+ this.cnt + `">
                  <option value="" selected>Select Co Insurer</option>
                </select>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="form-group">
                <label class="bs-form-label" for="co_insurer_perc_`+ this.cnt + `">Co Insurer (%) </label>
                <input type="text" class="form-control" formControlName="co_insurer_perc_`+ this.cnt + `" id="co_insurer_perc_` + this.cnt + `" maxlength="3" placeholder="Co Insurer (%)" />
            </div>
        </div>

        <div class="col-sm-4 form-group">
            <img src="../../../assets/img/common icons/trash.png" id="hr_row`+ this.cnt + `" role="button" class="img-fluid mt-3 pt-3" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newhr_row').append(html);

      for(var i=0; i<this.Insurer.length; i++){
        if(this.Insurer[i]['is_active']==1){
          var val = '#' + 'co_insurer_'+ this.cnt;
          $(val)
            .append($("<option></option>")
              .attr("value", this.Insurer[i]['insure_comp_id'])
              .text(this.Insurer[i]['name']));
        }

      }

      this.basicInfo.addControl("co_insurer_" + this.cnt, new FormControl(''));
      this.basicInfo.addControl("co_insurer_perc_" + this.cnt, new FormControl(''));

      var co_insurer = "co_insurer_" + this.cnt;
      var co_insurer_perc = "co_insurer_perc_" + this.cnt;

      this.hr_info_arr[this.cnt] = { co_insurer: "", co_insurer_perc: "" };

      let flag = this;
      let cntflag = flag.cnt;
      $("#hr_row" + this.cnt).on('click', function (e:any) {
        flag.removeBenefitRow(e, cntflag);
      });

      $("#co_insurer_perc_" + this.cnt).on('blur', function (e:any) {
        flag.ishrperc(e, cntflag);
      });

      $("#co_insurer_perc_" + this.cnt).on('keydown', function (e:any) {
        flag.checkaddmoreDiv(e, cntflag);
      });

      $("#co_insurer_perc_" + this.cnt).on('keyup', function (e:any) {
        flag.checkaddmoreDiv(e, cntflag);
      });

      this.cnt++;

      $(".Insurersum").css('display', 'none');

    }

  }

  removeBenefitRow(e: any, cntflag: any){

    var val = '#co_insurer_perc_' + cntflag;
    let value2:any = $(val).val();

    this.co_insurer_perc_sum -= parseInt(value2);
    // console.log(this.co_insurer_perc_sum);

    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#hr_info_row' + id;
    $(idstr).remove();

    if(this.co_insurer_perc_sum <= 100){
      $(".Insurersum").css('display', 'none');
    }else{
      $(".Insurersum").css('display', 'block');
    }

    let cnt:any = this.cnt;
    // console.log(this.cnt);
    this.cnt = parseInt(cnt) - 1;
    // console.log("cnt ==> ", this.cnt);

    // let sum = parseInt(this.co_insurer_perc_sum) - parseInt(e.target.value);
    // console.log(sum);
  }
  fn_onchangeinsurerpercentage(){
    let oneval:any = $("#insurer_perc").val();

    if(oneval > 100){
      $("#insurer_perc").val('');
    }

    if(oneval == 100 || oneval > 100){
      $(".hr_infocheck").css('display','none');
    }else{
      $(".hr_infocheck").css('display','block');

      // this.co_insurer_perc_sum = 0;
      // var allcovalue: any = 0;
      // // debugger;
      // for(var i = 1; i < this.cnt; i++){
      //   var q:any = $('#co_insurer_perc_'+i).val();
      //   allcovalue += parseInt(q);
      // }
      // if((parseInt(oneval)+parseInt(allcovalue))>100){
      //   $("#insurer_perc").val(0);
      //   $(".Insurersum").css('display', 'block');
      //   return;
      // }else{
      //   this.co_insurer_perc_sum += (parseInt(oneval)+parseInt(allcovalue));
      // }

    }

  }
  ishrperc(evt: any, cntflag:any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }

    //Sum
    // debugger;
    // let valper:any = $("#co_insurer_perc_" + cntflag).val();

    // if(this.co_insurer_perc_sum <= 100){

    //   if(parseInt(valper) <= 100){

    //     this.co_insurer_perc_sum += parseInt(valper);
    //     if(this.co_insurer_perc_sum <= 100){
    //       console.log('i am in SUM==>',parseInt(this.co_insurer_perc_sum));
    //       $(".Insurersum").css('display', 'none');

    //     }else{

    //       console.log('i am in minus==>',parseInt(this.co_insurer_perc_sum));
    //       this.co_insurer_perc_sum -= parseInt(valper);
    //       var str = evt.target.id;
    //       var id = str.charAt(str.length -1 );
    //       var idstr = '#hr_info_row' + id;
    //       $(idstr).remove();
    //       $(".Insurersum").css('display', 'block');
    //     }

    //   }else{

    //   }

    // }else{

    // }

    return true;
  }
  checkaddmoreDiv(e: any, cntflag: any){

    let sumcnt:any = $('#insurer_perc').val();
    let valfinal:any = 0;

    for(var i = 1; i < this.cnt; i++){
      let id:any = '#co_insurer_perc_'+i;
      // console.log(id);
      let val:any = $(id).val();
      valfinal += parseInt(val);
    }

    // console.log(sumcnt, valfinal);

    sumcnt = parseInt(sumcnt) + parseInt(valfinal);
    if(sumcnt == 100 || sumcnt > 100){
      $(".adrowdiv").css('display','none');
    }else{
      $(".adrowdiv").css('display','block');
    }
    // console.log("sumcnt from dynamic controls ==> ",sumcnt);

  }

  /******************************************/

  isNumber_tab2(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }

    // console.log(this.familyInfo.controls['zipcode'].value.length);

    if(this.familyInfo.controls['zipcode'].value.length == 6){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "pincode":this.familyInfo.controls['zipcode'].value
      }

      this.clientCreationService.getcountry(postdata)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {

            // console.log(result.data);

            this.familyInfo.controls['country'].setValue(result.data[0]['country_name']);
            this.familyInfo.controls['state'].setValue(result.data[0]['state_name']);
            this.familyInfo.controls['city'].setValue(result.data[0]['districtName']);

          } else {
            // console.log(result);
          }
        }, (error) => {

      });

    }else{
      this.familyInfo.controls['country'].setValue('');
      this.familyInfo.controls['state'].setValue('');
      this.familyInfo.controls['city'].setValue('');
    }

    return true;
  }

  isNumber_addr_repeat(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }

    // console.log(this.familyInfo.controls['c_zipcode'].value.length);

    if(this.familyInfo.controls['c_zipcode'].value.length == 6){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "pincode":this.familyInfo.controls['c_zipcode'].value
      }

      this.clientCreationService.getcountry(postdata)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {
            // console.log(result);

            this.familyInfo.controls['c_country'].setValue(result.data[0]['country_name']);
            this.familyInfo.controls['c_state'].setValue(result.data[0]['state_name']);
            this.familyInfo.controls['c_city'].setValue(result.data[0]['districtName']);

          } else {
            // console.log(result);
          }
        }, (error) => {

      });

    }else{
      this.familyInfo.controls['c_country'].setValue('');
      this.familyInfo.controls['c_state'].setValue('');
      this.familyInfo.controls['c_city'].setValue('');
    }

    return true;
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  get getfamilyInformationFormControls() {
    return this.familyInfo.controls;
  }

  get getSIPremiumInformationFormControls() {
    return this.SIPremiumInfo.controls;
  }

  get getContactInformationFormControls() {
    return this.contactInfo.controls;
  }

  get getAdditionalInformationFormControls() {
    return this.additionalInfo.controls;
  }

  get getClaimInformationFormControls() {
    return this.claimInfo.controls;
  }

  get getStep7InformationFormControls() {
    return this.step7Info.controls;
  }

  get getStep7PopupInformationFormControls() {
    return this.step7Info_popup.controls;
  }

  loadecpirydate(){

    var policy_date = this.basicInfo.controls['start_date'].value;
    var parts = policy_date.match(/(\d+)/g);
    var d = new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
    var newdate = new Date(d.getTime() + 365*24*60*60*1000);

    // check whether start date is in leap year
    let year:any = policy_date.substring(0,4);
    let leapyear:any = (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0));
    if(leapyear == true){
      newdate.setDate(newdate.getDate() + 1);
    }
    var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];
    this.basicInfo.controls['end_date'].setValue(dateString);

  }

  getcompanytype(){

    var val = this.SIPremiumInfo.controls['type_of_firm'].value;
    if(val == "Private Limited Company" || val == "Public Limited Company" || val == "Limited Liability Partnership"){
      $('#listeddiv').css('display','block');
    }else{
      $('#listeddiv').css('display','none');
    }

  }

  // Stepper Next Event
  next(step: number) {
    if (step == 1) {
      this.basicInfoSubmitted = true;
      if (this.basicInfoSubmitted && this.basicInfo.valid) {
        $('.stepper1_div').css('pointer-events', 'auto !important');
        $('#stepper1_circle').css('border', 'solid 3px #00d108');

        let sumcnt:any = $('#insurer_perc').val();
        let valfinal:any = 0;

        for(var i = 1; i < this.cnt; i++){
          let id:any = '#co_insurer_perc_'+i;
          console.log(id);
          let val:any = $(id).val();
          valfinal += parseInt(val);
        }

        // console.log(sumcnt, valfinal);

        sumcnt = parseInt(sumcnt) + parseInt(valfinal);
        console.log("sumcnt ==> ",sumcnt);

        if(sumcnt != 100){
          $(".Insurersum").css('display', 'block');
          $("#adrowdiv").css('display', 'block');
        }else{
          $(".Insurersum").css('display', 'none');
          $("#adrowdiv").css('display', 'none');
          this.stepper.next();
          this.onSubmit_first();
          this.scrollToTop();
        }

      }
    }
    if (step == 2) {
      this.familyInfoSubmitted = true;
      if (this.familyInfoSubmitted && this.familyInfo.valid) {
        $('#stepper2_div').css('pointer-events', 'auto !important');
        $('#stepper2_circle').css('border', 'solid 3px #00d108');

        let no_of_members_allowed:any = $('#no_of_members_allowed').val();
        let no_of_spouse:any = $('#no_of_spouse').val();
        let no_of_Child:any = $('#no_of_Child').val();
        let no_of_Parents:any = $('#no_of_Parents').val();
        let no_of_Parents_In_Laws:any = $('#no_of_Parents_In_Laws').val();
        let no_of_Siblings:any = $('#no_of_Siblings').val();

        //
        let total_sum:any = 0;

        if(no_of_spouse != ''){
          total_sum = parseInt(total_sum) + parseInt(no_of_spouse);
        }

        if(no_of_Child != ''){
          total_sum = parseInt(total_sum) + parseInt(no_of_Child);
        }

        if(no_of_Parents != ''){
          total_sum = parseInt(total_sum) + parseInt(no_of_Parents);
        }

        if(no_of_Parents_In_Laws != ''){
          total_sum = parseInt(total_sum) + parseInt(no_of_Parents_In_Laws);
        }

        if(no_of_Siblings != ''){
          total_sum = parseInt(total_sum) + parseInt(no_of_Siblings);
        }

        if(this.familyInfo.controls['employee_included'].value == 'Yes'){
          total_sum = parseInt(total_sum) + 1;
        }

        // console.log(total_sum);
        // console.log(no_of_members_allowed);

        if(no_of_members_allowed != ''){
          if(total_sum != no_of_members_allowed){
            this.displayfamilymsg = "block";
          }else{
            this.displayfamilymsg = "none";
            this.stepper.next();
            this.onSubmit_second();
            this.scrollToTop();
          }
        }

        if($('.spouseDiv').css('display') == 'block'){
          $(".tabSpouse").css('display', 'block');
        }else{
          $(".tabSpouse").css('display', 'none');
        }

        if($('.ParentDiv').css('display') == 'block'){
          $(".tabParent").css('display', 'block');
        }else{
          $(".tabParent").css('display', 'none');
        }

        if($('.ChildrenDiv').css('display') == 'block'){
          $(".tabChildren").css('display', 'block');
          $(".tabSpecialChild").css('display', 'block');
          $(".tabUnmarriedChildren").css('display', 'block');
        }else{
          $(".tabChildren").css('display', 'none');
          $(".tabSpecialChild").css('display', 'none');
          $(".tabUnmarriedChildren").css('display', 'none');

        }

        if($('.Parents_In_LawDiv').css('display') == 'block'){
          $(".tabParent_In_Laws").css('display', 'block');
        }else{
          $(".tabParent_In_Laws").css('display', 'none');
        }

        if($('.SiblingDiv').css('display') == 'block'){
          $(".tabSiblings").css('display', 'block');
        }else{
          $(".tabSiblings").css('display', 'none');
        }
      }
    }
    if (step == 3) {
      this.SIPremiumInfoSubmitted = true;
      if (this.SIPremiumInfoSubmitted && this.SIPremiumInfo.valid) {
        if((parseInt(this.SIPremiumInfo.controls['ca_employer'].value)+parseInt(this.SIPremiumInfo.controls['ca_employee'].value))!=100){
            this.Validation_Error_message = 'Premium contribution total must be 100';
            this.Standardmsgtoggle = "block";
            return;
        }else{
          this.Validation_Error_message = '';
          this.Standardmsgtoggle = "none";
        }

        let SI_Basis:any = $('#SI_Basis').val();
        let premium_Basis:any = $('#premium_Basis').val();

        if(SI_Basis == '1' && premium_Basis == '2' || SI_Basis == '1' && premium_Basis == '8' || SI_Basis == '1' && premium_Basis == '9' || SI_Basis == '1' && premium_Basis == '13' || SI_Basis == '1' && premium_Basis == '15' || SI_Basis == '3' && premium_Basis == '1'){
          if(this.employee_file == "" || this.employee_file == undefined || this.employee_file == null || this.employee_file == 0){
            this.Validation_Error_message = 'Please upload the file';
            this.Standardmsgtoggle = "block";
            $('#premiumfile_error').css('display', 'block');
            $('#custom-file-upload_emplyee_age_file').css('border', '1px solid red');
            return;
          }else{
            $('#premiumfile_error').css('display', 'none');
            $('#custom-file-upload_emplyee_age_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }
        }

        if(SI_Basis == '3' && premium_Basis == '4'){
          if(this.grade_SI == "" || this.grade_SI == undefined || this.grade_SI == null || this.grade_SI == 0){
            this.Validation_Error_message = 'Please upload the Grade Sum Insured file';
            this.Standardmsgtoggle = "block";
            $('#gradefile_error').css('display', 'block');
            $('#custom-file-upload_grade_SI_file').css('border', '1px solid red');
            return;
          }else{
            $('#gradefile_error').css('display', 'none');
            $('#custom-file-upload_grade_SI_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }

          if(this.grade_SP == "" || this.grade_SP == undefined || this.grade_SP == null || this.grade_SP == 0){
            this.Validation_Error_message = 'Please upload the Grade Premium file';
            this.Standardmsgtoggle = "block";
            $('#gradefile_SP_error').css('display', 'block');
            $('#custom-file-upload_grade_SP_file').css('border', '1px solid red');
            return;
          }else{
            $('#gradefile_SP_error').css('display', 'none');
            $('#custom-file-upload_grade_SP_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }

        }

        if(SI_Basis == '5' && premium_Basis == '5'){
          if(this.permily_age == "" || this.permily_age == undefined || this.permily_age == null || this.permily_age == 0){
            this.Validation_Error_message = 'Please upload the Permily Age file';
            this.Standardmsgtoggle = "block";
            $('#permilyfile_error').css('display', 'block');
            $('#custom-file-upload_permily_age_file').css('border', '1px solid red');
            return;
          }else{
            $('#permilyfile_error').css('display', 'none');
            $('#custom-file-upload_grade_SI_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }
        }

        if(SI_Basis == '17' && premium_Basis == '19'){
          if(this.unnamed == "" || this.unnamed == undefined || this.unnamed == null || this.unnamed == 0){
            this.Validation_Error_message = 'Please upload the unnamed file';
            this.Standardmsgtoggle = "block";
            $('#unnamed_error').css('display', 'block');
            $('#custom-file-upload_unnamed_file').css('border', '1px solid red');
            return;
          }else{
            $('#unnamed_error').css('display', 'none');
            $('#custom-file-upload_unnamed_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }
        }

        // if(this.employee_file == "" || this.employee_file == undefined || this.employee_file == null || this.employee_file == 0){
        //   this.Validation_Error_message = 'Please upload the Premium file';
        //   this.Standardmsgtoggle = "block";
        //   $('#premiumfile_error').css('display', 'block');
        //   $('#custom-file-upload_emplyee_age_file').css('border', '1px solid red');
        //   return;
        // }else{
        //   $('#premiumfile_error').css('display', 'none');
        //   $('#custom-file-upload_emplyee_age_file').css('border', '1px solid #dee2e6');
        //   this.Validation_Error_message = '';
        //   this.Standardmsgtoggle = "none";
        // }

        $('#stepper3_div').css('pointer-events', 'auto !important');
        $('#stepper3_circle').css('border', 'solid 3px #00d108');
        this.stepper.next();
        this.onSubmit_third();
        this.scrollToTop();
      }
    }
    if (step == 4) {
      this.contactInfoSubmitted = true;
      if (this.contactInfoSubmitted && this.contactInfo.valid) {
        $('#stepper4_div').css('pointer-events', 'auto !important');
        $('#stepper4_circle').css('border', 'solid 3px #00d108');
        this.stepper.next();
        this.onSubmit_fourth();
        this.scrollToTop();
      }
    }
    if (step == 5) {
      this.additionalInfoSubmitted = true;
      if (this.additionalInfoSubmitted && this.additionalInfo.valid) {
        $('#stepper5_div').css('pointer-events', 'auto !important');
        $('#stepper5_circle').css('border', 'solid 3px #00d108');

        //
        let inception_premium:any = $('#inception_premium').val();
        let installment_sum:any = 0;
        for(var i = 0; i < this.installmentArr.length; i++){
          let val:any = $('#installment_amount_'+i).val();
          if(val != ''){
            installment_sum += parseFloat(val);
          }else{
            installment_sum = installment_sum;
          }
        }
        console.log(inception_premium);
        console.log(installment_sum);
        // console.log(installment_sum);

        if(installment_sum != inception_premium){
          this.displayinceptionmsg = "block";
        }else{
          this.displayinceptionmsg = "none";
          this.stepper.next();
          this.onSubmit_fivth();
          this.scrollToTop();
        }

      }
    }
    if (step == 6) {
      this.claimInfoSubmitted = true;
      if (this.claimInfoSubmitted && this.claimInfo.valid) {
        // console.log(this.uploadExtraArr);
        $('#stepper6_div').css('pointer-events', 'auto !important');
        $('#stepper6_circle').css('border', 'solid 3px #00d108');
        this.stepper.next();
        this.onSubmit_sixth();
        this.scrollToTop();
      }
    }
    if (step == 7) {
      this.step7InfoSubmitted = true;
      if (this.step7InfoSubmitted && this.step7Info.valid) {
        $('#stepper7_div').css('pointer-events', 'auto !important');
        $('#stepper7_circle').css('border', 'solid 3px #00d108');
        this.stepper.next();
        this.onSubmit_seventh();
        this.scrollToTop();
      }
    }
    // else{
    //   this.stepper.next();
    // }
  }

  // calGST(){

  //   let premium:any = $('#total_premium_unnamed').val();
  //   let premium_tax: any = $('#gst_unnamed').val();

  //   if (premium != '' && premium_tax != '') {
  //     premium_tax = parseFloat(premium_tax) / 100;

  //     let premium_total = parseFloat(premium) * premium_tax;

  //     let finaltotal = parseFloat(premium) + premium_total;

  //     if (!isNaN(finaltotal)) {
  //       $('#total_premium_unnamedGST').val(finaltotal.toFixed(2)); // Adjust the number of decimal places as needed
  //     }
  //   }
  //   }

  ondownload(val: any){

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if(val == "policyfeatures"){
      link.setAttribute('href', this.policysamplefile);
      link.setAttribute('download', 'policyfeatures_'+localStorage.getItem('master_policies_id')+'.xls');
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // Stepper Previous Event
  previous() {
    this.stepper.previous();
  }

  // Show / Hide Address block
  ifradioclicked(){

    if($('#yes_as_register').is(':checked')) {
      $("#yes_or_no_div").css("display","none");
    }else{
      $("#yes_or_no_div").css("display","block");
    }

  }

  // Submit 1
  onSubmit_first() {

    /********* HR Array ********/
    for(var i = 1; i < this.cnt; i++){
      this.hr_info_arr[i] = {co_insurer: $('#co_insurer_'+i).val(), co_insurer_perc:$('#co_insurer_perc_'+i).val()};
    }

    let object = this.hr_info_arr;
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
          if (object[key]['co_insurer'] === undefined || object[key]['co_insurer_perc'] === null){
              delete object[key];
          }
      }
    }

    let relationTypeID:any = $('#relationshipType').val();
    let per_milli_value = relationTypeID.split('@');
    relationTypeID = per_milli_value[1];

    this.perMili_rate = per_milli_value[0];
    this.SIPremiumInfo.controls['per_milliRate'].setValue(this.perMili_rate);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      //basic details
      policy_number: this.basicInfo.controls['policy_number'].value,
      policy_name: this.basicInfo.controls['policy_name'].value,
      policy_type_id: this.basicInfo.controls['policy_type_id'].value,
      policy_sub_type_id: this.basicInfo.controls['policy_sub_type_id'].value,
      parent_policy_type_id: this.basicInfo.controls['parent_policy_type_id'].value,
      insurer_id: this.basicInfo.controls['insurer_id'].value,
      insurer_perc: this.basicInfo.controls['insurer_perc'].value,
      tpa_id: this.basicInfo.controls['tpa_id'].value,
      start_date: this.basicInfo.controls['start_date'].value,
      end_date: this.basicInfo.controls['end_date'].value,
      employee_tab_view: this.basicInfo.controls['employee_tab_view'].value,
      broker_commision: this.basicInfo.controls['broker_commision'].value,
      co_oprate_buffer: this.basicInfo.controls['co_oprate_buffer'].value,
      broker_branch_id: this.basicInfo.controls['broker_branch_id'].value,
      show_gst_flag: this.basicInfo.controls['show_gst_flag'].value,
      hide_cover: this.basicInfo.controls['hide_cover'].value,
      hide_premium: this.basicInfo.controls['hide_premium'].value,
      show_enhance: this.basicInfo.controls['show_enhance'].value,
      is_prorata_premium_allowed: this.basicInfo.controls['is_prorata_premium_allowed'].value,
      co_insurer_details: JSON.stringify(this.hr_info_arr),
      description: this.basicInfo.controls['description'].value,
      relationshipType: relationTypeID,
      //company details
      company_id: this.basicInfo.controls['company_id'].value,
      sub_entity: this.basicInfo.controls['sub_entity'].value,
      broker_verification_needed: this.basicInfo.controls['broker_verification_needed'].value,
      employer_verification_needed: this.basicInfo.controls['employer_verification_needed'].value,
      //enrolment details
      enrollement_status: this.basicInfo.controls['enrollement_status'].value,
      enrollment_window_close_mail_effective_date: this.basicInfo.controls['enrollment_window_close_mail_effective_date'].value,
      midterm_enrollement: this.basicInfo.controls['midterm_enrollement'].value,
      enrollment_allowed_days_mid_term: this.basicInfo.controls['enrollment_allowed_days_mid_term'].value,
      enrollment_start_date: this.basicInfo.controls['enrollment_start_date'].value,
      enrollment_end_date: this.basicInfo.controls['enrollment_end_date'].value,
      coverage_days: this.coverageDays,
      master_policies_id: localStorage.getItem('master_policies_id'),
    };

    console.log(postdata);

    this.clientCreationService.clientCreate(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          localStorage.setItem('master_policies_id', result.data.master_policies_id);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 2
  onSubmit_second() {

    let ParentData;
    let Parents_In_LawDivData;
    let SiblingDivData;
    let selfData;

    // console.log(this.memberType);

    let val15:any = this.memberType.indexOf('15')!== -1;
    if(val15 == true){
      this.spousedata = {
        no_age_spouse: this.familyInfo.controls['no_age_spouse'].value,
        min_age_spouse: this.familyInfo.controls['min_age_spouse'].value,
        max_age_spouse: this.familyInfo.controls['max_age_spouse'].value,
        marraige_certificate:this.familyInfo.controls['marraige_certificate'].value,
        is_lgbtq:this.familyInfo.controls['lgbt'].value,
        no_of_spouse:this.familyInfo.controls['no_of_spouse'].value,
        midterm_allowed_for_spouse:this.familyInfo.controls['midterm_allowed_for_spouse'].value,
        spouse_enrolment_allowed_days:this.familyInfo.controls['spouse_enrolment_allowed_days'].value,
        spouse_enrolment_considered:this.familyInfo.controls['spouse_enrolment_considered'].value,
      }
    }

    let val16:any = this.memberType.indexOf('16')!== -1;
    if(val16 == true){
      this.ChilData = {
        Child_age_depend:this.familyInfo.controls['Child_age_depend'].value,
        no_age_Children:this.familyInfo.controls['no_age_Children'].value,
        min_age_Child:this.familyInfo.controls['min_age_Child'].value,
        max_age_Child:this.familyInfo.controls['max_age_Child'].value,
        birth_certificate:this.familyInfo.controls['birth_certificate'].value,
        age_difference_Child:this.familyInfo.controls['age_difference_Child'].value,
        no_of_Child:this.familyInfo.controls['no_of_Child'].value,
        midterm_allowed_for_Child:this.familyInfo.controls['midterm_allowed_for_Child'].value,
        special_Child:this.familyInfo.controls['special_Child'].value,
        unmarried_Child:this.familyInfo.controls['unmarried_Child'].value,
        adopted_Child:this.familyInfo.controls['adopted_Child'].value,
        no_of_twin_child:this.familyInfo.controls['no_of_twin_child'].value,
      }
    }else{
      this.ChilData = {};
    }


    let val17:any = this.memberType.indexOf('17')!== -1;
    if(val17 == true){
      ParentData = {
        Parent_age_depend:this.familyInfo.controls['Parent_age_depend'].value,
        no_age_Parent:this.familyInfo.controls['no_age_Parent'].value,
        min_age_parent:this.familyInfo.controls['min_age_parent'].value,
        max_age_parent:this.familyInfo.controls['max_age_parent'].value,
        age_difference_parent:this.familyInfo.controls['age_difference_parent'].value,
        no_of_Parents:this.familyInfo.controls['no_of_Parents'].value,
        employee_allowed_Parents:this.familyInfo.controls['employee_allowed_Parents'].value,
      }
    }else{
      ParentData = {};
    }

    let val18:any = this.memberType.indexOf('18')!== -1;
    if(val18 == true){
      Parents_In_LawDivData = {
        Parents_In_Law_age_depend:this.familyInfo.controls['Parents_In_Law_age_depend'].value,
        no_age_Parents_In_Law:this.familyInfo.controls['no_age_Parents_In_Law'].value,
        min_age_parent_in_law:this.familyInfo.controls['min_age_parent_in_law'].value,
        max_age_parent_in_law:this.familyInfo.controls['max_age_parent_in_law'].value,
        age_difference_parent_in_law:this.familyInfo.controls['age_difference_parent_in_law'].value,
        no_of_Parents_In_Laws:this.familyInfo.controls['no_of_Parents_In_Laws'].value,
        employee_allowed_Parents_In_Law:this.familyInfo.controls['employee_allowed_Parents_In_Law'].value,
      }
    }else{
      Parents_In_LawDivData = {};
    }

    let val19:any = this.memberType.indexOf('19')!== -1;
    if(val19 == true){
      SiblingDivData = {
        no_of_Siblings:this.familyInfo.controls['no_of_Siblings'].value,
        no_age_Sibling:this.familyInfo.controls['no_age_Sibling'].value,
        min_age_sibling:this.familyInfo.controls['min_age_sibling'].value,
        max_age_sibling:this.familyInfo.controls['max_age_sibling'].value,
      }
    }else{
      SiblingDivData = {};
    }

    if(this.familyInfo.controls['employee_included'].value == 'Yes'){
      selfData = {
        member_type: this.familyInfo.controls['member_type'].value,
        no_age: this.familyInfo.controls['no_age'].value,
        min_age: this.familyInfo.controls['min_age'].value,
        max_age: this.familyInfo.controls['max_age'].value,
      }
    }else{
      selfData = {};
    }

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      "master_policies_id": localStorage.getItem('master_policies_id'),

      no_of_members_allowed: this.familyInfo.controls['no_of_members_allowed'].value,
      employee_included: this.familyInfo.controls['employee_included'].value,
      parent_cross_section: this.familyInfo.controls['parent_cross_section'].value,
      member_typeselect: JSON.stringify(this.memberType),

      selfData: selfData,
      spouseData: this.spousedata,
      chilData: this.ChilData,
      parentData: ParentData,
      Parents_In_LawData: Parents_In_LawDivData,
      siblingData: SiblingDivData,

    };

    console.log(postdata);

    this.clientCreationService.Step2_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 3
  onSubmit_third() {

    /********* Array ********/
    let SIArr: any = {};
    for(var i = 0; i < this.GenerateSICount.length; i++){
      console.log($('#sum_insured_'+i).val());
      SIArr[i] = {sum_insured: $('#sum_insured_'+i).val(), premium:$('#premium_'+i).val(), premium_tax:$('#premium_tax_'+i).val(), premium_total:$('#premium_total_'+i).val()};
    }

    let object = SIArr;
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
          if (object[key]['sum_insured'] === undefined || object[key]['premium'] === null || object[key]['premium_tax'] === null || object[key]['premium_total'] === null){
              delete object[key];
          }
      }
    }

    /********* Array ********/
    for(var i = 1; i < this.cnt_custom; i++){
      this.CustomArr[i] = {sum_insured: $('#sum_insured_'+i).val(), premium:$('#premium_'+i).val(), premium_tax:$('#premium_tax_'+i).val(), premium_total:$('#premium_total_'+i).val()};
    }

    let objectmetroArr = this.CustomArr;
    for (var key in objectmetroArr) {
      if (object.hasOwnProperty(key)) {
        if (object[key]['sum_insured'] === undefined || object[key]['premium'] === null || object[key]['premium_tax'] === null || object[key]['premium_total'] === null){
            delete object[key];
        }
      }
    }

    let tabSelected:any;
    var ref_this:any = $('#myTab .CA-tab').hasClass('active');
    if(ref_this == true){
      tabSelected = 1;
    }else{
      tabSelected = 2;
    }

    // Spouse
    let spouse_details:any;
    if($('.spouseDiv').css('display') == 'block'){
      spouse_details = {
        "spouse_additional_employer": this.SIPremiumInfo.controls['spouse_additional_employer'].value,
        "spouse_additional_employee": this.SIPremiumInfo.controls['spouse_additional_employee'].value,
        "spouse_additional_premium": this.SIPremiumInfo.controls['spouse_additional_premium'].value,
      }
    }else{
      spouse_details = {};
    }


    // Parent
    let parent_details:any;
    if($('.ParentDiv').css('display') == 'block'){
      parent_details = {
        "parent_additional_employer": this.SIPremiumInfo.controls['parent_additional_employer'].value,
        "parent_additional_employee": this.SIPremiumInfo.controls['parent_additional_employee'].value,
        "parent_additional_premium": this.SIPremiumInfo.controls['parent_additional_premium'].value,
      }
    }else{
      parent_details = {};
    }

    // Children
    let children_details:any;
    let special_child_contribution_all_employee:any;
    let unmarried_child_contribution_all_employee:any;
    let special_child_contribution_additional_employee:any;
    let unmarried_child_contribution_additional_employee:any;

    if($('.ChildrenDiv').css('display') == 'block'){
      children_details = {
        "children_additional_employer": this.SIPremiumInfo.controls['children_additional_employer'].value,
        "children_additional_employee": this.SIPremiumInfo.controls['children_additional_employee'].value,
        "children_additional_premium": this.SIPremiumInfo.controls['children_additional_premium'].value,
      }
      special_child_contribution_all_employee = {
        "special_child_contribution_all_employee": this.SIPremiumInfo.controls['special_child_contribution_all_employee'].value,
        "special_child_contribution_all_employer": this.SIPremiumInfo.controls['special_child_contribution_all_employer'].value,
        "special_child_contribution_all_premium": this.SIPremiumInfo.controls['special_child_contribution_all_premium'].value,
      }
      unmarried_child_contribution_all_employee = {
        "unmarried_child_contribution_all_employee": this.SIPremiumInfo.controls['unmarried_child_contribution_all_employee'].value,
        "unmarried_child_contribution_all_employer": this.SIPremiumInfo.controls['unmarried_child_contribution_all_employer'].value,
        "unmarried_child_contribution_all_premium": this.SIPremiumInfo.controls['unmarried_child_contribution_all_premium'].value,
      }
      special_child_contribution_additional_employee = {
        "special_child_contribution_additional_employee": this.SIPremiumInfo.controls['special_child_contribution_additional_employee'].value,
        "special_child_contribution_additional_employer": this.SIPremiumInfo.controls['special_child_contribution_additional_employer'].value,
        "special_child_contribution_additional_premium": this.SIPremiumInfo.controls['special_child_contribution_additional_premium'].value,
      }
      unmarried_child_contribution_additional_employee = {
        "unmarried_child_contribution_additional_employee": this.SIPremiumInfo.controls['unmarried_child_contribution_additional_employee'].value,
        "unmarried_child_contribution_additional_employer": this.SIPremiumInfo.controls['unmarried_child_contribution_additional_employer'].value,
        "unmarried_child_contribution_additional_premium": this.SIPremiumInfo.controls['unmarried_child_contribution_additional_premium'].value,
      }
    }else{
      children_details = {};
      special_child_contribution_all_employee= {};
      unmarried_child_contribution_all_employee = {};
      unmarried_child_contribution_additional_employee= {};
      special_child_contribution_additional_employee = {};
    }

    // Parents In Law
    let parent_in_laws_details:any;
    if($('.Parents_In_LawDiv').css('display') == 'block'){
      parent_in_laws_details = {
        "parent_in_laws_additional_employer": this.SIPremiumInfo.controls['parent_in_laws_additional_employer'].value,
        "parent_in_laws_additional_employee": this.SIPremiumInfo.controls['parent_in_laws_additional_employee'].value,
        "parent_in_laws_additional_premium": this.SIPremiumInfo.controls['parent_in_laws_additional_premium'].value,
      }
    }else{
      parent_in_laws_details = {};
    }

    // Siblings
    let siblings_details:any;
    if($('.SiblingDiv').css('display') == 'block'){
      siblings_details = {
        "siblings_additional_employer": this.SIPremiumInfo.controls['siblings_additional_employer'].value,
        "siblings_additional_employee": this.SIPremiumInfo.controls['siblings_additional_employee'].value,
        "siblings_additional_premium": this.SIPremiumInfo.controls['siblings_additional_premium'].value,
      }
    }else{
      siblings_details = {};
    }

    let MonthlyEquitible:any
    if(this.MonthlyEquitible.length != 0){
      MonthlyEquitible = this.MonthlyEquitible;
    }else{
      MonthlyEquitible = '';
    }

    let NoOfTimesSalary:any
    if(this.NoOfTimesSalary.length != 0){
      NoOfTimesSalary = this.NoOfTimesSalary;
    }else{
      NoOfTimesSalary = '';
    }

    let AdditionalCoverArray:any
    if(this.AdditionalCoverArray.length != 0){
      AdditionalCoverArray = this.AdditionalCoverArray;
    }else{
      AdditionalCoverArray = '';
    }

    let SIbasis:any = this.SIPremiumInfo.controls['SI_Basis'].value;
    let tax_status:any;
    let employee_premium_tax:any;
    if(SIbasis == "1"){
      tax_status = $("input[name='tax_status']:checked").val();
      employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax'].value;
    }
    if(SIbasis == "3"){
      tax_status = $("input[name='tax_status1']:checked").val();
      employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax1'].value;
    }
    if(SIbasis == "5"){
      tax_status = $("input[name='tax_status2']:checked").val();
      employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax2'].value;
    }
    if(SIbasis == "17"){
      tax_status = $("input[name='tax_status3']:checked").val();
      employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax3'].value;
    }

    let gst_flag:any = this.basicInfo.controls['show_gst_flag'].value;

    if (gst_flag === "Yes") {

      let total_premium_unnamedGST = $('#total_premium_unnamedGST').val();
      this.inception_premiumGST = total_premium_unnamedGST;
      this.additionalInfo.controls['inception_premium'].setValue(this.inception_premiumGST);
  }

  if (gst_flag === "No") {
      let total_premium_unnamed = $('#total_premium_unnamed').val();
      this.inception_premium = total_premium_unnamed;
      this.additionalInfo.controls['inception_premium'].setValue(this.inception_premium);
  }

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      "master_policies_id": localStorage.getItem('master_policies_id'),

      "cover_type": this.SIPremiumInfo.controls['cover_type'].value,
      "salary_type": this.SIPremiumInfo.controls['salary_type'].value,
      "SI_Type": this.SIPremiumInfo.controls['SI_Type'].value,
      "SI_Basis": this.SIPremiumInfo.controls['SI_Basis'].value,
      "premium_Basis": this.SIPremiumInfo.controls['premium_Basis'].value,

      "SIArr": JSON.stringify(SIArr),
      "SIArr_custom": JSON.stringify(this.CustomArr),
      "MonthlyEquitible": JSON.stringify(MonthlyEquitible),
      "NoOfTimesSalary": JSON.stringify(NoOfTimesSalary),

      "additional_cover":this.SIPremiumInfo.controls['additional_cover'].value,
      "AdditionalCover": JSON.stringify(AdditionalCoverArray),

      "minimum_SI": this.SIPremiumInfo.controls['minimum_SI'].value,
      "maximum_SI": this.SIPremiumInfo.controls['maximum_SI'].value,
      "interval_between_min_max_SI": this.SIPremiumInfo.controls['interval_between_min_max_SI'].value,

      "ca_employer": this.SIPremiumInfo.controls['ca_employer'].value,
      "ca_employee": this.SIPremiumInfo.controls['ca_employee'].value,

      "self_additional_employer": this.SIPremiumInfo.controls['self_additional_employer'].value,
      "self_additional_employee": this.SIPremiumInfo.controls['self_additional_employee'].value,
      "self_additional_premium": this.SIPremiumInfo.controls['self_additional_premium'].value,

      "spouse_details": spouse_details,
      "parent_details": parent_details,
      "children_details": children_details,
      "special_child_contribution_all_employee": special_child_contribution_all_employee,
      "unmarried_child_contribution_all_employee": unmarried_child_contribution_all_employee,
      "special_child_contribution_additional_employee": special_child_contribution_additional_employee,
      "unmarried_child_contribution_additional_employee": unmarried_child_contribution_additional_employee,

      "parent_in_laws_details": parent_in_laws_details,
      "siblings_details": siblings_details,

      "use_flex":this.SIPremiumInfo.controls['use_flex'].value,
      "payroll":this.SIPremiumInfo.controls['payroll'].value,
      "installment_allowed":this.SIPremiumInfo.controls['installment_allowed'].value,
      "installment_prorata":this.SIPremiumInfo.controls['installment_prorata'].value,
      "installment_type":$("input[name='installment_type']:checked").val(),
      "monthly_equitible_period":this.SIPremiumInfo.controls['monthly_equitible_period'].value,

      "tabSelected": tabSelected,

      "emmployeefile_id": this.emmployeefile_id,
      "tax_status": tax_status,
      "employee_premium_tax": employee_premium_tax,

      "employee_file": this.employee_file,

      "grade_SI_file": this.grade_SI,
      "grade_SP_file": this.grade_SP,

      "unnamed_file": this.unnamed,
      "total_employee": this.SIPremiumInfo.controls['total_employee'].value,
      "total_SI": this.SIPremiumInfo.controls['total_SI'].value,
      "total_premium": this.SIPremiumInfo.controls['total_premium'].value,

      "total_employee_unnamed": this.SIPremiumInfo.controls['total_employee_unnamed'].value,
      "gpa_individual_cover": this.SIPremiumInfo.controls['gpa_individual_cover'].value,
      "total_SI_unnamed": $('#total_SI_unnamed').val(),
      "total_premium_unnamed": $('#total_premium_unnamed').val(),
      "per_milliRate": this.perMili_rate,
      "gst_unnamed": this.SIPremiumInfo.controls['gst_unnamed'].value,
      "coverage_days": $('#gpa_coverage_days').val(),
      "prorata_premium": $('#prorata_premium_unnamed').val(),
      "gst_amount": $('#gst_amount_unnamed').val(),
      // "prorata_premium": this.SIPremiumInfo.controls['prorata_premium_unnamed'].value,
      // "gst_amount": this.SIPremiumInfo.controls['gst_amount_unnamed'].value,
      "total_premium_unnamedGST": $('#total_premium_unnamedGST').val(),

      "permily_age": this.permily_age,

    };

    console.log(postdata);

    this.clientCreationService.Step3_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 4
  onSubmit_fourth() {

    /********* Array ********/
    for(var i = 1; i < this.cnt_broker; i++){
      let emailval = '#b_emailID_'+i+'option:selected';
      this.brokerArr_step4[i] = {b_role_designation_type: $('#b_role_designation_type_'+i).val(), b_emailID:$(emailval).text(), b_emailID_ID:$('#b_emailID').val(), b_contactname:$('#b_contactname_'+i).val(), b_mobileno:$('#b_mobileno_'+i).val(), b_level:$('#b_level_'+i).val()};
    }

    for(var i = 1; i < this.cnt_employer; i++){
      let emailval = '#e_emailID_'+i+'option:selected';
      this.employer_step4Arr[i] = {e_role_designation_type: $('#e_role_designation_type_'+i).val(), e_emailID:$(emailval).text(), e_emailID_ID:$('#e_emailID').val(), e_contactname:$('#e_contactname_'+i).val(), e_mobileno:$('#e_mobileno_'+i).val(), e_level:$('#e_level_'+i).val()};
    }

    for(var i = 1; i < this.cnt_tpa; i++){
      this.tpa_step4Arr[i] = {tpa_role_designation_type: $('#tpa_role_designation_type_'+i).val(), tpa_emailID:$('#tpa_emailID_'+i).val(), tpa_contactname:$('#tpa_contactname_'+i).val(), tpa_mobileno:$('#tpa_mobileno_'+i).val(), tpa_level:$('#tpa_level_'+i).val()};
    }

    for(var i = 1; i < this.cnt_Insurer; i++){
      this.Insurer_step4Arr[i] = {step4_insurer_id: $('#step4_insurer_id_'+i).val(), Insurer_emailID:$('#Insurer_emailID_'+i).val(), Insurer_contactname:$('#Insurer_contactname_'+i).val(), Insurer_mobileno:$('#Insurer_mobileno_'+i).val(), Insurer_level:$('#Insurer_level_'+i).val()};
    }

    console.log($('#b_emailID option:selected').text());
    console.log($('#e_emailID option:selected').text());

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      "master_policies_id": localStorage.getItem('master_policies_id'),

      "b_role_designation_type":this.contactInfo.controls['b_role_designation_type'].value,
      "b_emailID":$('#b_emailID option:selected').text(),
      "b_emailID_ID":$('#b_emailID').val(),
      "b_contactname":this.contactInfo.controls['b_contactname'].value,
      "b_mobileno":this.contactInfo.controls['b_mobileno'].value,
      "b_level":this.contactInfo.controls['b_level'].value,

      "e_role_designation_type":this.contactInfo.controls['e_role_designation_type'].value,
      "e_emailID":$('#e_emailID option:selected').text(),
      "e_emailID_ID":$('#e_emailID').val(),
      "e_contactname":this.contactInfo.controls['e_contactname'].value,
      "e_mobileno":this.contactInfo.controls['e_mobileno'].value,
      "e_level":this.contactInfo.controls['e_level'].value,

      "tpa_role_designation_type":this.contactInfo.controls['tpa_role_designation_type'].value,
      "tpa_emailID":this.contactInfo.controls['tpa_emailID'].value,
      "tpa_contactname":this.contactInfo.controls['tpa_contactname'].value,
      "tpa_mobileno":this.contactInfo.controls['tpa_mobileno'].value,
      "tpa_level":this.contactInfo.controls['tpa_level'].value,

      "insurer_id_step4":this.contactInfo.controls['insurer_id_step4'].value,
      "insurer_emailID":this.contactInfo.controls['Insurer_emailID'].value,
      "insurer_contactname":this.contactInfo.controls['Insurer_contactname'].value,
      "insurer_mobileno":this.contactInfo.controls['Insurer_mobileno'].value,
      "insurer_level":this.contactInfo.controls['Insurer_level'].value,

      "brokerArr": JSON.stringify(this.brokerArr_step4),
      "employerArr": JSON.stringify(this.employer_step4Arr),
      "tpaArr": JSON.stringify(this.tpa_step4Arr),
      "insurerArr": JSON.stringify(this.Insurer_step4Arr),
    }

    console.log(postdata);

    this.clientCreationService.Step4_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 5
  onSubmit_fivth() {

    /********* Benefit Array ********/
    // for(var i = 1; i < this.cnt_add_benefit; i++){
    //   this.add_benefit_arr[i] = {label: $('#label_'+i).val(), limit:$('#limit_'+i).val()};
    // }

    // let object = this.add_benefit_arr;
    // for (var key in object) {
    //   if (object.hasOwnProperty(key)) {
    //       if (object[key]['label'] === undefined || object[key]['limit'] === null){
    //           delete object[key];
    //       }
    //   }
    // }

    let installment_arr:any = {};
    for(var i = 0; i < this.installmentArr.length; i++){
      installment_arr[i] = {installment: 'Installment '+ i, installment_amount:$('#installment_amount_'+i).val()};
    }

    let relationDetails_arr:any = {};
    let relarr:any = [];
    for(var i = 0; i < this.relationDetails.length; i++){
      let valchecked:any = $('#relation_'+this.relationDetails[i]['id']).is(":checked");
      if(valchecked == false){
        // relationDetails_arr[i] = {$('#relation_'+i).val()};
      }else{
        let val:any = this.relationDetails[i]['id'];
        relarr.push(val);
      }
    }

    relationDetails_arr = {
      "id" : relarr
    }

    // console.log(relationDetails_arr);

    let nomination_applicable = this.additionalInfo.controls['nomination_applicable'].value;
    let allowed_members = this.additionalInfo.controls['allowed_members'].value;
    var postdata:any;

    let allowvalue;
    if(this.additionalInfo.controls['allowed_members'].value == "Selected Members"){
      allowvalue = 2;
    }else{
      allowvalue = 1;
    }

    let cd_summary_checkbox = this.additionalInfo.controls['cd_summary_checkbox'].value;
    var cd_summary: any;
    if(cd_summary_checkbox == true){
      cd_summary = 1;
    }else{
      cd_summary = 0;
    }

    if(nomination_applicable == "Yes"){

      if(allowed_members == "Selected Members"){
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "parent_id":this.basicInfo.controls['company_id'].value,
          "broker_id":localStorage.getItem('broker_id'),
          "master_policies_id": localStorage.getItem('master_policies_id'),

          "nomination_applicable": this.additionalInfo.controls['nomination_applicable'].value,
          "nomination_mandatory": this.additionalInfo.controls['nomination_mandatory'].value,
          "declaration_mandatory": this.additionalInfo.controls['declaration_mandatory'].value,

          // "add_benefit_arr": JSON.stringify(this.add_benefit_arr),
          "installment_arr": JSON.stringify(installment_arr),
          "relationDetails_arr": JSON.stringify(relationDetails_arr),
          "cd_summary_checkbox":cd_summary,
          "opening_cd_balance":this.additionalInfo.controls['opening_cd_balance'].value,
          "cd_balance_thrsold":this.additionalInfo.controls['cd_balance_thrsold'].value,
          "inception_premium":this.additionalInfo.controls['inception_premium'].value,
          "inception_premium_installment":this.additionalInfo.controls['inception_premium_installment'].value,
          "allowed_members":allowvalue,
        }
      }else{
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "parent_id":this.basicInfo.controls['company_id'].value,
          "broker_id":localStorage.getItem('broker_id'),
          "master_policies_id": localStorage.getItem('master_policies_id'),

          "nomination_applicable": this.additionalInfo.controls['nomination_applicable'].value,
          "nomination_mandatory": this.additionalInfo.controls['nomination_mandatory'].value,
          "declaration_mandatory": this.additionalInfo.controls['declaration_mandatory'].value,

          // "add_benefit_arr": JSON.stringify(this.add_benefit_arr),
          "installment_arr": JSON.stringify(installment_arr),
          "relationDetails_arr": {},
          "opening_cd_balance":this.additionalInfo.controls['opening_cd_balance'].value,
          "cd_summary_checkbox":cd_summary,
          "cd_balance_thrsold":this.additionalInfo.controls['cd_balance_thrsold'].value,
          "inception_premium":this.additionalInfo.controls['inception_premium'].value,
          "inception_premium_installment":this.additionalInfo.controls['inception_premium_installment'].value,
          "allowed_members":allowvalue,
        }
      }

    }else{

      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "parent_id":this.basicInfo.controls['company_id'].value,
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),

        "nomination_applicable": this.additionalInfo.controls['nomination_applicable'].value,
        "nomination_mandatory": this.additionalInfo.controls['nomination_mandatory'].value,
        "declaration_mandatory": this.additionalInfo.controls['declaration_mandatory'].value,

        // "add_benefit_arr": JSON.stringify(this.add_benefit_arr),
        "installment_arr": JSON.stringify(installment_arr),
        "relationDetails_arr": {},
        "cd_summary_checkbox":cd_summary,
        "opening_cd_balance":this.additionalInfo.controls['opening_cd_balance'].value,
        "cd_balance_thrsold":this.additionalInfo.controls['cd_balance_thrsold'].value,
        "inception_premium":this.additionalInfo.controls['inception_premium'].value,
        "inception_premium_installment":this.additionalInfo.controls['inception_premium_installment'].value,
        "allowed_members":'',
      }

    }


    console.log(postdata);

    this.clientCreationService.Step5_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 6
  onSubmit_sixth() {

    let document_fields:any = {};
    for(var i = 1; i < this.uploadcnt; i++){

      var a = $('#document_name_'+i).val();
      var b = $('#document_type_'+i).val();
      var c = $('#document_mandatory_'+i).val();

      document_fields[i] = {
        document_name:a,
        document_type:b,
        document_mandatory:c,
      };
    }

    // console.log(document_fields);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      "master_policies_id": localStorage.getItem('master_policies_id'),

      "document_file": this.document_file,
      "uploadExtraArr": this.uploadExtraArr,
      "document_name": this.claimInfo.controls['document_name'].value,
      "document_type": this.claimInfo.controls['document_type'].value,
      "document_mandatory": this.claimInfo.controls['document_mandatory'].value,
      "no_of_back_days": this.claimInfo.controls['no_of_back_days'].value,
      "digital_claim": this.claimInfo.controls['digital_claim'].value,
      "claim_intimate_mandatory": this.claimInfo.controls['claim_intimate_mandatory'].value,
      "document_fields": JSON.stringify(document_fields),
      "is_update": "0"
    }

    // console.log(postdata);

    this.clientCreationService.Step6_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');
          this.policysamplefile = result.data["policy_features_sample"];
          //doanload_policy_file
          $("#policyfeatures_document_file_download").attr("href", result.data["policy_features_sample"]);

          this.downloadbuttontooltip = result.data["policy_name"];
        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 7
  onSubmit_seventh() {

    let arr:any = {};
    for(var i = 1; i < this.cnt_BenefitData; i++){
      arr[i] = {
        feature_name: $('#feature_name_'+i).val(),
        feature_description:$('#feature_description_'+i).val(),
        add_on_SI: $('#add_on_SI_'+i).val(),
        cover_by: $('#cover_by_'+i).val(),
        cover_type: $('#cover_type_'+i).val(),
        sum_insured_cap: $('#sum_insured_cap_'+i).val(),
        premium_by: $('#premium_by_'+i).val(),
        premium_value: $('#premium_value_'+i).val(),
        premium_type: $('#premium_type_'+i).val(),
        visible_from_SI_value: $('#visible_from_SI_value_'+i).val(),
      };
    }

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      "master_policies_id": localStorage.getItem('master_policies_id'),
      "policy_feature_document": this.policy_feature_document,

      "benefit_name": this.step7Info.controls['benefit_name'].value,
      "benefit_description": this.step7Info.controls['benefit_description'].value,

      "benefit_name_val": this.step7Info_popup.controls['benefit_name_val'].value,
      "benefit_description_val": this.step7Info_popup.controls['benefit_description_val'].value,
      "benefit_visible_from_SI": this.step7Info_popup.controls['benefit_visible_from_SI'].value,
      "benefit_mandatory": this.step7Info_popup.controls['benefit_mandatory'].value,
      "prorata_calculation_applicable": this.step7Info_popup.controls['prorata_calculation_applicable'].value,
      "add_benefit_array": JSON.stringify(arr),
    }

    this.clientCreationService.Step7_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');
          this.route.navigate(['listpolicies'])

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  closePopup() {
    this.displayinceptionmsg = "none";
    this.displayfamilymsg = "none";
    this.Standardmsgtoggle="none";
    this.PopupBenefits="none";
  }

  companyID(){
    this.getEntity();
    var atLeastOneIsChecked = $('#company_id').val();
    if(atLeastOneIsChecked != ''){
      $('.company_idDiv').css('display', 'block');
      $('.cdetails').css('margin-top', '18px');
    }else{
      $('.company_idDiv').css('display', 'none');
      $('.cdetails').css('margin-top', '0px');
    }
  }

  /***************************************/
  /***** Add Field *****/
  addBenefitField(){

    let html = `
    <div id="this_benefit_row_`+this.cnt_allowed+`" class="row">
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="member_type_`+this.cnt_allowed+`">Member type </label>
          <select class="form-select" formControlName="member_type_`+this.cnt_allowed+`" id="member_type_`+this.cnt_allowed+`">
                <option value="" selected>Select Member Type</option>
          </select>
      </div>
      <div class="col-sm-2 form-group mt-3">
          <div class="form-check" style="margin-top: 30px;">
              <input type="checkbox" formControlName="no_age_`+this.cnt_allowed+`" id="no_age_`+this.cnt_allowed+`" class="form-check-input ng-valid ng-dirty ng-touched" style="margin-left: 0px; margin-top: 0px; cursor: pointer;">
              <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
          </div>
      </div>
      <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
          <label class="bs-form-label" for="min_age_`+this.cnt_allowed+`">Min Age *</label>
          <input type="text" class="form-control" formControlName="min_age_`+this.cnt_allowed+`" id="min_age_`+this.cnt_allowed+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
          <label class="bs-form-label" for="max_age_`+this.cnt_allowed+`">Max Age *</label>
          <input type="text" class="form-control" formControlName="max_age_`+this.cnt_allowed+`" id="max_age_`+this.cnt_allowed+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="benefit_row_`+this.cnt_allowed+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newbenifitField').append(html);

    for(var i=0; i<this.Membertype.length; i++){
      var val = '#' + 'member_type_'+ this.cnt;
      $(val)
        .append($("<option></option>")
          .attr("value", this.Membertype[i]['id'])
          .text(this.Membertype[i]['name']));
    }

    this.familyInfo.addControl("member_type_"+this.cnt_allowed, new FormControl(''));
    this.familyInfo.addControl("no_age_"+this.cnt_allowed, new FormControl(''));
    this.familyInfo.addControl("min_age_"+this.cnt_allowed, new FormControl(''));
    this.familyInfo.addControl("max_age_"+this.cnt_allowed, new FormControl(''));

    this.allowedArr[this.cnt_allowed] = {member_type: "", no_age: "", min_age: "", max_age: ""};

    let flag = this;
    $("#benefit_row_"+this.cnt_allowed).on('click',function(e) {
      flag.removeRow(e);
    });

    $("#no_age_"+this.cnt_allowed).on('change',function(e) {
      flag.isnoage(e);
    });

    $("#min_age_"+this.cnt_allowed).on('keyup',function(e) {
      flag.isnumberBenefit(e);
    });

    $("#max_age_"+this.cnt_allowed).on('keyup',function(e) {
      flag.isnumberBenefit(e);
    });

    this.cnt_allowed++;

  }

  removeRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_benefit_row_' + id;
    $(idstr).remove();

  }

  isnumberBenefit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  isnoage(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    let valchecked:any = $("#" + str).is(":checked");
    var value = '.noAgeDiv_' + id;
    if(valchecked == false){
      $(value).css('display', 'block');
    }else{
      $(value).css('display', 'none');
    }
  }
  /***************************************/

  member_data_change(event: any) {
    if (event.target.files.length > 0) {

      this.document_file = event.target.files[0];
      console.log(event.target.files[0])

      $("#custom-file-upload_document_file").empty();
      $('#custom-file-upload_document_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_document_file').empty();
      $('#small_document_file').html('');
      $('#small_document_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }

  /***** Add Upload Field *****/
  addUploadField(){

    let html = `
    <hr>
    <div class="row mt-3" id="this_upload_row_`+this.uploadcnt+`">
      <div class="col-sm-3">
          <div class="form-group">
              <label class="bs-form-label" for="document_name_`+this.uploadcnt+`">Document Name</label>
              <input type="text" class="form-control" id="document_name_`+this.uploadcnt+`" placeholder="Document Name" />
          </div>
      </div>
      <div class="col-sm-3">
          <div class="form-group">
              <label class="bs-form-label" for="document_type_`+this.uploadcnt+`">Document Type *</label>
              <select class="form-select" id="document_type_`+this.uploadcnt+`">
                  <option value="" selected>Select Document Type</option>
              </select>
          </div>
      </div>
      <div class="col-sm-3">
          <div class="form-group">
              <label class="bs-form-label" for="document_mandatory_`+this.uploadcnt+`">Mandatory</label>
              <select class="form-select" id="document_mandatory_`+this.uploadcnt+`">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
              </select>
          </div>
      </div>
      <div class="col-sm-6">
          <div class="form-group">
              <label class="bs-form-label" for="upload_field_`+this.uploadcnt+`">Document</label>
              <label for="file_field_extra`+this.uploadcnt+`" id="custom-file-upload_`+this.uploadcnt+`" class="custom-file-upload form-control extrafile" style="text-align:center; cursor:pointer; padding: 5px;">
                  <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
              </label>
              <input id="file_field_extra`+this.uploadcnt+`" type="file" accept=".xlx, xlxs, .csv, .txt" class="extrafile" enctype="multipart/form-data" style="display: none" />
              <input id="`+this.uploadcnt+`" type="hidden" class="filehidden" />
          </div>
          <small style="font-size: 12px; color:#605D62" id="small_extra`+this.uploadcnt+`">Upload only .xlx, xlxs, .csv, .txt files</small>
      </div>
      <div class="col-sm-1 form-group pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    <hr>
    `;

    $(html).insertBefore('.newuploadField4');

    $('#document_mandatory_yes_' +this.uploadcnt).val("Yes");


    for(var i=0; i<this.documentType.length; i++){
      var val = '#' + 'document_type_'+ this.uploadcnt;
      $(val)
        .append($("<option></option>")
          .attr("value", this.documentType[i]['id'])
          .text(this.documentType[i]['document_type']));
    }

    var uploadcnt = this.uploadcnt;
    const extraUploadArr: any = {};

    $('.extrafile').on("change", function(event: any, extrafileArr: []){

      // console.log(uploadcnt);

      if (event.target.files.length > 0) {

        extrafileArr = event.target.files[0];

        $("#custom-file-upload_" + uploadcnt).empty();
        $('#custom-file-upload_' + uploadcnt).html(event.target.files[0]['name']);
        $('#custom-file-upload_' + uploadcnt).css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_' + uploadcnt).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_extra'+ uploadcnt).empty();
        $('#small_extra'+ uploadcnt).html('');
        $('#small_extra'+ uploadcnt).append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

      console.log(extrafileArr);

      extraUploadArr[uploadcnt] = extrafileArr;
      console.log(extraUploadArr);

    });

    console.log(extraUploadArr);
    this.uploadExtraArr[this.uploadcnt] = extraUploadArr;
    console.log(this.uploadExtraArr);

    let flag = this;
    $("#upload_row_"+this.uploadcnt).on('click',function(e) {
      flag.removeUploadRow(e);
    });

    this.uploadcnt++;

  }

  removeUploadRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_upload_row_' + id;
    $(idstr).remove();
    delete this.uploadExtraArr[id];
    this.uploadcnt = parseInt(this.uploadcnt) - 1;
  }

  /*****************************************/
  policyfeaturedocument(event: any){
    if(event.target.files.length > 0){
      this.policy_feature_document = event.target.files[0];
      // console.log(this.policy_feature_document);
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      // $('#policyfeatures_document_file').empty();
      // $('#policyfeatures_document_file').html('');
      // $('#policyfeatures_document_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');

    }
  }

  enrollement_status(){
    var atLeastOneIsChecked = $('#enrollement_status').is(':checked');
    if(atLeastOneIsChecked == true){
      $('.enrollement_statusDiv').css('display', 'flex');
    }else{
      $('#enrollment_window_close_mail_effective_date').val('');
      $('.enrollement_statusDiv').css('display', 'none');
    }
  }

  fnss(val1:any,val:any){
    if($('#'+val1).is(':checked')){
      $('#'+val).val("Yes");
    } else {
      $('#'+val).val("No");
    }

  }

  radio_yesno(val:any, text: any){

    if(text == 'yes'){

      $('#'+val+'_'+text).css('background', '#3c0111');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_no').css('background', '#f1f1f1');
      $('#'+val+'_no').css('color', '#000');

    }else{

      $('#'+val+'_'+text).css('background', '#9e9e9e');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_yes').css('background', '#f1f1f1');
      $('#'+val+'_yes').css('color', '#000');

    }
  }

  employeeIncluded(){
    let valchecked:any = this.familyInfo.controls['employee_included'].value;
    if(valchecked == "No"){
      $(".member_typeNo").css('display', 'flex');
      $(".member_typeYes").css('display', 'none');
    }else{
      $(".member_typeNo").css('display', 'none');
      $(".member_typeYes").css('display', 'flex');
    }
  }

  noAge(val:any){
    let valchecked:any = $("#" + val).is(":checked");
    if(valchecked == false){
      $(".Div_" + val).css('display', 'block');
    }else{
      $(".Div_" + val).css('display', 'none');
    }
  }

  memberTypeselect(){

    let valchecked:any = this.familyInfo.controls['member_typeselect'].value;

    this.memberType.indexOf(valchecked) === -1 ? this.memberType.push(valchecked) : console.log("This item already exists");

    if(valchecked == 15){
      if($('.spouseDiv:visible').length == 0){
        $(".spouseDiv").css('display', 'block');
      }
    }

    if(valchecked == 16){
      if($('.ChildrenDiv:visible').length == 0){
        $(".ChildrenDiv").css('display', 'block');
      }
    }

    if(valchecked == 17){
      if($('.ParentDiv:visible').length == 0){
        $(".ParentDiv").css('display', 'block');
      }
    }

    if(valchecked == 18){
      if($('.Parents_In_LawDiv:visible').length == 0){
        $(".Parents_In_LawDiv").css('display', 'block');
      }
    }

    if(valchecked == 19){
      if($('.SiblingDiv:visible').length == 0){
        $(".SiblingDiv").css('display', 'block');
      }
    }

    if($('.ParentDiv').css('display') == 'block' && $('.Parents_In_LawDiv').css('display') == 'block')
    {
      $('.Parents_addional_div').css('display', 'block');
    }else{
      $('.Parents_addional_div').css('display', 'none');
    }
  }

  midterm_allowed_for_spouse(){
    let valchecked:any = $("#midterm_allowed_for_spouse").is(":checked");

    if(valchecked == true){
      $("#midterm_allowed_for_spouseDiv").css('display', 'block');
    }else{
      $("#midterm_allowed_for_spouseDiv").css('display', 'none');
    }
  }

  removeDIV(val:any){
    if(val == 'spouse'){
      $('.spouseDiv').css('display', 'none');
      $('#no_of_spouse').val('');
      this.memberType.indexOf(15) === -1 ? this.memberType.pop(15) : '';
    }
    if(val == 'Children'){
      $('.ChildrenDiv').css('display', 'none');
      $('#no_of_Child').val('');
      this.memberType.indexOf(15) === -1 ? this.memberType.pop(16) : '';
    }
    if(val == 'Parent'){
      $('.ParentDiv').css('display', 'none');
      $('#no_of_Parents').val('');
      this.memberType.indexOf(15) === -1 ? this.memberType.pop(17) : '';
    }
    if(val == 'Parents_In_Law'){
      $('.Parents_In_LawDiv').css('display', 'none');
      $('#no_of_Parents_In_Laws').val('');
      this.memberType.indexOf(15) === -1 ? this.memberType.pop(18) : '';
    }
    if(val == 'Sibling'){
      $('.SiblingDiv').css('display', 'none');
      $('#no_of_Siblings').val('');
      this.memberType.indexOf(15) === -1 ? this.memberType.pop(19) : '';
    }

    if(val == 'Parent'){
      $('.Parents_addional_div').css('display', 'none');
      this.memberType.indexOf(15) === -1 ? this.memberType.pop(17) : '';
    }
    if(val == 'Parents_In_Law'){
      $('.Parents_addional_div').css('display', 'none');
      this.memberType.indexOf(15) === -1 ? this.memberType.pop(18) : '';
    }
  }

  Sum_Insured_BasisDiv(){
    let valchecked:any = $("#SI_Basis").val();

    if(valchecked == 1){
      $(".Sum_Insured_BasisDiv").css('display', 'block');
    }else{
      $(".Sum_Insured_BasisDiv").css('display', 'none');
    }

    let SI_Basis:any = $('#SI_Basis').val();
    let premium_Basis:any = $('#premium_Basis').val();

    // console.log(SI_Basis);
    // console.log(premium_Basis);

    this.emmployeefile_id = premium_Basis;

    if(SI_Basis == '1' && premium_Basis == '2' || SI_Basis == '1' && premium_Basis == '8' || SI_Basis == '1' && premium_Basis == '9' || SI_Basis == '1' && premium_Basis == '13' || SI_Basis == '1' && premium_Basis == '15' || SI_Basis == '3' && premium_Basis == '1'){
      $('.employee_age_div').css('display', 'block');
    }else{
      $('.employee_age_div').css('display', 'none');
    }

    if(SI_Basis == '3' && premium_Basis == '4' || SI_Basis == '3' && premium_Basis == '2'){
      $('.grade_div').css('display', 'flex');
    }else{
      $('.grade_div').css('display', 'none');
    }

    if(SI_Basis == '5' && premium_Basis == '5'){
      $('.no_times_salary_div').css('display', 'flex');
    }else{
      $('.no_times_salary_div').css('display', 'none');
    }

    if(SI_Basis == '17' && premium_Basis == '19'){
      $('.unnamedDiv').css('display', 'block');
    }else{
      $('.unnamedDiv').css('display', 'none');
    }

    if(SI_Basis == '17' && premium_Basis == '20'){
      $('.unnamed_unnamedDiv').css('display', 'block');
    }else{
      $('.unnamed_unnamedDiv').css('display', 'none');
    }

    if(SI_Basis == '3' && premium_Basis == '1' || SI_Basis == '1' && premium_Basis == '1'){
      $(".Sum_Insured_BasisDiv").css('display', 'block');
    }else{
      $(".Sum_Insured_BasisDiv").css('display', 'none');
    }

  }

  GenerateSI(){

    let first:any = $("#minimum_SI").val();
    let second:any = $("#maximum_SI").val();
    let interval:any = $("#interval_between_min_max_SI").val();

    // Calculate the difference between the start and end numbers
    const difference = Math.abs(first - second);
    this.RowCount = 0;

    // Calculate the count by dividing the difference by the interval and rounding down
    if(this.RowCount == 0){
      this.RowCount = Math.floor(difference / interval);
    }else{
      this.RowCount = parseInt(this.RowCount) + Math.floor(difference / interval);
    }
    // console.log("GenerateSICount count", this.GenerateSICount.length)

    this.RowCount = parseInt(this.RowCount) + 1;

    for(var i=0; i<this.RowCount; i++){

      if(this.GenerateSICount.length == 0){
        this.diffval = parseInt(first);
        this.GenerateSICount.push(this.diffval);
      }else{

        this.diffval = parseInt(this.diffval) + parseInt(interval);
        this.diffArr.push(this.diffval);

        let arrval:any = this.GenerateSICount[this.GenerateSICount.length - 1];
        let val = parseInt(arrval) + 1;
        this.GenerateSICount.push(this.diffval);
      }
    }

    // console.log("Diff array", this.diffArr);

    if(this.GenerateSICount.length == 0){
      $(".SITable").css('display', 'none');
    }else{
      $(".SITable").css('display', 'block');
    }

    console.log(this.GenerateSICount);
    // $("#minimum_SI").val('');
    // $("#maximum_SI").val('');
    // $("#interval_between_min_max_SI").val('');

  }

  SIRemove(id:any, index:any){
    this.RowCount = parseInt(this.RowCount) - 1;
    var idstr = '#' + id;
    $(idstr).remove();
    this.GenerateSICount.splice(index, 1);
    console.log(this.GenerateSICount);
    console.log(this.RowCount);
  }

  payroll(){
    let valchecked:any = $("#payroll").is(":checked");
    let valchecked_IA:any = $("#installment_allowed").is(":checked");
    if(valchecked == true){
      if(valchecked_IA == true){
        $(".installment_allowed").css('display', 'block');
      }
      $(".payroll").css('display', 'block');
    }else{
      $(".payroll").css('display', 'none');
      if(valchecked_IA == true){
        $(".installment_allowed").css('display', 'none');
      }
    }
  }

  installment_allowed(){
    let valchecked:any = $("#installment_allowed").is(":checked");
    if(valchecked == true){
      $(".installment_allowed").css('display', 'block');
    }else{
      $(".installment_allowed").css('display', 'none');
    }
  }

  additional_cover(){
    let valchecked:any = $("#additional_cover").is(":checked");
    if(valchecked == true){
      $(".additional_cover").css('display', 'block');
    }else{
      $(".additional_cover").css('display', 'none');
    }
  }

  sum_insured_Total(i:any){

    let premium:any = $('#premium_'+i).val();
    let premium_tax:any = $('#premium_tax_'+i).val();

    if(premium != '' && premium_tax != ''){

      premium_tax = parseInt(premium_tax) / 100;
      let premium_total:any = parseInt(premium) * parseFloat(premium_tax);

      let finaltotal:any = premium_total = parseInt(premium) + parseInt(premium_total);

      if(finaltotal != '' || finaltotal != null || finaltotal != undefined){
        $('#premium_total_'+i).val(finaltotal);
      }

    }

  }

  /***** Add Benfit step 5 Row *****/
  addbenefit(){
    const valchecked = $("#add_benefit").is(":checked");
    $('.add_benefit_row').html("");
    this.cnt_add_benefit = 1;
    if(valchecked == true){
      this.add_benefitRow();
      $("#add_benefitdiv").css('display', 'block');
      $("#showadd_benefit").css('display', 'block');
    }else{
      $("#add_benefitdiv").css('display', 'none');
      $("#showadd_benefit").css('display', 'none');
    }
  }

  add_benefitRow() {

    $("#add_benefitdiv").css('display', 'block');

    let html = `
    <div id="add_benefit_row`+ this.cnt_add_benefit + `" class="row mt-3">

      <div class="col-sm-4">
          <div class="form-group">
              <label class="bs-form-label" for="label_`+ this.cnt_add_benefit + `">Add Label </label>
    <input type="text" class="form-control" formControlName="label_`+ this.cnt_add_benefit + `" id="label_` + this.cnt_add_benefit + `" placeholder="Add Label" />
          </div>
      </div>

      <div class="col-sm-4">
          <div class="form-group">
              <label class="bs-form-label" for="limit_`+ this.cnt_add_benefit + `">Add Limit </label>
              <input type="text" class="form-control" formControlName="limit_`+ this.cnt_add_benefit + `" id="limit_` + this.cnt_add_benefit + `" maxlength="3" placeholder="Add Limit" />
          </div>
      </div>

      <div class="col-sm-4 form-group">
          <img src="../../../assets/img/common icons/trash.png" id="remove_benefit_row`+ this.cnt_add_benefit + `" role="button" class="img-fluid mt-3 pt-3" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.add_benefit_row').append(html);

    this.basicInfo.addControl("label_" + this.cnt_add_benefit, new FormControl(''));
    this.basicInfo.addControl("limit_" + this.cnt_add_benefit, new FormControl(''));

    var co_insurer = "label_" + this.cnt_add_benefit;
    var label_perc = "limit_" + this.cnt_add_benefit;

    this.add_benefit_arr[this.cnt_add_benefit] = { co_insurer: "", label_perc: "" };

    let flag = this;
    let cnt_add_benefitflag = flag.cnt_add_benefit;
    $("#remove_benefit_row" + this.cnt_add_benefit).on('click', function (e:any) {
      flag.removeaddBenefitRow(e, cnt_add_benefitflag);
    });

    $("#limit_" + this.cnt_add_benefit).on('keyup', function (e:any) {
      flag.isaddBenefit(e);
    });

    this.cnt_add_benefit++;

  }

  removeaddBenefitRow(e: any, cnt_add_benefitflag: any){
    var val = '#limit_' + cnt_add_benefitflag;
    let value2:any = $(val).val();

    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#add_benefit_row' + id;
    $(idstr).remove();

  }

  isaddBenefit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }

    return true;
  }

  // Step 5
  /***** Add SI Field *****/
  addSICustomField() {

    let html = `
    <div id="this_Custom_row_`+ this.cnt_custom + `" class="row">
      <div class="col-sm-3 form-group mt-3">
        <label class="bs-form-label">Sum Insured</label>
        <input type="text" class="form-control" id="sum_insured_custom_`+ this.cnt_custom + `" placeholder="Sum Insured" />
      </div>
      <div class="col-sm-3 form-group mt-3">
        <label class="bs-form-label">Premium</label>
        <input type="text" class="form-control" id="premium_custom_`+ this.cnt_custom + `" placeholder="Premium" />
      </div>
      <div class="col-sm-3 form-group mt-3">
        <label class="bs-form-label">Premium Tax</label>
        <input type="text" class="form-control" id="premium_tax_custom_`+ this.cnt_custom + `" placeholder="Premium Tax" />
      </div>
      <div class="col-sm-2 form-group mt-3">
        <label class="bs-form-label">Premium Total</label>
        <input type="text" class="form-control" id="premium_total_custom_`+ this.cnt_custom + `" placeholder="Premium Total" />
      </div>
      <div class="col-sm-1 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="custom_row_`+ this.cnt_custom + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newCustomField').append(html);

    this.SIPremiumInfo.addControl("sum_insured_custom_" + this.cnt_custom, new FormControl(''));
    this.SIPremiumInfo.addControl("premium_custom_" + this.cnt_custom, new FormControl(''));
    this.SIPremiumInfo.addControl("premium_tax_custom_" + this.cnt_custom, new FormControl(''));
    this.SIPremiumInfo.addControl("premium_total_custom_" + this.cnt_custom, new FormControl(''));

    var sum_insured = "sum_insured_custom_" + this.cnt_custom;
    var premium = "premium_custom_" + this.cnt_custom;
    var premium_tax = "premium_tax_custom_" + this.cnt_custom;
    var premium_total = "premium_total_custom_" + this.cnt_custom;

    this.CustomArr[this.cnt_custom] = { sum_insured: "", premium: "", premium_tax: "", premium_total: "" };

    let flag = this;
    $("#custom_row_" + this.cnt_custom).on('click', function (e) {
      flag.removeSICustomRow(e);
    });

    $("#premium_custom_" + this.cnt_custom).on('keyup', function (e) {
      flag.isnumberSICustom(e);
    });

    $("#premium_tax_custom_" + this.cnt_custom).on('keyup', function (e) {
      flag.isnumberSICustom(e);
    });

    $("#premium_custom_" + this.cnt_custom).on('keyup', function (e) {
      flag.sum_insured_Total_premium(e);
    });

    $("#premium_tax_custom_" + this.cnt_custom).on('keyup', function (e) {
      flag.sum_insured_Total_premium(e);
    });

    $("#premium_custom_" + this.cnt_custom).on('keydown', function (e) {
      flag.sum_insured_Total_premium(e);
    });

    $("#premium_tax_custom_" + this.cnt_custom).on('keydown', function (e) {
      flag.sum_insured_Total_premium(e);
    });

    this.cnt_custom++;

  }

  removeSICustomRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_Custom_row_' + id;
    $(idstr).remove();

  }

  isnumberSICustom(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  sum_insured_Total_premium(evt: any){

    var str = evt.target.id;
    var id = str.charAt(str.length -1 );
    let premium:any = $('#premium_custom_'+id).val();
    let premium_tax:any = $('#premium_tax_custom_'+id).val();

    if(premium != '' && premium_tax != ''){

      premium_tax = parseInt(premium_tax) / 100;
      let premium_total:any = parseInt(premium) * parseFloat(premium_tax);

      let finaltotal:any = premium_total = parseInt(premium) + parseInt(premium_total);

      if(finaltotal != '' || finaltotal != null || finaltotal != undefined){
        $('#premium_total_custom_'+id).val(finaltotal);
      }

    }

  }

  // Step 4
  /***** Add Broker Field *****/
  addSIbrokerField() {

    if(this.cnt_broker < 3){

      let html = `
      <div id="this_broker_row_`+ this.cnt_broker + `" class="row">
        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="b_role_designation_type_`+ this.cnt_broker + `">Role/Designation Type *</label>
            <select class="form-select" formControlName="b_role_designation_type_`+ this.cnt_broker + `" id="b_role_designation_type_`+ this.cnt_broker + `">
              <option value="" selected>Select Role/Designation Type</option>
            </select>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="b_emailID">Email ID *</label>
            <select class="form-select" id="b_emailID_`+ this.cnt_broker + `">
              <option value="" selected>Select Email ID</option>
            </select>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="b_contactname">Name *</label>
            <input type="text" class="form-control" formControlName="b_contactname_`+ this.cnt_broker + `" id="b_contactname_`+ this.cnt_broker + `" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="b_mobileno">Mobile No. *</label>
            <input type="text" class="form-control" formControlName="b_mobileno_`+ this.cnt_broker + `" id="b_mobileno_`+ this.cnt_broker + `" maxlength="10"/>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="b_level">Level *</label>
            <select class="form-select" formControlName="b_level_`+ this.cnt_broker + `" id="b_level_`+ this.cnt_broker + `">
              <option value="" selected>Select Level</option>
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
              <option value="3">Level 3</option>
            </select>
          </div>
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="broker_row_`+ this.cnt_broker + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newbrokerField').append(html);

      for(var i=0; i<this.contactroleArr.length; i++){
        var val = '#' + 'b_role_designation_type_'+ this.cnt_broker;
        $(val)
          .append($("<option></option>")
            .attr("value", this.contactroleArr[i]['user_type_id'])
            .text(this.contactroleArr[i]['tag_name']));
      }

      this.SIPremiumInfo.addControl("b_role_designation_type_" + this.cnt_broker, new FormControl(''));
      this.SIPremiumInfo.addControl("b_emailID_" + this.cnt_broker, new FormControl(''));
      this.SIPremiumInfo.addControl("b_contactname_" + this.cnt_broker, new FormControl(''));
      this.SIPremiumInfo.addControl("b_mobileno_" + this.cnt_broker, new FormControl(''));

      this.brokerArr_step4[this.cnt_broker] = { b_role_designation_type: "", b_contactname: "", b_emailID_tax: "", b_mobileno: "" };

      let flag = this;
      $("#broker_row_" + this.cnt_broker).on('click', function (e) {
        flag.removeSIbrokerRow(e);
      });

      $("#b_role_designation_type_" + this.cnt_broker).on('change', function (e) {

        var str = e.target.id;
        var id = str.charAt(str.length -1 );

        //clear
        var idclear = flag.cnt_broker - 1;
        var valclear = '#' + 'b_emailID_'+ idclear;
        $(valclear).empty();

        //First Value append
        var id_firstval = flag.cnt_broker - 1;
        var val_firstval = '#' + 'b_emailID_'+ id_firstval;
        $(val_firstval).append($("<option selected></option>").attr("value", '').text('Select Email ID'));

        // Load other option values
        var postdata2 = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "user_type_id":$('#b_role_designation_type_' + id).val(),
          "broker_id":localStorage.getItem('broker_id'),
          "policy_id": localStorage.getItem('master_policies_id'),
        };

        flag.clientCreationService.getDesignationType(postdata2)
          .subscribe((result) => {

            for(var i=0; i<result.data.length; i++){
              var id = flag.cnt_broker - 1;
              var val = '#' + 'b_emailID_'+ id;
              $(val).append($("<option></option>").attr("value", result.data[i]['user_id']).text(result.data[i]['email_address']));
            }

          }, (error) => {

        });

      });

      $("#b_emailID_" + this.cnt_broker).on('change', function (e) {

        var str = e.target.id;
        var id = str.charAt(str.length -1 );

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "user_type_id":$('#b_role_designation_type_' + id).val(),
          "broker_id":localStorage.getItem('broker_id'),
          "policy_id": localStorage.getItem('master_policies_id'),
        }
        flag.clientCreationService.getDesignationType(postdata)
          .subscribe((result) => {

            let valchecked:any = $("#b_emailID_" + id).val();

            for(var i=0; i < result.data.length; i++){
              if(result.data[i]['user_id'] ==  valchecked){
                $("#b_contactname_" + id).val(result.data[i]['name']);
                $("#b_mobileno_" + id).val(result.data[i]['mobile_no']);
              }else{

              }
            }

          }, (error) => {

        });

      });

      $("#b_mobileno_" + this.cnt_broker).on('keyup', function (e) {
        flag.isnumberSIbroker(e);
      });

      $("#b_emailID_" + this.cnt_broker).on('keyup', function (e) {
        flag.appendbrokerdetails(e);
      });

      this.cnt_broker++;

    }

  }

  removeSIbrokerRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_broker_row_' + id;
    $(idstr).remove();

    this.cnt_broker = parseInt(this.cnt_broker) - 1;
    console.log(this.cnt_broker);

  }

  isnumberSIbroker(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  appendbrokerdetails(evt: any){
    var str = evt.target.id;
    var id = str.charAt(str.length -1 );

    let valchecked:any = this.contactInfo.controls['b_emailID_'+id].value;
    for(var i=0; i < this.entityDetails.length; i++){
      if(this.entityDetails[i]['username'] ==  valchecked){
        this.contactInfo.controls['b_contactname_' + id].setValue(this.entityDetails[i]['name']);
        this.contactInfo.controls['b_mobileno_' + id].setValue(this.entityDetails[i]['mobile_no']);
      }else{
        this.contactInfo.controls['b_contactname_' + id].setValue('');
        this.contactInfo.controls['b_mobileno_' + id].setValue('');
      }
    }

  }

  // Step 4
  /***** Add Employer Field *****/
  addSIemployerField() {

    if(this.cnt_employer < 3){

      let html = `
      <div id="this_employer_row_`+ this.cnt_employer + `" class="row">
        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="e_role_designation_type_`+ this.cnt_employer + `">Role/Designation Type *</label>
            <select class="form-select" formControlName="e_role_designation_type_`+ this.cnt_employer + `" id="e_role_designation_type_`+ this.cnt_employer + `">
              <option value="" selected>Select Role/Designation Type</option>
            </select>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="e_emailID">Email ID *</label>
            <select class="form-select" id="e_emailID_`+ this.cnt_employer + `">
              <option value="" selected>Select Email ID</option>
            </select>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="e_contactname">Name *</label>
            <input type="text" class="form-control" formControlName="e_contactname_`+ this.cnt_employer + `" id="e_contactname_`+ this.cnt_employer + `" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="e_mobileno">Mobile No. *</label>
            <input type="text" class="form-control" formControlName="e_mobileno_`+ this.cnt_employer + `" id="e_mobileno_`+ this.cnt_employer + `" maxlength="10"/>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="e_level">Level *</label>
            <select class="form-select" formControlName="e_level_`+ this.cnt_employer + `" id="e_level_`+ this.cnt_employer + `">
              <option value="" selected>Select Level</option>
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
              <option value="3">Level 3</option>
            </select>
          </div>
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="employer_row_`+ this.cnt_employer + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newemployerField').append(html);

      for(var i=0; i<this.contactroleemployerArr.length; i++){
        var val = '#' + 'e_role_designation_type_'+ this.cnt_employer;
        $(val)
          .append($("<option></option>")
            .attr("value", this.contactroleemployerArr[i]['user_type_id'])
            .text(this.contactroleemployerArr[i]['tag_name']));
      }

      this.SIPremiumInfo.addControl("e_role_designation_type_" + this.cnt_employer, new FormControl(''));
      this.SIPremiumInfo.addControl("e_emailID_" + this.cnt_employer, new FormControl(''));
      this.SIPremiumInfo.addControl("e_contactname_" + this.cnt_employer, new FormControl(''));
      this.SIPremiumInfo.addControl("e_mobileno_" + this.cnt_employer, new FormControl(''));

      this.employer_step4Arr[this.cnt_employer] = { e_role_designation_type: "", e_contactname: "", e_emailID_tax: "", e_mobileno: "" };

      let flag = this;
      $("#employer_row_" + this.cnt_employer).on('click', function (e) {
        flag.removeSIemployerRow(e);
      });

      $("#e_role_designation_type_" + this.cnt_employer).on('change', function (e) {

        var str = e.target.id;
        var id = str.charAt(str.length -1 );

        //clear
        var idclear = flag.cnt_employer - 1;
        var valclear = '#' + 'e_emailID_'+ idclear;
        $(valclear).empty();

        //First Value append
        var id_firstval = flag.cnt_employer - 1;
        var val_firstval = '#' + 'e_emailID_'+ id_firstval;
        $(val_firstval).append($("<option selected></option>").attr("value", '').text('Select Email ID'));

        // Load other option values
        var postdata2 = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "user_type_id":$('#e_role_designation_type_' + id).val(),
          "broker_id":localStorage.getItem('broker_id'),
          "policy_id": localStorage.getItem('master_policies_id'),
        };

        flag.clientCreationService.getDesignationType_e(postdata2)
          .subscribe((result) => {

            for(var i=0; i<result.data.length; i++){
              var id = flag.cnt_employer - 1;
              var val = '#' + 'e_emailID_'+ id;
              $(val).append($("<option></option>").attr("value", result.data[i]['user_id']).text(result.data[i]['email_address']));
            }

          }, (error) => {

        });

      });

      $("#e_emailID_" + this.cnt_employer).on('change', function (e) {

        var str = e.target.id;
        var id = str.charAt(str.length -1 );

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "user_type_id":$('#e_role_designation_type_' + id).val(),
          "broker_id":localStorage.getItem('broker_id'),
          "policy_id": localStorage.getItem('master_policies_id'),
        }
        flag.clientCreationService.getDesignationType_e(postdata)
          .subscribe((result) => {

            let valchecked:any = $("#e_emailID_" + id).val();

            for(var i=0; i < result.data.length; i++){
              if(result.data[i]['user_id'] ==  valchecked){
                $("#e_contactname_" + id).val(result.data[i]['name']);
                $("#e_mobileno_" + id).val(result.data[i]['mobile_no']);
              }else{

              }
            }

          }, (error) => {

        });

      });

      $("#e_mobileno_" + this.cnt_employer).on('keyup', function (e) {
        flag.isnumberSIemployer(e);
      });

      this.cnt_employer++;

    }

  }

  removeSIemployerRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_employer_row_' + id;
    $(idstr).remove();

    this.cnt_employer = parseInt(this.cnt_employer) - 1;

  }

  isnumberSIemployer(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  // Step 4
  /***** Add TPA Field *****/
  addtpaField() {

    if(this.cnt_tpa < 3){

      let html = `
      <div id="this_tpa_row_`+ this.cnt_tpa + `" class="row">
        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="tpa_role_designation_type_`+ this.cnt_tpa + `">Role/Designation Type *</label>
            <select class="form-select" formControlName="tpa_role_designation_type_`+ this.cnt_tpa + `" id="tpa_role_designation_type_`+ this.cnt_tpa + `">
              <option value="" selected>Select Role/Designation Type</option>
            </select>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="tpa_emailID">Email ID *</label>
            <input type="text" class="form-control" formControlName="tpa_emailID_`+ this.cnt_tpa + `" id="tpa_emailID_`+ this.cnt_tpa + `" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="tpa_contactname">Name *</label>
            <input type="text" class="form-control" formControlName="tpa_contactname_`+ this.cnt_tpa + `" id="tpa_contactname_`+ this.cnt_tpa + `" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="tpa_mobileno">Mobile No. *</label>
            <input type="text" class="form-control" formControlName="tpa_mobileno_`+ this.cnt_tpa + `" id="tpa_mobileno_`+ this.cnt_tpa + `" maxlength="10" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="tpa_level">Level *</label>
            <select class="form-select" formControlName="tpa_level_`+ this.cnt_tpa + `" id="tpa_level_`+ this.cnt_tpa + `">
              <option value="" selected>Select Level</option>
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
              <option value="3">Level 3</option>
            </select>
          </div>
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="tpa_row_`+ this.cnt_tpa + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newtpaField').append(html);


      for(var i=0; i<this.TPA.length; i++){
        var val = '#' + 'tpa_role_designation_type_'+ this.cnt_tpa;
        $(val)
          .append($("<option></option>")
            .attr("value", this.TPA[i]['tpa_id'])
            .text(this.TPA[i]['name']));
      }

      for(var i=0; i<this.emailID.length; i++){
        var val = '#' + 'tpa_emailID_'+ this.cnt_tpa;
        $(val)
          .append($("<option></option>")
            .attr("value", this.emailID[i]['user_id'])
            .text(this.emailID[i]['username']));
      }

      this.SIPremiumInfo.addControl("tpa_role_designation_type_" + this.cnt_tpa, new FormControl(''));
      this.SIPremiumInfo.addControl("tpa_emailID_" + this.cnt_tpa, new FormControl(''));
      this.SIPremiumInfo.addControl("tpa_contactname_" + this.cnt_tpa, new FormControl(''));
      this.SIPremiumInfo.addControl("tpa_mobileno_" + this.cnt_tpa, new FormControl(''));

      var tpa_role_designation_type_ = "tpa_role_designation_type_" + this.cnt_tpa;
      var b_emailID = "tpa_emailID_" + this.cnt_tpa;
      var tpa_contactname_ = "tpa_contactname_" + this.cnt_tpa;
      var b_mobileno = "tpa_mobileno_" + this.cnt_tpa;

      this.tpa_step4Arr[this.cnt_tpa] = { tpa_role_designation_type_: "", tpa_contactname_: "", tpa_emailID_: "", tpa_mobileno_: "" };

      let flag = this;
      $("#tpa_row_" + this.cnt_tpa).on('click', function (e) {
        flag.removeSItpaRow(e);
      });

      $("#tpa_mobileno_" + this.cnt_tpa).on('keyup', function (e) {
        flag.isnumberSItpa(e);
      });

      $("#tpa_role_designation_type_" + this.cnt_tpa).on('change', function (e) {

        var str = e.target.id;
        var id = str.charAt(str.length -1 );

        var tpa_role_designation_type = "tpa_role_designation_type_" + id;
        var b_emailID = "tpa_emailID_" + id;
        var tpa_contactname_ = "tpa_contactname_" + id;
        var b_mobileno = "tpa_mobileno_" + id;

        let tpa_id:any = $('#tpa_role_designation_type_' + id).val();
        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "id":tpa_id
        };
        flag.clientCreationService.InfoSingle(postdata)
          .subscribe((result) => {

            flag.entityDetails_tpa = result.data[0];

            $('#tpa_emailID_' + id).val(flag.entityDetails_tpa.email_1);
            $('#tpa_contactname_' + id).val(flag.entityDetails_tpa.name);
            $('#tpa_mobileno_' + id).val(flag.entityDetails_tpa.contact_1);

          }, (error) => {
              // console.log(result);

              flag.entityDetails_tpa = [];
        });
      });

      flag.cnt_tpa++;

    }

  }

  removeSItpaRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_tpa_row_' + id;
    $(idstr).remove();

    this.cnt_tpa = parseInt(this.cnt_tpa) - 1;

  }

  isnumberSItpa(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  installments(e:any){

    if(e.target.value > '25'){
      $('#inception_premium_installment').val('');
    }else{

      if(e.target.value != ''){
        $('.InstallmentDiv').css('display','block');
      }else{
        $('.InstallmentDiv').css('display','none');
      }

    }

    this.installmentArr = [];

    for(var i=0; i<e.target.value; i++){
      this.installmentArr.push(i);
    }


  }

  b_designationType(){
    let valchecked:any = this.contactInfo.controls['b_role_designation_type'].value;
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "user_type_id":$('#b_role_designation_type').val(),
      "broker_id":localStorage.getItem('broker_id'),
      "policy_id": localStorage.getItem('master_policies_id'),
    }
    this.clientCreationService.getDesignationType(postdata)
      .subscribe((result) => {

        this.entityDetails = result.data;

        // console.log(this.entityDetails);

      }, (error) => {

        this.entityDetails = [];
          // console.log(result);
    });

  }

  b_emailID(){
    let valchecked:any = this.contactInfo.controls['b_emailID'].value;

    // var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // // console.log(regex.test(valchecked));

    // if(regex.test(valchecked) == false){
    //   $('#valid_b_emailID').css('display', 'block');
    // }else{
    //   $('#valid_b_emailID').css('display', 'none');
    // }

    for(var i=0; i < this.entityDetails.length; i++){
      if(this.entityDetails[i]['user_id'] ==  valchecked){
        // console.log(this.entityDetails[i]['user_id']);
        // console.log(valchecked);
        // console.log(this.entityDetails[i]['email_address']);
        // console.log(this.entityDetails[i]['mobile_no']);
        // $('#b_contactname').val(this.entityDetails[i]['email_address']);
        // $('#b_mobileno').val(this.entityDetails[i]['mobile_no']);
        this.contactInfo.controls['b_contactname'].setValue(this.entityDetails[i]['name']);
        this.contactInfo.controls['b_mobileno'].setValue(this.entityDetails[i]['mobile_no']);
      }else{
        // this.contactInfo.controls['b_contactname'].setValue('');
        // this.contactInfo.controls['b_mobileno'].setValue('');
      }
    }
  }

  e_designationType(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "user_type_id":$('#e_role_designation_type').val(),
      "broker_id":localStorage.getItem('broker_id'),
      "policy_id": localStorage.getItem('master_policies_id'),
    }
    this.clientCreationService.getDesignationType_e(postdata)
      .subscribe((result) => {

        this.entityDetails_e = result.data;

      }, (error) => {

        this.entityDetails_e = [];
          // console.log(result);
    });

  }

  e_emailID(){
    let valchecked:any = this.contactInfo.controls['e_emailID'].value;

    // var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // // console.log(regex.test(valchecked));

    // if(regex.test(valchecked) == false){
    //   $('#valid_e_emailID').css('display', 'block');
    // }else{
    //   $('#valid_e_emailID').css('display', 'none');
    // }

    for(var i=0; i < this.entityDetails_e.length; i++){
      if(this.entityDetails_e[i]['user_id'] ==  valchecked){
        this.contactInfo.controls['e_contactname'].setValue(this.entityDetails_e[i]['name']);
        this.contactInfo.controls['e_mobileno'].setValue(this.entityDetails_e[i]['mobile_no']);
      }else{
        // this.contactInfo.controls['e_contactname'].setValue('');
        // this.contactInfo.controls['e_mobileno'].setValue('');
      }
    }
  }

  tpa_emailID(){
    let tpa_id:any = this.contactInfo.controls['tpa_role_designation_type'].value;
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":tpa_id
    };
    this.clientCreationService.InfoSingle(postdata)
      .subscribe((result) => {

        this.entityDetails_tpa = result.data[0];

        this.contactInfo.controls['tpa_emailID'].setValue(this.entityDetails_tpa.email_1);
        this.contactInfo.controls['tpa_contactname'].setValue(this.entityDetails_tpa.name);
        this.contactInfo.controls['tpa_mobileno'].setValue(this.entityDetails_tpa.contact_1);

      }, (error) => {
          // console.log(result);

          this.entityDetails_tpa = [];
    });
  }

  Insurer_emailID(){
    let Insurer_id:any = this.contactInfo.controls['insurer_id_step4'].value;
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":Insurer_id
    };
    this.clientCreationService.InfoSingle_Insurer(postdata)
      .subscribe((result) => {

        this.entityDetails_Insurer = result.data[0];

        this.contactInfo.controls['Insurer_emailID'].setValue(this.entityDetails_Insurer.contact_email);
        this.contactInfo.controls['Insurer_contactname'].setValue(this.entityDetails_Insurer.name);
        this.contactInfo.controls['Insurer_mobileno'].setValue(this.entityDetails_Insurer.contact_number);

      }, (error) => {
          // console.log(result);

          this.entityDetails_Insurer = [];
    });
  }

  checkAllowed_MembersDIV(){
    let value:any = this.additionalInfo.controls['allowed_members'].value;
    if(value == "Selected Members"){
      $('.Allowed_MembersDIV').css('display', 'block');
    }else{
      $('.Allowed_MembersDIV').css('display', 'none');
    }
  }

  checkNominee(){
    let value:any = this.additionalInfo.controls['nomination_applicable'].value;
    if(value == "Yes"){
      $('.nomineeDIV').css('display', 'block');

      let value:any = this.additionalInfo.controls['allowed_members'].value;
      if(value == "Selected Members"){
        $('.Allowed_MembersDIV').css('display', 'block');
      }else{
        $('.Allowed_MembersDIV').css('display', 'none');
      }

    }else{
      $('.nomineeDIV').css('display', 'none');
    }
  }

  monthlyPeriod(){

    let value:any = this.SIPremiumInfo.controls['monthly_equitible_period'].value;
    if(value != 0){
      $('.monthlyPeriod').css('display', 'block');
      this.MonthlyEquitible.push(value);
    }
    // console.log(this.MonthlyEquitible);

  }

  additionalCover(){
    let value:any = this.SIPremiumInfo.controls['additional_benefit_label'].value;
    let limit:any = this.SIPremiumInfo.controls['additional_benefit_limit'].value;

    console.log(value);
    if(value != 0 && value != '' && value != null && limit != 0 && limit != '' && limit != null){
      $('.additionalCover').css('display', 'block');
      this.AdditionalCoverArray.push(
        {"label": value, "limit": limit},
      );
    }

    $('#additional_benefit_label').val('');
    $('#additional_benefit_limit').val('');
    // console.log(this.AdditionalCoverArray);

  }

  no_of_times_salary(){

    let value:any = this.SIPremiumInfo.controls['no_of_times_salary'].value;
    let lowest_SI_limit:any = this.SIPremiumInfo.controls['lowest_SI_limit'].value;
    let highest_SI_limit:any = this.SIPremiumInfo.controls['highest_SI_limit'].value;

    if(value != 0 || value != '' || value != null){
      $('.no_of_times_salary').css('display', 'block');
      this.NoOfTimesSalary.push({
        "no_of_times_salary" : value,
        "lowest_SI_limit": lowest_SI_limit,
        "highest_SI_limit": highest_SI_limit,
      });
    }
    // console.log(this.NoOfTimesSalary);

  }

  popmonthlyPeriod(item:any){
    var index = this.MonthlyEquitible.indexOf(item);
    if (index !== -1) {
      this.MonthlyEquitible.splice(index, 1);
    }
    // console.log(this.MonthlyEquitible);
  }

  popNoOfTimesSalary(item:any){
    // console.log(item);

    let index = this.NoOfTimesSalary.findIndex((row: { no_of_times_salary: any; }) => row.no_of_times_salary === item);
    console.log(index);
    if (index !== -1) {
      this.NoOfTimesSalary.splice(index, 1);
    }
    // console.log(this.NoOfTimesSalary);
  }

  popadditionalCoverArray(item:any){
    let index = this.AdditionalCoverArray.findIndex((row: { label: any; }) => row.label === item);
    // console.log(index);
    if (index !== -1) {
      this.AdditionalCoverArray.splice(index, 1);
    }
    // console.log(this.AdditionalCoverArray);
  }

  // Step 3
  showpremiumBasis(){

    let SI_Basis:any = $('#SI_Basis').val();
    let premium_Basis:any = $('#premium_Basis').val();

    // console.log(SI_Basis);
    // console.log(premium_Basis);

    this.emmployeefile_id = premium_Basis;

    if(SI_Basis == '1' && premium_Basis == '2' || SI_Basis == '1' && premium_Basis == '8' || SI_Basis == '1' && premium_Basis == '9' || SI_Basis == '1' && premium_Basis == '13' || SI_Basis == '1' && premium_Basis == '15' || SI_Basis == '3' && premium_Basis == '1'){
      $('.employee_age_div').css('display', 'block');
    }else{
      $('.employee_age_div').css('display', 'none');
    }

    if(SI_Basis == '3' && premium_Basis == '4' || SI_Basis == '3' && premium_Basis == '2'){
      $('.grade_div').css('display', 'flex');
    }else{
      $('.grade_div').css('display', 'none');
    }

    if(SI_Basis == '5' && premium_Basis == '5'){
      $('.no_times_salary_div').css('display', 'flex');
    }else{
      $('.no_times_salary_div').css('display', 'none');
    }

    if(SI_Basis == '17' && premium_Basis == '19'){
      $('.unnamedDiv').css('display', 'block');
    }else{
      $('.unnamedDiv').css('display', 'none');
    }

    if(SI_Basis == '17' && premium_Basis == '20'){
      $('.unnamed_unnamedDiv').css('display', 'block');
    }else{
      $('.unnamed_unnamedDiv').css('display', 'none');
    }

    if(SI_Basis == '3' && premium_Basis == '1' || SI_Basis == '1' && premium_Basis == '1'){
      $(".Sum_Insured_BasisDiv").css('display', 'block');
    }else{
      $(".Sum_Insured_BasisDiv").css('display', 'none');
    }

  }

  employee_file_change(event: any) {
    if (event.target.files.length > 0) {

      this.employee_file = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_emplyee_age_file").empty();
      $('#custom-file-upload_emplyee_age_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_emplyee_age_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_emplyee_age_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_emplyee_age_file').empty();
      $('#small_emplyee_age_file').html('');
      $('#small_emplyee_age_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
    }
  }

  grade_SI_file_change(event: any) {
    if (event.target.files.length > 0) {

      this.grade_SI = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_grade_SI_file").empty();
      $('#custom-file-upload_grade_SI_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_grade_SI_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_grade_SI_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_grade_SI_file').empty();
      $('#small_grade_SI_file').html('');
      $('#small_grade_SI_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
    }
  }

  permily_age_file_change(event: any) {
    if (event.target.files.length > 0) {

      this.permily_age = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_permily_age_file").empty();
      $('#custom-file-upload_permily_age_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_permily_age_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_permily_age_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_permily_age_file').empty();
      $('#small_permily_age_file').html('');
      $('#small_permily_age_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
    }
  }

  grade_SP_file_change(event: any) {
    if (event.target.files.length > 0) {

      this.grade_SP = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_grade_SP_file").empty();
      $('#custom-file-upload_grade_SP_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_grade_SP_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_grade_SP_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_grade_SP_file').empty();
      $('#small_grade_SP_file').html('');
      $('#small_grade_SP_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
    }
  }

  unnamed_file_change(event: any) {
    if (event.target.files.length > 0) {

      this.unnamed = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_unnamed_file").empty();
      $('#custom-file-upload_unnamed_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_unnamed_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_unnamed_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_unnamed_file').empty();
      $('#small_unnamed_file').html('');
      $('#small_unnamed_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
    }
  }

  ondownload_employee(){
    let SI_Basis:any = $('#SI_Basis').val();
    let premium_Basis:any = $('#premium_Basis').val();

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if(SI_Basis == '1' && premium_Basis == '2'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/flat_employee_age_wise_si_premium_sample.xlsx');
      link.setAttribute('download', `flat_employee_age_wise_si_premium_sample.xls`);
    }
    if(SI_Basis == '1' && premium_Basis == '8'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/flat_member_age_wise_si_premium_sample.xlsx');
      link.setAttribute('download', `flat_member_age_wise_si_premium_sample.xls`);
    }
    if(SI_Basis == '1' && premium_Basis == '9'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/flat_member_wise_si_premium_sample.xlsx');
      link.setAttribute('download', `flat_member_wise_si_premium_sample.xls`);
    }
    if(SI_Basis == '1' && premium_Basis == '13'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/flat_family_construct_wise_si_premium_sample.xlsx');
      link.setAttribute('download', `flat_family_construct_wise_si_premium_sample.xls`);
    }
    if(SI_Basis == '1' && premium_Basis == '15'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/flat_new_grade_with_family_construct_wise_si_premium_sample.xlsx');
      link.setAttribute('download', `flat_new_grade_with_family_construct_wise_si_premium_sample.xls`);
    }
    if(SI_Basis == '3' && premium_Basis == '2'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/employee_age_wise_premium_8.xlsx');
      link.setAttribute('download', `employee_age_wise_premium_8.xls`);
    }
    if(SI_Basis == '3' && premium_Basis == '4'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/grade_wise_si_premium_rater_10.xlsx');
      link.setAttribute('download', `grade_wise_si_premium_rater_10.xls`);
    }
    if(SI_Basis == '5' && premium_Basis == '5'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/permilli_age_wise_si_rater_11.xlsx');
      link.setAttribute('download', `permilli_age_wise_si_rater_11.xls`);
    }
    if(SI_Basis == '3' && premium_Basis == '1'){
      link.setAttribute('href', '../../assets/Member Data Sample Files/grade_flat_file.xlsx');
      link.setAttribute('download', `Grade_flat_file.xls`);
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  ondownload_grade_SI(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    link.setAttribute('href', '../../assets/Member Data Sample Files/grade_wise_si_6.xlsx');
    link.setAttribute('download', `grade_wise_si_6.xls`);

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  ondownload_grade_SP(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    link.setAttribute('href', '../../assets/Member Data Sample Files/grade_wise_si_premium_rater_10.xlsx');
    link.setAttribute('download', `grade_wise_si_premium_rater_10.xls`);

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  ondownload_unnamed(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    link.setAttribute('href', '../../assets/Member Data Sample Files/unnamed_employee_count_sample.xlsx');
    link.setAttribute('download', `unnamed_employee_count_sample.xls`);

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  checktax_employee(val:any){
    if(val == 0){
      $('.employee_tax_age_div').css('display', 'none');
    }else{
      $('.employee_tax_age_div').css('display', 'block');
    }
  }

  checktax_employee_step4(val:any){
    if(val == 0){
      $('.employee_tax_age_div_4').css('display', 'none');
    }else{
      $('.employee_tax_age_div_4').css('display', 'block');
    }
  }

  checkmidterm_enrollement(){

    let val:any = $('#midterm_enrollement').val();

    if(val == '26'){
      $('.Divenrollment_allowed_days_mid_term').css('display', 'none');
    }else{
      $('.Divenrollment_allowed_days_mid_term').css('display', 'block');
    }
  }

  checkpolicy_sub_type_id(){
    let val:any = $('#policy_sub_type_id').val();
    if(val == 2){
      $('.checkTPA').css('display', 'none');
      $('.TPADiv').css('display', 'none');
      $('.InsurerDiv').css('display', 'block');
    }else{
      $('.checkTPA').css('display', 'block');
      $('.TPADiv').css('display', 'block');
      $('.InsurerDiv').css('display', 'none');
    }
    if(val == 1){
      $('.parantpolicyDiv').css('display', 'block');
    }else{
      $('.parantpolicyDiv').css('display', 'none');
    }
  }

  /***** Add Insurer Field *****/
  addInsurerField() {

    if(this.cnt_Insurer < 3){

      let html = `
      <div id="this_Insurer_row_`+ this.cnt_Insurer + `" class="row">
        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="step4_insurer_id_`+ this.cnt_Insurer + `">Insurer *</label>
            <select class="form-select" formControlName="step4_insurer_id_`+ this.cnt_Insurer + `" id="step4_insurer_id_`+ this.cnt_Insurer + `">
              <option value="" selected>Select Insurer</option>
            </select>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_emailID">Email ID *</label>
            <input type="text" class="form-control" formControlName="Insurer_emailID_`+ this.cnt_Insurer + `" id="Insurer_emailID_`+ this.cnt_Insurer + `" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_contactname">Name *</label>
            <input type="text" class="form-control" formControlName="Insurer_contactname_`+ this.cnt_Insurer + `" id="Insurer_contactname_`+ this.cnt_Insurer + `" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_mobileno">Mobile No. *</label>
            <input type="text" class="form-control" formControlName="Insurer_mobileno_`+ this.cnt_Insurer + `" id="Insurer_mobileno_`+ this.cnt_Insurer + `" maxlength="10" />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_level">Level *</label>
            <select class="form-select" formControlName="Insurer_level_`+ this.cnt_Insurer + `" id="Insurer_level_`+ this.cnt_Insurer + `">
              <option value="" selected>Select Level</option>
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
              <option value="3">Level 3</option>
            </select>
          </div>
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="Insurer_row_`+ this.cnt_Insurer + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newInsurerField').append(html);


      for(var i=0; i<this.Insurer.length; i++){
        var val = '#' + 'step4_insurer_id_'+ this.cnt_Insurer;
        $(val)
          .append($("<option></option>")
            .attr("value", this.Insurer[i]['insure_comp_id'])
            .text(this.Insurer[i]['name']));
      }

      for(var i=0; i<this.emailID.length; i++){
        var val = '#' + 'Insurer_emailID_'+ this.cnt_Insurer;
        $(val)
          .append($("<option></option>")
            .attr("value", this.emailID[i]['user_id'])
            .text(this.emailID[i]['username']));
      }

      this.SIPremiumInfo.addControl("step4_insurer_id_" + this.cnt_Insurer, new FormControl(''));
      this.SIPremiumInfo.addControl("Insurer_emailID_" + this.cnt_Insurer, new FormControl(''));
      this.SIPremiumInfo.addControl("Insurer_contactname_" + this.cnt_Insurer, new FormControl(''));
      this.SIPremiumInfo.addControl("Insurer_mobileno_" + this.cnt_Insurer, new FormControl(''));

      var step4_insurer_id_ = "step4_insurer_id_" + this.cnt_Insurer;
      var b_emailID = "Insurer_emailID_" + this.cnt_Insurer;
      var Insurer_contactname_ = "Insurer_contactname_" + this.cnt_Insurer;
      var b_mobileno = "Insurer_mobileno_" + this.cnt_Insurer;

      this.Insurer_step4Arr[this.cnt_Insurer] = { step4_insurer_id_: "", Insurer_contactname_: "", Insurer_emailID_: "", Insurer_mobileno_: "" };

      let flag = this;
      $("#Insurer_row_" + this.cnt_Insurer).on('click', function (e) {
        flag.removeSIInsurerRow(e);
      });

      $("#Insurer_mobileno_" + this.cnt_Insurer).on('keyup', function (e) {
        flag.isnumberSIInsurer(e);
      });

      $("#step4_insurer_id_" + this.cnt_Insurer).on('change', function (e) {

        var str = e.target.id;
        var id = str.charAt(str.length -1 );

        var step4_insurer_id = "step4_insurer_id_" + id;
        var b_emailID = "Insurer_emailID_" + id;
        var Insurer_contactname_ = "Insurer_contactname_" + id;
        var b_mobileno = "Insurer_mobileno_" + id;

        let Insurer_id:any = $('#step4_insurer_id_' + id).val();
        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "id":Insurer_id
        };
        flag.clientCreationService.InfoSingle_Insurer(postdata)
          .subscribe((result) => {

            flag.entityDetails_Insurer = result.data[0];

            $('#Insurer_emailID_' + id).val(flag.entityDetails_Insurer.contact_email);
            $('#Insurer_contactname_' + id).val(flag.entityDetails_Insurer.name);
            $('#Insurer_mobileno_' + id).val(flag.entityDetails_Insurer.contact_number);

          }, (error) => {
              // console.log(result);

              flag.entityDetails_Insurer = [];
        });
      });

      flag.cnt_Insurer++;

    }

  }

  removeSIInsurerRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_Insurer_row_' + id;
    $(idstr).remove();

    this.cnt_Insurer = parseInt(this.cnt_Insurer) - 1;

  }

  isnumberSIInsurer(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  // Step 7
  showPopup(){

    this.PopupBenefits = "block";

  }

  getPolicyFeatures(){
    this.clientCreationService.getPolicyFeatures()
      .subscribe((result) => {

        this.PolicyFeatures = result.data;

      }, (error) => {
          // console.log(result);
        this.PolicyFeatures = [];
    });
  }

  /***** Add Field *****/
  addBenefitData(){

    let html = `
    <div id="this_Data_benefit_row_`+this.cnt_BenefitData+`" class="row mt-3">

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="feature_name_`+this.cnt_BenefitData+`">Feature Name </label>
          <input type="text" class="form-control" formControlName="feature_name_`+this.cnt_BenefitData+`" id="feature_name_`+this.cnt_BenefitData+`" placeholder="Feature Name" />
      </div>

      <div class="col-sm-6 form-group mt-3">
          <label class="bs-form-label" for="feature_description_`+this.cnt_BenefitData+`">Feature Description </label>
          <input type="text" class="form-control" formControlName="feature_description_`+this.cnt_BenefitData+`" id="feature_description_`+this.cnt_BenefitData+`" placeholder="Enter Content Here..." />
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="add_on_SI_`+this.cnt_BenefitData+`">Add On SI</label>
          <input type="text" class="form-control" formControlName="add_on_SI_`+this.cnt_BenefitData+`" id="add_on_SI_`+this.cnt_BenefitData+`" placeholder="Add On SI" value="0" />
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="cover_by_`+this.cnt_BenefitData+`">Cover By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+this.cnt_BenefitData+`" value="by_value" name="cover_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+this.cnt_BenefitData+`" value="by_SI" name="cover_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By SI %</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="cover_type_`+this.cnt_BenefitData+`">Cover Type</label>
          <select class="form-select" formControlName="cover_type_`+this.cnt_allowed+`" id="cover_type_`+this.cnt_allowed+`">
                <option value="" selected>Select Cover Type</option>
          </select>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="sum_insured_cap_`+this.cnt_BenefitData+`">Sum Insured Cap</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+this.cnt_BenefitData+`" value="Yes" name="sum_insured_cap_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+this.cnt_BenefitData+`" value="No" name="sum_insured_cap_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="premium_by_`+this.cnt_BenefitData+`">Premium By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+this.cnt_BenefitData+`" value="by_value" name="premium_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+this.cnt_BenefitData+`" value="by_premium" name="premium_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Premium %</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_value_`+this.cnt_BenefitData+`">Premium Value</label>
          <input type="text" class="form-control" formControlName="premium_value_`+this.cnt_BenefitData+`" id="premium_value_`+this.cnt_BenefitData+`" placeholder="Premium Value" value="0" />
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_type_`+this.cnt_BenefitData+`">Premium Type</label>
          <select class="form-select" formControlName="premium_type_`+this.cnt_allowed+`" id="premium_type_`+this.cnt_allowed+`">
                <option value="" selected>Select Premium Type</option>
          </select>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="visible_from_SI_value_`+this.cnt_BenefitData+`">Visible From SI Value</label>
          <input type="text" class="form-control" formControlName="visible_from_SI_value_`+this.cnt_BenefitData+`" id="visible_from_SI_value_`+this.cnt_BenefitData+`" placeholder="Visible From SI Value" value="0" />
      </div>

      <div class="col-sm-2 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="Data_benefit_row_`+this.cnt_BenefitData+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
      <div class="col-sm-12"><hr></div>
    </div>
    `;

    $('.newbenefitData').append(html);

    // for(var i=0; i<this.Membertype.length; i++){
    //   var val = '#' + 'cover_type_'+ this.cnt;
    //   $(val)
    //     .append($("<option></option>")
    //       .attr("value", this.Membertype[i]['id'])
    //       .text(this.Membertype[i]['name']));
    // }

    // this.familyInfo.addControl("feature_name_"+this.cnt_BenefitData, new FormControl(''));
    // this.familyInfo.addControl("feature_description_"+this.cnt_BenefitData, new FormControl(''));
    // this.familyInfo.addControl("add_on_SI_"+this.cnt_BenefitData, new FormControl(''));

    // this.BenefitDataArr[this.cnt_BenefitData] = {member_type: "", no_age: "", min_age: "", max_age: ""};

    let flag = this;
    $("#Data_benefit_row_"+this.cnt_BenefitData).on('click',function(e) {
      flag.removeRow_Benefit(e);
    });

    $("#add_on_SI_"+this.cnt_BenefitData).on('keyup',function(e) {
      flag.isnumberBenefit_benefit(e);
    });

    $("#premium_value_"+this.cnt_BenefitData).on('keyup',function(e) {
      flag.isnumberBenefit_benefit(e);
    });

    $("#visible_from_SI_value_"+this.cnt_BenefitData).on('keyup',function(e) {
      flag.isnumberBenefit_benefit(e);
    });

    this.cnt_BenefitData++;

  }

  removeRow_Benefit(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_Data_benefit_row_' + id;
    $(idstr).remove();

  }

  isnumberBenefit_benefit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  checkpolicy_number(e:any){

    let val:any = e.target.value;
    this.errrpolicyno = val;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_number":val,
    }
    this.clientCreationService.checkpolicyNumber(postdata)
      .subscribe((result) => {

        if(result.data == '1'){
          $('#policy_number').val('');
          $('#policy_number_duplicate').css('display','block');
        }else{
          $('#policy_number_duplicate').css('display','none');
        }

      }, (error) => {

          // console.log(result);
    });

  }

  getcontactrole(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "role_id":4,
    }
    this.clientCreationService.getcontactrole(postdata)
      .subscribe((result) => {

        this.contactroleArr = result.data;
        console.log(result.data);

      }, (error) => {

        this.contactroleArr = [];

        // console.log(result);
    });
  }

  getcontactroleemployer(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "role_id":3,
    }
    this.clientCreationService.getcontactrole(postdata)
      .subscribe((result) => {

        this.contactroleemployerArr = result.data;
        console.log(result.data);

      }, (error) => {

        this.contactroleemployerArr = [];

          // console.log(result);
    });
  }

  getcontactlistbyusertype(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "user_type_id":localStorage.getItem('user_type_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "employer_id":localStorage.getItem('employer_id'),
    }
    this.clientCreationService.getcontactrole(postdata)
      .subscribe((result) => {

        this.contactroleemployerArr = result.data;
        console.log(result.data);

      }, (error) => {

        this.contactroleemployerArr = [];

          // console.log(result);
    });
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  calculateCoverageDays() {
    const startDateValue = this.basicInfo.value.start_date;
    const endDateValue = this.basicInfo.value.end_date;

    // Check if both start date and end date are selected
    if(startDateValue && endDateValue) {
        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);

        // Check if the dates are valid
        if(!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            const differenceInTime = endDate.getTime() - startDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
           this.SIPremiumInfo.patchValue({gpa_coverage_days: differenceInDays });
           this.coverageDays = differenceInDays;
        } else {
            // Handle invalid dates
            console.error("Invalid start or end date");
        }
    }
}

calPremium(){

  let totalSI:any = $('#total_SI_unnamed').val();
  let rate_per_milli:any = $('#per_milliRate').val();
  let coverageDays:any = $('#gpa_coverage_days').val();
  let gst:any = $('#gst_unnamed').val();
  let totalPremium:any
  let singleDay:any
  let prorata_premium:any
  let gst_amount:any
  let endo_gross_premium:any

  if(totalSI != '' && rate_per_milli != ''){
    totalPremium = totalSI*rate_per_milli/1000;
    console.log(totalSI);
    console.log(rate_per_milli);

    if(!isNaN(totalPremium)){
      $('#total_premium_unnamed').val(totalPremium.toFixed(2))
    }
  }

  if(prorata_premium != ''){
    singleDay = totalPremium/coverageDays;
    prorata_premium = singleDay * coverageDays

    if(!isNaN(prorata_premium)){
    $('#prorata_premium_unnamed').val(prorata_premium.toFixed(2))
    }
  }

  if(gst != ''){
    gst_amount = prorata_premium * gst/100

    if(!isNaN(gst_amount)){
    $('#gst_amount_unnamed').val(gst_amount.toFixed(2))
    }
  }

  if(endo_gross_premium != ''){
    endo_gross_premium = prorata_premium + gst_amount

    if(!isNaN(endo_gross_premium)){
    $('#total_premium_unnamedGST').val(endo_gross_premium.toFixed(2))
    }
  }


}

calSI(){
  let a:any = $('#gpa_individual_cover').val();
  let b:any = $('#total_employee_unnamed').val();
  if(a != '' && b != ''){
    let d:any = a*b;
    $('#total_SI_unnamed').val(d);
    this.calPremium();
  }
}

}
