import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ServiceEmployee } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-employeruser-dashboard',
  templateUrl: './employeruser-dashboard.component.html',
  styleUrls: ['./employeruser-dashboard.component.css']
})
export class EmployeruserDashboardComponent implements OnInit {

  json:any = [{
    title: "My Family",
    description: "Enroll Members",
    imageURL: "Group 3.png",
    linkURL: "",    
  },
  {
    title: "My Benefits",
    description: "Enroll Members",
    imageURL: "benefit.png",
    linkURL: "",
  },
  {
    title: "My Wellness",
    description: "Enroll Members",
    imageURL: "wellness.png",
    linkURL: "",
  },
  {
    title: "My Health",
    description: "Enroll Members",
    imageURL: "call_center.png",
    linkURL: "",
  },
  {
    title: "My Service",
    description: "Enroll Members",
    imageURL: "call_center.png",
    linkURL: "",
  },
  {
    title: "My Center",
    description: "Enroll Members",
    imageURL: "call_center.png",
    linkURL: "",
  }];

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');
  
  constructor(private fb: FormBuilder, private dataservice: ServiceEmployee, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){
    this.getInfo();    
  }

  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    }
    
    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data.dashboard;                  

        }, (error) => {          
          
          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

          this.InfoArray = [];
          
      });
  }

  navigateto(id:any){    
    localStorage.setItem('dashboard_id', id);
    if(id == 1){
      // this.route.navigate(['/employeruser-dashboard/family-contruct']);
      this.route.navigate(['/employeruser-dashboard/family-enrollment']);
    }
    if(id == 2){
      // this.route.navigate(['/employeruser-dashboard/employee_benefit']);
    }
  }

}
