import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }    

  // getInfo(data:any): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
  //   };
  //   return this.http.post(this.AUTH_API + 'employeedashboard/getdashboard', 
  //     data,
  //     httpOptions);
  // }  

  getpolicy(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'commonclaimpolicy-list', 
      data,
      httpOptions);
  }  

  getdashboard(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'commonclaimpolicy-dashboard', 
      data,
      httpOptions);
  }  

  getSummary(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'commonclaimpolicy/summary/' + data.id, 
      data,
      httpOptions);
  }

  AddInfo(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'opsmanager/endorsementstore', 
      data,
      httpOptions);
  }

  getInfodropdown(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'clientinfobyid-list', 
      data,
      httpOptions);
  }

  getclientwisepolicy_list(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'clientwisepolicy-list', 
      data,
      httpOptions);
  }

  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'opsmanager/endorsement-list',
      data,
      httpOptions);
  }

  getpolicytype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
    , httpOptions);
  }
  
  onSubmitFirst(data: any, token: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("policy_type",data.policy_type);
    filedata.append("document_file",data.document_file);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + "opsmanager/endorsementupload",
      filedata
    , httpOptions);  
  }

  download_bulkFile(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'opsmanager/createndoexcel',
      data
    , httpOptions);
  }

  download(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ token})
    };
    return this.http.post(this.AUTH_API + 'endorsement-download',
      data
    , httpOptions);
  }
}