import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-ops-cd-summary',
  templateUrl: './ops-cd-summary.component.html',
  styleUrls: ['./ops-cd-summary.component.css']
})
export class OpsCdSummaryComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  InfoPolicy:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: true, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  readaccess   : any = 1;
  writeaccess  : any = 1;
  deleteaccess :  any = 1;
  modulename = 'opsmanager_cd_summary';

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.getpolicy();

  }

  getpolicy(){
    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "employer_id":localStorage.getItem('employer_id'),
    }
    this.dataservice.getpolicy(postdata)
        .subscribe((result: any) => {

          this.InfoPolicy = result.data;
          // this.dtTrigger.next();
          // this.dtOptions = {
          //   destroy: true,
          //   scrollX: true,
          //   columnDefs: [
          //     { orderable: true, className: 'reorder', targets: 0 },
          //     { orderable: false, targets: '_all' }
          //   ]
          // };
          this.dtTrigger.next();

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

          this.InfoPolicy = [];

      });
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ondownload_uploaded(fileUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  
}
