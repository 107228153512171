<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <!-- <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Endorsement </span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3> -->

            <h3 class="bs-title" *ngIf="addflag == 0">Endorsement Updation
                <!-- <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button> -->
            </h3>

            <hr class="mb-2">

            <!-- Filters -->
            <div class="row"  *ngIf="addflag == 0">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_name">Client Name</label>
                        <select class="form-select" id="client_name" (change)="getInfo($event, 'custom')">
                            <option value="" selected>Select Client Name</option>
                            <!-- <option value="{{clientname}}">{{clientname}}</option> -->
                            <option *ngFor="let res of ClientId" [value]="res.id">{{ res.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-8">
                    <button type="button" class="btn btn-primary" [disabled]="InfoArray.length == 0"  (click)="downloadCSV()" role="button" style="float:right">Download Excel</button>
                </div>
            </div>

            <!-- Listing -->
            <div class=" mt-3" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Endorsement No.</th>
                        <!-- <th>Policy No.</th> -->
                        <th>Policy Name</th>
                        <th>Count of Members</th>
                        <th>Endorsement Month Year</th>
                        <th>Insurer Endorsement No.</th>
                        <th>Status</th>
                        <th>Uploaded At</th>
                        <th>Endorsement Copy</th>
                        <th>Endorsement Upload</th>
                        <!-- <th>No. of Members </th> -->
                        <!-- <th>Status </th> -->
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['auto_endorsement_no']}}</td>
                        <!-- <td>{{group['policy_number']}}</td> -->
                        <td>{{group['policy_name']}}</td>
                        <td>{{group['member_count']}}</td>
                        <td>{{group['endorsement_date']}}</td>
                        <td>{{group['insurer_endo_number']}}</td>
                        <td>{{group['current_status']}}</td>
                        <td>{{group['added_date']}}</td>
                        <td *ngIf="group['broker_endo_copy_file']==='' && group['broker_endo_copy_file']===null"><span>NA</span></td>
                        <td  style="width: 15%" align="center">
                            <img *ngIf="group['broker_endo_copy_file']!=='' && group['broker_endo_copy_file']!== null" src="../../../assets/img/common icons/downloaddoc.png" id="download" (click)="ondownload_uploaded(group['broker_endo_copy_file'], group['endocopy'])" class="ml-4" role="button" title="Download" />
                        </td>

                        <td style="width: 15%" align="center"><label title="Upload" class="edit" (click)="uploadEndo(group['id'])">Upload</label> </td>

                        <td *ngIf="group['current_status_id'] === 3" style="width: 15%" align="center">
                            <label id="edituser"  (click)="viewpolicy(group)" class="edit" title="Edit">View</label>
                        </td>
                        <td *ngIf="group['current_status_id'] === 2" style="width: 15%" align="center">
                            <label id="edituser"  (click)="viewpolicy(group)" class="edit" title="Edit">View</label>
                        </td>
                        <td *ngIf="group['current_status_id'] === 0" style="width: 15%" align="center">
                            <label id="edituser"  (click)="editpolicy(group)" class="edit" title="Edit">Update</label>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Edit Form -->
            <div class="modal"  role="dialog" [ngStyle]="{'display':displayStyleEdit}">
                <div class="modal-dialog" role="document" style="max-width: 800px;margin-top: -50px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Update Endorsement Form</h4>
                        </div>
                        <form [formGroup]="EditInfo">
                            <div class="modal-body">
                                <div class="row">
                                    <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="client_id">Client Name </label>
                                            <input type="text" class="form-control" formControlName="client_id" id="client_id" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="policy_number">Policy No.</label>
                                            <input type="text" class="form-control" formControlName="policy_number" id="policy_number" readonly />
                                        </div>
                                    </div>


                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="endo_date">Endorsement Date</label>
                                            <input type="text" class="form-control" formControlName="endo_date" id="endo_date" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="coverageDays">Coverage Days</label>
                                            <input type="text" class="form-control" formControlName="coverageDays" id="coverageDays" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="individualCoverAmount">Individual Cover Amount (Lacs)</label>
                                            <input type="text" class="form-control" formControlName="individualCoverAmount" id="individualCoverAmount" placeholder="Individual Cover Amount (Lacs)" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="new_member_count">Count of Members</label>
                                            <input type="text" class="form-control" formControlName="new_member_count" id="new_member_count" placeholder="Count of Members" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="totalSI">Total Sum Insured </label>
                                            <input type="text" class="form-control" formControlName="totalSI" id="totalSI" placeholder="Total Sum Insured " readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="permili_rate">Per Milli Rate</label>
                                            <input type="text" class="form-control"
                                                formControlName="permili_rate" id="permili_rate"
                                                placeholder="Per Milli Rate" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="totalPremium"> Net Premium</label>
                                            <input type="text" class="form-control"
                                                formControlName="totalPremium" id="totalPremium"
                                                placeholder="Total Premium" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="prorataPremium"> Prorata Premium</label>
                                            <input type="text" class="form-control"
                                                formControlName="prorataPremium" id="prorataPremium"
                                                placeholder="Prorata Premium" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="gst">GST %</label>
                                            <input type="text" class="form-control" formControlName="gst" id="gst" placeholder="GST %" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="gst">GST Amount</label>
                                            <input type="text" class="form-control" formControlName="gst_amount" id="gst_amount" placeholder="GST Amount" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="totalPremiumwithGST">Endorsement Gross Premium</label>
                                            <input type="text" class="form-control"
                                                formControlName="totalPremiumwithGST" id="totalPremiumwithGST" placeholder="Total Premium With GST" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="endo_status">Status</label>
                                            <select class="form-select" formControlName="endo_status" id="endo_status" [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.endo_status.errors}"
                                                 required>
                                                <option value="" selected>Select</option>
                                                <option *ngFor="let res2 of endorsement_status_broker"
                                                [value]="res2.id+'@'+res2.status_name">{{res2.status_name}}</option>
                                             </select>
                                            <ng-container
                                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.endo_status.errors">
                                                <p class="error"
                                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.endo_status.errors.required">
                                                    Endorsement Status is required
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="insurer_endo_no">Insurer Endorsement Number</label>
                                            <input type="text" class="form-control" formControlName="insurer_endo_no" id="insurer_endo_no" placeholder="Insurer Endorsement No." [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.insurer_endo_no.errors}"
                                            required />
                                        </div>
                                        <ng-container
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.insurer_endo_no.errors">
                                            <p class="error"
                                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.insurer_endo_no.errors.required">
                                                Insurer Endorsement Number is required
                                            </p>
                                        </ng-container>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                        <label class="bs-form-label" for="endo_copy">Attach Insurance Endorsement Copy</label>
                                        <label for="endo_copy" id="features-file-upload_document_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 5px;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                        <input id="endo_copy" type="file" (change)="endofile_document($event)" formControlName="endo_copy" enctype="multipart/form-data" style="display: none"/>
                                        <small class="error" style="display: none; font-weight:normal" id="endofile_error">Please upload the  Insurance Endorsement Copy</small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Update</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

              <!-- View Form -->
            <div class="modal"  role="dialog" [ngStyle]="{'display':displayStyleView}">
                <div class="modal-dialog" role="document" style="max-width: 800px;margin-top: -50px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">View Endorsement Form</h4>
                        </div>
                        <form [formGroup]="EditInfo">
                            <div class="modal-body">
                                <div class="row">
                                    <!-- <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />                                                         -->

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="client_id">Client Name </label>
                                            <input type="text" class="form-control" formControlName="client_id" id="client_id" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="policy_number">Policy No.</label>
                                            <input type="text" class="form-control" formControlName="policy_number" id="policy_number" readonly />
                                        </div>
                                    </div>


                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="endo_date">Endorsement Date</label>
                                            <input type="text" class="form-control" formControlName="endo_date" id="endo_date" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="coverageDays">Coverage Days</label>
                                            <input type="text" class="form-control" formControlName="coverageDays" id="coverageDays" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="new_member_count">Count of Members</label>
                                            <input type="text" class="form-control" formControlName="new_member_count" id="new_member_count" placeholder="Count of Members" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="individualCoverAmount">Individual Cover Amount (Lacs)</label>
                                            <input type="text" class="form-control" formControlName="individualCoverAmount" id="individualCoverAmount" placeholder="Individual Cover Amount (Lacs)" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="totalSI">Total Sum Insured </label>
                                            <input type="text" class="form-control" formControlName="totalSI" id="totalSI" placeholder="Total Sum Insured " readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="permili_rate">Per Milli Rate</label>
                                            <input type="text" class="form-control"
                                                formControlName="permili_rate" id="permili_rate"
                                                placeholder="Per Milli Rate" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="totalPremium">Net Premium</label>
                                            <input type="text" class="form-control"
                                                formControlName="totalPremium" id="totalPremium"
                                                placeholder="Net Premium" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="prorataPremium"> Prorata Premium</label>
                                            <input type="text" class="form-control"
                                                formControlName="prorataPremium" id="prorataPremium"
                                                placeholder="Prorata Premium" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="gst">GST %</label>
                                            <input type="text" class="form-control" formControlName="gst" id="gst" placeholder="GST %" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="gst">GST Amount</label>
                                            <input type="text" class="form-control" formControlName="gst_amount" id="gst_amount" placeholder="GST Amount" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="totalPremiumwithGST">Endorsement Gross Premium</label>
                                            <input type="text" class="form-control"
                                                formControlName="totalPremiumwithGST" id="totalPremiumwithGST" placeholder="Total Premium With GST" readonly />
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="endo_status">Status</label>
                                            <select class="form-select" formControlName="endo_status" id="endo_status" [attr.disabled]="statusVal > 0 ? true : null">
                                                <option value="" selected>Select</option>
                                                <option *ngFor="let res2 of endorsement_status_broker"
                                                [value]="res2.id+'@'+res2.status_name">{{res2.status_name}}</option>
                                        </select>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="insurer_endo_no">Insurer Endorsement Number</label>
                                            <input type="text" class="form-control" formControlName="insurer_endo_no" id="insurer_endo_no" placeholder="Insurer Endorsement No." readonly />
                                        </div>
                                    </div>

                                    <!-- <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                        <label class="bs-form-label" for="endo_copy">Attach insurance endorsement copy</label>
                                        <label for="endo_copy" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                        <input id="endo_copy" type="file" (change)="endofile_document($event)" formControlName="endo_copy" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                                        </div>
                                        <small style="font-size: 12px; color:#605D62" id="endo_copy">Upload only .xlsx, .xls, .csv files</small>
                                    </div> -->

                                </div>
                            </div>
                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Update</button>                     -->
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

             <!-- Upload File -->
             <div class="modal"  role="dialog" [ngStyle]="{'display':displayStyleUpload}">
                <div class="modal-dialog" role="document" style="max-width: 800px;margin-top: -50px;">
                    <div class="modal-content" style=" margin-top: 150px;">
                        <div class="modal-header">
                            <h4 class="modal-title">Upload Endorsement</h4>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;border-radius: unset;">
                                <strong>File uploaded successfully <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;border-radius: unset;">
                                <strong>Something went wrong, please try again.<span class="alertspan">&times;</span></strong>
                            </div>

                            <div class="row mt-3">

                                <!-- <div class="col-sm-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="client_name">Client Name</label>
                                        <select class="form-select" id="client_name">
                                            <option value="" selected>Select Client Name</option>
                                            <option *ngFor="let res of InfoArraydropdown" [value]="res.employer_id">{{ res.employer_name }}</option>
                                        </select>
                                        <small id="client_name_error" class="bs-form-label" style="color: red; display: none; float: right;">Client Name Required</small>
                                    </div>
                                </div> -->

                                <div class="col-sm-7">
                                    <div class="form-group">
                                    <label class="bs-form-label" for="document_file">Upload Endorsement *</label>
                                    <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                    </label>
                                    <input id="document_file" type="file" (change)="endorsement_doc($event)" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />
                                    <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;" id="document_file">Upload only .xls, .xlsx files</small>
                                    <small id="document_file_error" class="bs-form-label" style="color: red; display: none; float: right;">Please Upload Document</small>
                                    <!-- <small class="sizeerror" style="font-size: 12px; color:#df3603; position: relative; top: -5px; display: none;">File size must be less than 5MB</small>                       -->
                                    </div>
                                </div>

                                <div class="col-sm-2 mt-3">
                                    <button type="button" class="btn btn-primary mt-3"
                                    (click)="onsubmit_EndoFile()" role="button">Upload</button>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <button type="button" class="btn btn-primary mt-3"
                                    (click)="ondownload(endofile, endofileName)" role="button">Download Endorsement Details</button>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <!-- <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Update</button>                     -->
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>
