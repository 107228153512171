import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SurveySelectProductService } from '../survey-select-product/select-product';

@Component({
  selector: 'app-survey-select-product',
  templateUrl: './survey-select-product.component.html',
  styleUrls: ['./survey-select-product.component.css']
})
export class SurveySelectProductComponent implements OnInit {

  survey_gmc_policy_check:any;
  survey_gpa_policy_check:any;
  survey_gtl_policy_check:any;
  survey_opd_policy_check:any;
  survey_opd_policy_check_limit:any

  survey_Company_paid: any;
  survey_Voluntary_paid: any;

  public FormInfo:FormGroup;
  FormInfoSubmitted: boolean = false;

  public GPAInfo:FormGroup;
  GPAInfoSubmitted: boolean = false;

  public GTLInfo:FormGroup;
  GTLInfoSubmitted: boolean = false;

  public OPDInfo:FormGroup;
  OPDInfoSubmitted: boolean = false;

  public PlanInfo:FormGroup;
  PlanInfoSubmitted: boolean = false;

  public PaymentInfo:FormGroup;
  PaymentInfoSubmitted: boolean = false;

  resstatus = localStorage.getItem('resstatus');

  validmsg: any;
  displayGMCPopup = "none";
  displayPayment = "none";

  productsArr:  any = [];

  flag: any;
  
  cnt_graded = 1;
  gradedArrSI: any = {};

  Voluntary_cnt_graded = 1;
  gradedArrSI_Voluntary: any = {};

  CPV_cnt_graded = 1;
  gradedArrSI_CPV: any = {};

  odpflag: any;

  flagSI:any = 0;
  flag_firstcheck_SI:any = 0;

  gmcArr: any = [];
  gpaArr: any = [];
  gtlArr: any = [];
  opdArr: any = []; 

  constructor(private fb: FormBuilder, private dataservice: SurveySelectProductService, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == null) {
          this.route.navigate(['/home']);
      }      
      
    });

  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){      

    if(localStorage.getItem('survey_gmc_policy_check') == null){
      this.survey_gmc_policy_check = 'No';
      localStorage.setItem('survey_gmc_policy_check', 'No'); 
    }else{
      this.survey_gmc_policy_check = localStorage.getItem('survey_gmc_policy_check');
      localStorage.setItem('survey_gmc_policy_check', this.survey_gmc_policy_check);
    }
    
    if(localStorage.getItem('survey_gpa_policy_check') == null){
      this.survey_gpa_policy_check = 'No';
      localStorage.setItem('survey_gpa_policy_check', 'No'); 
    }else{
      this.survey_gpa_policy_check = localStorage.getItem('survey_gpa_policy_check');
      localStorage.setItem('survey_gpa_policy_check', this.survey_gpa_policy_check);
    }

    if(localStorage.getItem('survey_gtl_policy_check') == null){
      this.survey_gtl_policy_check = 'No';
      localStorage.setItem('survey_gtl_policy_check', 'No'); 
    }else{
      this.survey_gtl_policy_check = localStorage.getItem('survey_gtl_policy_check');
      localStorage.setItem('survey_gtl_policy_check', this.survey_gtl_policy_check);
    }

    if(localStorage.getItem('survey_opd_policy_check') == null){
      this.survey_opd_policy_check = 'No';
      localStorage.setItem('survey_opd_policy_check', 'No');       
    }else{
      this.survey_opd_policy_check = localStorage.getItem('survey_opd_policy_check');
      localStorage.setItem('survey_opd_policy_check', this.survey_opd_policy_check);           
    }

    if(localStorage.getItem('survey_opd_policy_check_limit') == null){
      this.survey_opd_policy_check_limit = '';
      localStorage.setItem('survey_opd_policy_check_limit', ''); 
    }else{
      this.survey_opd_policy_check_limit = localStorage.getItem('survey_opd_policy_check_limit');
      localStorage.setItem('survey_opd_policy_check_limit', this.survey_opd_policy_check_limit);            
    }    

    // if(this.survey_opd_policy_check == 'No'){
    //   console.log(1);
    //   $('#divsurvey_OPD_coverage_limit').css('display','none');
    // }else{
    //   console.log(2);
    //   $('#divsurvey_OPD_coverage_limit').css('display','block');
    // }
    
    // this.getSubCategory();
    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.FormInfo = this.fb.group({
      // survey_gmc_policy_check: new FormControl(''),
      // survey_gpa_policy_check: new FormControl(''),
      // survey_gtl_policy_check: new FormControl(''),
      // survey_opd_policy_check: new FormControl('')             
    });   

    // Form element defined below
    this.PlanInfo = this.fb.group({
      survey_gmc_policy_check: new FormControl(''),
      survey_gpa_policy_check: new FormControl(''),
      survey_gtl_policy_check: new FormControl(''),               
      survey_opd_policy_check: new FormControl(''),
      survey_OPD_coverage_limit: new FormControl(''),
      
      survey_survey_survey_Company_paid_or_Voluntary_yesflow: new FormControl(''),
      survey_survey_Company_paid_or_Voluntary: new FormControl(''),
      survey_Company_paid: new FormControl(''),      
      survey_Voluntary_paid: new FormControl(''),           
      survey_Define_flat_SI: new FormControl(''),      
      survey_Voluntary_Define_flat_SI: new FormControl(''),      
      survey_CPV_Define_flat_SI: new FormControl('')
    });

    // Form element defined below
    this.PaymentInfo = this.fb.group({
      payment_id: new FormControl(''),      
    }); 

    // this.policyList();

  }

  //Valid
  get getFormInformationFormControls() {
    return this.FormInfo.controls;
  }  

  //Valid
  get getSubCategoryInformationFormControls() {
    return this.PlanInfo.controls;
  }

  onSubmitGMC() {

    this.FormInfoSubmitted = true;
    if (this.FormInfoSubmitted && this.FormInfo.valid) {            

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),        
        "client_id":localStorage.getItem('client_id'),
        "survey_gmc_policy_check": $('#survey_gmc_policy_check').val()
      };
      // console.log(postdata);

      // this.dataservice.FormInfo(postdata)
      //   .subscribe((result: any) => {
          
      //     // console.log(result);

      //     if (result.status == true) {            
            
      //       this.FormInfo.reset();            
      //       $('#validmsg').css('display', 'block');
      //       $('#errormsg').css('display', 'none');
            
      //       this.validmsg = result.message;   
            
      //       // localStorage.setItem('survey_gmc_policy_check', result.data.id);

      //     } else {
      //       // console.log(result);            
      //     }
      //   }, (error) => {          
      //     this.validmsg = error.error.message;
      //     $('#validmsg').css('display', 'none');  
      //     $('#errormsg').css('display', 'block');        
      // });
      
    } else {
      // validate all form fields
    }
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }  

  closePopup() {
    this.displayGMCPopup = "none";
    this.displayPayment = "none";
  }

  isformat(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }  
    if(evt.target.value == "0"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }    
    return true;
  }

  /************* Show and hide limit input fields ************/
  checkradio(id: any, val: any){

    // console.log(id,val);
    if(val == "Covered"){
      $('#'+id).css("display", "block");
    }else{
      $('#'+id).css("display", "none");
    }

    if(val == "Covered"){
      $('.'+id).css("display", "block");
    }else{
      $('.'+id).css("display", "none");
    }         

  }
  
  filldetails(val: any){    

    if(val == 'GMC'){
      // this.odpflag = 'NO';
      // this.displayGMCPopup = "block";
      // this.flag = 'GMC';      
      // localStorage.setItem('survey_gmc_policy_check', this.survey_gmc_policy_check);
      this.route.navigate(['/survey-gmc-policy-details']);
    }      

    if(val == 'GPA'){
      this.odpflag = 'NO';
      this.displayGMCPopup = "block";
      this.flag = 'GPA';
      localStorage.setItem('survey_gpa_policy_check', this.survey_gpa_policy_check);
      // this.route.navigate(['/gpa-policy-details']);
    }

    if(val == 'GTL'){
      this.odpflag = 'NO';
      this.displayGMCPopup = "block";
      this.flag = 'GTL';      
      localStorage.setItem('survey_gtl_policy_check', this.survey_gtl_policy_check);
      // this.route.navigate(['/policy-details']);
    }

    if(val == 'OPD'){
      this.odpflag = 'YES';
      this.displayGMCPopup = "block";
      this.flag = 'OPD';      
      localStorage.setItem('survey_opd_policy_check', this.survey_opd_policy_check);
      // localStorage.setItem('survey_opd_policy_check_limit', this.survey_opd_policy_check_limit);
      // this.route.navigate(['/policy-details']);      
    }

  }

  getSubCategory(){
    var postdata = {
      'id': 1
    };

    this.dataservice.getSubCategory(postdata)
      .subscribe((result: any) => {

        // console.log(result.data);
        this.productsArr = result.data;

        for(var i = 0; i < result.data.length; i++){

          let html = `
            <option value="`+result.data[i]['sub_product_id']+`">`+result.data[i]['sub_product_name']+`</option>
          `;

          $('#policy_sub_category').append(html);

        }


      }, (error) => {
          // console.log(result);
    });

  }

  redirect(val:any){
    this.PlanInfoSubmitted = true;
    if (this.PlanInfoSubmitted && this.PlanInfo.valid) {
      
      if(val == 1){
        console.log(1);
        var gmcval = this.PlanInfo.controls['survey_gmc_policy_check'].value;
        localStorage.setItem('survey_gmc_policy_check', gmcval);
        this.route.navigate(['/survey-gmc-policy-details']);
      }
      if(val == 2){
        var gpaval = this.PlanInfo.controls['survey_gpa_policy_check'].value;
        localStorage.setItem('survey_gpa_policy_check', gpaval);
        this.route.navigate(['/survey-gpa-policy-details']);
      }
      if(val == 3){
        var gtlval = this.PlanInfo.controls['survey_gtl_policy_check'].value;
        localStorage.setItem('survey_gtl_policy_check', gtlval);
        this.route.navigate(['/survey-gtl-policy-details']);
      }      
      if(val == 4){

        /********* Company paid Array ********/
        for(var i = 1; i < this.cnt_graded; i++){            
          this.gradedArrSI[i] = {bname: $('#graded_name_'+i).val(), blimit:$('#graded_limit_'+i).val()};                       
        }

        let objectoSI = this.gradedArrSI;
        for (var key in objectoSI) {
          if (objectoSI.hasOwnProperty(key)) {
              if (objectoSI[key]['bname'] === undefined || objectoSI[key]['bname'] === null){
                  delete objectoSI[key];
              }
          }
        }
        /*** END ***/


        /********* Voluntary Array ********/
        for(var i = 1; i < this.Voluntary_cnt_graded; i++){            
          this.gradedArrSI_Voluntary[i] = {bname: $('#graded_name_Voluntary_'+i).val(), blimit:$('#graded_limit_Voluntary_'+i).val()};                       
        }

        let objectoSI_Voluntary = this.gradedArrSI_Voluntary;
        for (var key in objectoSI_Voluntary) {
          if (objectoSI_Voluntary.hasOwnProperty(key)) {
              if (objectoSI_Voluntary[key]['bname'] === undefined || objectoSI_Voluntary[key]['bname'] === null){
                  delete objectoSI_Voluntary[key];
              }
          }
        }
        /*** END ***/


        /********* CPV Array ********/
        for(var i = 1; i < this.CPV_cnt_graded; i++){            
          this.gradedArrSI_CPV[i] = {bname: $('#graded_name_CPV_'+i).val(), blimit:$('#graded_limit_CPV_'+i).val()};                       
        }

        let objectoSI_CPV = this.gradedArrSI_CPV;
        for (var key in objectoSI_CPV) {
          if (objectoSI_CPV.hasOwnProperty(key)) {
              if (objectoSI_CPV[key]['bname'] === undefined || objectoSI_CPV[key]['bname'] === null){
                  delete objectoSI_CPV[key];
              }
          }
        }
        /*** END ***/
        
        /********************************************/

        var opdval = this.PlanInfo.controls['survey_opd_policy_check'].value;
        localStorage.setItem('survey_opd_policy_check', opdval);
        
        // survey_Company_paid
        var survey_Company_paid = this.PlanInfo.controls['survey_Company_paid'].value;
        localStorage.setItem('survey_Company_paid', survey_Company_paid);        

        // survey_Company_paid Flat
        var survey_Define_flat_SI = this.PlanInfo.controls['survey_Define_flat_SI'].value;
        localStorage.setItem('survey_Define_flat_SI', survey_Define_flat_SI);        

        // survey_Company_paid Graded
        localStorage.setItem("graded_array", JSON.stringify(this.gradedArrSI));

        /********************************************/


        /********************************************/

        // survey_Voluntary_paid
        var survey_Voluntary_paid = this.PlanInfo.controls['survey_Voluntary_paid'].value;
        localStorage.setItem('survey_Voluntary_paid', survey_Voluntary_paid);        

        // survey_Voluntary_paid Flat
        var survey_Voluntary_Define_flat_SI = this.PlanInfo.controls['survey_Voluntary_Define_flat_SI'].value;
        localStorage.setItem('survey_Voluntary_Define_flat_SI', survey_Voluntary_Define_flat_SI);        

        // survey_Voluntary_paid Graded
        localStorage.setItem("graded_array_Voluntary", JSON.stringify(this.gradedArrSI_Voluntary));

        /********************************************/


        /********************************************/

        // survey_survey_survey_Company_paid_or_Voluntary_yesflow
        var survey_survey_survey_Company_paid_or_Voluntary_yesflow = this.PlanInfo.controls['survey_survey_survey_Company_paid_or_Voluntary_yesflow'].value;
        localStorage.setItem('survey_survey_survey_Company_paid_or_Voluntary_yesflow', survey_survey_survey_Company_paid_or_Voluntary_yesflow);

        // survey_survey_Company_paid_or_Voluntary
        var survey_survey_Company_paid_or_Voluntary = this.PlanInfo.controls['survey_survey_Company_paid_or_Voluntary'].value;
        localStorage.setItem('survey_survey_Company_paid_or_Voluntary', survey_survey_Company_paid_or_Voluntary);        

        // survey_survey_Company_paid_or_Voluntary Flat
        var survey_CPV_Define_flat_SI = this.PlanInfo.controls['survey_CPV_Define_flat_SI'].value;
        localStorage.setItem('survey_CPV_Define_flat_SI', survey_CPV_Define_flat_SI);        

        // survey_survey_Company_paid_or_Voluntary Graded
        localStorage.setItem("graded_array_CPV", JSON.stringify(this.gradedArrSI_CPV));

        /********************************************/


        /*************** Waiting required fields *****************/      

        var errorArr_SI_type = ['survey_survey_survey_Company_paid_or_Voluntary_yesflow', 'survey_survey_Company_paid_or_Voluntary', 'survey_Company_paid', 'survey_Voluntary_paid', 'survey_CPV_Define_flat_SI', 'survey_Define_flat_SI', 'survey_Voluntary_Define_flat_SI'];
        
        for(var i = 0; i < errorArr_SI_type.length; i++){
          
          var value = errorArr_SI_type[i];
          var id = errorArr_SI_type[i] + '_error';
        
          if($('#' + value + ':visible').length == 0){                                    

          }else{
            if(this.PlanInfo.controls[value].value == ''){
              $('#' + id).css('display', 'block');
              $('#' + value).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + value).removeClass('control-red');
            }
          }          

        }             
        /******************************  END *******************************/


        /********************* Open/Close SI ******************/              
      
        var id_SI = '.opddiv';        

        var errorArr_SI = ['survey_survey_survey_Company_paid_or_Voluntary_yesflow', 'survey_survey_Company_paid_or_Voluntary', 'survey_Company_paid', 'survey_Voluntary_paid', 'survey_CPV_Define_flat_SI', 'survey_Define_flat_SI', 'survey_Voluntary_Define_flat_SI'];
                
        for(var i = 0; i < errorArr_SI.length; i++){
                    
          var value = errorArr_SI[i];
          var cond = $('#' + value).is(':visible');
          
          if(cond){

            // console.log('input visible');

            var cond2 = $(id_SI + ' .error').is(':visible');

            if (cond2){  
              // console.log('yes error'); 
              this.flagSI = 0;
            }else{
              // console.log('no error');
              this.flagSI = 1;
            }

          }else{

            // console.log('input not visible');            

            var cond2 = $(id_SI + ' .error').is(':visible');

            if (cond2){
              this.flagSI = 0;
            }else{
              this.flagSI = 1;
            }

          }                    
          
        }

        // console.log('SI flag - ', this.flagSI);
        
        if(this.flagSI == 0){
          
          
        }else{

          this.route.navigate(['/survey-opd-policy-details']);
        
        }        
        /**************************** END ***********************************/
        
        

      }

    }
  }

  /********************************/
  show_SI_div(val: any){    
    if(val == 'Flat'){
      $('#div_Multiple_of_Salary').css('display','none');
      $('#div_Flat').css('display','block');
      $('#div_Graded').css('display','none');
    }
    if(val == 'Graded'){
      $('#div_Multiple_of_Salary').css('display','none');
      $('#div_Flat').css('display','none');
      $('#div_Graded').css('display','block');
    }
    if(val == 'survey_Company_paid'){
      $('#div_survey_Company_paid').css('display','block');
      $('#div_survey_Voluntary_paid').css('display','none');
    }
    if(val == 'survey_Voluntary_paid'){
      $('#div_survey_Company_paid').css('display','none');
      $('#div_survey_Voluntary_paid').css('display','block');
    }
  }

  show_SI_div_Voluntary(val: any){    
    if(val == 'Flat'){
      $('#div_Flat_Voluntary').css('display','block');
      $('#div_Graded_Voluntary').css('display','none');
    }
    if(val == 'Graded'){
      $('#div_Flat_Voluntary').css('display','none');
      $('#div_Graded_Voluntary').css('display','block');
    }
  }

  show_SI_div_CPV(val: any){    
    if(val == 'Flat'){
      $('#div_Flat_CPV').css('display','block');
      $('#div_Graded_CPV').css('display','none');
    }
    if(val == 'Graded'){
      $('#div_Flat_CPV').css('display','none');
      $('#div_Graded_CPV').css('display','block');
    }
  }
  
  /***** Add Graded Field *****/
  addGradedField(){

    let html = `
    <div id="this_graded_row_`+this.cnt_graded+`" class="row">      
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_name_`+this.cnt_graded+`">Graded </label>
          <input type="text" class="form-control" formControlName="graded_name_`+this.cnt_graded+`" id="graded_name_`+this.cnt_graded+`" />
      </div>
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_limit_`+this.cnt_graded+`">Amount </label>
          <input type="text" class="form-control" formControlName="graded_limit_`+this.cnt_graded+`" id="graded_limit_`+this.cnt_graded+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">                        
          <img src="../../../assets/img/common icons/trash.png" id="graded_row_`+this.cnt_graded+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newgradedField').append(html);

    this.PlanInfo.addControl("graded_name_"+this.cnt_graded, new FormControl(''));
    this.PlanInfo.addControl("graded_limit_"+this.cnt_graded, new FormControl(''));

    var bname = "graded_name_"+this.cnt_graded;
    var blimit = "graded_limit_"+this.cnt_graded;

    this.gradedArrSI[this.cnt_graded] = {bname: "", blimit: ""};

    let flag = this;
    $("#graded_row_"+this.cnt_graded).on('click',function(e) {
      flag.removeGradedRow(e);
    });

    $("#graded_limit_"+this.cnt_graded).on('keyup',function(e) {
      flag.isnumberGraded(e);
    });
    
    $("#graded_limit_"+this.cnt_graded).on('input',function(e) {
      flag.isformatgraded(e);
    });

    this.cnt_graded++;

    console.log(this.gradedArrSI);

  }

  removeGradedRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_graded_row_' + id;
    $(idstr).remove();

  }

  isnumberGraded(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  isformatgraded(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }
  
  /***** Add Graded Field Voluntary *****/
  addGradedField_Voluntary(){

    let html = `
    <div id="this_graded_row_Voluntary`+this.Voluntary_cnt_graded+`" class="row">      
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_name_Voluntary_`+this.Voluntary_cnt_graded+`">Graded </label>
          <input type="text" class="form-control" formControlName="graded_name_Voluntary_`+this.Voluntary_cnt_graded+`" id="graded_name_Voluntary_`+this.Voluntary_cnt_graded+`" />
      </div>
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_limit_Voluntary_`+this.Voluntary_cnt_graded+`">Amount </label>
          <input type="text" class="form-control" formControlName="graded_limit_Voluntary_`+this.Voluntary_cnt_graded+`" id="graded_limit_Voluntary_`+this.Voluntary_cnt_graded+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">                        
          <img src="../../../assets/img/common icons/trash.png" id="this_graded_row_Voluntary_`+this.Voluntary_cnt_graded+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newgradedField_Voluntary').append(html);

    this.PlanInfo.addControl("graded_name_Voluntary_"+this.Voluntary_cnt_graded, new FormControl(''));
    this.PlanInfo.addControl("graded_limit_Voluntary_"+this.Voluntary_cnt_graded, new FormControl(''));

    var bname = "graded_name_Voluntary_"+this.Voluntary_cnt_graded;
    var blimit = "graded_limit_Voluntary_"+this.Voluntary_cnt_graded;

    this.gradedArrSI[this.Voluntary_cnt_graded] = {bname: "", blimit: ""};

    let flag = this;
    $("#this_graded_row_Voluntary_"+this.Voluntary_cnt_graded).on('click',function(e) {
      flag.removeGradedRow_Voluntary(e);
    });

    $("#graded_limit_Voluntary_"+this.Voluntary_cnt_graded).on('keyup',function(e) {
      flag.isnumberGraded_Voluntary(e);
    });
    
    $("#graded_limit_Voluntary_"+this.Voluntary_cnt_graded).on('input',function(e) {
      flag.isformatgraded_Voluntary(e);
    });

    this.Voluntary_cnt_graded++;

    console.log(this.gradedArrSI_Voluntary);

  }

  removeGradedRow_Voluntary(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_graded_row_Voluntary' + id;
    $(idstr).remove();

  }

  isnumberGraded_Voluntary(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  isformatgraded_Voluntary(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  /***** Add Graded Field CPV *****/
  addGradedField_CPV(){

    let html = `
    <div id="this_graded_row_CPV`+this.CPV_cnt_graded+`" class="row">      
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_name_CPV_`+this.CPV_cnt_graded+`">Graded </label>
          <input type="text" class="form-control" formControlName="graded_name_CPV_`+this.CPV_cnt_graded+`" id="graded_name_CPV_`+this.CPV_cnt_graded+`" />
      </div>
      <div class="col-sm-5 form-group mt-3">
          <label class="bs-form-label" for="graded_limit_CPV_`+this.CPV_cnt_graded+`">Amount </label>
          <input type="text" class="form-control" formControlName="graded_limit_CPV_`+this.CPV_cnt_graded+`" id="graded_limit_CPV_`+this.CPV_cnt_graded+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">                        
          <img src="../../../assets/img/common icons/trash.png" id="this_graded_row_CPV_`+this.CPV_cnt_graded+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newgradedField_CPV').append(html);

    this.PlanInfo.addControl("graded_name_CPV_"+this.CPV_cnt_graded, new FormControl(''));
    this.PlanInfo.addControl("graded_limit_CPV_"+this.CPV_cnt_graded, new FormControl(''));

    var bname = "graded_name_CPV_"+this.CPV_cnt_graded;
    var blimit = "graded_limit_CPV_"+this.CPV_cnt_graded;

    this.gradedArrSI[this.CPV_cnt_graded] = {bname: "", blimit: ""};

    let flag = this;
    $("#this_graded_row_CPV_"+this.CPV_cnt_graded).on('click',function(e) {
      flag.removeGradedRow_CPV(e);
    });

    $("#graded_limit_CPV_"+this.CPV_cnt_graded).on('keyup',function(e) {
      flag.isnumberGraded_CPV(e);
    });
    
    $("#graded_limit_CPV_"+this.CPV_cnt_graded).on('input',function(e) {
      flag.isformatgraded_CPV(e);
    });

    this.CPV_cnt_graded++;

    console.log(this.gradedArrSI_CPV);

  }

  removeGradedRow_CPV(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_graded_row_CPV' + id;
    $(idstr).remove();

  }

  isnumberGraded_CPV(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  isformatgraded_CPV(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  gotopolicies(val:any){
    this.route.navigate(['/go-to-policies']);
    localStorage.setItem('gotopolicy', val);
  }

  policyList(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id')
    };

    this.dataservice.policyList(postdata)
      .subscribe((result: any) => {

        // console.log(result); 
        
        this.gmcArr = result.data.gmclist;
        this.gpaArr = result.data.gpalist;
        this.gtlArr = result.data.gtllist;
        this.opdArr = result.data.opdlist;
        
      }, (error) => {
          // console.log(result);
    });
  }

  ActiveStatus(is_active:any, client_product_id:any, icon_name:any){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "is_active": is_active,
      "client_product_id": client_product_id,
      "icon_name": icon_name
    };

    console.log(postdata);

    this.dataservice.ActiveStatus(postdata)
      .subscribe((result: any) => {

        // console.log(result); 

        this.policyList();
       
      }, (error) => {
          // console.log(result);
    });
  }

  showpayment(id: any){
    // console.log(id);
    this.displayPayment = "block";
    $("#payment_ID").val(id);
  }

  SubmitPayment(){

    if(this.PaymentInfo.controls['payment_id'].value == ""){
      $('#payment_id_error').css('display','block');
      $('#payment_id').addClass('control-red');
    }else{
      $('#payment_id_error').css('display','none');
      $('#payment_id').removeClass('control-red');

      var client_product_id = $('#payment_ID').val();
      // console.log(client_product_id);

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),        
        "client_id":localStorage.getItem('client_id'),    
        "client_product_id": client_product_id,
        "payment_id": this.PaymentInfo.controls['payment_id'].value      
      };
  
      console.log(postdata);
  
      this.dataservice.SubmitPayment(postdata)
        .subscribe((result: any) => {
  
          // console.log(result); 
          this.displayPayment = "none";
          this.policyList();
         
        }, (error) => {
            // console.log(result);
      });

    }

    
  }

}
